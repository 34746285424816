import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ManageAssessmentAndGradingsService } from '../services/manage-assessment-and-gradings.service';

@Component({
  selector: 'app-report-cards-template1',
  templateUrl: './report-cards-template1.component.html',
  styleUrls: ['./report-cards-template1.component.css']
})
export class ReportCardsTemplate1Component implements OnInit, OnChanges {

  @Input() studentData: any;

  studentChange: boolean = false;
  studentDetails: any = [];
  attendanceDetails: any = [];
  reportCardsFormatID: any;
  reportCardMasterFields: any = [];
  instituteGroup: any = [];
  studentGroup: any = [];
  attendanceGroup: any = [];
  assessmentsGroup: any = [];
  othersGroup: any = [];
  croppedImage: any;
  studentCustomGroup: any = [];
  commentPosition = 1;
  orgDetails: any;
  orgName: any;
  orgContact: any;
  orgEmail: any;
  orgAddress: any;

  public editorModel: any = {
    customCommentTitle: "",
    customCommentValue: "",
    contentCommentTitle: "",
    contentCommentValue: "",
    signatureCommentTitle: "",
    signatureCommentValue: ""
  };

  constructor(
    private manageAssessmentService: ManageAssessmentAndGradingsService,
  ) {
    this.orgDetails = localStorage.getItem('instituteDetails');
    if (this.orgDetails != "" && this.orgDetails != null) {
      this.orgDetails = JSON.parse(this.orgDetails);
      console.warn(this.orgDetails);
      if (this.orgDetails != null) {
        this.orgName = this.orgDetails.orgName;
        this.orgContact = this.orgDetails.contact;
        this.orgEmail = this.orgDetails.email;
        this.orgAddress = this.orgDetails.address;
      }
    }
    if (localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
      this.croppedImage = localStorage.getItem('Logo');
    } else {
      this.croppedImage = '../../../../../../assets/img/admin/org_logo.png';
    }
  }

  ngOnChanges() {
    if (this.studentChange) {
      this.ngOnInit();
    } else {
      this.studentChange = true;
    }
  }
  assessmentName: any = [];
  assessmentDetails: any = [];
  rubicDetails: any = [];
  assessmentCatWeight: any = [];
  ngOnInit(): void {
    this.studentDetails = this.studentData.studentDetails;
    this.attendanceDetails = this.studentData.attendanceDetais;
    this.assessmentDetails = this.studentData.studentAssessmentDetails;
    this.assessmentName = this.studentData.studentAssessmentName;
    this.rubicDetails = this.studentData.rubicData;
    this.reportCardsFormatID = this.studentDetails[0].ReportCardsFormatID;
    this.assessmentCatWeight = this.studentData.assessmentCatWeight;
    this.getTemplateDetails(this.reportCardsFormatID);
    this.calculateAssessment();
    //console.warn('>>>>>>>>assessment=====', this.assessmentDetails)
  }
  attendanceCustomGroup: any = [];
  percentage: any;
  getTemplateDetails(formatID: any) {
    this.manageAssessmentService.GetAssignedTemplateDetails(formatID).subscribe((res: any) => {
      this.reportCardMasterFields = res.responseData;
      console.warn('>>>>>>>>>>>>>>', res.responseData);
      this.instituteGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 1);
      this.studentGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 2);
      this.attendanceGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 3);
      this.attendanceCustomGroup = this.reportCardMasterFields.filter((x: any) => x.extraFieldValue == 'Attendance fields');
      this.assessmentsGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 4);
      this.othersGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 5);
      this.studentCustomGroup = this.reportCardMasterFields.filter((x: any) => x.extraFieldValue == "Custom fields")

      let customTitle = this.instituteGroup.filter((x: any) => x.fieldName == "Custom title");
      let contentblock = this.othersGroup.filter((x: any) => x.fieldName == "Contents block");
      let signatureBlock = this.othersGroup.filter((x: any) => x.fieldName == "Signature block");
      let comments = this.assessmentsGroup.filter((x: any) => x.fieldName == "Comments");


      this.commentPosition = Number(comments[0].extraFieldValue);
      this.editorModel.customCommentTitle = customTitle[0].commentTitle;
      this.editorModel.customCommentValue = customTitle[0].commentValue;
      this.editorModel.contentCommentTitle = contentblock[0].commentTitle;
      this.editorModel.contentCommentValue = contentblock[0].commentValue;
      this.editorModel.signatureCommentTitle = signatureBlock[0].commentTitle;
      this.editorModel.signatureCommentValue = signatureBlock[0].commentValue;
      this.attendanceCustomGroup.forEach((element: any) => {
        if (element.aLabel != '') {
          let chk = this.attendanceDetails.filter((x: any) => x.statusName == element.aLabel);
          element.total = Math.floor(chk[0].total);
          element.totalDays = chk[0].totalDays;
          element.percentage = chk[0].percentage;
          if (chk[0].isRelatedWithPresentAbsent && chk[0].attendanceStatusMasterID != 1 && chk[0].attendanceStatusMasterID != 2)
            element.showPercentage = false;
          else
            element.showPercentage = true;
        }

      });
      this.percentage = this.attendanceDetails.filter((z: any) => z.attendanceStatusMasterID
        == 1)
      console.warn('<><><><><><><>', this.attendanceCustomGroup);
    });
  }
  score: any = 0;
  totalScore: any = 0
  overallScore: any = 0;
  calculateAssessment() {
    //debugger;
    this.assessmentDetails.forEach((element: any) => {
      this.totalScore = 0;
      this.score = 0;
      this.overallScore = 0;
      this.assessmentName.forEach((x: any) => {
        let catWeightData = this.assessmentCatWeight.filter((chk: any) => chk.subjectID == element.SubjectID && chk.assessmentCategoryID == x.assessmentCategoryID);
        let catWeight = 0;
        if (catWeightData.length > 0) {
          catWeight = Number(catWeightData[0].weightresult);
        }
        let totalCategoryScore = x.assessmentList.reduce((prev: any, next: any) => prev + next.points, 0);
        this.totalScore += totalCategoryScore;
        let catScore = 0;
        let assessmentScore = 0;
        let count = 0;
        x.assessmentList.forEach((y: any) => {
          count++;
          if (y.gradingScaleTypeID == 1 || y.gradingScaleTypeID == 3) {
            element[y.assessmentName] = ((Number(y.points) * Number(element[y.assessmentName])) / 100).toFixed(2);
          }
          this.score += Number(element[y.assessmentName]);
          catScore += Number(element[y.assessmentName]);
          assessmentScore += (Number(element[y.assessmentName]) * catWeight) / Number(y.points);
        });
        element['catScore' + x.assessmentCategoryID] = catScore.toFixed(2);
        element['catTotal' + x.assessmentCategoryID] = totalCategoryScore.toFixed(2);
        // element['cat' + x.assessmentCategoryID] = ((catScore * 100) / totalCategoryScore).toFixed(2);
        this.overallScore += (assessmentScore / count);
        element['cat' + x.assessmentCategoryID] = (assessmentScore / count).toFixed(2);
      });
      element.totalScore = this.totalScore;
      element.score = this.score;
      // element.finalScore = ((this.score * 100 ) / this.totalScore).toFixed(2);
      element.finalScore = this.overallScore.toFixed(2);
    });
    console.warn('final============= ', this.assessmentDetails);
  }
}
