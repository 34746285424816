import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment, FeeAndInvoice, FinanceAndAccount } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FinanceAndAccountService {
  paidBy: any = '';
  private myBehaviorSubject1 = new BehaviorSubject<any>('');
  private accountId: number;
  constructor(private http: HttpClient,) { }

  createStripeSession(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.createStripeSession}`, data);
  }

  paypalSubcription(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.paypalSubscription}`, data);
  }

  getAccountId() {
    let user: any = localStorage.getItem('currentUser')
    const currentuser = JSON.parse(user)
    this.accountId = currentuser.AccountId;
    return this.accountId;
  }

  // setPaidBy(value: string) {
  //   this.myBehaviorSubject.next(value);
  // }

  // getPaidBy() {
  //   return this.myBehaviorSubject.asObservable();
  // }

  setDueAmount(value: string) {
    this.myBehaviorSubject1.next(value);
  }

  getDueAmount1() {
    return this.myBehaviorSubject1.asObservable();
  }
  setPaymentAmount(value: string) {
    this.myBehaviorSubject1.next(value);
  }

  getPaymentAmount() {
    return this.myBehaviorSubject1.asObservable();
  }
  // setPaymentAmount(value: string) {
  //   this.myBehaviorSubject1.next(value);
  // }

  // getPaymentAmount() {
  //   return this.myBehaviorSubject1.asObservable();
  // }

  setAccountId(id: number) {
    this.accountId = id;
  }

  CreateAccount(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.createAccount}`, data);
  }
  GetAccountList(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.getAccountList}`, data);
  }
  DeleteAccountList(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.deleteAccount}` + '?id=' + Id);
  }
  GetAllAccountDropdown() {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAllAccount}`);
  }
  GetAccountByID(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAccountByID}/${Id}`);
  }
  GetAccountActivityLog(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAccountActivityLog}` + '?id=' + Id);
  }
  UpdateAccount(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.updateAccount}`, data);
  }
  GetChartOfAccountList(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.getAllChartOfAccount}`, data);
  }
  GetTrailBalData(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.getTrailBalData}`, data);
  }
  GetProfitLossData(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.getProfitLossData}`, data);
  }

  GetBalanceSheetData(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.getBalanceSheetData}`, data);
  }

  CreateAccountGroup(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.createAccountGroup}`, data);
  }
  GetAccountGroupByID(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAccountGroupByID}` + '?accountGroupID=' + Id + '&accountID=' + this.accountId);
  }
  UpdateAccountGroup(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.updateAccountGroup}`, data);
  }
  DeleteAccountGroup(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.deleteAccountGroup}` + '?id=' + Id);
  }

  CreateAccountLedger(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.createAccountLedger}`, data);
  }
  GetAccountLedgerByID(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAccountLedgerByID}` + '?accountLedgerID=' + Id + '&accountID=' + this.accountId);
  }
  UpdateAccountLedger(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.updateAccountLedger}`, data);
  }
  getAccountLedgerStatementById(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.getAccountLedgerStatementByID}`, data);
  }

  LedgerReconciliation(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.LedgerReconciliation}`, data);
  }
  DeleteAccountLedger(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.deleteAccountLedger}` + '?id=' + Id);
  }
  getAllAccountLedgerbyID(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAllAccountLedgerByID}` + '?id=' + Id);
  }

  CreateAccountEntry(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.createAccountEntry}`, data);
  }
  UpdateAccountEntry(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.updateAccountEntry}`, data);
  }
  getAccountEntrybyID(accountEntryId: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAccountEntrybyID}` + '?accountEntryId=' + accountEntryId + '&accountID=' + this.accountId);
  }
  sendEmail(accountEntryId: any, emailto: any) {
    let data = {
      AccountEntryID: accountEntryId,
      AccountID: this.accountId,
      EmailTo: emailto
    };
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.sendEmail}`, data);
  }
  getAllAccountEntrybyID(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAllAccountLedgerByID}` + '?id=' + Id);
  }
  getAllAccountEntriesFilter(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.getAllAccountEntryList}`, data);
  }
  DeleteAccountEntry(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.deleteAccountEntry}` + '?id=' + Id);
  }

  getAllAccountTag(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAllAccountEntry}` + '?id=' + Id);
  }

  getAllAccountEntryType(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAllAccountEntryType}` + '?id=' + Id);
  }

  deleteAccountEntryType(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.deleteAccountEntryType}` + '?id=' + Id);
  }

  getAccountEntryTypeById(Id: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAccountEntryTypeById}` + '?id=' + Id);
  }

  getAccountUniqueNumber(Id: any, entryTypeID: any) {
    return this.http.get(`${environment.baseUrl}${FinanceAndAccount.getAccountUniqueNumber}` + '?id=' + Id + '&entryTypeID=' + entryTypeID);
  }

  updateAccountEntryType(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.updateAccountEntryType}`, data);
  }
  addEntryType(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.addEntryType}`, data);
  }

  CreateUpdateSetting(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.createUpdateSetting}`, data);
  }
  getFeeSetting() {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getFeeSetting}`);
  }

  CreateFeeAndInvoice(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.createFeeAndInvoice}`, data);
  }
  updateFeeAndInvoice(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.updateFeeAndInvoice}`, data);
  }
  getFeeAndInvoiceById(Id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getFeeAndInvoiceById}${Id}`);
  }

  CreateUpdateInvoice(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.createAndUpdateInvoice}`, data);
  }
  getInvoiceById(Id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getInvoiceById}${Id}`);
  }
  createMasterTax(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.createMasterTax}`, data)
  }
  deleteTax(Id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.deleteTax}${Id}`);
  }


  getAllTaxes() {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getAllTaxes}`);
  }
  getAllDeletedTaxes() {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getAllDeletedTaxes}`);
  }
  addPayment(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.addPayment}`, data);
  }
  updatePayment(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.updatePayment}`, data);
  }
  getpaymentById(id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getPaymentById}${id}`);
  }
  getpaymentByInvoiceId(id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getPaymentByInvoiceId}${id}`);
  }
  getInvoiceEditLogsByInvoiceId(id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getInvoiceEditLogsByInvoiceId}${id}`);
  }
  getFeesAndInvoiceList(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.getFeesAndInvoicepagination}`, data);
  }
  deleteFeesAndInvoiceByIdList(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.deleteMultiplefeesandinvoiceById}`, data);
  }
  archiveFeesAndInvoiceByIdList(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.archiveMultiplefeesandinvoiceById}`, data);
  }
  activeFeesAndInvoiceByIdList(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.activeMultiplefeesandinvoiceById}`, data);
  }
  getInvoiceList(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.getInvoicepagination}`, data);
  }
  getPaymentList(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.getPaymentList}`, data);
  }
  getFeesName() {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getAllFees}`);
  }
  deleteInvoicesByIdList(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.deleteInvoices}`, data);
  }
  getDueAmount(id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getDueAmount}${id}`);
  }
  getInvoiceStatusList() {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getInvoiceStatus}`);
  }


  SendInvoceEmailToStudent(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.SendInvoceEmailToStudent}`, data);
  }

  SendInvoceSMSToStudent(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.SendInvoceSMSToStudent}`, data);
  }
  lockAccountById(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.lockAccount}`, data);
  }
  dashBordDetails(data: any) {
    return this.http.post(`${environment.baseUrl}${FinanceAndAccount.dashbordDetails}`, data);
  }
  cancelInvoice(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.cancelInvoice}`, data);
  }
  getOverallFeeAndInvoice() {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getOverAllFeeAndInvoice}`);
  }
  getFeeAndInvoiceForClassSetion(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.getFeeAndInvoiceForClassSetion}`, data);
  }
  getFeeAndInvoiceForIndividual() {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getFeeAndInvoiceForIndividual}`);
  }
  getInvoiceByIdForEmail(Id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getInvoiceByIdForEmail}${Id}`);
  }
  getpaymentByInvoiceIdForEmail(id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getPaymentByInvoiceIdForEmail}${id}`);
  }
  getInvoiceEditLogsByInvoiceIdForEmail(id: any) {
    return this.http.get(`${environment.baseUrl}${FeeAndInvoice.getInvoiceEditLogsByInvoiceIdForEmail}${id}`);
  }
  getLogoInBase64(model: any) {

    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.getLogoInBase64}`, model);
  }



  FeePostTransaction(data: any) {
    return this.http.post(`${environment.baseUrl}${FeeAndInvoice.feePostTransaction}`, data);
  }
}
