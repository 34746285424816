<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Attendance Submission Analytics</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
          <li class="breadcrumb-item"><a routerLink="../manage-student-attendance">Manage Student Attendance</a></li>
          <li class="breadcrumb-item active" aria-current="page">Attendance Submission Analytics</li>
        </ol>
      </div>
    </div>

  </div>
  <div class="cardWidget">
    <div class="assesmentTabs">
      <a *ngIf="checkScreenPermission('Manage Students')" routerLink="../manage-student-attendance"
        routerLinkActive="active"><i class="fas fa-tasks"></i> Manage Student Attendance</a>
      <a *ngIf="checkScreenPermission('Attendance Submission Analytics')" routerLink="../student-submission-analytics"
        routerLinkActive="active"><i class="fas fa-chart-line"></i> Attendance Submission Analytics</a>
      <a [queryParams]="{ id: '3' }" *ngIf="checkScreenPermission('Attendance Status Setting')"
        routerLink="../attendance-settings" routerLinkActive="active"><i class="fas fa-cog"></i>Settings</a>
    </div>
    <div class="cardBody">
      <form class="filters-form" (submit)="onSubmit()">
        <div class="row">
          <div class="col-lg-10 col-sm-12">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Date Range</mat-label>
                  <mat-date-range-input [formGroup]="campaignOne" [rangePicker]="campaignOnePicker" required
                    [comparisonStart]="campaignTwo.value.start" [comparisonEnd]="campaignTwo.value.end">
                    <input matStartDate placeholder="Start date" formControlName="start">
                    <input matEndDate placeholder="End date" formControlName="end">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                  <mat-error *ngIf="campaignOne.controls['start'].invalid ||campaignOne.controls['end'].invalid">
                    Date is required
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-lg-4 col-sm-12">
                <div class="multiselectformfield">
                  <!-- <mat-label class="multiselectlabel">Select Class & Section</mat-label> -->

                  <!-- <ng-multiselect-dropdown  [formControl]="toppings" [settings]="dropdownSettings" [formGroup]="multiselector"
          [data]="toppingList" formControlName="selectedClassSectionID" [disabled]="disabled"
          [placeholder]="'Select Class-Section'" toppings.required="true" class="m-0">
        </ng-multiselect-dropdown> -->
                  <div class="w-100" appearance="outline">
                    <p-multiSelect [options]="toppingList" [formControl]="toppings" defaultLabel="Select Class-Section"
                      optionLabel="dropDown" (click)="valueChk(elm.value)" display="chip" #elm>
                    </p-multiSelect>
                    <mat-label class="mutilselectlable">Select Class & Section</mat-label>
                  </div>
                  <mat-error *ngIf="toppings.dirty && toppings.touched && toppings.invalid">
                    please select class section
                  </mat-error>
                </div>
                <!-- <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Class-Section</mat-label>
          <mat-select [formControl]="toppings" multiple required>
            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping.className}} - {{topping.sectionName}}</mat-option>
          </mat-select>
        </mat-form-field> -->




                <br>
                <!-- <p>Select All / Unselect All </p> -->
                <div class="text-center">
                  <p>Apply further filter on
                    <a class="underlinedLink primaryText" href="javascript:void(0)"
                      (click)="SelectedSubjects()">Subjects </a> or
                    <a class="underlinedLink primaryText" href="javascript:void(0)" (click)="SelectedTeacher()">Teacher
                    </a>
                  </p>
                </div>
                <br>
                <!-- <ng-multiselect-dropdown *ngIf="subjectCheckBox" [formControl]="subjects" [settings]="dropdownSettingsforsubject" [formGroup]="multiSubject"
          [data]="subjectData" formControlName="subject" [disabled]="disabled"
          [placeholder]="'Select Subject'">
        </ng-multiselect-dropdown> -->
                <div class="w-100" *ngIf="subjectCheckBox" appearance="outline">
                  <p-multiSelect [options]="subjectData" [formControl]="subjects" defaultLabel="Select Subject"
                    optionLabel="subjectName" display="chip" #elm>
                  </p-multiSelect>
                  <mat-label class="mutilselectlable">Select Subject</mat-label>
                </div>

                <!-- <ng-multiselect-dropdown *ngIf="teacherCheckBox" [formControl]="teacher" [settings]="dropdownSettingsforTeacher" [formGroup]="multiTeacher"
          [data]="teacherData" formControlName="teacher" [disabled]="disabled"
          [placeholder]="'Select Teacher'">
        </ng-multiselect-dropdown> -->
                <div class="w-100" *ngIf="teacherCheckBox" appearance="outline">
                  <p-multiSelect [options]="teacherData" [formControl]="teacher" defaultLabel="Select Teacher"
                    optionLabel="teacherName" display="chip" #elm>
                  </p-multiSelect>
                  <mat-label class="mutilselectlable">Select Teacher</mat-label>
                </div>

              </div>

              <div class="col-lg-4 col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>View All</mat-label>
                  <mat-select [formControl]="ViewStudent">
                    <mat-option value="all">View All</mat-option>
                    <mat-option value="unmarkedAll">View Unmarked Attendance Only</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
          </div>
          <div class="col-lg-2 col-sm-12">
            <button mat-button class="btn btn-primary mb-2 w-100"
              [disabled]="campaignOne.invalid || campaignTwo.invalid || toppings.invalid" matRipple>Find <svg
                xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                  <path id="Path"
                    d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                    transform="translate(-3 -5.95)" fill="#fff" />
                </g>
              </svg></button>
          </div>
        </div>
      </form>
      <div class="downloadTable tableActions" matTooltip="Download file"
        *ngIf="checkActionPermission('Download Analytics')">
        <a (click)="ExportTOExcel()" mat-raised-button><i title="Export Students Attendance"
            class="fas fa-download"></i></a>
      </div>
      <div class="submissions-list" #TABLE>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #table>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.Date | date:dateFormat}} </td>
          </ng-container>

          <ng-container matColumnDef="class">
            <th mat-header-cell *matHeaderCellDef> Class </th>
            <td mat-cell *matCellDef="let element"> {{element.className}} </td>
          </ng-container>

          <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef> Section </th>
            <td mat-cell *matCellDef="let element"> {{element.sectionName}} </td>
          </ng-container>

          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef> Subject </th>
            <td mat-cell *matCellDef="let element"> {{element.subjectName}} <br>
              <!-- <a routerLink="../manage-student-attendance"> {{element.studentName}}
          </a> -->
              <a routerLink="../manage-students/view-profile"
                (click)="infoCall(element.TeacherID)">{{element.TeacherName}}
              </a>
            </td>
            <!-- <span *matCellDef="let element"> {{element.studentName}}</span> -->
          </ng-container>
          <!-- <div *ngIf="showAccourding=='all'"> -->
          <ng-container matColumnDef="session">
            <!-- *ngIf="showAccourding=='all'"> -->
            <th mat-header-cell *matHeaderCellDef> Session | Default </th>
            <!-- <div *ngIf="showAccourding=='all'"> -->
            <td mat-cell *matCellDef="let element">
              <!-- <div *ngIf="element.attendanceStatusMasterID==''|| element.attendanceStatusMasterID==5 || element.Date!=element.DateFromDB;else myvlog">
            <button class="btn btn-primary btn-sm" routerLink="../manage-student-attendance"
            (click)="getRecordsAccordingToDate(element)">
            Submit
          </button>
          </div>
          <ng-template #myvlog>    
            <button class="btn btn-primary btn-sm" routerLink="../student-submission-analytics"
            (click)="getRecordsAccordingToDate(element)"><i class="fa fa-check"></i>
          </button>
          </ng-template> -->
              <div *ngIf="!element.IsSubmitted;else myvlog">
                <button class="btn btn-primary btn-sm" routerLink="../manage-student-attendance"
                  (click)="getRecordsAccordingToDate(element)">
                  Submit
                </button>
              </div>
              <ng-template #myvlog>
                <button class="btn btn-primary btn-sm" routerLink="../manage-student-attendance"
                  (click)="getRecordsAccordingToDate(element)"> Submitted
                </button>
              </ng-template>
            </td>
            <!-- </div> -->
            <!-- <div *ngIf="showAccourding=='unmarkedAll'">
        <td mat-cell *matCellDef="let element" >
          <div *ngIf="element.attendanceStatusMasterID==''|| element.attendanceStatusMasterID==5">
            <button class="mat-stroked-button mat-button-base mat-primary" routerLink="../manage-student-attendance"
            (click)="getRecordsAccordingToDate(element)">
            Submit
          </button>
          </div>
        </td>
      </div> -->
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999">
              No data found
            </td>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="!dataSource"><span>No data found</span></div>
      </div>
    </div>
  </div>
</div>