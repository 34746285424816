<div *ngIf="instituteGroup | customRepotCard: 'Custom title'" class="reportHeader">
    <div class="text-center">
        <p class="logoleft institutename"><strong>{{ editorModel.customCommentTitle }}</strong></p>
    </div>
    <div class="logoleft customtext" [innerHTML]="editorModel.customCommentValue">
        
    </div>
    <div class="headerRightAddress">

    </div>
</div>
<div class="reportHeader d-flex justify-content-between align-items-center row mx-10">
    <div class="col-lg-6 col-sm-12 d-flex align-items-center">
        <img *ngIf="instituteGroup | customRepotCard: 'Logo'" class="mr-2 logos" [src]="croppedImage" alt="logo">
        <h1 *ngIf="instituteGroup | customRepotCard: 'Institute name'" class="institutename">{{ orgName }}</h1>
    </div>
    <div class="headerRightAddress col-lg-6 col-sm-12">
        <p *ngIf="instituteGroup | customRepotCard: 'Institute address'"><strong>Address:</strong>{{ orgAddress }}</p>
        <p>
            <strong *ngIf="instituteGroup | customRepotCard: 'Contact'" >Contact: {{ orgContact }}</strong> 
            <strong *ngIf="instituteGroup | customRepotCard: 'Email'" class="ml-3">Email: {{ orgEmail }}</strong> 
        </p>
    </div>
</div>
<div class="d-flex justify-content-between form-group student-card-report">
    <div class="studentInfo d-flex align-items-center">
        <div *ngIf="studentGroup | customRepotCard: 'Photograph'" class="studentThumb">
            <img *ngIf="studentDetails[0]?.ProfilePhoto != '' " [src]="studentDetails[0].ProfilePhoto" alt="">
            <img *ngIf="studentDetails[0]?.ProfilePhoto == '' " src="../../../../../assets/img/avtaradmin.jpg" alt="">
        </div>
        <div class="flex-grow-1 studentNameCl">
            <h4 *ngIf="studentGroup | customRepotCard: 'Name'">{{ studentDetails[0].Name }}</h4>
            <p>
                <strong *ngIf="studentGroup | customRepotCard: 'Class'">{{ studentDetails[0].ClassName }}-</strong>
                <strong *ngIf="studentGroup | customRepotCard: 'Section'">{{ studentDetails[0].SectionName }}</strong>
            </p>
            <p *ngIf="studentGroup | customRepotCard: 'Admission_no'">Admission No. {{ studentDetails[0].AdmissionNumber }}</p>
            <p *ngIf="studentGroup | customRepotCard: 'Academic session name'">Session: {{ studentDetails[0].AcademicSessionName }}</p>
            <div *ngFor="let item of studentCustomGroup">
                <p *ngIf="item | customRepotCard: 'studentCustomFields' ">
                    {{item.label}}: {{ studentDetails[0][item.fName] }}
                </p>
            </div>
            
            
        </div>
    </div>
    <div class="studentInfoAttendence">
        <table *ngIf="attendanceGroup | customRepotCard: 'Display attendance'" class="table table-bordered">
            <thead>
                <tr>
                    <th>Attendance</th>
                    <th class="center">
                      <span *ngIf="percentage"> {{percentage[0].percentage}}</span> %
                        <!-- {{percentage1[0].percentage}}% --> 
                        <!-- 0.00% -->
                    </th>
                    <!-- changes -->
                </tr>
            </thead>
            <tbody *ngFor="let item of attendanceCustomGroup">
                <tr *ngIf=" item.checked && item.aLabel != '' ">
                    <td [ngStyle]="{ color: item.colorCode }">{{ item.aLabel }}</td>
                    <td [ngStyle]="{ color: item.colorCode }" class="center">
                        <span *ngIf="attendanceGroup | customRepotCard: 'Display in numbers'">{{ item.total }}<span *ngIf="!(attendanceGroup | customRepotCard: 'Disable Max Status Count')">/{{ item.totalDays }}</span></span> <span *ngIf="item.showPercentage"> <span *ngIf="attendanceGroup | customRepotCard: 'Display in percentage'"> ({{ item.percentage }}%)</span> </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="semesterVise">
    <div *ngIf="!(assessmentsGroup | customRepotCard: 'Hide subjects scores table completely')" class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th class="center" scope="col">#</th>
                    <th class="center" scope="col">Subjects</th>
                    <th class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display subjects code'" scope="col">Subject Code</th>
                    <th class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display subjects credits'" scope="col">Credits / Hours</th>
                    <th class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display subjects enrollment status'" scope="col">Enrollment status</th>
                    <th class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display subjects teachers'" scope="col">Subjects teachers</th>
                    <th class="center" *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 2)" scope="col">Subjects comments</th>
                    <ng-container *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')">
                        <th scope="col" class="center" *ngFor="let item of assessmentName">{{ item.assessmentCategoryName }}
                            <br>
                            <div class="assm-names ass-cat-1" data-keyword="all_assessments">
                                <div *ngFor="let items of item.assessmentList" class="assm-v-name" style="width:63px;">
                                    <div class="assm-name">{{ items.assessmentName }}</div>
                                </div>
                                <div class="assm-v-name  " data-keyword="assm_cats_hidde" style="width:75px; border: none;">
                                    <div class="assm-name final-scoreed" style="left: -39px; padding: 6px 10px;">
                                        <b class="pwhite">Category Score</b>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </th>
                    </ng-container>
                    
                    <!-- <th *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')" scope="col">Category 2</th>
                    <th *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')" scope="col">Category 3</th>
                    <th *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')" scope="col">Category 4</th> -->
                    <th *ngIf="assessmentsGroup | customRepotCard: 'Display total obtained points for assessment eg: 95/100'" scope="col">Total Points</th>
                    <th *ngIf="assessmentsGroup | customRepotCard: 'Final scores'" scope="col" class="finalScore">Final Score</th>
                    <th *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'" scope="col">Grade Points</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of assessmentDetails">
                    <td scope="row">{{ item.RowNumber }}</td>
                    <td class="center">{{ item.SubjectName }}</td>
                    <td class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display subjects code'">
                        <div class="text-center">{{ item.SubjectCode }}</div>
                    </td>
                    <td class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display subjects credits'">
                        <div class="text-center">{{ item.SubjectCredit }}</div>
                    </td>
                    <td class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display subjects enrollment status'">
                        <div class="text-center enrolmentstatus">{{ item.SubjecttEnrollmentStatusName }}</div>
                    </td>
                    <td class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display subjects teachers'">
                        <div class="text-center">{{ item.TeacherName }}</div>
                    </td>
                    <td class="center" *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 2)"></td>
                    <ng-container *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')">
                        <td class="center" *ngFor="let items of assessmentName">
                            <div class="assm-score" *ngFor="let itemss of items.assessmentList">
                                <div class="subject-name-height-block subject-height-1">{{ item[itemss.assessmentName] }} / {{ itemss.points }}</div>
                            </div>
                            <div class="score-bg pull-left">
                                <b class="pwhite">{{ item['cat' + items.assessmentCategoryID] }}%</b>
                            </div>
                        </td>
                    </ng-container>
                    <td class="center" *ngIf="assessmentsGroup | customRepotCard: 'Display total obtained points for assessment eg: 95/100'">{{ item.score }}/ {{ item.totalScore }}</td>
                    <td *ngIf="assessmentsGroup | customRepotCard: 'Final scores'" class="finalScore">{{ item.finalScore }}%</td>
                    <td *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'">-</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'" class="text-right flex-end">
    <div class="smalltable">
        <p class="mb-1 mt-3">Earned Grade Points: 0.00</p>
        <p class="mb-1 mt-2">Total Credits / Hours: 0.00</p>
        <p class="mb-1 mt-2">GPA: 0.00</p>
    </div>

</div>

<div *ngIf="assessmentsGroup | customRepotCard: 'Rubric scores'" class="block-border extra-block-sm" data-keyword="rubric_scores">
    <h2 class="mb-4 text-center template-group-title ">Rubric Scores</h2>
    <div class="justify-content-center row text-center">
        <div *ngFor="let item of rubicDetails" class="col-md-4 mb10">
            <h6 class="strong">{{ item.rubicList[0].assessmentCategoryName }}</h6>
            <h6 class="strong">{{ item.subjectName }} - {{ item.assessmentName }}</h6>
            <div *ngIf="(assessmentsGroup | customRepotCard: 'Display Primary teachers name in rubric scores table') || (assessmentsGroup | customRepotCard: 'Display Secondary teachers name in rubric scores table')" class="" data-keyword="rubric_scores_teacher"
                style="font-size: 12px;margin-bottom: 5px;">Teachers Name: {{ item.teacherName }}</div>
            <table class="table table-bordered mt-2 table-striped mb10">
                <thead>
                    <tr>
                        <th class="center" style="width:50% !important;">Criteria</th>
                        <th class="center" style="width:50% !important;">Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let criteria of item.rubicList">
                        <td class="center">{{ criteria.criteriaTitle }}</td>
                        <td class="center">{{ criteria.attributeName }} <span *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class=""
                                data-keyword="hide_rubric_points">[ {{ criteria.attributeValue }} ]</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div *ngIf="othersGroup | customRepotCard: 'Grading structure for final score'" class="semesterVise fulltable">
    <h5 class="template-group-title">Grading structure for final score</h5>
</div>
<div *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 1)" class="semesterVise fulltable">
    <h5 class="template-group-title">Comments</h5>
</div>

<div *ngIf="othersGroup | customRepotCard: 'Contents block'" class="semesterVise fulltable">
    <h5 class="template-group-title">{{ editorModel.contentCommentTitle }}</h5>
    <div [innerHTML]="editorModel.contentCommentValue">
    </div>
</div>
<div *ngIf="othersGroup | customRepotCard: 'Signature block'" class="semesterVise fulltable">
    <h5 class="template-group-title">{{ editorModel.signatureCommentTitle }}</h5>
    <div [innerHTML]="editorModel.signatureCommentValue">
    </div>
</div>