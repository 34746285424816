import { Component, OnInit, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StudentService } from 'src/app/services/student.service';
import { ClassService } from '../../courses-and-subjects/services/classServices/class.service';
import { SectionService } from '../../courses-and-subjects/services/sectionServices/section.service';
import { SubjectService } from '../../courses-and-subjects/services/subjectServices/subject.service';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { SectionDropdownService } from 'src/app/masterServices/sectionDropdownService/section-dropdown.service';
import { DepartmentService } from '../../courses-and-subjects/services/department.service';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/services/master.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ManageAttendanceService } from 'src/app/services/manage-attendance.service';
import { filter, first } from 'rxjs/operators';
import { CommonService } from 'src/app/common/core-services/common.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { Metadata } from 'src/app/common/MasterModel/masterModel';
import { DialogService } from 'src/app/services/dialog.service';
@Component({
  selector: 'app-manage-student-attendance',
  templateUrl: './manage-student-attendance.component.html',
  styleUrls: ['./manage-student-attendance.component.css']
})
export class ManageStudentAttendanceComponent implements OnInit {

  classListDropdown: any;
  academicSessions: any;
  departmentListDropdown: any;

  stuAttendanceForm: FormGroup;
  permissions: any;
  markAttendanceForm: FormGroup;
  columnsToDisplay: string[] = ['changes', 'createdDate', 'attendanceMessage'];
  dataSourceAttendance: MatTableDataSource<any>;
  Lstatus: any[] = [];
  selectedClass: string = "";
  selectedSection: string = "";
  pageNumber: any = 1;
  pageSize: any = 10;
  users: any;
  user: any;
  metaData: any
  academicSessionID: any;
  dateFormat: any
  @ViewChild(MatPaginator) paginator: MatPaginator;
  previousClassVal: any = [];
  previousSectionVal: any = [];
  previousSubjectVal: any = [];
  constructor(
    private studentService: StudentService,
    private classService: ClassService,
    private sectionService: SectionService,
    private subjectService: SubjectService,
    private classDropdownService: ClassDropdownService,
    private sectionDropdownService: SectionDropdownService,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private masterService: MasterService,
    private fb: FormBuilder,
    private attendanceService: ManageAttendanceService, private ngZone: NgZone,
    private commonService: CommonService,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private dialogService: DialogService
  ) {
    document.addEventListener('keydown', e => {
      if ((e.target as any).nodeName === 'MAT-SELECT') {
        e.stopImmediatePropagation();
        if (e.key == 'SPACE') {
          return;
        }
      }
      if ((e.target as any).nodeName === 'INPUT') {
        e.stopImmediatePropagation();
      }
    }, true);
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
    if (currentUser != null) {
      this.user = currentUser;
      this.dateFormat = currentUser.dateFormat;
    }
    //debugger;
    // let data = this.commonService.getAttSubmissionData();
    // if (data != null && data != undefined) {
    //   this.sectionService.GetSectionByClassId(data.classID).subscribe((res: any) => {
    //     this.sectionListDropdown = [];
    //     if (res.responseData) {
    //       this.sectionListDropdown = res.responseData;
    //       this.selectedSection = this.sectionListDropdown.find((x: any) => x.sectionID == data.sectionID).sectionName;
    //      // this.stuAttendanceForm.controls['SubjetID'].setValue('');
    //       this.subjectService.GetSubjectBySectionId(data.sectionID).subscribe((res: any) => {
    //         this.subjectList = [];
    //         if (res.responseData) {
    //           this.subjectList = res.responseData
    //         }
    //       });
    //     }
    //   });
    // }
  }
  ngOnInit(): void {


    this.metaData = new Metadata();
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Manage Attendance');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    // this.GetAllStudentList();
    this.GetAllSubjectList();
    this.getMasterDropdown();
    this.curr = new Date();
    this.getExactDate();
    this.getAllAttendanceStatus();
    this.initForm();
    this.getAttSubmissionAnalyticsValue();

  }

  onKeyFilter(val: any, type: string) {
    let serachTxt = String(val.target.value);
    if (type == 'class') {
      if (serachTxt != '') {
        this.classListDropdown = this.previousClassVal.filter((x: any) => x.className.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.classListDropdown = this.previousClassVal;
      }
    } else if (type == 'section') {
      if (serachTxt != '') {
        this.sectionListDropdown = this.previousSectionVal.filter((x: any) => x.sectionName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.sectionListDropdown = this.previousSectionVal;
      }
    } else if (type == 'subject') {
      if (serachTxt != '') {
        this.subjectList = this.previousSubjectVal.filter((x: any) => x.subjectName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.subjectList = this.previousSubjectVal;
      }
    }


  }

  dispalyFns(classId: any) {
    return this.classDropDown.filter((a: any) => a.classID == classId)[0]?.className;
  }
  // sectionListDropdown
  dispalyFnsSection(sectionId: any) {
    return this.sectionListDropdown.filter((a: any) => a.sectionID == sectionId)[0]?.sectionName;
  }

  dispalyFnsSubject(subjectId: any) {
    return this.subjectListsValue.filter((a: any) => a.subjectID == subjectId)[0]?.subjectName;
  }






  checkActionPermission(actionName: any) {
    let screen = 'Manage Attendance';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  analyticDta: any;
  getAttSubmissionAnalyticsValue() {
    debugger
    let data = this.commonService.getAttSubmissionData();
    this.analyticDta = data;
    if (data != null && data != undefined) {
      this.sectionService.GetSectionByClassId(data.classID).subscribe((res: any) => {
        this.sectionListDropdown = [];
        if (res.responseData) {
          this.sectionListDropdown = res.responseData;
          this.previousSectionVal = Object.assign([], this.sectionListDropdown);
          this.selectedSection = this.sectionListDropdown.find((x: any) => x.sectionID == data.sectionID).sectionName;
          this.subjectService.GetSubjectBySectionId(data.sectionID).subscribe((res: any) => {
            this.subjectList = [];
            if (res.responseData) {
              this.subjectList = res.responseData
              this.subjectListsValue = [...this.subjectList];
              this.previousSubjectVal = Object.assign([], this.subjectList);
              this.stuAttendanceForm.patchValue({
                ClassID: data.classID,
                SectionID: data.sectionID,
                SubjetID: data.subjectID,
                Date: new Date(data.Date),
              });
              this.curr = new Date(data.Date)
              if (data.keyy == 'autoclick') {
                this.getExactDate();
                this.getStudentsAttendanceByDate();
                this.commonService.setAttSubmissionData(null);
              }
            }
          });
        }
      });
    } else {
      this.getExactDate();
    }
  }

  initForm() {
    this.stuAttendanceForm = this.fb.group({
      ClassID: ['', Validators.required],
      SectionID: ['', Validators.required],
      SubjetID: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      Date: ['', Validators.required],
      academicSessionID: this.academicSessionID
    });

    this.markAttendanceForm = this.fb.group({
      AttendanceStatusMasterID: [''],
      StudentID: [''],
      Date: [''],
      ClassID: [''],
      SectionID: [''],
      SubjetID: [''],
      SessionMasterID: ['']
    })
  }

  collapseMonFlag = false;
  collapseTueFlag = false;
  collapseWedFlag = false;
  collapseThuFlag = false;
  collapseFriFlag = false;
  collapseSatFlag = false;
  collapseSunFlag = false;

  sectionListDropdown: any[] = [];

  collapseSentNoti = false;

  // studentList: any;
  classList: any;
  sectionList: any;
  subjectList: any[] = [];

  // #region Prev Next Date Logic Variables
  curr: Date;
  startDate: string;
  endDate: string;
  CurrentWeekSlot: string;
  sD: Date;
  eD: Date;
  currNew: Date;

  WeekDates: any = {
    MonDate: '',
    TuesDate: '',
    WedDate: '',
    ThursDate: '',
    FriDate: '',
    SatDate: '',
    SunDate: '',
  }
  //#endregion

  TotalStudentsFound: number = 0;
  AttendanceStatusList: any;
  attendanceStatus: any
  colorCode: any

  getAllAttendanceStatus() {
    this.attendanceService.GetAllAttendanceStatus().subscribe((res: any) => {
      if (res.responseData) {
        this.attendanceStatus = res.responseData;
        let defaultStatuses = res.responseData.filter((x: any) => x.isDefault == true);
        let nonDefaultStatuses = res.responseData.filter((x: any) => x.isDefault == false);
        this.AttendanceStatusList = [...defaultStatuses, ...nonDefaultStatuses];
        console.log("this.AttedanceStatusList", this.AttendanceStatusList)
        this.colorCode = this.AttendanceStatusList.colorCode
      }
    })
  }
  GetAllSubjectList() {
    this.subjectService.AllSubject().subscribe((res: any) => {
      //this.subjectList = res.responseData
    })
  }
  sectionListsValue: any = [];
  selectSectionByClassID(event: any) {
    console.log(event);
    this.selectedClass = this.classListDropdown.find((x: any) => x.classID == event.value).className;

    this.stuAttendanceForm.controls['SectionID'].setValue('');
    this.stuAttendanceForm.controls['SubjetID'].setValue('');
    this.sectionService.GetSectionByClassId(event.value).subscribe((res: any) => {
      this.sectionListDropdown = [];
      if (res.responseData) {
        this.sectionListDropdown = res.responseData
        this.sectionListDropdown = [...this.sectionListDropdown];
        this.previousSectionVal = Object.assign([], this.sectionListDropdown);
        console.log("this.sectionListDropdown", this.sectionListDropdown)
      }
      // this.GetSubjectBySectionId(event.value);
    });
  }
  subjectListsValue: any = [];
  GetSubjectBySectionId(event: any) {
    this.selectedSection = this.sectionListDropdown.find((x: any) => x.sectionID == event.value).sectionName;
    this.stuAttendanceForm.controls['SubjetID'].setValue('');
    this.subjectService.GetSubjectBySectionId(event.value).subscribe((res: any) => {
      this.subjectList = [];
      if (res.responseData) {
        this.subjectList = res.responseData
        this.subjectListsValue = [...this.subjectList];
        this.previousSubjectVal = Object.assign([], this.subjectList);
        console.log("this.sectionListDropdown", this.sectionListDropdown)
      }
    });
  }
  classDropDown: any = [];
  getMasterDropdown() {
    // this.sectionDropdownService.SectionDropdown().subscribe((res: any) => {
    //   this.sectionListDropdown = res.responseData
    // })
    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classListDropdown = res.responseData
      this.classDropDown = [...this.classListDropdown];
      this.previousClassVal = Object.assign([], this.classListDropdown);
    });

    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      this.academicSessions = res.responseData;
    });
    this.departmentService.DepartmentDropdown().subscribe((res: any) => {
      this.departmentListDropdown = res.responseData;
    });
  }

  onDateChange(event: any) {
    this.curr = event.target.value;
    this.getExactDate();
  }

  prevWeek() {
    this.curr = new Date(this.curr.setMonth(this.sD.getMonth()));
    this.curr = new Date(this.curr.setDate(this.sD.getDate() - 1));
    this.getExactDate();
    this.getStudentsAttendanceByDate();
  }
  nextWeek() {
    this.curr = new Date(this.curr.setMonth(this.eD.getMonth()));
    this.curr = new Date(this.curr.setDate(this.eD.getDate() + 1));
    this.getExactDate();
    this.getStudentsAttendanceByDate();
  }

  getExactDate() {
    let first = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
    if (first <= 0) {
      this.currNew = new Date(this.curr.setMonth(this.curr.getMonth()));
    }
    let last = first + 6; // last day is the first day + 6
    this.sD = new Date(this.curr.setDate(first));
    this.startDate = this.sD.toLocaleString();
    if (first <= 0) {
      this.curr = this.currNew;
    }
    this.eD = new Date(this.curr.setDate(last));
    this.endDate = this.eD.toLocaleString();

    this.WeekDates = {
      MonDate: moment(this.sD).add(1, 'day').format('DD MMM'),
      TuesDate: moment(this.sD).add(2, 'day').format('DD MMM'),
      WedDate: moment(this.sD).add(3, 'day').format('DD MMM'),
      ThursDate: moment(this.sD).add(4, 'day').format('DD MMM'),
      FriDate: moment(this.sD).add(5, 'day').format('DD MMM'),
      SatDate: moment(this.sD).add(6, 'day').format('DD MMM'),
      SunDate: moment(this.sD).add(7, 'day').format('DD MMM'),
    }
    if (first <= 0) {
      this.curr = new Date(this.sD);
    }
  }

  STUDENT_DATA: PeriodicElement[];
  displayedColumns: string[] = ['admissionNo', 'firstName', 'lastName', 'enrollmentStatus', 'className', 'sectionName', 'subjectName', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  dataSource: any;
  OdataSource: any;
  getAttendanceLog() {

    var data = {
      "ClassID": this.stuAttendanceForm.controls['ClassID'].value,
      "SectionID": this.stuAttendanceForm.controls['SectionID'].value,
      "SubjectID": this.stuAttendanceForm.controls['SubjetID'].value,
      "PageNumber": this.pageNumber,
      "PageSize": this.pageSize
    }
    this.studentService.GetAttendanceLog(data).subscribe((res: any) => {
      this.dataSourceAttendance = res.responseData;
      this.metaData = res.meta;
    })
  }
  public handlePage(e: any) {
    this.pageNumber = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.getAttendanceLog();
  }
  activityLog: any;
  ActivityLogTrack() {
    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Students',
        ActivityTypeId: 10,
        Description: 'Student Attendance record viewed by',
        ScreenName: 'Manage Attendance',
        RecordTypeId: 4,
        RecordId: 0,
        Type: 0
      }
    }

    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }


  getStudentsAttendanceByDate() {
    this.ActivityLogTrack();
    this.dataSource = [];
    this.stuAttendanceForm.patchValue({
      StartDate: moment(this.sD).add(1, 'day').format('YYYY-MM-DD'),
      EndDate: moment(this.sD).add(7, 'day').format('YYYY-MM-DD'),
    })
    if (this.stuAttendanceForm.valid) {
      this.getAttendanceLog();
      this.studentService.GetStudentAttendanceByDate(this.stuAttendanceForm.value).subscribe((res: any) => {
        if (res.responseData) {
          this.STUDENT_DATA = res.responseData;
          let className = this.classListDropdown.find((x: any) => x.classID == this.stuAttendanceForm.controls['ClassID'].value);

          let sectionName = this.sectionListDropdown.find((x: any) => x.sectionID == this.stuAttendanceForm.controls['SectionID'].value);
          let subjectName = this.subjectList.find((x: any) => x.subjectID == this.stuAttendanceForm.controls['SubjetID'].value)
          //this.classListDropdown.find((x: any) => x.classID == event.value).className;

          //alert(className)
          this.STUDENT_DATA.forEach(element => {
            element.className = className?.className;
            element.sectionName = sectionName?.sectionName;
            element.subjectName = subjectName?.subjectName
          });


          //this.stuAttendanceForm.controls['ClassID'].
          //let SectionName=this.stuAttendanceForm.controls['ClassID'].value
          this.dataSource = this.STUDENT_DATA;
          this.OdataSource = [];
          this.ngZone.onStable.pipe(first()).subscribe(() => {
            this.setInitialStatuses();
          });
          this.TotalStudentsFound = this.STUDENT_DATA.length;
        }
      })
    } else {
      this.stuAttendanceForm.markAllAsTouched();
      this.toaster.error("Please validate Details.");
    }
  }


  setInitialStatuses(): void {
    for (let i = 0; i < this.dataSource.length; i++) {
      let days = Object.keys(this.dataSource[i]).filter(x => x.includes('day'));
      let temp: any = Object.keys(this.dataSource[i]).filter(x => x.includes('day')).
        reduce((cur, key) => { return Object.assign(cur, { [key]: this.dataSource[i][key] }) }, {})
      for (let j = 0; j < days.length; j++) {
        this.changeAttStatusOnClick(temp[days[j]], this.dataSource[i].studentID, days[j], false);
      }
    }
  }

  setStudentAttendanceStatus(id: any, element: any) {
    if (id == 0) {
      id = this.AttendanceStatusList.find((x: any) => x.isDefault == true && x.statusSortCode == '').attendanceStatusMasterID;
    }
    element["innerHTML"] = this.AttendanceStatusList.find((x: any) => x.attendanceStatusMasterID == id).statusSortCode;
    element.style.backgroundColor = this.AttendanceStatusList.find((x: any) => x.attendanceStatusMasterID == id).colorCode;
  }

  changeAttStatusOnClick(statusId: any, studentID: any, day: string, isChangingStatus: boolean) {
    let element: any = document.getElementById("col" + studentID + day);
    if (!isChangingStatus) {
      this.setStudentAttendanceStatus(statusId, element);
    }
    if (isChangingStatus) {
      // const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
      // const d = new Date(String(this.analyticDta?.Date));
      // let submittedDay = weekday[d.getDay()];
      // if(this.analyticDta?.IsSubmitted && String(submittedDay).toLocaleLowerCase() === String(day).toLocaleLowerCase()){
      // this.dialogService.confirm(`Are you sure you want to update submitted attendance status?`).subscribe((result: any) => {
      // if (result) {
      this.AttendanceStatusList = this.AttendanceStatusList.sort((a: any, b: any) =>
        (a.attendanceStatusMasterID > b.attendanceStatusMasterID ? 1 : -1)).sort();
      for (let i = 0; i < this.AttendanceStatusList.length; i++) {
        if (statusId == 0) {
          statusId++;
          break;
        }
        if (this.AttendanceStatusList[i].attendanceStatusMasterID == statusId) {
          if (i < this.AttendanceStatusList.length - 1) {
            statusId = this.AttendanceStatusList[i + 1].attendanceStatusMasterID;
            break;
          }
          else {
            statusId = this.AttendanceStatusList[0].attendanceStatusMasterID;
            break;
          }
        }
      }
      let stu: any = this.STUDENT_DATA.find((x: any) => x.studentID == studentID);
      this.setStudentAttendanceStatus(statusId, element);
      stu[day] = statusId;
      // }
      // });

      // } else{
      //   this.AttendanceStatusList = this.AttendanceStatusList.sort((a: any, b: any) =>
      //     (a.attendanceStatusMasterID > b.attendanceStatusMasterID ? 1 : -1)).sort();
      //   for (let i = 0; i < this.AttendanceStatusList.length; i++) {
      //     if (statusId == 0) {
      //       statusId++;
      //       break;
      //     }
      //     if (this.AttendanceStatusList[i].attendanceStatusMasterID == statusId) {
      //       if (i < this.AttendanceStatusList.length - 1) {
      //         statusId = this.AttendanceStatusList[i + 1].attendanceStatusMasterID;
      //         break;
      //       }
      //       else {
      //         statusId = this.AttendanceStatusList[0].attendanceStatusMasterID;
      //         break;
      //       }
      //     }
      //   }
      //   let stu: any = this.STUDENT_DATA.find((x: any) => x.studentID == studentID);
      //   this.setStudentAttendanceStatus(statusId, element);
      //   stu[day] = statusId;
      // }
      // console.table(this.STUDENT_DATA);
    }
  }


  dayWiseAttendance = {
    Mon: 'Mon',
    Tue: 'Tues',
    Wed: 'Wed',
    Thurs: 'Thurs',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    Holiday: 'holiday'
  }



  setWholeClassAttStatus(attStatus: any, day: string) {
    //debugger;
    let holidayStatus = this.AttendanceStatusList.find((x: any) => x.statusName == 'Holiday')?.attendanceStatusMasterID;
    this.STUDENT_DATA.map((x: any) => {
      if (day == this.dayWiseAttendance.Mon) {
        if (this.isMarkAllSelected) {
          x['monday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['monday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.monday, x.studentID, 'monday', false);
      }
      if (day == this.dayWiseAttendance.Tue) {
        if (this.isMarkAllSelected) {
          x['tuesday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['tuesday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.tuesday, x.studentID, 'tuesday', false);
      }
      if (day == this.dayWiseAttendance.Wed) {
        if (this.isMarkAllSelected) {
          x['wednesday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['wednesday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.wednesday, x.studentID, 'wednesday', false);
      }
      if (day == this.dayWiseAttendance.Thurs) {
        if (this.isMarkAllSelected) {
          x['thursday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['thursday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.thursday, x.studentID, 'thursday', false);
      }
      if (day == this.dayWiseAttendance.Fri) {
        if (this.isMarkAllSelected) {
          x['friday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['friday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.friday, x.studentID, 'friday', false);
      }
      if (day == this.dayWiseAttendance.Sat) {
        if (this.isMarkAllSelected) {
          x['saturday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['saturday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.saturday, x.studentID, 'saturday', false);
      }
      if (day == this.dayWiseAttendance.Sun) {
        if (this.isMarkAllSelected) {
          x['sunday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['sunday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.sunday, x.studentID, 'sunday', false);
      }
    })
  }

  saveStudentAttendance() {
    let data = {
      "studentAttendanceData": this.STUDENT_DATA, "ToParents": this.ToParents,
      "ToStudents": this.ToStudents, "ViaEmail": this.ViaEmail, "ViaSMS": this.ViaSMS, "ForMonday": this.ForMonday,
      "ForTuesday": this.ForTuesday, "ForWednesday": this.ForWednesday, "ForThursday": this.ForThursday, "ForFriday": this.ForFriday,
      "ForSaturday": this.ForSaturday, "ForSunday": this.ForSunday, "academicSessionID": this.academicSessionID
    };
    this.studentService.saveStudentAttendance(data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.toaster.success(res.message);
        this.getStudentsAttendanceByDate()
      }
    })
  }

  isMarkAllSelected: boolean = false;
  isHolidaySelected: boolean = false;

  markAll(event: any) {
    this.isMarkAllSelected = event;
  }
  markHoliday(event: any, day: string) {
    this.isHolidaySelected = event;
    this.setWholeClassAttStatus('holiday', day);
  }

  collapseMon() {
    this.collapseMonFlag = !this.collapseMonFlag;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;
    this.collapseSunFlag = false;
  }

  collapseTue() {
    this.collapseTueFlag = !this.collapseTueFlag;
    this.collapseMonFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;
    this.collapseSunFlag = false;
  }

  collapseWed() {
    this.collapseWedFlag = !this.collapseWedFlag;
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;
    this.collapseSunFlag = false;
  }

  collapseThu() {
    this.collapseThuFlag = !this.collapseThuFlag;
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;
    this.collapseSunFlag = false;
  }

  collapseFri() {
    this.collapseFriFlag = !this.collapseFriFlag;
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseSatFlag = false;
    this.collapseSunFlag = false;
  }

  collapseSat() {
    this.collapseSatFlag = !this.collapseSatFlag;
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSunFlag = false;
  }

  collapseSun() {
    this.collapseSunFlag = !this.collapseSunFlag;
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;
  }

  collapseNoti() {
    this.collapseSentNoti = !this.collapseSentNoti;
  }

  @ViewChild('select')
  select!: MatSelect;

  allSelected = false;
  allSelected1 = false;

  Status: any[] = [
    { value: 'upcoming', viewValue: 'Upcoming' },
    { value: 'inprogress', viewValue: 'In Progress' },
    { value: 'completed', viewValue: 'Completed' },
    { value: 'incomplete', viewValue: 'Incomplete' },
    { value: 'withdrawn', viewValue: 'Withdrawn' },
    { value: 'notset', viewValue: 'Not Set' },
  ];
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  monAbsent: any[] = [];
  tueAbsent: any[] = [];
  webAbsent: any[] = [];
  thurAbsent: any[] = [];
  friAbsent: any[] = [];
  satAbsent: any[] = [];
  sunAbsent: any[] = [];
  totalAbsent: any[] = [];
  //Added For Absent Notification
  ToParents: boolean = false;
  ToStudents: boolean = false;
  ViaEmail: boolean = false;
  ViaSMS: boolean = false;
  ForMonday: boolean = false;
  ForTuesday: boolean = false;
  ForWednesday: boolean = false;
  ForThursday: boolean = false;
  ForFriday: boolean = false;
  ForSaturday: boolean = false;
  ForSunday: boolean = false
  show: boolean = false;
  showForSMS: boolean = false;
  AbsentStudent() {
    for (let i = 0; i < this.STUDENT_DATA.length; i++) {
      if (this.STUDENT_DATA[i].monday == '2') { this.monAbsent.push(this.STUDENT_DATA[i]) }
      if (this.STUDENT_DATA[i].tuesday == '2') { this.tueAbsent.push(this.STUDENT_DATA[i]) }
      if (this.STUDENT_DATA[i].wednesday == '2') { this.webAbsent.push(this.STUDENT_DATA[i]) }
      if (this.STUDENT_DATA[i].thursday == '2') { this.thurAbsent.push(this.STUDENT_DATA[i]) }
      if (this.STUDENT_DATA[i].friday == '2') { this.friAbsent.push(this.STUDENT_DATA[i]) }
      if (this.STUDENT_DATA[i].saturday == '2') { this.satAbsent.push(this.STUDENT_DATA[i]) }
      if (this.STUDENT_DATA[i].sunday == '2') { this.sunAbsent.push(this.STUDENT_DATA[i]) }
    }
  }
  NotificationViaEmail() {
    this.AbsentStudent();
    this.show = !this.show;
    this.ViaEmail = !this.ViaEmail;
  }
  NotificationViaSMS() {
    this.AbsentStudent();
    this.showForSMS = !this.showForSMS;
    this.ViaSMS = !this.ViaSMS;

  }

  mondayAbsent() { this.ForMonday = !this.ForMonday }
  tuesdayAbsent() { this.ForTuesday = !this.ForTuesday }
  NotificationToStudent() { this.ToStudents = !this.ToStudents }
  wednesdayAbsent() { this.ForWednesday = !this.ForWednesday }
  thursdayAbsent() { this.ForThursday = !this.ForThursday }
  fridayAbsent() { this.ForFriday = !this.ForFriday }
  saturdayAbsent() { this.ForSaturday = !this.ForSaturday }
  sundayAbsent() { this.ForSunday = !this.ForSunday }
  NotificationToParent() { this.ToParents = !this.ToParents }

  statusChange(event: any) {
    debugger
    if (!this.OdataSource || (this.OdataSource && this.OdataSource.length == 0)) {
      this.OdataSource = [];
      this.OdataSource = JSON.parse(JSON.stringify(this.dataSource));
    }

    if (this.Lstatus && this.Lstatus.length > 0) {
      let data = this.OdataSource.filter((x: any) => this.Lstatus.some(z => z == x.studentEnrollmentStatusName))////
      this.dataSource = JSON.parse(JSON.stringify(data));
    } else {
      this.dataSource = JSON.parse(JSON.stringify(this.OdataSource));
    }
    this.ngZone.onStable.pipe(first()).subscribe(() => {
      this.setInitialStatuses();
    });
    this.TotalStudentsFound = this.STUDENT_DATA.length;
  }
  showlogshide: boolean = false;
  showlogs() {
    this.showlogshide = !this.showlogshide;
  }
}

export interface PeriodicElement {
  admissionNo: number;
  firstName: string;
  lastName: string;
  enrollmentStatus: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
  className: string;
  sectionName: string;
  subjectName: string;
}

