import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ManageGradeComponent } from 'src/app/app-layout/content/academics-and-learning/assesment-and-gradings/manage-grade/manage-grade.component';
import { ManageAssessmentAndGradingsService } from 'src/app/app-layout/content/academics-and-learning/assesment-and-gradings/services/manage-assessment-and-gradings.service';
import { ImportExportDataComponent } from 'src/app/app-layout/content/settings/organization-settings/import-export-data/import-export-data.component';
import { FormInputBase, ModuleDefinition } from '../../MasterModel/masterModel';
import { ImportGradeBookStatusComponent } from '../import-grade-book-status/import-grade-book-status.component';
import { ImportStatusReportLinkComponent } from '../import-status-report-link/import-status-report-link.component';


@Component({
  selector: 'app-mapping-container-student-grade',
  templateUrl: './mapping-container-student-grade.component.html',
  styleUrls: ['./mapping-container-student-grade.component.css']
})
export class MappingContainerStudentGradeComponent implements OnInit {
  @Input() dynamicInputControls: FormInputBase<string>[] = [];
  payLoad={};
  @Input() uploadedFile: File;
  @Input() caller: string; 
  statusLink: string;
  @Input() form: FormGroup;
  @Input() excelheaders: string[];
  public unique_key: number;
  parentRef: ManageGradeComponent;
  responseMessage: string='';
  responseSent: boolean=false;
  @Input() academicSessionId: Number;
  @Input() classId: Number;
  @Input() sectionId: Number;
  @Input() subjectId: Number;
  failedCount=0;
  totalCount=0;
  dialogRef: MatDialogRef<ImportGradeBookStatusComponent, any>;
  constructor(private gradeBookService: ManageAssessmentAndGradingsService,private toaster: ToastrService, private dialog: MatDialog,) { }



  ngOnInit(): void {
    this.form =this.toFormGroup(this.dynamicInputControls);
    console.log(this.form);
    console.log(this.excelheaders);
  }

  ngAfterContentChecked() {
    
     }

     
     
  

  toFormGroup(controls: FormInputBase<string>[]) {
    const group: any = {};
    controls.forEach((control) => {
      group[control.key] = control.required
        ? new FormControl('', Validators.required)
        : new FormControl('');
    });
    return new FormGroup(group);
  }

  startAutoMatching(){

    Object.keys(this.form.controls).forEach(key => {
       this.form.controls[key].setValue(key);
   });
  }

  transformPayloadForImport(payload: any){
    const mappings: {key: string, value: string}[]= [];
    const res = {};
    Object.keys(payload).forEach(key => {
      mappings.push({key: key, value:payload[key]?.key || 'Not Mapped'});
    });
    mappings.forEach(({ key, value }) => Object.assign(res, { [key]: value }));

    return res;
  }
  openStatusResponseDialog(ImportProcessId:number,failedCount: number,totalRecords: number,message: string,caller: string,showStats: boolean = true){
     this.dialogRef = this.dialog.open(ImportGradeBookStatusComponent, {
      data: {
        dataKey: {
          message: message,
          link: ImportProcessId,
          caller: caller,
          failedCount: failedCount,
          totalCount: totalRecords,
          showStats: showStats,
          parentRef: this
        },
      }, 
      disableClose:true,
      width: "60vw",
    });
  }
  onSubmit(){
  
    //this.payLoad =this.transformPayloadForImport(this.form.getRawValue());
    this.payLoad = this.form.getRawValue();

    if(this.uploadedFile){

    const formData: FormData = new FormData();
    formData.append('file',this.uploadedFile);
    formData.append('mapping',JSON.stringify(this.payLoad));
    formData.append('AcademicSessionId',String(this.academicSessionId));
    formData.append('ClassId',String(this.classId));
    formData.append('SectionId',String(this.sectionId));
    formData.append('SubjectId',String(this.subjectId));


      this.gradeBookService.ImportExcelFile(formData).subscribe({
        next: (event: any)=>{
              if(event.responseData) {
                if (event.responseData.message === 'Success with errors')
                {
                  this.openStatusResponseDialog(event.responseData.importProcessId,event.responseData.failedCount,event.responseData.totalRecords,"The data has been imported with a few errors. You can download the report from the link provided",ModuleDefinition.Student);
                }
                else if(event.responseData.message === 'Error'){
                  this.openStatusResponseDialog(0,event.responseData.failedCount,event.responseData.totalRecords,"There's an error processing your request. Please contact administrator for further assistance",ModuleDefinition.Student,false);
                }
                else
                {
                  this.openStatusResponseDialog(event.responseData.importProcessId,event.responseData.failedCount,event.responseData.totalRecords,"The data has been imported successfully",ModuleDefinition.Student);
                }
              }
        },
        error: (event: any)=>{
          this.openStatusResponseDialog(0,event.responseData.failedCount,event.responseData.totalRecords,"There's an error processing your request. Please contact administrator for further assistance",ModuleDefinition.Student,false);
        },
      });
      
    }
  
      
  }
}
