import { Component, Input, OnChanges, ViewChild, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import { merge } from 'rxjs';
import { Meta, Metadata } from 'src/app/common/MasterModel/masterModel';
import {  UserModel } from '../student/manage-students/UserModel';

@Component({
  selector: 'app-checkboxdata-table',
  templateUrl: './checkboxdata-table.component.html',
  styleUrls: ['./checkboxdata-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxdataTableComponent implements OnInit {

  @Input() inputSource: any[];
  @Input() inputColumns: any[];
  @Input() inputMeta: any;
  @Input() inputButtons:any[]
  @Output() onChange = new EventEmitter();
  @Output() onTableActionClick = new EventEmitter();
  @Output() onselect = new EventEmitter();
  selection = new SelectionModel<UserModel>(true, []);
  displayedColumns: Array<any>;
  extraColumns: Array<any>;
  selectColumns: Array<any>;
  columnsToDisplay: Array<any>;
  dataSource: MatTableDataSource<any>;
  metaData = new  Metadata();// = new Metadata();
  actionButton:Array<any>;
  meta = new Meta();
  isLoadingResults = true;
  selectedId:any[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    // If the user changes the sort order, reset back to the first page.
    if(true){
        
    }
   
  }
  ngAfterViewInit(): void {
    
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
          .subscribe(() => {
            this.isLoadingResults = true;
    
            const changeState = {
              sort: this.sort.active || '' ,
              order: this.sort.direction || '',
              pageNumber: (this.paginator.pageIndex + 1),
              pageSize:this.paginator.pageSize
            }

            console.log("THSI>PAGG",changeState)
            this.onChange.emit(changeState);
          })
    //this.dataSource.sort = this.sort;	
  }
  itemperPage:number[] = [];
  ngOnChanges(value: any) {
    if (value.inputColumns) {
      this.displayedColumns = (value.inputColumns.currentValue || []).filter((x:any) => x.key != 'Actions');
      if(this.displayedColumns.length !=0){
        for(var i =0 ;i<this.displayedColumns.length;i++){
          if(this.displayedColumns[i].key=='select'){
            this.displayedColumns.splice(i, 1);
          }
        }
      }
      let changedColumns = (this.displayedColumns || []).map(obj => obj.key);
      changedColumns.splice(0, 0, "select");
      //if(value.inputButtons!=null && value.inputButtons.currentValue.length>0)
        changedColumns.push('Actions')
        console.log("change",changedColumns)
        this.columnsToDisplay = changedColumns;
        this.extraColumns = (value.inputColumns.currentValue || []).filter((x:any) => x.key == 'Actions');
        console.log("Extracolumn",this.extraColumns)
    }
    if (value.inputSource) {
      this.isLoadingResults = false;
      this.dataSource = new MatTableDataSource(value.inputSource.currentValue);
      
    }
    if (value.inputMeta) {
      this.metaData = value.inputMeta.currentValue || new Meta();
      console.log("metetetet",this.metaData)
      var totalcount = this.metaData.totalRecords / 500 ;
        if(totalcount > 1){
          let value = Math.round(totalcount) + 1
          this.itemperPage = [10,100,200,500,value*500]
        }
        else{
          this.itemperPage = [10,100,200,500]
        }
    }
    if (value.inputButtons) {
      this.actionButton = value.inputButtons.currentValue || [];
    }
  }
  onToggleClick(action: string, data: any,column:string)
  {
    const actionObj = {
      action,
      data,
      column
    }
    this.onTableActionClick.emit(actionObj);
  }
  onActionClick(action: string, data: any) {
    const actionObj = {
      action,
      data,
    }
    this.onTableActionClick.emit(actionObj);
  } 
  isAllSelected() {
    this.selectedId =[];
    if(this.selection.selected.length !=0){
    for(var i=0;i<this.selection.selected.length;i++){
       if(this.selection.selected[i].studentID != undefined){
       this.selectedId.push(this.selection.selected[i].studentID)
      }
      if(this.selection.selected[i].teacherID != undefined){
        this.selectedId.push(this.selection.selected[i].teacherID)
       }
       if(this.selection.selected[i].adminID != undefined){
        this.selectedId.push(this.selection.selected[i].adminID)
       }
    }
    
    this.onselect.emit(this.selectedId)
  }
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  changee(event:any,data:any){
    if(event.checked == false ){
      for(var i=0;i<this.selectedId.length;i++){
        if(this.selectedId == data.studentID){
          this.selectedId.splice(i, 1);
        }
        if(this.selectedId == data.teacherID){
          this.selectedId.splice(i, 1);
        }
        if(this.selectedId == data.adminID){
          this.selectedId.splice(i, 1);
        }
      }
    }
    this.onselect.emit(this.selectedId)
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
      if(this.selection.selected.length == 0){
        this.selectedId =[];
        console.log("SELECTEDDATA",this.selectedId)
        this.onselect.emit(this.selectedId)
      }
      console.log()
  }
}


// class Meta {
//   totalPages?: number = 0;
//   pageSize?: number = 5;
//   currentPage?: number = 1;
//   defaultPageSize?: number = 5
//   totalRecords?: number = 0
// }
