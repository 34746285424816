<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Teachers Payment</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Teachers Payment</li>
        </ol>
      </div>
    </div>
  </div>
  <div class="cardWidget">
    <div class="cardBody">
      <div class="tableActionTop">
        <div class="tableFilter">
          <div class="row" class="filterForm" [class.toggled]="classToggled">
            <div class="col-lg-12 col-sm-12 mt-2">
              <form [formGroup]="form" class="w-100">
                <div class="row">
    
                  <div class="col-lg-2 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-select placeholder="Month" (selectionChange)="monthSelection($event.value)" formControlName="month">
                        <mat-option value="" disabled>Select month</mat-option>
                        <mat-option *ngFor="let month of months" [value]="month.monthNumber">
                          {{month.monthName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-2 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-select placeholder="Year" (selectionChange)="yearSelection($event.value)" formControlName="year">
                        <mat-option value="" disabled>Select year</mat-option>
                        <mat-option *ngFor="let y of years" [value]="y.yearNumber">
                          {{y.yearName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Select Teacher</mat-label>
                      
                      <!-- <mat-select formControlName="teacherList">
                        <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'teacher')"> -->


                        <input type="text" (input)="onKeyFilter($event, 'teacher')" matInput
                        formControlName="teacherList" [matAutocomplete]="teacherListAuto">


                        <mat-autocomplete #teacherListAuto="matAutocomplete" 
                        [displayWith]="dispalyTeacherAuto.bind(this)">


                        <mat-option value="0">All</mat-option>
                        <mat-option *ngFor="let topping of teacherList" [value]="topping">{{topping.teacherName}}</mat-option>
                      <!-- </mat-select> -->
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-2 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Select Status</mat-label>
                      <mat-select formControlName="paymentStatus">
                        <mat-option value="0">Unpaid</mat-option>
                        <mat-option value="1">Paid</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <div class="row mt-2">
                      <div class="col-6">
                        <button class="btn btn-primary w-100 btn-sm" (click)="onFilterSubmit()" matRipple>Get Details
                        </button>
                      </div>
                      <div class="col-6">
                        <button class="btn btn-reset w-100 btn-sm" (click)="resetForm($event)">Reset</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
        </div>
      </div>
     
      <div class="rightTableActions centerItemvert align-items-center">
        <div class="iconsset d-flex">
            <div class="viewColumns tableActions" matTooltip="Filter">
                <i title="Filter Student" class="fas fa-filter cursor-pointer"
                    (click)="toggleField()"></i>
                <span class="mobileHidden cursor-pointer" (click)="toggleField()"></span>
            </div>
        </div>
        <div class="buttonsset d-flex align-items-center">
          <button class="ddButtonType" [matMenuTriggerFor]="beforeMenu" [disabled]="selectedId.length==0">
            <span class="leftItem conterColumns">{{selectedId.length}}</span> <span class="mobileHidden"
              matTooltip="Select atleast one teacher">Selected</span>
            <i class="fa fa-caret-down rightItem"></i></button>
            <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
              <div [ngClass]="DisabledPointer()?  'disablePointer' : ''" [matTooltip]="toolTipText">
              <button [disabled]="DisabledPointer()" (click)="actionDialogPopup1(accountTypePayment)" mat-menu-item>Bulk Pay</button>
            </div>
            </mat-menu>
          </div>
</div>
      </div>

      <div class="mat-elevation-z8">
        <div class="table-responsive">
          <table mat-table [dataSource]="collection" matSort (matSortChange)="announceSortChange($event)">

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="paymentDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date</th>
                <td mat-cell *matCellDef="let element">  
                  <ng-container *ngIf="element.paymentDate != '1900-01-01T00:00:00'">
                    {{element.paymentDate | date: dateFormat}}
                  </ng-container>
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="employeeTypeID">
                            <th mat-header-cell *matHeaderCellDef>Absent</th>
                            <td mat-cell *matCellDef="let element"> {{element.employeeTypeID}} </td>
                          </ng-container> -->

            <ng-container matColumnDef="teacherName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Teacher Name</th>
              <td mat-cell *matCellDef="let element"> {{element.teacherName}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container> -->

            <!-- <ng-container matColumnDef="contactNumber">
              <th mat-header-cell *matHeaderCellDef>Contact Number</th>
              <td mat-cell *matCellDef="let element"> {{element.contactNumber}} </td>
            </ng-container> -->

            <!-- <ng-container matColumnDef="absentForMonthName">
              <th mat-header-cell *matHeaderCellDef>Month</th>
              <td mat-cell *matCellDef="let element"> {{element.absentForMonthName}}</td>
            </ng-container> -->

            <!-- <ng-container matColumnDef="absentForYear">
              <th mat-header-cell *matHeaderCellDef>Year</th>
              <td mat-cell *matCellDef="let element"> {{element.absentForYear}}</td>
            </ng-container> -->

            <ng-container matColumnDef="totalAbsent">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Absent</th>
              <td mat-cell *matCellDef="let element"> {{element.totalAbsent}}</td>
            </ng-container>

            <ng-container matColumnDef="salaryBeforeDeduction">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Salary Amt </th>
              <td mat-cell *matCellDef="let element"> {{element.salaryBeforeDeduction}} </td>
            </ng-container>

            <ng-container matColumnDef="deductionAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Deduction Amt </th>
              <td mat-cell *matCellDef="let element"> {{element.deductionAmount}} </td>
            </ng-container>

            <ng-container matColumnDef="salaryAfterDeduction">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Salary After DED </th>
              <td mat-cell *matCellDef="let element"> {{element.salaryAfterDeduction}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="salaryForMonth">
              <th mat-header-cell *matHeaderCellDef>Month</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryForMonth}}</td>
            </ng-container> -->

             <ng-container matColumnDef="salaryForMonthName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Month</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryForMonthName}}</td>
            </ng-container>

            <ng-container matColumnDef="salaryForYear">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Year</th>
              <td mat-cell *matCellDef="let element"> {{element.salaryForYear}}</td>
            </ng-container>

            <ng-container matColumnDef="payableAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payable Amt</th>
              <td mat-cell *matCellDef="let element"> {{element.payableAmount}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="paidAmount">
              <th mat-header-cell *matHeaderCellDef>Paid Amt $</th>
              <td mat-cell *matCellDef="let element"> {{element.paidAmount}}</td>
            </ng-container> -->

            <ng-container matColumnDef="alreadyPaidAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Already Paid</th>
              <td mat-cell *matCellDef="let element"> {{element.alreadyPaidAmount}}</td>
            </ng-container>

            <ng-container matColumnDef="bonusAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Bonus Amt</th>
              <td mat-cell *matCellDef="let element"> {{element.bonusAmount}}</td>
            </ng-container>

            <ng-container matColumnDef="adjustableAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Adjustable Amt</th>
              <td mat-cell *matCellDef="let element"> {{element.adjustableAmount}}</td>
            </ng-container>



            <!-- <ng-container matColumnDef="monthStartDate">
                            <th mat-header-cell *matHeaderCellDef>Month Start Date</th>
                            <td mat-cell *matCellDef="let element"> {{element.monthStartDate}}</td>
                          </ng-container> -->

            <!-- <ng-container matColumnDef="monthEndDate">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let element"> {{element.monthEndDate}}</td>
                          </ng-container> -->



            <ng-container matColumnDef="comment">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
              <td mat-cell *matCellDef="let element"> {{element.comment}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="paymentUpfrontStatus">
              <th mat-header-cell *matHeaderCellDef>Is Upfront</th>
              <td mat-cell *matCellDef="let element"> {{element.paymentUpfrontStatus}}</td>
            </ng-container> -->

            <ng-container matColumnDef="paymentStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Status</th>
              <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.paymentStatus == true">Paid</span>
                <!-- <span *ngIf="element.paymentStatus == true && element.payableAmount > 0">
                  Partially Paid
                </span> -->
                <span *ngIf="element.paymentStatus == false">
                  Unpaid
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="button">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
              <td mat-cell *matCellDef="let element">
                <!-- *ngIf="element.paymentUpfrontBalanceStatus == false" -->
                <ng-container >
                  <!-- <button title="view" class="btn btn-primary btn-sm" (click)="actionDialogPopup(advancepayment, element)"> -->
                  <button *ngIf="element.paymentStatus == false" title="view" class="tbactionbtn" (click)="actionDialogPopup(advancepayment, element)">
                    <i class="fas fa-money-bill"></i>
                    Pay
                  </button>
                </ng-container>
              </td>
            </ng-container>
            <!-- </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [length]="100"
              [pageSize]="10"
              [pageSizeOptions]="[10, 25, 100]"
              aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #advancepayment>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle">
      <h5 mat-dialog-title>Payment for: {{paymentForName}}</h5>
    </div>

    <mat-dialog-content class="mat-typography">
      <form [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()">
        <div class="row">
    
          <!-- <div class="col-12">
                <mat-label>Pay Upfront: </mat-label>
                <mat-checkbox class="example-margin"  matInput formControlName="upfrontPayment" (change)="bonusCheck($event)"></mat-checkbox>
              </div> -->
    
          <!-- <div hidden class="col-12" *ngIf="hideUpfrontendCheckBox">
            <section class="radioDefaultColor form-group35">
              <div class="flex-grow-1 CheckboxGroup">
                <div class="col2Check">
                  <mat-checkbox class="example-margin" matInput formControlName="upfrontPayment"
                    (change)="bonusCheck($event)">Pay Upfront</mat-checkbox>
                </div>
              </div>
            </section>
          </div> -->
    
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Salary After Deduction</mat-label>
              <input matInput type="number" formControlName="salaryAfterDeduction" readonly>
            </mat-form-field>
          </div>
    
          <div class="col-12 mt-3" *ngIf="bonusFieldStatus">
            <ng-container *ngIf="hideUpfrontendCheckBox">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Bonus Amount</mat-label>
                <input matInput type="number" (blur)="bonusPay($event)" formControlName="bonusAmount" (keydown)="eventHandler($event)">
              </mat-form-field>
            </ng-container>
          </div>

          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Adjustable Amount</mat-label>
              <input matInput type="number" formControlName="adjustableAmount" (blur)="adjustablePay($event)" (keydown)="eventHandler($event)">
            </mat-form-field>
          </div>
    

          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Paying Amount</mat-label>
              <input matInput type="number" formControlName="paidAmount" (keydown)="eventHandler($event)" readonly>
            </mat-form-field>
          </div>
    
          <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-select placeholder="Account Type" formControlName="accountType">
                <mat-option *ngFor="let acc of accountType" [value]="acc.id">
                  {{acc.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Comment</mat-label>
              <textarea input matInput placeholder="Comment" formControlName="comment"></textarea>
            </mat-form-field>
          </div>

            <!-- mat-dialog-close -->
          <div class="col-12">
          <div class="proSection text-right">
            <input matInput type="number" formControlName="userID" hidden>
            <button class="btn btn-primary btn-sm" matRipple>Pay</button>
          </div>
          </div>
        </div>
      </form>
    </mat-dialog-content>

    
  </div>
</ng-template>

<ng-template #accountTypePayment>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle">
        <h2 mat-dialog-title>Select Credit Ledger</h2>
    </div>
    <div class="popover-content text-center">
    </div>
    <div class="text-center mt-4 d-flex">
      <button type="button" class="btn btn-primary w-50 mr-2 h-40" (click)="bulkPay(1)" matRipple>Cash In Hand Ledger</button>
      <br>
      <br>
      <button type="button" class="btn btn-primary w-50 h-40" (click)="bulkPay(2)" matRipple>Cash In Bank Ledger</button>
      <div class='stripe' id="stripe" #stripe></div>
    </div>
  </div>
</ng-template>