<div class="dialog-xll">
  <i class="material-icons popupClose" mat-dialog-close>clear</i>
  <div class="dialogTitle center-align loginTtile">
     <h2 mat-dialog-title><span *ngIf="!readOnly">Add</span> Rubric Scale</h2> 
  </div>
  <form [formGroup]="formGroup">
    <div class="gradeListHead gradelistWidget">
      <mat-form-field class="w-100" appearance="outline">
        <!-- <span 
        class="text-danger" 
        *ngIf="formGroup.get('RubicName')?.errors && 
            formGroup.get('RubicName')?.hasError('required')"
        >*</span> -->
        <mat-label>Rubric Scale</mat-label>
        <!-- <input
        matInput
       type="text"
       name="RubicId"
       class="ng-hide"
       formControlName="RubicId"
     />
        <input matInput  type="text"
        name="RubicName"
        formControlName="RubicName"
        [readonly]="readOnly"
        [class.valid]="formGroup.get('RubicName')?.valid && 
                      (formGroup.get('RubicName')?.dirty || formGroup.get('RubicName')?.touched)"
        [class.invalid]="formGroup.get('RubicName')?.invalid && 
                      (formGroup.get('RubicName')?.dirty || formGroup.get('RubicName')?.touched)"
        placeholder="Rubric Scale Name">   
        <div *ngIf="formGroup.get('RubicName')?.invalid && 
        formGroup.get('RubicName')?.errors && 
        (formGroup.get('RubicName')?.dirty || formGroup.get('RubicName')?.touched)">
        <small class="text-danger float-r"
            *ngIf="formGroup.get('RubicName')?.hasError('required')">
            This field is required.
        </small> -->
        <input matInput  type="text"
            name="RubicName"
            [readonly]="readOnly"
            formControlName="RubicName"
            [class.valid]="formGroup.get('RubicName')?.valid &&
                          (formGroup.get('RubicName')?.dirty || formGroup.get('RubicName')?.touched)"
            [class.invalid]="formGroup.get('RubicName')?.invalid &&
                          (formGroup.get('RubicName')?.dirty || formGroup.get('RubicName')?.touched)"
            placeholder="Rubric Scale Name">  
            <div *ngIf="formGroup.get('RubicName')?.invalid &&
            formGroup.get('RubicName')?.errors &&
            (formGroup.get('RubicName')?.dirty || formGroup.get('RubicName')?.touched)">
            </div>
     
        <mat-error class="mt-2"
        *ngIf="formGroup.get('RubicName')?.hasError('required')">
        This field is required. </mat-error>

    </mat-form-field>
    </div>
    
  <div class="manageGradeList my-4">
    <div class="gradeListHead gradelistWidget">
        <div class="criteriaCol">Criteria</div>
        <div class="scaleItemsCol">Grading Scale</div>
        <div class="scalePointsCol center">Points</div>
    </div>

     <ng-container
                formArrayName="Criterions"
                *ngFor="
                  let criteria of Criterions().controls;
                  let criteriaIndex = index
                "
              >

              <div class="gradeListrow gradelistWidget" [formGroupName]="criteriaIndex"
              *ngIf="isCriteriaNotDeleted(criteriaIndex)">
                <div class="titlesofRubic">
                  <input
                            matInput
                            type="text"
                            [hidden]="true"
                            formControlName="RubicCriteriaId"
                          />
                   <div class="criteriaCol titles">
                    <input
                    matInput
                    type="text"
                    [readonly]="readOnly"
                    formControlName="CriteriaTitle"
                  />
                  </div><div *ngIf="criteria.get('CriteriaTitle')?.invalid && 
                   criteria.get('CriteriaTitle')?.errors && 
                   (criteria.get('CriteriaTitle')?.dirty || criteria.get('CriteriaTitle')?.touched)">
                   <small class="text-danger smalled">
                       {{MessageInfo.Exist.criteria}}
                   </small>
                </div><br>
                  <div class="criteriaCol subtitles">
                    <input
                    matInput
                    type="text"
                    [readonly]="readOnly"
                    formControlName="CriteriaDescription"
                  />
                  </div>
                </div>
                <div class="scaleItemsCol d-flex">
                  <ng-container
                  formArrayName="Attributes"
                  *ngFor="
                    let attribute of Attributes(criteriaIndex).controls;
                    let attributeIndex = index
                  "
                >
                    <div class="scaleWidgetPop" [class.criteria-selected]="isSelected(criteriaIndex,attributeIndex) && readOnly" *ngIf="isNotDeleted(criteriaIndex, attributeIndex)"
                    [formGroupName]="attributeIndex" (click)="readOnly && select($event,criteriaIndex,attributeIndex)">
                             <input
                                matInput
                                type="text"
                                [hidden]="true"
                                formControlName="RubicCriteriaAttributeId"
                              />
                      <div class="scalePoint">
                        <input
                        matInput
                        type="text"
                        [readonly]="readOnly"
                        (keypress)="
                          ($event.charCode >= 48 && $event.charCode < 58)
                        "
                        (mouseenter)="showBorder($event)"
                        (mouseleave)="hideBorder($event)"
                        formControlName="AttributeValue"
                        maxlength="2"
                      />
                      <div *ngIf="attribute.get('AttributeValue')?.invalid && 
                    attribute.get('AttributeValue')?.errors && 
                    (attribute.get('AttributeValue')?.dirty || attribute.get('AttributeValue')?.touched)">
                    <small class="text-danger smalled float-r">
                        {{MessageInfo.Exist.attributeValue}}
                    </small>
                    </div>
                      </div>
                      <div class="scaleText">
                        <input
                        matInput
                        type="text"
                        [readonly]="readOnly"
                        (mouseenter)="showBorder($event)"
                        (mouseleave)="hideBorder($event)"
                        formControlName="AttributeName"
                      />
                        <div *ngIf="attribute.get('AttributeName')?.invalid && 
                        attribute.get('AttributeName')?.errors && 
                        (attribute.get('AttributeName')?.dirty || attribute.get('AttributeName')?.touched)">
                        <small class="text-danger smalled float-r">
                            {{MessageInfo.Exist.attribute}}
                        </small>
                        </div>
                      </div>
                     

                      <div class="scaleTexticons"><i class="fas fa-plus mr-1 set-cursor" *ngIf="!readOnly" (click)="addAttribute(criteriaIndex)"></i><i class="fas fa-trash-alt set-cursor" *ngIf="!readOnly" (click)="
                        removeAttribute(criteriaIndex, attributeIndex)
                      "></i></div>
                  </div>

                </ng-container> 
                    
               </div>

               <div class="scalePointsCol text-right">{{ getPoints(criteriaIndex) }}</div>
               <mat-icon class="deleteIconRight" *ngIf="!readOnly" (click)="removeCriteria(criteriaIndex)">delete</mat-icon>
               <div class="scaleTexticons"><i class="fas fa-plus mr-1" *ngIf="!readOnly"></i><i class="fas fa-trash-alt" *ngIf="!readOnly"></i></div>
           </div>
          
    </ng-container>

     <div class="gradeListrowTotal gradelistWidget">
          <div class="criteriaCol"><a href="javascript:void(0)" class="underlinedLink" *ngIf="!readOnly" (click)="AddCriteria()">+Add Criteria</a></div>
          <div class="scaleItemsCol text-right"><strong>Total Points</strong></div>
            <div class="scalePointsCol center">{{ totalCount }}</div>
        </div>
  </div>

  <div class="btnsWidget form-group17 text-right">
    <button type="button" matRipple class="btn btn-primary btn-sm" [disabled]="!formGroup.valid" *ngIf="!readOnly" (click)="save($event)">Save</button>
    <button type="button" matRipple class="btn btn-primary btn-sm" *ngIf="readOnly" (click)="close()">Select</button>
    <button type="button" matRipple class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
  </div>  
</form>
</div>