import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';

// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
// import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTreeModule } from '@angular/material/tree';

import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import { DatePipe } from '@angular/common'

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    RxReactiveFormsModule,
    // SlickCarouselModule,
    MatSelectModule,
    // NgxMatIntlTelInputModule,
    MatRadioModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    CdkTableModule,
    MatSortModule,
    MatTreeModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    DragDropModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatChipsModule,
    MatTooltipModule

  ],
  providers: [DatePipe],
  exports: [
    CommonModule,
    // SlickCarouselModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    // NgxMatIntlTelInputModule,
    MatRadioModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    CdkTableModule,
    MatSortModule,
    MatTreeModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    DragDropModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatChipsModule,
    MatTooltipModule
  ],
})
export class SharedModule { }
