<div class="activityList">
    <h2 class="heading">Activities</h2>
    <div class="row">
        <div class="col-lg-8 col-sm-12">
            <div>
                <div class="text-right activitiesFilters">
                    <button class="btn btn-primary btnsmall" (click)="toggleField()" matRipple><i
                            class="fas fa-filter"></i> Filter</button>
                    <div class="filterForm filtersWidget" [class.toggled]="classToggled">
                        <section class="example-section">
                            <mat-checkbox class="" [(ngModel)]="reviewCheck" (change)="commonfunc()" [checked]="true">
                                Review</mat-checkbox>
                        </section>
                        <section class="example-section">
                            <span class="example-list-section">
                                <mat-checkbox class="example-margin" [checked]="allComplete"
                                    [indeterminate]="someComplete()" (change)="setAll($event.checked)">Email
                                </mat-checkbox>
                            </span>
                            <span class="example-list-section">
                                <ul>
                                    <li *ngFor="let subtask of task.subtasks">
                                        <!-- <mat-checkbox [(ngModel)]="subtask.completed" (change)="checkedBox(subtask.name,$event)"
                                  (ngModelChange)="updateAllComplete()">{{subtask.name}}</mat-checkbox> -->
                                        <mat-checkbox [(ngModel)]="subtask.completed" (change)="commonfunc()"
                                            (ngModelChange)="updateAllComplete()">{{subtask.name}}</mat-checkbox>
                                    </li>
                                </ul>
                            </span>
                        </section>

                        <section class="example-section">
                            <span class="example-list-section">
                                <mat-checkbox class="example-margin" [checked]="allComplete1"
                                    [indeterminate]="someComplete1()" (change)="setAll1($event.checked)">SMS
                                </mat-checkbox>
                            </span>
                            <span class="example-list-section">
                                <ul>
                                    <li *ngFor="let subtask of sms.subtaskssms">
                                        <mat-checkbox [(ngModel)]="subtask.completed" (change)="commonfunc()"
                                            (ngModelChange)="updateAllComplete()">{{subtask.name}}</mat-checkbox>
                                    </li>
                                </ul>
                            </span>
                        </section>
                        <section class="example-section">
                            <mat-checkbox class="" [(ngModel)]="pvtNote" (change)="commonfunc()" [checked]="true">
                                PrivateNote</mat-checkbox>
                        </section>
                        <section class="example-section">
                            <mat-checkbox class="" [(ngModel)]="profile" (change)="commonfunc()" [checked]="true">
                                Profile</mat-checkbox>
                        </section>
                        <section class="example-section">
                            <mat-checkbox class="" [(ngModel)]="payment" (change)="commonfunc()" [checked]="true">
                                Payment</mat-checkbox>
                        </section>
                        <section class="example-section">
                            <mat-checkbox class="" [(ngModel)]="invoice" (change)="commonfunc()" [checked]="true">
                                Invoice</mat-checkbox>
                        </section>

                    </div>
                </div>
                <div class="activityListGroup" *ngFor="let data of dataSouceForSMS">
                    <div class="activityDate"> {{data.date|date }}</div>
                    <div class="activitiesContent" *ngFor="let gm of data.games">
                        <div class="activityContent" *ngIf="gm.type=='SMS'">
                        <div class="activityUser d-flex">
                            <span>SMS</span><i class="fas fa-comment"></i>
                        </div>
                        <div class="" *ngIf="gm.type=='SMS'">
                            <div class="activityContentLeft d-flex justify-content-between">
                                <div class="activityUserProfile">
                                    <img src="../../../../../../../../../assets/img/admin/avatar.jpg" alt="">
                                    <span><b>You</b> sent sms.</span>
                                </div>
                                <div class="activityContentRight">
                                    <span class="activityMark">{{gm.status}}</span>
                                </div>
                            </div>
                            
                    </div>
                    <p class="activityContentText d-flex align-items-center">
                        <span>{{gm.body}}</span>
                    </p>
                </div>
                <div class="activityContent" *ngIf="gm.type=='Email'">
                    <div class="activityUser d-flex">
                        <span>Email</span><i class="fas fa-envelope"></i>
                    </div>
                    <div class="activityContentLeft d-flex justify-content-between">
                        <div class="activityUserProfile">
                            <img src="../../../../../../../../../assets/img/admin/avatar.jpg" alt="">
                            <span><b>You</b> sent email</span>
                        </div>

                        <div class="activityContentRight">
                            <span class="activityMark">{{gm.subject}}</span>
                        </div>

                    </div>


                    <div class="activityContentText d-flex align-items-center">
                        <p [innerHTML]="gm.body"></p>
                    </div>
                </div>
                <div class="activityContent" *ngIf="gm.type=='Private_Note'">
                    <div class="activityUser d-flex">
                        <span>Private Note</span><i class="fas fa-sticky-note"></i>
                    </div>
                    <div class="activityContentLeft d-flex justify-content-between">
                        <div class="activityUserProfile">
                            <img src="../../../../../../../../../assets/img/admin/avatar.jpg" alt="">
                            <span><b>You</b> sent private note.</span>
                        </div>
                        <div class="activityContentRight">
                            <span class="activityMark">{{gm.subject}}</span>
                            <i class="fa fa-trash-o ml-1" (click)="delete(gm.type,gm.id)"></i>
                        </div>
                        
                    </div>

                    <p class="activityContentText d-flex align-items-center">
                        <span>{{gm.body}}</span>
                    </p>
                </div>

                <div class="activityContent" *ngIf="gm.type=='Review'">
                    <div class="activityUser d-flex">
                        <span>Review</span><i class="fas fa-glasses"></i>
                    </div>
                    <div class="activityContentLeft d-flex justify-content-between">
                        <div class="activityUserProfile">
                            <img src="../../../../../../../../../assets/img/admin/avatar.jpg" alt="">
                            <span><b>You</b> sent review.</span>
                        </div>
                       
                   
                        <div class="activityContentRight">
                            <i class="fa fa-trash-o" (click)="delete(gm.type,gm.id)"></i>
                        </div>
                    </div>

                    <div class="activityContentText d-flex align-items-center">
                        <p [innerHTML]="gm.body"></p>
                    </div>
                    <div>
                        <span *ngFor="let sub of gm.subject?.split(',')" class="activityMark">
                            {{sub}}</span>
                    </div>
            </div>
        </div>
        <!--//Activity Group-->





        <!-- 
                <div class="activityListGroup">
                    <div class="activityDate">02 Sep 2021</div>
                    <div class="activitiesContent">
                        <div class="activityContent d-sm-flex justify-content-between">
                            <div class="activityUser">
                                <span>Profile</span><i class="fas fa-user"></i> 
                            </div>
                            <div class="activityContentLeft d-flex">
                                <div class="activityContentUser">
                                    <img src="../../../../../../../../../assets/img/admin/avatar.jpg" alt="">
                                </div>
                                <p class="activityContentText d-flex align-items-center">
                                    <span><strong>You</strong> created Students Profile</span>
                                </p>
                            </div>
                            <div class="activityContentRight">
                                <span class="activityMark">Profile Created</span>
                            </div>
                        </div>
                        <div class="activityContent d-sm-flex justify-content-between">
                            <div class="activityUser">
                                <span>Profile</span><i class="fas fa-user"></i> 
                            </div>
                            <div class="activityContentLeft d-flex">
                                <div class="activityContentUser">
                                    <img src="../../../../../../../../../assets/img/admin/avatar.jpg" alt="">
                                </div>
                                <p class="activityContentText d-flex align-items-center">
                                    <span><strong>You</strong> created Students Profile</span>
                                </p>
                            </div>
                            <div class="activityContentRight">
                                <span class="activityMark">Profile Created</span>
                            </div>
                        </div>
                        <div class="activityContent d-sm-flex justify-content-between">
                            <div class="activityUser">
                                <span>Profile</span><i class="fas fa-user"></i> 
                            </div>
                            <div class="activityContentLeft d-flex">
                                <div class="activityContentUser">
                                    <img src="../../../../../../../../../assets/img/admin/avatar.jpg" alt="">
                                </div>
                                <p class="activityContentText d-flex align-items-center">
                                    <span><strong>You</strong> created Students Profile</span>
                                </p>
                            </div>
                            <div class="activityContentRight">
                                <span class="activityMark">Profile Created</span>
                            </div>
                        </div>
                    </div>
                </div>//Activity Group -->
    </div>
</div>
<div class="col-lg-4 col-sm-0"></div>
</div>

</div>