import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { StudentService } from 'src/app/services/student.service';
import { StudentEnrollmentStatusService } from 'src/app/masterServices/enrollmentStatusServices/student-enrollment-status.service';
import { SubjectEnrollmentStatusDropdownService } from 'src/app/masterServices/subjectenrollmentStatusIDropdown/subject-enrollment-status-dropdown.service';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { ActivatedRoute } from '@angular/router';
import { SectionService } from '../../../../courses-and-subjects/services/sectionServices/section.service';
import { MasterService } from 'src/app/services/master.service';
import { SubjectService } from '../../../../courses-and-subjects/services/subjectServices/subject.service';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.css']
})
export class EnrollmentComponent implements OnInit {
  myControl = new FormControl();
  EnrollmentDetails: any;
  studentEnrollmentForm: FormGroup;
  options: string[] = ['English', 'THindi', 'Maths'];
  electiveSubjectTerm: any
  filteredOptions: Observable<string[]>;
  constructor(private dialog: MatDialog,
    private studenService: StudentService,
    private studentEnrollmentStatusService: StudentEnrollmentStatusService,
    private subjectEnrollmentStatus: SubjectEnrollmentStatusDropdownService,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private sectionService: SectionService,
    private subjectService: SubjectService,
    private masterService: MasterService,
    private classDropdownService: ClassDropdownService,
  ) { }

  studentID: any;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? null : params.id;
    });
    console.log(" this.studentID", this.studentID);

    this.GetSubjectEnrollmentStatus();
    this.GetEnrollmentSubjectDetails();
    this.GetStudentEnrollmentStatusID();
    this.AcademicSessionDropDown();
    this.GetClassDropdown()
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value)),
    // );
    this.studentEnrollmentForm = this.formBuilder.group({
      studentEnrollmentStatusID: ['', Validators.required],
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      academicSessionID: ['', Validators.required],
    }
    );
  }

  electiveSubject: any;
  subjectIDbutton: any;
  electiveSubjectEnrollID: any;
  buttonHide = false;
  GetElectiveSubject(data: any) {
    console.log("this.enrollSubjectDetailsList1", this.enrollSubjectDetailsList1);
    ////debugger;
    this.studenService.GetAllElectiveSubject(data).subscribe((res: any) => {
      this.electiveSubject = res.responseData;

      console.log("this.electiveSubject", this.electiveSubject);

      for (let j = 0; j < this.enrollSubjectDetailsList1.length; j++) {
        for (let i = 0; i < this.electiveSubject.length; i++) {
          if (this.electiveSubject[i].subjectID == this.enrollSubjectDetailsList1[j].subjectID) {
            this.buttonHide = true;
          }
          // else{
          //   this.buttonHide = false;
          // }
          console.log("this.buttonHide", this.buttonHide);
        }
      }
      // for (let i = 0; i < this.enrollSubjectDetailsList1.length; i++) {
      //   this.subjectIDbutton = this.enrollSubjectDetailsList1[i]
      //   if (this.subjectIDbutton == this.electiveSubjectEnrollID) {
      //     this.button = true;
      //   }
      //   else {
      //     this.button = false;
      //   }

      // }
      this.electiveSubObj = {};
      console.log("this.subjectIDbutton", this.subjectIDbutton);
      console.log("this.electiveSubjectEnrollID", this.electiveSubjectEnrollID);
    });
  }
  classListDropdown: any
  GetClassDropdown() {
    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classListDropdown = res.responseData
    });
  }
  academicSessions: any;
  AcademicSessionDropDown() {
    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      console.log("ACADEMIC Session", res.responseData)
      this.academicSessions = res.responseData;
    });
  }
  sectionListDropdown: any;
  filterSection(event: any) {
    this.sectionService.GetSectionByClassId(event.value).subscribe((res: any) => {
      this.sectionListDropdown = res.responseData
    });
  }
  subjectEnrollmentStatusDropdown: any
  GetSubjectEnrollmentStatus() {
    this.subjectEnrollmentStatus.getSubjectEnrollmentStatusDropdown().subscribe((res: any) => {
      this.subjectEnrollmentStatusDropdown = res.responseData
    });
  }
  studentEnrollmentStatus: any;
  GetStudentEnrollmentStatusID() {
    this.studentEnrollmentStatusService.StudentEnrollmentStatusDropdown().subscribe((res: any) => {
      this.studentEnrollmentStatus = res.responseData
    }
    )
  }
  enrollSubjectDetailsList: any[];
  enrollSubjectDetailsList1: any[];
  studentName: string;
  GetEnrollmentSubjectDetails() {
    this.enrollSubjectDetailsList = []
    this.enrollSubjectDetailsList1 = []
    this.studenService.GetStudentEnrollmentTabDetailsId(this.studentID).subscribe((res: any) => {
      this.EnrollmentDetails = res.responseData;
      console.log("EnrollmentDetails", this.EnrollmentDetails);
      ////debugger;
      console.log("res", this.EnrollmentDetails);
      for (let i = 0; i < this.EnrollmentDetails.length; i++) {
        for (let j = 0; j < this.EnrollmentDetails[i].lstSubject.length; j++) {
          if (this.EnrollmentDetails[i].lstSubject[j].isCore == false) {
            this.enrollSubjectDetailsList1.push(this.EnrollmentDetails[i].lstSubject[j])
          }
        }
      }
      for (let i = 0; i < this.EnrollmentDetails.length; i++) {
        this.enrollSubjectDetailsList.push(this.EnrollmentDetails[0].studentName)
      }
      this.studentName = this.enrollSubjectDetailsList[0]
    });

    console.log("this.enrollSubjectDetailsList1", this.enrollSubjectDetailsList1);

  }
  noop(event: MouseEvent) {
    console.log('noop', event);
    event.preventDefault();
    event.stopPropagation();
  }


  studentEnrollObj: any;

  onSubmitStudentEnrollment() {
    this.studentsid = [];
    this.studentsid.push(this.studentID)
    console.log("this.studentID", this.studentID);
    this.studentEnrollObj = {
      "studentID": this.studentsid,
      "academicSessionID": this.studentEnrollmentForm.controls.academicSessionID.value,
      "classID": this.studentEnrollmentForm.controls.classID.value,
      "sectionID": this.studentEnrollmentForm.controls.sectionID.value,
      "studentEnrollmentStatusID": this.studentEnrollmentForm.controls.studentEnrollmentStatusID.value,
    }
    console.log("this.studentEnrollObj", this.studentEnrollObj);
    this.studenService.StudentEnrollment(this.studentEnrollObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.GetEnrollmentSubjectDetails();
        this.studentsid = [];
        this.dialog.closeAll();
      }
      else {
        this.toaster.error(res.message);
        this.studentsid = [];
      }
    });
  }

  studentStatusObj: any;
  studentsid: any
  changeStudentEnrollmentStatus(event: any, studentID: any, sectionID: any, classID: any, acedemicSessionID: any) {
    this.studentsid = [];
    this.studentsid.push(JSON.stringify(studentID))
    this.studentStatusObj = {
      "studentID": this.studentsid,
      "classID": classID,
      "sectionID": sectionID,
      "academicSessionID": acedemicSessionID,
      "studentEnrollmentStatusID": event.value,
    }
    console.log("this.studentStatusObj", this.studentStatusObj);

    this.studenService.UpdateStudentEnrollmentStatus(this.studentStatusObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.studentStatusObj = {};
        this.studentsid = [];
      }
      else {
        this.toaster.error(res.message);
        this.studentStatusObj = {};
        this.studentsid = [];
      }
    })
  }

  // this.subjectEnrollObj = {
  //   studentID: this.studentsid,
  //   subjectID: this.SubjectID,
  //   academicSessionID: this.SessionID,
  //   classID: this.ClassID,
  //   sectionID: this.SectionID,
  //   subjectEnrollmentStatusID:
  //     this.UpdateSubjectEnrollmentStatusForm.controls
  //       .subjectEnrollmentStatusID.value,
  // };
  subjectStatusObj: any;
  changeSubjectEnrollmentStatus(event: any, studentID: any, classID: any, sectionID: any, academicSessionID: any, subjectID: any) {
    //debugger;
    this.studentsid = [];
    this.studentsid.push(JSON.stringify(studentID))
    this.subjectStatusObj = {
      "studentID": this.studentsid,
      "classID": classID,
      "sectionID": sectionID,
      "academicSessionID": academicSessionID,
      "subjectID": subjectID,
      "subjectEnrollmentStatusID": event.value,
    }
    console.log("this.subjectStatusObj", this.subjectStatusObj);

    this.studenService.UpdateSubjectEnrollmentStatus(this.subjectStatusObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.subjectStatusObj = {}
        this.studentsid = [];
      }
      else {
        this.toaster.error(res.message);
        this.studentStatusObj = {};
        this.studentsid = [];
      }
    })
  }
  DeleteStudentStatusObj: any;
  deleteStudentEnrollment(studentID: any, sectionID: any, classID: any, acedemicSessionID: any) {
    this.DeleteStudentStatusObj = {
      "studentID": studentID,
      "classID": classID,
      "sectionID": sectionID,
      "academicSessionID": acedemicSessionID
    }
    console.log("this.DeleteStudentStatusObj", this.DeleteStudentStatusObj);

    this.dialogService.confirm(`Are you sure you want to delete student enrollment?`).subscribe((result: any) => {
      if (result == true) {
        this.studenService.DeleteStudentEnrollment(this.DeleteStudentStatusObj).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.GetSubjectEnrollmentStatus();
            this.GetEnrollmentSubjectDetails();
            this.GetStudentEnrollmentStatusID();
            this.DeleteStudentStatusObj = {};
          }
          else {
            this.toaster.error(res.message);
            this.DeleteStudentStatusObj = {};
          }
        })
      }
    });
  }

  DeleteElectiveSubjectEnrollmentObj: any
  deleteElectiveSubjectEnrollment(studentID: any, subjectID: any) {
    this.DeleteElectiveSubjectEnrollmentObj = {
      "studentID": studentID,
      "subjectID": subjectID,
    }
    this.dialogService.confirm(`Are you sure you want to delete elective subject enrollment?`).subscribe((result: any) => {
      if (result == true) {
        this.studenService.DeleteSubjectEnrollment(this.DeleteElectiveSubjectEnrollmentObj).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.DeleteElectiveSubjectEnrollmentObj = {};
            this.GetSubjectEnrollmentStatus();
            this.GetEnrollmentSubjectDetails();
            this.GetStudentEnrollmentStatusID();
          }
          else {
            this.toaster.error(res.message);
            this.DeleteElectiveSubjectEnrollmentObj = {};
          }
        })
      }
    });
  }

  deleteElectiveSubjectEnrollmentPop(subjectID: any) {
    this.DeleteElectiveSubjectEnrollmentObj = {
      "studentID": this.studentID,
      "subjectID": subjectID,
    }
    this.dialogService.confirm(`Are you sure you want to delete elective subject enrollment?`).subscribe((result: any) => {
      if (result == true) {
        this.studenService.DeleteSubjectEnrollment(this.DeleteElectiveSubjectEnrollmentObj).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.DeleteElectiveSubjectEnrollmentObj = {};
            this.GetSubjectEnrollmentStatus();
            this.GetEnrollmentSubjectDetails();
            this.GetStudentEnrollmentStatusID();
            this.studentID;
          }
          else {
            this.toaster.error(res.message);
            this.DeleteElectiveSubjectEnrollmentObj = {};
            this.studentID;
          }
        })
      }
    });
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
  acdemicID: any;
  stuID: any;
  clsID: any;
  secID: any;
  electiveSubObj: any;
  //subjectIDbutton:any;
  electiveDialogPopup(templateRef: TemplateRef<any>, sectionID: any, classID: any, academicSessionID: any, studentID: any,) {
    this.electiveSubObj = {
      "classID": classID,
      "sectionID": sectionID
    };
    this.GetElectiveSubject(this.electiveSubObj)
    this.dialog.open(templateRef);
    this.acdemicID = academicSessionID;
    this.stuID = studentID;
    this.clsID = classID;
    this.secID = sectionID;
    console.log("studentID", this.stuID);
    console.log("acdemicID", this.acdemicID);
    //this.subjectIDbutton = this.enrollSubjectDetailsList.filter((v:any) => v.subjectID);
    // for(let i=0;i<this.enrollSubjectDetailsList.length;i++){
    //   this.subjectIDbutton=this.enrollSubjectDetailsList[i]
    // }
  }

  obj: any;
  studentsid1: any;
  subjectsid1: any;
  subjectIDForCancel: any
  subjectIDButton = false;

  onSubmit(subjectID: any) {
    this.studentsid1 = [];
    this.studentsid1.push(JSON.stringify(this.stuID))
    this.subjectsid1 = [];
    this.subjectsid1.push(JSON.stringify(subjectID))
    this.obj = {
      "studentID": this.studentsid1,
      "academicSessionID": this.acdemicID,
      "classID": this.clsID,
      "sectionID": this.secID,
      "subjectID": this.subjectsid1,
    }
    console.log("objelec", this.obj);

    this.studenService.SubjectEnrollment(this.obj).subscribe((res: any) => {
      if (res.status > 0) {
        this.subjectIDForCancel = subjectID
        this.toaster.success(res.message);
        this.GetEnrollmentSubjectDetails();
        this.obj = {};
        this.subjectIDButton = true;
      }
      else {
        this.toaster.error(res.message);
        this.obj = {};
      }
    });
  };

  CancelElectiveEnroll() {

  }
  // onSubmitElectiveSubjectEnrollment() {
  //   this.electiveSubjectsList = []
  //   let electiveSubjectIDs = this.subjectEnrollmentForm.controls.subjectID.value
  //   for (var i = 0; i < electiveSubjectIDs.length; i++) {
  //     this.electiveSubjectsList.push(JSON.stringify(electiveSubjectIDs[i].subjectID))
  //   }
  //   this.subjectEnrollObj = {
  //     "studentID": this.studentsid,
  //     // "academicSessionID": this.SessionID,
  //     // "classID": this.ClassID,
  //     // "sectionID": this.SectionID,
  //     "subjectID": this.electiveSubjectsList
  //   }

  // }


}
