<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>Edit Teacher's Deatils</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-academic">Academic &amp;
                            Learning</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage
                            Teachers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Edit Teacher's Deatils</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-teachers">Back</button>
            </div>
          </div>
    </div>

    <div class="cardWidget">
        <div class="matCard">
            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">

                        <div class="row">
                            <div class="col-lg-2 col-sm-12">
                                <div class="profilePicLeft form-group30">
                                    <!-- <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Profile pic <span class="req">*</span></mat-label>
                                <input matInput placeholder="Profile" required name="Profile" formControlName="profilePic">
                            </mat-form-field>     -->
                                    <!-- <p class="chngePic">
                                    <input type="file">
                                    <span class="icon centerItem">
                                        <mat-icon>edit</mat-icon>
                                    </span> -->
                                    <!-- <img src="../../../assets/img/display.jpg" alt=""> -->
                                    <!-- <img [src]="previewUrl" alt="" />
                                </p> -->
                                    <p class="chngePic">
                                        <input type="file" accept="image/*"
                                            (change)="actionDialogPopup(uploadPopup,$event)">
                                        <span class="icon centerItem">
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                        <img [src]="croppedImage" alt="">
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-10 col-sm-12">
                                <div class="profileContentRight flex-grow-1">

                                    <!-- <div class="proSection">
                                    <h5>Enrollment</h5>
    
                                    <div class="row">
    
    
                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Select Academic Session</mat-label>
                                                <mat-select placeholder="Session" formControlName="academicSessionID">
                                                    <mat-option value="Select Session">Academic Sessions</mat-option>
                                                    <mat-option *ngFor="let session of academicSessions"
                                                        [value]="session.academicSessionID">
                                                        {{session.academicSessionName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="form.get('academicSessionID')?.touched  && form.get('academicSessionID')?.errors?.required">
                                                    academicSession is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
    
                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Select Class</mat-label>
                                                <mat-select placeholder="Class" formControlName="classID">
                                                    <mat-option value="Select Class">Classes</mat-option>
                                                    <mat-option *ngFor="let class of classes" [value]="class.classID">
                                                        {{class.className}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="form.get('classID')?.touched  && form.get('classID')?.errors?.required">
                                                    Class is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
    
    
                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Select Section</mat-label>
                                                <mat-select placeholder="Section" formControlName="sectionID">
                                                    <mat-option value="Select Section">Sections</mat-option>
                                                    <mat-option *ngFor="let section of sections"
                                                        [value]="section.sectionID">
                                                        {{section.sectionName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="form.get('sectionID')?.touched  && form.get('sectionID')?.errors?.required">
                                                    Section is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
    
                                    </div>
                                </div>
     -->
                                    <div class="proSection">
                                        <h5><i class="fas fa-chalkboard-teacher mr-2"></i>Teachers Details</h5>

                                        <div class="row-flex">
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Employee Type</mat-label>
                                                    <mat-select placeholder="Employee Type" required name="employee"
                                                        formControlName="employeeTypeID">

                                                        <mat-option value="" disabled>Select Employee Type</mat-option>
                                                        <mat-option *ngFor="let employee of employeeTypes "
                                                            [value]="employee.employeeTypeID">
                                                            {{employee.employeeTypeName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('employeeTypeID')?.touched  && form.get('employeeTypeID')?.errors?.required">
                                                        Employee type is required is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Teacher Code </mat-label>
                                                    <input (keypress)="allowAlphaNumber($event)" matInput
                                                        placeholder="Teacher Code" required name="teacherNumber"
                                                        formControlName="teacherNumber" maxlength="10">
                                                    <mat-hint>Only 10 Number are allowed</mat-hint>
                                                    <mat-error
                                                        *ngIf="form.get('teacherNumber')?.touched  && form.get('teacherNumber')?.errors?.required">
                                                        Teacher Code is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>First Name </mat-label>
                                                    <input maxlength="20" matInput placeholder="First Name" required name="firstname"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="firstName">
                                                    <mat-error
                                                        *ngIf="form.get('firstName')?.touched  && form.get('firstName')?.errors?.required">
                                                        First Name is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('firstName')?.hasError('pattern')">
                                                        Please enter valid First Name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Last Name</mat-label>
                                                    <input maxlength="20" matInput placeholder="Last Name" required name="lastName"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="lastName">
                                                    <mat-error
                                                        *ngIf="form.get('lastName')?.touched  && form.get('lastName')?.errors?.required">
                                                        Last Name is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('lastName')?.hasError('pattern')">
                                                        Please enter valid Last Name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <!-- <mat-label>Teacher contact Number</mat-label> -->
                                                    <ngx-mat-intl-tel-input style="border: none;"
                                                        [preferredCountries]="['au', 'us']" [enablePlaceholder]="true"
                                                        [enableSearch]="true" name="ContactNumber"
                                                        (keyup)="phoneLength($event)" formControlName="contactNumber"
                                                        #phone>
                                                    </ngx-mat-intl-tel-input>
                                                    <mat-hint>eg : {{phone.selectedCountry.placeHolder}}</mat-hint>

                                                    <mat-error
                                                        *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                        Teacher contact is required
                                                    </mat-error>
                                                    <mat-error *ngIf="phoneShow">
                                                        Phone length should be less than 10 digits</mat-error>
                                                </mat-form-field>
                                            </div>


                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Teacher Email</mat-label>
                                                    <input matInput placeholder="email" required name="email"
                                                        formControlName="email" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50">
                                                    <mat-error
                                                        *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                        Teacher Email is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('email')?.hasError('pattern')">
                                                        Please enter valid Email
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Date Of Birth</mat-label>
                                                    <input (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                        matInput placeholder="Date Of Birth" required name="DateOfBirth"
                                                        formControlName="dateOfBirth" [matDatepicker]="picker">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-error
                                                        *ngIf="form.get('dateOfBirth')?.touched  && form.get('dateOfBirth')?.errors?.required">
                                                        Field is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>



                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Gender</mat-label>
                                                    <mat-select placeholder="Gender" required name="gender"
                                                        formControlName="genderID">
                                                        <mat-option *ngFor="let gender of genders "
                                                            [value]="gender.genderID">
                                                            {{gender.genderName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('genderID')?.touched  && form.get('genderID')?.errors?.required">
                                                        Gender is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                    </div>




                                    <div class="proSection">
                                        <h5><i class="fas fa-plus-square mr-2"></i> Additional Information</h5>
                                        <div class="row-flex">

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-select placeholder="Blood Group"
                                                        formControlName="bloodGroupID">
                                                        <mat-option value="" disabled>Select Blood Group</mat-option>
                                                        <mat-option *ngFor="let bloodGroup of bloodGroups"
                                                            [value]="bloodGroup.bloodGroupID">
                                                            {{bloodGroup.bloodGroupName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Address</mat-label>
                                                    <input matInput placeholder="address" maxlength="100" formControlName="address">

                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Country</mat-label>
                                                    <mat-select placeholder="Country"
                                                        (valueChange)="GetStateList($event)"
                                                        formControlName="countryID">
                                                        <mat-option value="" disabled>Select Country</mat-option>
                                                        <mat-option *ngFor="let country of countries"
                                                            [value]="country.countryID">
                                                            {{country.countryName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>State</mat-label>
                                                    <mat-select placeholder="State" formControlName="stateID">
                                                        <mat-option value="" disabled>Select State</mat-option>
                                                        <mat-option *ngFor="let state of states"
                                                            [value]="state.stateID">
                                                            {{state.stateName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>City</mat-label>
                                                    <input maxlength="20" matInput placeholder="city"
                                                        formControlName="city">

                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Zip Code</mat-label>
                                                    <input (keypress)="allowAlphaNumber($event)" maxlength="10" matInput
                                                        placeholder="Zip Code" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                        formControlName="zipCode">
                                                    <mat-error *ngIf="form.get('zipCode')?.hasError('pattern')">
                                                        Please enter valid Zipcode
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="proSection">
                                        <h5><i class="fas fa-star mr-2"></i>Skills & Social Details</h5>
                                        <div class="row-flex">

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Profile Summary</mat-label>
                                                    <textarea input matInput
                                                        placeholder="Profile Summary"
                                                        formControlName="profileSummary" maxlength="250"></textarea>

                                                </mat-form-field>
                                            </div>


                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Teacher Skills </mat-label>
                                                    <mat-select placeholder="Stdenty Skills"
                                                        formControlName="teacherSkillsID">
                                                        <mat-option *ngFor="let skill of teacherSkills "
                                                            [value]="skill.teacherSkillID">
                                                            {{skill.teacherSkillsName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Facebook Profile Link</mat-label>
                                                    <input matInput placeholder="Facebook Profile Link"
                                                        formControlName="facebookLink" maxlength="250">

                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>LinkedIn Profile Link</mat-label>
                                                    <input matInput type="url" placeholder="LinkedIn Profile Link"
                                                        formControlName="linkedInlink" maxlength="250">

                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="extraformField == true" class="proSection">
                                    <h5>Custom Fields</h5>
                                    <div class="row-flex">
                                        <div class="col s12 form-group">
                                            <formio [form]='jsonFormData' [options]="formioOptions" [submission]="initialFormValues" (change)="onChange($event)"></formio>
                                        </div>
                                    </div>
                                </div> -->
                                    <div class="proSection text-right">
                                        <button type="submit" class="btn btn-primary btn-sm">Update <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                                            <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                                              <path id="Path" d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z" transform="translate(-3 -5.95)" fill="#fff"/>
                                            </g>
                                          </svg>
                            </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #uploadPopup>
    <div class="dialog-md">
        <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
        <div class="dialogTitle">
            <h5 mat-dialog-title>Select Image Area</h5>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

            </div>

        </mat-dialog-content>
    </div>
    <div class="proSection text-right">
        <button class="btn btn-primary btn-sm" mat-dialog-close (click)="finalUpload()" matRipple>Save</button>
    </div>
</ng-template>