<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>Manage Branches</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Manage Branches</li>
                </ol>
            </div>
        </div>
       
        <div class="">
          <div class="add_button">
            <button class="addbtn btn" matRipple>Add Branch</button>
          </div>
        </div>
    </div>
   
    <div class="cardWidget">
        <div class="matCard">
          <div class="matCrdTitle centerItemVert justify-content-between">
            <div class="cardTitle centerVTItems justify-content-between">
              <h3>Branches</h3>
            </div>
  
          </div>
  
          <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 d-table">

              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S.No </th>
                <td mat-cell *matCellDef="let element;let i = index;"> {{i+1}} </td>
              </ng-container>
        
              <!-- Name Column -->
              <ng-container matColumnDef="organizationID">
                <th mat-header-cell *matHeaderCellDef>Branch ID</th>
                <td mat-cell *matCellDef="let element"> {{element.organizationID}} </td>
              </ng-container>
        
              <!-- Weight Column -->
              <ng-container matColumnDef="organizationName">
                <th mat-header-cell *matHeaderCellDef>Org Name</th>
                <td mat-cell *matCellDef="let element"> {{element.organizationName}} </td>
              </ng-container>
        
              <!-- Symbol Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.email}}</td>
              </ng-container>

              <ng-container matColumnDef="contactNumber">
                <th mat-header-cell *matHeaderCellDef> Contact Number </th>
                <td mat-cell *matCellDef="let element"> {{element.contactNumber}}</td>
              </ng-container>

              <!-- <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef> Username </th>
                <td mat-cell *matCellDef="let element"> {{element.userName}}</td>
              </ng-container> -->

              <ng-container matColumnDef="password">
                <th mat-header-cell *matHeaderCellDef> Password</th>
                <td mat-cell *matCellDef="let element"> {{element.password}}</td>
              </ng-container>

              <ng-container matColumnDef="subDomainName">
                <th mat-header-cell *matHeaderCellDef> Domain</th>
                <td mat-cell *matCellDef="let element"> {{element.subDomainName}}</td>
              </ng-container>

              <!-- <ng-container matColumnDef="connectionStringName">
                <th mat-header-cell *matHeaderCellDef> Connection Name</th>
                <td mat-cell *matCellDef="let element"> {{element.connectionStringName}}</td>
              </ng-container> -->

              <ng-container matColumnDef="organizationDatabaseName">
                <th mat-header-cell *matHeaderCellDef> Database Name </th>
                <td mat-cell *matCellDef="let element"> {{element.organizationDatabaseName}</td>
              </ng-container>

        
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
           </div>
        </div>
      </div>
</div>
