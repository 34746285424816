import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { SalaryService } from 'src/app/services/salary.service';
@Component({
  selector: 'app-salarydetails',
  templateUrl: './salarydetails.component.html',
  styleUrls: ['./salarydetails.component.css'],
})
export class SalarydetailsComponent implements OnInit {
  salaryFormFields: any[];
  salaryFormDB: any[] = [];
  teacherID:number;
  salaryDetailsForm: FormGroup;
  form: FormGroup;
  salaryBeforeDeduction: Number = 0;
  deductionAmount: Number = 0;
  salaryAfterDeduction: Number = 0;

  constructor(
    private activatedRoute:ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private salaryService: SalaryService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.teacherID = params.id==undefined?null:params.id;
    });

    this.xray();
    this.getAllSalaryFormFields();
    this.buildSalaryForm();
    this.getSalaryForm();
  }

  buildSalaryForm(){
    this.salaryDetailsForm = this.fb.group({  
      salaryFormItems: this.fb.array([])  
    });
    
    // this.salaryDetailsForm = this.fb.group({
    //   count:0,
    //   salaryFormList: this.fb.array([]),
    // })
  }

  xray(){
    this.form = this.fb.group({
    });
  }

  createItem(Data: any){
    const salaryFormItems = this.salaryDetailsForm.controls.salaryFormItems as FormArray;
    salaryFormItems.push(this.formBuilder.group({
      salaryFormID: Data.salaryFormID,  
      salaryFormFieldID: Data.salaryFormFieldID,  
      salaryFormFieldName: Data.salaryFormFieldName,
      salaryFormFieldValue: Data.salaryFormFieldValue,
      formType: 1,
      userID: this.teacherID 
    }));
  }

  addCreds() {
    const creds = this.form.controls.credentials as FormArray;
    creds.push(this.fb.group({
      username: '',
      password: '',
    }));
  }

  // get salaryFormList(): FormArray {
  //   return this.salaryDetailsForm.get('salaryFormList') as FormArray
  // }

  getAllSalaryFormFields() {
    this.salaryService.GetSalaryFormFields().subscribe((res: any) => {
      if (res.status > 0) {
        this.salaryFormFields = res.responseData;
        this.salaryFormFields.forEach((x) => {
           this.salaryDetailsForm.addControl(
            x.salaryFormFieldID,
            new FormControl(x.salaryFormFieldName, Validators.required)
          );
        });
        this.calculateSalary();
        console.log('this.salaryFormFields', this.salaryFormFields);
      }
    });
  }

  
  getSalaryForm(){
    this.salaryService.GetSalaryFormByTeacherID(this.teacherID).subscribe((res: any) => {
      if (res.status > 0) {
        this.salaryFormDB = res.responseData;
        this.salaryFormDB.forEach(element => {
          //this.createItem(element)

          //this.form.addControl(element.salaryFormFieldName, this.formBuilder.control(''));
          if (element.isRequired == true) {
            this.form.addControl(element.salaryFormFieldID, this.formBuilder.control(element.salaryFormFieldValue, [Validators.min(1), Validators.required]));
          }
          else{
            this.form.addControl(element.salaryFormFieldID, this.formBuilder.control(element.salaryFormFieldValue, [Validators.min(0)]));
          }
          

        });
        console.log('this.salaryFormDB GetSalaryFormByTeacherID', this.salaryFormDB);
        let salaryFormItems = this.salaryDetailsForm.get('salaryFormItems') as FormArray;
        console.log("salaryFormItems", salaryFormItems);
        this.calculateSalary();
      }
    });
  }


  onBlurMethodtxt(value:any, salaryFormItem:any, salaryFormFieldValue:any)
  {
    console.log(this.commonService.allowAlphabetOnly(value))
    this.salaryFormDB.find((item: any) => item.salaryFormFieldID === salaryFormItem.salaryFormFieldID).salaryFormFieldValue = Number(value.target.value);

    this.calculateSalary();
    console.log("this.salaryFormDB", this.salaryFormDB)
    //this.salaryFormDB.find((g: any) => g.groupID == grp.groupID).fields.find((f: any) => f.fieldName == fieldName).fieldValue = value.target.value;
  }

  calculateSalary(){
    this.deductionAmount = 0;
    this.salaryBeforeDeduction = 0; 
    this.salaryAfterDeduction = 0;

    this.salaryFormDB.forEach(element => {
      if (element.deductionType == true) {
        this.deductionAmount = Number(this.deductionAmount) +  Number(element.salaryFormFieldValue);
      }
      else{
        this.salaryBeforeDeduction = Number(this.salaryBeforeDeduction) + Number(element.salaryFormFieldValue);
      }
     
    });
    this.salaryAfterDeduction = Number(this.salaryBeforeDeduction) - Number(this.deductionAmount);
  }

  // inputTouched() {}

  // get f() {
  //   console.log("this.salaryDetailsForm.controls", this.salaryDetailsForm.controls)
  //   return this.salaryDetailsForm.controls;
  // }

  // setIsShown(completed: boolean, fieldItem: any) {
  //   console.log('setIsShown', completed);

  //   let index = this.salaryFormFields.indexOf(fieldItem);
  //   fieldItem.isShown = completed;
  //   this.salaryFormFields[index] = fieldItem;

  //   console.log('setIsShown salaryFormFields', this.salaryFormFields);
  // }

  // control = new FormControl();
  // setDeductionType(completed: boolean, fieldItem: any) {
  //   console.log('setDeductionType', completed);

  //   let index = this.salaryFormFields.indexOf(fieldItem);
  //   fieldItem.deductionType = completed;
  //   this.salaryFormFields[index] = fieldItem;

  //   console.log('setDeductionType salaryFormFields', this.salaryFormFields);
  // }

  onSubmit() {
    console.log("this.form", this.form)
    if (this.form.valid === true) {
      console.log("this.salaryFormDB", JSON.stringify(this.salaryFormDB))
      let data = {
        salaryFormDto: [{}],
      };

      this.salaryFormDB.forEach(element => {
        let obj = {
          salaryFormFieldID: element.salaryFormFieldID,
          salaryFormFieldName: element.salaryFormFieldName,
          salaryFormFieldValue: element.salaryFormFieldValue,
          salaryFormID: element.salaryFormID,
          formType: 1,
          userID: this.teacherID,
        };
        data.salaryFormDto.push(obj)
      });
      data.salaryFormDto.splice(0,1);
      this.salaryService.UpdateSalaryForm(data).subscribe((res: any) => {
        if (res.status > 0) {
            this.toaster.success(res.message, 'Success');
            this.getSalaryForm();
        }
        else{
          this.toaster.error(res.message, 'error');
        }
      });
    }
  }
}
