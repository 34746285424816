import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdminStaffService } from 'src/app/services/admin-staff.service';
import { StudentService } from 'src/app/services/student.service';
import { Location } from '@angular/common'
@Component({
  selector: 'app-view-admin-staff',
  templateUrl: './view-admin-staff.component.html',
  styleUrls: ['./view-admin-staff.component.css']
})
export class ViewAdminStaffComponent implements OnInit {
  form: FormGroup;
  adminID: number;
  data: string;
  firstName: string;
  lastName: string;
  profilePhoto: string = '../../../assets/img/display.jpg';
  contactNumber: string
  address: string
  zipCode: string
  stateName: string
  countryName: string
  city: string
  userName: string
  email: string




  constructor(

    private activatedRoute: ActivatedRoute,
    private adminService: AdminStaffService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.adminID = params.id == undefined ? null : params.id;
    });
    //this.adminUserID = this.activatedRoute.snapshot.params.Id
    this.adminService.GetAdminListById(this.adminID).subscribe((res: any) => {
      this.firstName = res.responseData.firstName;
      this.lastName = res.responseData.lastName;
      this.userName = res.responseData.userName;
      this.profilePhoto = res.responseData.profilePhoto ? res.responseData.profilePhoto : '../../../assets/img/display.jpg';
      this.contactNumber = res.responseData.contactNumber;
      this.address = res.responseData.address;
      this.city = res.responseData.city;
      this.countryName = res.responseData.countryName;
      this.stateName = res.responseData.stateName;
      this.zipCode = res.responseData.zipCode;
      this.email = res.responseData.email;


      console.log(res.responseData);


    });
  }
  back() {
    this.location.back()
  }

}




