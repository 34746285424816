import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { StudentService } from 'src/app/services/student.service';
import { Pipe, PipeTransform } from '@angular/core';

export interface Task {
  name: string;  
  completed: boolean;
  subtasks?: Task[];
}
export interface Sms {
  name: string;  
  completed: boolean;
  subtaskssms?: Sms[];
}
@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.css']
})
export class ActivityListComponent implements OnInit {
  classToggled = false;
  reviewCheck:boolean = true;
  pvtNote:boolean = true;
  profile:boolean=true;
  payment:boolean=true;
  invoice:boolean=true;
  @Input() StudentID: number;
  
  public toggleField() {
    this.classToggled = !this.classToggled;  
  }
  constructor(private studenService: StudentService,
    private toaster: ToastrService,) { }

  ngOnInit(): void {
    sessionStorage.setItem('Loader', '1');
    this.commonfunc();
    
  }
  commonDataSource:any=[]
  task: Task = {
    name: 'Email',
    completed: true,
    subtasks: [
      {name: 'Login Invitation', completed: true},
      {name: 'Login Credentials Update', completed: true},
      {name: 'Password Reset', completed: true},
      {name: 'New Password', completed: true},
      {name: 'Invoice Notification', completed: true},
      {name: 'Payment Notification', completed: true},
      {name: 'Notice', completed: true},
      {name: 'Custom', completed: true}
    ]
  };

  sms: Sms = {
    name: 'SMS',
    completed: true,
    subtaskssms: [
      {name: 'Login Invitation', completed: true},
      {name: 'Invoice Notification', completed: true},
      {name: 'Absent Notification', completed: true},
      {name: 'Custom', completed: true}
    ]
  };

  allComplete: boolean = true;
  allComplete1: boolean = true;
dataSouceForSMS:any
dataSourceForEmail:any
  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    this.allComplete1 = this.sms.subtaskssms != null && this.sms.subtaskssms.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  
  }

  someComplete1(): boolean {  
    if (this.sms.subtaskssms == null) {
      return false;
    }
    return this.sms.subtaskssms.filter(t => t.completed).length > 0 && !this.allComplete;
  }


  setAll(completed: boolean) {

    this.lastfilteredEmail=[]
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => t.completed = completed);
    this.commonfunc();
  }
allcheckedSMS:any
  setAll1(completed: boolean) {
    this.allcheckedSMS=[]
    this.allComplete = completed;
    if (this.sms.subtaskssms == null) {
      return;
    }
    this.sms.subtaskssms.forEach(t => t.completed = completed);
    this.commonfunc()
   }
  findAllEmail:any=[]
  lastfilteredEmail:any
  checkedBox(event:any,checking:any){
    this.lastfilteredEmail=[]
  if (this.task.subtasks != null) {
    this.findAllEmail=this.task.subtasks.findIndex(t => t.name === event);
    this.task.subtasks[this.findAllEmail].completed=checking.checked
    console.log("key For Email==============================",this.task.subtasks)
    }
    if(this.task.subtasks!=null){
      for(let i=0;i<this.task.subtasks.length;i++){
        if(this.task.subtasks[i].completed==true){
          this.lastfilteredEmail.push(this.task.subtasks[i].name)
          console.log("key For Email==============================",this.lastfilteredEmail)
        }
      }
     }

     let obj={
      'FilteredEmail':this.lastfilteredEmail
    }




  }



//SMS check box hide show
finddArray:any=[]
lastfiltered:any
showhideSMS(event:any,checkedBox:any){
  this.lastfiltered=[]
  if (this.sms.subtaskssms != null) {
    this.finddArray=this.sms.subtaskssms.findIndex(p=>p.name===event)
  this.sms.subtaskssms[this.finddArray].completed=checkedBox.checked
  console.log("key For SMS==============================",this.sms.subtaskssms)
  }
  if(this.sms.subtaskssms!=null){
 for(let i=0;i<this.sms.subtaskssms.length;i++){
   if(this.sms.subtaskssms[i].completed==true){
     this.lastfiltered.push(this.sms.subtaskssms[i].name)
   }
 }
}
 console.log(this.lastfiltered);
let obj={
  'FilteredSMS':this.lastfiltered
}

this.studenService.FilteredSMS(obj).subscribe((res: any) => {
  if (res.status > 0) {
    this.toaster.success(res.message);
    this.dataSouceForSMS=''
    this.dataSouceForSMS=res.responseData
    this.lastfiltered=''
    console.log("Value Inside DataSource For Checked Only",this.dataSouceForSMS)
    //this.CommonFunction();
//put values into data source
//List of all sms accourding to checkbox

  }
  else {
    this.toaster.error(res.message);
    this.lastfiltered=''
  }
});

}


//End SMS check box hide show
//review checkbox work start

//review checkbox work start


//Work For Private note 

//Work end For Private note 



 tt:any=[]
commonfunc(){
  // console.warn(this.reviewCheck);
  //console.warn(this.task.subtasks);
  //console.warn(this.sms.subtaskssms);
  // console.warn(this.pvtNote);
  let obj = {
    'review': this.reviewCheck,
    'email':this.task.subtasks,
    'sms': this.sms.subtaskssms,
    'privateNote': this.pvtNote,
    'StudentID':this.StudentID,
    'profile':this.profile,
    'payment':this.payment,
    'invoice':this.invoice,
  }
  console.log('type of',typeof(this.task.subtasks))
  console.log("to send objt",obj);
  this.studenService.FilteredSMS(obj).subscribe((res: any) => {
    if (res.status > 0) {
      this.dataSouceForSMS=''
      this.lastfiltered=''

      res.responseData = res.responseData.sort((x:any, y:any) => {  
            <any>new Date(x.createdDate) - <any>new Date(y.createdDate)
              
            });
            //this.dataSouceForSMS=res.responseData 
    console.log("Now the shorted Date",res.responseData);
    
            const groups = res.responseData.reduce((groups:any, game:any) => {
              const date = game.createdDate.split('T')[0];
              if (!groups[date]) {
                groups[date] = [];
              }
              groups[date].push(game);
              return groups;
            }, {});
            
            // Edit: to add it in the array format instead
            const groupArrays = Object.keys(groups).map((date) => {
              return {
                date,
                games: groups[date]
              };
            });
            
            this.dataSouceForSMS=groupArrays;
            console.log("Copiede By Google",groupArrays);            
         }
    else {
      this.toaster.error("No Record Found");
      this.lastfiltered='';
      this.dataSouceForSMS='';
    }
  });






}



delete(type:any,ID:any){
  console.log("Type And ID",type,ID)

  let obj={
    'Type':type,
    'ID':ID
  }
  this.studenService.FilteredEmail(obj).subscribe((res: any) => {
    if (res.status > 0) {
      this.dataSourceForEmail=''
      this.dataSourceForEmail=res.responseData
      this.ngOnInit()
      console.log("Value Inside DataSource For Checked Only",res.responseData)
      //this.CommonFunction();
  //put values into data source
  //List of all sms accourding to checkbox
  
    }
    else {
      this.lastfiltered=''
    }
  }); 













}


}
