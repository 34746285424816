<div class="reportCardsWidget mt-3 mt-md-0">
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex btnsWidget reportButtons">
    </div>
  </div>

  <div class="row-flex">
    <div class="col-md-4 col-xl-3 s2">
      <div class="manageCardLeft">
        <div class="template-group-title my-2">Report Card Settings</div>
        <div *ngIf="instituteGroup != null" class="sub-head">Institute</div>
        <div class="list">
          <div *ngFor="let item of instituteGroup; let i = index">
            <div *ngIf="item.fieldName == 'Custom title'" class="withcmts">
                <mat-checkbox  (change)="itemOnChangeInstitute(i)" [checked]="item.checked"
                    class="example-margin matbox">Custom title
                </mat-checkbox>
                <a (click)="addComment(titleComment)" class="template-group-title text-underline addcomment">Add Comments</a>
            </div>
            <mat-checkbox *ngIf="item.fieldName != 'Custom title'" (change)="itemOnChangeInstitute(i)" [checked]="item.checked" class="example-margin matbox">
                {{ item.fieldName }}
            </mat-checkbox>
          </div>
        </div>
        <div *ngIf="studentGroup != null" class="sub-head">Student</div>
        <div class="list">
          <mat-checkbox  *ngFor="let item of studentGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeStudent(i)">{{ item.fieldName }}</mat-checkbox>


          <div class="searchSelect form-group">
            <mat-label class="mutilselectlable">Custom Fields</mat-label>
            <p-multiSelect *ngIf="studentGroup | customRepotCard: 'Custom fields'"
            [options]="toppingList" defaultLabel="Custom Fields"  [formControl]="toppings" optionLabel="fieldName" display="chip" (onClick)="studentCusChk = elm.value; changeStudentField($event)" #elm>
            </p-multiSelect>
          </div>
        </div>
        <div *ngIf="assessmentsGroup != null" class="sub-head">Assessment</div>
        <div class="list">
          <div *ngFor="let item of assessmentsGroup; let i = index">
            <mat-checkbox class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAssessments(i)">{{ item.fieldName }}</mat-checkbox>

            <mat-select class="ml-5" [(value)]="commentPosition"
            *ngIf="(item.fieldName == 'Comments') && (assessmentsGroup | customRepotCard: 'Comments') " placeholder="Comments">
                <mat-option [value]="1">Display in bottom</mat-option>
                <mat-option [value]="2">Display in Score Table</mat-option>
            </mat-select>
          </div>
        </div>
         <div *ngIf="attendanceGroup != null" class="sub-head">Attendance</div>
        <div class="list">
          <mat-checkbox *ngFor="let item of attendanceGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAttendance(i)">{{ item.fieldName }}</mat-checkbox>
          <mat-checkbox *ngFor="let item of attendanceCustomGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAttendanceCustom(i)">{{ item.fieldName }}</mat-checkbox>
        </div> 
        <div *ngIf="othersGroup != null" class="sub-head">Others</div>
        <div class="list">
          <div *ngFor="let item of othersGroup; let i = index">
            <mat-checkbox  [checked]="item.checked" (change)="itemOnChangeOthers(i)" class="example-margin matbox">{{ item.fieldName }} </mat-checkbox>
            <div *ngIf="item.fieldName == 'Character Traits'" class="searchSelect form-group">
              <mat-label class="mutilselectlable">Character Traits</mat-label>
              <p-multiSelect *ngIf="othersGroup | customRepotCard: 'Character Traits'"
              [options]="toppingList1" defaultLabel="Character Traits"  [formControl]="toppings1" optionLabel="fieldName" display="chip" (onClick)="traitsCusChk = elm.value; changeCharacterTraitField($event)" #elm>
              </p-multiSelect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" col-md-8 col-xl-9 s10">

      <div class="report-card">
        <div class="report-custom">
          <!-- Report card header block -->
          <div class="report-card-header-block">
            <div *ngIf="instituteGroup | customRepotCard: 'Custom title'">
              <div class="text-center"><p class="customTitle mb-2">{{editorModel.customCommentTitle}}</p></div>
              <div class="text-left" [innerHTML]="editorModel.customCommentValue"></div>
            </div>
            <div class="row">
              <div class="col-md-3 extra-header-info">
             
              </div>
              <div class="col-md-6 inst-info-block">
               
                <div *ngIf="instituteGroup | customRepotCard: 'Logo'"  class="text-center">
                  <div class="inst-image-block">
                    <img width="80px" [src]="croppedImage" class="inst-image"
                      alt="Inst. Logo" />
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div *ngIf="instituteGroup | customRepotCard: 'Institute name'" class="col-md-3 inst-extra-info-block">
                <div class="text-center inst-extra-info">
                  <span class="inst-name">Institute Name</span>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <!-- Report card Header block -->
          <!-- Report card Student Information -->
          <div class="reportcard-student-info">
            <table class="table table-bordered table-vertical-center scores-table">
              <tbody>
                <tr *ngIf="studentGroup | customRepotCard: 'Name'" class="student-name">
                  <td colspan="4"><b> Name:</b> Name</td>
                </tr>
                <tr *ngIf="studentGroup | customRepotCard: 'Academic session name'" class="student-name">
                  <td colspan="4"><b> Academic Sesssion:</b> Name of the Academic Session</td>
                </tr>
                <tr class="student-name hidden">
                  <td colspan="4">
                    <b>Academic Session:</b> Name of Academic Session
                  </td>
                </tr>
                <tr class="stud-class-section">
                  <td *ngIf="studentGroup | customRepotCard: 'Class'" class="" style="width: 15%">
                    <b>Class</b>
                  </td>
                  <td *ngIf="studentGroup | customRepotCard: 'Class'"class="" style="width: 35%">Class</td>
                  <td *ngIf="studentGroup | customRepotCard: 'Section'"class="" style="width: 15%">
                    <b>Section</b>
                  </td>
                  <td *ngIf="studentGroup | customRepotCard: 'Section'"class="" style="width: 35%">
                    Section
                  </td>
                </tr>
                <tr>
                  <td *ngIf="studentGroup | customRepotCard: 'Admission_no'" class="" style="width: 15%">
                    <b>Admission No</b>
                  </td>
                  <td *ngIf="studentGroup | customRepotCard: 'Admission_no'" class="" style="width: 35%">
                    Admission No
                  </td>
                </tr>
                
              </tbody>
            </table>
            <div class="clearfix"></div>
          </div>
          <!-- Report card Student Information -->
          <!-- Report card Assessment Score Information -->
          <div class="report-card-assessment-score-block">
            <!-- <before-scores-table></before-scores-table> -->
            <h4 style="margin-top: 5px"></h4>
            <div class="temp2-scores-block">
              <div class="cat-view-options">
                <table class="table table-bordered  table-vertical-center scores-table">
                  <thead>
                    <tr>
                      <th class="center">Subjects</th>
                      <th class="center" colspan="3">Category 1</th>
                      <th class="center" colspan="3">Category 2</th>
                      <th class="center" colspan="3">Category 3</th>
                      <th class="center" colspan="2">Combined</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="center">Core</th>
                      <th class="center">Marks</th>
                      <th class="center">%</th>
                      <th class="center">Grade</th>
                      <th class="center">Marks</th>
                      <th class="center">%</th>
                      <th class="center">Grade</th>
                      <th class="center">Marks</th>
                      <th class="center">%</th>
                      <th class="center">Grade</th>
                      <th class="center">% Marks</th>
                      <th class="center">Grade</th>
                    </tr>

                    <tr>
                      <td>Subject 1</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <!-- Overall For subject -->
                      <!-- Overall for subject -->
                      <td class="center">-</td>
                      <td class="center">-</td>
                    </tr>
                    <tr>
                      <td>Subject 2</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <!-- Overall For subject -->
                      <!-- Overall for subject -->
                      <td class="center">-</td>
                      <td class="center">-</td>
                    </tr>
                    <tr>
                      <td>Subject 3</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <!-- Overall For subject -->
                      <!-- Overall for subject -->
                      <td class="center">-</td>
                      <td class="center">-</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <!-- Core combined Total -->
                      <td class="center">0.00</td>
                      <td class="center">-</td>
                    </tr>
                    <tr>
                      <th>Subsidiary</th>
                      <th class="center">Marks</th>
                      <th class="center">%</th>
                      <th class="center">Grade</th>
                      <th class="center">Marks</th>
                      <th class="center">%</th>
                      <th class="center">Grade</th>
                      <th class="center">Marks</th>
                      <th class="center">%</th>
                      <th class="center">Grade</th>
                      <th class="center">% Marks</th>
                      <th class="center">Grade</th>
                    </tr>
                    <tr>
                      <td>Subject 4</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <!-- Overall Score For elective subject -->

                      <td class="center">-</td>
                      <td class="center">-</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <td class="center">-</td>
                      <!-- elective combined Total -->
                      <td class="center">-</td>
                      <td class="center">-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="clearfix"></div>
            <!-- Consolidate Score for grading period  -->
            <div class="clearfix"></div>

            <!-- Consolidate Score for grading period  -->
          </div>
          <!-- Report card Assessment Score Information -->
          <!-- Reportcard Extra block like Grading Scale attendance information and other information -->
          <div class="reportcard-extra-block page-break-avoid">
            <!-- Grading Scale information -->
            <div class="row">
              <div *ngIf="othersGroup | customRepotCard: 'Grading structure for final score'" class="col-md-6 overall-score-grading-block">
                <div class="block-border extra-block-sm">
                  <h4 class="mb10">Grading structure for final score</h4>
                  <div></div>
                </div>
              </div>
              <!-- Grading Scale information -->
              <div class="offset-1 col-md-5 attendance-checter-block">
                <!-- Attendance info table -->
                <div class="attendance-block">
                  <table  *ngIf="attendanceGroup | customRepotCard: 'Display attendance'"  class="table  table-bordered text-center attendance-summary">
                    <thead>
                      <tr>
                        <th class="center" style="border: 0px">Attendance</th>
                        <th class="center" style="border: 0px">0.00%</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let item of attendanceCustomGroup">
                      <tr *ngIf="attendanceCustomGroup | customRepotCard: item.fieldName">
                          <td [ngStyle]="{ color: item.colorCode }">{{ item.fieldName }}</td>
                          <td [ngStyle]="{ color: item.colorCode }" class="center">
                              <span *ngIf="attendanceGroup | customRepotCard: 'Display in numbers'">0<span *ngIf="!(attendanceGroup | customRepotCard: 'Disable Max Status Count')">/0</span></span> <span *ngIf="attendanceGroup | customRepotCard: 'Display in percentage'"> (0.00%)</span>
                          </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Attendance info table -->
                <!-- Other info table -->
                <div class="report-other-info-block mt-3">
                  <table *ngIf="othersGroup | customRepotCard: 'Character Traits'" class="table table-bordered  table-vertical-center scores-table character-traits-table">
                    <thead>
                      <tr>
                        <th>Character</th>
                        <th class="center">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of characterTraitsGroup">
                        <td *ngIf="characterTraitsGroup | customRepotCard: item.fieldName">
                          {{ item.fieldName }}
                        </td>
                        <td *ngIf="characterTraitsGroup | customRepotCard: item.fieldName"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Other info table -->
              </div>
              
            </div>
            <div class="clearfix"></div>
          </div>
          <!-- Reportcard Extra block like Grading Scale attendance information and other information -->
          <!-- Reportcard Footer block it contain reamarks-->
          <div class="reportcard-footer-block page-break-avoid">
            <div class="row">
              <div class="col-md-6 col-sm-6 report-signature-block">
                <div *ngIf="othersGroup | customRepotCard: 'Contents block'" class="signature-block">
                  <h5>Class Teacher's Signature: ___________________</h5>
                  <br />
                  <h5>Parents Signature: ___________________</h5>
                </div>
              </div>
              <div class="offset-2 col-md-4 col-sm-6 student-progress-block">
                <table *ngIf="othersGroup | customRepotCard: 'Contents block'" class="table table-bordered progress-block">
                  <tbody>
                    <tr>
                      <td class="center" style="width: 50%">Progress</td>
                      <td class="center" style="width: 50%"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-12" style="margin-top: 35px">
                <table *ngIf="othersGroup | customRepotCard: 'Signature block'" class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="center">School Stamp</th>
                      <th class="center">Principal's Signature</th>
                      <th class="center">Ministry</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div style="min-height: 50px"></div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- Reportcard Footer block -->
        </div>
      </div>
    </div>
  </div>

</div>
<div class="btnsWidget form-group17 text-right">
  <button type="button" (click)="onSubmit()" class="btn btn-primary btn-sm m-0 ">Save</button>
  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Cancel</button>
</div>

<ng-template #titleComment>
  <div class="dialog-lg">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle">
        <h2 mat-dialog-title><i style="color: #808080;">Custom title</i> - <b>Add Contents</b></h2>
      </div>
      <form>
          <div class="row">
              <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Block Title</mat-label>
                  <input matInput name="blockTitle" [value]="editorModel.customCommentTitle" placeholder="Block Title" />
              </mat-form-field>
          </div>
          <div class="row">
              <ejs-richtexteditor #titleCommentEditor [toolbarSettings]='tools' [value]="editorModel.customCommentValue" >
              </ejs-richtexteditor>
          </div>
  
        <div class="btnsWidget form-group17 text-right">
          <button type="button" (click)="addCustomTitleComment()" class="btn btn-primary btn-sm" mat-dialog-close matRipple>Save</button>
          <button type="button" class="btn btn-reset btn-sm" mat-dialog-close matRipple>Close</button>
        </div>  
      </form>
    </div>
</ng-template>
