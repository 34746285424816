import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';

@Component({
  selector: 'app-default-template',
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.css']
})
export class DefaultTemplateComponent implements OnInit, OnChanges {

  @Input() studentData: any; 

  studentDetails: any;
  attendanceDetails: any;
  orgDetails: any;
  orgName: any;
  orgContact: any;
  orgEmail: any;
  orgAddress: any;
  croppedImage: any;
  attendanceCustomGroup: any;
  assessmentDetails: any;

  constructor(
    private manageAssessmentService: ManageAssessmentAndGradingsService,
  ) { 
    this.orgDetails = localStorage.getItem('instituteDetails');
    if(this.orgDetails != "" && this.orgDetails != null) {
      this.orgDetails = JSON.parse(this.orgDetails);
      if(this.orgDetails != null) {
        this.orgName = this.orgDetails.orgName;
        this.orgContact = this.orgDetails.contact;
        this.orgEmail = this.orgDetails.email;
        this.orgAddress = this.orgDetails.address;
      }
    }
    if(localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
      this.croppedImage = localStorage.getItem('Logo');
    }else {
      this.croppedImage = '../../../../../../assets/img/admin/org_logo.png';
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }
  assessmentName: any;
  assessmentCatWeight: any = [];
  ngOnInit(): void {
    this.studentDetails = this.studentData.studentDetails;
    this.attendanceDetails = this.studentData.attendanceDetais;
    this.assessmentDetails = this.studentData.studentAssessmentDetails;
    this.assessmentName = this.studentData.studentAssessmentName;
    this.assessmentCatWeight = this.studentData.assessmentCatWeight;
    console.warn(this.assessmentDetails);
    this.getAttendanceCustomField();
    this.calculateAssessment();
  }
  percentage: any;
  getAttendanceCustomField() {
    this.manageAssessmentService.GetAllAttendanceField().subscribe((res: any) => {
      this.attendanceCustomGroup = res.responseData;
      this.attendanceCustomGroup.forEach((element: any) => {
        if(element.fieldName != ''){
          let chk = this.attendanceDetails.filter((x: any) => x.statusName == element.fieldName);
          element.total = Math.floor(chk[0].total);
          element.totalDays = chk[0].totalDays;
          element.percentage = chk[0].percentage;
          if(chk[0].isRelatedWithPresentAbsent && chk[0].attendanceStatusMasterID != 1 && chk[0].attendanceStatusMasterID != 2)
            element.showPercentage = false;
          else
            element.showPercentage = true;
        }
        if(element.attendanceStatusMasterID == 1 || element.attendanceStatusMasterID == 2)
          element.checked = true;
        else
          element.checked = false;

      });
      this.percentage=this.attendanceDetails.filter((z:any)=>z.attendanceStatusMasterID
     ==1)
      console.warn(this.attendanceCustomGroup);
    });
  }

  score: any = 0;
  totalScore: any = 0
  overallScore: any = 0;
  calculateAssessment() {
    this.assessmentDetails.forEach((element: any) => {
      this.totalScore = 0;
      this.score = 0;
      this.overallScore = 0;
      this.assessmentName.forEach((x: any) => {
        let catWeightData = this.assessmentCatWeight.filter((chk: any) => chk.subjectID == element.SubjectID && chk.assessmentCategoryID == x.assessmentCategoryID);
        let catWeight = 0;
        if(catWeightData.length > 0) {
          catWeight = Number(catWeightData[0].weightresult);
        }
        let totalCategoryScore = x.assessmentList.reduce((prev: any,next: any)=>prev+next.points,0);
        this.totalScore += totalCategoryScore;
        let catScore = 0;
        let assessmentScore = 0;
        let count = 0;
        x.assessmentList.forEach((y: any) => {
          count++;
          if(y.gradingScaleTypeID == 1 || y.gradingScaleTypeID == 3) {
            element[y.assessmentName] = ((Number(y.points) * Number(element[y.assessmentName])) / 100).toFixed(2);
          }
          this.score += Number(element[y.assessmentName]);
          catScore += Number(element[y.assessmentName]);
          assessmentScore +=  (Number(element[y.assessmentName]) * catWeight) / Number(y.points);
        });
        element['catScore' + x.assessmentCategoryID] = catScore.toFixed(2);
        element['catTotal' + x.assessmentCategoryID] = totalCategoryScore.toFixed(2);
        // element['cat' + x.assessmentCategoryID] = ((catScore * 100) / totalCategoryScore).toFixed(2);
        this.overallScore += (assessmentScore / count);
        element['cat' + x.assessmentCategoryID] = (assessmentScore / count).toFixed(2);
      });
      element.totalScore = this.totalScore;
      element.score = this.score;
      // element.finalScore = ((this.score * 100 ) / this.totalScore).toFixed(2);
      element.finalScore = this.overallScore.toFixed(2);
    });
    console.warn('final============= ', this.assessmentDetails);
  }

}
