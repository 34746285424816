
<div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Import File Template</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <p><a (click)="selectUnSelectAllImport(true)" class="tags underlinedLink">Select All</a> / <a (click)="selectUnSelectAllImport(false)"
          class="tags underlinedLink">Unselect All</a>
      </p>
      <form [formGroup]="importForm" (ngSubmit)="downloadImportFile($event)">
        <div class="row-flex">
          <div *ngFor="let item of columnListForExport" class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" [formControlName]="item.fieldName">
              {{item.fieldName}}
            </mat-checkbox>
            
          </div>
        </div>
        <div class="form-group17 text-right">
          <button type="submit"  class="btn btn-primary btn-sm"
            >Import</button>
        </div>
      </form>

    </mat-dialog-content>
  </div>

