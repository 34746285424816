<!-- <main> -->
<!-- <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Student</h2>
      
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-students">Manage Student</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Student</li>
            </ol>
        </div>
    </div> -->
<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>Edit Admin/Staff Details</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
                    <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-admin-staff">Manage
                            Admins</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Edit Admin/Staff</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-admin-staff">Back</button>
            </div>
          </div>
    </div>
    <div class="cardWidget">
        <div class="matCard">
            <!-- <div class="matCrdTitle centerItemVert justify-content-between">
                <div class="cardTitle centerVTItems justify-content-between">
                    <h3>Profile</h3>
                    <button class="btn btn-primary btnsmall" (click)="back()">Back</button>
                </div>

            </div> -->
            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <!-- <div class="profilePicLeft form-group30">
                            <p class="chngePic">
                                <input type="file">
                                <span class="icon centerItem">
                                    <mat-icon>edit</mat-icon>
                                </span>
                              <img src="../../../assets/img/display.jpg" alt=""> -->

                        <!-- 
                        <img [src]="sanitizeImageUrl(croppedImage1)" style="height: 300px;width: 350px;" alt="">
                        &nbsp;&nbsp;&nbsp;
                        <label for="file-upload" class="custom-file-upload">
                            <i class="fa fa-cloud-upload"></i>Upload Image
                            <input id="file-upload" type="file" title="Please Banner Image 1" accept="image/*"
                                (change)="actionDialogPopup(uploadPopup,$event,'1')">
                        </label> -->



                        <!-- </p> -->
                        <!-- </div> -->


                        <div class="proSection">
                            <div class="row">
                                <div class="col-lg-2 col-sm-12">
                                    <div class="profilePicLeft form-group30">
                                        <p class="chngePic">
                                            <input id="file-upload" type="file" title="Please Banner Image 1"
                                                accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'1')">
                                            <span class="icon centerItem">
                                                <mat-icon>edit</mat-icon>
                                            </span>
                                            <img [src]="croppedImage1" alt="" />
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-10 col-sm-12">
                                    <h5><i class="fas fa-user mr-2"></i>Admin/Staff Details</h5>
                                    <div class="row">

                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>First Name </mat-label>
                                                <input maxlength="50" (keypress)="allowAlphabetOnly($event)" matInput
                                                    placeholder="First Name" required name="firstname"
                                                    formControlName="firstName" pattern="^[^\s]+(\s+[^\s]+)*$">
                                                <mat-error
                                                    *ngIf="form.get('firstName')?.touched  && form.get('firstName')?.errors?.required">
                                                    First Name is required
                                                </mat-error>
                                                <mat-error *ngIf="form.get('firstName')?.hasError('pattern')">
                                                    Please enter valid First Name</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Last Name</mat-label>
                                                <input maxlength="50" (keypress)="allowAlphabetOnly($event)" matInput
                                                    placeholder="Last Name" required name="lastName"
                                                    formControlName="lastName" pattern="^[^\s]+(\s+[^\s]+)*$">
                                                <mat-error
                                                    *ngIf="form.get('lastName')?.touched  && form.get('lastName')?.errors?.required">
                                                    Last Name is required
                                                </mat-error>
                                                <mat-error *ngIf="form.get('lastName')?.hasError('pattern')">
                                                    Please enter valid Last Name</mat-error>
                                            </mat-form-field>
                                        </div>

                                        <!-- <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>User Name </mat-label>
                                                <input maxlength="50" matInput placeholder="User Name" required (keydown.space)="checkWhiteSpace($event)"
                                                    name="userName" formControlName="userName"
                                                    pattern="^[^\s]+(\s+[^\s]+)*$">
                                                <mat-error
                                                    *ngIf="form.get('userName')?.touched  && form.get('userName')?.errors?.required">
                                                    User Name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div> -->

                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Admin Email</mat-label>
                                                <input matInput placeholder="email" required name="email" (keydown.space)="checkWhiteSpace($event)"
                                                    formControlName="email" pattern="^[^\s]+(\s+[^\s]+)*$"  maxlength="50">
                                                <mat-error
                                                    *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                    Admin Email is required
                                                </mat-error>
                                                <mat-error *ngIf="form.get('email')?.errors?.email">
                                                    Please enter valid email
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">

                                                <ngx-mat-intl-tel-input style="border: none;"
                                                    [preferredCountries]="['au', 'us']" [enablePlaceholder]="true"
                                                    [enableSearch]="true" name="ContactNumber"
                                                    (keyup)="phoneLength($event)" formControlName="contactNumber"
                                                    #phone>
                                                </ngx-mat-intl-tel-input>
                                                <mat-hint>eg : {{phone.selectedCountry.placeHolder}}
                                                </mat-hint>
                                                <mat-error
                                                    *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                    Admin contact is required
                                                </mat-error>
                                                <mat-error *ngIf="phoneShow">
                                                    Phone length should be less than 10 digits
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Address</mat-label>
                                                <input matInput placeholder="address" formControlName="address"
                                                    pattern="^[^\s]+(\s+[^\s]+)*$" required maxlength="50"> 
                                                <mat-error
                                                        *ngIf="form.get('address')?.touched  && form.get('address')?.errors?.required">
                                                        Address is required
                                                    </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Country</mat-label>

                                                <input type="text" (input)="onKeyFilter($event, 'country')" matInput
                                                formControlName="countryID" [matAutocomplete]="countryauto">

                                                <mat-autocomplete #countryauto="matAutocomplete" 
                                                (optionSelected)="GetStateList($event)" [displayWith]="dispalyFns.bind(this)" >

                                                <!-- <mat-select placeholder="Country" required
                                                    (valueChange)="GetStateList($event)" formControlName="countryID"> -->
                                                    <mat-option value="" [disabled]="true">Select Country
                                                    </mat-option>
                                                    <mat-option *ngFor="let country of countries"
                                                        [value]="country.countryID">
                                                        {{country.countryName}}
                                                    </mat-option>
                                                <!-- </mat-select> -->



                                                <!-- <mat-error
                                                    *ngIf="form.get('countryID')?.touched  && form.get('countryID')?.errors?.required">
                                                    Country is required
                                                </mat-error> -->
                                                </mat-autocomplete>
                                            </mat-form-field>



                                        </div>

                                        <!-- [disabled]="true" -->
                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>State</mat-label>
                                                <!-- <mat-select placeholder="State" required formControlName="stateID"> -->

                                                    <input type="text" (input)="onKeyFilter($event, 'state')" matInput
                                                    formControlName="stateID" [matAutocomplete]="stateAuto">
    
    
                                                    <mat-autocomplete #stateAuto="matAutocomplete" 
                                                    [displayWith]="dispalyStateAuto.bind(this)">

                                                    <mat-option value="" [disabled]="true">Select State
                                                    </mat-option>
                                                    <mat-option *ngFor="let state of states" [value]="state.stateID">
                                                        {{state.stateName}}
                                                    </mat-option>
                                                <!-- </mat-select> -->
                                                <!-- <mat-error
                                                    *ngIf="form.get('stateID')?.touched  && form.get('stateID')?.errors?.required">
                                                    State is required
                                                </mat-error> -->
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>City</mat-label>
                                                <input maxlength="20" matInput placeholder="city" required
                                                    formControlName="city" pattern="^[^\s]+(\s+[^\s]+)*$">
                                                <mat-error
                                                    *ngIf="form.get('city')?.touched  && form.get('city')?.errors?.required">
                                                    City is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Zip Code</mat-label>
                                                <!-- <input (keypress)="allowOnlyNumber($event)" maxlength="6" matInput
                                                    placeholder="Zip Code" formControlName="zipCode"
                                                    pattern="^[^\s]+(\s+[^\s]+)*$"> -->
                                                    <input  maxlength="8" matInput
                                                placeholder="Zip Code" formControlName="zipCode" pattern="^[^\s]+(\s+[^\s]+)*$">
                                                <!-- <mat-error
                                                        *ngIf="form.get('zipCode')?.touched  && form.get('zipCode')?.errors?.required">
                                                        Zip Code is required
                                                    </mat-error> -->
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-sm-12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Roles</mat-label>
                                                <!-- <mat-select placeholder="Roles" required formControlName="roleID"> -->

                                                    <input type="text" (input)="onKeyFilter($event, 'role')" matInput
                                                    formControlName="roleID" [matAutocomplete]="RoleAuto">

                                                    <mat-autocomplete #RoleAuto="matAutocomplete" 
                                                   [displayWith]="dispalyRolesAuto.bind(this)">

                                                    <mat-option value="" [disabled]="true">Select Role</mat-option>
                                                    <mat-option *ngFor="let role of roleList" [value]="role.roleID">
                                                        {{role.roleName}}
                                                    </mat-option>
                                                <!-- </mat-select> -->
                                                 <!-- <mat-error
                                                        *ngIf="form.get('roleID')?.touched  && form.get('roleID')?.errors?.required">
                                                        Role is required
                                                    </mat-error>  -->
                                                    </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="proSection text-right">
                                        <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid"
                                            matRipple>Update <svg xmlns="http://www.w3.org/2000/svg" width="12.611"
                                                height="8.478" viewBox="0 0 12.611 8.478">
                                                <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                                                    <path id="Path"
                                                        d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                                                        transform="translate(-3 -5.95)" fill="#fff" />
                                                </g>
                                            </svg>
                                        </button>
                                    </div>

                                </div>
                            </div>


                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>


    <ng-template #uploadPopup>
        <div class="dialog-md">
            <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
            <div class="dialogTitle">
                <h5 mat-dialog-title>Select Image Area</h5>
            </div>
            <mat-dialog-content class="mat-typography">
                <div class="row">
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="10/8" [resizeToWidth]="500" [cropperMinWidth]="1600" format="png"
                        (imageCropped)="imageCropped($event,'1')" (imageLoaded)="imageLoaded($event)"
                        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                    </image-cropper>

                    <!-- <img [src]="croppedImage" /> -->
                </div>

            </mat-dialog-content>
        </div>
        <div class="proSection text-right">
            <button class="btn btn-primary btn-sm" mat-dialog-close matRipple (click)="finalUpload()"> Save </button>
        </div>

    </ng-template>