import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ManageAssessmentAndGradingsService } from '../services/manage-assessment-and-gradings.service';

@Component({
  selector: 'app-grade-book-log',
  templateUrl: './grade-book-log.component.html',
  styleUrls: ['./grade-book-log.component.css']
})
export class GradeBookLogComponent implements OnInit {

  constructor(
    private _gradeBookService:ManageAssessmentAndGradingsService
  ) { }

  displayedColumns: string[] = ['Changes','Date', 'Detail'];
  dataSource: any;

  ngOnInit(): void {
  }


}
