<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Manage ID Card</h2>

      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
          <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
              Learning</a></li>
          <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-students">Manage
              Student</a></li>
          <li class="breadcrumb-item active" aria-current="page">Manage ID Card</li>

        </ol>
      </div>
    </div>

    <div class="">
      <div class="add_button">
        <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
      </div>
    </div>
  </div>

  <div class="cardWidget">
    <div class="cardTitle justify-content-between">
      <!-- <mat-form-field appearance="fill"> -->
      <!-- <mat-label>Selected Student</mat-label> -->
      <div class="">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
          class="btn btn-primary btn-sm float-right mt-2">
          Save as
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [useExistingCss]="true" printSectionId="print-section" printTitle="ID Card" ngxPrint>

            <span>Print</span>
          </button>
          <button mat-menu-item (click)="pdf()">
            <span>Save as PDF</span>
          </button>
          <button mat-menu-item disabled>
            <span>Save as WordFile</span>
          </button>

        </mat-menu>
        <div class="d-flex flex-row">
          <h3 class="mt-3">Make ID Card Here</h3>
          <br>



          <mat-form-field class="w-50 ml-3" appearance="outline">
            <mat-label>Student Name</mat-label>
            <mat-select placeholder="Student" name="student" (selectionChange)="onSelect($event)">
              <mat-option *ngFor="let student of studentList" [value]="student">
                {{student.firstName}} {{student.lastName}}</mat-option>

            </mat-select>
            <mat-error>
              Please Select a status </mat-error>
          </mat-form-field>

        </div>
      </div>

      <!-- <mat-select  >
                  <mat-option *ngFor="let student of studentList" [value]="student">
                    {{student.firstName}} {{student.lastName}}
                  </mat-option>
                </mat-select> -->



      <!-- </mat-form-field> -->

      <!-- <button class="btn btn-primary" (click)="pdf()">PDF</button>
            <button class="btn btn-primary" printSectionId="print-section" printTitle="ID Card" ngxPrint>Print</button> -->
    </div>
    <div class="cardBody">
      <div class="manageIDCard d-flex">
        <div class="manageCardLeft">
          <h3>Select Options</h3>

          <section class="selectOptionCard">
            <p>
              <mat-checkbox [(ngModel)]="studentnameFlag"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">Student Name</mat-checkbox>
            </p>
            <p>
              <mat-checkbox [(ngModel)]="admissionFlag"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">Admission No</mat-checkbox>
            </p>
            <p>
              <mat-checkbox [(ngModel)]="studentclassFlag" (change)="onsChange()"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">Student Class</mat-checkbox>
            </p>
            <p>
              <mat-checkbox [(ngModel)]="studentSectionFlag" (change)="onsChange()"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">Student Section
              </mat-checkbox>
            </p>

            <p>
              <mat-checkbox [(ngModel)]="studentPhotoFlag"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">Student Photo</mat-checkbox>
            </p>
            <!-- <p><mat-checkbox [(ngModel)]="generateQRFlag" (change)="onsChange()">Generate QR Code </mat-checkbox></p>
                    <p><mat-checkbox [(ngModel)]="generateBarCodeFlag" (change)="onsChange()">Generate Bar Code</mat-checkbox></p> -->
            <div *ngFor="let item of generateList;  let i = index">
              <mat-checkbox [checked]="selected === i" (change)="onChange(i)" (change)="selected = i"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">{{item}}</mat-checkbox>
            </div>
            <p>
              <mat-checkbox [(ngModel)]="dobFlag"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">DOB</mat-checkbox>
            </p>
            <p>
              <mat-checkbox [(ngModel)]="defaultTillFlag"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">Default Valid Till
              </mat-checkbox>
            </p>
            <mat-form-field *ngIf="defaultTillFlag" appearance="outline" (click)="picker.open()" style="width:95%">
              <mat-label>Valid Till</mat-label>
              <input matInput [(ngModel)]="defaultvalid" [min]="minDate" [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <p>
              <mat-checkbox [(ngModel)]="planTextFlag"
                style="height: 35px; padding: 10px; font-size: 16px; margin-bottom: 10px;">Plain Text</mat-checkbox>
            </p>
            <textarea *ngIf="planTextFlag" matInput [(ngModel)]="plaintext"
              style="width: 95%;border: 1px #f1f1f3 solid;" class="form-control borderRadius8"></textarea>
          </section>


        </div>

        <div class="manageCardRight flex-grow-1" style="font-size: 15px; margin-bottom: 15px;" id="print-section">
          <h3 class="text-center printper">Card Image Preview</h3>
          <div #content id="print-section" class="card-preview"
            style="width: 450px;min-height:420px; margin: 30px auto; padding: 15px;"
            [ngStyle]="{'backgroundColor': idcardBackground}">
            <div class="card-Top centerVTItems" style="font-size: 22px; font-weight: 700; margin-bottom: 20px;">
              <p style="margin-right: 15px;"><img [src]="logo" alt="Institute Logo" style="max-height:60px;"></p>
              <span class="flex-grow-1">{{instituteName}}</span>
            </div>
            <div class="card-content d-flex">
              <div *ngIf="studentPhotoFlag" class="cardimage" style="height: 100px; flex: 0 0 100px;max-width: 100px;">
                <img [src]="studentData.studentPhoto" alt="">
              </div>
              <!-- <div *ngIf="!studentPhotoFlag" class="cardimage">
                                <img src="../../../../../../../assets/img/admin/avatar.jpg" alt="">
                            </div> -->
              <div *ngIf="!studentPhotoFlag" class="cardimage" style="height: 100px; flex: 0 0 100px;max-width: 100px;">

                <img src="../../../../../../../assets/img/avtaradmin.jpg" style="width:100px;height:100px;" alt="">
              </div>
              <div class="card-content-text" style="margin-left: 20px;">
                <div class="row-flex" style="min-width: 270px; position: relative;">

                  <!-- <div style="font-size:14px;" *ngIf="studentnameFlag" class="col s5 mb-2 text-right">Student Name : </div>
                                    <div class="idcrd_Name"  *ngIf="studentnameFlag" class="col s7 mb-2">{{studentData.studentName}} </div>
                                     <div style="font-size:14px;" *ngIf="admissionFlag" class="col s5 mb-2 text-left p-0">Admission No:</div>
                                     <div style="font-size:14px;" *ngIf="admissionFlag" class="col s7 mb-2">{{studentData.admissionNo}} </div>
                                     <div style="font-size:14px;" *ngIf="studentclassFlag && !classsectionFlag" class="col s5 mb-2 text-left p-0">Class:</div>
                                     <div style="font-size:14px;" *ngIf="studentclassFlag && !classsectionFlag" class="col s7 mb-2">{{studentData.studentClass}} </div>
                                     <div style="font-size:14px;" *ngIf="studentSectionFlag && !classsectionFlag" class="col s5 mb-2 text-left p-0">Section:</div>
                                     <div style="font-size:14px;" *ngIf="studentSectionFlag && !classsectionFlag" class="col s7 mb-2">{{studentData.studentSection}} </div>
                                     <div style="font-size:14px;" *ngIf="classsectionFlag" class="col s5 mb-2 text-left p-0">Class-Section:</div>
                                     <div style="font-size:14px;" *ngIf="classsectionFlag" class="col s7 mb-2">{{studentData.classSection}} </div>
                                     <div style="font-size:14px;" *ngIf="dobFlag" class="col s5 mb-2 text-left p-0">DOB:</div>
                                     <div style="font-size:14px;" *ngIf="dobFlag" class="col s7 mb-2">{{studentData.dob | date}} </div>
                                     <div style="font-size:14px;" *ngIf="defaultTillFlag" class="col s5 mb-2 text-left p-0">Valid Till:</div>
                                     <div style="font-size:14px;" *ngIf="defaultTillFlag" class="col s7 mb-2" style="color:red !important;">
                                        {{defaultvalid | date}}
                                    </div> -->
                  <!-- <div *ngIf="generateQRFlag" class="col s6 text-right">
                                         <img [src]="studentData.generateQR" alt="" style="height:72px !important;">
                                         <img [src]="studentData.generateQR" alt="" style="position: absolute;top: 238px;right: 10px;">
                                     </div>
                                     <div *ngIf="generateBarCodeFlag" class="col s6 text-right">
                                        <img [src]="studentData.generateBarCode" alt="" style="height:72px !important;">
                                        <img [src]="studentData.generateBarCode" alt="" style="position: absolute;top: 238px;right: 10px;">
                                    </div> -->

                  <div class="idcrd-details">
                    <div class="idcrd_Name" *ngIf="studentnameFlag">{{studentData.studentName}} </div>
                    <div *ngIf="admissionFlag">
                      <span>Admission No:</span>
                      <span> {{studentData.admissionNo}}</span>
                    </div>
                    <div *ngIf="studentclassFlag && !classsectionFlag">
                      <span>Class:</span>
                      <span> {{studentData.studentClass}}</span>
                    </div>
                    <div *ngIf="studentSectionFlag && !classsectionFlag">
                      <span>Section:</span>
                      <span> {{studentData.studentSection}}</span>
                    </div>
                    <div *ngIf="classsectionFlag">
                      <span>Class-Section:</span>
                      <span> {{studentData.classSection}}</span>
                    </div>
                    <div *ngIf="dobFlag">
                      <span>DOB:</span>
                      <span> {{studentData.dob | date}}</span>
                    </div>
                    <div *ngIf="generateQRFlag" class="pl-4">
                      <img [src]="studentData.generateQR" alt="" style="height:72px !important;">
                    </div>
                    <div *ngIf="generateBarCodeFlag" class="pl-4">
                      <img [src]="studentData.generateBarCode" alt="" style="height:72px !important;">
                    </div>
                    <div *ngIf="defaultTillFlag">
                      <span>Valid Till:</span>
                      <span> {{defaultvalid | date}}</span>
                    </div>
                    <div *ngIf="planTextFlag">
                      {{plaintext}}
                    </div>
                  </div>

                </div>

              </div>

            </div>
            <!-- <div style="font-size:14px;" *ngIf="planTextFlag" class="col s6 text-center" style="overflow: hidden;">
                          {{plaintext}}
                      </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>