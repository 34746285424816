import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CommonService } from 'src/app/common/core-services/common.service';
import { AdminStaffService } from 'src/app/services/admin-staff.service';
import { MasterService } from 'src/app/services/master.service';
import { Location } from '@angular/common'
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { MatSelect } from '@angular/material/select';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { debounce } from '@syncfusion/ej2-base';
@Component({
  selector: 'app-add-admin-staff',
  templateUrl: './add-admin-staff.component.html',
  styleUrls: ['./add-admin-staff.component.css']
})
export class AddAdminStaffComponent implements OnInit {
  form: FormGroup;
  countries: any;
  phoneShow: boolean;
  roleList: any;
  cities: any[];
  @ViewChild('phone') phone: any;
  @ViewChild('multiSelect') multiSelect: MatSelect;
  previousCountryVal: any = [];
  previousStateVal: any = [];
  previousRoleVal: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminStaffService,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private masterService: MasterService,
    private location: Location,
    private roleService: RoleAndPermissionService,
    private primengConfig: PrimeNGConfig

  ) {
    document.addEventListener('keydown', e => {
      if ((e.target as any).nodeName === 'MAT-SELECT') {
        e.stopImmediatePropagation();
        if (e.key == 'SPACE') {
          return;
        }
      }
      if ((e.target as any).nodeName === 'INPUT') {
        e.stopImmediatePropagation();
      }
    }, true);
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' },
    ];
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: [''],
      zipCode: [''],
      countryID: ['', Validators.required],
      stateID: ['', Validators.required],
      roleID: ['', Validators.required],
      city: ['', Validators.required],
      profilePhoto: [''],
      // userID: ['337BFA1D-3D51-4E04-146D-08D9AFD6B314'],
      hasExcess: [true],
      password: [''],
      userName: ['', Validators.required],
      // roleID: [3],
      middleName: [''],
      isSendCredentials: [false]

    });
    this.GetCountiresList();
    this.GetRolesList();
  }
  onKeyFilter(val: any, type: string) {
    let serachTxt = String(val.target.value);
    if (type == 'state') {
      if (serachTxt != '') {
        this.states = this.previousStateVal.filter((x: any) => x.stateName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.states = this.previousStateVal;
      }
    } else if (type == 'country') {
      if (serachTxt != '') {
        this.countries = this.previousCountryVal.filter((x: any) => x.countryName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.countries = this.previousCountryVal;
      }
    }

    else if (type == 'role') {
      if (serachTxt != '') {
        this.roleList = this.previousRoleVal.filter((x: any) => x.roleName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.roleList = this.previousRoleVal;
      }
    }

  }

  rolesData: any = [];
  GetRolesList() {
    this.roleService.getRoleListDropdown().subscribe((res: any) => {
      this.roleList = res.responseData;
      this.rolesData = [...this.roleList];
      this.previousRoleVal = Object.assign([], this.roleList);
    })
  }
  countryNames: any = [];
  GetCountiresList() {
    this.masterService.GetCountryDropdown().subscribe((res: any) => {
      this.countries = res.responseData;
      this.countryNames = [...this.countries];
      this.previousCountryVal = Object.assign([], this.countries);
    })
  }

  states: any = [];
  GetStateList(countryId: any) {
    this.form.controls['stateID'].setValue('');
    let countryID = countryId.option.value;
    this.masterService.GetStateDropdown(countryID).subscribe((res: any) => {
      this.states = res.responseData;
      this.previousStateVal = Object.assign([], this.states);
    })
  }


  image1: string = '';
  AddProfile(file: any) {
    this.masterService.postDataAboutImg(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {
        this.image1 = res.body.responseData.imageName;
        console.log(this.image1);
      }

    })

  }
  get confirm_userName(): AbstractControl {
    return this.form.controls['userName'];
  }
  CheckDuplicateUserName(value: any) {
    this.datas = value.target.value;
    console.log("eventDomain", value.target.value);
    if (value.target.value == "") {
      this.userNameAvailable = false;
      return
    }
    this.checkUsername(value.target.value)
  }
  userNameExist: boolean;
  userNameAvailable: boolean;
  datas: any;
  domainObj: any
  checkUsername(data: any) {
    // const regexp = new RegExp('^[a-zA-Z0-9]+$');
    // const result = regexp.test(data);
    // if(!result){
    //   this.form.patchValue({
    //     userName:''
    //   })
    //   this.toaster.error("Special characters not allowed")
    //   return
    // }
    this.adminService.CheckDuplicateUserName(this.datas).subscribe((res: any) => {
      console.log(res);
      if (res.status == 1) {
        this.confirm_userName.setErrors({ mismatchSubdomain: true });
        this.form.setErrors({ mismatchSubdomain: true });
        this.userNameExist = true
        this.userNameAvailable = false;
      } else {
        this.userNameExist = false
        this.userNameAvailable = true;
      }
    });
  }

  onSubmit() {
    ////debugger;
    // if (this.form.invalid) {
    //   return;
    // }
    if (this.image1 != '') {
      this.form.controls.profilePhoto.setValue(this.image1);
    }
    let newPassword
    newPassword = this.randPass(2, 2, 2)
    //  else{
    //     if(this.form.controls.adminID.value  == 0){
    //       this.toaster.error('Upload images first', 'Error');
    //       return;
    //     }
    //     console.log(this.form.value);
    //   }


    console.log(this.form.value);
    console.log("PHONENO", this.phone)
    this.form.value.contactNumber = this.phone.numberInstance.nationalNumber
    this.form.value.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
    this.form.value.password = newPassword;
    this.form.value.roleID = [this.form.value.roleID]
    this.form.value.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);
    this.adminService.AddAdmin(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        if (this.imageFile) {
          this.adminService.UpdateAdminProfilePhoto(res.responseData, this.imageFile).subscribe((res: any) => {
            this.router.navigate(['admin/academicslearning/manage-admin-staff']);
          }
            , err => this.router.navigate(['admin/academicslearning/manage-admin-staff']));
        } else
          this.router.navigate(['admin/academicslearning/manage-admin-staff']);


      } else {
        this.toaster.error(res.message, 'Error');
      }


    });
  }


  /// crop image
  /// crop image
  imageChangedEvent: any = '';
  croppedImage1: any = '../../../assets/img/display.jpg';
  croppedImage2: any = '../../../assets/img/banner-img.png';
  imageNumber: any;
  fileChangeEvent(event: any, imgNum: any): void {
    this.imageChangedEvent = event;
    this.imageNumber = imgNum;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent, img: any) {
    if (this.imageNumber == '1') {
      this.croppedImage1 = event.base64;
    }


    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
    this.imageFile = this.imageChangedEvent.target.files[0]
  }
  countryName: any = [];
  dispalyFns(countryId: any) {
    this.countryName = this.countryNames.filter((a: any) => a.countryID == countryId);
    return this.countryName[0]?.countryName

  }
  stateName: any = [];
  dispalyStateAuto(stateId: any) {
    this.stateName = this.states.filter((a: any) => a.stateID == stateId)
    return this.stateName[0]?.stateName;
  }

  roleName: any = [];
  dispalyRolesAuto(roleId: any) {
    this.roleName = this.rolesData.filter((a: any) => a.roleID == roleId)
    return this.roleName[0]?.roleName;
  }


  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  finalUpload() {
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  actionDialogPopup(templateRef: TemplateRef<any>, data: any, imgNumner: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data, imgNumner);

  }

  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  imageUrl: any;
  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();

    formData.append('files', file);
    this.AddAboutUsImage(formData);


  }

  AddAboutUsImage(file: any) {
    this.masterService.postDataAboutImg(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {

        switch (this.imageNumber) {
          case '1':
            this.image1 = res.body.responseData.imageName;
            break;

          default:
            break;
        }
        this.imageNumber = '';
        console.log(res.body.responseData.imageName);
      }

    })
  }


  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  phoneLength(event: any) {
    this.phoneShow = this.commonService.phoneLengths(event.target.value.length);
  }
  maxDate = new Date();


  checkWhiteSpace(event: any) {
    //debugger
    if (event.target.value.length > 0) {
      return;
    } else {
      event.preventDefault();
    }
  }


  randPass(lettersLength: number, numbersLength: number, splCharLength: number) {
    var j, x, i;
    var result: any
    var letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var numbers = '0123456789';
    var specialCharacter = '(~!@#$%^&*_-+=`|\(){}[]:;"<>,.?/)';
    for (i = 0; i < lettersLength; i++) {
      result += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    for (i = 0; i < numbersLength; i++) {
      result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
    for (i = 0; i < splCharLength; i++) {
      result += specialCharacter.charAt(Math.floor(Math.random() * specialCharacter.length));
    }
    result = result.split("");
    for (i = result.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = result[i];
      result[i] = result[j];
      result[j] = x;
    }
    result = result.join("");
    return result


  }



}


