import {SelectionModel} from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AcademicSessionService } from '../../courses-and-subjects/services/academicSessionServices/academic-session.service';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
@Component({
  selector: 'app-old-academic-sessions',
  templateUrl: './old-academic-sessions.component.html',
  styleUrls: ['./old-academic-sessions.component.css']
})
export class OldAcademicSessionsComponent implements AfterViewInit {

  classToggled = false;
  users:any;
  dateFormat:any;
  public toggleField() {
    this.classToggled = !this.classToggled;  
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private dialog: MatDialog,
    private academicSessionService: AcademicSessionService
    ) { 
      this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
    }
    this.filterModel = new FilterModel();
  }
  displayedColumns: string[] = ['id', 'academicsession', 'timeperiod', 'courses'];
    // dataSource = ELEMENT_DATA;
    dataSource:any;
    selection = new SelectionModel<PeriodicElement>(true, []);
    private filterModel: FilterModel;
    list:any[]=[];
  ngAfterViewInit(): void {
    
    
  }
  ngOnInit(): void {
    this.getacademicSessionList(this.filterModel);
  }
  getacademicSessionList(filterModel: any) {
    filterModel.pageSize = 10000
    console.log("THIS>FILTER",filterModel)
    this.academicSessionService
      .AllAcademicSession(filterModel)
      .subscribe((res: any) => {
        if (res.status > 0) {
          let ListData = res.responseData.filter((x:any) => x.academicSessionStatusID == 3);
          this.dataSource = new MatTableDataSource<any>(ListData);
          //this.list = res.responseData.filter((x:any) => x.academicSessionStatusID == 3);
          //this.dataSource = 
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        console.log("this.academicSessionListData",this.dataSource);

        }
      });
  }
  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach((row:any) => this.selection.select(row));
  }

}

export interface PeriodicElement {
	id: number; 
	academicsession: string;
	timeperiod: string;
	courses: string;
  }
  
  const ELEMENT_DATA: PeriodicElement[] = [];
