<div class="cardTitle centerVTItems justify-content-between">
    <h3 class="text-colored"><i class="fas fa-bookmark mr-2"></i> Report cards &amp; Grade Book Display</h3>
</div>
<form [formGroup]="ReportCardsGradeBooksDisplay">
    <div class="gradeSettings">
        <div class="row-flex mt-4">
            <div class="col s12 m4 form-group">Display Report Cards to Students &amp; Parents?</div>
            <div class="col s12 m8 form-group">
                <mat-radio-group aria-label="Select an option" formControlName="displayReportCardsToStudentsParent" required>
                    <mat-radio-button [value]=true>Yes</mat-radio-button>
                    <mat-radio-button [value]=false>No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col s12 m4 form-group"> Display Grade Book to Students &amp; Parents?</div>
            <div class="col s12 m8 form-group">
                <mat-radio-group aria-label="Select an option" formControlName="displayGradeBookToStudentsParent" required>
                    <mat-radio-button [value]=true>Yes</mat-radio-button>
                    <mat-radio-button [value]=false>No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col s12 m4 form-group"></div>
            <div class="col s12 m8 form-group">
                <button type="submit" (click)="submitReportCardsGradeBooksDisplay()" class="btn btn-primary btn-sm min-140" matRipple>Save</button>
            </div>
        </div>
    </div>
</form>