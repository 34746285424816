import { AfterContentChecked, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PaymentDetailsService } from 'src/app/services/payment-details.service';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { TeacherService } from 'src/app/services/teacher.service';
import { StudentService } from 'src/app/services/student.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef } from '@angular/core';
import { PermissionService } from 'src/app/services/permission.service';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-teacherspayment',
  templateUrl: './teacherspayment.component.html',
  styleUrls: ['./teacherspayment.component.css'],
})
export class TeacherspaymentComponent implements OnInit, AfterContentChecked {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  collection = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  selectedId: any[] = [];
  form: FormGroup;
  paymentForm: FormGroup;
  date = new FormControl();
  date12: Date;
  es: any;
  minDate: Date;
  maxDate: Date;
  invalidDates: Array<Date>
  filterDate: any;
  filterTeacherID: number = 0;
  todaysDate: any;
  teacherList: any[];
  numRegex = /^-?\d*[.,]?\d{0,2}$/;
  paymentForName: string = '';
  userSalaryInfo: any;
  month: any;
  year: any;
  years: any[] = [];
  bonusFieldStatus: boolean = true;
  dateFormat: string;
  hideUpfrontendCheckBox: boolean = true;
  readonlyFieldStatus: boolean = false;
  classToggled: boolean = false;
  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  months: any[] = [
    { monthName: "January", monthNumber: 1 },
    { monthName: "February", monthNumber: 2 },
    { monthName: "March", monthNumber: 3 },
    { monthName: "April", monthNumber: 4 },
    { monthName: "May", monthNumber: 5 },
    { monthName: "June", monthNumber: 6 },
    { monthName: "July", monthNumber: 7 },
    { monthName: "August", monthNumber: 8 },
    { monthName: "September", monthNumber: 9 },
    { monthName: "October", monthNumber: 10 },
    { monthName: "November", monthNumber: 11 },
    { monthName: "December", monthNumber: 12 }
  ];

  accountType: any[] = [
    { id: 1, name: "Cash in hand Cr. Ledger" },
    { id: 2, name: "Cash in bank Cr. Ledger" }
  ]

  displayedColumns: string[] = [
    'select',
    //'paymentDetailsID',
    //'userID',
    //'formType',
    //'employeeTypeID',
    'teacherName',
    // 'email',
    'salaryForMonthName',
    'salaryForYear',


    'salaryBeforeDeduction',
    'deductionAmount',
    'salaryAfterDeduction',
    'bonusAmount',
    'adjustableAmount',
    //'salaryForMonth',

    'totalAbsent',
    'alreadyPaidAmount',
    'payableAmount',
    //'paidAmount',
    'paymentDate',

    'comment',
    //'paymentUpfrontStatus',
    'paymentStatus',
    'button',
  ];
  users: any;
  orgID: any;
  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private paymentDetailService: PaymentDetailsService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private teacherService: StudentService,
    private _liveAnnouncer: LiveAnnouncer,
    public cdRef: ChangeDetectorRef,
    private permissionService: PermissionService,
    private masterService: MasterService
  ) {
    document.addEventListener('keydown', e => {
      if ((e.target as any).nodeName === 'MAT-SELECT') {
        e.stopImmediatePropagation();
        if (e.key == 'SPACE') {
          return;
        }
      }
      if ((e.target as any).nodeName === 'INPUT') {
        e.stopImmediatePropagation();
      }
    }, true);
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
      this.orgID = currentUser.orgID;
    }
  }

  ngOnInit(): void {
    this.ActivityLogTrack();
    this.searchFilterForm();
    this.paymentPopForm();
    this.getYear(2020)

    this.filterTeacherID = 0;
    this.paymentStatus = '0';
    const format2 = 'YYYY-MM-DD';
    const format4 = 'YYYY';
    const format3 = 'M';

    this.todaysDate = moment(new Date()).format(format2);
    this.month = moment(new Date()).format(format3);
    this.year = moment(new Date()).format(format4);


    console.log('moment(new Date()).format(format2) YYYY', moment(new Date()).format(format3));
    console.log('moment(new Date()).format(format2) MM', moment(new Date()).format(format4));

    this.form.patchValue({
      seletedDate: this.todaysDate,
    });
    this.getTeacher();
    this.getTeacherPaymentDetails(this.year, this.month, this.filterTeacherID, this.paymentStatus);
  }
  previousTeacherVal: any = [];
  onKeyFilter(val: any, type: string) {
    let serachTxt = String(val.target.value);
    if (type == 'teacher') {
      if (serachTxt != '') {
        this.teacherList = this.previousTeacherVal.filter((x: any) => x.teacherName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.teacherList = this.previousTeacherVal;
      }
    }
  }


  activityLog: any;
  ActivityLogTrack() {

    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Teachers & Staff/Admin',
        ActivityTypeId: 10,
        Description: 'Teacher payments record viewed by',
        ScreenName: 'Teachers Payment',
        RecordTypeId: 2,
        RecordId: 0,
        Type: 0
      }
    }

    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }
  actionDialogPopup1(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
  actionDialogPopup(templateRef: TemplateRef<any>, element: any) {
    ////debugger;
    this.paymentForName = element.teacherName;


    if (
      element.salaryBeforeDeduction == 0 &&
      element.deductionAmount == 0 &&
      element.salaryAfterDeduction == 0
    ) {
      this.toaster.error('Teacher Salary is not defined');
      console.log('1st');
    } else if (
      element.paymentDetailsID == 0 &&
      element.paymentStatus == false &&
      element.alreadyPaidAmount == 0
    ) {
      this.paymentForm.patchValue({
        userID: element.userID,
        upfrontPayment: element.paymentUpfrontStatus,
        salaryAfterDeduction: element.salaryAfterDeduction,
        bonusAmount: 0,
        paidAmount: element.payableAmount,
        comment: '',
        adjustableAmount: 0
      });
      this.dialog.open(templateRef);
      this.userSalaryInfo = element;
    } else if (
      element.paymentDetailsID == 0 &&
      element.paymentStatus == false &&
      element.alreadyPaidAmount > 0
    ) {
      this.paymentForm.patchValue({
        userID: element.userID,
        upfrontPayment: element.paymentUpfrontStatus,
        salaryAfterDeduction: element.salaryAfterDeduction,
        bonusAmount: 0,
        paidAmount: element.payableAmount,
        comment: '',
        adjustableAmount: 0
      });

      this.hideUpfrontendCheckBox = false;
      this.readonlyFieldStatus = true;

      this.dialog.open(templateRef);
      this.userSalaryInfo = element;
    } else if (
      element.paymentDetailsID > 0 &&
      element.paymentStatus == true &&
      element.currentMonthPayment == true
    ) {
      this.paymentForm.patchValue({
        userID: element.userID,
        upfrontPayment: true,
        salaryAfterDeduction: element.salaryAfterDeduction,
        bonusAmount: 0,
        paidAmount: element.payableAmount,
        comment: '',
        adjustableAmount: 0
      });

      this.hideUpfrontendCheckBox = true;
      this.readonlyFieldStatus = true;

      this.dialog.open(templateRef);
      this.userSalaryInfo = element;
    }
    console.log("element", element)
  }
  bonusPay(event: any) {

    if (event.target.value == null || event.target.value == "") {
      event.target.value = 0
      this.paymentForm.patchValue({
        bonusAmount: 0
      })
    }
    if (this.paymentForm.value.adjustableAmount == 0 || this.paymentForm.value.adjustableAmount == null) {
      this.paymentForm.patchValue({
        paidAmount: parseInt(this.userSalaryInfo.payableAmount) + parseInt(event.target.value)
      })
    }
    else {
      var adjustableAmount = this.paymentForm.value.adjustableAmount
      this.paymentForm.patchValue({
        paidAmount: parseInt(this.userSalaryInfo.payableAmount) + parseInt(event.target.value) - parseInt(adjustableAmount)
      })
    }
  }
  adjustablePay(event: any) {
    if (event.target.value == null || event.target.value == "") {
      event.target.value = 0
      this.paymentForm.patchValue({
        adjustableAmount: 0
      })
    }
    if (event.target.value == null || event.target.value == 0 || event.target.value == "") {
      this.paymentForm.patchValue({
        paidAmount: parseInt(this.userSalaryInfo.payableAmount) + parseInt(this.paymentForm.value.bonusAmount)
      })
    }
    else {
      var total = parseInt(this.userSalaryInfo.payableAmount) + parseInt(this.paymentForm.value.bonusAmount)
      this.paymentForm.patchValue({
        paidAmount: total - event.target.value
      })
    }
  }
  eventHandler(event: any) {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  }
  onDateChange(event: any, datepicker: MatDatepicker<Moment>) {

    //console.log("event.target.value;", event.target.value);
    console.log("datepicker", datepicker);
    console.log("date", this.form);
    datepicker.close();
  }

  onChange(event: any) {
    console.log("event.target.value;", event);
    const format2 = 'YYYY-MM-DD';
    let convertedDate = moment(event).format(format2)
    console.log("moment(date2).format(format2)", convertedDate);
    this.form.patchValue({
      seletedDate: convertedDate,
    });
    this.todaysDate = convertedDate;
  }

  searchFilterForm() {
    this.form = this.fb.group({
      seletedDate: ['', Validators.required],
      teacherList: [''],
      month: ['', Validators.required],
      year: ['', Validators.required],
      paymentStatus: ['0']
    });
  }

  paymentPopForm() {
    this.paymentForm = this.fb.group({
      upfrontPayment: false,
      userID: 0,
      salaryAfterDeduction: [0, [Validators.required, Validators.min(1)]],
      bonusAmount: [0],
      paidAmount: [0, [Validators.required, Validators.min(1)]],
      comment: ['', [Validators.required]],
      adjustableAmount: [0],
      accountType: ['', [Validators.required]],
    });

  }

  paymentStatus: any;
  resetForm(event: Event) {
    this.form.reset();
    this.filterTeacherID = 0;
    this.paymentStatus = '0';
    const format2 = 'YYYY-MM-DD';
    const format4 = 'YYYY';
    const format3 = 'M';
    this.todaysDate = moment(new Date()).format(format2);
    this.month = moment(new Date()).format(format3);
    this.year = moment(new Date()).format(format4);
    this.form.patchValue({
      seletedDate: this.todaysDate,
      month: this.month,
      year: this.year,
    });
    console.log("GETETETET", this.form.value)
    this.getTeacherPaymentDetails(this.year, this.month, this.filterTeacherID, this.paymentStatus);
  }

  onFilterSubmit() {
    if (this.form.valid) {
      if (this.form.value.teacherList != null && this.form.value.teacherList != undefined && this.form.value.teacherList != '') {
        if (this.form.value.teacherList == 0) {
          this.filterTeacherID = 0
        }
        else {
          this.filterTeacherID = this.form.value.teacherList.teacherID;
        }
      }
      this.paymentStatus = this.form.value.paymentStatus;
      this.getTeacherPaymentDetails(this.year, this.month, this.filterTeacherID, this.paymentStatus)
    }
    else {
      this.form.markAllAsTouched();
      this.toaster.error("Please validate month and year.");
    }
  }

  onPaymentSubmit() {
    if (this.paymentForm.valid) {
      console.log("userSalaryInfo", this.userSalaryInfo);

      console.log("userSalaryInfo", this.userSalaryInfo);

      console.log("this.paymentForm.value", this.paymentForm);
      let data = {
        userSalaryDto: {
          UserID: this.paymentForm.value.userID,
          FormType: 1,
          SalaryForYear: Number(this.year),
          SalaryForMonth: Number(this.month),
          SalaryForDate: this.todaysDate,
          SalaryAmount: this.paymentForm.value.salaryAfterDeduction,
          PaidAmount: this.paymentForm.value.paidAmount,
          BonusAmount: this.paymentForm.value.bonusAmount,
          TotalAbsent: this.userSalaryInfo.totalAbsent,
          Comment: this.paymentForm.value.comment,
          PaymentUpfrontStatus: this.paymentForm.value.upfrontPayment,
          AdjustableAmount: this.paymentForm.value.adjustableAmount,
          AccountType: this.paymentForm.value.accountType,
          OrgID: this.orgID
        },
      };
      console.log("onPaymentSubmit data", data);

      this.paymentDetailService
        .PayUserSalary(data)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.dialog.closeAll();
            this.userSalaryInfo = null;
            this.toaster.success("Record Saved");
            this.getTeacherPaymentDetails(this.year, this.month, this.filterTeacherID, this.paymentStatus);
            this.bonusFieldStatus = true;
          } else {
            this.toaster.error(res.message);
          }
        });
    }
    else {
      this.paymentForm.markAllAsTouched();
      this.toaster.error("Please enter validate values.");
    }
  }

  getTeacherPaymentDetails(year: number, month: number, filterTeacherID: number, paymentStatus: string) {
    this.paymentDetailService
      .GetPaymentDetails(year, month, filterTeacherID, paymentStatus)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.selectedId = [];
          this.selection.clear();
          this.collection = new MatTableDataSource(res.responseData);
          console.log('this.collection', this.collection);
          this.collection.sort = this.sort;
          this.collection.paginator = this.paginator;
        }
      });
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  teacherName: any;
  dispalyTeacherAuto(teacherId: any) {
    if (teacherId == '0') {
      return "All";
    }
    else {
      return teacherId?.teacherName;
    }
  }

  // GetYearAndMonth() {
  //   console.log('this.collection', this.collection);

  //   this.paymentDetailService
  //     .GetYearAndMonthFromPaymentDetails()
  //     .subscribe((res: any) => {
  //       if (res.status > 0) {
  //         this.months = res.responseData.months
  //         this.years = res.responseData.year
  //        console.log("GetYearAndMonth res", res);
  //       }
  //     });
  // }
  getYear(startYear: any) {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 2020;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    for (var i = 0; i < years.length; i++) {
      this.years.push({
        yearName: JSON.stringify(years[i]),
        yearNumber: years[i]
      })
    }
  }

  bonusCheck(event: MatCheckboxChange) {
    if (event.checked == true) {
      this.bonusFieldStatus = false;
    }
    else {
      this.bonusFieldStatus = true;
    }
    console.log("event", event);

  }

  monthSelection(event: Event) {
    console.log("event monthSelection", event);
    this.month = event;
  }

  yearSelection(event: Event) {
    console.log("event yearSelection", event);
    this.year = event;
  }
  teachersList: any;
  getTeacher() {
    this.teacherService.GetTeacherDropdown().subscribe((res: any) => {
      debugger
      this.teacherList = res.responseData
      this.teachersList = [...this.teacherList];
      this.previousTeacherVal = Object.assign([], this.teacherList);
      console.log('Teacher Data', this.teacherList)
    });
  }
  isAllSelected() {
    this.selectedId = [];
    if (this.selection.selected.length != 0) {
      for (var i = 0; i < this.selection.selected.length; i++) {
        if (this.selection.selected[i] != undefined) {
          this.selectedId.push(this.selection.selected[i]);
        }
      }
    }
    //this.cdr.detectChanges();
    const numSelected = this.selection.selected.length;
    const numRows = this.collection.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.collection.data);
  }

  checkActionPermission(actionName: any) {
    //debugger;
    let screen = 'Teachers Payment';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  bulkData: any[] = [];
  bulkPay(aType: any) {
    this.bulkData = [];
    let totalPaid: any = 0.00;
    for (let i = 0; i < this.selectedId.length; i++) {
      this.bulkData.push({
        UserID: this.selectedId[i].userID,
        FormType: 1,
        SalaryForYear: Number(this.year),
        SalaryForMonth: Number(this.month),
        SalaryForDate: this.todaysDate,
        SalaryAmount: this.selectedId[i].salaryAfterDeduction,
        PaidAmount: this.selectedId[i].payableAmount,
        BonusAmount: 0,
        TotalAbsent: this.selectedId[i].totalAbsent,
        Comment: "Bulk Pay",
        PaymentUpfrontStatus: this.selectedId[i].paymentUpfrontStatus,
        AdjustableAmount: 0,
        OrgID: this.orgID
      });
      totalPaid += this.selectedId[i].payableAmount;
    }

    let data = {
      userSalaryDto: this.bulkData,
      AccountType: aType,
      OrgID: this.orgID,
      TotalPaid: totalPaid
    }

    this.paymentDetailService
      .PayBulkUserSalary(data)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.selectedId = [];
          this.selection.clear();
          this.dialog.closeAll();
          this.userSalaryInfo = null;
          this.toaster.success("Record Saved");
          this.getTeacherPaymentDetails(this.year, this.month, this.filterTeacherID, this.paymentStatus);
          this.bonusFieldStatus = true;
        }
        else {
          this.toaster.error(res.message);
        }
      });
  }
  toolTipText: any;
  DisabledPointer() {
    if (
      this.form.controls.paymentStatus.value == '0'
    ) {
      this.toolTipText = '';
      return false;
    } else {
      this.toolTipText =
        'All salary is paid';
      return true;
    }
  }
}
