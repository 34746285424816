import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormioOptions } from 'angular-formio';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { MasterService } from 'src/app/services/master.service';
import { TeachersService } from 'src/app/services/teachers.service';
import { DatePipe, Location } from '@angular/common'
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-edit-teacher',
  templateUrl: './edit-teacher.component.html',
  styleUrls: ['./edit-teacher.component.css']
})
export class EditTeacherComponent implements OnInit {

  form: FormGroup;
  teacherID: number;
  data: string;
  previewUrl: any = '../../../assets/img/display.jpg';

  @ViewChild('phone') phone: any;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private teacherService: TeachersService,
    private masterService: MasterService,
    private router: Router,
    private toaster: ToastrService,
    private commonService: CommonService,
    private location: Location,
    private datePipe: DatePipe,
    private dialog: MatDialog

  ) { }
  countries: any;
  bloodGroups: any;
  genders: any;
  maxDate = new Date();
  minDate = new Date(1900, 0, 1);
  phoneShow: boolean;
  public jsonFormData: Object = {
    components: []
  };
  initialFormValues: any = null;
  formioOptions: FormioOptions = {
    disableAlerts: true
  }
  extraformField: boolean;
  ngOnInit(): void {
    this.extraformField = false;
    //this.adminUserID = this.activatedRoute.snapshot.params.Id 
    this.activatedRoute.queryParams.subscribe(params => {
      this.teacherID = params.id == undefined ? null : params.id;
    });


    this.form = this.formBuilder.group({
      teacherID: this.teacherID,
      //  studentID:[0],

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      genderID: ['', Validators.required],
      bloodGroupID: [''],
      dateOfBirth: ['', Validators.required],
      address: [''],
      zipCode: [''],
      countryID: [''],
      employeeTypeID: ['', Validators.required],
      stateID: [''],
      city: [''],
      profilePicture: [''],
      profileSummary: [''],
      facebookLink: [''],
      linkedInlink: [''],
      teacherSkillsID: [0],

      teacherNumber: ['', Validators.required],
      userID: 0,
      hasExcess: [true],
      password: ['Password@pm01'],
      userName: ['Priya'],
      roleID: 1,
      teacherExtraInfoInJson: ""

    });
    this.masterService.GetCustomFormTemplate(2).subscribe((res: any) => {
      if (res.responseData.formInJson != "") {
        this.extraformField = true
        this.jsonFormData = JSON.parse(res.responseData.formInJson || '');
      }
    })
    this.GetTeacherById();

    this.GetCountiresList();
    this.GetBloodGroupList();
    this.GetGenderList();
    this.GetTeacherSkillsList();
    this.GetEmployeeTypeList();


  }
  GetTeacherById() {
    this.teacherService.GetTeacherById(this.teacherID).subscribe((res: any) => {
      if (res.responseData.profilePicture != '') {
        this.previewUrl = res.responseData.profilePicture;
      }
      this.GetStateList(res.responseData['countryID']);
      this.form.patchValue({
        teacherID: res.responseData['teacherID'],
        firstName: res.responseData['firstName'],
        lastName: res.responseData['lastName'],
        dateOfBirth: res.responseData['dateOfBirth'],

        genderID: res.responseData['genderID'],
        email: res.responseData['email'],

        profileSummary: res.responseData['profileSummary'],
        facebookLink: res.responseData['facebookLink'],
        linkedInlink: res.responseData['linkedInlink'],
        bloodGroupID: res.responseData['bloodGroupID'],
        address: res.responseData['address'],
        city: res.responseData['city'],
        contactNumber: res.responseData['contactNumber'],
        countryID: res.responseData['countryID'],
        stateID: res.responseData['stateID'],
        zipCode: res.responseData['zipCode'],
        teacherSkillsID: res.responseData['teacherSkillsID'],
        employeeTypeID: res.responseData['employeeTypeID'],
        teacherNumber: res.responseData['teacherNumber'],
        userID: res.responseData['userID'],
        hasExcess: res.responseData['hasExcess'],
        password: res.responseData['password'],
        userName: res.responseData['userName'],
        roleID: res.responseData['roleID']
      });
      if (res?.responseData['profilePicture']) {
        this.croppedImage = res.responseData['profilePicture']
      }
      this.initialFormValues = JSON.parse(res.responseData['teacherExtraInfoInJson'])
      
      this.data = res.responseData;
      console.log("sae", this.initialFormValues)
    });
  }
  GetCountiresList() {
    this.masterService.GetCountryDropdown().subscribe((res: any) => {
      this.countries = res.responseData;
    })
  }


  GetBloodGroupList() {
    this.masterService.GetBloodGroupDropdown().subscribe((res: any) => {
      this.bloodGroups = res.responseData;
    })
  }

  teacherSkills: any
  GetTeacherSkillsList() {
    this.masterService.GetTeacherSkillsDropdown().subscribe((res: any) => {
      this.teacherSkills = res.responseData;

    })
  }
  employeeTypes: any
  GetEmployeeTypeList() {
    this.masterService.GetEmployeeTypeDropdown().subscribe((res: any) => {
      this.employeeTypes = res.responseData;

    })
  }
  formData: any;
  onChange(event: any) {
    if (event.data != undefined || event.data != null) {
      this.formData = {}
      this.formData = {
        data: event.data
      }
      this.form.value.teacherExtraInfoInJson = JSON.stringify(this.formData)
      console.log("EVENT", this.form.value.teacherExtraInfoInJson)
    }
  }
  onSubmit() {
    this.form.value.contactNumber = this.phone.numberInstance.nationalNumber
    this.form.value.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
    this.form.value.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);
    this.form.controls.dateOfBirth.setValue(moment(this.form.value.dateOfBirth).format('YYYY-MM-DD'));
    this.updateTeacher();
  }
  back() {
    this.location.back()
  }
  updateTeacher() {
    this.teacherService.UpdateTeacher(this.form.value).subscribe((res: any) => {
      console.log("update", res);
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        if (this.imageFile) {
          this.teacherService.UpdateTeacherProfilePhoto(this.teacherID, this.imageFile).subscribe((res: any) => {
            this.back();
          }
            , err => this.back());
        } else
          this.back();

      } else {
        this.toaster.error(res.message, 'Error');
      }
    })
  }
  GetGenderList() {
    this.masterService.GetGenderDropdown().subscribe((res: any) => {
      this.genders = res.responseData;
    })
  }

  states: any;
  GetStateList(countryId: any) {
    this.masterService.GetStateDropdown(countryId).subscribe((res: any) => {
      this.states = res.responseData;
      console.log(res);
    })
  }

  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  phoneLength(event: any) {
    this.phoneShow = this.commonService.phoneLengths(event.target.value.length);
  }
  profileImage: string = '';
  AddProfile(file: any) {
    this.teacherService.postData(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {
        this.profileImage = res.body.responseData.imageName;
        console.log(this.profileImage);
      }
    })

  }
  allowAlphaNumber(event: any) {
    this.commonService.allowAlphaNumber(event)
  }

  imageUrl: any;
  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();
    //for (let file of this.filesdata)
    formData.append('files', file);
    //this.AddProfile(formData);
    // if (event.target.files && event.target.files[0]) {
    //   reader.readAsDataURL(file);

    //   // When file uploads set it to file formcontrol
    //   reader.onload = () => {
    //     this.imageUrl = reader.result;
    //     // this.registrationForm.patchValue({
    //     //   file: reader.result
    //     // });
    //     // this.editFile = false;
    //     // this.removeUpload = true;
    //   }
    //   // ChangeDetectorRef since file is loading outside the zone
    //   //this.cd.markForCheck();        
    // }
  }


  /// crop image
  imageChangedEvent: any = '';
  croppedImage: any = '../../../assets/img/display.jpg';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
    this.imageFile = this.imageChangedEvent.target.files[0];
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data);

  }
  finalUpload() {
    //alert('called');
    //this.uploadFile(this.imageFile);
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

}

