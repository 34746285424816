import { SelectionModel } from '@angular/cdk/collections';
import { Component, TemplateRef, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';
//import { FormBuilder } from 'formiojs';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
@Component({
  selector: 'app-grading-scales',
  templateUrl: './grading-scales.component.html',
  styleUrls: ['./grading-scales.component.css'],
})
export class GradingScalesComponent implements OnInit {
  gradeMasterData: any;
  setGPA: boolean;
  //gradeScaleList: any;
  addGradingScaleForm: FormGroup;
  users: any;
  user: any;
  listtax: any = [];
  permissions: any;
  err: any = false;
  updateTextInPopUp: boolean;
  objData: {
    gradingScaleName: any;
    isgpaCalcualtion: any;
    academicSessionID: any;
    gradingScaleDetailsList: any[];
  };
  academicSessionID: any;
  constructor(
    private dialog: MatDialog,
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private permissionService: PermissionService,
    private roleService: RoleAndPermissionService
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID;
    if (currentUser != null) {
      this.user = currentUser;
    }
  }
  displayedColumns: string[] = ['id', 'gradingScaleName', 'actions'];
  gradingScaleDisplayedColumns: string[] = [
    'min',
    'max',
    'grade',
    'gradePoints',
    'color',
  ];

  // dataSource = ELEMENT_DATA;
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  gradeScaleList: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit(): void {
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Grade Setup');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService
        .getPermissionByUserID(this.user.userId)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.permissionService.addPermissions(res.responseData);
            this.permissions = this.permissionService.getAllPermissions();
          }
        });
    }
    this.GetAllGradeMasterData();
    this.GetAllGradingScale();

    this.addGradingScaleForm = this.fb.group({
      gradingScaleName: ['', Validators.required],
      academicSessionID: [0],
      isgpaCalcualtion: false,
      gradingScaleID: [0],
      taxItemList: this.fb.array([]),
    });
    console.log('this.addGradingScaleForm', this.addGradingScaleForm.value);
  }
  ngOnDestroy(): void {
    sessionStorage.setItem('Loader', '1');
  }
  checkActionPermission(actionName: any) {
    let screen = 'Grade Setup';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(
      actionName,
      screen
    );
  }

  checkWhiteSpace(event: any) {
    if (event.target.value.length > 0) {
      return;
    } else {
      event.preventDefault();
    }
  }
  IsgpaCalcualtionEvent(event: any) {
    console.log('event', event);
    this.setGPA = event.checked;
    console.log('event', this.setGPA);
  }
  GetAllGradeMasterData() {
    this.manageAssessmentService
      .GetAllGradeMasterDataList()
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.gradeMasterData = res.responseData;
          this.listtax = res.responseData;
          console.log('gradeMasterData', res);
          for (let x of this.listtax) {
            this.addTax();
          }
          this.taxItemArr.patchValue(this.listtax);
          console.log('myform', this.taxItemArr.value);
        }
      });
  }
  addTax() {
    this.taxItemArr.push(
      this.fb.group({
        minValue: [],
        maxValue: [],
        denotedAlpha: [''],
        gradingPoint: [0],
        colorCode: ['#FFFFF'],
        gradingScaleID: [0],
        gradingScaleDetailID: [0],
      })
    );
  }
  GetAllGradingScale() {
    this.gradeScaleList = new MatTableDataSource();
    this.manageAssessmentService
      .GetAllGradingscaleList(this.academicSessionID)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.gradeScaleList.data = res.responseData;
        this.gradeScaleList.sort = this.sort;
        this.gradeScaleList.paginator = this.paginator;
        this.gradeScaleList.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
        }
      });
  }

  arrayMasterData: any[] = [];
  validate: boolean = false;
  onSubmit() {
    this.validate = false;
    this.err = false;
    let filteredValues = this.taxItemArr.value; //.filter((row: any) => row.isChecked == true && row.taxMasterID > 0);

    console.log('dataToBeSend', filteredValues);
    filteredValues.forEach((element: any) => {
      if (
        element.minValue == null ||
        element.maxValue == null ||
        element.denotedAlpha == ''
      ) {
        this.toaster.error('Please make sure you have filled all fields');
        this.validate = true;
      }
    });
    if (!this.validate) {
      filteredValues.forEach((element: any) => {
        if (element.maxValue < element.minValue) {
          this.err = true;
          this.toaster.error("Max value can't be less than Min value");
        }
      });
    }

    if (!this.validate && !this.err) {
      if (this.addGradingScaleForm.value.gradingScaleID > 0) {
        console.log('going to update');
        let obj = {
          gradingScaleName: this.addGradingScaleForm.value.gradingScaleName,
          isgpaCalcualtion: this.addGradingScaleForm.value.isgpaCalcualtion,
          GradingScaleID: this.addGradingScaleForm.value.gradingScaleID,
          academicSessionID: this.addGradingScaleForm.value.academicSessionID,
          gradingScaleDetailsList: filteredValues,
        };
        sessionStorage.setItem('Loader', '1');
        this.manageAssessmentService
          .UpdateGradingScaleByID(obj)
          .subscribe((res: any) => {
            this.addGradingScaleForm.patchValue({
              gradingScaleName: '',
              isgpaCalcualtion: false,
              gradingScaleID: 0,
              academicSessionID: 0,
              ace: 0,
            });
            
            console.log('res');
            if (res.status == 0) {
              this.toaster.error(res.message);
            } else {
              if (res.status > 0) {
                this.toaster.success(res.message);
                this.taxItemArr.clear();
                this.dialog.closeAll();
                this.GetAllGradingScale();
                this.GetAllGradeMasterData();
              } else {
                this.toaster.error(res.message);
              }
            }
          });
      } else {
        console.log('going to add');
        this.objData = {
          gradingScaleName: this.addGradingScaleForm.value.gradingScaleName,
          isgpaCalcualtion: this.addGradingScaleForm.value.isgpaCalcualtion,
          academicSessionID: this.academicSessionID,
          gradingScaleDetailsList: filteredValues
        };
        console.log('this.objData', this.objData);
        console.log('gradeMasterData', this.gradeMasterData);

        sessionStorage.setItem('Loader', '1');
        this.manageAssessmentService
          .AddGradingScaleAPI(this.objData)
          .subscribe((res: any) => {
            this.addGradingScaleForm.patchValue({
              gradingScaleName: '',
              isgpaCalcualtion: false,
              gradingScaleID: 0,
              academicSessionID: 0,
            });
            if (res.status == 0) {
              this.toaster.error(res.message);
            } else {
              if (res.status > 0) {
                this.toaster.success(res.message);
                this.taxItemArr.clear();
                this.dialog.closeAll();
                this.GetAllGradingScale();
                this.GetAllGradeMasterData();
              } else {
                this.toaster.error(res.message);
              }
            }
          });
      }
    }
  }

  deleteGradeScale(id: number) {
    this.dialogService
      .confirm(`Are you sure you want to delete Grading Scale?`)
      .subscribe((result: any) => {
        if (result == true) {
          this.manageAssessmentService
            .deleteGradingScale(id)
            .subscribe((res: any) => {
              if (res.status > 0) {
                this.toaster.success(res.message);
                this.GetAllGradingScale();
              } else {
                this.toaster.error(res.message);
              }
            });
        }
      });
  }

  resetForm() {
    this.addGradingScaleForm.reset();
    this.addGradingScaleForm.patchValue({
      isgpaCalcualtion: false,
      gradingScaleID: 0,
      academicSessionID: 0,
    });
  }
  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
    this.addGradingScaleForm.patchValue({
      isgpaCalcualtion: false,
      gradingScaleName: '',
      gradingScaleID: 0,
      academicSessionID: 0,
    });
    this.setGPA = false;
    this.validate = false;
    this.updateTextInPopUp = false;
    this.err = false;
    this.taxItemArr.clear();
    this.GetAllGradeMasterData();
    // this.addGradingScaleForm.patchValue({
    //   'isgpaCalcualtion': false
    // });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
  get taxItemArr() {
    return this.addGradingScaleForm.get('taxItemList') as FormArray;
  }
  removeTax(item: any) {
    const index = this.taxItemArr.controls.indexOf(item);
    if (index > -1) {
      this.taxItemArr.controls.splice(index, 1);
    }
    var arr = this.taxItemArr.controls.filter((obj) => obj !== item);
    this.taxItemArr.patchValue(arr);
    console.log(this.taxItemArr);
  }

  UpdateGradeScale(templateRef: TemplateRef<any>, event: any) {
    this.err = false;
    this.validate = false;
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef);
    this.addGradingScaleForm = this.fb.group({
      gradingScaleName: ['', Validators.required],
      isgpaCalcualtion: false,
      gradingScaleID: [0],
      academicSessionID: [0],
      taxItemList: this.fb.array([]),
    });
    this.manageAssessmentService
      .GetGradingScaleByID(event)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.taxItemArr.clear();
          this.gradeMasterData = res.responseData;
          this.listtax = res.responseData.getGradingScaleDetailsList;
          console.log('gradeMasterData', res);
          for (let x of this.listtax) {
            this.addTax();
          }
          this.addGradingScaleForm.patchValue({
            gradingScaleName: res.responseData.gradingScaleName,
            isgpaCalcualtion: res.responseData.isgpaCalcualtion,
            gradingScaleID: res.responseData.gradingScaleID,
            academicSessionID: res.responseData.academicSessionID
          });
          this.taxItemArr.patchValue(this.listtax);
          this.setGPA = res.responseData.isgpaCalcualtion;
          console.log('myform', this.taxItemArr.value);
        }
      });
  }
}

export interface PeriodicElement {
  id: number;
  name: string;
  actions: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: 1, name: 'Default Grading', actions: ' ' },
  { id: 2, name: 'Pass or Fail', actions: ' ' },
];
