<!-- <app-main-page></app-main-page> -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>
<router-outlet></router-outlet>

<svg xmlns="http://www.w3.org/2000/svg" style="display:none;">
  <symbol id="successCheck" viewBox="0 0 130 130">
    <g id="success-check" transform="translate(-895 -211)">
      <g id="Ellipse_9" data-name="Ellipse 9" transform="translate(895 211)" fill="none" stroke="#fff" stroke-width="3">
        <circle cx="65" cy="65" r="65" stroke="none" />
        <circle cx="65" cy="65" r="63.5" fill="none" />
      </g>
      <path id="Icon_feather-check" data-name="Icon feather-check" d="M56,9,21.625,43.375,6,27.75"
        transform="translate(929.5 249.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="3" />
    </g>
  </symbol>
  <symbol id="admin-icon" viewBox="0 0 34.838 36.983">
    <g id="admin" opacity="0.8">
      <path id="Path_9189" data-name="Path 9189"
        d="M233.568,93.523c-.047-.882-.949-.732-1.829-.732A7.79,7.79,0,0,1,233.568,93.523Z"
        transform="translate(-214.752 -85.55)" fill="#1a2a4c" />
      <path id="Path_9190" data-name="Path 9190"
        d="M91.358,73.928a3.641,3.641,0,0,0-2.257,6.5,7.884,7.884,0,0,1,5.213-4.974A3.644,3.644,0,0,0,91.358,73.928Z"
        transform="translate(-81.947 -68.167)" fill="currentColor" />
      <path id="Path_9191" data-name="Path 9191"
        d="M16.121,11.582a7.576,7.576,0,0,0,.534,1.281l-1.122,1.123a.773.773,0,0,0,0,1.092l1.978,1.978a.776.776,0,0,0,1.093,0l1.121-1.123c.168.089.355.144.529.22,0-.013,0-.026,0-.039a11.506,11.506,0,0,1,.293-2.6,4.879,4.879,0,1,1,6.874-6.5,8.508,8.508,0,0,1,2.757.081A7.365,7.365,0,0,0,29.7,5.959l1.122-1.123a.769.769,0,0,0,0-1.092L28.844,1.767a.77.77,0,0,0-1.09,0L26.628,2.89a7.229,7.229,0,0,0-1.281-.532V.772A.77.77,0,0,0,24.576,0h-2.8a.773.773,0,0,0-.772.772V2.358a7.306,7.306,0,0,0-1.282.532L18.6,1.768a.772.772,0,0,0-1.092,0L15.533,3.744a.775.775,0,0,0,0,1.092l1.122,1.122a7.386,7.386,0,0,0-.531,1.281H14.538a.772.772,0,0,0-.772.773v2.8a.772.772,0,0,0,.772.772Z"
        transform="translate(-13.766 0)" fill="currentColor" />
      <path id="Path_9192" data-name="Path 9192"
        d="M287.386,252.254a3.973,3.973,0,1,0-4-6.858,14.365,14.365,0,0,1-1.7,4.068A11.947,11.947,0,0,1,287.386,252.254Z"
        transform="translate(-260.803 -225.707)" fill="currentColor" />
      <path id="Path_9193" data-name="Path 9193"
        d="M316.56,174.3a.834.834,0,0,0-.833-.833h-1.711a7.9,7.9,0,0,0-.576-1.383l1.211-1.211a.832.832,0,0,0,0-1.178l-2.132-2.133a.831.831,0,0,0-1.177,0l-1.214,1.212a7.786,7.786,0,0,0-1.383-.574v-1.711a.832.832,0,0,0-.831-.833H304.9a.821.821,0,0,0-.525.2,11.346,11.346,0,0,1,.393,3,14.439,14.439,0,0,1-.152,2.059,5.156,5.156,0,0,1,1.792-.337,5.219,5.219,0,0,1,2.822,9.615,11.917,11.917,0,0,1,2.429,4.051.856.856,0,0,0,.276.053.832.832,0,0,0,.589-.244l2.134-2.133a.838.838,0,0,0,0-1.178l-1.214-1.212a7.868,7.868,0,0,0,.576-1.383h1.711a.835.835,0,0,0,.834-.833Z"
        transform="translate(-281.724 -152.743)" fill="currentColor" />
      <path id="Path_9194" data-name="Path 9194"
        d="M47.575,333.67a10.081,10.081,0,0,0-8.659-6.782,6.617,6.617,0,0,1-4.811,2.287,6.614,6.614,0,0,1-4.809-2.287,10.081,10.081,0,0,0-8.662,6.782l-.715,2.105A1.984,1.984,0,0,0,21.8,338.4H46.412a1.983,1.983,0,0,0,1.878-2.621Z"
        transform="translate(-19.342 -301.413)" fill="currentColor" />
      <path id="Path_9195" data-name="Path 9195"
        d="M120.842,119.841c0,4.722,2.874,9.788,6.418,9.788s6.419-5.067,6.419-9.788C133.679,110.053,120.842,110.034,120.842,119.841Z"
        transform="translate(-112.497 -103.726)" fill="currentColor" />
    </g>
  </symbol>

  <symbol id="teachers-icon" viewBox="0 0 38.135 37.824">
    <g id="XMLID_129_" opacity="0.8">
      <path id="XMLID_134_"
        d="M219.413,5.656H199.141a1.22,1.22,0,0,0-1.22,1.221v8.609l.882-1.031a2.808,2.808,0,0,1,1.558-.919V8.1h17.832V24.035H200.361V21.367l-.549.642a2.834,2.834,0,0,1-1.891.976v2.269a1.22,1.22,0,0,0,1.22,1.22h6.584L202.38,41.66a1.262,1.262,0,0,0,2.465.544l3.464-15.729h2.014L213.787,42.2a1.262,1.262,0,1,0,2.465-.544l-3.345-15.186h6.505a1.22,1.22,0,0,0,1.22-1.22V6.877A1.221,1.221,0,0,0,219.413,5.656Z"
        transform="translate(-182.498 -5.371)" fill="currentColor" />
      <path id="XMLID_132_"
        d="M180.6,93.582a.47.47,0,0,0-.611-.265L172.8,95.854a1.578,1.578,0,0,0-2.161.22l-2.281,2.663L166.339,97c.013.143.044.282.044.429V101.2l1.125.964a1.579,1.579,0,0,0,2.225-.171l2.715-3.175,2.19-1.281,5.763-3.372A.47.47,0,0,0,180.6,93.582Z"
        transform="translate(-153.377 -86.17)" fill="currentColor" />
      <path id="XMLID_131_"
        d="M8.566,105.776H7.211a3.161,3.161,0,0,0-2.528,1.272L.525,110.876A1.577,1.577,0,0,0,.4,113.1l3.392,3.786a1.577,1.577,0,1,0,2.349-2.1l-2.338-2.611L7.126,109.2l-1.934,2.628,1.892,2.112a2.83,2.83,0,0,1-3.049,4.562s.015,7.3.015,15.114a1.893,1.893,0,0,0,3.786,0V122.338H9.1V133.62a3.13,3.13,0,0,1-.395,1.5,1.871,1.871,0,0,0,1.144.394,1.893,1.893,0,0,0,1.893-1.893c0-7.814,0-4.538,0-24.668A3.176,3.176,0,0,0,8.566,105.776Z"
        transform="translate(0 -97.689)" fill="currentColor" />
      <path id="XMLID_130_" d="M55.88,8.946a3.546,3.546,0,0,0,3.372,0,3.69,3.69,0,1,0-3.372,0Z"
        transform="translate(-49.678 -1.995)" fill="currentColor" />
    </g>
  </symbol>

  <symbol id="students-icon" viewBox="0 0 36.032 42.772">
    <g id="student-icon" opacity="0.8">
      <circle id="Ellipse_10" data-name="Ellipse 10" cx="7" cy="7" r="7" transform="translate(11)"
        fill="currentColor" />
      <path id="Path_9205" data-name="Path 9205"
        d="M154.185,181.515a9.617,9.617,0,0,1-10.468,0,14.65,14.65,0,0,0-4.557,2.783l9.791,3.672,9.791-3.672A14.648,14.648,0,0,0,154.185,181.515Z"
        transform="translate(-130.936 -166.436)" fill="currentColor" />
      <path id="Path_9206" data-name="Path 9206"
        d="M413.095,310h-.838A1.257,1.257,0,0,0,411,311.257v3.352a1.257,1.257,0,0,0,1.257,1.257h.838a2.933,2.933,0,0,0,0-5.866Z"
        transform="translate(-379.996 -284.155)" fill="currentColor" />
      <path id="Path_9207" data-name="Path 9207"
        d="M81.541,231.828A1.257,1.257,0,0,0,81,232.861v2.8h.419a3.775,3.775,0,0,1,3.771,3.771v3.352a3.775,3.775,0,0,1-3.771,3.771H81V249.2a1.257,1.257,0,0,0,.816,1.177L94.407,255.1V236.075L82.7,231.684A1.256,1.256,0,0,0,81.541,231.828Z"
        transform="translate(-77.648 -212.328)" fill="currentColor" />
      <path id="Path_9208" data-name="Path 9208"
        d="M280.217,242.782V239.43a3.775,3.775,0,0,1,3.771-3.771h.419v-2.8a1.257,1.257,0,0,0-1.7-1.177L271,236.075V255.1l12.592-4.722a1.257,1.257,0,0,0,.816-1.177v-2.648h-.419A3.775,3.775,0,0,1,280.217,242.782Z"
        transform="translate(-251.727 -212.328)" fill="currentColor" />
      <path id="Path_9209" data-name="Path 9209"
        d="M46.028,314.609v-3.352A1.257,1.257,0,0,0,44.771,310h-.838a2.933,2.933,0,0,0,0,5.866h.838A1.257,1.257,0,0,0,46.028,314.609Z"
        transform="translate(-41 -284.155)" fill="currentColor" />
    </g>
  </symbol>

  <symbol id="parents-icon" viewBox="0 0 43.884 39.008">
    <path id="Icon_metro-users" data-name="Icon metro-users"
      d="M31.827,34.5V32.488c2.686-1.513,4.876-5.286,4.876-9.06,0-6.059,0-10.971-7.314-10.971s-7.314,4.912-7.314,10.971c0,3.774,2.19,7.547,4.876,9.06V34.5c-8.27.676-14.628,4.74-14.628,9.653H46.455c0-4.913-6.358-8.976-14.628-9.653Zm-16.792.942a23.162,23.162,0,0,1,7.611-3.063A13.753,13.753,0,0,1,21.1,30.128a13.96,13.96,0,0,1-1.771-6.7c0-3.278,0-6.373,1.166-8.9a7.938,7.938,0,0,1,6.065-4.552c-.644-2.913-2.36-4.828-6.926-4.828-7.314,0-7.314,4.912-7.314,10.971,0,3.774,2.19,7.547,4.876,9.06v2.011c-8.27.676-14.628,4.74-14.628,9.653H13.2a15.616,15.616,0,0,1,1.836-1.4Z"
      transform="translate(-2.571 -5.143)" fill="currentColor" opacity="0.8" />
  </symbol>

  <symbol id="emailsent-icon" viewBox="0 0 118 117.998">
    <g id="email" transform="translate(-0.001 -0.004)">
      <g id="Group_1837" data-name="Group 1837" transform="translate(0.001 0.004)">
        <g id="Group_1836" data-name="Group 1836" transform="translate(0 0)">
          <path id="Path_17471" data-name="Path 17471"
            d="M117.977,45.114a1.86,1.86,0,0,0-.081-.4,1.968,1.968,0,0,0-.112-.338,1.765,1.765,0,0,0-.2-.3,1.854,1.854,0,0,0-.262-.3c-.032-.027-.045-.065-.079-.092L98.333,28.989V13.764a5.9,5.9,0,0,0-5.9-5.9H71.119L62.585,1.238a5.832,5.832,0,0,0-7.173,0L46.877,7.864H25.563a5.9,5.9,0,0,0-5.9,5.9V28.989L.754,43.679c-.033.028-.047.065-.079.092a1.845,1.845,0,0,0-.262.3,1.755,1.755,0,0,0-.2.3,1.941,1.941,0,0,0-.112.338,1.864,1.864,0,0,0-.081.393c0,.041-.024.077-.024.118V112.1a5.834,5.834,0,0,0,1.154,3.471c.012.018.014.039.027.055s.043.033.063.055A5.872,5.872,0,0,0,5.9,118H112.1a5.873,5.873,0,0,0,4.665-2.329c.016-.02.039-.026.053-.045s.016-.037.028-.055A5.835,5.835,0,0,0,118,112.1V45.232C118,45.191,117.979,45.156,117.977,45.114ZM57.818,4.341a1.886,1.886,0,0,1,2.346,0L64.7,7.864H53.294ZM6.391,114.069,57.818,74.122a1.888,1.888,0,0,1,2.346,0L111.6,114.069ZM114.067,111,62.585,71.018a5.836,5.836,0,0,0-7.173,0L3.928,111V48.228L36.158,73.255a1.967,1.967,0,0,0,2.411-3.11L5.826,44.721,19.663,33.968v15.2a1.967,1.967,0,1,0,3.933,0v-35.4A1.967,1.967,0,0,1,25.563,11.8h66.87A1.967,1.967,0,0,1,94.4,13.764v35.4a1.967,1.967,0,0,0,3.933,0v-15.2l13.836,10.752-32.8,25.47A1.967,1.967,0,1,0,81.781,73.3l32.286-25.07V111Z"
            transform="translate(-0.001 -0.005)" fill="#5ca4eb" />
          <path id="Path_17472" data-name="Path 17472"
            d="M200.776,99.705V91.838a23.6,23.6,0,1,0-23.6,23.6,1.967,1.967,0,1,0,0-3.933,19.668,19.668,0,1,1,19.668-19.668v7.867a3.934,3.934,0,1,1-7.867,0V91.838a1.967,1.967,0,1,0-3.933,0,7.867,7.867,0,1,1-7.867-7.867,1.967,1.967,0,1,0,0-3.933,11.8,11.8,0,1,0,7.952,20.5,7.845,7.845,0,0,0,15.65-.829Z"
            transform="translate(-118.178 -52.511)" fill="#5ca4eb" />
        </g>
      </g>
    </g>
  </symbol>

</svg>