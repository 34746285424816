import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { MasterService } from 'src/app/services/master.service';
import { TeachersService } from 'src/app/services/teachers.service';
import { FormioOptions } from 'angular-formio';
import { DatePipe, Location } from '@angular/common'
import * as moment from 'moment';
import { StudentService } from 'src/app/services/student.service';
@Component({
  selector: 'app-add-teachers',
  templateUrl: './add-teachers.component.html',
  styleUrls: ['./add-teachers.component.css']
})
export class AddTeachersComponent implements OnInit {

  form: FormGroup;
  countries: any;

  employeeTypes: any;
  bloodGroups: any;
  genders: any;
  teacherSkills: any;
  phoneShow: boolean;
  public jsonFormData: Object = {
    components: []
  };
  // initialFormValues: any = null;
  // formioOptions: FormioOptions = {
  //   disableAlerts: true
  // }
  getFieldAndGroupDto: any;
  fieldTypeDto: any;
  @ViewChild('phone') phone: any;
  contactNumberCountryCode: any;
  teacherID: number = 0;
  previousCountryVal: any = [];
  previousStateVal: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private teacherService: TeachersService,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private masterService: MasterService,
    private location: Location,
    private datePipe: DatePipe,
    private studentService: StudentService,
    private activatedRoute: ActivatedRoute,
  ) {
    document.addEventListener('keydown', e => {
      if ((e.target as any).nodeName === 'MAT-SELECT') {
        e.stopImmediatePropagation();
        if (e.key=='SPACE') {
          return;
        }
      }
      if ((e.target as any).nodeName === 'INPUT') {
        e.stopImmediatePropagation();
      }
    }, true);
    this.GetCountiresList();
   }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.teacherID = params.id == undefined ? 0 : params.id;
    });
    console.log("teacherID", this.teacherID)
    this.form = this.formBuilder.group({
      employeeTypeID: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      genderID: ['', Validators.required],
      bloodGroupID: [''],
      dateOfBirth: ['', Validators.required],
      address: [''],
      zipCode: [''],
      countryID: [0],
      stateID: [0],
      city: [''],

      profilePicture: [''],
      teacherSkillsID: [''],
      profileSummary: [''],
      facebookLink: [''],
      linkedInlink: [''],
      teacherNumber: ['', Validators.required],
      userID: ['337BFA1D-3D51-4E04-146D-08D9AFD6B314'],
      hasExcess: [true],
      password: ['Password@pm01'],
      userName: ['Priya'],
      roleID: 1

    });
    // this.masterService.GetCustomFormTemplate(2).subscribe((res: any) => {
    //   if (res.responseData.formInJson != "") {
    //     this.extraFieldForm = true
    //     this.jsonFormData = JSON.parse(res.responseData.formInJson || '');
    //   }
    // })
    if (this.teacherID > 0)
      this.GetTeacherById();
    else
      this.GetTeacherForm();
    
    this.GetBloodGroupList();
    this.GetGenderList();
    this.GetTeacherSkillsList();
    this.GetEmployeeTypeList();
  }
  
  onKeyFilter(val: any, type: string){
    let serachTxt = String(val.target.value);
    if(type == 'state') {
      if(serachTxt != ''){
        this.states = this.previousStateVal.filter((x: any)=> x.stateName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.states = this.previousStateVal;
      }
    } else if(type == 'country') {
      if(serachTxt != ''){
        this.countries = this.previousCountryVal.filter((x: any)=> x.countryName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
      } else {
        this.countries = this.previousCountryVal;
      }
    }
  }

  GetTeacherForm() {
    this.studentService.GetCustomFormById(2).subscribe((res: any) => {

      this.fieldTypeDto = res.responseData.fieldTypeDto;
      this.getFieldAndGroupDto = res.responseData.getFieldAndGroupDto;

      this.getFieldAndGroupDto.forEach((grp: any) => {
        grp.fields = grp.fields.filter((f: any) => f.isUnpublished == false);
        grp.fields.forEach((f: any) => {
          if(f.isDynamicField==true)
          {   if(f.fieldTypeName!=='Checkbox')
          {
            if(f.email==true && f.isRequried==true)
              this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.required,Validators.email]));
            else if(f.isRequried==true)
              this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.required]));
            else if(f.email==true)
              this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.email]));
            else
              this.form.addControl(f.fieldName, this.formBuilder.control(''));
          }
          }
          if(f.fieldName=='Blood Group')
      {
        if(f.isRequried==true)
        {
          this.form.controls["bloodGroupID"].setValidators(Validators.required);;
        }
      }
        });
      });
      console.log("FIELDDATA", res.responseData)
    })
  }
  GetTeacherById() {
    debugger
    this.teacherService.getTeacherById(this.teacherID).subscribe((res: any) => {
      console.log("TEACHERDATA", res.responseData)
      let teacherdetail = res.responseData.coreTeacherDetails;
      this.getFieldAndGroupDto = teacherdetail.groups;
      let profilePhoto: any, employeeTypeID: any, firstName: any, lastName: any, contactNumber: any, email: any,
        genderID: any, bloodGroupID: any, dateOfBirth: any, address: any, zipCode: any, countryID: any, stateID: any, city: any,
        profilePicture: any, teacherSkillsID: any, profileSummary: any, facebookLink: any, linkedInlink: any, teacherNumber: any
      profilePhoto = teacherdetail.profilePhoto;
      if (teacherdetail.profilePhoto !== undefined && teacherdetail.profilePhoto !== null && teacherdetail.profilePhoto !== '') {
        this.croppedImage = teacherdetail.profilePhoto;
      }
      this.getFieldAndGroupDto.forEach((grp: any) => {
        grp.fields = grp.fields.filter((f: any) => f.isUnpublished == false);
        grp.fields.forEach((f: any) => {
          if (f.isDynamicField == true) {
            if (f.fieldTypeName !== 'Checkbox') {
          if(f.email==true && f.isRequried==true)
          this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.required,Validators.email]));
        else if(f.isRequried==true)
          this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.required]));
        else if(f.email==true)
          this.form.addControl(f.fieldName, this.formBuilder.control('', [Validators.email]));
        else
        this.form.addControl(f.fieldName, this.formBuilder.control(''));

              this.form.controls[f.fieldName].setValue(f.fieldValue)
            }
          }
          if (f.fieldName == 'Employee Type') {
            employeeTypeID = f.fieldValue;
          }
          if (f.fieldName == 'First Name') {
            firstName = f.fieldValue;
          }
          if (f.fieldName == 'Last Name') {
            lastName = f.fieldValue;
          }
          if (f.fieldName == 'Contact') {
            if (f.fieldValue !== null && f.fieldValue !== '' && f.fieldValue !== undefined)
              contactNumber = teacherdetail.contactNumberCountryCode + f.fieldValue;
            else
              contactNumber = "";
          }
          if (f.fieldName == 'Teacher Email') {
            email = f.fieldValue;
          }
          if (f.fieldName == 'Gender') {
            genderID = f.fieldValue;
          }
          if (f.fieldName == 'Blood Group') {
            bloodGroupID = f.fieldValue;
            if(f.isRequried==true)
        {
          this.form.controls["bloodGroupID"].setValidators(Validators.required);;
        }
          }
          if (f.fieldName == 'Date of Birth') {
            dateOfBirth = f.fieldValue;
          }
          if (f.fieldName == 'Address') {
            address = f.fieldValue;
          }
          if (f.fieldName == 'Zip Code') {
            zipCode = f.fieldValue;
          }
          if (f.fieldName == 'Country') {
            countryID = f.fieldValue;
            if (countryID !== undefined && countryID !== null && countryID !== '') {
              this.form.patchValue({
                countryID: countryID>0? Number(countryID):''
              })
              
            }
          }
          if (f.fieldName == 'State') {
            stateID = f.fieldValue;
          }
          if (f.fieldName == 'City') {
            city = f.fieldValue;
          }
          if (f.fieldName == 'Teacher Code') {
            teacherNumber = f.fieldValue;
          }
          if (f.fieldName == 'Skills') {
            teacherSkillsID = f.fieldValue;
          }
          if (f.fieldName == 'Profile Summary') {
            profileSummary = f.fieldValue;
          }
          if (f.fieldName == 'Facebook Profile Link') {
            facebookLink = f.fieldValue;
          }
          if (f.fieldName == 'LinkedIn Profile Link') {
            linkedInlink = f.fieldValue;
          }
        })
      })
      
      this.form.patchValue({
        employeeTypeID: employeeTypeID > 0 ? Number(employeeTypeID) : '',
        firstName: firstName,
        lastName: lastName,
        contactNumber: contactNumber,
        email: email,
        genderID: genderID > 0 ? Number(genderID) : '',
        bloodGroupID: bloodGroupID > 0 ? Number(bloodGroupID) : '',
        dateOfBirth: dateOfBirth,
        address: address,
        zipCode: zipCode,
        countryID: countryID > 0 ? Number(countryID) : '',
        stateID: stateID > 0 ? Number(stateID) : '',
        city: city,

        profilePicture: profilePicture,
        teacherSkillsID: teacherSkillsID,
        profileSummary: profileSummary,
        facebookLink: facebookLink,
        linkedInlink: linkedInlink,
        teacherNumber: teacherNumber,
      })
      this.GetStateList(countryID,stateID,true);
      console.log("Final edit", this.form.value)
    })
  }
  countryName:any=[];
  dispalyFns(countrtyID: any){
    this.countryName=this.country.filter((a:any)=>a.countryID==countrtyID);
    return this.countryName[0]?.countryName

  }

  ddlChange(value: any, grp: any, fieldName: any) {
    this.form.valid;
    this.getFieldAndGroupDto
      .find((g: any) => g.groupID == grp.groupID)
      .fields.find(
        (f: any) => f.fieldName == fieldName
      ).fieldValue = value;
  }
  chkChange(value: any, grp: any, chk: any, fieldName: any) {
    let val = this.getFieldAndGroupDto
      .find((g: any) => g.groupID == grp.groupID)
      .fields.find(
        (f: any) => f.fieldName == fieldName
      ).fieldValue;
    if (val == undefined || val == null || val == '') {
      val = [];
    }
    else {
      val = this.getFieldAndGroupDto
        .find((g: any) => g.groupID == grp.groupID)
        .fields.find(
          (f: any) => f.fieldName == fieldName
        ).fieldValue.split(',');
    }
    if (value.checked) {
      val.push(chk);
      this.getFieldAndGroupDto
        .find((g: any) => g.groupID == grp.groupID)
        .fields.find(
          (f: any) => f.fieldName == fieldName
        ).fieldValue = val.join(',');
    }
    else {
      val.pop(chk);
      this.getFieldAndGroupDto
        .find((g: any) => g.groupID == grp.groupID)
        .fields.find(
          (f: any) => f.fieldName == fieldName
        ).fieldValue = val.join(',');
    }
  }
  splitValues(values: any) {
    if (values !== undefined && values !== null && values !== '')
      return values.split(',');
    else
      return "";
  }
  genderID: any;
  setchkValue(fieldValue: any, chk: any) {
    if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
      let vals = fieldValue.split(',');

      return vals.includes(chk);

    }
    return false
  }
  onBlurMethodtxt(value: any, grp: any, fieldName: any) {
    this.getFieldAndGroupDto
      .find((g: any) => g.groupID == grp.groupID)
      .fields.find(
        (f: any) => f.fieldName == fieldName
      ).fieldValue = value.target.value;
  }
  onBlurPhone(value: any, grp: any, fieldName: any) {
    if (this.phone.numberInstance !== undefined && this.phone.numberInstance !== null && this.phone.numberInstance !== '') {
      this.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
      this.getFieldAndGroupDto
        .find((g: any) => g.groupID == grp.groupID)
        .fields.find(
          (f: any) => f.fieldName == fieldName
        ).fieldValue = this.phone.numberInstance.nationalNumber;
    }
  }
  country:any=[];
  GetCountiresList() {
    this.masterService.GetCountryDropdown().subscribe((res: any) => {
      this.countries = res.responseData;
      this.country=[...this.countries];
      this.previousCountryVal = Object.assign([], this.countries);
      console.log("countries", this.countries);
    })
  }
  states: any;
  stateValues:any=[]
  GetStateList(countryId: any,stateID?: any, defaults?:any) {
   // let countryID= countryId.option.value;
   let countryID=this.form.controls['countryID'].value;
    this.form.controls['stateID'].setValue('');
    this.masterService.GetStateDropdown(countryID).subscribe((res: any) => {
      this.states = res.responseData;
      this.stateValues=[...this.states];
      this.previousStateVal = Object.assign([], this.states);
      console.log(res);
      if(defaults){
        this.form.patchValue({
          stateID: stateID>0? Number(stateID):''
          
        })
      }
    })
  }

  stateName:any=[];
  dispalyStateAuto(stateID:any){
    this.stateName=this.stateValues.filter((a:any)=>a.stateID==stateID)
  return this.stateName[0]?.stateName;
  }
  GetBloodGroupList() {
    this.masterService.GetBloodGroupDropdown().subscribe((res: any) => {
      this.bloodGroups = res.responseData;
    })
  }
  GetGenderList() {
    this.masterService.GetGenderDropdown().subscribe((res: any) => {
      this.genders = res.responseData;
    })
  }

  GetEmployeeTypeList() {
    this.masterService.GetEmployeeTypeDropdown().subscribe((res: any) => {
      this.employeeTypes = res.responseData;
    })
  }

  GetTeacherSkillsList() {
    this.masterService.GetTeacherSkillsDropdown().subscribe((res: any) => {
      this.teacherSkills = res.responseData;

    })
  }
  profileImage: string = '';
  AddProfile(file: any) {
    this.teacherService.postData(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {
        this.profileImage = res.body.responseData.imageName;
        console.log("add profile", this.profileImage);
      }

    })

  }
  back() {
    this.location.back()
  }
  formData: any;
  onChange(event: any) {
    if (event.data != undefined || event.data != null) {
      this.formData = {}
      this.formData = {
        data: event.data
      }
      this.form.value.teacherExtraInfoInJson = JSON.stringify(this.formData)
      console.log("EVENT", this.form.value.teacherExtraInfoInJson)
    }
  }
  onSubmit() {
    let chkerror="";
    this.getFieldAndGroupDto.forEach((grp: any) => {
      grp.fields= grp.fields.filter((f:any) =>f.isUnpublished==false);
      grp.fields.forEach((f:any) => {
        if(f.fieldTypeName=='Checkbox' && f.isRequried==true)
        {
          if(f.fieldValue==undefined || f.fieldValue==null || f.fieldValue=='')
          {
            chkerror=chkerror+" "+f.fieldName+" field is required";
          }
        }
      })
    });
    if(chkerror!=="")
    {
      this.toaster.warning(chkerror);
      return;
    }
    if (this.form.invalid) {
      return;
    }
    if (this.profileImage != '') {
      this.form.controls.profilePicture.setValue(this.profileImage);
    }
    if (this.teacherID > 0) {
      let data = {
        teacherModel: {
          teacherID: this.teacherID,
          ContactNumberCountryCode: '+' + (this.phone.numberInstance.countryCallingCode),
          Groups: this.getFieldAndGroupDto
        }
      }
      this.teacherService.updateCoreTeacher(data).subscribe((res: any) => {
        console.log(res);
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');

          if (this.imageFile) {
            this.teacherService.UpdateTeacherProfilePhoto(this.teacherID, this.imageFile).subscribe((res: any) => {
              this.router.navigate(['/admin/academicslearning/manage-teachers']);
            }, err => this.router.navigate(['/admin/academicslearning/manage-teachers']));
          } else {
            this.router.navigate(['/admin/academicslearning/manage-teachers']);
          }

        } else {
          this.toaster.error(res.message, 'Error');
        }
      });
    }
    else {
      let data = {
        teacherModel: {
          teacherID: this.teacherID,
          ContactNumberCountryCode: '+' + (this.phone.numberInstance.countryCallingCode),
          Groups: this.getFieldAndGroupDto
        }
      }
      console.log("FINALDATA", this.getFieldAndGroupDto)
      this.teacherService.addCoreTeacher(data).subscribe((res: any) => {
        console.log(res);
          if (res.status > 0) {
            this.toaster.success(res.message, 'Success');

            if (this.imageFile) {
              this.teacherService.UpdateTeacherProfilePhoto(res.responseData, this.imageFile).subscribe((res: any) => {
                this.router.navigate(['/admin/academicslearning/manage-teachers']);
              }
                , err => this.router.navigate(['/admin/academicslearning/manage-teachers']));
            } else
              this.router.navigate(['/admin/academicslearning/manage-teachers']);
          } else {
            this.toaster.error(res.message, 'Error');
          }
      });
    }
    // this.form.controls.dateOfBirth.setValue(moment(this.form.value.dateOfBirth).format('YYYY-MM-DD'));
    // this.form.value.contactNumber = this.phone.numberInstance.nationalNumber
    // this.form.value.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
    // this.form.value.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);
    // console.log(this.form.value);
    // this.teacherService.AddTeacher(this.form.value).subscribe((res: any) => {
    //   console.log(res);
    //   if (res.status > 0) {
    //     this.toaster.success(res.message, 'Success');

    //     if (this.imageFile) {
    //       this.teacherService.UpdateTeacherProfilePhoto(res.responseData, this.imageFile).subscribe((res: any) => {
    //         this.router.navigate(['/admin/academicslearning/manage-teachers']);
    //       }
    //         , err => this.router.navigate(['/admin/academicslearning/manage-teachers']));
    //     } else
    //       this.router.navigate(['/admin/academicslearning/manage-teachers']);
    //   } else {
    //     this.toaster.error(res.message, 'Error');
    //   }


    // });
  }

  imageUrl: any;
  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();
    //for (let file of this.filesdata)
    formData.append('files', file);
    this.AddProfile(formData);
    // if (event.target.files && event.target.files[0]) {
    //   reader.readAsDataURL(file);

    //   // When file uploads set it to file formcontrol
    //   reader.onload = () => {
    //     this.imageUrl = reader.result;
    //     // this.registrationForm.patchValue({
    //     //   file: reader.result
    //     // });
    //     // this.editFile = false;
    //     // this.removeUpload = true;
    //   }
    //   // ChangeDetectorRef since file is loading outside the zone
    //   //this.cd.markForCheck();        
    // }
  }


  /// crop image
  imageChangedEvent: any = '';
  croppedImage: any = '../../../assets/img/display.jpg';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
    this.imageFile = this.imageChangedEvent.target.files[0];
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data);

  }
  finalUpload() {
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  allowAlphaNumber(event: any) {
    this.commonService.allowAlphaNumber(event)
  }
  phoneLength(event: any) {
    this.phoneShow = this.commonService.phoneLengths(event.target.value.length);
  }
  maxDate = new Date();
  minDate = new Date(1900, 0, 1);
}