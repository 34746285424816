import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TeachersService } from 'src/app/services/teachers.service';

@Component({
  selector: 'app-view-teacher',
  templateUrl: './view-teacher.component.html',
  styleUrls: ['./view-teacher.component.css']
})
export class ViewTeacherComponent implements OnInit {
  form: FormGroup;
  teacherID: number;
  data: string;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  genderName: string;
  employeeTypeName: string
  email: string
  profilePicture: string = '../../../assets/img/display.jpg';
  facebookLink: string
  linkedInlink: string
  profileSummary: string
  teacherSkillsName: string
  contactNumber: string
  address: string
  zipCode: string
  stateName: string
  countryName: string
  city: string
  bloodGroupName: string
  extraField: any
  croppedImage: any = '../../../assets/img/display.jpg';
  getFieldAndGroupDto: any;
  fieldTypeDto: any;
  users:any;
  dateFormat:any;

  constructor(

    private activatedRoute: ActivatedRoute,
    private teacherService: TeachersService

  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
    }
   }
  ngOnInit(): void {
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.teacherID = params.id == undefined ? null : params.id;
    });
    //this.adminUserID = this.activatedRoute.snapshot.params.Id


    // this.teacherService.GetTeacherListById(this.teacherID).subscribe((res: any) => {
    //   console.log(res.responseData);
    //   this.firstName = res.responseData.firstName;
    //   this.lastName = res.responseData.lastName;
    //   this.dateOfBirth = res.responseData.dateOfBirth;
    //   this.genderName = res.responseData.genderName;
    //   this.employeeTypeName = res.responseData.employeeTypeName;
    //   this.email = res.responseData.email;
    //   this.teacherSkillsName = res.responseData.teacherSkillsName;
    //   this.profileSummary = res.responseData.profileSummary;
    //   this.linkedInlink = res.responseData.linkedInlink;
    //   this.facebookLink = res.responseData.facebookLink;
    //   this.profilePicture = res.responseData.profilePicture ? res.responseData.profilePicture : '../../../assets/img/display.jpg';
    //   this.contactNumber = res.responseData.contactNumber;
    //   this.address = res.responseData.address;
    //   this.city = res.responseData.city;
    //   this.countryName = res.responseData.countryName;
    //   this.stateName = res.responseData.stateName;
    //   this.zipCode = res.responseData.zipCode;
    //   this.bloodGroupName = res.responseData.bloodGroupName;
    //   if (res.responseData.teacherExtraInfoInJson != "") {
    //     var extra = JSON.parse(res.responseData.teacherExtraInfoInJson)
    //     this.extraField = extra.data;
    //     this.extrafieldFlag = true;
    //   }

    // });
    this.GetTeacherById();
  }
  GetTeacherById() {
    this.teacherService.getDynamicTeacherById(this.teacherID).subscribe((res: any) => {
      console.log("TEACHERDATA", res.responseData)
      let teacherdetail = res.responseData.coreTeacherDetails;
      this.getFieldAndGroupDto = teacherdetail.groups;
      if (teacherdetail.profilePhoto !== undefined && teacherdetail.profilePhoto !== null && teacherdetail.profilePhoto !== '') {
        this.croppedImage = teacherdetail.profilePhoto;
      }
      this.getFieldAndGroupDto.forEach((grp: any) => {
        grp.fields = grp.fields.filter((f: any) => f.isUnpublished == false);
        grp.fields.forEach((f: any) => {
          if (f.fieldName == 'First Name') {
            this.firstName = f.fieldValue;
          }
          if (f.fieldName == 'Last Name') {
            this.lastName = f.fieldValue;
          }
        })
        })
    })
  }

}
