import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, ClassTimeTable } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ClassTimeTableService {

  constructor(private http : HttpClient) { }

  getTimetableList(data:any) {
    return this.http.post(`${environment.baseUrl}${ClassTimeTable.getTimetableList}`,data);
  }
  getTimetableDataByID(id:any){
    return this.http.get(`${environment.baseUrl}${ClassTimeTable.getTimeTableByID}${id}`)
    
  }
  gettTimetableDataByID(id:any){
     return this.http.get(`${environment.baseUrl}${ClassTimeTable.getTimeTableByID}${id}`).toPromise();
    
  }

  updateTimeTableDataByID(data:any){
    return this.http.post(`${environment.baseUrl}${ClassTimeTable.updateTimeTableData}`,data);
  }
  deleteTimeTableDataBYID(Id:any){
    return this.http.get(`${environment.baseUrl}${ClassTimeTable.deleteTimeTableDataByID}`+'?id='+Id);
  }
  getClassSheduleData(){
    return this.http.get(`${environment.baseUrl}${ClassTimeTable.getClassSheduleData}`);
  }
  getClassSheduleBycategoryId(id:any){
    return this.http.get(`${environment.baseUrl}${ClassTimeTable.getClassSheduleByCategoryId}${id}`);
  }
  getTeacherBySubjectId(id:any){
    return this.http.get(`${environment.baseUrl}${ClassTimeTable.getTeacherBysubjectId}${id}`);
  }
}
