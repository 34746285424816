
export class Metadata {
    totalRecords: number = 0;
    currentPage: number = 1;
    pageSize: number = 5;
    defaultPageSize: number = 5;
    totalPages: number = 0;
}
export class FilterModel {
    pageNumber: number = 1;
    pageSize: number = 10;
    sortColumn: string = '';
    sortOrder: string = '';
    searchText: string = '';
    columnName: string = '';
    accountId: any;
    academicSessionID: string = '';
    searchPageNumber:number=1;
}

export class timeTableModel {
  pageNumber: number = 1;
  pageSize: number = 10;
  sortColumn: string = '';
  sortOrder: string = '';
  searchText: string = '';
  columnName: string = '';
  accountId: any;
  academicSessionID: string = '';
  searchPageNumber:number=1;
  keyWord: string = '';
  startDate: string = '';
  endDate  : string = '';
}

export class SMSFilterModel {
    pageNumber: number = 1;
    pageSize: number = 10;
    sortColumn: string = '';
    sortOrder: string = '';
    searchText: string = '';
    columnName: string = '';
    accountId: any;
    message:any='';
    receiverNumber:any='';
}

export class EntryFilterModel {
    pageNumber: number = 1;
    pageSize: number = 10;
    sortColumn: string = '';
    sortOrder: string = '';
    searchText: string = '';
    columnName: string = '';
    accountId: any;
    accountUniqueReceiptNumber: any;
    accountEntryTypeId: number = 0;
    startDate: string = '';
    endDate  : string = '';
}

export class AttacmentFilterModel {
    pageNumber: number = 1;
    pageSize: number = 10;
    sortColumn: string = '';
    sortOrder: string = '';
    searchText: string = '';
    columnName: string = '';
    studentID: any;
}

export class TeacherAttacmentFilterModel {
    pageNumber: number = 1;
    pageSize: number = 10;
    sortColumn: string = '';
    sortOrder: string = '';
    searchText: string = '';
    columnName: string = '';
    teacherID: any;
}
export class Meta {
    totalPages?: number = 0;
    pageSize?: number = 5;
    currentPage?: number = 1;
    defaultPageSize?: number = 5
    totalRecords?: number = 0
}

export class FeesInvoiceMeta {
    pageSize: number = 10;
    pageNumber: number = 1;
    feeName: string = '';
    feeType: string = '';
    dueDateFrom: string = '';
    dueDateTo: string = '';
    isPartialPayment: number = 0;
    isArchived: boolean = false;
    isActive: boolean = true;
    columnName: string = '';
    sortOrder: string = '';
    sortColumn: string = '';
}

export class InvoiceMeta {
    pageSize: number = 10;
    pageNumber: number = 1;
    invoiceId: number = 0;
    statusId: number = 0;
    classid: number = 0;
    studentId: number = 0;
    academicSessionId: number = 0;
    departmentId: number = 0;
    section: number = 0;
    feeName: string = '';
    feeType: string = '';
    dueDateFrom: string = '';
    dueDateTo: string = '';
    invoiceDueDateFrom: string = '';
    invoiceDueDateTo: string = '';
    isPartialPayment: number = 0;
    isArchived: boolean = false;
    isActive: boolean = true;
    columnName: string = '';
    sortOrder: string = '';
    informed: boolean = false;
    recurring: boolean = false
}

export class StudentInvoiceMeta{
   pageSize: number = 10;
   pageNumber: number = 1;
   columnName: string = '';
  sortOrder: string = '';
  invoiceId:  number = 0;
  statusId:  number = 0;
  studentId:  number = 0;
  feeName: string = '';
  startDate: string = '';
  endDate: string = '';
  recurring: string = '';
}

export class PaymentMeta {
    pageSize: number = 10;
    pageNumber: number = 1;
    paymentID: number = 0;
    invoiceID: number = 0;
    transactionId: string = '';
    feeandInvoiceId: number = 0;
    paymentMethodMasterId: number = 0;
    paymentStatusId: number = 0;
    academicSessionId: number = 0;
    classId: number = 0;
    startDate: string = '';
    endDate: string = '';
    sectionId: number = 0;
    recurringId: number = 0;
    columnName: string = '';
    sortOrder: string = '';
    sortColumn: string = '';
    departmentId: number = 0;
}

export class StudentMata {
    pageSize: number = 10;
    pageNumber: number = 1;
    classID: number = 0;
    sectionID: number = 0;
    academicSessionID: number = 0;
    subjectID: number = 0;
    section: number = 0;
    firstName: string = '';
    sortColumn: string = '';
    sortOrder: string = '';
    searchText: string = '';
    columnName: string = '';
    stuEnrollmentStatusID: number = 0;
    subEnrollmentStatusID: number = 0;
}

export class EmailMasterMeta {
    pageNumber: number = 1;
    pageSize: number = 10;
    sortColumn: string = '';
    sortOrder: string = '';
    searchText: string = '';
    emailSubject: string = '';
    toEmail: string = '';
    isEmailDelivered: number;
    sendofAfterDate: string = '';
    sendOnBeforeDate: string = '';
    columnName: string = '';
    accountId: any;
}

export class DynamicStudentMeta {
    pageSize: number = 10;
    pageNumber: number = 1;
    filterFieldIds: any = "";
    filterFieldValue: any = "";
    AcademicSessionID: any = "";
    ClassID: any = "";
    SectionID: any = "";
    SubjectID: any = "";
    StudentEnrollmentStatusID: any = "";
    SubjectEnrollmentStatusID: any = "";
}
export class DynamicTeacherMeta{
    PageSize:number=5;
    PageNumber: number=1;
    FilterFieldId:any="";
    FilterFieldValue:any="";
}

export class DynamicAlumniMeta{
  PageSize:number=5;
PageNumber:number=1;
FilterFieldIds:any="";
FilterFieldValue:any="";
}

export class ReportSectionModel {
    PageSize: number = 10;
    PageNumber: number = 1;
    ColumnName: any = '';
    SortOrder: any = '';
    ClassID: any = '';
    SectionID: any = '';
    AcademicSessionID:any=1;
}


export class FormInputBase<T> {
    value: T;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    options: { key: string; value: string }[];
  
    constructor(
      options: {
        value?: T;
        key?: string;
        label?: string;
        required?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        options?: { key: string; value: string }[];
      } = {}
    ) {
      this.value = options.value!;
      this.key = options.key || '';
      this.label = options.label || '';
      this.required = !!options.required;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.options = options.options || [];
    }
  }

  export class DropdownInput extends FormInputBase<string> {
    
  }

  export class ModuleDefinition  {
      public static readonly Teacher: string = "Teacher";
      public static readonly Student: string = "Student";
      public static readonly Alumni: string = "Alumni";
  }

  export class Criteria {
    RubicCriteriaId: number;
    CriteriaTitle: string;
    CriteriaDescription: string;
    IsDeleted: boolean;
    isSelected: boolean;
    Attributes: CriteriaAttributes[];
  
    constructor(
      criteriaId: number,
      criteriaTitle: string,
      criteriaDescription: string,
      isDeleted: boolean,
      isSelected: boolean,
      attributes: CriteriaAttributes[]
    ) {
      this.RubicCriteriaId = criteriaId;
      this.CriteriaTitle = criteriaTitle;
      this.CriteriaDescription = criteriaDescription;
      this.IsDeleted = isDeleted;
      this.Attributes = attributes;
      this.isSelected= isSelected;
    }
  }

  export class CriteriaAttributes {
    RubicCriteriaAttributeId: number;
    AttributeName: string;
    AttributeValue: string;
    IsDeleted: boolean;
    isSelected: boolean;
  
    constructor(
      attributeId: number,
      attributeName: string,
      attributeValue: string,
      isDeleted: boolean,
      isSelected: boolean
    ) {
      this.RubicCriteriaAttributeId = attributeId;
      this.AttributeName = attributeName;
      this.AttributeValue = attributeValue;
      this.IsDeleted = isDeleted;
      this.isSelected = isSelected;
    }
  }

  export class Rubic {
    RubicId: number;
    RubicName: string;
    Criterias: Criteria[];
    constructor(rubicId: number, rubicName: string, criterias: Criteria[]) {
      this.RubicId = rubicId;
      this.RubicName = rubicName;
      this.Criterias = criterias;
    }
  }

  export interface RubicApiResponse{
     RubicID: number; 
     RubicCriteriaID: number; 
     RubicCriteriaAttributeID: number; 
     RubicName: string; 
     CriteriaTitle: string; 
     CriteriaDescription: string; 
     AttributeName: string; 
     AttributeValue: string; 
  }


  export interface ClassApiResponse{
    classID: number;
    className: number;
  }

  export interface SectionApiResponse{
    sectionID: number;
    sectionName: string;
  }

  export interface SubjectApiReponse{
    subjectID: number;
    subjectName: string;
  }

  export class GradeType {
    public static Manual='MANUAL ENTRY';
    public static Grade='GRADES';
    public static Rubric='RUBRIC';
    public static Percentage='PERCENTAGE';
    public static Calculated='CALCULATED';
    public static GradeLowerCase='Grades';
    public static CalculatedLowerCase='Calculated';
    public static GradingScales = [GradeType.Manual,GradeType.Grade,GradeType.Rubric,GradeType.Percentage,GradeType.Calculated];
 }

 export class RubricDefaults{
public static defaultSelection =[{
  "RubicCriteriaAttributeId": 0,
  "AttributeName": "",
  "AttributeValue": "",
  "IsDeleted": false,
  "Criteria": 0
}];

   public static Criteria = new Criteria(0, 'Title', 'Description', false,false, [
    new CriteriaAttributes(0, 'Excellent', '1', false,false),
    new CriteriaAttributes(0, 'Good', '2', false,false),
    new CriteriaAttributes(0, 'Average', '3', false,false),
    new CriteriaAttributes(0, 'Need Improvement', '4', false,false),
  ]);

  public static Rubic = new Rubic(0, '', [
    new Criteria(0, 'Title1', 'Description1', false,false, [
      new CriteriaAttributes(0, 'Excellent', '1', false,false),
      new CriteriaAttributes(0, 'Good', '2', false,false),
      new CriteriaAttributes(0, 'Average', '3', false,false),
      new CriteriaAttributes(0, 'Need Improvement', '4', false,false),
    ]),
    new Criteria(0, 'Title2', 'Description2', false,false, [
      new CriteriaAttributes(0, 'Excellent', '1', false,false),
      new CriteriaAttributes(0, 'Good', '2', false,false),
      new CriteriaAttributes(0, 'Average', '3', false,false),
      new CriteriaAttributes(0, 'Need Improvement', '4', false,false),
    ])
  ]);
 }
 export class ActivityLogModel {
      pageSize: number = 10;
      pageNumber: number = 1;
      user:any='null'
      modulename:any='';
      Aevent:any='';
      startDate:any='';
      endDate:any= '';
      activity:any='';
      action:any='';
      screen:any='';
      columnName:any='';
      sortBy: any='';
}
