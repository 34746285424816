<!-- <main> -->
    <div class="container container1580">
        <div class="headingcontent">
            <div class="pageTitle mt-0">
                <h2>Edit Role</h2>
                <div class="breadcrumbWidget">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-roles">Manage Roles</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Edit Role</li>
                    </ol>
                </div>
            </div>
            <div class="">
                <div class="add_button">
                  <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-roles">Back</button>
                </div>
              </div>
    
        </div>

        <div class="cardWidget">
        <div class="matCard">
            <!-- <div class="matCrdTitle centerItemVert justify-content-between">
                
                <a routerLink="/manage-role" class="btn btn-primary btnsmall">Back</a>
            </div> -->
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="matCardBody">
                    <div class="profileDetailsWidget t-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <!-- <h5>Edit Roles</h5> -->

                                <div class="row">


                                    <div class="col-lg-6 col-sm-12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Name </mat-label>
                                            <input (keypress)="allowAlphabetOnly($event)" required maxlength="50"
                                                formControlName="roleName" matInput placeholder="Name">
                                            <mat-error
                                                *ngIf="form.get('roleName')?.touched  && form.get('roleName')?.errors?.required">
                                                Role Name is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-sm-12  form-group"></div>
                                    <div class="col-lg-6 col-sm-12  form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Description </mat-label>
                                            <textarea input (keypress)="allowAlphabetOnly($event)" matInput
                                                placeholder="Description" required name="roleDescription"
                                                formControlName="roleDescription"></textarea>
                                            <mat-error
                                                *ngIf="form.get('roleDescription')?.touched  && form.get('roleDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>

                                   
                                    </div>
                                    <div class="col-lg-6 col-sm-12  form-group"></div>
                                </div>
                            </div>
    
                            <div class="proSection">
                                <h5>Permissions </h5>
                              
    
    
    
                                
    
                              <ngx-treeview class="customClassfortree" [config]="config" [items]="items" (selectedChange)="onSelectedChange($event)"
                              (filterChange)="onFilterChange($event)">
                               </ngx-treeview>
    
                            </div>
    
                            <div class="proSection text-right">
                                <button [disabled]="form.invalid" n-submit (click)="onSubmit()" type="submit" class="btn btn-primary btn-sm" matRipple>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
    </div>
    
<!-- </main> -->
<!-- <button (click)="testB()" ></button> -->

