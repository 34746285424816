<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Manage Assessment</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i
                class="fas fa-home"></i> Home</a></li>
                <li class="breadcrumb-item"><a routerLink="../manage-grade">Grade Book</a></li>
          <li class="breadcrumb-item active" aria-current="page">Manage Assessment</li>
        </ol>
      </div>
    </div>
    <div class="">
      <div class="add_button">
        <button
        *ngIf="checkActionPermission('Add Assessment')" class="addbtn btn" matRipple (click)="openDialog(addassesment)"> <i
            class="fas fa-plus-circle"></i> Add New Assesment </button>
      </div>
    </div>
  </div>

    <div class="cardWidget">
        <div class="assesmentTabs">
          <a *ngIf="checkScreenPermission('Grade Book')" routerLink="../manage-grade" routerLinkActive="active"><i class="far fa-bookmark"></i> Grade Book</a>
          <a *ngIf="checkScreenPermission('Report Card')" routerLink="../report-cards" routerLinkActive="active"><i class="far fa-list-alt"></i> Report Cards</a>
          <a *ngIf="checkScreenPermission('Assessment')" routerLink="../manage-assessment" routerLinkActive="active"><i class="far fa-file-alt"></i> Assessment</a>
          <a *ngIf="checkScreenPermission('Grade Setup')" routerLink="../grade-setup" routerLinkActive="active"><i class="fas fa-chalkboard-teacher"></i> Grade Setup</a>
        </div>
    <div class="cardBody">
      <!-- <div class="cardWidget"> -->
        <!-- <div class="cardTitle centerVTItems justify-content-between">
          <h3>Assessment</h3>
          <button class="btn btn-primary btnsmall" (click)="openDialog(addassesment)"><i class="fas fa-plus"></i>
            Add New</button>
        </div> -->

        <div class="matCard">
          <div class="matCrdTitle centerItemVert justify-content-between">
            <!-- <div class="row">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-3 s12 m3 form-group mb-0">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Select Academic Session</mat-label>
                  <mat-select placeholder="Session">
                    <mat-option value="Select Session">Select Academic Sessions</mat-option>
                    <mat-option>
                      Hello
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    session
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3 s12 m3 form-group mb-0">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Select Class</mat-label>
                  <mat-select placeholder="Class">
                    <mat-option value="Select Class">Select Class</mat-option>
                    <mat-option >
                     abdc
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    Class is required
                  </mat-error>
                </mat-form-field>
              </div>


              <div class="col-md-3 s12 m3 form-group mb-0">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Select Section</mat-label>
                  <mat-select placeholder="Section">
                    <mat-option value="Select Section">Select Sections</mat-option>
                    <mat-option>
                     hi
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    Section is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 s12 m3 form-group mb-0">
                <mat-form-field class="w-100" appearance="outline">
                  <mat-label> Subject</mat-label>
                  <mat-select placeholder="Select Subject">
                    <mat-option value="Select Subject">Select Subject</mat-option>
                    <mat-option>
                      vanda
                    </mat-option>
                  </mat-select>
                  <mat-error
                    >
                    Please Select Subject
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>


          <div class="col-md-2">
            <button class="btn btn-primary btn-block mt-1 mb-3">Go</button>
          </div>
        </div> -->
            <div class="tableActionTop">
              <div class="tableFilter">
                <!-- <div><i class="fas fa-filter cursor-pointer"></i> <span class="mobileHidden cursor-pointer"
                    (click)="toggleField()">Filter</span></div> -->
                <div class="filterForm" [class.toggled]="classToggled">
                  <form [formGroup]="searchingAssessmentForm">
                    <div class="row">
                      <div class="col-lg-4 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label> Class-Section</mat-label>
                          <!-- <mat-select placeholder="Select Class-Section" formControlName="classSectionID">
                            <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'classSec')"> -->

                            <input type="text" (input)="onKeyFilter($event, 'classSec')" matInput
                            formControlName="classSectionID" [matAutocomplete]="classauto">
  
  
                            <mat-autocomplete #classauto="matAutocomplete" 
                            [displayWith]="dispalyFns.bind(this)" >
  

                            <mat-option disabled value="Select Class-Section">Select Class-Section</mat-option>
                            <mat-option *ngFor="let classSection of classsectionDropdown" [value]="classSection">
                              {{classSection.classSectionName}}
                            </mat-option>
                            </mat-autocomplete>
                          <!-- </mat-select> -->
                          <mat-error>
                            Please Select Class
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label> Subjects</mat-label>
                          <!-- <mat-select placeholder="Select Subject Scale" formControlName="subjectID">
                            <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'subject')"> -->

                            <input type="text" (input)="onKeyFilter($event, 'subject')" matInput
                            formControlName="subjectID" [matAutocomplete]="subjectauto">
  
  
                            <mat-autocomplete #subjectauto="matAutocomplete" 
                             [displayWith]="dispalyFnsSubject.bind(this)" >

                            <mat-option disabled value="Select Subject">Select Subject</mat-option>
                            <mat-option *ngFor="let subject of subjectDropdown" [value]="subject.subjectID">
                              {{subject.subjectName}}
                            </mat-option>
                          <!-- </mat-select> -->
                          <!-- <mat-error>
                            Please Select Subject
                          </mat-error> -->
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label> Assessment Category</mat-label>


                          <input type="text" (input)="onKeyFilter($event, 'assesmentCategory')" matInput
                          formControlName="assessmentCategoryName" [matAutocomplete]="assesmentCategoryauto">


                          <mat-autocomplete #assesmentCategoryauto="matAutocomplete" 
                           [displayWith]="dispalyFnsAssesmentCategory.bind(this)" >


                          <!-- <mat-select placeholder="Select Category" formControlName="assessmentCategoryName"> -->
                            <mat-option disabled value="Select Category">Select Category</mat-option>
                            <mat-option *ngFor="let category of categoryDropdown"
                              [value]="category.assessmentCategoryName">
                              {{category.assessmentCategoryName}}
                            </mat-option>
                          <!-- </mat-select> -->
                          </mat-autocomplete>
                          <!-- <mat-error>
                            Please Select Assessment Category
                          </mat-error> -->
                        </mat-form-field>
                      </div>
                      <div class="col-lg-4 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label> Count in Overall</mat-label>
                          <mat-select placeholder="Select count in overall" formControlName="isCountTowardsOverAllScore">
                            <mat-option [value]=true>Yes</mat-option>
                            <mat-option [value]=false>No</mat-option>
                          </mat-select>
                          <mat-error>
                            Count in Overall
                          </mat-error>
                        </mat-form-field>

                      </div>
                      <div class="col-lg-4 col-sm-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Assessment Name</mat-label>
                          <input matInput type="text" formControlName="assessmentName" (keydown.space)="checkWhiteSpace($event)">
                          <mat-error>
                            Assessment name is required
                          </mat-error>
                        </mat-form-field>

                      </div>
                      <div class="col-lg-4 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Status</mat-label>
                          <mat-select placeholder="Select Status" formControlName="assessmentStatusName">
                            <mat-option value=1>Unpublish</mat-option>
                            <mat-option value=2>Publish</mat-option>
                          </mat-select>
                          <mat-error>
                            Please Select Status
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Start date (from)</mat-label>
                          <input matInput placeholder="Start date (from)" formControlName="startFromDate"  [matDatepicker]="startDateFrom">
                          <mat-datepicker-toggle matSuffix [for]="startDateFrom"></mat-datepicker-toggle>
                          <mat-datepicker #startDateFrom></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>Start date (to)</mat-label>
                          <input matInput placeholder="Start date (to)" formControlName="startToDate" [matDatepicker]="startDateTo">
                          <mat-datepicker-toggle matSuffix [for]="startDateTo"></mat-datepicker-toggle>
                          <mat-datepicker #startDateTo></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>End date (From)</mat-label>
                          <input matInput placeholder="End date (From)" formControlName="endFromDate" [matDatepicker]="endDateFrom">
                          <mat-datepicker-toggle matSuffix [for]="endDateFrom"></mat-datepicker-toggle>
                          <mat-datepicker #endDateFrom></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-lg-6 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                          <mat-label>End date (to)</mat-label>
                          <input matInput placeholder="End date (to)" formControlName="endToDate" [matDatepicker]="endDateTo">
                          <mat-datepicker-toggle matSuffix [for]="endDateTo"></mat-datepicker-toggle>
                          <mat-datepicker #endDateTo></mat-datepicker>
                        </mat-form-field>
                      </div>

                    </div>
                    <div class="btnsWidget form-group text-right">
                      <button type="submit" (click)="onSearchingSubmit()" class="btn btn-primary btn-sm" matRipple>Search</button>
                      <button type="button" class="btn btn-reset btn-sm" (click)="resetfilterform()" matRipple>Reset</button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="rightTableActions centerItemvert align-items-center">
                <div class="viewColumns tableActions">
               <i class="fas fa-filter cursor-pointer" matRipple matTooltip="Filters" (click)="toggleField()"></i> 
               <span class="mobileHidden cursor-pointer" (click)="toggleField()"></span>

                </div>
                <!-- <div class="downloadTable tableActions">

                </div>
                <div class="enrolmentTable tableActions">

                </div> -->
                <div class="selectedColTable tableActions">
                  <button class="ddButtonType" [matMenuTriggerFor]="beforeMenu" [disabled]="selectedId.length==0">
                    <span class="leftItem conterColumns">{{selectedId.length}}</span> <span class="mobileHidden"
                      matTooltip="Select atleast one Fees and Invoice">Selected</span>
                    <i class="fa fa-caret-down rightItem"></i></button>
                  <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
                    <h4 class="actionSelected">Assessment Status</h4>
                    <button (click)="multiPublish()" mat-menu-item>
                      <i class="fa-eye fas"></i>
                      Publish</button>
                    <button (click)="multiPublish()" mat-menu-item><i class="fas fa-eye-slash"></i>
                      Unpublish
                    </button>
                    <h4 class="actionSelected">Other Actions</h4>
                    <button (click)="deleteAssessments(deleteAssessment)" mat-menu-item><i
                        class="fa-trash fas"></i>Delete</button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <div class="matCardBody">
            <div class="tableActionTop">
              <div class="tableFilter">
                <table mat-table [dataSource]="dataSource" class="w-100" matSort (matSortChange)="sortData($event)">
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        (change)="changee($event,row)" [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="assessmentID">
                    <th mat-header-cell *matHeaderCellDef >Sr. No.</th>
                    <td mat-cell *matCellDef="let element"> {{element.rowNumber}} </td>
                   
                  </ng-container>
                  <ng-container matColumnDef="assessmentCategoryName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Assessment Category</th>
                    <td mat-cell *matCellDef="let element">{{element.assessmentCategoryName}}</td>
                  </ng-container>

                  <ng-container matColumnDef="assessmentName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Assessment Name</th>
                    <td mat-cell *matCellDef="let element">{{element.assessmentName}} 
                      <i class="fa fa-info-circle" 
                       [popover]="scores" 
                       triggers="mouseenter:mouseleave" 
                       (mouseenter)="mouseEnter(element.assessmentID)"
                       [adaptivePosition]="true"
                       placement="bottom"></i> 
                    </td>
                  </ng-container>
                  
              <ng-template  #scores >

                <div class="mb-3">
                    <label><b>Points</b></label>
                    <p>{{hoverData[0].points}}</p>
                </div>
                <div class="mb-3 ">
                    <label><b>Passing Points</b></label>
                    <p>{{hoverData[0].passingPoints}}</p>
                </div>
                <div class="mb-3 ">
                  <label><b>Start Date</b></label>
                  <p>{{hoverData[0].startDate | date: dateFormat}}</p>
              </div>
              <div class="mb-3 ">
                <label><b>End Date</b></label>
                <p>{{hoverData[0].endDate | date: dateFormat}}</p>
            </div>
            <div class="mb-3 ">
              <label><b>Description</b></label>
              <p>{{hoverData[0].description}}</p>
          </div>
          <div class="mb-3 ">
            <label><b>Online Submission</b></label>
            <p *ngIf="hoverData[0].onlineSubmission=='1'">Disable</p>
            <p *ngIf="hoverData[0].onlineSubmission=='2'">Enable</p>
        </div>
   
              </ng-template>

                  <ng-container matColumnDef="isAppliedTo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Applied To</th>
                    <td mat-cell *matCellDef="let element"> {{element.isAppliedTo}} </td>
                  </ng-container>
                  <ng-container matColumnDef="gradingScaleName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Scale Type</th>
                    <td mat-cell *matCellDef="let element"> {{element.gradingScaleName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="isCountTowardsOverAllScore">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Count in Overall</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.isCountTowardsOverAllScore==true">Yes</span>
                      <span *ngIf="element.isCountTowardsOverAllScore==false">No</span>
                      <!-- {{element.isCountTowardsOverAllScore}}  -->
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="assessmentStatusName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element"> {{element.assessmentStatusName}} </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <!-- mat-sort-header =>To Add A sorting in mat table -->
                    <th mat-header-cell *matHeaderCellDef > Action </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="actionsCol">
                        <!-- <mat-icon routerLink="/Org-details">info</mat-icon> -->
                        <!-- <i class="fas fa-arrows-alt"></i> -->
                        <mat-icon *ngIf="checkActionPermission('Update Assessment')" matTooltip="Edit Assessment"
                          (click)="editAssessment(addassesment, element.assessmentID)">edit</mat-icon>

                        <mat-icon matTooltip="Unpublish" *ngIf="checkActionPermission('Publish/Unpublish Assessment') && element.assessmentStatusName=='Published'"
                          (click)="PublishAndUnpublisht(element.assessmentID)">visibility_off</mat-icon>
                        <mat-icon
                          matTooltip="Publish" *ngIf="checkActionPermission('Publish/Unpublish Assessment') && element.assessmentStatusName=='Unpublished'"
                          (click)="PublishAndUnpublisht(element.assessmentID)">remove_red_eye</mat-icon> 
                     
                         
                          <mat-icon *ngIf="checkActionPermission('View Attachment')">link</mat-icon>
                        <mat-icon *ngIf="checkActionPermission('Copy Assessment')" matTooltip="Copy Assessment"
                          (click)="copyAssessment(addassesment, element.assessmentID)">file_copy</mat-icon>
                        <mat-icon *ngIf="checkActionPermission('Send Notification Email')" matTooltip="Send Notification" (click)="sendNotification(element.assessmentID)"> email</mat-icon>
                        <mat-icon matTooltip="Delete" *ngIf="checkActionPermission('Delete Assessment')"
                          (click)="deleteAssessmentData(deleteAssessment, element.assessmentID)">delete</mat-icon>
                      </div>
                    </td>
                  </ng-container>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9999">
                      No Assessment Found
                    </td>
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                  </tr>
                </table>
                <!-- pagination -->
                <mat-paginator [length]="metaData['totalRecords']" [pageIndex]="metaData['currentPage'] - 1"
                  (page)="handlePage($event)" [pageSize]="metaData['pageSize']" showFirstLastButtons>
                </mat-paginator>

                <!-- <div *ngIf="pageEvent">
                  <h5>Page Change Event Properties</h5>
                  <div>List length: {{pageEvent.length}}</div>
                  <div>Page size: {{pageEvent.pageSize}}</div>
                  <div>Page index: {{pageEvent.pageIndex}}</div>
                </div> -->

              </div>
              <div class="rightTableActions centerItemVert">
              </div>
            </div>
            <div class="mat-elevation-z8">
            </div>
            <!-- <app-checkboxdata-table [inputColumns]="displayedColumns" [inputSource]="usersData" [inputMeta]="metaData"
          [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)" (onselect)="onselect($event)"
          (onTableActionClick)="onTableActionClick($event)">
        </app-checkboxdata-table> -->
          </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
  <ng-template #addassesment>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close (click)="reset()">clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Assessment</h2>
        <h2 *ngIf="copyAssessmentData" mat-dialog-title>Copy Assessment</h2>
        <ng-template #elseTemplate>
          <h2 mat-dialog-title> <span *ngIf="!copyAssessmentData"> Add Assessment </span></h2>
        </ng-template>
      </div>
      <form [formGroup]="addAssessmentForm">
        <div class="popover-content">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select placeholder="Select Category" formControlName="assessmentCategoryID">
                  <mat-option value="" hidden>Select Category</mat-option>
                  <mat-option *ngFor="let category of categoryDropdown" [value]="category.assessmentCategoryID">
                    {{category.assessmentCategoryName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Please Select Category
                </mat-error>
              </mat-form-field>
            </div>             
            <div class="col-lg-6 col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Assessment Name</mat-label>
                <input matInput type="text" pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="assessmentName" (keydown.space)="checkWhiteSpace($event)" maxlength="50">
                <mat-error>
                  Assessment name is required
                </mat-error>
                <mat-error *ngIf="addAssessmentForm.get('assessmentName')?.hasError('pattern')">
                  Please enter valid assessment name
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Points</mat-label>
                <input matInput type="number" min="0"oninput="this.value = Math.abs(this.value)"  formControlName="points" (keydown.space)="checkWhiteSpace($event)" [(ngModel)]="greaterThanValue" (ngModelChange)="onChange()" maxlength="10">
                <mat-error>
                  Points is required
                </mat-error>
                </mat-form-field>
               
            </div>
            <div class="col-lg-6 col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Passing Points</mat-label>
                <input matInput type="number" min="0"oninput="this.value = Math.abs(this.value)"  formControlName="passingPoints" (keydown.space)="checkWhiteSpace($event)" [(ngModel)]="lessThanValue" (ngModelChange)="onChange()"  maxlength="10">
                <mat-error>
                  Passing points is required
                </mat-error>     
                 
              </mat-form-field>
              <span *ngIf="err" class="err1">
                {{err}}
              </span>  
            </div>
            
            <div class="col-lg-6 col-sm-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select placeholder="Select Status" formControlName="assessmentStatusID">
                  <mat-option [value]=1>Unpublish</mat-option>
                  <mat-option [value]=2>Publish</mat-option>
                </mat-select>
                <mat-error>
                  Please Select Status
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-sm-12"></div>
            <div class="col-lg-12 col-sm-12">
              <mat-label class="d-block mb-2">Counts towards Overall Score</mat-label>
              <mat-radio-group aria-label="Select an option" required class="mt-2"
                formControlName="isCountTowardsOverAllScore">
                <mat-radio-button [value]=true [checked]='true'>Yes</mat-radio-button>
                <mat-radio-button [value]=false>No</mat-radio-button>
              </mat-radio-group>
            </div>
            <!-- <div class="col-lg-6 col-sm-12"></div> -->
            <div class="col-lg-6 col-sm-6">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Grading Scale</mat-label>
                <mat-select placeholder="Select Grading Scale" formControlName="gradingScaleTypeID" (selectionChange)="selectGradingScale($event.value)" [disabled]="updateTextInPopUp">
                  <mat-option *ngFor="let grading of gradeScaleTypeList" [value]="grading.gradeScaleTypeId">
                    {{grading.typeName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Please Select Grading Scale
                </mat-error>
              </mat-form-field>
            </div>



            <div class="col-lg-6 col-sm-6" *ngIf="scaleValue==1">
              <mat-form-field class="w-100" appearance="outline">
                <!-- <mat-label>Grading Scale</mat-label> -->
                <mat-select placeholder="Select Grading Scale" formControlName="recordID" [disabled]="updateTextInPopUp">
                  <mat-option value="" hidden>Select Grading</mat-option>
                  <mat-option *ngFor="let grading of gradeScaleDropdown" [value]="grading.gradingScaleID">
                    {{grading.gradingScaleName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Please Select Grading Scale
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-sm-6" *ngIf="scaleValue==2">
              <mat-form-field class="w-100" appearance="outline">
                <!-- <mat-label>Grading Scale</mat-label> -->
                <mat-select placeholder="Select Rubric" formControlName="recordID">
                  <mat-option value="" hidden>Select Rubric</mat-option>
                  <mat-option *ngFor="let rubric of rubicScaleList" [value]="rubric.rubicID">
                    {{rubric.rubicName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Please Select Rubric
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 col-sm-12">
              <mat-label class="d-block mb-2">Applied To</mat-label>
              <mat-radio-group aria-label="Select an option" (change)="appliedSetting($event.value)"
                formControlName="isAppliedTo" class="mt-2">
                <mat-radio-button [value]=1>All</mat-radio-button>
                <mat-radio-button [value]=2>All Subjects of Particular Class-Section</mat-radio-button>
                <mat-radio-button [value]=3>Particular Subjects</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-lg-12 col-sm-12" *ngIf="AllSubjectsToApplied==2">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Class-Section</mat-label>
                <!-- <mat-select placeholder="Select Class-Section" formControlName="classSectionID"> -->

                  <input type="text" (input)="onKeyFilter($event, 'classSect')" matInput
                  formControlName="classSectionID" [matAutocomplete]="classSectionauto">


                  <mat-autocomplete #classSectionauto="matAutocomplete" 
                   [displayWith]="dispalyFnsSections.bind(this)" >


                  <mat-option value="Select Class-Section" [disabled]="true">Select Class-Section</mat-option>
                  <mat-option *ngFor="let classSection of classsectionDropdown" [value]="classSection">
                    {{classSection.classSectionName}}
                  </mat-option>
                <!-- </mat-select> -->
                <!-- <mat-error>
                  Please Select Class-Section
                </mat-error> -->
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-lg-12 col-sm-12" *ngIf="AllSubjectsToApplied==3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Subject</mat-label>
                <!-- <mat-select placeholder="Select Subject Scale" formControlName="subjectID"> -->


                  <input type="text" (input)="onKeyFilter($event, 'subject')" matInput
                  formControlName="subjectID" [matAutocomplete]="classSectionauto">


                  <mat-autocomplete #classSectionauto="matAutocomplete" 
                   [displayWith]="dispalyFnsSubject.bind(this)" >

                  <mat-option value="Select Subject" [disabled]="true">Select Subject</mat-option>
                  <mat-option *ngFor="let subject of subjectDropdown" [value]="subject.subjectID">
                    {{subject.subjectName}}
                  </mat-option>
                <!-- </mat-select> -->
                <!-- <mat-error>
                  Please Select Subject
                </mat-error> -->
                </mat-autocomplete>
              </mat-form-field>
            </div>  
            <!-- <div class="col-lg-12 col-sm-12">
              <mat-label class="mb-2">Display Assessment</mat-label><br><br>
              <mat-radio-group aria-label="Select an option" (change)= "displaySetting($event)" formControlName="assessmentCategoryName" required
                class="mt-2">
                <mat-radio-button value="Begning of list">Begning of List</mat-radio-button>
                <mat-radio-button value="End of List">End of List</mat-radio-button><br>
              </mat-radio-group>
            </div> -->
            <!-- <div class="col-lg-12 col-sm-12">
            <br>
            <br>
            <mat-checkbox class="example-margin">Send notification Email</mat-checkbox>
          </div> -->
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="startDate" [(ngModel)]="startDateValue">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                  *ngIf="addAssessmentForm.get('startDate')?.touched && addAssessmentForm.get('startDate')?.errors?.required">
                  Start Date is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6 col-sm-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="picker1" (click)="picker1.open()" formControlName="endDate" (dateChange)="checkDates()" [(ngModel)]="endDateValue">  
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error
                  *ngIf="addAssessmentForm.get('endDate')?.touched && addAssessmentForm.get('endDate')?.errors?.required">
                  End Date is required
                </mat-error>
                 </mat-form-field>
                 <p *ngIf="dateErro" class="err1">{{dateErro}}</p>
             
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Online Submission</mat-label>
                <mat-select placeholder="Online Submission" formControlName="onlineSubmissionID">
                  <mat-option [value]=1>Disable</mat-option>
                  <mat-option [value]=2>Enable</mat-option>
                </mat-select>
                <mat-error>
                  Online Submission
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 col-sm-12">
              
              <!-- <mat-form-field class="w-100" appearance="outline">
              
                <textarea matInput formControlName="description" placeholder="Description"></textarea>
               
              </mat-form-field> -->

              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description" placeholder="Description"></textarea>
                <mat-error
                *ngIf="addAssessmentForm.get('description')?.touched  && addAssessmentForm.get('description')?.errors?.required">
                Description is required
              </mat-error>

            </mat-form-field>
            </div>
            <div class="col-lg-6 col-sm-12" *ngIf="!updateTextInPopUp">
              <input type="file" (change)="uploadFile($event)" name="filename">
          </div>
            <div class="col-lg-6 col-sm-12">
              <mat-checkbox formControlName="isSendNotification" class="example-margin">Send Notification Email</mat-checkbox>
            </div>
          </div>
          <div class="btnsWidget form-group17 text-right">
            <button (click)="onSubmitAssessment()" [disabled]="addAssessmentForm.invalid || err!=false || dateErro!=false"  type="button"
              class="btn btn-primary btn-sm" mat-dialog-close matRipple>Save</button>
            <button type="button" matRipple (reset)="reset()" class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
          </div>
          <!-- <div class="buttons float-right">
            <button type="button" (click)="onSubmitAssessment()" class="btn btn-sm btn-primary mr-2">Submit</button>
            <button type="button" (reset)="reset()" class="btn btn-sm btn-outline-primary">Cancel</button>
          </div> -->
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #deleteAssessment>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close (click)="reset()">clear</i>
      <div class="dialogTitle">
        <h2 mat-dialog-title>Delete Assessment</h2>
      </div>
      <form [formGroup]="deleteAssessmentForm">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput type="password" placeholder="Enter password" formControlName="password"
            (keydown.space)="checkWhiteSpace($event)" required />
          <mat-error
            *ngIf="deleteAssessmentForm.get('password')?.touched && deleteAssessmentForm.get('password')?.errors?.required">
            Password is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Please type DELETE to confirm</mat-label>
          <input matInput placeholder="Please type DELETE to confirm" formControlName="keyword"
            (keydown.space)="checkWhiteSpace($event)" required />
          <mat-error
            *ngIf="deleteAssessmentForm.get('keyword')?.touched && deleteAssessmentForm.get('keyword')?.errors?.required">
            Delete keyword is required
          </mat-error>
        </mat-form-field>

        <div class="btnsWidget form-group17 center">
          <div class="text-right">
       
              <button type="submit" matRipple (click)="submitDeleteAssessment()" class="btn btn-primary btn-sm mr-2"
                mat-dialog-close [disabled]="deleteAssessmentForm.invalid">Save</button>
     

              <button type="reset" matRipple (click)="reset()" class="btn btn-reset btn-sm"
                mat-dialog-close>Cancel</button>

          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>
