import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { MasterService } from 'src/app/services/master.service';
import { StudentService } from 'src/app/services/student.service';
import { FormioOptions } from 'angular-formio';
import { DatePipe, Location } from '@angular/common';
import { SectionService } from '../../../courses-and-subjects/services/sectionServices/section.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.css']
})
export class EditStudentComponent implements OnInit {

  form: FormGroup;
  studentID: number;
  data: string;
  previewUrl: any = '../../../assets/img/display.jpg';
  public jsonFormData: Object = {
    components: []
  };
  initialFormValues: any = null;
  formioOptions: FormioOptions = {
    disableAlerts: true
  }
  @ViewChild('phone') phone: any;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private studentService: StudentService,
    private commonService: CommonService,
    private masterService: MasterService,
    private router: Router,
    private toaster: ToastrService,
    private location: Location,
    private sectionService: SectionService,
    private datePipe: DatePipe,
    private dialog: MatDialog,

  ) { }
  countries: any;
  bloodGroups: any;
  genders: any;
  extraformField: boolean;



  ngOnInit(): void {
    this.extraformField = false;
    //this.adminUserID = this.activatedRoute.snapshot.params.Id 
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? null : params.id;
    });


    this.form = this.formBuilder.group({
      studentID: this.studentID,
      //  studentID:[0],
      admissionNumber: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      genderID: ['', Validators.required],
      bloodGroupID: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      address: [''],
      zipCode: [''],
      countryID: [0],
      stateID: [0],
      city: [''],
      profilePhoto: [''],
      studentSkillID: [''],
      profileSummary: [''],
      facebookLink: [''],
      linkedInlink: [''],
      academicSessionID: ['', Validators.required],
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      departmentID: [1],
      parentID: ['', Validators.required],
      parentsNumber: [''],
      fatherName: ['', Validators.required],
      motherName: ['', Validators.required],
      guardianName: [''],
      parentContact: ['', Validators.required],
      parentEmail: ['', [Validators.required, Validators.email]],
      guardianContact: [''],
      guardianEmail: [''],
      hasExcess: [true],
      password: ['123456'],
      userName: ['alia10'],
      roleID: [1],
      studentExtraInfoInJson: ['']
    });
    this.masterService.GetCustomFormTemplate(1).subscribe((res: any) => {
      console.log("Form", res.responseData)
      if (res.responseData != null) {
        if (res.responseData.formInJson != "") {
          this.extraformField = true;
          this.jsonFormData = JSON.parse(res.responseData.formInJson || '');
        }
      }
    })
    this.GetStudentById();

    this.GetCountiresList();
    this.GetBloodGroupList();
    this.GetGenderList();
    this.GetClassList();
    this.GetSectionList();
    this.GetAcademicSessionList();
    //this.GetStudentSkillsList();



  }
  GetStudentById() {
    this.studentService.GetStudentById(this.studentID).subscribe((res: any) => {
      console.log("photourl", res.responseData.profilePhoto);
      
      if (res.responseData.profilePhoto != '') {
        this.previewUrl = res.responseData.profilePhoto;
      }
      console.log("DATAGET", res.responseData);
      this.filterSectionListByClassId(res.responseData['classID'])
      this.form.patchValue({
        studentID: res.responseData['studentID'],

        admissionNumber: res.responseData['admissionNumber'],
        firstName: res.responseData['firstName'],
        lastName: res.responseData['lastName'],

        dateOfBirth: res.responseData['dateOfBirth'],

        genderID: res.responseData['genderID'],
        email: res.responseData['email'],


        motherName: res.responseData['motherName'],
        fatherName: res.responseData['fatherName'],


        studentSkillID: res.responseData['studentSkillID'],
        profileSummary: res.responseData['profileSummary'],
        facebookLink: res.responseData['facebookLink'],
        linkedInlink: res.responseData['linkedInlink'],
        bloodGroupID: res.responseData['bloodGroupID'],
        address: res.responseData['address'],
        city: res.responseData['city'],
        contactNumber: res.responseData['contactNumber'],
        countryID: res.responseData['countryID'],
        stateID: res.responseData['stateID'],
        zipCode: res.responseData['zipCode'],
        academicSessionID: res.responseData['academicSessionID'],
        classID: res.responseData['classID'],
        sectionID: res.responseData['sectionID'],
        parentContact: res.responseData['parentContact'],
        parentEmail: res.responseData['parentEmail'],
        parentID: res.responseData['parentID'],
        parentsNumber: res.responseData['parentsNumber'],
        studentskillID: res.responseData['studentSkillID'],
        profilePhoto: res.responseData['profilePhoto']
      });
      if (res?.responseData['profileSRC']) {
        this.croppedImage = res.responseData['profileSRC']
      }
      if (res.responseData['studentExtraInfoInJson'] != "") {
        this.initialFormValues = JSON.parse(res.responseData['studentExtraInfoInJson'])
      }
      this.GetStateList(res.responseData['countryID']);
      this.data = res.responseData;
      console.log("sae", this.initialFormValues)
    });
  }
  GetCountiresList() {
    this.masterService.GetCountryDropdown().subscribe((res: any) => {
      this.countries = res.responseData;
    })
  }


  GetBloodGroupList() {
    this.masterService.GetBloodGroupDropdown().subscribe((res: any) => {
      this.bloodGroups = res.responseData;
    })
  }

  // skills:any
  //   GetStudentSkillsList() {
  //     this.masterService.GetStudentSkillsDropdown().subscribe((res: any) => {
  //       this.skills = res.responseData;

  //     })
  //   }
  formData: any;
  onChange(event: any) {
    if (event.data != undefined || event.data != null) {
      this.formData = {}
      this.formData = {
        data: event.data
      }
      this.form.value.studentExtraInfoInJson = JSON.stringify(this.formData)
      console.log("EVENT", this.form.value.studentExtraInfoInJson)
    }
  }
  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.updateStaff();
  }
  onSubmitTemplate(event: any) {
    console.log("DATAT EVENT", event)
  }

  updateStaff() {

    console.log("PHONENO", this.phone)
    this.form.controls.dateOfBirth.setValue(moment(this.form.value.dateOfBirth).format('YYYY-MM-DD'));
    this.form.value.contactNumber = this.phone.numberInstance.nationalNumber
    this.form.value.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
    this.form.value.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);

    console.log("SAVE", this.form.value)
    this.studentService.UpdateStudent(this.form.value).subscribe((res: any) => {
      console.log("update", res);
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        //this.router.navigate(['/manage-students']);
        if (this.imageFile) {
          this.studentService.UpdateStudentProfilePhoto(this.studentID, this.imageFile).subscribe((res: any) => {
            this.location.back()
          }
            , err => this.location.back());
        } else
          this.location.back()
      } else {
        this.toaster.error(res.message, 'Error');
      }

    })
  }
  GetGenderList() {
    this.masterService.GetGenderDropdown().subscribe((res: any) => {
      this.genders = res.responseData;
    })
  }

  states: any;
  GetStateList(countryId: any) {
    this.masterService.GetStateDropdown(countryId).subscribe((res: any) => {
      this.states = res.responseData;
      console.log(res);
    })
  }

  classes: any
  GetClassList() {
    this.masterService.GetClassDropdown().subscribe((res: any) => {
      this.classes = res.responseData;
    })
  }
  sections: any
  GetSectionList() {
    // this.masterService.GetSectionDropdown().subscribe((res: any) => {
    //   this.sections = res.responseData;
    // })
  }
  filterSectionListByClassId(id: number) {
    console.log("filterSectionListByClassId", id)
    this.sections = [];
    this.sectionService.GetSectionByClassId(id).subscribe((res: any) => {
      this.sections = res.responseData;
    })
  }
  academicSessions: any
  GetAcademicSessionList() {
    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      this.academicSessions = res.responseData;
      console.log("sessions", this.academicSessions);
    })
  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  allowAlphaNumber(event: any) {
    this.commonService.allowAlphaNumber(event)
  }
  back() {
    this.location.back()
  }
  maxDate = new Date();
  minDate = new Date(1900, 0, 1);

  actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data);

  }
  imageChangedEvent: any = '';
  croppedImage: any = '../../../assets/img/display.jpg';
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
    this.imageFile = this.imageChangedEvent.target.files[0];
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  finalUpload() {
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();
    //for (let file of this.filesdata)
    formData.append('files', file);
    //this.AddProfile(formData);
  }
  profileImage: string = '';
  AddProfile(file: any) {
    this.studentService.postData(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {
        this.profileImage = res.body.responseData.imageName;
        console.log(this.profileImage);
      }
    })
  }
}

