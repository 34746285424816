import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { ObeService } from 'src/app/services/obe.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SessionStatusDropdownService } from 'src/app/masterServices/sessionStatusDropdownServices/session-status-dropdown.service';
import { User } from 'src/app/app-layout/header/User';
export interface PeriodicElement {
  sno: number;
  organizationID: number;
  organizationName: string;
  email: string;
  contactNumber: number;
  userName: string;
  password: string;
  subDomainName: string;
  connectionStringName: string;
  organizationDatabaseName: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-manage-branch-grid',
  templateUrl: './manage-branch-grid.component.html',
  styleUrls: ['./manage-branch-grid.component.css']
})
export class ManageBranchGridComponent implements OnInit {
  users: any;
  user: User;
  orgID:any;
  userId: any;
  displayedColumns: string[] = ['sno','organizationID', 'organizationName', 'email', 'contactNumber','userName', 'password','subDomainName','connectionStringName','organizationDatabaseName'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialog: MatDialog,
    private dialogService: DialogService,
    private router: Router,
    private toaster: ToastrService,
    private sessionStatusDropdownService: SessionStatusDropdownService,
  ) { 
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.orgID = currentUser.orgID;
    this.userId = currentUser.userId;
    if (currentUser != null) {
      this.user = currentUser;
    }
  }

  ngOnInit(): void {
    this.getbranches();
  }
  getbranches() {
    this.sessionStatusDropdownService.GetOrganizationDropdown(this.user.email).subscribe((res: any) => {
      this.dataSource = res.responseData;
    });
  }
}
