<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Academic Sessions</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i
                class="fas fa-home"></i> Home</a>
          <!-- <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
              Learning</a></li> -->
          <li class="breadcrumb-item active" aria-current="page">Academic Sessions</li>
        </ol>
      </div>
    </div>

  </div>
  <!--/Page Title-->
  <div class="cardWidget">
    <!-- <div class="cardTitle">
      <h3>Academic Sessions</h3>
    </div> -->
    <div class="cardBody">
      <div class="tableActionTop mb-3">
        <div class="tableFilter">
          <!-- <a (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden">Filter</span></a> -->
          <div class="filterForm" [class.toggled]="classToggled">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col s12 m6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Session Name</mat-label>
                    <input matInput type="text" formControlName="academicSessionName">
                  </mat-form-field>
                </div>
                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Status</mat-label>
                    <mat-select placeholder="Select Status" formControlName="academicSessionStatusName">
                      <mat-option value="Select Status">Select Status</mat-option>
                      <mat-option *ngFor="let statusSelect of sessionStatus"
                        [value]="statusSelect.academicSessionStatusName">
                        {{statusSelect.academicSessionStatusName}}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="form.get('academicSessionStatusID')?.touched && form.get('academicSessionStatusID')?.errors?.required">
                      Please Select a status </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="btnsWidget form-group text-right">
                <button type="submit" class="btn btn-primary btn-sm" matRipple>Search</button>
                <button type="button" (click)="reset()" class="btn btn-reset btn-sm" matRipple>Reset</button>
              </div>
            </form>
          </div>
        </div>
        <div class="rightTableActions centerItemvert">
          <div class="iconsset d-flex">
            <div class="viewColumns tableActions" matTooltip="Filter">
              <i title="Filter Student" class="fas fa-filter cursor-pointer" (click)="toggleField()" ></i>
              <span class="mobileHidden cursor-pointer" (click)="toggleField()"></span>
            </div>
            </div>

          <!-- <a (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden">Filter</span></a> -->

          <!-- <div class="viewColumns tableActions">
                       <a (click)="actionDialogPopup(hideShowColPopup)"><i class="fas fa-columns"></i></a>                           
                    </div> -->
          <!-- <div class="downloadTable tableActions">
                      <a href="#"><i class="fas fa-download"></i></a>
                   </div> -->
          <!-- <div class="enrolmentTable tableActions">
                    <a mat-button [matMenuTriggerFor]="enrollment" class="ddButtonType">Enrollment <i class="fa fa-caret-down rightItem"></i></a>
                    <mat-menu #enrollment="matMenu" xPosition="before" class="actionSelectedDD">
                      <button mat-menu-item>Upcoming</button>
                      <button mat-menu-item>In Progress</button>
                      <button mat-menu-item>Completed</button>
                      <button mat-menu-item>In Complete</button>
                    </mat-menu>
                   </div> -->
          <!-- <div class="selectedColTable tableActions">
                    <a mat-button [matMenuTriggerFor]="beforeMenu" class="ddButtonType"><span class="leftItem conterColumns">0</span> <span class="mobileHidden">Selected</span> <i class="fa fa-caret-down rightItem"></i></a>
                    <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
                      <h4 class="actionSelected">Subjects</h4>
                      <button mat-menu-item>Subjects Allocation</button>
                      <h4 class="actionSelected">Communications</h4>
                      <button mat-menu-item>Send Email</button>
                      <button mat-menu-item>Send SMS</button>
                      <button mat-menu-item>Invite to Login (Email)</button>
                      <button mat-menu-item>Invite to Login (SMS)</button>
                      <h4 class="actionSelected">Academics</h4>
                      <button mat-menu-item>Print ID Cards</button>
                      <button mat-menu-item>Print Report Cards</button>
                      <button mat-menu-item>Enable Report Cards</button>
                      <button mat-menu-item>Disable Report Cards</button>
                      <h4 class="actionSelected">Other Actions</h4>
                      <button mat-menu-item>Block</button>
                      <button mat-menu-item>Unblock</button>
                      <button mat-menu-item>Move to Alumni</button>
                      <button mat-menu-item>Delete</button>
                    </mat-menu>
                   </div> -->
        </div>

      </div>
      <div class="mat-elevation-z8">
        <!-- <table mat-table [dataSource]="dataSource" matSort> -->
        <!-- Checkbox Column -->
        <!-- <ng-container matColumnDef="select"> -->
        <!-- <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th> -->
        <!-- <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td> -->
        <!-- </ng-container> -->
        <!-- ID Column -->
        <!-- <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container> -->

        <!-- Name Column -->
        <!-- <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container> -->

        <!-- Time Period Column -->
        <!-- <ng-container matColumnDef="timeperiod">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Time Period </th>
                      <td mat-cell *matCellDef="let element"> {{element.timeperiod}} </td>
                    </ng-container> -->

        <!-- Plan Duration Column -->
        <!-- <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                      <td mat-cell *matCellDef="let element"> <span class="active-status Bold">Upcoming</span> </td>
                    </ng-container> -->

        <!-- Pricing Column -->
        <!-- <ng-container matColumnDef="lockunlock">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Lock/Unlock </th>
                        <td mat-cell *matCellDef="let element"> <mat-slide-toggle
                            class="custom-slideToggle">
                        </mat-slide-toggle></td>
                    </ng-container>
                     -->

        <!-- Duration Column -->
        <!-- <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="actionsCol">
                                <i class="fas fa-arrows-alt"></i>
                                <i class="far fa-copy" (click)="actionDialogPopup(copyPopup)"></i>
                                <mat-icon (click)="actionDialogPopup(copyPopup)">file_copy</mat-icon>
                                <mat-icon>edit</mat-icon>
                                <mat-icon>delete</mat-icon>
                            </div>
                        </td>
                      </ng-container> -->

        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                         >
                      </tr> -->
        <!-- </table> -->
      </div>
      <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
    </div>
    <!-- <app-data-table [inputColumns]="displayedColumns" [inputSource]="academicSessionListData" [inputMeta]="metaData"
      [inputButtons]='actionButtons' (onTableActionClick)="onTableActionClick($event)"
      (onChange)="onPageOrSortChange($event)">
    </app-data-table> --> 

    <table mat-table [dataSource]="academicSessionListData" matSort>

      <!-- Plan ID Column -->
      <ng-container matColumnDef="academicSessionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Session Name
        </th>
        <td mat-cell *matCellDef="let element"> {{element.academicSessionName}} </td>
      </ng-container>

      <!-- Plan Name Column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Start Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element.startDate | date:dateFormat}} </td>
      </ng-container>

      <!-- Plan Type Column -->
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          End Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element.endDate | date:dateFormat}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="academicSessionStatusName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          STATUS
        </th>
        <td mat-cell *matCellDef="let element"> {{element.academicSessionStatusName}} </td>
      </ng-container>

      <!-- price Column -->
      <ng-container matColumnDef="lockunlock">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          UnLock/Lock
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [checked]="element.isLocked"  (click)='onActionClick("toggle", element)'>
          </mat-slide-toggle>
        </td>
      </ng-container>

      <!-- Status Column -->
      <!-- <ng-container matColumnDef="numberOfUserAllowed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Users">
          USERS
        </th>
        <td mat-cell *matCellDef="let element"> {{element.numberOfUserAllowed}} </td>
      </ng-container> -->

      <!-- Status Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          ACTIONS
        </th>
        <td mat-cell *matCellDef="let element">
          <!-- <button class="tbactionbtn" title="'Drag and Drop to Order">
            <i class="fas fa-arrows-alt" aria-hidden="true"></i>
          </button> -->

          <button class="tbactionbtn" (click)='onActionClick("edit", element)' title="Edit">
            <i class="fas fa-edit" aria-hidden="true"></i>
          </button>
          <button class="tbactionbtn" (click)='onActionClick("copy", element)'
            title="Copy data from another Academic session">
            <i class="fas fa-copy" aria-hidden="true"></i>
          </button>
          <button *ngIf="element.academicSessionID != 1" class="tbactionbtn" (click)='onActionClick("delete", element)' title="Delete">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayCol"></tr>
      <tr mat-row *matRowDef="let row; columns: displayCol;"></tr>
    </table>

    <mat-paginator [length]="metaData['totalRecords']" [pageIndex]="metaData['currentPage'] - 1"
      [pageSize]="metaData['pageSize']" showFirstLastButtons></mat-paginator>
  </div>
</div>
<!-- (click)="selection.toggle(row)" -->
<ng-template #copyPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Copy Data</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="sessionCopyForm" (ngSubmit)="sessionCopyFormSubmit()">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Copy Data From</mat-label>
          <!-- <mat-select aria-placeholder="Select Academic Session">
            <mat-option value="one">Select Academic Session</mat-option>  
          </mat-select> -->
          <mat-select placeholder="Copy Data From" formControlName="copyDataFrom">
            <mat-option value="">Select Session</mat-option>
            <mat-option *ngFor="let academicSession of copyFromAcademicSession"
              [value]="academicSession.academicSessionID">
              {{academicSession.academicSessionName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline" >
          <mat-label>Copy to</mat-label>
          <!-- <mat-select aria-placeholder="Select Academic Session">
            <mat-option value="one">Select Academic Session</mat-option>
          </mat-select> -->
          <mat-select placeholder="Copy Data To" formControlName="copyDataTo" [disabled]="true">
            <mat-option value="Select Status">Select Session</mat-option>
            <mat-option *ngFor="let academicSession of copyToAcademicSession"
              [value]="academicSession.academicSessionID" [disabled]="true">
              {{academicSession.academicSessionName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <section class="radioDefaultColor form-group35 mb-0">
          <label class="label-block Bold mb-0">Existing data</label>
          <div class="flex-grow-1 CheckboxGroup">
            <div class="col2Check">
              <mat-checkbox class="example-margin" value="Keep Existing Data" #keepExistingData formControlName="keepExistingData" [checked]="keepExistingDataCheck" 
              (change)="flushKeepDataCheck(true, false)"> Keep Existing Data 
              </mat-checkbox>

              <mat-checkbox class="example-margin" value="Flush Existing Data" #flushExistingData formControlName="flushExistingData" [checked]="flushExistingDataDataCheck" 
              (change)="flushKeepDataCheck(false, true)"> Flush Existing Data
              </mat-checkbox>
            </div>

          </div>
        </section>
        <section class="radioDefaultColor form-group35 mb-0">
          <label class="label-block mb-0 Bold">Data to be Copied</label>
          <div class="flex-grow-1 CheckboxGroup">
            <h4>Academics</h4>
            <div class="col2Check">

              <mat-checkbox class="example-margin" value="Teacher-subject Allocation" #teacherAndSubjectAllocation formControlName="teacherAndSubjectAllocation"  
              (change)="tableOperationsSelection('teacherAndSubjectAllocation')" [checked]="teacherAndSubjectAllocationCheck">Teacher And Subject Allocation
              </mat-checkbox>

              <mat-checkbox class="example-margin" value="Class Schedules" #classSchedules formControlName="classSchedules"   (change)="tableOperationsSelection('classSchedules')"
              [checked]="classSchedulesCheck">Class Schedules 
                <i class="fa fa-info-circle" [popover]="ClassSchedules" triggers="mouseenter:mouseleave" [adaptivePosition]="true"  placement="bottom"></i>
                </mat-checkbox>

              <h4>Assessment &amp; Gradings</h4>
              <mat-checkbox class="example-margin" value="Assessment Category" #assessmentCategory  formControlName="assessmentCategory"  (change)="tableOperationsSelection('assessmentCategory')"
              [checked]="assessmentCategoryCheck">Assessment Category</mat-checkbox>

              <mat-checkbox class="example-margin" value="Grading Periods" #gradingPeriods formControlName="gradingPeriods"  (change)="tableOperationsSelection('gradingPeriods')"
              [checked]="gradingPeriodsCheck">Grading Periods</mat-checkbox>

              <mat-checkbox class="example-margin" value="Grading Scales" #gradingScales formControlName="gradingScales"  (change)="tableOperationsSelection('gradingScales')"
              [checked]="gradingScalesCheck">Grading Scales</mat-checkbox>

              <mat-checkbox class="example-margin" value="Assessment" #assessment formControlName="assessment"  (change)="tableOperationsSelection('assessment')"
              [checked]="assessmentCheck">Assessment <i class="fa fa-info-circle" [popover]="Assessment" triggers="mouseenter:mouseleave" 
                [adaptivePosition]="true" placement="bottom"></i>
              </mat-checkbox>

              <mat-checkbox class="example-margin" value="Report Card Formats" #reportCardFormats formControlName="reportCardFormats"  (change)="tableOperationsSelection('reportCardFormats')"
              [checked]="reportCardFormatsCheck">Report Card Formats <i  class="fa fa-info-circle" [popover]="reportcard" 
                triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="bottom"></i>
              </mat-checkbox>
            </div>
          </div>
        </section>
        <!-- <section class="radioDefaultColor form-group35">
          <label class="label-block Bold">LMS</label>
          <div class="flex-grow-1 CheckboxGroup">
            <div class="col2Check">
              <mat-checkbox class="example-margin" value="Keep Existing Data ">Flip Class</mat-checkbox>
              <mat-checkbox class="example-margin" value="Flush Existing Existing Data">Quizzes</mat-checkbox>
              <mat-checkbox class="example-margin" value="Flush Existing Existing Data">Resources</mat-checkbox>
            </div>

          </div>
        </section> -->

        <div class="form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" matRipple >Send</button>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>

<ng-template #hideShowColPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Hide/Show Colums</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <p><a href="#" class="underlinedLink">Select All</a> / <a href="#" class="underlinedLink">Unselect All</a>
      </p>
      <form>
        <div class="row-flex">
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin m-0">
              First Name
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin m-0">
              Last Name
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin m-0">
              Grade
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin m-0">
              Section
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin m-0">
              Student Enrollment Status
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin m-0">
              Subject Enrollment Status
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin m-0">
              Admission No.
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin m-0">
              Middle Name
            </mat-checkbox>
          </div>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>

<ng-template  #ClassSchedules>
 <p class="text-center fs-sm">Class Schedules are related with Teachers - Subjects Allocation. So Teachers - Subjects Allocation must be selected to select Class Schedules.</p>
</ng-template>

<ng-template  #Assessment>
  <p class="text-center fs-sm">Assessment are related with Assessment Categories, Grading Scales and Grading Periods. So Assessment Categories, Grading Scales and Grading Periods must be selected to select the Assessment.</p>
 </ng-template>
 <ng-template  #reportcard>
  <p class="text-center fs-sm">Report Cards Formats are related with Grading Scales. So Grading Scales must be selected to select Report Cards Formats.</p>
 </ng-template>
 