import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-student-grade-import',
  templateUrl: './map-student-grade-import.component.html',
  styleUrls: ['./map-student-grade-import.component.css']
})
export class MapStudentGradeImportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
