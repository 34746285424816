import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { inject } from '@angular/core/testing';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr';
import { AcademicSessionService } from 'src/app/app-layout/content/academics-and-learning/courses-and-subjects/services/academicSessionServices/academic-session.service';
import { AcademicSession } from 'src/app/app-layout/content/academics-and-learning/student/manage-students/manage-students.component';
import { MasterService } from 'src/app/services/master.service';
import { StudentService } from 'src/app/services/student.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { DropdownInput } from '../../MasterModel/masterModel';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.css']
})
export class ExportDialogComponent implements OnInit {

formType:number;
title:string;
types$: any;
isEnrolled: boolean=false;
academicSessionDropDown: any = 0;
sessionList: AcademicSession[] = [];
exportForm: FormGroup;
columnListForExport:any;
isAlumni:boolean;
fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
fileExtension = '.xlsx';

  constructor(public dialogRef: MatDialogRef<ExportDialogComponent>,private toaster: ToastrService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data:any, private studentService:StudentService, private teachService:TeacherService, private academicSessionService: MasterService
    ) { 
        this.formType = data.formType;
        this.isAlumni = data.isAlumni;
        if(this.formType===1)
        this.title = 'Student';
        else if(this.formType===2)
          this.title = 'Teacher';
  }

  ngOnInit(): void {
    let result$;
      if(this.formType===1){
      result$ =  this.studentService.getColumnsForExport(this.formType);
      }
      else{
       result$ = this.teachService.getColumnsForExport(this.formType);
      }
      result$.subscribe((res:any)=>{
        if(res?.responseData){
          this.columnListForExport = res?.responseData;
          let group:any = {};
            res?.responseData.forEach((item:any)=>{
               group[item.fieldName] = new FormControl(false);
            })
            this.exportForm = new FormGroup(group);
        }
      });
      this.types$ = this.academicSessionService.GetAcademicSessionDropdown().subscribe((res: any) => {
        for (var i = 0; i < res.responseData.length; i++) {
          this.sessionList.push({
            sessionID: res.responseData[i].academicSessionID,
            sessionName: res.responseData[i].academicSessionName
          })
        }
      });
  }
  toggle(event: MatCheckboxChange){
      this.isEnrolled=event.source.checked;

      if(!event.source.checked)
        this.academicSessionDropDown = 0;
}
changeAcademicDropdownValue(value: any){
  this.academicSessionDropDown=value;
}

  downloadExportFile(event: any){
    event.preventDefault();
    let selectedColumns:string='';
    let title = this.title;
    this.columnListForExport.forEach((item:any)=>{
      if(this.exportForm.controls[item.fieldName].value){
        selectedColumns +=item.fieldName+',';
      }
    });
    if(selectedColumns==='')
    {
    this.toaster.error(`Please select atleast one column to export ${title} data`);
    }
    else {
      let fileName='';
      if(this.formType===1)
      {
        fileName = this.isAlumni===false?'StudentData':'AlumniData';
        
      }else{
        fileName = 'TeacherData';
      }

    let result$;
    if(this.formType===1){
      result$ =  this.studentService.exportFile(this.formType.toString(),selectedColumns,String(this.academicSessionDropDown), this.isAlumni);
      }
      else{
       result$ = this.teachService.exportFile(this.formType.toString(),selectedColumns,String(this.academicSessionDropDown));
      }

    result$.subscribe((data:any)=>{

      let workBook: any = null;
          let parsedExcelRowArray = null;
          let rawExcel: File;
         const reader = new FileReader();
         reader.onload = (event) => {
         const data = reader.result;
         workBook = XLSX.read(data, { type: 'binary' });
         rawExcel = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

        const blob = new File([new Blob([rawExcel], {type: this.fileType})], `${fileName}.xlsx`);
          const url= window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          link.download = `${fileName}.xlsx`;
          link.click();
       };

        reader.readAsBinaryString(data);
  });
    }
}

  selectUnSelectAllExport(val:boolean){
    this.isEnrolled= val;
    
    if(val === false)
     this.academicSessionDropDown = 0;

    this.columnListForExport.forEach((item:any)=>{
      this.exportForm.controls[item.fieldName].setValue(val);
    })
  }

}
