import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/services/master.service';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';

@Component({
  selector: 'app-template-four',
  templateUrl: './template-four.component.html',
  styleUrls: ['./template-four.component.css']
})
export class TemplateFourComponent implements OnInit {

  @Input() reportCardItem: any; 

  templateID:any;
  reportCardMasterFields: any;
  instituteGroup: any=[];
  studentGroup: any=[];
  attendanceGroup: any=[];
  assessmentsGroup: any=[];
  othersGroup: any=[];
  orgID: any;
  users: any;
  user: any;
  academicSessionID: any;
  croppedImage: any;

  public editorModel: any = {
    customCommentTitle: "",
    customCommentValue: "",
    contentCommentTitle: "",
    contentCommentValue: "",
    signatureCommentTitle: "",
    signatureCommentValue: ""
  };

  constructor(
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private masterService: MasterService
  ) { 
    if(localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
      this.croppedImage = localStorage.getItem('Logo');
    }else {
      this.croppedImage = '../../../../../../assets/img/admin/org_logo.png';
    }

    this.users = localStorage.getItem('currentUser');
      var currentUser = JSON.parse(this.users);
      this.academicSessionID = currentUser.academicSessionID
      if (currentUser != null) {
        this.user = currentUser;
      }
  }
  ngOnInit(): void {
    this.templateID = this.reportCardItem.value.Template;
    this.getFormat(this.templateID);
  }

  onSubmit(){
    if(this.reportCardItem.invalid) {
      if(this.reportCardItem.controls.selectedClassSectionID.errors?.required) {
        this.toaster.error('Please Select Class-Section');
      }
      if(this.reportCardItem.controls.Name.errors?.required) {
        this.toaster.error('Name cannot be empty');
      }
      if(this.reportCardItem.controls.Name.errors?.pattern){
        this.toaster.error('Enter a valid Name');
      }
    } else {
      let data = {
        ReportCardFormatName: this.reportCardItem.value.Name,
        ReportCardTemplateID: this.templateID,
        academicSessionID: this.academicSessionID,
        ClassSection: this.reportCardItem.value.selectedClassSectionID,
        Fields: this.reportCardMasterFields,
        CommentFields: this.editorModel
      }

      this.manageAssessmentService.CreateReportCardFieldDetails(data).subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
          // this.router.navigate(["admin/"]);
        } else {
          this.toaster.error(res.message, 'Error');
        }
      })
    }
  }

  reset() {
    this.reportCardItem.reset();
  }
  
  getOrganizationLogo() {
    this.masterService.GetOrganizationById(this.orgID).subscribe((res: any) => {
      this.croppedImage = res.responseData['organizationLogo']
    });
  }

  getFormat(Id: number) {
    this.manageAssessmentService.GetAllReportCardFields(Id).subscribe((res: any) => {
      this.reportCardMasterFields = res.responseData;
      this.instituteGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 1);
      this.studentGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 2);
      this.attendanceGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 3);
      this.assessmentsGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 4);
      this.othersGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 5);
    });
  }

  itemOnChangeInstitute(idx: any) {
    this.instituteGroup[idx].checked = !this.instituteGroup[idx].checked;
  }
  itemOnChangeStudent(idx: any) {
    this.studentGroup[idx].checked = !this.studentGroup[idx].checked;
  }
  itemOnChangeAttendance(idx: any) {
    this.attendanceGroup[idx].checked = !this.attendanceGroup[idx].checked;
  }
  itemOnChangeAssessments(idx: any) {
    this.assessmentsGroup[idx].checked = !this.assessmentsGroup[idx].checked;
  }
  itemOnChangeOthers(idx: any) {
    this.othersGroup[idx].checked = !this.othersGroup[idx].checked;
  }

}
