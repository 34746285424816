    <div class="container container1580">
        <div class="headingcontent">
          <div class="pageTitle mt-0">
            <h2 *ngIf="updateText; else elseTemplate">Update Attachment</h2>
 
            <ng-template #elseTemplate>
                <h2 mat-dialog-title>Add Attachment</h2>
            </ng-template> 
            <div class="breadcrumbWidget">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/admin"><i
                      class="fas fa-home"></i> Home</a>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage Teacher</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Attachment</li>
              </ol>
            </div>
          </div>
        </div>

    <div class="cardWidget">                
        <div class="matCard">

            <div class="profileDetailsWidget pt-4">
                <div class="col-md-6 px-0">
                    <form [formGroup]="form">

                        <div class="profileContentRight flex-grow-1">

                            <div class="proSection top pt-0">

                                <div class="">
                                    
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Title </mat-label>
                                            <input  maxlength="100" matInput
                                                placeholder="Title" required name="title"
                                                formControlName="title" pattern="^[^\s]+(\s+[^\s]+)*$">
                                            <mat-error
                                                *ngIf="form.get('title')?.touched  && form.get('title')?.errors?.required">
                                                Title is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('title')?.hasError('pattern')">
                                                Please enter valid title
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Description </mat-label>
                                            <textarea required maxlength="256" pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="description" matInput placeholder="Description" ></textarea>
                                            <mat-error *ngIf="form.get('description')?.touched  && form.get('description')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('description')?.hasError('pattern')">
                                                Please enter valid description
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-group">
                                        <input type="file" (change)="uploadFile($event)" name="filename">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 text-right">
                                <button type="submit" (click)="onSubmit($event)" [disabled]="form.invalid" class="btn btn-primary btn-sm" matRipple> <span *ngIf="updateText">Update</span><span *ngIf="!updateText">Submit</span></button>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
        <main>
            <div class="pageTitle">
                <!-- <h2>Manage Students</h2>
                <div class="breadcrumbWidget">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="../../dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Manage Students</li>
                  </ol>
                </div> -->
            </div>
            <div class="container-fluid">
                <div class="matCard">
                    <!-- <div class="cardTitle centerVTItems justify-content-between">
                    <h3>Manage Attachment</h3>
                    <button class="btn btn-primary btnsmall" (click)="goto()">
                      Back</button>
                  </div> -->
                    <div class="tableActionTop mb-3">

                        <div class="tableFilter">
                            <a (click)="toggleField()"><i class="fas fa-filter"></i> <span
                                    class="mobileHidden">Filter</span></a>
                            <div class="filterForm" [class.toggled]="classToggled">
                                <form [formGroup]="searchingForm" (ngSubmit)="onFilterSubmit()">
                                    <div class="row">
                                        <!-- <div class="col s12 m6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search Student Name</mat-label>
                                                <input matInput type="text" formControlName="studentName">
                                            </mat-form-field>
                                        </div> -->
                                        <div class="col s12 m6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search Document Name</mat-label>
                                                <input matInput type="text" formControlName="documentName">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search Title Name</mat-label>
                                                <input matInput type="text" formControlName="title">
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="btnsWidget form-group">
                                        <button type="submit" class="btn btn-primary btn-sm">Search</button>
                                        <button type="button" (click)="reset()"
                                            class="btn btn-reset btn-sm">Reset</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>



                    <app-data-table [inputColumns]="displayedColumns" [inputSource]="attchData" [inputMeta]="metaData"
                        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
                        (onTableActionClick)="onTableActionClick($event)">
                    </app-data-table>
                </div>


            </div>

        </main>
    </div>
</div>


