import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
// import { MatSort} from '@angular/material/sort';
// import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';
import { ToastrService } from 'ngx-toastr';
import { ClassService } from '../../../courses-and-subjects/services/classServices/class.service';
import { SectionService } from '../../../courses-and-subjects/services/sectionServices/section.service';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { SectionDropdownService } from 'src/app/masterServices/sectionDropdownService/section-dropdown.service';
import { DialogService } from 'src/app/services/dialog.service';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-reportcard-format',
  templateUrl: './reportcard-format.component.html',
  styleUrls: ['./reportcard-format.component.css']
})
export class ReportcardFormatComponent implements OnInit {

  classToggled = false;
  addaddformatForm: FormGroup;
  sectionListDropdown: any;
  classListDropdown: any;
  //formatList: any;
  updateTextInPopUp: boolean;
  sectionsListMenu: any;
  users: any;
  user: any;
  permissions: any;
  academicSessionID: any;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }

  formatList: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private toaster: ToastrService,
    private classDropdownService: ClassDropdownService,
    private sectionDropdownService: SectionDropdownService,
    private dialogService: DialogService,
    private sectionService: SectionService,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private router: Router,
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
    if (currentUser != null) {
      this.user = currentUser;
    }
  }
  displayedColumns: string[] = ['reportCardsFormatID', 'reportCardsFormatName', 'actions'];
  // dataSource = ELEMENT_DATA;
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  ngOnInit(): void {
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Grade Setup');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    this.getMasterDropdown();
    this.getFormat();
    // this.dataSource.sort = this.sort;	
  }
  checkActionPermission(actionName: any) {
    let screen = 'Grade Setup';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
    this.updateTextInPopUp = false;
    this.addaddformatForm = this.fb.group({
      reportCardsFormatID: [0, Validators.required],
      reportCardsFormatName: ['', Validators.required],
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      templateID: ['', Validators.required],
    });
  }
  Editformat(templateRef: TemplateRef<any>, id: number) {
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef);
    this.addaddformatForm = this.fb.group({
      reportCardsFormatID: [0, Validators.required],
      reportCardsFormatName: ['', Validators.required],
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      templateID: ['', Validators.required],
    });

    this.manageAssessmentService.getReportCardFormatById(id).subscribe((res: any) => {
      console.log(res);
      this.addaddformatForm.patchValue({
        reportCardsFormatID: res.responseData['reportCardsFormatID'],
        reportCardsFormatName: res.responseData['reportCardsFormatName'],
        classID: res.responseData['classID'],
        sectionID: res.responseData['sectionID'],
        templateID: res.responseData['templateID'],
        // console.log('this.addAssessmentCategoryForm', res);
      });

    });
  }
  addaddformat() {
    ////debugger;
    if (this.addaddformatForm.invalid) {
      return;
    }
    this.AddFormat();
  }

  AddFormat() {
    ////debugger;
    console.log("this.addaddformatForm.value", this.addaddformatForm.value);
    this.manageAssessmentService.addUpdateReportCardFormatAPI(this.addaddformatForm.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.getFormat();
      }
      else {
        this.toaster.error(res.message);
      }
      console.log("res", res);
    });
  }

  selectSectionByClassID(event: any) {
    this.sectionService.GetSectionByClassId(event.value).subscribe((res: any) => {
      this.sectionListDropdown = res.responseData;
    });
  }

  getFormat() {
    this.formatList = new MatTableDataSource();
    this.manageAssessmentService.GetReportCardFormat(this.academicSessionID).subscribe((res: any) => {
      console.log("res", res.responseData);
      this.formatList.data = res.responseData;
      this.formatList.sort = this.sort;
      this.formatList.paginator = this.paginator;
      this.formatList.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
    });
  }
  deleteFormat(id: any) {
    this.dialogService.confirm(`Are you sure you want to delete Assessment Category?`).subscribe((result: any) => {
      if (result == true) {
        this.manageAssessmentService.deleteReportCardFormatById(id).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.getFormat();
          }
          else {
            this.toaster.error(res.message);
          }
        });
      }
    });
  }
  getMasterDropdown() {
    this.sectionDropdownService.SectionDropdown().subscribe((res: any) => {
      this.sectionListDropdown = res.responseData
    })
    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classListDropdown = res.responseData
    });
  }

  reset() {
    this.addaddformatForm.reset();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  EditReportCard(Id: any) {
    this.router.navigate(["admin/academicslearning/edit-report-card"], { queryParams: { id: Id } });
  }



}


export interface PeriodicElement {
  id: number;
  name: string;
  actions: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: 1, name: 'Default', actions: ' ' },
  { id: 2, name: 'General', actions: ' ' },
];
