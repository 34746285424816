import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { RoleModel } from './RoleModel';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.css']
})
export class ManageRolesComponent implements OnInit {

  requestpages: any;
  form: FormGroup;
  filterForm:FormGroup;
  metaData: any;
  users: any;
  user:any;
   private filterModel: FilterModel;
   usersData: RoleModel[];

   classToggled = false;

   public toggleField() {
    this.classToggled = !this.classToggled;
  }
 
   displayedColumns: Array<any> = [
    { displayName: 'Role Name', key: 'roleName', isSort: true, class: '', width: '30%' },
    { displayName: 'Description', key: 'roleDescription', isSort: true, class: '', width: '40%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
   actionButtons: Array<any> = [
    // { displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
  permissions: any;
constructor(
  private dialog: MatDialog,
  private manageRolesService: RoleAndPermissionService,
  private formBuilder: FormBuilder,
  private router: Router,
  private toaster: ToastrService,
  private dialogService: DialogService,
  private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private masterService : MasterService
) { 
  this.users = localStorage.getItem('currentUser');
  var currentUser = JSON.parse(this.users);
  if (currentUser != null) {
    this.user = currentUser;
  }
  this.filterModel = new FilterModel();
  this.usersData = new Array<RoleModel>();
}
  //displayedColumns: string[] = ['profilepic', 'adminName', 'username', 'email', 'contactNumber', 'roleName', 'actions'];



ngOnInit(): void {
  //Legasov Added 25 March 2022
this.permissionService.checkComponentHasScreenPermission('Manage Roles');
  this.permissions = this.permissionService.getAllPermissions();
    if(this.permissions.length == 0){
     this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
       if(res.status> 0){
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
       }
     })
    }
  this.metaData = new Metadata();
  this.filterForm = this.formBuilder.group({
    roleName: [''],
  });

  this.form = this.formBuilder.group({ 
    roleName: [''],
    roleDescription: [''],
  });

  this.getUsersList(this.filterModel, '', '', );
  this.GetRolesList();
  this.ActivityLogTrack();
  // this.InitialGridCall()
  // this.AdminUserList();
}
displayFn(classData: Roles): string {
  return classData && classData.roleName ? classData.roleName : '';
}
roleList:any;
roleFlag:boolean;
GetRolesList(){
  this.roleService.getRoleListDropdown().subscribe((res: any)=> {
     this.roleList = res.responseData;
     console.log("this.roleList", this.roleList);
     
     if (this.roleList.length == 0) {
      this.roleFlag = true;
    } else {
      this.roleFlag = false;
    }
  })
}
checkActionPermission(actionName: any){
  let screen = 'Manage Roles';
 let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
  return permission.find((x: any) => x.actionName == actionName);
}


activityLog:any;
ActivityLogTrack()
{
  
  this.activityLog={
    ActivityLogTrack:{
            ModuleName: 'Academic & Learning',
            SubModuleName: 'Roles & Permissions',
            ActivityTypeId: 10,
            Description: 'Role list record viewed by',
            ScreenName: 'Manage Roles',
            RecordTypeId: 53,  
            RecordId: 0,      
            Type: 0
          }
  }
  this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response:any)=>{
    if(response!=null && response !=undefined && response !=''){
      let responseData=response.responseData;
      console.log("responseData:",responseData);
    }


  });
}




onPageOrSortChange(changeState?: any) {
  this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
  this.getUsersList(this.filterModel, '', '')
}
roleFilterData(){
console.log(" this.form.controls.roleName.value",  this.filterForm.value.roleName.roleName);
this.requestpages = {
  "pageSize": 10,
  "pageNumber": 1,
  "roleName": this.filterForm.value.roleName.roleName,
  //"roleDescription": this.form.controls.roleDescription.value,
  "columnName": "",
  "sortOrder": ""
}
this.getUsersList(this.requestpages, '', '')
}
resetFilter(){
  this.filterForm.reset();
  this.getUsersList(this.filterModel, '', '')
}
onSubmit() {
  this.requestpages = {
    "pageSize": 10,
    "pageNumber": 1,
    "roleName": this.form.controls.roleName.value,
    "roleDescription": this.form.controls.roleDescription.value,
    "columnName": "",
    "sortOrder": ""
  }
  console.log(this.requestpages);
  this.getUsersList(this.requestpages, '', '')
  //this.AdminUserList();
}
//metaData: any;
// AdminUserList() {
//   this.adminUserService.AllAdminUser(this.requestpages).subscribe((res: any) => {
//     if(res.status > 0){
//       console.log(res);
//       this.usersData = res.responseData;
//       this.metaData = res.meta;
     
//     }else{
//       this.metaData = new Metadata();
//     }
   
//   });
// }

onTableActionClick(actionObj?: any) {
  const id = actionObj.data && actionObj.data.roleID;
  switch ((actionObj.action || '').toUpperCase()) {
    case 'EDIT':
      this.editCall(id);
      break;
    case 'DELETE':
      {
        
        this.dialogService
          .confirm(`Are you sure you want to delete this role?`)
          .subscribe((result: any) => {
            if (result == true) {
              this.deleteCall(id)
            }
          });
      }
      break;
      case 'DETAILS':
      //this.infoCall(id);
      break;
    default:
      break;
  }
}
applyFilter(searchText: string = '') {
  this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
  if (searchText.trim() == '' || searchText.trim().length >= 3) {
    this.getUsersList(this.filterModel, '', '');
  }
}

getUsersList(filterModel: any, tags: any, roleIds: any) {
  this.manageRolesService.GetRolesList(filterModel).subscribe((res: any) => {
    if(res.status > 0){
      console.log(res);
      this.usersData = res.responseData;
      this.metaData = res.meta;
     
    }else{
      this.metaData = new Metadata();
    }
   
  });
}

setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
  this.filterModel.pageNumber = pageNumber;
  this.filterModel.pageSize = pageSize;
  this.filterModel.sortOrder = sortOrder;
  this.filterModel.sortColumn = sortColumn;
  this.filterModel.columnName = sortColumn;
  this.filterModel.searchText = searchText;
}

// infoCall(staffId: any){
//   //this.router.navigate(['/view-staff/']);
//   this.router.navigate(["/view-staff/"], { queryParams: { id: staffId } });
// }
// editCall(staffId: any){
//   //this.router.navigate(['/view-staff/']);
//   this.router.navigate(["/edit-staff/"], { queryParams: { id: staffId } });
// }

deleteId:any;
actionDialogPopup(templateRef: TemplateRef<any>,id:any) {
  this.dialog.open(templateRef,{
    data:id

  });
  this.deleteId = id;
}

deleteCall(id: any) {
  this.manageRolesService.deleteRole(id).subscribe((res: any) => {

    console.log(res);
    if (res.status > 0) {
      this.toaster.success(res.message, 'Success');
      this.getUsersList(this.filterModel, '', '', );
    } else {
      this.toaster.error(res.message, 'Error');
    }
  });
}


reset() {
  this.form.reset();
  this.InitialGridCall();
 
  this.getUsersList(this.requestpages, '', '')
}

InitialGridCall() {
  this.requestpages = {
    "pageSize": 10,
    "pageNumber": 1,
    "roleName": "",
    "roleDescription": "",
    "columnName": "",
    "sortOrder": ""
  }
}


editCall(id:any){
  this.router.navigate(["/admin/academicslearning/manage-roles/edit-roles"], { queryParams: { id: id } });

}


}
export interface Roles {
  roleID: string
  roleName: string;
}