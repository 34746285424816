import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, Department } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  constructor(private http : HttpClient) { }

  AddDepartment(data:any) {
    return this.http.post(`${environment.baseUrl}${Department.addDepartmentAPI}`,data);
  }

  AllDepartmentPlan() {
    return this.http.get(`${environment.baseUrl}${Department.getAllDepartmentAPI}`);
  }
  
  GetDepartmentById(Id:any) {
     return this.http.get(`${environment.baseUrl}${Department.getDepartmentByIdAPI}${'?departmentID=' + Id}`);
  }

  EditDepartment(data:any) {
    return this.http.post(`${environment.baseUrl}${Department.EditDepartmentByIdAPI}`,data);
  }
  
  DeleteDepartment(Id:any) {
    return this.http.get(`${environment.baseUrl}${Department.DeleteDepartmentByIdAPI}${'?id=' + Id}`);
  }

  DepartmentDropdown() {
    return this.http.get(`${environment.baseUrl}${Department.getAllDepartmentAPI}`);
  }

  EnableDepartment() {
    return this.http.get(`${environment.baseUrl}${Department.EnableDepartmentAPI}`);
  }

  GetEnableAndDisable() {
    return this.http.get(`${environment.baseUrl}${Department.GetEnableAndDisableDepartmentAPI}`);
  }

  getAllDepartmentCommonWeight(filterType: number, filterName: string) {
    return this.http.get(`${environment.baseUrl}${Department.GetAllDepartmentCommonWeight}${'?FilterType=' + filterType}${'&FilterName=' + filterName}`);
  }

  getAllDepartmentCommonWeightSubjects() {
    return this.http.get(`${environment.baseUrl}${Department.GetAllDepartmentCommonWeightSubjects}`);
  }
}
