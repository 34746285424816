<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <!-- <h2>{{firstName}} {{lastName}}</h2> -->
      <h2>{{studentName}}</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
          <!-- <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
              Learning</a></li> -->
          <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-students">Manage
              Students</a></li>
          <li class="breadcrumb-item active" aria-current="page">Fees and Invoicing</li>
        </ol>
      </div>
    </div>
    <div class="">
      <div class="add_button">
        <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
      </div>
    </div>
  </div>
  <!--/Page Title-->
  <div class="cardWidget">
    <app-profile-tabs></app-profile-tabs>
    <div class="cardBody">
      <div class="row">

        <div class="col-lg-6 col-sm-0">

        </div>
        <div class="col-lg-6 col-sm-12 text-right">
          <div class="amountStatus d-flex">
            <div class="d-flex">
              <div>
                <div class="feesPaid feeCol mr-2">
                  <h4>{{paidAmount}}</h4>
                  <p>Paid Amount</p>
                </div>
              </div>
              <div>
                <div class="feesDue feeCol">
                  <h4>{{unPaidAmount}}</h4>
                  <p>Un-paid Amount</p>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right d-flex">
            <div class="d-flex">
              <div class="viewColumns tableActions" matTooltip="Filter">
                <i class="fas fa-filter cursor-pointer"></i>
              </div>
              <div class="downloadTable tableActions" matTooltip="Download file">
                <a (click)="exportCSV()"><i class="fas fa-download"></i></a> 
              </div>
            </div>

          </div>

        </div>


      </div>
      <div class="filterform">
        <form [formGroup]="filterForm">
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Invoice Id</mat-label>
              <input matInput type="text" formControlName="invoiceId">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-sm-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select Status</mat-label>
              <mat-select placeholder="Select Status" formControlName="statusId">
                <mat-option *ngFor="let status of statusList"
                [value]="status.invoiceStatusMasterID">
                {{status.invoiceStatusName}}
            </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-sm-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Fee Name</mat-label>
              <mat-select placeholder="Select Status" formControlName="feeName">
                <mat-option *ngFor="let fee of feesNameList"
                   [value]="fee.feeAndInvoiceID">{{fee.feeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-sm-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Invoice Date(From)</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="startDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-sm-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Invoice Date(To)</mat-label>
              <input matInput [matDatepicker]="picker1" formControlName="endDate">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-sm-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Recurring</mat-label>
              <mat-select placeholder="Select Status" formControlName="recurring">
                <mat-option value="1">Yes</mat-option>
                <mat-option value="0">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="btnsWidget form-group text-right">
          <button (click)="filterSubmit()" type="submit" class="btn btn-primary btn-sm">Search</button>
          <button (click)="reset()" type="button" class="btn btn-reset btn-sm">Reset</button>
        </div>
      </form>
      </div>



      <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 d-table">

          <ng-container matColumnDef="Invoice ID">
            <th mat-header-cell *matHeaderCellDef> Invoice ID </th>
            <td mat-cell *matCellDef="let element">{{element.invoiceID}}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Fee Name">
            <th mat-header-cell *matHeaderCellDef>Fee Name</th>
            <td mat-cell *matCellDef="let element">{{element.feeName}}</td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="Fees Amount">
            <th mat-header-cell *matHeaderCellDef> Fees Amount </th>
            <td mat-cell *matCellDef="let element">{{currency}}{{element.feeAmount}}</td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="Discount">
            <th mat-header-cell *matHeaderCellDef> Discount </th>
            <td mat-cell *matCellDef="let element">{{currency}}{{element.discount}}</td>
          </ng-container>

          <ng-container matColumnDef="Paid Amount">
            <th mat-header-cell *matHeaderCellDef> Paid Amount </th>
            <td mat-cell *matCellDef="let element">{{currency}}{{element.paidAmount}}</td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">{{element.invoiceStatusName}}</td>
          </ng-container>

          <ng-container matColumnDef="Invoice Date">
            <th mat-header-cell *matHeaderCellDef> Invoice Date </th>
            <td mat-cell *matCellDef="let element">{{element.invoiceDate | date:dateFormat}}</td>
          </ng-container>

          <ng-container matColumnDef="Due Date">
            <th mat-header-cell *matHeaderCellDef> Due Date </th>
            <td mat-cell *matCellDef="let element">{{element.dueDate | date:dateFormat}}</td>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <Button title="View" (click)="viewInvoiceDetails(element.invoiceID)" class="tbactionbtn"><i
                  class="fas fa-eye"></i></Button>
              <Button *ngIf="element.invoiceStatusName!='Paid'" title="Delete"
                (click)="onsingleDelete(element.invoiceID)" class="tbactionbtn"><i class="fas fa-trash"></i></Button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="dataSource == null">No Data Found</div>
        <mat-paginator [length]="metaData['totalRecords']" [pageIndex]="metaData['currentPage'] - 1"
                (page)="handlePage($event)" [pageSize]="metaData['pageSize']" showFirstLastButtons>
            </mat-paginator>
      </div>
    </div>
  </div>
</div>