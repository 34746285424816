import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { RubicGradingScaleService } from 'src/app/services/rubic-grading-scale.service';
import { Criteria, CriteriaAttributes, Rubic, RubricDefaults } from '../../MasterModel/masterModel';


@Component({
  selector: 'app-rubic-grading-scale',
  templateUrl: './rubic-grading-scale.component.html',
  styleUrls: ['./rubic-grading-scale.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RubicDialogComponent implements AfterViewChecked  {
  formGroup: FormGroup;
  isDisabled: boolean = false;
  isErrorDisplayed: boolean = false;
  userID: any;
  closeCallback: any;
  totalCount: number;
  criteriaPoints: number;
  readOnly: boolean;
  studentIndex: number;
  assesstmentName: string;
  prevSelectedCriteria: any=[];
  selectedCriteria: any[]=[];
  refreshRubicGridCallback: any;
  AcademicSessionId:number;
          ClassId: number;
          SectionId: number;
          SubjectId: number;
  MessageInfo: any = {
    Exist: {
      attribute: 'Attribute already exists',
      criteria: 'Criteria already exists',
      both: 'Attribute/Criteria already exists.',
      attributeValue: 'Weightage already exists'
    },
    Action: {
        success: 'The rubic grade has been saved successfully',
        error: 'We are facing problem connecting to the server. Please check your network connection and try again',
        exists: 'The rubic name already exists'

    }
  };
  message: string;
  defaultCriteria: Criteria;
  RubicObject: Rubic;
  noPreviousSelectionMade: boolean;

  constructor(
    public dialogRef: MatDialogRef<RubicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private rubicService: RubicGradingScaleService,
    private toaster: ToastrService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.message = this.MessageInfo.Exist.attribute;
    this.defaultCriteria = data.Collection.defaultCriteria;
    if(Array.isArray(data.Collection.rubicObject)){
        this.RubicObject = data.Collection.rubicObject[0] as Rubic;
    }
    else{
        this.RubicObject = data.Collection.rubicObject;
    }
    this.readOnly = data.Collection.readOnly !== undefined ? data.Collection.readOnly : false;
    this.userID= data.Collection.userID;
    this.closeCallback = data.Collection.closeCallback;
    this.studentIndex = data.Collection.studentIndex;
    this.assesstmentName = data.Collection.assesstmentName;

    if(data.Collection?.prevSelectedCriteria){
      if(data.Collection.prevSelectedCriteria?.length)
      this.prevSelectedCriteria = [...data.Collection.prevSelectedCriteria];
    else
      this.prevSelectedCriteria = [data.Collection.prevSelectedCriteria];
    }
    else{
      this.prevSelectedCriteria = [RubricDefaults.defaultSelection];
    }
    

    this.noPreviousSelectionMade = this.prevSelectedCriteria[0].Criteria === 0 && this.prevSelectedCriteria[0].RubicCriteriaAttributeId === 0;
    this.message = this.MessageInfo.Exist.both;
    this.refreshRubicGridCallback = data.Collection.refreshCallback;
    this.AcademicSessionId = data.Collection.AcademicSessionId;
    this.ClassId= data.Collection.ClassId;
    this.SectionId= data.Collection.SectionId;
    this.SubjectId= data.Collection.SubjectId;
    
    if(this.noPreviousSelectionMade){
      this.formGroup = this.buildDynamicFormGroup(this.RubicObject);
      this.showPreviouslySelectedCriteria();
    }

    if(!this.noPreviousSelectionMade){
      this.showPreviouslySelectedCriteria();
      this.formGroup = this.buildDynamicFormGroup(this.RubicObject);
    }
    this.getTotal();
  }  
  ngOnInit(): void {}
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  clearDbSelectedCriteriaAttributes(){
    this.RubicObject.Criterias.forEach((criteria: Criteria)=>{
      if(criteria.isSelected)
          criteria.isSelected = false;

      const selectedAttribute = criteria.Attributes.find((attribute: CriteriaAttributes)=>{
          return attribute.isSelected === true;
      })

      if(selectedAttribute)
        selectedAttribute.isSelected = false;
    })
  }
  showPreviouslySelectedCriteria(){
    
    if(!this.noPreviousSelectionMade){
      this.selectedCriteria = this.prevSelectedCriteria;
      this.clearDbSelectedCriteriaAttributes();
      this.prevSelectedCriteria.forEach((previous: any)=>{
        if(previous.Criteria > 0 && previous.RubicCriteriaAttributeId > 0){
         const previouslySelectedCriteria = this.RubicObject.Criterias.find((allCriteria: Criteria)=>{
            return allCriteria.RubicCriteriaId === previous.Criteria
         })
  
          if(previouslySelectedCriteria)
              previouslySelectedCriteria.isSelected = true;
  
          const previouslySelectedAttribute = previouslySelectedCriteria?.Attributes.find((allAttributes: CriteriaAttributes)=>{
              return allAttributes.RubicCriteriaAttributeId === previous.RubicCriteriaAttributeId
          })
  
          if(previouslySelectedAttribute)
              previouslySelectedAttribute.isSelected= true;
  
        }
        
      });
    }
    else{
      const selectedCriteria = this.Criterions().value.filter((criteria: Criteria)=>{
        return criteria.isSelected === true
      });
  
      selectedCriteria.forEach((currentCriteria: any) => {
        const selectedAttributes = currentCriteria.Attributes.find((attribute: CriteriaAttributes)=>{
          return attribute.isSelected === true;
        });
  
        let selection = cloneDeep(selectedAttributes);
        selection.Criteria = currentCriteria.RubicCriteriaId;
        if(!this.selectedCriteria.some((existingCriteria: any)=> existingCriteria.Criteria === selection.Criteria)){
          this.selectedCriteria.push(selection);
        }
      });
    }
  }
  rubric() {
    return this.formGroup;
  }
  Criterions() {
    return this.formGroup.get('Criterions') as FormArray;
  }

  Attributes(criteriaIndex: number) {
    return this.Criterions().at(criteriaIndex).get('Attributes') as FormArray;
  }
  hideMessage() {
    this.isErrorDisplayed = false;
  }
  AddCriteria() {
    const defaultCriteria = { ...this.defaultCriteria };
    defaultCriteria.CriteriaTitle =
      defaultCriteria.CriteriaTitle + (this.Criterions().value.length + 1);
    defaultCriteria.CriteriaDescription =
      defaultCriteria.CriteriaDescription +
      (this.Criterions().value.length + 1);
    this.Criterions().push(this.createCriterions(defaultCriteria));
    this.getTotal();
  }
  removeCriteria(criteriaIndex: number) {
    if (
      this.Criterions().value.filter((criteria: Criteria) => {
        return criteria.IsDeleted === false;
      }).length > 1
    ) {
      this.Criterions().at(criteriaIndex).patchValue({
        IsDeleted: true,
      });

      this.Attributes(criteriaIndex).controls.forEach((attribute) => {
        attribute.patchValue({ IsDeleted: true });
      });

      this.getTotal();
    }
  }

  getPoints(criteriaIndex: number) {
    const criteria = (
      this.Criterions().at(criteriaIndex).get('Attributes') as FormArray
    ).value.filter((CriteriaAttributes: CriteriaAttributes)=>{ return CriteriaAttributes.IsDeleted === false});
    this.getTotal();
    return Math.max.apply(
      Math,
      criteria.map((attribute: CriteriaAttributes) => {
        return attribute.AttributeValue;
      })
    );
  }
  getTotal() {
    let total = 0;
    this.Criterions().value.filter((criteria: Criteria)=>{ return criteria.IsDeleted === false}).map((criteria: Criteria) => {
      total =
        total +
        Math.max.apply(
          Math,
          criteria.Attributes.filter((CriteriaAttributes: CriteriaAttributes)=>{ return CriteriaAttributes.IsDeleted === false}).map((attribute: CriteriaAttributes) => {
            return Number(attribute.AttributeValue);
          })
        );
    });

    this.totalCount = total;
  }
  addAttribute(criteriaIndex: number) {
    this.Attributes(criteriaIndex).push(
      this.createAttributes(
        new CriteriaAttributes(
          0,
          'Default' + (this.Attributes(criteriaIndex).value.length + 1),
          '5',
          false,
          false
        )
      )
    );
    this.getTotal();
  }

  removeAttribute(criteriaIndex: number, attributeIndex: number) {
    if (
      this.Attributes(criteriaIndex).value.filter(
        (attribute: CriteriaAttributes) => {
          return attribute.IsDeleted === false;
        }
      ).length > 1
    ) {
      this.Attributes(criteriaIndex).at(attributeIndex).patchValue({
        IsDeleted: true,
      });
    }
    this.getTotal();
  }

  showActions(e: any) {
    const element = e.target.querySelector('.add-remove-actions');
    if (element.classList.contains('hidden')) {
      element.classList.remove('hidden');
    }
  }

  hideActions(e: any) {
    const element = e.target.querySelector('.add-remove-actions');
    if (!element.classList.contains('hidden')) {
      element.classList.add('hidden');
    }
  }

  showBorder(e: any) {
    if (!e.target.classList.contains('border')) {
      e.target.classList.add('border');
    }
  }

  hideBorder(e: any) {
    if (e.target.classList.contains('border')) {
      e.target.classList.remove('border');
    }
  }

  isNotDeleted(criteriaIndex: number, attributeIndex: number) {
    return !this.Attributes(criteriaIndex).at(attributeIndex).value.IsDeleted;
  }
  isCriteriaNotDeleted(criteriaIndex: number) {
    return !this.Criterions().at(criteriaIndex).value.IsDeleted;
  }

  buildDynamicFormGroup(rubic: Rubic) {
    return this.formBuilder.group({
      RubicId: [rubic.RubicId],
      RubicName: [rubic.RubicName, Validators.required],
      Criterions: this.formBuilder.array(
        rubic.Criterias?.map((criteria) => this.createCriterions(criteria))
      ),
    });
  }
  createCriterions(criteria: Criteria): FormGroup {
    return this.formBuilder.group({
      ...criteria,
      CriteriaTitle: [criteria.CriteriaTitle, RxwebValidators.unique()],
      Attributes: this.formBuilder.array(
        criteria.Attributes?.map((attribute) =>
          this.createAttributes(attribute)
        )
      ),
    });
  }
  createAttributes(attribute: CriteriaAttributes): FormGroup {
    return this.formBuilder.group({
      ...attribute,
      AttributeName: [attribute.AttributeName, RxwebValidators.unique()],
      AttributeValue: [attribute.AttributeValue,RxwebValidators.unique()]
    });
  }
  close() {
    if(this.readOnly){
      if(this.Criterions().value.length === this.selectedCriteria.length){
        this.closeCallback(this.selectedCriteria,this.studentIndex,this.assesstmentName);
      }
      else{
        this.toaster.warning('Please select a attribute from all the criteria.');
      }
    }
    else{
      this.closeCallback(this.selectedCriteria,this.studentIndex,this.assesstmentName);
    }
  }
  select(event: any,criteriaIndex: number,attributeIndex: number){
    if(event.target.parentElement.parentElement.classList.contains('criteria-selected')){
      event.target.parentElement.parentElement.classList.remove('criteria-selected');

      if(this.selectedCriteria && this.selectedCriteria.length > 0){
        const removeCriteria = this.selectedCriteria.filter((criteria: any)=>{
            return criteria.RubicCriteriaAttributeId != this.Attributes(criteriaIndex).at(attributeIndex).value.RubicCriteriaAttributeId
        });

        this.selectedCriteria = removeCriteria;
      }
    }
    else{
      if(this.selectedCriteria === undefined || this.selectedCriteria?.filter((criteria: any)=> criteria.Criteria === this.Criterions().at(criteriaIndex).value.RubicCriteriaId)?.length === 0){
        event.target.parentElement.parentElement.classList.add('criteria-selected');
        const currentCriteria=this.Attributes(criteriaIndex).at(attributeIndex).value;
        currentCriteria.Criteria = this.Criterions().at(criteriaIndex).value.RubicCriteriaId;
        this.selectedCriteria.push(currentCriteria);
      }
      else{
        this.toaster.warning('You cannot select more than 1 attribute for a given criteria.');
      }
    }
  }
  isSelected(criteriaIndex: number,attributeIndex: number){
        const currentCriteria=this.Attributes(criteriaIndex).at(attributeIndex).value;
         return currentCriteria.isSelected;
  }
  save(event: any) {
    event.preventDefault();
    
    const request = {
        RubicCriteriaDetail: JSON.stringify(this.formGroup.getRawValue()),
        userId: this.userID
    }
    
    this.rubicService.Upsert(request).subscribe((response: any)=>{
      if(response.responseData === 'success'){
            this.toaster.success(this.MessageInfo.Action.success);
            this.refreshRubicGridCallback();
            this.close();
            window.location.reload();
        }
        else if(response.responseData === 'exists'){
          this.toaster.warning(this.MessageInfo.Action.exists);
        }
        else{
            this.toaster.error(this.MessageInfo.Action.error);
        }
    });

  }
}
