<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>View Admin/Staff Details</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-admin-staff">Manage
                            Admins</a></li>
                    <li class="breadcrumb-item active" aria-current="page">View Admin/Staff Details</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-admin-staff">Back</button>
            </div>
          </div>
    </div>
    <div class="cardWidget">
        <!-- <div class="cardTitle centerVTItems justify-content-between"><h3>{{firstName}}</h3>
            <button class="btn btn-primary btnsmall" (click)="back()">Back</button></div>
        <div class="cardBody"> -->

        <div class="profileDetailsWidget pt-4 d-md-flex">
            <div class="profilePicLeft form-group30">
                <p class="chngePic">
                    <!-- <input type="file"> -->

                    <img [src]="profilePhoto" alt="" />

                </p>
            </div>
            <div class="profileContentRight flex-grow-1 ml-2">



                <div class="proSection pt-0">
                    <h5>Admin Details</h5>
                    <div class="row">
                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>First Name :</label>
                                <span>{{firstName}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>Last Name :</label>
                                <span>{{lastName}}</span>
                            </div>
                        </div>


                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>Email :</label>
                                <span>{{email}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>User Name : </label>
                                <span>{{userName}}</span>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label> Admin Contact :</label>
                                <span>{{contactNumber}}</span>
                            </div>
                        </div>





                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>Address :</label>
                                <span>{{address}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>Zip Code :</label>
                                <span>{{zipCode}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>Country :</label>
                                <span>{{countryName}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>State :</label>
                                <span>{{stateName}}</span>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 s12 m6 form-group">
                            <div class="detailsContent">
                                <label>City :</label>
                                <span>{{city}}</span>
                            </div>
                        </div>






                    </div>
                </div>
            </div>
        </div>