import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { FilterModel, Metadata, TeacherAttacmentFilterModel } from 'src/app/common/MasterModel/masterModel';
import { DialogService } from 'src/app/services/dialog.service';
import { StudentService } from 'src/app/services/student.service';
import { TeachersService } from 'src/app/services/teachers.service';
import { environment, Student } from 'src/environments/environment';
import { AttachmentModel } from '../attachment-model';

@Component({
  selector: 'app-add-teacher-attachment',
  templateUrl: './add-teacher-attachment.component.html',
  styleUrls: ['./add-teacher-attachment.component.css']
})
export class AddTeacherAttachmentComponent implements OnInit {

  form: FormGroup;
  teacherIdList: any;
  teacherList: any;
  requestpages: any;
  searchingForm: FormGroup;
  metaData: any;
  private filterModel: TeacherAttacmentFilterModel;
  attchData: AttachmentModel[];
  classToggled = false;
  teacherID: any;
  id: any;
  updateText: boolean;
  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  displayedColumns: Array<any> = [
    { displayName: 'TEACHER NAME', key: 'teacherName', isSort: true, class: '', width: '20%' },
    { displayName: 'TITLE', key: 'title', isSort: true, class: '', width: '20%' },
    { displayName: 'Document Name', key: 'documentLabel', isSort: true, class: '', width: '20%' },
    { displayName: 'DESCRIPTION', key: 'description', isSort: true, class: '', width: '40%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
  actionButtons: Array<any> = [
    { displayName: 'Download', key: 'download', class: 'fas fa-download' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
  constructor(private formBuilder: FormBuilder,
    // private location: Location,
    private studentformBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private teacherService: TeachersService,
    private dialogService: DialogService,
    private toaster: ToastrService,
    private studentService: StudentService,
    private router: Router) {
    this.filterModel = new TeacherAttacmentFilterModel();
    this.attchData = new Array<AttachmentModel>();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.teacherID = params.id == undefined ? null : params.id;
      console.log("teacherID", this.teacherID);
    });
    this.metaData = new Metadata();
    this.searchingForm = this.formBuilder.group({
      documentName: [''],
      title: [''],

    });
    this.form = this.formBuilder.group({
      teacherDocumentID: [0],
      teacherID: [0],
      title: ['', Validators.required],
      description: ['', Validators.required],
      documentName: [],
      documentLabel: ['']
    });

    this.InitialGridCall();
    this.getAttchList(this.requestpages, '', '');
  }

  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText, this.teacherID);
    //this.InitialGridCall();
    //this.filterModel
    this.getAttchList(this.filterModel, '', '');
    // this.getAttchList(this.requestpages, '', '');
  }
  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string, teacherID: any) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
    this.filterModel.searchText = searchText;
    this.filterModel.teacherID = teacherID;
  }

  getAttchList(filterModel: any, tags: any, roleIds: any) {
    console.log("TEACHERSORT", filterModel)
    this.teacherService.GetTeacherAttachList(filterModel).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);
        this.attchData = res.responseData;
        this.metaData = res.meta;
      } else {
        this.metaData = new Metadata();
      }

    });
  }

  onTableActionClick(actionObj?: any) {
    ////debugger;
    this.id = actionObj.data && actionObj.data.teacherDocumentID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editTeacherAttachment(this.id);
        break;
      case 'DELETE':
        {

          this.dialogService
            .confirm(`Are you sure you want to delete this record?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.deleteAttach(this.id);
              }
            });
        }
        break;
      case 'DOWNLOAD':
        // this.downloadfile(actionObj.data.documentPath);
        //  window.open(`${environment.baseUrl}${Student.downloadfile}`+"?filepath="+actionObj.data.documentPath, "_blank");
        this.DownLoadExcel(actionObj.data.documentPath);
        break;
      default:
        break;
    }
  }

  DownLoadExcel(path: any) {
    this.studentService.downloadFile(path).subscribe((data: any) => { this.downloadFile(data) });
  }
  downloadFile(data: any) {
    const contentType = data.type
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    saveAs(blob)
  }


  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText, this.teacherID);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getAttchList(this.filterModel, '', '');
    }
  }

  GetTeacherByIdList() {
    this.teacherService.GetTeachersDatabyIdList(this.teacherIdList).subscribe((res: any) => {
      this.teacherList = res.responseData;
      console.log("ALLTeacher", this.teacherList)

    })
  }
  onSubmit(event: any) {
    this.submitted = true;
    const button = (event.srcElement.disabled === undefined) ? event.srcElement.parentElement : event.srcElement;
    button.setAttribute('disabled', true);
    setTimeout(function () {
      button.removeAttribute('disabled');
    }, 1000);
    if (this.form.invalid) {
      return;
    }
    if (this.documentFile != '') {
      this.form.controls.documentName.patchValue(this.documentFile);
    }
    else {
      this.toaster.error("Please choose File");
      return;
    }
    //debugger;
    this.form.controls.teacherID.patchValue(this.teacherID);
    console.log("form value", this.form.value);
    this.form.controls.documentLabel.patchValue(this.documentLabel);
    this.teacherService.postTeacherDocumentDetails(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        this.InitialGridCall();
        this.getAttchList(this.requestpages, '', '');
        window.location.reload();
        //this.ngOnInit();
      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }
  // reloadCurrentRoute() {
  //   let currentUrl = this.router.url;
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //     this.router.navigate([currentUrl]);
  //   });
  // }
  onFilterSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "title": this.searchingForm.controls.title.value,
      "columnName": "",
      "sortOrder": "",
      "teacherID": this.teacherID,
      "documentName": this.searchingForm.controls.documentName.value

    }
    console.log(this.requestpages);
    this.getAttchList(this.requestpages, '', '')
  }
  reset() {
    this.form.reset();
    this.searchingForm.reset();
    this.InitialGridCall();

    this.getAttchList(this.requestpages, '', '')
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "searchValue": "",
      "columnName": "",
      "sortOrder": "string",
      "teacherID": this.teacherID,
      "documentName": ""
    }
  }
  // end

  downloadfile(path: any) {
    this.teacherService.downloadFile(path).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);

      } else {

      }

    });
  }

  deleteAttach(id: any) {
    this.teacherService.deleteAttachmentDetailsId(id).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);
        this.toaster.success(res.message, 'Success');
        this.InitialGridCall();

        //this.reset();
        this.getAttchList(this.requestpages, '', '');


      } else {
        this.metaData = new Metadata();
      }

    });
  }

  teacherId: any = '';
  onSelect(data: any) {
    console.log("SELECTED DATA", data);
    this.teacherId = data.value['teacherID'];
  }
  submitted = false;
  formRequest: any;
  onSearchingSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    if (this.documentFile != '') {
      this.form.controls.documentName.setValue(this.documentFile);
    } else {
      if (this.form.controls.staffDocumentID.value == 0) {
        this.toaster.error('Upload document first', 'Error');
        return;
      }
    }
    if (this.teacherId != '') {
      this.form.controls.userID.setValue(this.teacherId);
    } else {
      if (this.form.controls.staffDocumentID.value == 0) {
        this.toaster.error('Select teacher first', 'Error');
        return;
      }
    }
    console.log(this.form.value);
    this.teacherService.postTeacherDocumentDetails(this.form.value).subscribe((res: any) => {
      console.log('test data its test', res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        //this.id = res.responseData;
        //this.form.controls.studentDocumentID.setValue(this.id);
        this.router.navigate(['/admin/academicslearning/manage-teacher-attachment/view-teacher-attachment']);
      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }
  documentLabel: string = '';
  uploadFile(event: any) {
    //debugger;
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      let file = event.target.files[0];
      this.documentLabel = file.name;
      formData.append('files', file);
      if (file.size < 20000000) {
        this.AddDocument(formData);
      }
      else {
        this.toaster.error("Please upload less than 20mb attachment file.")
        return;
      }
    }
  }

  documentFile: string = '';
  AddDocument(file: any) {
    this.teacherService.postTeacherDocData(file).subscribe((res: any) => {
      //debugger;
      console.log(res);
      if (res.body.status > 0) {
        this.documentFile = res.body.responseData.imageName;
        console.log(this.documentFile);
      }

    })
  }
  goto() {
    this.router.navigate(['/admin/academicslearning/manage-teacher-attachment/view-teacher-attachment']);
  }
  editTeacherAttachment(id: any) {
    ////debugger;
    if (this.id) {
      this.updateText = true;
    }
    this.teacherService.getAttachmentDetailsId(id).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.form.patchValue({
          teacherDocumentID: res.responseData['teacherDocumentID'],
          teacherID: res.responseData['teacherID'],
          title: res.responseData['title'],
          description: res.responseData['description'],
          documentName: res.responseData['documentName'],
        });
        // this.router.navigate(['/admin/academicslearning/manage-students/add-attachment']);
      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }
}
