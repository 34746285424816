import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-attachment',
  templateUrl: './edit-attachment.component.html',
  styleUrls: ['./edit-attachment.component.css']
})
export class EditAttachmentComponent implements OnInit {

  form: FormGroup;
  studentIdList:any;
  studentList:any;
  studentName:any = "";
  studentDocumentID:any = 0;
  constructor(private formBuilder: FormBuilder,
    private studentformBuilder: FormBuilder,
    private activatedRoute:ActivatedRoute,
    private studentService:StudentService,
    private toaster: ToastrService,
    private router: Router) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      studentDocumentID: 0,
      studentID: 0,
      title: ['', Validators.required],
      description: ['', Validators.required],
      documentName: [],
     
    });


    this.activatedRoute.queryParams.subscribe(params => {
      console.log("recieved",params.data)
      let data =  JSON.parse((atob(params.data)));
      console.log("recieved",data);
      this.studentName = data.studentName;
      if(data.studentDocumentID > 0){
         this.studentDocumentID =data.studentDocumentID;
        this.getAttachment();
      }
    });

    //this.GetStudentByIdList();
  }


  studentId:any = '';
  onSelect(data:any){
    console.log("SELECTED DATA",data);
    this.studentId = data.value['studentID'];
  }
  submitted = false;
  formRequest: any;
  onSubmit(){
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    if(this.documentFile != ''){
      this.form.controls.documentName.setValue(this.documentFile);
    }else{
      if(this.form.controls.studentDocumentID.value  == 0){
        this.toaster.error('Upload document first', 'Error');
        return;
      }
    }
    if(this.studentId != ''){
      this.form.controls.studentID.setValue(this.studentId);
    }else{
      if(this.form.controls.studentDocumentID.value  == 0){
        this.toaster.error('Select student first', 'Error');
        return;
      }
    }
    console.log(this.form.value);
    this.studentService.postStudnetDocumentDetails(this.form.value).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        //this.id = res.responseData;
        //this.form.controls.studentDocumentID.setValue(this.id);
        this.router.navigate(['/admin/academicslearning/manage-students/add-attachment']);
      }else{
        this.toaster.error(res.message, 'Error');
      }
    });
  }

  uploadFile(event:any){
    
    if (event.target.files && event.target.files[0]){
      const formData = new FormData();
      let file = event.target.files[0];
        formData.append('files', file);
      this.AddDocument(formData);
    }
  }

  documentFile: string = '';
  AddDocument(file:any) {
    this.studentService.postStudentDocData(file).subscribe((res: any) => {
      console.log(res);
      if(res.body.status > 0){
      this.documentFile = res.body.responseData.imageName;
        console.log(this.documentFile);
      }
      
    })
  }

  getAttachment(){
    this.studentService.getAttachmentDetailsId(this.studentDocumentID).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.form.patchValue({
          studentDocumentID: res.responseData['studentDocumentID'],
          studentID: res.responseData['studentID'],
          title: res.responseData['title'],
          description: res.responseData['description'],
          documentName: res.responseData['documentName'],
        });
        
      }else{
        this.toaster.error(res.message, 'Error');
      }
    });
  }
  goto(){
    this.router.navigate(['/admin/academicslearning/manage-students/add-attachment']);
  }
}