import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DialogService } from 'src/app/services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManageAttendanceService } from 'src/app/services/manage-attendance.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-attendance-settings',
  templateUrl: '../attendance-settings/attendance-settings.component.html',
  styleUrls: ['../attendance-settings/attendance-settings.component.css']
})
export class AttendanceSettingsComponent implements OnInit {
  form: FormGroup;
  public color: string = '#2889e9';
  isRelatedWithPresentAbsent: boolean = false;
  AttendanceStatusList: any;
  updateTextInPopUp: boolean;
  totalSumOfPresentAndAbsent: boolean;
  prese: number;
  users: any;
  user: any;
  permissions: any;
  absent: number;
  totalPer: number;
  constructor(private dialog: MatDialog, private fb: FormBuilder, private attendanceService: ManageAttendanceService,
    private dialogService: DialogService,
    private toaster: ToastrService,
    private commonService: CommonService,
    private permissionService: PermissionService,
    private roleService: RoleAndPermissionService, private activatedRoute: ActivatedRoute
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.user = currentUser;
    }
  }
  checkScreenPermission(screen: any) {
    return this.permissions.find((x: any) => x.screenName == screen);
  }
  checkActionPermission(actionName: any) {
    let screen = 'Attendance Status Setting';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  id: any;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;

    });
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Attendance Status Setting');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    this.initForm();
    this.getAllAttendanceStatus();
  }


  initForm() {
    this.form = this.fb.group({
      attendanceStatusMasterID: [''],
      StatusName: ['', Validators.required],
      StatusSortCode: ['', Validators.required],
      ColorCode: ['#2889e9'],
      keywordCode: ['', Validators.required],
      IsRelatedWithPresentAbsent: [''],
      PercentagePresent: ['', Validators.max(100)],
      PercentageAbsent: ['', Validators.max(100)],
    })
  }

  colorChange(event: any) {
    this.form.patchValue({
      ColorCode: event
    })
  }

  getAllAttendanceStatus() {
    this.attendanceService.GetAllAttendanceStatus().subscribe((res: any) => {
      if (res.responseData) {
        let defaultStatuses = res.responseData.filter((x: any) => x.isDefault == true);
        let nonDefaultStatuses = res.responseData.filter((x: any) => x.isDefault == false);
        this.AttendanceStatusList = [...defaultStatuses, ...nonDefaultStatuses];
        this.AttendanceStatusList = this.AttendanceStatusList.filter((x: any) => x.statusName != 'Not Marked Yet');
        console.log("AAAATSTATST", this.AttendanceStatusList)
      }
    })
  }

  changeIsRelatedWithPresentAbsent(event: any) {

    this.isRelatedWithPresentAbsent = !this.isRelatedWithPresentAbsent;
    if (event == false) {
      this.form.controls.PercentagePresent.setValue("");
      this.form.controls.PercentageAbsent.setValue("");
      this.form.controls.PercentagePresent.setValidators([]);
      this.form.controls.PercentageAbsent.setValidators([]);
      this.form.controls.PercentagePresent.updateValueAndValidity();
      this.form.controls.PercentageAbsent.updateValueAndValidity();
      this.totalSumOfPresentAndAbsent = false;
      this.form.patchValue({
        IsRelatedWithPresentAbsent: event
      });
    }
    else {
      this.form.patchValue({
        IsRelatedWithPresentAbsent: event
      });
    }


  }

  addStatus(templateRef: TemplateRef<any>) {
    this.totalSumOfPresentAndAbsent = false;
    this.color = '#2889e9';
    this.updateTextInPopUp = false;
    this.isRelatedWithPresentAbsent = false;
    this.initForm();
    this.dialog.open(templateRef);
  }


  editStatus(id: any, templateRef: TemplateRef<any>) {
    this.totalSumOfPresentAndAbsent = false;
    this.updateTextInPopUp = true;
    this.attendanceService.GetAttendanceStatusById(id).subscribe((res: any) => {
      console.log("res", res.responseData);

      if (res.responseData) {
        this.form.patchValue({
          attendanceStatusMasterID: res.responseData.attendanceStatusMasterID,
          StatusName: res.responseData.statusName,
          StatusSortCode: res.responseData.statusSortCode,
          keywordCode: res.responseData.keywordCode,
          ColorCode: res.responseData.colorCode,
          IsRelatedWithPresentAbsent: res.responseData.isRelatedWithPresentAbsent,
          PercentagePresent: res.responseData.percentagePresent,
          PercentageAbsent: res.responseData.percentageAbsent
        });
        this.color = res.responseData.colorCode;
        this.dialog.open(templateRef);
        this.isRelatedWithPresentAbsent = res.responseData.isRelatedWithPresentAbsent
      }
    })
  }



  maintainPresentAbsent(event: any) {
    ////debugger;
    this.prese = parseInt(this.form.controls.PercentagePresent?.value);
    this.absent = parseInt(this.form.controls.PercentageAbsent?.value);
    this.totalPer = this.prese + this.absent;

    if (this.totalPer != 100 && this.form.controls.PercentagePresent.valid && this.form.controls.PercentageAbsent.valid) {
      this.totalSumOfPresentAndAbsent = true;
    }
    else {
      this.totalSumOfPresentAndAbsent = false;
    }

    // if ((this.totalPer > 100 || this.totalPer < 100) && this.prese && this.absent) {
    //   this.totalSumOfPresentAndAbsent = true;
    // }
    // else {
    //   this.totalSumOfPresentAndAbsent = false;
    // }
  }

  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  checkWhiteSpace(event: any) {
    if (event.target.value.length > 0) {
      return;
    } else {
      event.preventDefault();
    }
  }
  onSubmit() {
    console.log(this.form.value);
    if (this.form.value['attendanceStatusMasterID']
      && this.form.value['attendanceStatusMasterID'] != '' && this.form.value['attendanceStatusMasterID'] != 0) {
      this.attendanceService.UpdateAttendanceStatus(this.form.value).subscribe((res: any) => {
        if (res.status > 0) {
          this.getAllAttendanceStatus();
          this.toaster.success(res.message);
        }
        else {
          this.toaster.error(res.message);
        }
      });
    }
    else {
      this.attendanceService.AddAttendanceStatus(this.form.value).subscribe((res: any) => {
        if (res.status > 0) {
          this.getAllAttendanceStatus();
          this.toaster.success(res.message);
        }
        else {
          this.toaster.error(res.message);
        }
      });
    }
    this.dialog.closeAll();
  }

  deleteStatus(id: any) {
    this.dialogService.confirm(`Are you sure you want to delete attendance status?`).subscribe((result: any) => {
      if (result == true) {
        this.attendanceService.DeleteAttendanceStatusById(id).subscribe((res: any) => {
          if (res.status > 0) {
            this.getAllAttendanceStatus();
            this.toaster.success(res.message);
          }
          else {
            this.toaster.error(res.message);
          }
        });
      }
    });

  }

}
