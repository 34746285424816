import { Component, Input, OnChanges, ViewChild, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { merge } from 'rxjs';
import { Meta, Metadata } from 'src/app/common/MasterModel/masterModel';
import { DatePipe } from '@angular/common';
const ELEMENT_DATA = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

const displayedColumns: Array<any> = [
  { displayName: 'Service Code', key: 'serviceCode', isSort: true, class: '' },
  { displayName: 'Description', key: 'description', isSort: true, class: '' },
  { displayName: 'Billable', key: 'isBillable' },
  { displayName: 'Unit Duration', key: 'unitDuration' },
  { displayName: 'Rate Per Unit', key: 'ratePerUnit' },
  { displayName: 'Required Authorization', key: 'isRequiredAuthorization' },
];


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DataTableComponent implements OnInit, OnChanges {
  @Input() inputSource: any[];
  @Input() inputColumns: any[];
  @Input() inputMeta: any;
  @Input() inputButtons:any[]
  @Output() onChange = new EventEmitter();
  @Output() onTableActionClick = new EventEmitter();
  displayedColumns: Array<any>;
  extraColumns: Array<any>;
  columnsToDisplay: Array<any>;
  dataSource: MatTableDataSource<any>;
  metaData = new  Metadata();// = new Metadata();
  actionButton:Array<any>;
  noRecord:boolean
  isLoadingResults = true;
  users:any;
  dateFormat:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //datepipe: any;
  date: Date;
  endDate: Date;
  constructor(public datepipe: DatePipe){
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
    }
  }
  ngOnInit() {

    // If the user changes the sort order, reset back to the first page.
    if(true){
        
    }
   
  }
  ngAfterViewInit(): void {
    
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
          .subscribe(() => {
            this.isLoadingResults = true;
    
            const changeState = {
              sort: this.sort.active || '' ,
              order: this.sort.direction || '',
              pageNumber: (this.paginator.pageIndex + 1)
            }
            this.onChange.emit(changeState);
          })
    //this.dataSource.sort = this.sort;	
  }

  ngOnChanges(value: any) {
    if (value.inputColumns) {
      this.displayedColumns = (value.inputColumns.currentValue || []).filter((x:any) => x.key != 'Actions');
      let changedColumns = (this.displayedColumns || []).map(obj => obj.key);
      if(value.inputButtons!=null && value.inputButtons.currentValue.length>0)
        changedColumns.push('Actions')
        this.columnsToDisplay = changedColumns;
        this.extraColumns = (value.inputColumns.currentValue || []).filter((x:any) => x.key == 'Actions');
    }
    if (value.inputSource) {
      
      this.isLoadingResults = false;
      if(value.inputSource.currentValue.length != 0){
        for(var i=0;i < value.inputSource.currentValue.length;i++){
          if(value.inputSource.currentValue[i].startDate){
          this.date = new Date(value.inputSource.currentValue[i].startDate)
          let latest_date =this.datepipe.transform(this.date, this.dateFormat);
          value.inputSource.currentValue[i].startDate = latest_date;
          }
          if(value.inputSource.currentValue[i].endDate){
            this.endDate = new Date(value.inputSource.currentValue[i].endDate)
            let latest_endDate =this.datepipe.transform(this.endDate, this.dateFormat);
            value.inputSource.currentValue[i].endDate = latest_endDate;
            }
        }
      }
    this.dataSource = value.inputSource.currentValue;
    if(value.inputSource.currentValue.length > 0){
      this.noRecord = false;
    }
    else{
      this.noRecord = true;
    }
    console.log("TABLE DATA",this.dataSource)
    }
    if (value.inputMeta) {
      this.metaData = value.inputMeta.currentValue || new Meta();
      console.log("MEATATAT",this.metaData)
    }
    if (value.inputButtons) {
      this.actionButton = value.inputButtons.currentValue || [];
    }
  }
  onToggleClick(action: string, data: any,column:string)
  {
    const actionObj = {
      action,
      data,
      column
    }
    this.onTableActionClick.emit(actionObj);
  }
  onActionClick(action: string, data: any) {
    const actionObj = {
      action,
      data,
    }
    this.onTableActionClick.emit(actionObj);
  }  
}


// class Meta {
//   totalPages?: number = 0;
//   pageSize?: number = 5;
//   currentPage?: number = 1;
//   defaultPageSize?: number = 5
//   totalRecords?: number = 0
// }