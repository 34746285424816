<!-- <div class="container container1580">
  <div class="pageTitle">
    <h2>Subjects Allocation</h2>
    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="../../dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Subjects Allocation</li>
      </ol>
    </div>
  </div>
   -->
   <div class="container container1580">
    <div class="headingcontent">
      <div class="pageTitle mt-0">
        <h2>Subjects Allocation</h2>
        <div class="breadcrumbWidget">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/admin"><i
                  class="fas fa-home"></i> Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage Teachers</a></li>
            <li class="breadcrumb-item active" aria-current="page">Subjects Allocation</li>
          </ol>
        </div>
      </div>
    </div>
  <!--/Page Title-->
  <div class="cardWidget">
    <div class="cardBody">
      <div class="row-flex">
        <!-- <div class="col s12 m4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Class</mat-label>
            <mat-select placeholder="Select Class" formControlName="classId" (selectionChange)="filterSectionListByClassId($event.value)">
              <mat-option *ngFor="let class of classList" [value]="class.classID">
                {{class.className}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="col-lg-5 col-sm-12 m4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Class</mat-label>
            <input type="text" matInput [formControl]="classControl" [matAutocomplete]="classauto">
            <mat-autocomplete #classauto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="filterSectionListByClassId($event)">
              <mat-option *ngFor="let class of filteredOptions | async" [value]="class">
                {{class.className}}
              </mat-option>
              <mat-option *ngIf="classFlag==true" disabled>
                No Class Found
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-lg-5 col-sm-12 m4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Section</mat-label>
            <input type="text" matInput [formControl]="sectionCOntrol" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="sectiondisplayFn">
              <mat-option *ngFor="let section of sectionfilteredOptions | async" [value]="section">
                {{section.sectionName}}
              </mat-option>
              <mat-option *ngIf="sectionFlag==true" disabled>
                No Section Found
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <!-- <div class="col s12 m4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Section</mat-label>
            <mat-select placeholder="Select Section" formControlName="sectionID">
              <mat-option *ngFor="let section of sectionListDropdown" [value]="section.sectionID">
                {{section.sectionName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <div class="col-lg-2 col-sm-12 m4">
          <div class="btnsWidget">
            <button type="button" (click)="getSubject()"  class="btn btn-primary m-0 mt-1 w-100" matRipple><i class=""></i>Get Subjects <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
              <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                <path id="Path" d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z" transform="translate(-3 -5.95)" fill="#fff"/>
              </g>
            </svg>
</button>
          </div>
        </div>
      </div>
    <span *ngIf="showTable == false">No subjects found for selected class/section</span>
      <form *ngIf="checkActionPermission('View Subject Allocation') && showTable == true" [formGroup]="subjectAllocationForm" >
        <div class="">
          <table formArrayName="subjectAllocationList">
            <tr class="tabeleheader">
              <th style="width: 15%;padding: 10px;"class="text-center" >Subject Name</th>
              <th style="width: 25%;padding: 10px;" >Current Teachers</th>
              <th style="width: 30%;padding: 10px;"  class="text-center" >Select Primary Teachers</th>
              <th style="width: 30%;padding: 10px;"class="text-center">Select Secondary Teachers</th>
            </tr>
            <ng-container *ngFor="let item of subjectArr.controls; let i = index">
              <tr formGroupName="{{i}}">
                <td>
                  <div class="w-100 text-center" >
                    <!-- <input matInput readonly formControlName="subjectName"> -->
                    <!-- <p>{{item.subjectName}}</p> -->
                    <p >{{item.value.subjectName}}</p>
                  </div>
                </td>
                <td>
                  <div *ngIf="item.value.primaryTeacher.length == '0' && item.value.secondaryTeacher.length == '0'">
                    <span class="notAssigned">Not Assigned</span>
                  </div>
                  <div *ngIf="item.value.primaryTeacher.length != '0' || item.value.secondaryTeacher.length != '0'">
                  <span class="primary">Primary Teacher:</span><span *ngIf="item.value.primaryTeacher.length == '0'" class="notAssigned">Not Assigned</span>
                  <div *ngIf="item.value.primaryTeacher.length != '0'">
                  <mat-chip-list aria-label="Primary Teacher" >
                    <mat-chip *ngFor="let chip of item.value.primaryTeacher" class="primaryteacher">{{chip.teacherName}}</mat-chip>
                  </mat-chip-list>
                </div>
                 <br>
                  <span class="secondary">Secondary Teacher:</span><span *ngIf="item.value.secondaryTeacher.length == '0'" class="notAssigned">Not Assigned</span>
                  <div *ngIf="item.value.secondaryTeacher.length != '0'">
                  <mat-chip-list aria-label="Secondary Teacher" >
                    <mat-chip *ngFor="let chip of item.value.secondaryTeacher" class="secondaryteacher">{{chip.teacherName}}</mat-chip>
                  </mat-chip-list>
                </div>
                </div>
                </td>
                <td class="px-1">
                  <!-- <div class="multiselectformfield">
                    <mat-label class="multiselectlabel">Primary Teacher</mat-label>
                  <ng-multiselect-dropdown name="teacher" [placeholder]="'Select Teacher'" [settings]="dropdownSettings"
                    [data]="teachers" formControlName="primaryTeacher" [disabled]="disabled"
                    (onSelect)="onItemSelect($event)">
                  </ng-multiselect-dropdown>
                </div> -->
                <div class="form-group multiselected">
                  <div class="w-100" appearance="outline">
                      <p-multiSelect class="teachersselect" [options]="teachers" formControlName="primaryTeacher" defaultLabel="Select a Teacher" 
                      optionLabel="teacherName" (onClick)="onPrimarySelect(i, elm.value)" display="chip" #elm>
                    </p-multiSelect>
                      <mat-label class="mutilselectlable" >Teachers</mat-label>
                  </div>

                 </div>
                </td>
                <td class="px-1">
                  <!-- <div class="multiselectformfield">
                  <mat-label class="multiselectlabel">Secondary Teacher</mat-label>
                  <ng-multiselect-dropdown name="teacher" [placeholder]="'Select Teacher'" [settings]="dropdownSettings"
                    [data]="teachers" formControlName="secondaryTeacher" [disabled]="disabled"
                    (onSelect)="onItemSelect($event, 'xray')">
                  </ng-multiselect-dropdown>
                </div> -->
                <div class="form-group multiselected">
                  <div class="w-100" appearance="outline">
                      <p-multiSelect class="teachersselect" [options]="teachers" formControlName="secondaryTeacher" defaultLabel="Select a Teacher" 
                      optionLabel="teacherName" (onClick)="onSecondarySelect(i, elms.value)" display="chip" #elms>
                    </p-multiSelect>
                      <mat-label class="mutilselectlable" >Teachers</mat-label>
                  </div>

                 </div>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>

        <div class="btnsWidget text-right">
          <button type="button" *ngIf="checkActionPermission('Update Subject Allocation')" (click)="onsubmit()" class="btn btn-primary btn-sm" matRipple>Update <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
            <g id="Group" transform="translate(12.611 8.478) rotate(180)">
              <path id="Path" d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z" transform="translate(-3 -5.95)" fill="#fff"/>
            </g>
          </svg>
</button></div>
      </form>
    </div>
  </div>
</div>
<!-- <table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
  <ng-container matColumnDef="subjectName">
    <th mat-header-cell *matHeaderCellDef> Subject Name </th>
    <td mat-cell *matCellDef="let element">
        {{element.subjectName}}
    </td>
  </ng-container>
  <ng-container matColumnDef="curentTeacher">
    <th mat-header-cell *matHeaderCellDef> Curent Teacher </th>
    <td mat-cell *matCellDef="let element"> <span class="notAssigned">{{element.curentTeacher}}</span> </td>
  </ng-container>
  <ng-container matColumnDef="primaryTeacher">
    <th mat-header-cell *matHeaderCellDef> Primary Teacher </th>
    <td mat-cell *matCellDef="let element">  
        <mat-form-field class="w-100" appearance="outline">
          <mat-select placeholder="Select Teacher" #select1 multiple>
            <mat-option></mat-option>
            </mat-select>
      </mat-form-field>
    </td>
  </ng-container>
  <ng-container matColumnDef="secondaryTeacher">
    <th mat-header-cell *matHeaderCellDef class="text-left"> Secondary Teacher </th>
    <td mat-cell *matCellDef="let element" class="text-left"> 
        <mat-form-field class="w-100" appearance="outline">
            <mat-select placeholder="Select Teacher" #select1 multiple>
              <mat-option></mat-option>
              </mat-select>
        </mat-form-field>
     </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
  </tr>                 
</table> -->