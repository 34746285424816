<div class="cardTitle centerVTItems justify-content-between">
  <h3 class="text-colored"><i class="fas fa-user-check mr-2"></i> Assessment Categories</h3>
  <button *ngIf="checkActionPermission('Add Assessment Category')"
   class="btn btn-primary btn-sm" (click)="actionDialogPopup(addassessment)" matRipple><i class="fas fa-plus"></i>
    Add New</button>
</div>
<div class="gradeSettings">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Sr. No.</th>
        <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
        <!-- <td mat-cell *matCellDef="let element"> {{element.assessmentCategoryID}} </td> -->
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="assessmentCategoryName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Category Name</th>
        <td mat-cell *matCellDef="let element"> {{element.assessmentCategoryName}} </td>
      </ng-container>

      <!-- Duration Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
          <div class="actionsCol">
            <!-- <i class="fas fa-arrows-alt"></i> -->
            <mat-icon
            *ngIf="checkActionPermission('Update Assessment Category')" (click)="editAssessmentCategoryModal(addassessment, element.assessmentCategoryID)">edit</mat-icon>
            <mat-icon *ngIf="checkActionPermission('Delete Assessment Category')" (click)="deleteAssessmentCategory(element.assessmentCategoryID)">delete</mat-icon>
          </div>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
        No assessment category found
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    <!-- <table>
      <tr *ngIf="!dataSource.length">
        <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
          No Assessment Category Found!
        </td>
      </tr>
    </table> -->
  </div>
  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
</div>

<ng-template #addassessment>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close (click)="reset()">clear</i>
    <div class="dialogTitle">
      <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Assessment Category</h2>
      <ng-template #elseTemplate>
        <h2 mat-dialog-title>Add Assessment Category</h2>
      </ng-template>
    </div>
    <form [formGroup]="addAssessmentCategoryForm" (ngSubmit)="onSubmitAssessmentCategory()">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Enter valid assessment name" pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="assessmentCategoryName" maxlength="50"
          (keydown.space)="checkWhiteSpace($event)" required />
        <mat-error
          *ngIf="addAssessmentCategoryForm.get('assessmentCategoryName')?.touched && addAssessmentCategoryForm.get('assessmentCategoryName')?.errors?.required">
          Assessment category name is required
        </mat-error>
      </mat-form-field>

      <div class="btnsWidget form-group17 text-right">
   
        <input matInput  formControlName="academicSessionID" hidden/>
         
            <button type="submit" class="btn btn-primary m-0 btn-sm mr-2" mat-dialog-close matRipple
              [disabled]="addAssessmentCategoryForm.invalid">Save</button>
            <button type="reset" (click)="reset()" class="btn btn-reset  m-0 btn-sm" mat-dialog-close matRipple>Cancel</button>
   
     
      </div>
    </form>
  </div>
</ng-template>