<div class="container container1580">
    <div class="pageTitle">
        <h2>Manage Student</h2>
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a href="admin/academicslearning/manage-students/">Manage Student</a></li>
                <li class="breadcrumb-item active" aria-current="page">View Student</li>
            </ol>
        </div>
    </div>
    <!--/Page Title-->
    <div class="cardWidget">
        <div class="cardTitle centerVTItems justify-content-between">
            <h3>View Student</h3><button class="btn btn-primary btnsmall" routerLink="../">Back</button>
        </div>
        <div class="cardBody">

            <div class="profileDetailsWidget pt-4 d-md-flex">
                <div class="profilePicLeft form-group30">
                    <p class="chngePic">
                        <input type="file">

                        <img [src]="profilePhoto" alt="">

                    </p>
                </div>
                <div class="profileContentRight flex-grow-1">
                    <div class="proSection">
                        <h5>Enrollment</h5>
                        <div class="row">
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>AcademicSession Name :</label>
                                    <span>{{academicSessionName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Class Name :</label>
                                    <span>{{className}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label> Section Name:</label>
                                    <span>{{sectionName}}</span>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="proSection">
                        <h5>Student Details</h5>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>First Name :</label>
                                    <span>{{firstName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6  s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Last Name :</label>
                                    <span>{{lastName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6  s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Admission Number:</label>
                                    <span>{{admissionNumber}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 c s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Email :</label>
                                    <span>{{email}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6  s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Date of Birth : </label>
                                    <span>{{dateOfBirth}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6  s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Gender :</label>
                                    <span>{{genderName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Blood Group :</label>
                                    <span>{{bloodGroupName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label> Student Contact :</label>
                                    <span>{{contactNumber}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="proSection">
                        <h5>Skills & Social Details</h5>
                        <div class="row">


                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Profile Summary :</label>
                                    <span>{{profileSummary}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label> Skills:</label>
                                    <span>{{skill}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>LinkedIn Link :</label>
                                    <span>{{linkedInlink}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Facebook Link :</label>
                                    <span>{{facebookLink}}</span>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div *ngIf="extraformField == true" class="proSection">
                        <h5>Custom Field Details</h5>
                        <div class="row">
                            <div *ngFor="let item of extraField | keyvalue" class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>{{item.key}} :</label>
                                    <span>{{item.value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="proSection">
                        <h5>Additional Details</h5>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Address :</label>
                                    <span>{{address}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Zip Code :</label>
                                    <span>{{zipCode}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Country :</label>
                                    <span>{{countryName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>State :</label>
                                    <span>{{stateName}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>City :</label>
                                    <span>{{city}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Father Name :</label>
                                    <span>{{fatherName}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Mother Name :</label>
                                    <span>{{motherName}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Parent Contact:</label>
                                    <span>{{parentContact}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Parent Email:</label>
                                    <span>{{parentsEmail}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>