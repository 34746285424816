import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { CommonService } from 'src/app/common/core-services/common.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import {Location} from '@angular/common';
@Component({
  selector: 'app-edit-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.css']
})
export class EditRolesComponent implements OnInit {
id: number;
form: FormGroup;
submitted = false;
formRequest:any;
items: TreeviewItem[];
roleList: any = [];
config = TreeviewConfig.create({
  hasAllCheckBox: false,
  hasFilter: false,
  hasCollapseExpand: false,
  decoupleChildFromParent: false,
  maxHeight:3000
});
  constructor(
    private manageRoleService: RoleAndPermissionService,
    private activatedRoute: ActivatedRoute,
    private formBuilder:FormBuilder,
    private commonService: CommonService,
    private toaster: ToastrService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      roleName: ['', [Validators.required]],
      roleDescription: [''],
      
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id == undefined ? null : params.id;
     
    });
    this.getPermissionByRoleId(this.id);
  }
  onSubmit(){
    var list: any = [];
    this.submitted = true;
   
    if (this.form.invalid) {
      //this.SpinnerService.hide();
      return;
    }
    if(this.roleList.length == 0){
      this.toaster.error('Atleast one permission select!', 'Error');
      return;
    }
    this.roleList.forEach((role: any) => {
      list.push({
        "screenActionID" : role
      })
    });
   
    this.formRequest = {     
      "roleID":this.id,
      "roleName": this.form.controls.roleName.value.trim(),
      "roleDescription": this.form.controls.roleDescription.value,
      "roleScreenPermissions": list   // this.roleList
    }

    this.RoleAdd();
  
  }
  RoleAdd(){
    this.manageRoleService.updateRole(this.formRequest).subscribe((res:any) => {
      console.log(res);
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        //this.router.navigate(['/manage-role']);
        this.location.back();
      }else{

        this.toaster.error(res.message, 'Error');
      }
      
    });
  }

  onSelectedChange(value: any){
    this.roleList = []
    this.roleList = value;
  }
  onFilterChange(event: any){

  }
  getPermissionByRoleId (id: any) {

   this.manageRoleService.GetPermissionByRoleID(id).subscribe((res: any) => {
     if(res.statusCode ==200){
      this.form.patchValue({
        roleName: res.responseData.roleName,
        roleDescription: res.responseData.roleDescription
      });
      this.RoleScreensList(res.responseData.finalList);
     }
      
   })
  }
  allowOnlyNumber(event:any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event:any) {
    this.commonService.allowAlphabetOnly(event);
  }
   RoleScreensList(res: any){
    this.items =  [  new TreeviewItem({
      text: 'Select All', value: 0,checked: true,
      children:res
    })]

  
    console.log(this.items);
 
  }
  noWhitespaceValidator(event:any){
    console.log("CONTROL",event.target.value)
    const isWhitespace = (event.target.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : event.target.value = '';
  }
}
