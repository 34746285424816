import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TeachersService } from 'src/app/services/teachers.service';

@Component({
  selector: 'app-edit-teacher-attachment',
  templateUrl: './edit-teacher-attachment.component.html',
  styleUrls: ['./edit-teacher-attachment.component.css']
})
export class EditTeacherAttachmentComponent implements OnInit {

  form: FormGroup;
  teacherIdList:any;
  teacherList:any;
  teacherName:any = "";
  staffDocumentID:any = 0;
  constructor(private formBuilder: FormBuilder,
    private studentformBuilder: FormBuilder,
    private activatedRoute:ActivatedRoute,
    private teacherService:TeachersService,
    private toaster: ToastrService,
    private router: Router) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      staffDocumentID: 0,
      userID: 0,
      title: ['', Validators.required],
      description: ['', Validators.required],
      documentName: [],
     
    });


    this.activatedRoute.queryParams.subscribe(params => {
      console.log("recieved",params.data)
      let data =  JSON.parse((atob(params.data)));
      console.log("recieved",data);
      this.teacherName = data.teacherName;
      if(data.staffDocumentID > 0){
         this.staffDocumentID =data.staffDocumentID;
        this.getAttachment();
      }
    });

    //this.GetStudentByIdList();
  }


  teacherId:any = '';
  onSelect(data:any){
    console.log("SELECTED DATA",data);
    this.teacherId = data.value['userID'];
  }
  submitted = false;
  formRequest: any;
  onSubmit(){
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    if(this.documentFile != ''){
      this.form.controls.documentName.setValue(this.documentFile);
    }else{
      if(this.form.controls.staffDocumentID.value  == 0){
        this.toaster.error('Upload document first', 'Error');
        return;
      }
    }
    if(this.teacherId != ''){
      this.form.controls.userID.setValue(this.teacherId);
    }else{
      if(this.form.controls.staffDocumentID.value  == 0){
        this.toaster.error('Select teacher first', 'Error');
        return;
      }
    }
    console.log(this.form.value);
    this.teacherService.postTeacherDocumentDetails(this.form.value).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        //this.id = res.responseData;
        //this.form.controls.studentDocumentID.setValue(this.id);
        this.router.navigate(['/admin/academicslearning/manage-teacher-attachment/view-teacher-attachment']);
      }else{
        this.toaster.error(res.message, 'Error');
      }
    });
  }

  uploadFile(event:any){
    
    if (event.target.files && event.target.files[0]){
      const formData = new FormData();
      let file = event.target.files[0];
        formData.append('files', file);
      this.AddDocument(formData);
    }
  }

  documentFile: string = '';
  AddDocument(file:any) {
    this.teacherService.postTeacherDocData(file).subscribe((res: any) => {
      console.log(res);
      if(res.body.status > 0){
      this.documentFile = res.body.responseData.imageName;
        console.log(this.documentFile);
      }
      
    })
  }

  getAttachment(){
    this.teacherService.getAttachmentDetailsId(this.staffDocumentID).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.form.patchValue({
          staffDocumentID: res.responseData['staffDocumentID'],
          userID: res.responseData['userID'],
          title: res.responseData['title'],
          description: res.responseData['description'],
          documentName: res.responseData['documentName'],
        });
        
      }else{
        this.toaster.error(res.message, 'Error');
      }
    });
  }
  goto(){
    this.router.navigate(['/admin/academicslearning/manage-teacher-attachment/view-teacher-attachment']);
  }
}