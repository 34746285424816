// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  baseUrl: 'https://localhost:5001',
  //  baseUrl: 'https://localhost:44363',
   baseUrl: 'https://instituteapi.creocms.com.au',
/*  baseUrl: 'https://instituteapi.creocms.com.au',*/
  //baseUrl: 'https://creolms.com.au:8003',
  // branch switch branch use only
  // do not change this is Admin url
   adminUrl: 'https://ytp-api.creocms.com.au',
/*  adminUrl: 'https://adminapi.creocms.com.au',*/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zongetEnrollmentStateusDropdowne.run`, `zoneDelegate.invokeTask`.
 * ng serve --port 4488
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.






//
export const organizationSetUp = {
  addAccountAPI: '/register',
};

export const SuperAccount = {
  resetPasswordAPI: '/api/Account/Reset-Password',
  CheckUserEmailAPI: '/api/Account/CheckUserEmail',
  ForgotAdminPasswordAPI: '/api/Account/ForgotAdminPassword',

};

export const UserProfile = {
  GetUserDetailById: '/api/UserProfile/GetUserDetailById',
  UpdateUserProfile: '/api/UserProfile/Update',
  UploadUserProfile: '/api/UserProfile/UploadUserProfile',
};

export const RubicApi = {
  getRubic: '/api/Rubics/GetRubic',
  upsert: '/api/Rubics/Upsert',
}

export const Student = {
  downloadCsvForImport: '/api/Student/DownloadImportTemplate/1',
  importStudent: '/api/Student/ImportStudent',
  getStatusReport: '/api/Student/DownloadStatusReport',
  exportStudent: '/api/student/ExportStudent',
  getColumnsForExport: '/api/student/GetColumnsForExport',
  getStudentFields: '/api/Student/GetFields',
  addStudentAPI: '/api/Student',
  getStudentList: '/api/Student/GetStudentList',
  deleteStudent: '/api/Student/Delete',
  updateStudent: '/api/Student/Update',
  UpdateStudentProfileImage: '/api/Student/UpdateProfileImage?id=',
  getStudentByID: '/api/Student/',
  getStudentDetailsByIdAPI: '/api/Student/StudentDetails',
  getStudentIdCardById: '/api/Student/GetStudentIdCardById',
  getStudentsDataByIdList: '/api/Student/GetStudentIdCardById',
  getParentDetails: '/api/Student/ParentsID',
  getStudentTableCustomColumn: '/api/Student/GetStudentCustomGridId',
  updateStudentTableColumn: '/api/Student/CreateStudentCustomGrid',
  getStudentByMultiId: '/api/Enrollment/GetStudentByMultiId',
  uploadStudentDocument: '/api/Student/UploadDocument',
  postStudentDocumentDetails: '/api/Student/CreateStudentDocument',
  getStudentAttachList: '/api/Student/GetStudentAttachList',
  getStudentAttachById: '/api/Student/GetStudentAttachById',
  downloadfile: '/api/Student/DownloadDocument',
  deleteStudentAttachById: '/api/Student/DeleteStudentAttachById',
  getStudentByIdData: '/api/Master/GetStudentListDropdown',
  sentStudentEmail: '/api/Master/CommonEmailSender',
  CommonEmailSenderNotification: '/api/Master/CommonEmailSenderNotification',
  StudentLoginInvitationEmail: '/api/Master/StudentLoginInvitationEmail',
  sendEmailToStudent: '/api/Student/SendEmailToStudent',
  importClassSectionStudentDataExcel: '/api/Student/ImportClassSectionSubjectDataFromExcel',


  getStudentAlumniList: '/api/Student/StudentAlumniList',
  revertStudentAlumni: '/api/Student/RevertStudentAlumni',
  sentStudentSms: '/api/Master/CommonSmsSender',
  blockUnblockStudent: '/api/Student/ChangeStudentStatus',
  addStudentToAlumni: '/api/Student/AddStudentAlumni',
  deleteMultipleStudent: '/api/Student/StudentmultipleDelete',
  blockUnblockAlumniStudent: '/api/Student/ChangeAlumniStatus',
  deleteMultipleAlumniStudent: '/api/Student/AlumniMultipleDelete',
  StudentreportcadEnableDisable: '/api/Student/ReportCardStatus',
  getAlumnistudentColumn: '/api/Student/GetAlumniStudentCustomGridId',
  updateAlumniStudentColumn: '/api/Student/CreateAlumniStudentCustomGrid',
  updateCreditial: '/api/Student/ResetPassword',
  importStudentApi: '/api/Student/ImportStudentDataFromCSV',
  SendPrivateNote: '/api/Student/PrivateNote',
  SendReview: '/api/Student/SendReview',
  FilteredSMSToShow: '/api/Student/GetSMSByID',
  FilteredEmailToShow: '/api/Student/GetEmail',
  sendEmail: '/api/Student/SendActivitiesEmail',
  //student attendance APIs
  getStudentAttendanceByDate: '/api/ManageStudentAttendance/GetAllStudentAttendanceByDate',
  saveStudentAttendanceByDate: '/api/ManageStudentAttendance/SaveStudentAttendanceByDate',
  getStudentAttendanceLog: '/api/ManageStudentAttendance/GetStudentAttendanceLog',
  getInvoiceandfeesBystudentId: '/api/Student/GetAllStudentInvoicesBySudentId/',
  getCalendarAttendanceByStudentID: '/api/ManageStudentAttendance/GetCalendarAttendanceByStudentID',
  getclassSchedulebyId: '/api/Student/GetStudentClassScheduleByIds',

  //student attendance Analytics APIs
  getStudentAnalyticsRecords: '/api/StudentAttendanceAnalytics/GetStudentAnalyticsRecords',
  getStudentAnalyticsRecordsFinal: '/api/StudentAttendanceAnalytics/GetStudentAnalyticsRecordsFinal',
  getStudentClassSetionForAnalutics: '/api/StudentAttendanceAnalytics/GetClassSectionForStudentAnalytics',
  getSubjectTeacher: '/api/StudentAttendanceAnalytics/GetSubjectForClassSection',
  getTeacherForMultiClassSection: '/api/StudentAttendanceAnalytics/GetTeacherForClassSection',
  getCombineClassSection: '/api/StudentAttendanceAnalytics/GetcombineClassSection',
  customFormByIdApi: '/api/DynamicForm/GetCustomFormById',
  CreateCoreStudent: '/api/DynamicForm/CreateCoreStudent',
  UpdateCoreStudent: '/api/DynamicForm/UpdateCoreStudent',
  AddUpdateDynamicFormGroupFields: '/api/DynamicForm/AddUpdateDynamicFormGroupFields',
  GetDynamicFormDetailsByStudentID: '/api/DynamicForm/GetDynamicFormDetailsByStudentID',
  CreateEditDeleteGroup: '/api/DynamicForm/AddUpdateGroupDetete',
  getcoreStudentDetailById: '/api/DynamicForm/GetCoreStudentViewDetailsByID',
  deletecoreStudent: '/api/DynamicForm/DeleteCoreStudent',

  getalumniList: '/api/DynamicForm/GetCoreAlumniList',
  AddUpdateDynamicAlumniFields: '/api/DynamicForm/AddShowHideAlumniDynamicList',
  studentInvoicepaidandunpaidAmount: '/api/DynamicForm/GetStudentAllInvoicesPaidUnPaidAmount',
  getAllInvoicesByStudentId: '/api/Student/GetAllStudentInvoicesByStudentID',
  GetStudentDynamicField: '/api/Student/StudentDynamicFieldName'
}


export const masters = {
  getCurrencyDropdown: '/api/Master/CurrencyDropdown',
  getCountryDropdown: '/api/Master/CountryDropdown',
  getStateDropdown: '/api/Master/StateDropdown',
  getBloodGroupDropdown: '/api/Master/BloodGroupDropdown',
  getGenderDropdown: '/api/Master/GenderDropdown',
  getClassDropdown: '/api/Master/ClassDropdown',
  getClassSectionDropdown: '/AcademicClass/ClassSectionDropdown',
  getSubjectDropdown: '/AcademicSubject',
  getSectionDropdown: '/api/Master/SectionDropdown',
  getStudentListDropdown: '/api/Student/GetStudentList',
  getAcademicSessionDropdown: '/AcademicSession/AcademicSessionDropdown',
  getParentDropdown: '/api/Master/GetAllParentsList',
  getStudentBySubEnrollmentAndSubject: '/api/Master/GetStudentBySubErollmentAndSubject',
  getAllClassesAPI: '/AcademicClass',
  getStudentByEnrollmentAndClass: '/api/Master/GetStudentBySubErollmentAndClass',
  getStudentSkillsDropdown: '/api/Master/StudentSkillsDropdown',
  getEmployeeTypeDropdown: '/api/Master/EmployeeTypeDropdown',
  getTeacherSkillsDropdown: '/api/Master/TeacherSkillsDropdown',
  getEnrollmentStateusDropdown: '/api/Master/StudentEnrollmentStatusDropdown',
  geSubectEnrollmentStateusDropdown: '/api/Master/SubjectEnrollmentStatusDropdown',
  getEnrollmentElectiveSubjectDropDown: '/api/Master/GetElectiveSubjectDropdown',
  getGetElectiveSubjectDropdownBySectionIdAPI: '/api/Master/GetElectiveSubjectDropdownBySectionId',
  getMultipleTeacherDataById: '/api/Master/GetTeacherListDropdown',
  getMultipleAdminStaffDataVyId: '/api/Master/GetAdminListDropdown',
  getShiftStatusDropdown: '/api/Master/ShiftStatusDropdown',
  getShiftCategoryDropdown: '/api/Master/ShiftCategoryDropdown',

  GetCurrentPlanStatus: '/api/Master/GetCurrentPlanStatus',
  getStaffAttachList: '/api/Master/GetTeacherAttachList',
  getStaffAttachById: '/api/Master/GetTeacherAttachById',
  deleteStaffAttachById: '/api/Master/DeleteTeacherDocumentById',
  postTeacherDocumentDetails: '/api/Master/CreateTeacherDocument',
  getStudentDropDownData: '/api/Master/StudentListDropdown',
  getStudentDropDownDataNewByClassSection: '/api/Master/StudentListDropdownNewByClassSection',
  getStudentDropDownDataNew: '/api/Master/StudentListDropdownNew',
  addclassSheduleTimetable: '/api/AddClassTimeTable/AddTimeTableCategories',
  updateclassSheduleTimetable: '/api/AddClassTimeTable/UpdateClassTimeTable',
  deleteclassSheduleTimetable: '/api/AddClassTimeTable/DeleteClassTimeTable',
  addtimeTableCategories: '/api/ClassTimeTableCategory/CreateTimeTableCategories',
  addCustomFormTemplate: '/api/DynamicForm/CreateDynamicForm',
  getCustomeForm: '/api/DynamicForm/GetDynamicFormById/',
  editCustomForm: '/api/DynamicForm/UpdateDynamicForm',
  getParentGroupDropdown: '/api/AccountParentGroupAndGroup/GetAllRecordsOfAccountGroup/',
  getAllDays: '/api/Master/GetAllDaysList',
  getAdminDropDown: '/api/Master/AdminListDropdown',
  getAlumniParentDropDown: '/api/Master/AlumniParentListDropdown',
  getActiveParentDropDown: '/api/Master/ActiveParentListDropdown',
  getTeacherDropDownData: '/api/Master/TeacherListDropdown',
  getStudentBYEnrollmentAndClass: '/api/Master/GetStudentByErollmentAndClass',
  getStudentByEnrollmentAndSubject: '/api/Master/GetStudentByErollmentAndSubject',
  getEmailTemplateById: '/api/EmailTemplate/GetEmailTemplateById',
  addEmailTemplate: '/api/EmailTemplate/CreateEmailTemplate',
  getAllEmailTemplate: '/api/EmailTemplate/GetAllEmailTemplates',
  updateEmailTemplate: '/api/EmailTemplate/UpdateEmailTemplate',
  deleteCustomEmailTemplate: '/api/EmailTemplate/DeleteEmailTemplate',
  getAllTeacher: '/api/Master/TeacherDropdownListDeletedAndNonDeleted',
  CreateConfig: '/api/Configuration/CreateConfig',
  UpdateConfig: '/api/Configuration/UpdateConfig',
  getConfig: '/api/Configuration/getConfig?key=',
  GetAcademicSessionByStudentID: '/AcademicSession/GetAcademicSessionByStudentID',
  uploadFilesAPI: '/api/EmailMaster/UploadFiles',
  checkproductionAPI: '/api/Master/CheckSubdomain',
  getStudentBirthday: '/api/Master/GetStudentBirthday',

};
export const profile = {
  getProfilePhoto: '/api/Account/UploadPhoto',
  getProfileData: '/api/AdminUsers/',
  getAdminProfileData: '/Profile/GetAdminProfile',
  updateAdminProfileData: '/Profile/UpdateAdminProfile',
  updateLoginProfileData: '/Profile/UpdateLoginUserProfile',
  getloginUserProfile: '/Profile/GetLoginUserProfile'
}
export const ManageTags = {
  GetAllAccountTag: '/api/AccountTag/GetAllRecordsOAccountTag',
  updateAccountTag: '/api/AccountTag/UpdateAccountTag',
  deleteAccountTag: '/api/AccountTag/DeleteAccountTag',
  createAccountTag: '/api/AccountTag/CreateAccountTag',
  getAccountTagById: '/api/AccountTag/GetAccountTagById',

}
export const AcademicSession = {
  addAcademicSessionAPI: '/AcademicSession',
  getAcademicSessionAPI: '/AcademicSession/GetAcademicSessionList',
  getAcademicSessionByIdAPI: '/AcademicSession/',
  EditAcademicSessionByIdAPI: '/AcademicSession/Update',
  DeleteAcademicSessionByIdAPI: '/AcademicSession/delete',
  AcademicSessionAcademicSessionLockUnlockAPI: '/AcademicSession/AcademicSessionLockUnlock',
  CopyAcademicSessionDateAPI: '/AcademicSessionCopy/AcademicSessionCopyData',
};

export const Department = {
  addDepartmentAPI: '/AcademicDepartment',
  getAllDepartmentAPI: '/AcademicDepartment',
  getDepartmentByIdAPI: '/AcademicDepartment/GetByacademicSessionIdOrdepartmentID',
  EditDepartmentByIdAPI: '/AcademicDepartment/Update',
  DeleteDepartmentByIdAPI: '/AcademicDepartment/delete',
  DepartmentDropdownAPI: '/AcademicDepartment/DepartmentDropdown',
  EnableDepartmentAPI: '/api/Master/EnableAndDisableDepartment',
  GetEnableAndDisableDepartmentAPI: '/api/Master/GetEnableAndDisableDepartment',
  GetAllDepartmentCommonWeight: '/ManageAssessment/GetAllDepartmentCommonWeights',
  GetAllDepartmentCommonWeightSubjects: '/ManageAssessment/GetAllSubjectInDepartmentCommonWeight'
};

export const Classes = {
  addClassesAPI: '/AcademicClass',
  getAllClassesAPI: '/AcademicClass',
  getClassesByIdAPI: '/AcademicClass/GetByClassIdOrDepartmentId',
  EditClassesByIdAPI: '/AcademicClass/Update',
  DeleteClassesByIdAPI: '/AcademicClass/delete',
  GetClassByClassIdOrDepartmentId: '/AcademicClass/GetByClassIdOrDepartmentId',
  GetClassByClassIdAPI: '/AcademicClass/GetByClassId',
  getClassandSection: '/AcademicClass/ClassSectionDropdown',
  GetClassSectionDropdownByAcademicSessionID: '/AcademicClass/GetClassSectionDropdownByAcademicSessionID',
  GetClassesForDropdown: '/AcademicClass/ClassDropdown',
};

export const Section = {
  addSectonAPI: '/AcademicSection',
  getAllSectionAPI: '/AcademicSection',
  getSectionByIdAPI: '/AcademicSection/GetClassIdOrSectionIdById',
  EditSectionByIdAPI: '/AcademicSection/Update',
  DeleteSectionByIdAPI: '/AcademicSection/delete',
  GetsectionBySectionIdOrClassId: '/AcademicSection/GetClassIdOrSectionIdById',
};


export const Subject = {
  addSubjectAPI: '/AcademicSubject',
  getAllSubjectAPI: '/AcademicSubject',
  getAllSubjectListAPI: '/AcademicSubject/GetAllSubjects',
  getSubjectByIdAPI: '/AcademicSubject/GetByClassIdSectionIdOrSubjectId',
  EditSubjectByIdAPI: '/AcademicSubject/Update',
  DeleteSubjectByIdAPI: '/AcademicSubject/delete',
  getSubjectBySectionIdAPI: '/AcademicSubject/GetByClassIdSectionIdOrSubjectId',
  getSubjectByClassIDOrSectionIdOrSubjectAPI: '/AcademicSubject/GetByClassIdSectionIdOrSubjectId',
  getSubjectByTeacherID: '/AcademicSubject/GetSubjectByTeacherID'
};

export const Master = {
  classDropdownAPI: '/api/Master/ClassDropdown',
  sectionDropdownAPI: '/api/Master/SectionDropdown',
  acdemicSessionStatusDropdownAPI: '/api/Master/AcademicSessionStatusDropdown',
  organizationTypeAPI: '/api/Master/OrganizationTypeDropdown',
  getSiteSubscriptionPlanDropdown: '/api/Master/SiteSubscriptionPlanDropdown',
  getSMSSubscriptionPlanDropdown: '/api/Master/SMSSubscriptionPlanDropdown',
  getEmailSubscriptionPlanDropdown: '/api/Master/EmailSubscriptionPlanDropdown',
  getPPkey: '/api/Master/GetPaymentGatewayKey',
  postTransaction: '/api/Master/CreateTransaction',
  UpdateOrganization: '/api/Master/UpdateOrganization',
  getSiteSubscriptionPlans: '/api/Master/GetSiteSubscriptionPlan',
  getSMSSubscriptionPlans: '/api/Master/GetSMSSubscriptionPlan',
  getEmailSubscriptionPlans: '/api/Master/GetEmailSubscriptionPlan',
  getOrganization: '/api/Master/GetOrganization',
  GetTransaction: '/api/Master/GetTransaction',
  GetTransactionById: '/api/Master/GetTransactionById?id=',
  GetOrganizationDetailById: '/api/OrganizationProfile/GetOrganizationDetailById',
  UpdateOrganizationDetailById: '/api/OrganizationProfile/Update',
  UploadOrganizationLogo: '/api/OrganizationProfile/UploadOrganizationLogo',
  UploadOrganizationFavIcon: '/api/OrganizationProfile/UploadOrganizationFavIcon',
  UploadOrganizationBackgroundImage: '/api/OrganizationProfile/OrganizationBackgroundImage',
  UploadOrganizationVideo: '/api/OrganizationProfile/OrganizationVideo',
  DeleteVideo: '/api/OrganizationProfile/delete',
  GetOrganizationDetailBySubdomain: '/api/OrganizationProfile/GetOrganizationDetailBySubdomain',
  moduleNameDropDown: '/api/Master/GetModuleDropDown',
  userNameDropDown: '/api/Master/GetUserDropDown',
  ActivityLog: '/api/Master/ActivityLog',
  ActivityLogForNotification: '/api/Master/ActivityLogForNotification',
  updateNotificationIsShown: '/api/Master/ActivityLogShowHide',
  ScreenName: '/api/Master/ScreenName',
  getAllStudentLoginActivity: '/api/Report/GetAllStudentsLoginActivityReport',
  getAllIncompleteContactDetail: '/api/Report/GetAllStudentsInCompleteContactsReport',
  getAllStudentContactDetail: '/api/Report/GetAllStudentsContactReport',
  getAllAcademicsDataReport: '/api/Report/GetAllAcademicsDataReport',
  getAllStudentSubjectAllocationReport: '/api/Report/GetAllStudentsSubjectAllocationReport',
  getAllStudentbirthdayReport: '/api/Report/GetAllStudentsBirthDayReport',
  getAllStudentAgeReport: '/api/Report/GetAllStudentsAgeReport',
  getAllStudentAlumniReport: '/api/Report/GetAllAlumniReport',
  getAllActivityReport: '/api/Report/GetAllActivityReport',
  getfilteredStudentSubjectAllocationReport: '/api/Report/GetAllStudentsSubjectAllocationReport',
  getstudentCustomBirthdayReport: '/api/Report/GetAllStudentsBirthDayReport',
  getstudentIncompleteContactByFilterReport: '/api/Report/GetAllStudentsInCompleteContactsReport',
  getstudentContactByFilterReport: '/api/Report/GetAllStudentsContactReport',
  getTeacherContactReport: '/api/Report/GetAllTecaherContactReport',
  getTeacherLoginActivityReport: '/api/Report/GetAllTeacherLoginActivityReport',
  getTeacherSubjectAllocationReport: '/api/Report/GetAllTeacherSubjectAllocationReport',
  getParentLoginActivityReport: '/api/Report/GetAllParentsLoginActivityReport',
  getParentLoginActivityByfilterReport: '/api/Report/GetAllParentsLoginActivityReport',
  getParentInfoReport: '/api/Report/GetAllParentsInfoReport',
  getParentInfoByfilterReport: '/api/Report/GetAllParentsInfoReport',
  getSiblingsReport: '/api/Report/GetAllStudentSibilingsReport',
  StudentsAverageAttendanceReport: '/api/Report/GetAllStudentsAvgAttendanceReport',
  ClassAverageAttendanceCustomReport: '/api/Report/GetAllStudentsClassSectionAvgReport',
  getStudentsAbsentDaysReport: '/api/Report/GetAllStudentsAbsentReport',
  getDailyAttendanceReport: '/api/Report/GetAllStudentsDailyAttendanceReport',
  getAttendanceRawReport: '/api/Report/GetAllStudentsAttendanceReport',
  FeesPaymenStatisticsDefaultReport: '/api/Report/GetAllFeesPaymentStatisticsReport',
  StudentsBasedFeeReport: '/api/Report/GetAllStudentsFeeReportByClassIDSectionID',
  PaymentsSummaryReport: '/api/Report/GetAllStudentsPaymentSummaryReport',
  ClassSectionFinalScoresReport: '/api/Report/GetAllFinalClassSectionScoresReport',
  AssessmentScoresReport: '/api/Report/GetAllAssessmentScoresReport',
  getClassSectionScoreReport: '/api/Report/GetAllClassSectionScoresReport',
  getAllStatisticsForAcademic: '/api/Report/GetAcademicsDashboardstatisticsDetails',
  getStudentVsClassSectionForAcademic: '/api/Report/GetAcademicsDashboardsStuClassSectionChart',
  getMaleFemaleTeacherForAcademic: '/api/Report/GetAcademicsDashboardsMaleVsFemaleTeacherChart',
  getAllStatisticsForFee: '/api/Report/GetFeesDashboardStatisticsDetails',
  getAllAmountVsFeesForFee: '/api/Report/GetFeesDashboardAmountVsMonthChart',
  getAllAmountVsMonthsForFee: '/api/Report/GetFeesDashboardAmountVsFeesChart',
  getAllStatisticsForAttendance: '/api/Report/GetAttendanceDashboardChart',
  getAllTeacherStudentLinkReport: '/api/Report/GetAllTeacherStudentLinkReport',
  getLedgerDropdownByAccountID: '/api/Master/GetLedgerDropdownByAccountID',
  getAllStudentsGenderReport: '/api/Report/GetAllStudentsGenderReport',
  getAttendancebyDates: '/api/Report/GetAttendanceDashboardChartNew',
  GetOrganizationDetailBySubdomainForEmail: '/api/OrganizationProfile/GetOrganizationDetailBySubdomainForEmail',
  GetActivityLogTrack: '/api/Master/ActivityLogTrack'
};

export const Teacher = {
  downloadCsvForImport: '/api/Teacher/DownloadImportTemplate/2',
  getTeacherFields: '/api/Teacher/GetFields',
  getStatusReport: '/api/Teacher/DownloadStatusReport',
  importTeacher: '/api/Teacher/ImportTeacher',
  addTeacherAPI: '/api/Teacher',
  getTeacherList: '/api/Teacher/GetTeacherList',
  deleteTeacher: '/api/Teacher/Delete',
  updateTeacher: '/api/Teacher/Update',
  UpdateTeacherProfileImage: "/api/Teacher/UpdateProfileImage?id=",
  getTeachertByID: '/api/Teacher/',
  getTeacherDetailsByIdAPI: '/api/Teacher/TeacherDetails',
  getTeacherTableColumn: '/api/Teacher/GetTeacherCustomGridId',
  updateTeacherTableColumn: '/api/Teacher/CreateTeacherCustomGrid',
  getTeacherDataByIdList: '/api/Master/GetTeacherListDropdown',
  uploadTeacherDocument: '/api/Master/UploadTeacherDocument',
  deleteMultipleTeacher: '/api/Teacher/TeachersMultipleDelete',
  getTeacherAttendanceByDate: '/api/ManageTeacherAttendance/GetTeacherAttendanceByDate',
  saveTeacherAttendanceByDate: '/api/ManageTeacherAttendance/SaveTeacherAttendanceByDate',
  getAllTeacherAttendanceByDate: '/api/ManageTeacherAttendance/GetAllTeacherAttendanceByDate',
  getSujectbyclasssectionId: '/api/SubjectTeacherAllocation/GetAllSubjectsTeacherAllocatedList',
  subjectAllocationAPI: '/api/SubjectTeacherAllocation/AllocateSubjectAndTeacher',
  importTeachers: '/api/Teacher/ImportTeacherDataFromCSV',
  getSubjectwithClassAndSection: '/api/SubjectTeacherAllocation/GetAllSubjelistWithClassAndSectionNameList',
  getTeacherAttendanceAnalyticsRecord: '/api/StudentAttendanceAnalytics/GetTeacherAnalyticsRecords',
  createcoreTeacher: '/api/DynamicForm/CreateCoreTeacher',
  GetDynamicFormDetailsByTeacherID: '/api/DynamicForm/GetDynamicFormDetailsByTeacherID',
  updatecoreTeacher: '/api/DynamicForm/UpdateCoreTeacher',
  getcoreTeacherDetailById: '/api/DynamicForm/GetCoreTeacherViewDetailsByID',
  deletecoreTeacher: '/api/DynamicForm/DeleteCoreTeacher',
  getTeacherClassSchedule: '/api/Teacher/GetTeacherClassScheduleByIds',
  getColumnsForExport: '/api/Teacher/GetColumnsForExport',
  exportTeacher: '/api/Teacher/ExportTeacher',
  getTeacherByIdData: '/api/Master/GetTeacherDetailsByID',
  updateCreditial: '/api/Teacher/ResetPassword'
}


export const Enrollments = {
  studentEnrollmentAPI: '/api/Enrollment',
  subjectEnrollmentAPI: '/api/Enrollment/ElectiveSubjectEnrollment',
  updateStudentEnrollmentStatusAPI: '/api/Enrollment/UpdateStudentEnrollmentStatus',
  deleteStudentEnrollmentAPI: '/api/Enrollment/DeleteStudentEnrollment',
  deleteElectiveSubjectEnrollmentAPI: '/api/Enrollment/DeleteElectiveSubjectEnrollment',
  updateSubjectEnrollmentStatusAPI: '/api/Enrollment/UpdateSubjectEnrollmentStatus',
  getEnrollmentDetailsBystudentIDAPI: '/api/Enrollment/',
  getSubjectEnrollmentDetailsBystudentIDAPI: '/api/Enrollment/studentID',
  getSubjectEnrollmentTabDetailsBystudentIDAPI: '/api/Enrollment/GetSubjectEnrollmentByStudentId',
  getAllElectiveSubjectAPI: '/api/Enrollment/GetElectiveSubjectByClassAndSection',
}

export const Shift = {

  addShiftApi: '/api/Shift',
  getShiftList: '/api/Shift/GetShiftList',
  deleteshift: '/api/Shift/Delete',
  updateShift: '/api/Shift/Update',
  getShiftByID: '/api/Shift/',
}

export const ShiftCategory = {
  addShiftCategoryApi: '/api/ShiftCategory',
  getShiftCategoryList: '/api/ShiftCategory/GetShiftList',
  deleteshiftCategory: '/api/ShiftCategory/Delete',
  updateShiftCategory: '/api/ShiftCategory/Update',
  getShiftCategoryByID: '/api/ShiftCategory/',
}

export const HomeService = {
  adminLoginAPI: '/Login/Adminlogin',
  ResendOtp: '/Login/ResendOtp',
  OtpValidation: '/Login/OtpValidation',
}
export const Email = {
  sentEmailAPI: '/api/EmailMaster/SentEmail',
  getEmailAPI: '/api/EmailMaster/GetEmailList',
  getEmailTemplateById: '/api/EmailTemplate/GetEmailTemplateById/',
  getCustomEmailTemplate: '/api/EmailTemplate/GetCustomEmailTemplate',
  getEmailStatisticsAPI: '/api/EmailMaster/GetEmailStatistics',
  getEmailCreditLog: '/api/EmailMaster/GetEmailCreditLog?id=',
}

export const Branch = {
  postBranchAPI: '/api/Branch/CreateBranch',
  getBranchAPI: '/api/Branch/BranchDropdown',
  getOrganizationsByUserAPI: '/api/OrganizationProfile/GetOrganizationByUser',
  getBranchesList: '/api/Branch/GetBranchByUserNameOrSearchKeyword'
}
export const Sms = {
  sentSmsAPI: '/api/SmsMaster/SentSms',
  getSmsAPI: '/api/SmsMaster/GetSmsList',
  getSmsStatisticsAPI: '/api/SmsMaster/GetSmsStatistics',
  getSmsEmailStatisticsAPI: '/api/SmsMaster/GetSmsEmailStatistics',
  saveSMSCustomGrid: '/api/SmsMaster/CreateSmsCustomGrid',
  getSMSCustomGrid: '/api/SmsMaster/GetSmsCustomGrid',
  getSmsCreditLog: '/api/SmsMaster/GetSmsCreditLog?id=',
}

export const ClassTimeTable = {
  getTimetableList: '/api/ClassTimeTableCategory/GetAllClassTimeTableCategoriesListWithSort_Search',
  getTimeTableByID: '/api/ClassTimeTableCategory/GetTimeTableCategoriesById/',
  updateTimeTableData: '/api/ClassTimeTableCategory/UpdateTimeTableCategories',
  deleteTimeTableDataByID: '/api/ClassTimeTableCategory/DeleteTimeTableCategories',
  getClassSheduleData: '/api/AddClassTimeTable/GetAllClassTimeTableScheduleList',
  getClassSheduleByCategoryId: '/api/AddClassTimeTable/GetAllClassTimeTableScheduleListByCategoryID/',
  getTeacherBysubjectId: '/api/AddClassTimeTable/GetSubjectTeacherDetailListBySubjectId/'
}

export const ClassTimeTableCategory = {
  getCategoryById: '/api/ClassTimeTableCategory/GetTimeTableCategoriesById/'
}

export const Admin = {
  addAdminAPI: '/api/Admin',
  getAdminList: '/api/Admin/GetAdminList',
  deleteAdmin: '/api/Admin/Delete',
  updateAdmin: '/api/Admin/Update',
  UpdateAdminProfileImage: '/api/Admin/UpdateProfileImage?id=',
  getAdminByID: '/api/Admin/',
  getAdminDetailsByIdAPI: '/api/Admin/AdminDetails',
  getAdminByIdData: '/api/Master/GetAdminListDropdown',
  sentAdminEmail: '/api/Master/CommonEmailSenderForAdminUser',
  sentAdminSms: '/api/Master/CommonSmsSender',

  updateAdminTableColumn: '/api/Admin/CreateAdminCustomGrid',
  getAdminTableCustomColumn: '/api/Admin/GetAdminCustomGridId',
  importAdmin: '/api/Admin/ImportAdminDataFromCSV',
  checkDuplicateUserName: '/api/Admin/CheckDuplicateUsername'
}

export const RolesandPermission = {
  getroleList: '/api/Roles/GetAllRoleListWithPageAndSort',
  addrole: '/api/Roles',
  getroleScreen: '/api/Roles/GetRoleScreens',
  getPermission: '/api/Roles/GetAllScreensAndPermissions',
  getPermissionByRoleID: '/api/Roles/GetEditRoleAndPermissionDetailsByRoleID',
  updateRole: '/api/Roles/EditRole',
  getPermissionByUserID: '/api/Roles/GetAllRolesAndPermissionsOfUserByUserID',
  getOrganizationThemeById: '/api/OrganizationProfile/getOrganizationThemeById',
  getAllTheme: '/api/OrganizationProfile/GetAllTheme',
  updateOrganizationTheme: '/api/OrganizationProfile/UpdateOrganizationTheme',
  getRoleListDropdown: '/api/Roles/GetAllListOfRoles',
  deleteRoles: '/api/Roles/DeleteRole'
}

export const ManageAssessmentAndGradings = {
  upsert: '/api/GradeBook/Upsert',
  getGradeBook: '/api/GradeBook/GetGradeBook',
  addAssessmentCategoryAPI: '/ManageAssessment',
  getAssessmentCategoryList: '/ManageAssessment',
  deleteAssessmentCategory: '/ManageAssessment/delete',
  updateAssessmentCategory: '/ManageAssessment/Update',
  getAssessmentCategoryByID: '/ManageAssessment/GetAssessmentCategoryByID',
  AddWeightedSubjectAndCategorySetting: '/ManageAssessment/updateWeightedSubjectAndCategorySetting',
  GetWeightedSubjectAndCategorySettingByID: '/ManageAssessment/getAllWeightedSubjectAndCategorySetting',
  getGradingPeriodAPI: '/ManageAssessment/getGradingPeriod',
  UpdateGradingPeriodAPI: '/ManageAssessment/updateGradingPeriod',
  getReportCardsAndGradeBookDisplayAPI: '/ManageAssessment/getReportCardsAndGradeBookDisplay',
  updateReportCardsAndGradeBookDisplayAPI: '/ManageAssessment/updateReportCardsAndGradeBookDisplay',
  addReportCardFormatAPI: '/ManageAssessment/addReportCardFormat',
  getReportCardFormatByIdAPI: '/ManageAssessment/GetReportCardFormat',
  deleteReportCardFormatByIdAPI: '/ManageAssessment/DeleteReportCardFormat',
  getReportCardFormatList: '/ManageAssessment/GetAllReportCardFormat',
  GetAllGradeMasterDataList: '/ManageAssessment/GetAllGradeMasterData',
  GetAllGradingscaleList: '/ManageAssessment/GetAllGradingscale',
  GetAllRubicScaleList: '/api/Rubics/GetAllRubics',
  addGradingScaleAPI: '/ManageAssessment/CreateGradingScale',
  GetGradingScaleById: '/ManageAssessment/GetGradingScaleDetailsById',
  UpdateGradingScaleById: '/ManageAssessment/UpdateGradingScale',
  deleteGradingScaleAPI: '/ManageAssessment/DeleteUpdateGradingScale',
  addManageCategoryWeightsAPI: '/ManageAssessment/addCategoryWeights',

  GetAssessmentListAPI: '/ManageAssessment/GetAssessmentList',
  AddAssessmentAPI: '/ManageAssessment/addAssessment',
  sendAssessmentNotificationAPI: '/ManageAssessment/SendAssessmentNotification',
  CopyAssessmentAPI: '/ManageAssessment/copyAssessment',
  DeleteAssessmentAPI: '/ManageAssessment/DeleteAssessment',
  PublishAndUnpublishAPI: '/ManageAssessment/PublishAndUnpublish',
  GetAssessmentByIDAPI: '/ManageAssessment/GetAssessmentById',
  GetAllReportCardMasterFieldsAPI: '/ManageAssessment/GetAllReportCardFields',
  GetAllReportCardFieldsAPI: '/api/ReportCardFieldDetails/GetAllReportCardFields',
  CreateReportCardFieldDetailsAPI: '/api/ReportCardFieldDetails/CreateReportCardFieldDetails',
  GetAllReportCardTemplateAPI: '/api/ReportCardFieldDetails/GetAllReportCardTemplate',
  GetAllReportCardGroupAPI: '/api/ReportCardFieldDetails/GetAllReportCardGroup',
  GetWeightBySubjectIDClassIDSectionID: '/ManageAssessment/GetWeightBySubjectIDClassIDSectionID',
  GetAllStudentFieldAPI: '/api/ReportCardFieldDetails/GetAllStudentField',
  GetAllAttendanceFieldAPI: '/api/ReportCardFieldDetails/GetAllAttendanceField',
  GetAllStudentAPI: '/api/ReportCardFieldDetails/GetAllStudent',
  GetAllStudentIDAPI: '/api/ReportCardFieldDetails/GetAllStudentByID',
  GetStudentAssignedTemplate: '/api/ReportCardFieldDetails/GetStudentAssignedTemplate',
  GetAssignedTemplateDetails: '/api/ReportCardFieldDetails/GetAssignedTemplateDetails',
  CopySubjectWeightToOther: '/ManageAssessment/CopySubjectWeighToOthers',
  GetAssessmentDropDownByClassSectionID: '/ManageAssessment/GetAssessmentDropDownByClassSectionID',
  ReportCardEnableOrDisable: '/api/ReportCardFieldDetails/ReportCardEnableOrDisable',
  CheckReportCardEnableOrDisable: '/api/ReportCardFieldDetails/CheckReportCardEnableDisable',
  ExportGradeBookUrl: '/api/GradeBook/ExportGradeBook',
  LogGradeBookUrl: '/api/GradeBook/LogGradeBook',
  ColumnsForImport: '/api/GradeBook/ColumnsForImport',
  ImportFile: '/api/GradeBook/ImportFile',
  GetStudentGradeBookFields: '/api/GradeBook/ColumnsForImport',
  ImportExcelGradeBook: '/api/GradeBook/ImportExcelGradeBook',
  GetAllGradingScaleTypesList: '/ManageAssessment/GetAllGradingScaleTypesList',
  GetImportGradeStatusReport: '/api/GradeBook/DownloadStatusReport',

  GetReportCardDetailById: '/api/ReportCardFieldDetails/GetReportCardDetailById',
  UpdateReportCardFieldDetails: '/api/ReportCardFieldDetails/UpdateReportCardFieldDetails',
  deleteRubricAPI: '/ManageAssessment/DeleteRubric',
  SendReportCard: '/api/ReportCardFieldDetails/SendReportCard',
}

export const FinanceAndAccount = {
  createAccount: '/api/FinanceAccount/CreateAccountTableRecord',
  updateAccount: '/api/FinanceAccount/UpdateAccountTableRecord',
  getAccountList: '/api/FinanceAccount/GetAllAccountTableRecordListWithSort_Search',
  deleteAccount: '/api/FinanceAccount/DeleteAccountTableRecord',
  getAllAccount: '/api/FinanceAccount/GetAllAccountTableRecord',
  getAccountByID: '/api/FinanceAccount/GetAccountTableRecordById',
  getAccountActivityLog: '/api/FinanceAccount/GetAccountActivityLog',
  getAllChartOfAccount: '/api/FinanceAccount/GetChartOfAccounts',
  getTrailBalData: '/api/FinanceAccount/GetTrailBalData',
  getProfitLossData: '/api/FinanceAccount/GetProfitLossData',
  getBalanceSheetData: '/api/FinanceAccount/GetBalanceSheetData',
  createAccountGroup: '/api/AccountParentGroupAndGroup/CreateAccountGroup',
  getAccountGroupByID: '/api/AccountParentGroupAndGroup/GetAccountGroupById',
  updateAccountGroup: '/api/AccountParentGroupAndGroup/UpdateAccountGroup',
  deleteAccountGroup: '/api/AccountParentGroupAndGroup/DeleteAccountGroup',

  createAccountLedger: '/api/AccountLedger/CreateAccountLedger',
  getAccountLedgerByID: '/api/AccountLedger/GetAccountLedgerById',
  updateAccountLedger: '/api/AccountLedger/UpdateAccountLedger',
  deleteAccountLedger: '/api/AccountLedger/DeleteAccountLedger',
  getAllAccountLedgerByID: '/api/AccountLedger/GetAllRecordsOfAccountLedger',
  getAccountLedgerStatementByID: '/api/AccountLedger/LedgerStatement',
  LedgerReconciliation: '/api/AccountLedger/LedgerReconciliation',

  createAccountEntry: '/api/AccountEntry/CreateAccountEntry',
  getAccountEntrybyID: '/api/AccountEntry/GetAccountEntryById',
  sendEmail: '/api/AccountEntry/sendEmail',
  getAllAccountEntrybyID: '/api/AccountEntry/GetAllRecordsOfAccountEntry',
  getAllAccountEntryList: '/api/AccountEntry/GetAccountEntryList',
  deleteAccountEntry: '/api/AccountEntry/DeleteAccountEntry',

  getAllAccountEntry: '/api/AccountTag/GetAllRecordsOAccountTag',

  getAllAccountEntryType: '/api/AccountEntryType/GetAllRecordsOfAccountEntryType',
  deleteAccountEntryType: '/api/AccountEntryType/DeleteAccountEntryType',
  addEntryType: '/api/AccountEntryType/CreateAccountEntryType',
  getAccountEntryTypeById: '/api/AccountEntryType/GetAccountEntryTypeById',
  getAccountUniqueNumber: '/api/FinanceAccount/GetAccountUniqueNumber',
  updateAccountEntryType: '/api/AccountEntryType/UpdateAccountEntryType',

  updateAccountEntry: '/api/AccountEntry/UpdateAccountEntry',
  lockAccount: '/api/FinanceAccount/LockUnlockAccountByAccountID',
  dashbordDetails: '/api/FinanceAccount/GetAccountDashboardDetails'
}

export const ManageAttendance = {
  addAttendanceStatus: '/api/ManageAttendanceStatus/CreateAttendanceStatus',
  updateAttendanceStatus: '/api/ManageAttendanceStatus/UpdateAttendanceStatus',
  getAttendanceStatusById: '/api/ManageAttendanceStatus/GetAttendanceStatusById',
  getAllAttendanceStatus: '/api/ManageAttendanceStatus/GetAllRecordsOfAttendanceStatus',
  deleteAttendanceStatus: '/api/DeleteAttendanceStatus',
  deleteAttendanceStatusApi: '/api/ManageAttendanceStatus/DeleteAttendanceStatus'
}

export const AccountType = {
  Group: 1,
  Ledger: 2
}

// by chandra prakash(22 Mar, 2022) : Using for Dynamic Themes
export enum ManageTheme {
  num1 = 'assets/css/theme/default.css',
  num2 = 'assets/css/theme/mountainnights.css',
  num3 = 'assets/css/theme/roman.css',
  num4 = 'assets/css/theme/royalblue.css',
  num5 = 'assets/css/theme/shadesofgold.css',
  num6 = 'assets/css/theme/ruby.css'
}

export const Obe = {
  createPO: '/api/PODetail/CreatePODetails',
  getPOList: '/api/PODetail/GetAllRecordsOfPODetails',
  updatePO: '/api/PODetail/UpdatePODetails',
  deletePO: '/api/PODetail/DeletePODetails',
  getPObyID: '/api/PODetail/GetPODetailById/',
  addAttainmentTree: '/api/AttainmentTrees/AttainmentTrees',
  getAttainmentTreeList: '/api/AttainmentTrees/GetAllRecordsOfAttainmentTrees',
  deleteAttainmentTree: '/api/AttainmentTrees/DeleteAttainmentTrees',
  updateAttainmentTree: '/api/AttainmentTrees/UpdateAttainmentTrees',
  getAttainmenttreeBYID: '/api/AttainmentTrees/GetAttainmentTreesById/',
  getAllNode: '/api/NodeMaster/GetAllRecordsOfNodeMaster',
  getPOnodesByAttainmentId: '/api/PONode/GetAllPONodesByAttainmentTreeID',
  CreatePONodes: '/api/PONode/CreatePONodes',
  UpdatePONodes: '/api/PONode/UpdatePONodes',
  deletePONodes: '/api/PONode/DeletePONodes',
  createCO: '/CODetail/CreateCODetail',
  getAllCO: '/CODetail',
  getCObyId: '/CODetail/GetCOById',
  updateCO: '/CODetail/Update',
  deleteCO: '/CODetail/deleteByID',
  addQuestionPaper: '/api/QuestionPaper/CreateQuestionPaper',
  getAllQuestionPaper: '/api/QuestionPaper/GetAllQuestionPapers',
  getQuestionPaperById: '/api/QuestionPaper/GetQuestionPaperById/',
  updateQuestionPaper: '/api/QuestionPaper/UpdateQuestionPaper',
  deleteQuestionPaper: '/api/QuestionPaper/DeleteQuestionPaper',
  addQuestion: '/api/QuestionPaperQuestion/CreateQuestionPaperQuestion'
}

export const FeeAndInvoice = {
  createUpdateSetting: '/api/FeeAndInvoice/CreateSettings',
  getFeeSetting: '/api/FeeAndInvoice/GetSettings',
  createFeeAndInvoice: '/api/FeeAndInvoice/CreateFeeAndInvoice',
  updateFeeAndInvoice: '/api/FeeAndInvoice/UpdateFeeAndInvoice',
  getFeeAndInvoiceById: '/api/FeeAndInvoice/GetFeeandInvoiceById/',
  getInvoicepagination: '/api/FeeAndInvoice/GetAllInvoice',

  createAndUpdateInvoice: '/api/FeeAndInvoice/CreateAndUpdateInvoice',
  getInvoiceById: '/api/FeeAndInvoice/GetInvoiceByInvoiceID/',

  getAllTaxes: '/api/TaxMaster/GetAllTaxes',
  getAllDeletedTaxes: '/api/TaxMaster/GetAllDeletedTaxes',
  createMasterTax: '/api/TaxMaster/CraeteTaxMaster',
  deleteTax: '/api/TaxMaster/DeleteTax/',
  addPayment: '/api/FeeAndInvoice/AddPayment',
  updatePayment: '/api/FeeAndInvoice/UpdatePayment',
  getPaymentById: '/api/FeeAndInvoice/GetPaymentById/',
  getPaymentByInvoiceId: '/api/FeeAndInvoice/GetPaymentByInvoiceId/',
  getInvoiceEditLogsByInvoiceId: '/api/FeeAndInvoice/GetInvoiceEditLogsByInvoiceId/',
  getFeesAndInvoicepagination: '/api/FeeAndInvoice/GetAllFeeAndInvoiceswithPageSortFilter',
  deleteMultiplefeesandinvoiceById: '/api/FeeAndInvoice/FeeAndInvoiceMultipleDelete',
  archiveMultiplefeesandinvoiceById: '/api/FeeAndInvoice/FeeAndInvoiceMultipleArchive',
  activeMultiplefeesandinvoiceById: '/api/FeeAndInvoice/FeeAndInvoiceMultipleUnArchive',
  getPaymentList: '/api/FeeAndInvoice/GetAllPaymentTransaction',
  getAllFees: '/api/FeeAndInvoice',
  deleteInvoices: '/api/FeeAndInvoice/DeleteBulkinvoice',
  getDueAmount: '/api/FeeAndInvoice/GetDueAmountByInvoiceId/',
  getInvoiceStatus: '/api/FeeAndInvoice/GetAllRecordsOfInvoiceStatusMaster',
  SendInvoceEmailToStudent: '/api/FeeAndInvoice/SendInvoceEmailToStudent',
  SendInvoceSMSToStudent: '/api/FeeAndInvoice/SendInvoceSMSToStudent',
  cancelInvoice: '/api/FeeAndInvoice/BulkChangeInvoiceStatusToCancel',
  getOverAllFeeAndInvoice: '/api/FeeAndInvoice/overAllFeeAndInvoice',
  getFeeAndInvoiceForClassSetion: '/api/FeeAndInvoice/overFeeAndInvoiceForClassSection',
  getFeeAndInvoiceForIndividual: '/api/FeeAndInvoice/FeeAndInvoiceForIndividual',
  getInvoiceByIdForEmail: '/api/FeeAndInvoice/GetInvoiceByInvoiceIDForEmail/',
  getPaymentByInvoiceIdForEmail: '/api/FeeAndInvoice/GetPaymentByInvoiceIdForEmail/',
  getInvoiceEditLogsByInvoiceIdForEmail: '/api/FeeAndInvoice/GetInvoiceEditLogsByInvoiceIdForEmail/',
  getLogoInBase64: '/api/FinanceAccount/GetLogoInBase',


  feePostTransaction: '/api/FeeAndInvoice/FeeTransaction',
  createStripeSession: '/api/FeeAndInvoice/CreateStripeSession',
  paypalSubscription: '/api/FeeAndInvoice/PaypalSubscribe',
}
export const PaymentModes = {
  Live: "Live",
  Sandbox: "Sandbox"
}

export const PaymentMethod = {
  Paypal: "Paypal",
  Stripe: "Stripe"
}

export const PaymentAccountDetail = {
  GetPaymentAccountDetail: '/api/PaymentAccountDetail/GetPaymentAccountDetail?PaymentMethod=',
  CreateUpdatePaymentAccountDetail: '/api/PaymentAccountDetail/CreateUpdatePaymentAccountDetail',
  GetAllPaymentAccountDetail: '/api/PaymentAccountDetail/GetAllPaymentAccountDetail',
  UpdatePaymentAccountDetail: '/api/PaymentAccountDetail/UpdatePaymentAccountDetail',
}

export const ImportExport = {
  GetExportDataByClass: '/ImportExport/GetAllClassRecords',
  GetExportDataBySection: '/ImportExport/GetAllSectionRecords',
  GetExportDataBySubject: '/ImportExport/GetAllSubjectRecords',
}

export const Configuration = {
  smsKey: 'sms',
  emailKey: 'email',
  securesmsKey: 'securesms'
}

export const paymentgatewayKey = {
  paypalClientId: 'AUz8BJ_hGnPwfoGpGWbQ4PEW4uzHdwCzB0YOFPUKp35pN7CTcO71cH8tvmFTMu8Yp6ii0n23bcROebO2',
  paypalSecurityKey: 'ENHCEk7-XTZrLlbW_2bJIKBYVeoU8H8dZZYbCPSyprkmHddk0FQLfiCq6bdDThxv4WVZDS4tm-oNrOki',
  stripeClientId: 'pk_test_51KRYKIGmyAD9fM6FGOjMKG1IfJPcIGeth6n6K7Ly1JeApPG6kcl8HafUADeeQnogaOaI99GCJbCY4LajSiw5sjZ400LXppuP02',
  stripeSecurityKey: 'sk_test_51KRYKIGmyAD9fM6FxhX7P3aHRK2ONOmEbdlX2Nc3EifBZtNeLImtHye8qBaBI2p4mWryp6okRiegRTekPMujN6bz00bCvds0rO'
  // stripeClientId:   'pk_live_51KJFcnLCIOHCABAx5R4j1z0w8UWKbbkIGEpjsOuWbbGzX5t9WjVXLd1dlSBu0BPpEk7P0zVrc9AsnKtXHKye8SYP004Fktnz5r',
  // stripeSecurityKey: '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'
}

export const DynamicFormModule = {
  getStudentTableList: '/api/DynamicForm/GetCoreStudentList',//
  getStudentCount: '/api/DynamicForm/GetStudentCount',
  addShowHideDynamicList: '/api/DynamicForm/AddShowHideDynamicList',
  getTeacherTableList: '/api/DynamicForm/GetCoreTeacherList',
  addShowHideDynamicTeacherList: '/api/DynamicForm/AddShowHideTeacherDynamicList'
}

export const Salary = {
  GetSalaryFormFields: '/Salary/GetSalaryFormFields',
  CreateSalaryFormField: '/Salary/CreateSalaryFormField',
  GetSalaryForm: '/Salary/GetSalaryForm',
  UpdateSalaryForm: '/Salary/UpdateSalaryForm',
  UpdateAllSalaryFormFields: '/Salary/UpdateSalaryFormFields',
  GetSalaryFormByTeacherID: '/Salary/GetSalaryFormByTeacherID',
  GetSalaryCalculationsByTeacherID: '/Salary/GetTeacherSalaryCalculationsByTeacherID',
  GetAccountAndLedgerByOrgID: '/Salary/GetOrganizationDetailByOrgId',
  GetSalarySlipFormByTeacherID: '/Salary/GetSalarySlipFormByTeacherID'
}

export const PaymentDetails = {
  GetTeacherPaymentDetails: '/api/Payment/GetTeacherPaymentDetails',
  GetPaymentDetails: '/api/Payment/GetPaymentDetails',
  GetPaymentDetailsByID: '/api/Payment/GetPaymentDetailsByID',
  PayUserSalary: '/api/Payment/PayUserSalary',
  PayBulkUserSalary: '/api/Payment/PayBulkUserSalary',
  GetYearAndMonthFromPaymentDetails: '/api/Payment/GetYearAndMonthFromPaymentDetails',
}

