<div class="cardTitle centerVTItems justify-content-between">
    <h3 class="text-colored"><i class="fas fa-ruler mr-2"></i>Grading Scales</h3>
</div>
<div class="gradeSettings">
    <div class="tabularWidget">
        <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Grades">
                <!-- <p class="mb-3">No grade structure added yet. You can start adding one.</p> -->
                <p class="text-right mb-2" *ngIf="checkActionPermission('Add Grade')"><button
                        (click)="actionDialogPopup(addeditGrades)" class="btn btn-primary btn-sm" matRipple><i
                            class="fas fa-plus"></i> Add New</button></p>
                <div class="mat-elevation-z8" *ngIf="checkActionPermission('Grades List')">
                    <table mat-table [dataSource]="gradeScaleList" matSort>

                        <!-- ID Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef>Sr. No.</th>
                            <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
                            <!-- <td mat-cell *matCellDef="let element"> {{element.gradingScaleID}} </td> -->
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="gradingScaleName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.gradingScaleName}} </td>
                        </ng-container>

                        <!-- Duration Column -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Actions </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="actionsCol">
                                    <!-- <i class="fas fa-arrows-alt"></i> -->
                                    <mat-icon *ngIf="checkActionPermission('Update Grade')"
                                        (click)="UpdateGradeScale(addeditGrades,element.gradingScaleID)">edit</mat-icon>
                                    <mat-icon *ngIf="checkActionPermission('Delete Grade')"
                                        (click)="deleteGradeScale(element.gradingScaleID)">delete</mat-icon>
                                </div>
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                        </tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="6">No grade structure added yet. You can start adding one</td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
            </mat-tab>
            <mat-tab label="Rubric">
                <app-rubic-scale></app-rubic-scale>
            </mat-tab>
            <mat-tab label="Percentage">
                <p class="mb-3">This type enables entering obtained score/points and will display related percentage value for obtained score/points.</p>
            </mat-tab>
            <mat-tab label="Actual Points">
                <p class="mb-3">This type enables entering obtained score/points and will display that actual score/points.</p>
            </mat-tab>
            <mat-tab label="Manual Entry">
                <p class="mb-0">This type enables entering user-defined score, points or any word in Grade Book.</p>
                    <p class="mb-3 mt-1"><b>Note:</b> Make sure that, manual entry score is not considered in overall/final score calculation.</p>
            </mat-tab>
        </mat-tab-group>
    </div>

</div>

<ng-template #addeditGrades>
    <div class="dialog-lg">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h2 mat-dialog-title *ngIf="!updateTextInPopUp">Add Grading Scale</h2>
            <h2 *ngIf="updateTextInPopUp" mat-dialog-title>Update Grading Scale</h2>
        </div>
        <form [formGroup]="addGradingScaleForm">
            <mat-dialog-content class="mat-typography">

                <mat-form-field class="w-100 mb-4" appearance="outline">
                    <mat-label>Grading Scale</mat-label>

                    <!-- <input maxlength="100" formControlName="academicSessionID" matInput hidden> -->

                    <input maxlength="100" pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="gradingScaleName" matInput
                        placeholder="Grading Scale Name" (keydown.space)="checkWhiteSpace($event)">
                    <mat-error class="mt-3">
                        Enter valid grading scale name
                    </mat-error>
                </mat-form-field>
                <mat-checkbox (change)="IsgpaCalcualtionEvent($event)" formControlName="isgpaCalcualtion"
                    class="example-margin">Set Grade Points for GPA Calculations</mat-checkbox>

                <div class="table-mx-hght">
                    <table class="table table-borderless tax-table" formArrayName="taxItemList">
                        <thead>
                            <tr class="entity-table-header">
                                <th class="table-header-cell">Min(%)</th>
                                <th class="table-header-cell">Max(%)</th>
                                <th class="table-header-cell"></th>
                                <th class="table-header-cell" *ngIf="setGPA">Grade Points</th>
                                <th class="table-header-cell"></th>
                            </tr>
                        </thead>


                        <tbody class="tax-block-body">
                            <!--  -->
                            <ng-container *ngFor="let taxitem of taxItemArr.controls; let taxfg = index">
                                <tr class="tax-info" formGroupName="{{taxfg}}">
                                    <td class="tax-value">
                                        <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                            class="form-control" formControlName="minValue"
                                            (keydown.space)="checkWhiteSpace($event)">
                                    </td>

                                    <td class="tax-value">
                                        <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                            class="form-control" formControlName="maxValue"
                                            (keydown.space)="checkWhiteSpace($event)">
                                        <!-- (ngModelChange)="onChange(taxitem)"
                                            
                                            <mat-error *ngIf="err">
                                            Max value can't be less than Min value
                                          </mat-error> -->
                                    </td>

                                    <td class="tax-value">
                                        <input type="text" maxlength="1" class="form-control"
                                            formControlName="denotedAlpha" pattern="^[^\s]+(\s+[^\s]+)*$"
                                            (keydown.space)="checkWhiteSpace($event)">
                                    </td>

                                    <td class="tax-value" *ngIf="setGPA">
                                        <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                            class="form-control" formControlName="gradingPoint"
                                            (keydown.space)="checkWhiteSpace($event)">
                                    </td>

                                    <td class="tax-value" style="width:150px;">
                                        <input type="color" maxlength="10" class="form-control"
                                            formControlName="colorCode" (keydown.space)="checkWhiteSpace($event)">
                                    </td>

                                    <td (click)="removeTax(taxitem)" style="vertical-align: middle;"><i
                                            class="fas fa-trash" aria-hidden="true"></i></td>



                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="add-grade">
                    <button class="text-colored mt-2 adbtns" (click)="addTax()">Add +</button>
                </div>
                <div class="btnsWidget form-group17 text-right">
                    <button (click)="onSubmit()" type="button" [disabled]="addGradingScaleForm.invalid"
                        class="btn btn-primary btn-sm">Save</button>
                    <button type="button" (reset)="resetForm()" class="btn btn-reset btn-sm"
                        mat-dialog-close>Cancel</button>
                </div>
                <!-- <div class="manageGradeList my-4">
                   
                    <table mat-table [dataSource]="gradeMasterData">
                        <ng-container matColumnDef="min">
                            <th mat-header-cell *matHeaderCellDef> Min(%) </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="text" value="{{element.minValue}}"
                                    class="form-control customControl center" (keydown.space)="checkWhiteSpace($event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="max">
                            <th mat-header-cell *matHeaderCellDef> Max(%) </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="text" value="{{element.maxValue}}"
                                    class="form-control customControl center" (keydown.space)="checkWhiteSpace($event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="grade">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <input type="text" value="{{element.denotedAlpha}}"
                                    class="form-control customControl center" (keydown.space)="checkWhiteSpace($event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="gradePoints">
                            <th mat-header-cell *matHeaderCellDef>
                                <span *ngIf="setGPA">Grade Points</span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="text" *ngIf="setGPA" value="10" class="form-control customControl center"
                                    (keydown.space)="checkWhiteSpace($event)">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="color">
                            <th mat-header-cell *matHeaderCellDef></th>
                            
                            <td mat-cell *matCellDef="let element">
                                <input type="color" value="{{element.colorCode}"
                                class="form-control customControl center colorFeild">
                            </td>
                        </ng-container>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="9999">
                            No grade scale found
                            </td>
                          </tr>

                        <tr mat-header-row *matHeaderRowDef="gradingScaleDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: gradingScaleDisplayedColumns;"></tr>

                    </table>
                    <div class="text-right">
                    <button class="text-right text-colored mr-2 mt-2 adbtns">Add +</button>
                    </div>
                </div> -->
                <!-- 
                <div class="btnsWidget form-group17 text-right">
                    <button (click)="onSubmit()" type="button" [disabled]="addGradingScaleForm.invalid"
                        class="btn btn-primary btn-sm" mat-dialog-close matRipple>Save</button>
                    <button type="button" (reset)="resetForm()" class="btn btn-reset btn-sm" mat-dialog-close matRipple>Cancel</button>
                </div> -->
            </mat-dialog-content>
        </form>
    </div>
</ng-template>