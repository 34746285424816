import { AfterViewInit, Component, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';
import { HtmlEditorService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-template-one',
  templateUrl: './template-one.component.html',
  styleUrls: ['./template-one.component.css'],
  providers: [ToolbarService, HtmlEditorService]
})
export class TemplateOneComponent implements OnInit {
  @Input() reportCardItem: any; 
  
  @ViewChild('titleCommentEditor', {}) titleObj: RichTextEditorComponent;
  @ViewChild('contentCommentEditor', {}) contentObj: RichTextEditorComponent;
  @ViewChild('signatureCommentEditor', {}) signatureObj: RichTextEditorComponent;

  reportCardMasterFields: any;
  templateID: any;
  setGPA: any;
  logo: any;
  instituteName: any;
  instituteAddress: any;
  studentCusChk: any[];
  toppings = new FormControl('');
  email: any;
  comments: any;
  commentPosition = 1;
  ShowFilter = true;
  toppingList: any;
  instituteGroup: any;
  studentGroup: any;
  attendanceGroup: any;
  assessmentsGroup: any;
  othersGroup: any;
  studentCustomGroup: any;
  attendanceCustomGroup: any;
  fields: any = [];
  classSection: any = [];
  customCommntArr: any = [];
  orgID: any;
  users: any;
  user: any;
  academicSessionID: any;
  croppedImage: any;


  public editorModel: any = {
    customCommentTitle: "Custom title",
    customCommentValue: "<p>You can set any custom title here ...</p>",
    contentCommentTitle: "Contents Block",
    contentCommentValue: "<p>You can set any custom contents here ...</p>",
    signatureCommentTitle: "Signature Block",
    signatureCommentValue: "<p>You can set signature text here as you want ...</p>"
  };

  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'CreateTable', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'ClearFormat', 'Print', 'SourceCode', 'InsertCode', '|',
      'Undo', 'Redo', '|',
      'FullScreen', 'Preview'
      ]
  };
  
  constructor(
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private masterService: MasterService
  ) {
      if(localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
        this.croppedImage = localStorage.getItem('Logo');
      }else {
        this.croppedImage = '../../../../../../assets/img/admin/org_logo.png';
      }

      this.users = localStorage.getItem('currentUser');
      var currentUser = JSON.parse(this.users);
      this.academicSessionID = currentUser.academicSessionID
      if (currentUser != null) {
        this.user = currentUser;
      }
  }

  ngOnInit(): void {
    this.templateID = this.reportCardItem.value.Template;
    this.getFormat(this.templateID);
    this.getStudentCustomField();
    this.getAttendanceCustomField();
  }


  onSubmit(){
    if(this.reportCardItem.invalid) {
      if(this.reportCardItem.controls.selectedClassSectionID.errors?.required) {
        this.toaster.error('Please Select Class-Section');
      }
      if(this.reportCardItem.controls.Name.errors?.required) {
        this.toaster.error('Name cannot be empty');
      }
      if(this.reportCardItem.controls.Name.errors?.pattern){
        this.toaster.error('Enter a valid Name');
      }
    } else {
      let data = {
        ReportCardFormatName: this.reportCardItem.value.Name,
        academicSessionID: this.academicSessionID,
        ReportCardTemplateID: this.templateID,
        ClassSection: this.reportCardItem.value.selectedClassSectionID,
        Fields: this.reportCardMasterFields,
        CustomFields: this.studentCustomGroup,
        CommentFields: this.editorModel,
        ExtraFieldValue: this.commentPosition.toString(),
        AttendanceGroup: this.attendanceCustomGroup
      }
      this.manageAssessmentService.CreateReportCardFieldDetails(data).subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
          // this.router.navigate(["admin/"]);
        } else {
          this.toaster.error(res.message, 'Error');
        }
      })
    }
    
  }

  reset() {
    this.reportCardItem.reset();
  }

  addComment(templateRef: TemplateRef<any>){
    this.dialog.open(templateRef);
  }

  getFormat(Id: number) {
    this.manageAssessmentService.GetAllReportCardFields(Id).subscribe((res: any) => {
      this.reportCardMasterFields = res.responseData;
      this.instituteGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 1);
      this.studentGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 2);
      this.attendanceGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 3);
      this.assessmentsGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 4);
      this.othersGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 5);
    });
  }

  getStudentCustomField() {
    this.manageAssessmentService.GetAllStudentField().subscribe((res: any) => {
      this.studentCustomGroup = res.responseData.filter((x: any) => x.fieldID != 1 && x.fieldID != 2 && x.fieldID != 3 && x.fieldID != 9 && x.fieldID != 10 && x.fieldID != 11);
      this.studentCustomGroup.forEach((element: any) => {
        element.checked = false;
      });
      this.toppingList = this.studentCustomGroup;
    });
  }

  getAttendanceCustomField() {
    this.manageAssessmentService.GetAllAttendanceField().subscribe((res: any) => {
      this.attendanceCustomGroup = res.responseData;
      this.attendanceCustomGroup.forEach((element: any) => {
        if(element.attendanceStatusMasterID == 1 || element.attendanceStatusMasterID == 2)
          element.checked = true;
        else
          element.checked = false;
      });
    });
  }

  changeStudentField(event: any) {
    this.studentCustomGroup.forEach((element: any) => {
      let idx = this.studentCusChk.indexOf(element);
      if(idx != -1) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  itemOnChangeInstitute(idx: any) {
    this.instituteGroup[idx].checked = !this.instituteGroup[idx].checked;
  }
  itemOnChangeStudent(idx: any) {
    this.studentGroup[idx].checked = !this.studentGroup[idx].checked;
    if(this.studentGroup[idx].fieldName == 'Custom fields') {
      this.toppings.setValue('');
      this.studentCustomGroup.forEach((element: any) => {
        element.checked = false;
      });
    }
  }
  itemOnChangeAttendance(idx: any) {
    this.attendanceGroup[idx].checked = !this.attendanceGroup[idx].checked;
  }
  itemOnChangeAttendanceCustom(idx: any) {
    this.attendanceCustomGroup[idx].checked = !this.attendanceCustomGroup[idx].checked;
  }
  
  itemOnChangeAssessments(idx: any) {
    this.assessmentsGroup[idx].checked = !this.assessmentsGroup[idx].checked;
  }
  itemOnChangeOthers(idx: any) {
    this.othersGroup[idx].checked = !this.othersGroup[idx].checked;
  }


  addCustomTitleComment() {
    let question = this.titleObj.value;
    this.editorModel.customCommentValue = question;
    let name = document.querySelector<HTMLInputElement>('input[name="blockTitle"]')?.value;
    this.editorModel.customCommentTitle = name;
  }

  addContentComment() {
    let question = this.contentObj.value;
    this.editorModel.contentCommentValue = question;
    let name = document.querySelector<HTMLInputElement>('input[name="blockContent"]')?.value;
    this.editorModel.contentCommentTitle = name;
  }

  addSignatureComment() {
    let question = this.signatureObj.value;
    this.editorModel.signatureCommentValue = question;
    let name = document.querySelector<HTMLInputElement>('input[name="blockSignature"]')?.value;
    this.editorModel.signatureCommentTitle = name;
  }
}
