import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, Master, masters, profile, SuperAccount, Teacher, UserProfile } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private http: HttpClient,) { }


  GetCountryDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getCountryDropdown}`);
  }

  GetBloodGroupDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getBloodGroupDropdown}`);
  }

  GetStateDropdown(CountryID: any) {
    return this.http.get(`${environment.baseUrl}${masters.getStateDropdown}` + '?CountryID=' + CountryID);
  }
  GetGenderDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getGenderDropdown}`);
  }
  GetClassDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getClassDropdown}`);
  }
  CurrentPlanStatus() {
    return this.http.get(`${environment.baseUrl}${masters.GetCurrentPlanStatus}`);
  }
  GetClassSectionDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getClassSectionDropdown}`);
  }
  GetSubjectDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getSubjectDropdown}`);
  }
  GetSectionDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getSectionDropdown}`);
  }
  GetStudentListDropdown(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.getStudentListDropdown}`, data);
  }
  GetAcademicSessionDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getAcademicSessionDropdown}`);
  }
  GetAcademicSessionDropdownByStudentID(id: any) {
    return this.http.get(`${environment.baseUrl}${masters.GetAcademicSessionByStudentID}` + '?id=' + id);
  }
  GetStudentSkillsDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getStudentSkillsDropdown}`);
  }
  GetTeacherSkillsDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getTeacherSkillsDropdown}`);
  }
  GetEmployeeTypeDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getEmployeeTypeDropdown}`);
  }
  GetProfileDetails(Id: any) {
    return this.http.get(`${environment.baseUrl}${profile.getProfileData}${Id}`);
  }
  GetElectiveSubjectDropDown(id: any) {
    return this.http.get(`${environment.baseUrl}${masters.getGetElectiveSubjectDropdownBySectionIdAPI}` + '?sectionID=' + id);
  }
  GetShiftCategoryDropDown() {
    return this.http.get(`${environment.baseUrl}${masters.getShiftCategoryDropdown}`);
  }
  GetShiftStatusDropDown() {
    return this.http.get(`${environment.baseUrl}${masters.getShiftStatusDropdown}`);

  }

  GetProfileData(id: any) {
    return this.http.get(`${environment.baseUrl}${profile.getAdminProfileData}` + '?id=' + id);
  }
  UpdateProfileData(data: any) {
    return this.http.post(`${environment.baseUrl}${profile.updateAdminProfileData}`, data);
  }


  GetLoginUserProfileData(id: any) {
    return this.http.get(`${environment.baseUrl}${profile.getloginUserProfile}` + '?id=' + id);
  }
  UpdateLoginUserProfileData(data: any) {
    return this.http.post(`${environment.baseUrl}${profile.updateLoginProfileData}`, data);
  }
  AddclassSheduleTimetable(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.addclassSheduleTimetable}`, data);
  }
  UpdateclassSheduleTimetable(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.updateclassSheduleTimetable}`, data);
  }
  DeleteclassSheduleTimetable(id: any) {
    return this.http.get(`${environment.baseUrl}${masters.deleteclassSheduleTimetable}` + '?id=' + id);
  }
  AddtimeTableCategories(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.addtimeTableCategories}`, data);
  }
  AddCustomFormTemplate(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.addCustomFormTemplate}`, data)
  }
  GetCustomFormTemplate(Id: any) {
    return this.http.get(`${environment.baseUrl}${masters.getCustomeForm}${Id}`);
  }
  UpdateCustomFormTemplate(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.editCustomForm}`, data)
  }

  getParentGroupDropdown(Id: any) {
    return this.http.get(`${environment.baseUrl}${masters.getParentGroupDropdown}${Id}`);
  }
  getAllDays() {
    return this.http.get(`${environment.baseUrl}${masters.getAllDays}`)
  }
  subjectAllocationAPI(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.subjectAllocationAPI}`, data)
  }
  public handleError = (error: Response) => {
    // Do messaging and error handling here
    //this.errorhandler.handleError(error.status);
    return observableThrowError(error);
  };

  private getUrl(url: string): string {
    return environment.baseUrl + url;
  }
  postDataAboutImg<T>(data: {}): Observable<any> {
    let url = profile.getProfilePhoto;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  AddEmailTemplate(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.addEmailTemplate}`, data);
  }
  getAllEmailTemplate() {
    return this.http.get(`${environment.baseUrl}${masters.getAllEmailTemplate}`)
  }

  UpdateEmailTemplate(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.updateEmailTemplate}`, data);
  }

  getEmailTemplateById(id: any) {
    return this.http.get(`${environment.baseUrl}${masters.getEmailTemplateById}` + '?id=' + id);
  }
  deleteCustomEmailTemplate(Id: any) {
    return this.http.get(`${environment.baseUrl}${masters.deleteCustomEmailTemplate}${'?id=' + Id}`);
  }
  CreateConfiguration(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.CreateConfig}`, data);
  }
  UpdateConfiguration(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.UpdateConfig}`, data);
  }
  getConfiguration(data: any) {
    return this.http.get(`${environment.baseUrl}${masters.getConfig}` + data);
  }

  GetOrganizationById(Id: any) {
    return this.http.get(`${environment.baseUrl}${Master.GetOrganizationDetailById}${'?id=' + Id}`);
  }

  GetUserById(Id: any) {
    return this.http.get(`${environment.baseUrl}${UserProfile.GetUserDetailById}${'?id=' + Id}`);
  }

  updateUserProfile(data: any) {
    return this.http.post(`${environment.baseUrl}${UserProfile.UpdateUserProfile}`, data);
  }

  updateOrganization(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.UpdateOrganizationDetailById}`, data);
  }


  UploadUserProfile<T>(data: {}): Observable<any> {
    let url = UserProfile.UploadUserProfile;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  postData<T>(data: {}): Observable<any> {
    let url = Master.UploadOrganizationLogo;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  uploadFavIcon<T>(data: {}): Observable<any> {
    let url = Master.UploadOrganizationFavIcon;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  UploadOrganizationBackgroundImage<T>(data: {}): Observable<any> {
    let url = Master.UploadOrganizationBackgroundImage;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  UploadOrganizationVideo<T>(data: {}): Observable<any> {
    let url = Master.UploadOrganizationVideo;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  DeleteUploadedVideo(Id: any) {
    return this.http.get(`${environment.baseUrl}${Master.DeleteVideo}${'?id=' + Id}`);
  }

  GetOrganizationDetailBySubdomain(subDomainName: any) {
    return this.http.get(`${environment.baseUrl}${Master.GetOrganizationDetailBySubdomain}${'?subDomainName=' + subDomainName}`);
  }

  ChangePassword(data: any) {
    return this.http.post(`${environment.baseUrl}${SuperAccount.resetPasswordAPI}`, data);
  }

  CheckAdminEmail(data: any) {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://creocms.com.au/',
    };
    return this.http.post(`${environment.baseUrl}${SuperAccount.CheckUserEmailAPI}`, data, { headers });
  }

  ForgotPassword(data: any) {
    return this.http.post(`${environment.baseUrl}${SuperAccount.ForgotAdminPasswordAPI}`, data);
  }
  ModuleDropDown() {
    return this.http.get(`${environment.baseUrl}${Master.moduleNameDropDown}`);
  }
  UserDropDown() {
    return this.http.get(`${environment.baseUrl}${Master.userNameDropDown}`);
  }
  ActivityLog(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.ActivityLog}`, data);
  }

  ActivityLogForNotification() {
    return this.http.get(`${environment.baseUrl}${Master.ActivityLogForNotification}`);
  }

  updateNotificationIsShown(activityId: any) {
    return this.http.post(`${environment.baseUrl}${Master.updateNotificationIsShown}`, activityId);
  }
  getScreenName(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.ScreenName}`, data);
  }

  getStudentLoginActivityReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStudentLoginActivity}`, data);
  }
  getIncompleteContact(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllIncompleteContactDetail}`, data);
  }

  getstudentContactDetail(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStudentContactDetail}`, data);
  }
  getAcademicsDataReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllAcademicsDataReport}`, data);
  }

  getAllStudentSubjectAllocationReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStudentSubjectAllocationReport}`, data);
  }
  getAllStudentBirthdayReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStudentbirthdayReport}`, data);
  }
  getAllStudentAgeReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStudentAgeReport}`, data);
  }
  getAllStudentAlumniReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStudentAlumniReport}`, data);
  }
  getAllStudentGenderReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStudentsGenderReport}`, data);
  }
  getAllActivityReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllActivityReport}`, data);
  }
  getfilteredStudentSubjectAllocationReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getfilteredStudentSubjectAllocationReport}`, data);
  }
  getstudentCustomBirthdayReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getstudentCustomBirthdayReport}`, data);
  }
  getstudentIncompleteContactByFilterReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getstudentIncompleteContactByFilterReport}`, data);
  }
  getstudentContactByFilterReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getstudentContactByFilterReport}`, data);
  }
  getTeacherContactReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getTeacherContactReport}`, data);
  }
  getTeacherLoginActivityReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getTeacherLoginActivityReport}`, data);
  }
  getTeacherSubjectAllocationReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getTeacherSubjectAllocationReport}`, data);
  }
  getParentLoginActivityReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getParentLoginActivityReport}`, data);
  }
  getParentLoginActivityByfilterReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getParentLoginActivityByfilterReport}`, data);
  }
  getParentInfoReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getParentInfoReport}`, data);
  }
  getParentInfoByfilterReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getParentInfoByfilterReport}`, data);
  }
  getSiblingsReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getSiblingsReport}`, data);
  }
  getStudentsAverageAttendanceReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.StudentsAverageAttendanceReport}`, data);
  }
  getClassAverageAttendanceCustomReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.ClassAverageAttendanceCustomReport}`, data);
  }
  getStudentsAbsentDaysReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getStudentsAbsentDaysReport}`, data);
  }
  getDailyAttendanceReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getDailyAttendanceReport}`, data);
  }
  getAttendanceRawReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAttendanceRawReport}`, data);
  }
  GetActivityLogTrack(activityLog: any) {
    return this.http.post(`${environment.baseUrl}${Master.GetActivityLogTrack}`, activityLog);
  }
  FeesPaymenStatisticsDefaultReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.FeesPaymenStatisticsDefaultReport}`, data);
  }
  StudentsBasedFeeReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.StudentsBasedFeeReport}`, data);
  }
  PaymentsSummaryReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.PaymentsSummaryReport}`, data);
  }
  ClassSectionFinalScoresReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.ClassSectionFinalScoresReport}`, data);
  }
  AssessmentScoresReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.AssessmentScoresReport}`, data);
  }
  getClassSectionScoreReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getClassSectionScoreReport}`, data);
  }
  getAllStatisticsForAcademic(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStatisticsForAcademic}`, data);
  }
  getStudentVsClassSectionForAcademic(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getStudentVsClassSectionForAcademic}`, data);
  }
  getMaleFemaleTeacherForAcademic(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getMaleFemaleTeacherForAcademic}`, data);
  }
  getAllStatisticsForFee(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStatisticsForFee}`, data);
  }
  getAllAmountVsFeesForFee(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllAmountVsFeesForFee}`, data);
  }
  getAllAmountVsMonthsForFee(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllAmountVsMonthsForFee}`, data);
  }
  getAllStatisticsForAttendance(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllStatisticsForAttendance}`, data);
  }
  getAttendanceByDates(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAttendancebyDates}`, data);
  }
  getAllTeacherStudentLinkReport(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.getAllTeacherStudentLinkReport}`, data);
  }
  getLedgerDropdownByAccountID(AccountID: any) {
    return this.http.get(`${environment.baseUrl}${Master.getLedgerDropdownByAccountID}` + '?AccountID=' + AccountID);
  }
  GetCurrencyDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getCurrencyDropdown}`);
  }
  GetOrganizationDetailBySubdomainForEmail(subDomainName: any) {
    return this.http.get(`${environment.baseUrl}${Master.GetOrganizationDetailBySubdomainForEmail}${'?subDomainName=' + subDomainName}`);
  }
  GetStudentBirthday(date: any) {
    return this.http.get(`${environment.baseUrl}${masters.getStudentBirthday}` + '?Date=' + date);
  }
}
