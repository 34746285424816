<div class="reportCardsWidget mt-3 mt-md-0">
    <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex btnsWidget reportButtons">
        </div>
    </div>

    <div class="row-flex">
        <div class="col-md-4 col-xl-3 s2">
            <div class="manageCardLeft">
                <div class="template-group-title my-2">Report Card Settings</div>
                <div *ngIf="instituteGroup != 0" class="sub-head">Institute</div>
                <div class="list">
                    <div *ngFor="let item of instituteGroup; let i = index">
                        <div *ngIf="item.fieldName == 'Custom title'" class="withcmts">
                            <mat-checkbox  (change)="itemOnChangeInstitute(i)" [checked]="item.checked"
                                class="example-margin matbox">Custom title
                            </mat-checkbox>
                            <a class="template-group-title text-underline addcomment">Add Comments</a>
                        </div>
                        <mat-checkbox *ngIf="item.fieldName != 'Custom title'" (change)="itemOnChangeInstitute(i)" [checked]="item.checked" class="example-margin matbox">
                            {{ item.fieldName }}
                        </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="studentGroup != 0" class="sub-head">Student</div>
                <div class="list">
                    <mat-checkbox  *ngFor="let item of studentGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeStudent(i)">{{ item.fieldName }}</mat-checkbox>
                </div>
                <div *ngIf="assessmentsGroup != 0" class="sub-head">Assessment</div>
                <div class="list">
                    <div *ngFor="let item of assessmentsGroup; let i = index">
                        <mat-checkbox class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAssessments(i)">{{ item.fieldName }}</mat-checkbox>
                    </div>
                </div>
                <div *ngIf="attendanceGroup.length != 0" class="sub-head">Attendance</div>
                <div class="list">
                    <mat-checkbox *ngFor="let item of attendanceGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAttendance(i)">{{ item.fieldName }}</mat-checkbox>
                </div>
                <div *ngIf="othersGroup != 0" class="sub-head">Others</div>
                <div class="list">
                    <div *ngFor="let item of othersGroup; let i = index">
                        <mat-checkbox  [checked]="item.checked" (change)="itemOnChangeOthers(i)" class="example-margin matbox">{{ item.fieldName }} </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class=" col-md-8 col-xl-9 s10">

            <div class="report-card">
                <div class="report-custom">
                    <!-- Report card header block -->
                    <div class="report-card-header-block">
                        <div class="row">
                            <div class="col-md-6 extra-header-info" style="display: flex;align-items: center;">
                                <img *ngIf="instituteGroup | customRepotCard: 'Logo'" width="80px" [src]="croppedImage"
                                    class="inst-image" alt="Inst. Logo" />
                                <div *ngIf="instituteGroup | customRepotCard: 'Institute name'" class="institutename ml-2">
                                    Institute Name
                                </div>
                            </div>
                            <div class="col-md-6 inst-info-block">

                                <div class="text-right inst-extra-info">
                                    <div class="inst-name"><b>PREPARATORY SCHOOL</b></div>
                                    <div *ngIf="instituteGroup | customRepotCard: 'Contact'" class="contact"><i class="fas fa-phone-alt"></i> 051-3751001-3</div>
                                    <div *ngIf="instituteGroup | customRepotCard: 'Email'" class="contact"><i class="fas fa-envelope"></i> email of organization</div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>

                    <div class="reportcardtitile">
                        <div class="text-center">
                            <div class="reportcard headings">
                                <b>RESULT REPORT</b>
                            </div>
                            <div class="reportcard headings">
                                <b>ANNUAL EXAMINATION-2021</b>
                            </div>
                        </div>
                    </div>

                    <div class="basicDetails">
                        <div class="row">
                            <div class="col-4 text-left">
                                <b>P.C. NO:PK-1957</b>
                            </div>
                            <div class="col-4 text-center">
                                <span *ngIf="studentGroup | customRepotCard: 'Name'"><b>NAME:SYED NAWAZISH ALI</b></span>
                            </div>
                            <div class="col-4 text-right">
                                <b><span *ngIf="studentGroup | customRepotCard: 'Class'">CLASS:VII-</span><span *ngIf="studentGroup | customRepotCard: 'Academic session name'">2021</span><span *ngIf="studentGroup | customRepotCard: 'Section'">(A)</span></b>
                            </div>
                        </div>
                    </div>
                    <div class="report-card-assessment-score-block pt-0">
                        <div class="temp2-scores-block">
                            <div class="cat-view-options">
                                <table class="table table-bordered  table-vertical-center score-table">
                                    <thead>
                                        <tr>
                                            <th class="text-left text-dark" colspan="1">Subjects</th>
                                            <th class="center text-dark" colspan="2">Second Term 2021<br>100</th>
                                            <th class="center text-dark" colspan="3">Annual<br>100</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>English</b></div>
                                            <span>Nawazish is a very confident student. He has shown improvement in his comprehension skills, but needsto be more vigilant about his careless mistakes.</span></th>
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">61</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">62</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Mathamatics</b></div>
                                            <span>Nawazish is a very confident student. He has shown improvement in his comprehension skills, but needsto be more vigilant about his careless mistakes.</span></th>
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">61</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">62</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Genral Science</b></div>
                                            <span>Nawazish is a very confident student. He has shown improvement in his comprehension skills, but needsto be more vigilant about his careless mistakes.</span></th>
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">61</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">62</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Obtained Marks:</b></div></th> 
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">61</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">62</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Total Marks:</b></div></th> 
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">700</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">700</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Percentage</b></div></th> 
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">70%</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">68%</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>No. of Failing Subjects:</b></div></th> 
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">1</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">0</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Position In Section:</b></div></th> 
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">22/33</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">29/33</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Position In Class:</b></div></th> 
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">40/72</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">53/72</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Grade</b></div></th> 
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">Average</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">Average</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="reportcard-extra-block page-break-avoid">
                        <div class="row">
                            <div class="col-md-4 overall-score-grading-block">
                            </div>
                            <div class="col-md-8 attendance-checter-block">
                                <div class="report-other-info-block mt-3">
                                    <table *ngIf="othersGroup | customRepotCard: 'Grading structure for final score'" class="table table-bordered  table-vertical-center scores-table character-traits-table">
                                        <thead>
                                            <tr>
                                                <th>Personal Characteristics</th>
                                                <th class="center">Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Personal Turn Out</th>
                                                <th class="center">Good</th>
                                            </tr>
                                            <tr>
                                                <th>Spoken English</th>
                                                <th class="center">Satisfactory</th>
                                            </tr>
                                            <tr>
                                                <th>Interest in Curricular/Co-curricular Activities</th>
                                                <th class="center">Satisfactory</th>
                                            </tr>
                                            <tr>
                                                <th>Completion of Assignment</th>
                                                <th class="center">Satisfactory</th>
                                            </tr>
                                            <tr>
                                                <th>Personal Hygiene</th>
                                                <th class="center">Good</th>
                                            </tr>
                                            <tr>
                                                <th>Punctuality</th>
                                                <th class="center">Good</th>
                                            </tr>
                                            <tr>
                                                <th>Self-Confidence</th>
                                                <th class="center">Satisfactory</th>
                                            </tr>
                                            <tr>
                                                <th>Group Co-operation</th>
                                                <th class="center">Good</th>
                                            </tr>
                                            <tr>
                                                <th>Respect for the College Rules</th>
                                                <th class="center">Excellent</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- Other info table -->
                            </div>

                        </div>

                    </div>
                    <div class="semesterVise fulltable">
                        <h5 class="template-group-title">Housemaster's Remarks:</h5>
                        <p class="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus neque dui, ac venenatis ex viverra eget. Fusce congue tellus lectus, ut gravida mi euismod nec.</p>
                    </div>
                    <div class="semesterVise fulltable">
                        <h5 class="template-group-title">Headmaster's Remarks:</h5>
                        <p class="mb-2">Needs to improve his concentration in General Science.</p>
                    </div>
                    <div class="text-center mt-5">
                        <h5 class="infoText">This is an electronically generated report, hence does not require a signature</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="btnsWidget form-group17 text-right">
    <button type="button" (click)="onSubmit()" class="btn btn-primary btn-sm m-0 ">Save</button>
    <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Cancel</button>
</div> 