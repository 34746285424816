import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
// import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { CommonService } from 'src/app/common/core-services/common.service';
import { SubjectService } from '../../courses-and-subjects/services/subjectServices/subject.service';
import { StudentService } from 'src/app/services/student.service';
import { SectionService } from '../../courses-and-subjects/services/sectionServices/section.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/services/master.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { SelectItem, PrimeNGConfig } from "primeng/api";
import { MultiSelect } from "primeng/multiselect";
export interface Class {
  classID:string
  className: string;
}
export interface Section {
  sectionID:string
  sectionName: string;
}
@Component({
  selector: 'app-subjects-allocation',
  templateUrl: './subjects-allocation.component.html',
  styleUrls: ['./subjects-allocation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SubjectsAllocationComponent implements OnInit {
  @ViewChild(MultiSelect) ms: MultiSelect;

  constructor(
    private classDropdownService: ClassDropdownService,
    private commonService: CommonService,
    private subjectService: SubjectService,
    private studentService: StudentService,
    private sectionService: SectionService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private masterService: MasterService,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private primengConfig: PrimeNGConfig
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
    if (currentUser != null) {
      this.user = currentUser;
    }
   }
  academicSessionID:any;
  teacherList: any[];
  selectedTeachers: string[] = [];
  teachers: any[] = [];
  classList: Class[] = [];
  sectionListDropdown: Section[]=[];
  subjectAllocationForm: FormGroup;
  form: FormGroup
  subjectAllocationArrayList: FormArray;
  showTable: boolean;
  disabled = false;
  ShowFilter = true;
  limitSelection = false;
  cities: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: any = {};
  subjectdata: any[] = [];
  classControl = new FormControl();
  sectionCOntrol = new FormControl();
  users: any;
  user: any;
  permissions: any;
  filteredOptions: Observable<Class[]>;
  sectionfilteredOptions: Observable<Section[]>
  classFlag: boolean;
  sectionFlag: boolean;
  displayFn(classData: Class): string {
    return classData && classData.className ? classData.className : '';
  }
  sectiondisplayFn(section: Section): string {
    return section && section.sectionName ? section.sectionName : '';
  }
  private _filter(name: string): Class[] {
    const filterValue = name.toLowerCase();
    let data = this.classList.filter(option => option.className.toLowerCase().includes(filterValue));
    if (data.length == 0) {
      this.classFlag = true;
    }
    else {
      this.classFlag = false;
    }
    return this.classList.filter(option => option.className.toLowerCase().includes(filterValue));
  }
  private Sectionfilter(name: string): Section[] {
    const filterValue = name.toLowerCase();
    let data = this.sectionListDropdown.filter(option => option.sectionName.toLowerCase().includes(filterValue));
    if (data.length == 0) {
      this.sectionFlag = true;
    }
    else {
      this.sectionFlag = false;
    }
    return this.sectionListDropdown.filter(option => option.sectionName.toLowerCase().includes(filterValue));
  }
  ngOnInit(): void {
    //Legasov Added 25 March 2022
this.permissionService.checkComponentHasScreenPermission('Subject Allocations');
    this.primengConfig.ripple = true;
    this.permissions = this.permissionService.getAllPermissions();
    if(this.permissions.length == 0){
     this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
       if(res.status> 0){
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
       }
     })
    }

    
    this.filteredOptions = this.classControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.className)),
      map(className => (className ? this._filter(className) : this.classList.slice())),
    );
    this.sectionfilteredOptions = this.sectionCOntrol.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.sectionName)),
      map(sectionName => (sectionName ? this.Sectionfilter(sectionName) : this.sectionListDropdown.slice())),
    );
    this.form = this.formBuilder.group({
      classId: [''],
      sectionID: ['']
    })
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'teacherID',
      textField: 'teacherName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter,
      classes:"myclass custom-class",
    };
    this.subjectAllocationForm = this.formBuilder.group({
      subjectAllocationList: this.formBuilder.array([])
    })
    this.subjectAllocationArrayList = this.subjectAllocationForm.get('subjectAllocationList') as FormArray;
    this.getMasterData();
  }
  get subjectArr() {
    return this.subjectAllocationForm.get('subjectAllocationList') as FormArray;
  }

  activityLog:any;
  ActivityLogTrack()
  {
    
    this.activityLog={
      ActivityLogTrack:{
              ModuleName: 'Academic & Learning',
              SubModuleName: 'Teachers & Staff/Admin',
              ActivityTypeId: 10,
              Description: 'Teacher Fields record viewed by',
              ScreenName: 'Subject Allocations',
              RecordTypeId: 22,  
              RecordId: 0,      
              Type: 0
            }
    }
    
    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response:any)=>{
      if(response!=null && response !=undefined && response !=''){
        let responseData=response.responseData;
        console.log("responseData:",responseData);
      }


    });
  }



  onPrimarySelect(e: any, item: any) {
    this.subjectAllocationArrayList.controls[e].patchValue({"primaryTeacher": item});
  }
  onSecondarySelect(e: any, item: any) {
    this.subjectAllocationArrayList.controls[e].patchValue({"secondaryTeacher": item});
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }
  checkActionPermission(actionName: any){
    let screen = 'Subject Allocations';
    return this.permissionService.checkScreenActionPermission(actionName, screen);
}

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }
  getsubjectObj:any;
  getSubject() {
    this.ActivityLogTrack();
    if(this.classControl.value == null || this.sectionCOntrol.value == null){
      this.toaster.error("Some required data missing.")
      return;
    }
    if(this.classControl.value == '' || this.sectionCOntrol.value == ''){
      this.toaster.error("Some required data missing.")
      return;
    }
    this.subjectdata = [];
    this.subjectAllocationArrayList.clear();
    this.getsubjectObj = {
      "classID": this.classControl.value.classID,
      "sectionID": this.sectionCOntrol.value.sectionID,
      "academicSessionID": this.academicSessionID
    }
    console.log("GETSUBJECT",this.getsubjectObj)
    this.classDropdownService.getSubjectByClassandSectionId(this.getsubjectObj).subscribe((res: any) => {
      this.subjectdata = res.responseData.returnSubjectTeachersList;
      if (this.subjectdata.length > 0) {
        this.showTable = true;
      }
      else {
        this.showTable = false;
        this.subjectAllocationForm.patchValue({
          subjectAllocationList: []
        })
      }
      console.log("SUBJECT", this.subjectdata)
      for (let i = 0; i < this.subjectdata.length; i++) {
        this.subjectAllocationArrayList.push(this.formBuilder.group({
          subjectId: this.subjectdata[i].subjectID,
          subjectName: this.subjectdata[i].subjectName,
          primaryTeacher: this.getPrimaryTeacher(this.subjectdata[i].returnTeachersSubDetailList),
          secondaryTeacher: this.getSecondaryTeacher(this.subjectdata[i].returnTeachersSubDetailList),
        }))
      }
      console.log("FInale data", this.subjectAllocationArrayList.value)
    })
  }
  primaryList: any[];
  secondaryList: any[] = [];
  getPrimaryTeacher(data: any) {
    this.primaryList = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].isTeacherPrimary == true) {
        this.primaryList.push({
          teacherID: data[i].teacherID,
          teacherName: data[i].teacherName,
        })
      }
    }
    return [this.primaryList]
  }
  getSecondaryTeacher(data: any) {
    this.secondaryList = []
    for (var i = 0; i < data.length; i++) {
      if (data[i].isTeacherPrimary == false) {
        this.secondaryList.push({
          teacherID: data[i].teacherID,
          teacherName: data[i].teacherName,
        })
      }
    }
    return [this.secondaryList]
  }
  getMasterData() {
    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classList = res.responseData;
      console.log("CLASS",this.classList)
      this.filteredOptions = this.classControl.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.className)),
        map(className => (className ? this._filter(className) : this.classList.slice())),
      );
    });
    this.teacherList = []
    this.studentService.GetTeacherDropdown().subscribe((res: any) => {
      //this.teachers = res.responseData;
      for(var i = 0;i<res.responseData.length;i++){
        this.teachers.push({
          "teacherName": res.responseData[i].teacherName,
          "teacherID": res.responseData[i].teacherID
        })
      }
      for(var i = 0;i<res.responseData.length;i++){
        this.teacherList.push({
          "name": res.responseData[i].teacherName,
          "code": res.responseData[i].teacherID
        })
      }
      console.log("TEACHERLIST",this.teachers)
      //this.teacher = res.responseData;
    })
  }
  filterSectionListByClassId(data: any) {
    this.sectionCOntrol.setValue('');
    console.log("filterSectionListByClassId", data.option.value.classID)
    this.sectionListDropdown = [];
    this.sectionService.GetSectionByClassId(data.option.value.classID).subscribe((res: any) => {
      for(var i=0;i<res.responseData.length;i++){
        this.sectionListDropdown.push({
          sectionID:res.responseData[i].sectionID,
          sectionName:res.responseData[i].sectionName
        })
      }
      this.sectionfilteredOptions = this.sectionCOntrol.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.sectionName)),
        map(sectionName => (sectionName ? this.Sectionfilter(sectionName) : this.sectionListDropdown.slice())),
      );
      console.log("SECTION", this.sectionListDropdown)
    })
  }
  subjectTeachersList: any[] = [];
  getTeacher(primarydata: any, secondaryData: any) {
    let list: any[] = []
    if (primarydata.length != 0) {
      for (var i = 0; i < primarydata.length; i++) {
        list.push({
          teacherID: primarydata[i].teacherID,
          isTeacherPrimary: true
        })
      }
    }
    if (secondaryData.length != 0) {
      for (var j = 0; j < secondaryData.length; j++) {
        list.push({
          teacherID: secondaryData[j].teacherID,
          isTeacherPrimary: false
        })
      }
    }
    return list
  }
  subjectAllocationObj = {}
  onsubmit() {
    this.subjectTeachersList = [];
    console.log('RESULT',this.subjectAllocationForm.value.subjectAllocationList)
    for (var i = 0; i < this.subjectAllocationForm.value.subjectAllocationList.length; i++) {
      this.subjectTeachersList.push({
        subjectID: this.subjectAllocationForm.value.subjectAllocationList[i].subjectId,
        teachersSubDetailList: this.getTeacher(this.subjectAllocationForm.value.subjectAllocationList[i].primaryTeacher, this.subjectAllocationForm.value.subjectAllocationList[i].secondaryTeacher)
      })
    }
    for (var i = 0; i < this.subjectTeachersList.length; i++) {
      var uniqueValues = new Set(this.subjectTeachersList[i].teachersSubDetailList.map((v: any) => v.teacherID));
      if (uniqueValues.size < this.subjectTeachersList[i].teachersSubDetailList.length) {
        this.toaster.error("You can select a particular teacher either as primary teacher or as secondary teacher.", 'Error');
        return;
      }
    }

    this.subjectAllocationObj = {
      "academicSessionID": this.academicSessionID,
      "subjectTeachersList": this.subjectTeachersList
    }
    console.log("FINAldata",this.subjectAllocationObj)
    this.masterService.subjectAllocationAPI(this.subjectAllocationObj).subscribe((res: any) => {
      console.log("STatuse", res)
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        window.location.reload();
      } else {
        this.toaster.error(res.message, 'Error');
      }
    })
  }
}

