<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Manage Alumni</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
          <li class="breadcrumb-item active" aria-current="page">Manage Alumni</li>
        </ol>
      </div>
    </div>

  </div>

  <div class="cardWidget">

    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <div class="tableActionTop">
          <div class="tableFilter">

            <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="dynamicFilterform" (ngSubmit)="onSubmit()">
                <div class="row">
                  <ng-container *ngFor="let field of dynamicFilterFields">
                    <div class="col s12 m6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{field.fieldName}}</mat-label>
                        <input matInput type="text" formControlName="{{field.fieldName}}">
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>
                <div class="btnsWidget form-group text-right">
                  <button type="submit" class="btn btn-primary btn-sm" (click)="dynamicFilter()">Search</button>
                  <button type="button" (click)="resetFilter()" class="btn btn-reset btn-sm">Reset</button>
                </div>
              </form>
            </div>
          </div>

          <div class="rightTableActions centerItemvert">
            <div class="viewColumns tableActions" matTooltip="Filter">
              <i title="Filter Student" class="fas fa-filter cursor-pointer" (click)="toggleField()"></i>
              <span class="mobileHidden cursor-pointer" (click)="toggleField()"></span>
            </div>
            <div class="viewColumns tableActions" matTooltip="Hide and Show Columns">
              <a (click)="actionDialogPopup(hideShowColPopup,$event)"><i class="fas fa-columns"></i></a>
            </div>
            <div class="downloadTable tableActions" matTooltip="Downlaod File">
              <a (click)="actionDialogPopup(exportPopup,$event)"><i class="fas fa-download"></i></a>
            </div>

            <div class="selectedColTable tableActions" matTooltip={{selectedAluminiTooltip}}>
              <button mat-button [matMenuTriggerFor]="beforeMenu" [ngClass]="{'nocursor':!printFlag}"
                class="ddButtonType" [disabled]="!printFlag">
                <span class="leftItem conterColumns">{{selectedData}}</span>
                <span>Selected</span>
                <i class="fa fa-caret-down rightItem"></i>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
                <h4 class="actionSelected">Communications</h4>
                <button mat-menu-item (click)="studentEmailSent(studentEmail)"
                  *ngIf="checkActionPermission('Send Email')"><i class="fas fa-envelope-open-text"></i>
                  Send Email</button>
                <button mat-menu-item (click)="studentSmsSent(studentSms)" *ngIf="checkActionPermission('Send SMS')"><i
                    class="fas fa-comment-alt"></i> Send
                  SMS</button>
                <h4 class="actionSelected">Other Actions</h4>
                <button mat-menu-item (click)="Block()" *ngIf="checkActionPermission('Block Alumni')"><i
                    class="fas fa-ban"></i> Block</button>
                <button mat-menu-item (click)="Unblock()" *ngIf="checkActionPermission('Unblock Alumni')"><i
                    class="fas fa-unlock"></i> Unblock</button>
                <button mat-menu-item (click)="actionDialogPopup(revertPopup,$event)"
                  *ngIf="checkActionPermission('Revert Alumni')"><i class="fas fa-exchange-alt"></i> Revert
                  Alumni</button>
                <button *ngIf="checkActionPermission('Delete Alumni')" mat-menu-item (click)="Delete()"><i
                    class="fas fa-trash"></i> Delete</button>
              </mat-menu>
            </div>

          </div>
        </div>
      </div>
      <div class="mat-elevation-z8" *ngIf="checkActionPermission('View Alumni')">
        <table mat-table class="w-100" [dataSource]="dataSource" matSort matSortDisableClear matSortDirection="asc"
          (matSortChange)="SortChange($event)">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                (change)="changee($event,row)" [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="columnName" *ngFor="let columnName of displayedColumnsList">
            <th [hidden]="(columnName == 'Section' || columnName == 'Class')" mat-header-cell mat-sort-header
              *matHeaderCellDef>{{ columnName }}</th>
            <td [hidden]="(columnName == 'Section' || columnName == 'Class')" mat-cell *matCellDef="let row">
              <span *ngIf="columnName != 'profilePhoto' && columnName != 'First Name' && columnName != 'Last Name'"
                [innerHTML]="row[columnName]"></span>
              <span *ngIf="columnName == 'First Name'">
                <a (click)="infoCall(row['studentID'])">{{row[columnName]}}</a><br>
                <span *ngIf="displayedColumnsList.includes('Class')"> {{row['Class']}}</span><br>
                <span *ngIf="displayedColumnsList.includes('Section')"> {{row['Section']}}</span>
              </span>
              <span *ngIf="columnName == 'Last Name'"><a
                  (click)="infoCall(row['studentID'])">{{row[columnName]}}</a></span>
              <span *ngIf="columnName == 'profilePhoto'"><img [src]="row[columnName]" alt=""
                  onerror="this.src='../../../../../assets/img/avtaradmin.jpg';"></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <Button title="Details" (click)="infoCall(element.studentID)" class="tbactionbtn"><i
                  class="fas fa-eye"></i></Button>
              <Button title="Revert" (click)="RevertStudent(element.studentID)" class="tbactionbtn"><i
                  class="fas fa-exchange-alt"></i></Button>
              <Button title="Delete" (click)="deleteSinglealumni(element.studentID)" class="tbactionbtn"><i
                  class="fas fa-trash"></i></Button>
            </td>

          </ng-container>
          <tr mat-header-row *matHeaderRowDef="changeColumnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: changeColumnsToDisplay"></tr>
        </table>
        <div *ngIf="metaData.totalRecords==0">
          <p>No Record Found</p>
        </div>
      </div>
      <mat-paginator [length]="metaData['totalRecords']" [pageSizeOptions]="itemperPage"
        [pageIndex]="metaData['currentPage'] - 1" [pageSize]="metaData['pageSize']" (page)="handlePage($event)"
        showFirstLastButtons></mat-paginator>


      <!-- <app-checkboxdata-table [inputColumns]="displayedColumns" [inputSource]="usersData" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)" (onselect)="onselect($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-checkboxdata-table> -->
    </div>
  </div>


  <!-- </div> -->

  <ng-template #loginPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Reset Password</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline" floatLabel="always">
              <mat-label>Username</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
              <input matInput value="Sharma">
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
              <input matInput>
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
              <input matInput>
            </mat-form-field>
          </div>
          <div class="form-group17 text-right">
            <button type="button" class="btn btn-primary btn-sm" mat-dialog-close matRipple>Send</button>
          </div>
        </form>

      </mat-dialog-content>
    </div>
  </ng-template>



  <ng-template #hideShowColPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Hide/Show Colums</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <p><a (click)="selectAllField()" class="tags underlinedLink">Select All</a> / <a (click)="unselectAllField()"
            class="tags underlinedLink">Unselect All</a>
        </p>
        <div class="row-flex" cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="col s12 m12 form-group">
            <mat-checkbox class="example-margin" [disabled]="true" [(ngModel)]="checked">
              Profile Picture
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group" *ngFor="let field of hideshowGridColumnList" cdkDrag>
            <mat-checkbox class="example-margin" [(ngModel)]="field.isShowInGrid" (change)="onCheck()">
              {{field.fieldName}}
            </mat-checkbox>
          </div>
        </div>
      </mat-dialog-content>
    </div>
  </ng-template>


  <ng-template #exportPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Export Student Data</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <p><a (click)="selectAllExport() " class="underlinedLink">Select All</a> / <a (click)="unselectAllExport()"
            class="underlinedLink">Unselect All</a>
        </p>
        <div class="row-flex">
          <div *ngFor="let field of exportField" class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" [(ngModel)]="field.checkbox">
              {{field.fieldName}}
            </mat-checkbox>
          </div>
        </div>
        <div class="form-group17 text-right">
          <button type="submit" (click)="export()" class="btn btn-primary btn-sm" mat-dialog-close>Export</button>
        </div>
      </mat-dialog-content>
    </div>
  </ng-template>

  <ng-template #revertPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title> Do you want to revert students ?</h5>
      </div>
      <mat-dialog-content class="mat-typography">
        <!-- <p class="mb-4 center ">Do you want to delete ?</p> -->
        <div class="btnsWidget form-group text-right">
          <button mat-dialog-close (click)=confirmRevert() class="btn btn-primary btn-sm">Yes</button>
          <button mat-dialog-close class="btn btn-reset btn-sm">NO</button>

        </div>
      </mat-dialog-content>
    </div>
  </ng-template>
  <ng-template #studentEmail>
    <div class="">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Send Student Email</h2>
        <ng-template #elseTemplate>
          <h2 mat-dialog-title>Student Email</h2>
        </ng-template>
        <!-- <h2 mat-dialog-title>Student Enrollment</h2> -->
      </div>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="alumnistudentEmailForm" (ngSubmit)="onSubmitstudentEmail()">
          <div>
            <!-- <p class="graphTitle"> selected {{selectedData}} Students:</p> -->

          </div>
          <div class="row-flex">
            <div class="col-12 form-group">
              <mat-label>Send As</mat-label>
              <br>
              <br>
              <mat-radio-group aria-label="Select an option" formControlName="senderEmail" required>
                <mat-radio-button value=true [checked]="true" (change)="instituteMail()">info@creocms.com.au(Institute's
                  email)</mat-radio-button>
                <mat-radio-button value=false (change)="userMail()">{{userEmail}}(Your Email)</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col s12 m12 form-group">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Subject </mat-label>
                <input matInput maxlength="100" formControlName="subject" placeholder="subject" required>
                <mat-error
                  *ngIf="alumnistudentEmailForm.get('subject')?.touched  && alumnistudentEmailForm.get('subject')?.errors?.required">
                  Subject is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col s12 m12 form-group">
              <mat-label>Receivers</mat-label><br>
              <mat-checkbox [checked]='true' class="example-margin" [disabled]="true">
                Students Email
              </mat-checkbox>
              <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                  {{student.email}}
                </mat-chip>
              </mat-chip-list>
            </div>


            <div class="col s12 m12 form-group">
              <mat-checkbox [checked]='true' class="example-margin" (change)="onparentChange()"
                formControlName="parentCheck">
                Parents Email
              </mat-checkbox>
              <ng-container *ngIf="!boxhide">
                <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                  <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                    {{student.parentEmail}}
                  </mat-chip>
                </mat-chip-list>
              </ng-container>

            </div>
            <div class="col s12 m12 form-group">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Select Template</mat-label>
                <mat-select formControlName="template" (selectionChange)="selectChangeTemplate($event)" name="template">
                  <mat-option *ngFor="let temp of customtemplate" [value]="temp.emailTemplateID">
                    {{temp.templateName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 form-group">
              <ejs-richtexteditor #textEditorValue required formControlName="body" [maxLength]='200'
                [toolbarSettings]='tools'>
              </ejs-richtexteditor>
            </div>
            <div class="form-group col-12 px-4">
              <input formControlName="attachment" (change)="uploadFile($event)" id="file" type="file" multiple>
            </div>

          </div>

          <div class="btnsWidget form-group17 text-right">
            <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close
              [disabled]="alumnistudentEmailForm.invalid">Send</button>
            <button typetype="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close>Close</button>
          </div>

          <!-- <div class="forgotpassword form-group17 center">
              <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
                [disabled]="addSubjectForm.invalid">Save</button>
            </div> -->
        </form>
      </mat-dialog-content>
    </div>
  </ng-template>



  <ng-template #studentSms>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Send Student SMS </h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="studentSmsForm" (ngSubmit)="onSubmitstudentSms()">
          <div>


          </div>
          <div class="row-flex">
            <div class="col s12 m12 form-group">
              <mat-label>Receivers</mat-label><br>
              <mat-checkbox [checked]='true' class="example-margin" [disabled]="true">
                Students Contact Number
              </mat-checkbox>
              <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                  {{student.contactNumber}}
                </mat-chip>
              </mat-chip-list>
            </div>


            <div class="col s12 m12 form-group">
              <mat-checkbox [checked]='false' class="example-margin" (change)="onparentSmsChange()"
                formControlName="parentCheckSms">
                Parents Contact Number
              </mat-checkbox>
              <ng-container *ngIf="!boxhidesms">
                <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                  <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                    {{student.parentContactNumber}}
                  </mat-chip>
                </mat-chip-list>
              </ng-container>

            </div>

            <div class="col s12 m12 form-group">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Message</mat-label>
                <textarea required maxlength="200" formControlName="message" matInput placeholder="Message"></textarea>
                <mat-error
                  *ngIf="studentSmsForm.get('message')?.touched  && studentSmsForm.get('message')?.errors?.required">
                  Message is required
                </mat-error>
              </mat-form-field>
            </div>


          </div>

          <div class="btnsWidget form-group17 text-right">
            <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close [disabled]="studentSmsForm.invalid"
              matRipple>Send</button>
            <button typetype="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close
              matRipple>Close</button>
          </div>
        </form>
      </mat-dialog-content>
    </div>
  </ng-template>