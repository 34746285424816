
  <div [formGroup]="form">
     <mat-label class="extralabel" [attr.for]="inputControl.key"> {{ inputControl.key }} <span *ngIf="inputControl.required === true" style="color:red">*</span></mat-label>

  <div [ngSwitch]="inputControl.controlType">
            <input
            *ngSwitchCase="'textbox'"
            [formControlName]="inputControl.key"
            [id]="inputControl.key"
            [type]="inputControl.type"
          />

          <mat-form-field class="w-100" appearance="outline">
            <mat-label class="extralabel" for="inputControl.key"></mat-label>
            <mat-select
            matNativeControl
            placeholder="Select"
            name="inputControl.key"
            [id]="inputControl.key"
            [required]="inputControl.required === true"
            *ngSwitchCase="'dropdown'"
            [formControlName]="inputControl.key"
          >
          <mat-option [value]="opt.value" *ngFor="let opt of inputControl.options">
            {{ opt.value }}
          </mat-option>
          </mat-select>
          <mat-error
            *ngIf="isValid(inputControl.key)">
            {{ inputControl.label }} is required
          </mat-error>
        </mat-form-field>
      </div>
  </div>


