import { Component, AfterViewInit, ViewChild,OnInit,NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {FormControl, Validators} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ClassService } from '../../courses-and-subjects/services/classServices/class.service';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { MasterService } from 'src/app/services/master.service';
import { SectionDropdownService } from 'src/app/masterServices/sectionDropdownService/section-dropdown.service';
import { DepartmentService } from '../../courses-and-subjects/services/department.service';
import { SectionService } from '../../courses-and-subjects/services/sectionServices/section.service';
import { SubjectService } from '../../courses-and-subjects/services/subjectServices/subject.service';
import { StudentService } from 'src/app/services/student.service';
import { TeacherService } from 'src/app/services/teacher.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { CommonService } from 'src/app/common/core-services/common.service';
import { first } from 'rxjs/operators';
import { ManageAttendanceService } from 'src/app/services/manage-attendance.service';
import { NgForOf } from '@angular/common';
import { BlockLike } from 'typescript';
import { MultiSelect } from "primeng/multiselect";
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-manage-teacher-attendance',
  templateUrl: './manage-teacher-attendance.component.html',
  styleUrls: ['./manage-teacher-attendance.component.css']
})
export class ManageTeacherAttendanceComponent implements OnInit {
  @ViewChild(MultiSelect) ms: MultiSelect;
  classListDropdown: any;
  teacherListDropdown:any;
  sectionListDropdown:any;
  departmentListDropdown:any;
  academicSessions:any;
  subjectList: any;
  AttendanceStatusList: any;
  teacherAttendanceForm: FormGroup;
  users:any;
  academicSessionID:any;
  collapseMonFlag = false;
  collapseTueFlag = false;
  collapseWedFlag = false;
  collapseThuFlag = false;
  collapseFriFlag = false;
  collapseSatFlag = false;
  collapseSunFlag = false;
  
  collapseSentNoti = false;

  collapseMon() {
    this.collapseMonFlag = !this.collapseMonFlag;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;
    this.collapseSunFlag = false;
  }

  collapseTue() {
    this.collapseTueFlag = !this.collapseTueFlag;
    this.collapseMonFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;   
    this.collapseSunFlag = false;

  }

  collapseWed() {
    this.collapseWedFlag = !this.collapseWedFlag; 
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;   
    this.collapseSunFlag = false; 
  }

  collapseThu() {
    this.collapseThuFlag = !this.collapseThuFlag;  
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;   
    this.collapseSunFlag = false;
  }

  collapseFri() {
    this.collapseFriFlag = !this.collapseFriFlag;  
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseSatFlag = false;   
    this.collapseSunFlag = false;
  }

  collapseSat() {
    this.collapseSatFlag = !this.collapseSatFlag;  
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSunFlag = false;
  }
  collapseSun() {
    
    this.collapseSunFlag = !this.collapseSunFlag;  
    this.collapseMonFlag = false;
    this.collapseTueFlag = false;
    this.collapseWedFlag = false;
    this.collapseThuFlag = false;
    this.collapseFriFlag = false;
    this.collapseSatFlag = false;
  }

  collapseNoti() {
    this.collapseSentNoti = !this.collapseSentNoti;
  }
 
  @ViewChild('select')
  select!: MatSelect;

  allSelected=false;
  allSelected1=false;
  teacheridList:any[];
  teachers: any[] = [
    {value: 'upcoming', viewValue: 'Upcoming'},
    {value: 'inprogress', viewValue: 'In Progress'},
    {value: 'completed', viewValue: 'Completed'},
    {value: 'incomplete', viewValue: 'Incomplete'},
    {value: 'withdrawn', viewValue: 'Withdrawn'},
    {value: 'notset', viewValue: 'Not Set'},
  ];
  ngAfterViewInit() {
    const originalRemoveChip = this.ms.removeChip;
    this.ms.removeChip = (...args) => {
      originalRemoveChip.apply(this.ms, args);
      this.ms.onModelChange(this.ms.value);
      this.ms.onChange.emit({originalEvent: null, value: this.ms.value});
    };
  }
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  constructor(
    // private studentService: StudentService,
    private classService: ClassService,
    private toaster: ToastrService,
    private classDropdownService: ClassDropdownService,
    private sectionDropdownService: SectionDropdownService,
    private departmentService: DepartmentService,
    private masterService: MasterService,
    private sectionService: SectionService,
    private subjectService: SubjectService,
   private teacherService:StudentService,
    private fb: FormBuilder,
    private commonService: CommonService,
    private teacherServices:TeacherService,
    private ngZone: NgZone,
    private attendanceService:ManageAttendanceService,
    private dialogService: DialogService


  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
   }

  initForm() {
    this.teacherAttendanceForm = this.fb.group({
      teacherIDs:['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      Date: ['', Validators.required],
      academicSessionID: this.academicSessionID,
      selectedTeacher:['']
    });
  }

    // #region Prev Next Date Logic Variables
    curr: Date;
    startDate: string;
    endDate: string;
    CurrentWeekSlot: string;
    sD: Date;
    eD: Date;
    currNew: Date;

    WeekDates: any = {
      MonDate: '',
      TuesDate: '',
      WedDate: '',
      ThursDate: '',
      FriDate: '',
      SatDate: '',
      SunDate: '',
    }
    //#endregion


  TEACHER_DATA: PeriodicElement[];
  displayedColumns: string[] = ['teacherid', 'teacherName', 'email', 'contact', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saterday','sunday'];
    //dataSource = ELEMENT_DATA;
    // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    ngOnInit(): void {
    this.getMasterDropdown();
    this.initForm();
    this.getAllAttendanceStatus();
    this.getAttSubmissionAnalyticsValue()
   }

   dataSource: any;
   colorCode:any

   onDateChange(event: any) {
    this.curr = event.target.value;
    this.getExactDate();
  }
  getAllAttendanceStatus() {
    this.attendanceService.GetAllAttendanceStatus().subscribe((res: any) => {
      if (res.responseData) {
        console.log("getAllAttendanceStatus res.responseData", res.responseData)
        let defaultStatuses = res.responseData.filter((x: any) => x.isDefault == true);
        let nonDefaultStatuses = res.responseData.filter((x: any) => x.isDefault == false);
        this.AttendanceStatusList = [...defaultStatuses, ...nonDefaultStatuses];
        console.log('here', this.AttendanceStatusList  )
        this.colorCode = this.AttendanceStatusList.colorCode
      }
    })
  }
  prevWeek() {
    this.curr = new Date(this.curr.setMonth(this.sD.getMonth()));
    this.curr = new Date(this.curr.setDate(this.sD.getDate() - 1));
    this.getExactDate();
    this.getTeacherAttendanceByDate();
  }
  nextWeek() {
    this.curr = new Date(this.curr.setMonth(this.eD.getMonth()));
    this.curr = new Date(this.curr.setDate(this.eD.getDate() + 1));
    this.getExactDate();
    this.getTeacherAttendanceByDate();
  }
  getExactDate() {
    let first = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
    if (first <= 0) {
      this.currNew = new Date(this.curr.setMonth(this.curr.getMonth()));
    }
    let last = first + 6; // last day is the first day + 6
    this.sD = new Date(this.curr.setDate(first));
    this.startDate = this.sD.toLocaleString();
    if (first <= 0) {
      this.curr = this.currNew;
    }
    this.eD = new Date(this.curr.setDate(last));
    this.endDate = this.eD.toLocaleString();

    this.WeekDates = {
      MonDate: moment(this.sD).add(1, 'day').format('DD MMM'),
      TuesDate: moment(this.sD).add(2, 'day').format('DD MMM'),
      WedDate: moment(this.sD).add(3, 'day').format('DD MMM'),
      ThursDate: moment(this.sD).add(4, 'day').format('DD MMM'),
      FriDate: moment(this.sD).add(5, 'day').format('DD MMM'),
      SatDate: moment(this.sD).add(6, 'day').format('DD MMM'),
      SunDate: moment(this.sD).add(7, 'day').format('DD MMM'),
    }
    if (first <= 0) {
      this.curr = new Date(this.sD);
    }
  }
  onElementChange(event:any) {
    this.teacheridList = [];
    console.log("Teacher FOrm",this.teacherAttendanceForm.value);
    for(var i=0;i<event.value.length;i++){
      this.teacheridList.push(JSON.stringify(event.value[i].id))
    }
    console.log("EVENTTT",this.teacheridList);

  }
   getTeacherAttendanceByDate(){
    this.dataSource = [];
    this.teacherAttendanceForm.patchValue({
      teacherIDs:this.teacheridList,
      StartDate: moment(this.sD).add(1, 'day').format('YYYY-MM-DD'),
      EndDate: moment(this.sD).add(7, 'day').format('YYYY-MM-DD')
    })
    var d=(this.teacherAttendanceForm.get('TeacherID')?.value)
    console.log("Teacher FOrm",this.teacherAttendanceForm.value);
    if(this.teacherAttendanceForm.valid){
      this.teacherServices.GetTeacherAttendanceByDate(this.teacherAttendanceForm.value).subscribe((res: any) => {
              if (res.responseData) {
                this.TEACHER_DATA = res.responseData;
                this.dataSource = this.TEACHER_DATA;
                this.ngZone.onStable.pipe(first()).subscribe(() => {
                  console.log('Here Is All Teacher Data', this.dataSource);
                  this.setInitialStatuses();
                });
              }
            })
    }
    else {
      this.teacherAttendanceForm.markAllAsTouched();
      this.toaster.error("Please validate Details.");
    }
  //   if(this.teacherAttendanceForm.get('TeacherID')?.value==""){
  //     this.teacherServices.GetAllTeacherAttendanceByDate(this.teacherAttendanceForm.value).subscribe((res: any) => {
  //       if (res.responseData) {
  //         this.TEACHER_DATA = res.responseData;
  //         this.dataSource = this.TEACHER_DATA;
  //         this.ngZone.onStable.pipe(first()).subscribe(() => {
  //           console.log('Here Is All Teacher Data', this.dataSource);
  //           this.setInitialStatuses();
  //         });
  //       }
  //     })
  //   }
  //   else{
  //   this.teacherServices.GetTeacherAttendanceByDate(this.teacherAttendanceForm.value).subscribe((res: any) => {
  //     if (res.responseData) {
  //       this.TEACHER_DATA = res.responseData;
  //       this.dataSource = this.TEACHER_DATA;
  //       this.ngZone.onStable.pipe(first()).subscribe(() => {
  //         console.log('Here Is Teacher Data', this.dataSource);
  //         this.setInitialStatuses();
  //       });
  //     }
  //   })
  // }
   }
   setInitialStatuses(): void {

    for (let i = 0; i < this.dataSource.length; i++) {
      let days = Object.keys(this.dataSource[i]).filter(x => x.includes('day'));
      let temp: any = Object.keys(this.dataSource[i]).filter(x => x.includes('day')).
        reduce((cur, key) => { return Object.assign(cur, { [key]: this.dataSource[i][key] }) }, {})
      for (let j = 0; j < days.length; j++) {
        this.changeAttStatusOnClick(temp[days[j]], this.dataSource[i].teacherID, days[j], false);
      }
    }
  }
  setTeacherAttendanceStatus(id: any, element: any) {
    //debugger
    if (id == 0) {
      id = this.AttendanceStatusList.find((x: any) => x.isDefault == true && x.statusSortCode == '').attendanceStatusMasterID;
    }
    //null || undefined ?? "Hello World"
    element["innerHTML"] = this.AttendanceStatusList.find((x: any) => x.attendanceStatusMasterID == id).statusSortCode;
    console.log("element[innerHTML]", element["innerHTML"])
    element.style.backgroundColor = this.AttendanceStatusList.find((x: any) => x.attendanceStatusMasterID == id).colorCode;
  }

  changeAttStatusOnClick(statusId: any, teacherID: any, day: string, isChangingStatus: boolean) {

    let element: any = document.getElementById("col" + teacherID + day);
    console.log("changeAttStatusOnClick element", element)
    if (!isChangingStatus) {
      this.setTeacherAttendanceStatus(statusId, element);
    }
    if (isChangingStatus) {
      const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
      const d = new Date(String(this.analyticDta?.Date));
      let submittedDay = weekday[d.getDay()];
      if(this.analyticDta?.IsSubmitted && String(submittedDay).toLocaleLowerCase() === String(day).toLocaleLowerCase()){
        this.dialogService.confirm(`Are you sure you want to update submitted attendance status?`).subscribe((result: any) => {
          if (result) {
            this.AttendanceStatusList = this.AttendanceStatusList.sort((a: any, b: any) =>
              (a.attendanceStatusMasterID > b.attendanceStatusMasterID ? 1 : -1)).sort();
            for (let i = 0; i < this.AttendanceStatusList.length; i++) {
              if (statusId == 0) {
                statusId++;
                break;
              }
              if (this.AttendanceStatusList[i].attendanceStatusMasterID == statusId) {
                if (i < this.AttendanceStatusList.length - 1) {
                  statusId = this.AttendanceStatusList[i + 1].attendanceStatusMasterID;
                  break;
                }
                else {
                  statusId = this.AttendanceStatusList[0].attendanceStatusMasterID;
                  break;
                }
              }
            }
            let stu: any = this.TEACHER_DATA.find((x: any) => x.teacherID == teacherID);
            this.setTeacherAttendanceStatus(statusId, element);
            stu[day] = statusId;
          }
        });
      } else {
        this.AttendanceStatusList = this.AttendanceStatusList.sort((a: any, b: any) =>
          (a.attendanceStatusMasterID > b.attendanceStatusMasterID ? 1 : -1)).sort();
        for (let i = 0; i < this.AttendanceStatusList.length; i++) {
          if (statusId == 0) {
            statusId++;
            break;
          }
          if (this.AttendanceStatusList[i].attendanceStatusMasterID == statusId) {
            if (i < this.AttendanceStatusList.length - 1) {
              statusId = this.AttendanceStatusList[i + 1].attendanceStatusMasterID;
              break;
            }
            else {
              statusId = this.AttendanceStatusList[0].attendanceStatusMasterID;
              break;
            }
          }
        }
        let stu: any = this.TEACHER_DATA.find((x: any) => x.teacherID == teacherID);
        this.setTeacherAttendanceStatus(statusId, element);
        stu[day] = statusId;

      }
      
      
    }
  }

    dayWiseAttendance = {
    Mon: 'Mon',
    Tue: 'Tues',
    Wed: 'Wed',
    Thurs: 'Thurs',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    Holiday: 'holiday'
  }
  isMarkAllSelected: boolean = false;
  isHolidaySelected: boolean = false;

  markAll(event: any) {
    this.isMarkAllSelected = event;
  }
  markHoliday(event: any, day: string) {
    this.isHolidaySelected = event;
    this.setWholeClassAttStatus('holiday', day);
  }
  setWholeClassAttStatus(attStatus: any, day: string) {
  
    let holidayStatus = this.AttendanceStatusList.find((x: any) => x.statusName == 'Holiday').attendanceStatusMasterID;
    this.TEACHER_DATA.map((x: any) => {
      if (day == this.dayWiseAttendance.Mon) {
        if (this.isMarkAllSelected) {
          x['monday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['monday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.monday, x.teacherID, 'monday', false);
      }
      if (day == this.dayWiseAttendance.Tue) {
        if (this.isMarkAllSelected) {
          x['tuesday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['tuesday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.tuesday, x.teacherID, 'tuesday', false);
      }
      if (day == this.dayWiseAttendance.Wed) {
        if (this.isMarkAllSelected) {
          x['wednesday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['wednesday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.wednesday, x.teacherID, 'wednesday', false);
      }
      if (day == this.dayWiseAttendance.Thurs) {
        if (this.isMarkAllSelected) {
          x['thursday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['thursday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.thursday, x.teacherID, 'thursday', false);
      }
      if (day == this.dayWiseAttendance.Fri) {
        if (this.isMarkAllSelected) {
          x['friday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['friday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.friday, x.teacherID, 'friday', false);
      }
      if (day == this.dayWiseAttendance.Sat) {
        if (this.isMarkAllSelected) {
          x['saturday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['saturday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.saturday, x.teacherID, 'saturday', false);
      }
      if (day == this.dayWiseAttendance.Sun) {
        if (this.isMarkAllSelected) {
          x['sunday'] = attStatus.value;
        }
        else if (this.isHolidaySelected && attStatus == this.dayWiseAttendance.Holiday) {
          x['sunday'] = holidayStatus;
        }
        this.changeAttStatusOnClick(x.sunday, x.teacherID, 'sunday', false);
      }
    })
  }

  getMasterDropdown() {
    this.sectionDropdownService.SectionDropdown().subscribe((res: any) => {
      this.sectionListDropdown = res.responseData
      console.log("Section",this.sectionListDropdown)
    })
    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classListDropdown = res.responseData
      console.log("Class",this.classListDropdown)

    });

    this.teacherService.GetTeacherDropdown().subscribe((res: any) => {
      this.teacherListDropdown = [];
      if (res.responseData) {
        console.log("TEACHERLIST",res.responseData)
        for(var i=0;i<res.responseData.length;i++){
          this.teacherListDropdown.push({
                name:res.responseData[i].teacherName,
                id:res.responseData[i].teacherID,
          })
        }
      }
    });



    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      this.academicSessions = res.responseData;
      console.log("AcademicSessions",this.academicSessions)

    });
    this.departmentService.DepartmentDropdown().subscribe((res: any) => {
      this.departmentListDropdown = res.responseData;
      console.log("Department",this.departmentListDropdown)

    });
  }

  selectSectionByClassID(event: any) {
    this.teacherAttendanceForm.patchValue({
      'ClassID':event.value
    })
    this.sectionService.GetSectionByClassId(event.value).subscribe((res: any) => {
      this.sectionListDropdown = [];
      if (res.responseData) {
        this.sectionListDropdown = res.responseData
      }
    });
  }
  analyticDta: any
  getAttSubmissionAnalyticsValue() {
    this.teacheridList = [];
    let data = this.commonService.getTeacherAttSubmissionData();
    this.analyticDta = data;
    console.log("getAttSubmissionAnalyticsValue",data);
    if (data != null && data != undefined) {
      this.teacherAttendanceForm.patchValue({
        //teacherIDs: [JSON.parse(data.TeacherID)],
        Date: new Date(data.Date),
      })
      this.teacheridList.push(JSON.stringify(data.TeacherID))
      this.curr = new Date(data.Date)
      if(data.key=='autoclick'){
        this.getExactDate();
        this.getTeacherAttendanceByDate();
      }
      else{
        this.getExactDate();
      }
      this.teacherService.GetTeacherDropdown().subscribe((res: any) => {
        this.teacherListDropdown = [];
        if (res.responseData) {
          for(var i=0;i<res.responseData.length;i++){
            this.teacherListDropdown.push({
                  name:res.responseData[i].teacherName,
                  id:res.responseData[i].teacherID,
            })
            if(data.TeacherID == res.responseData[i].teacherID){
              this.teacherAttendanceForm.patchValue({
                selectedTeacher:[{
                  name:res.responseData[i].teacherName,
                  id:res.responseData[i].teacherID,
                }]
              })
            }
          }
        }
      });
      // this.teacherService.GetTeacherDropdown().subscribe((res: any) => {
      //   this.teacherListDropdown = [];
      //   if (res.responseData) {
      //     for(var i=0;i<res.responseData.length;i++){
      //       if(data.TeacherID == res.responseData[i].teacherID){
      //         console.log("AAAAA",res.responseData[i])
      //         this.teacherAttendanceForm.patchValue({
      //           selectedTeacher:[{
      //             name:res.responseData[i].teacherName,
      //             id:res.responseData[i].teacherID,
      //           }]
      //         })
      //         console.log("final",this.teacherAttendanceForm.value)
      //       }
      //     }
      //   }
      // })
      
    }
    //this.sD=
    this.getExactDate();

  }

  // GetTeacherBySectionId(event: any) {
  //   this.teacherAttendanceForm.patchValue({
  //     'SectionID':event.value
  //   })
  //   this.teacherService.GetTeacherDropdown().subscribe((res: any) => {
  //     this.teacherListDropdown = [];
  //     if (res.responseData) {
  //       this.teacherListDropdown = res.responseData
  //     }
  //   });
  // }

  GetSubjectBySectionId(event: any) {
    this.teacherAttendanceForm.patchValue({
      'SectionID':event.value
    })
    this.subjectService.GetSubjectByTeacherId(event.value).subscribe((res: any) => {
      this.subjectList = [];
      if (res.responseData) {
        this.subjectList = res.responseData
      }
    });
  }
  
  GetSubjectByTeacherId(event:any){
    this.teacherAttendanceForm.patchValue({
      'TeacherID':event.value
    })
    // this.subjectService.GetSubjectByTeacherId(event.value).subscribe((res: any) => {
    //   this.subjectList = [];
    //   if (res.responseData) {
    //     this.subjectList = res.responseData
    //   }
    // });
  }

  setFormControl(event:any){
    this.teacherAttendanceForm.patchValue({
      'SubjetID':event.value
    })
    console.log("subId",event.value)
  }


  saveTeacherAttendance() {
    let data = { "teacherAttendanceData": this.TEACHER_DATA,
        "toTeacher":this.ToStudents,"ViaEmail":this.ViaEmail,"ViaSMS":this.ViaSMS,"ForMonday":this.ForMonday,
        "ForTuesday":this.ForTuesday,"ForWednesday":this.ForWednesday,"ForThursday":this.ForThursday,"ForFriday":this.ForFriday,
        "ForSaturday":this.ForSaturday,"ForSunday":this.ForSunday,"academicSessionID": this.academicSessionID};
    console.log('Teacher Data While Saving',data);
    this.teacherServices.saveTeacherAttendance(data).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.toaster.success(res.message);
        this.getTeacherAttendanceByDate()
      }
    })
  }
  monAbsent: any[] = [];
  tueAbsent: any[] = [];
  webAbsent: any[] = [];
  thurAbsent: any[] = [];
  friAbsent: any[] = [];
  satAbsent: any[] = [];
  sunAbsent: any[] = [];
   //Added For Absent Notification
   ToParents:boolean=false;
   ToStudents:boolean=false;
   ViaEmail:boolean=false;
   ViaSMS:boolean=false;
   ForMonday:boolean=false;
   ForTuesday:boolean=false;
   ForWednesday:boolean=false;
   ForThursday:boolean=false;
   ForFriday:boolean=false;
   ForSaturday:boolean=false;
   ForSunday:boolean=false
  show:boolean=false;
  showForSMS :boolean=false;
  AbsentStudent(){
    console.log("ABSBSBS",this.TEACHER_DATA)
    for(let i=0 ;i<this.TEACHER_DATA.length;i++){
      if(this.TEACHER_DATA[i].monday=='2'){this.monAbsent.push(this.TEACHER_DATA[i])}
      if(this.TEACHER_DATA[i].tuesday=='2'){this.tueAbsent.push(this.TEACHER_DATA[i])}
      if(this.TEACHER_DATA[i].wednesday=='2'){this.webAbsent.push(this.TEACHER_DATA[i])}
      if(this.TEACHER_DATA[i].thursday=='2'){this.thurAbsent.push(this.TEACHER_DATA[i])}
      if(this.TEACHER_DATA[i].friday=='2'){this.friAbsent.push(this.TEACHER_DATA[i])}
      if(this.TEACHER_DATA[i].saturday=='2'){this.satAbsent.push(this.TEACHER_DATA[i])}
      if(this.TEACHER_DATA[i].sunday=='2'){this.sunAbsent.push(this.TEACHER_DATA[i])}
    }
  }
  NotificationViaEmail(){
    this.AbsentStudent();
    this.show=!this.show;
    this.ViaEmail=!this.ViaEmail;
  }
  NotificationViaSMS(){
    this.AbsentStudent();
    this.showForSMS=!this.showForSMS;
    this.ViaSMS=!this.ViaSMS;

  }
totalAbsent:any[]=[];
mondayAbsent(){
  // for(let i=0 ;i<this.monAbsent.length;i++){
  //     this.totalAbsent.push({
  //       teacherid:this.monAbsent[i].teacherID,
  //       absentdate:moment(this.monAbsent[i].monDate).format('YYYY-MM-DD'),
  //       subjectId:this.monAbsent[i].subjetID
  //     })
      
  // }
  //  console.log("Total Absent",this.totalAbsent)
this.ForMonday=!this.ForMonday  
} 
tuesdayAbsent(){this.ForTuesday=!this.ForTuesday}
NotificationToStudent(){this.ToStudents=!this.ToStudents} 
wednesdayAbsent(){this.ForWednesday=!this.ForWednesday} 
thursdayAbsent(){this.ForThursday=!this.ForThursday} 
fridayAbsent(){this.ForFriday=!this.ForFriday} 
saturdayAbsent(){this.ForSaturday=!this.ForSaturday} 
sundayAbsent(){this.ForSunday=!this.ForSunday} 
//NotificationToParent(){this.ToParents=!this.ToParents}



}


export interface PeriodicElement {
	teacherid: number; 
	teacherName: string;
	email: string;
	contact: string;
	monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
 
  // added column name
  //monDate:string;
  }
  
  // const ELEMENT_DATA: PeriodicElement[] = [
	// {teacherid: 1,  teacherName: 'Maths', email: 'Not Assigned', contact: '1213354', monday: ' ', tuesday: ' ', wednesday: ' ', thursday: ' ', friday: ' ', saterday: ' '},  
	// ];