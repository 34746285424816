import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { StudentService } from 'src/app/services/student.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/common/core-services/common.service';
//import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { Router, ActivatedRoute } from '@angular/router';
import { TeacherService } from 'src/app/services/teacher.service';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { ToastrService } from 'ngx-toastr';



export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
// const ELEMENT_DATA: PeriodicElement[] = [
//   { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
//   { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
//   { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
//   { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
//   { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
//   { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
//   { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
//   { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
//   { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
//   { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
// ];

@Component({
  selector: 'app-attendance-submission-analytics',
  templateUrl: './attendance-submission-analytics.component.html',
  styleUrls: ['./attendance-submission-analytics.component.css']
})
export class AttendanceSubmissionAnalyticsComponent implements OnInit {
  @ViewChild('TABLE') table: ElementRef;

  displayedColumns: string[] = ['date', 'teacher', 'session'];
  dataSource: any;
  campaignOne: FormGroup;
  campaignTwo: FormGroup;
  toppings = new FormControl('', Validators.required);
  ViewStudent = new FormControl();
  toppingList: any
  dropdownSettings: any = {};
  ShowFilter = true;
  multiselector: FormGroup;
  selectedItems: any[] = [];
  disabled = false;
  users: any;
  dateFormat: any;

  // = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];


  dateArray: any = [];

  constructor(
    private studentService: StudentService, private commonService: CommonService, private route: Router,
    private teacherService: StudentService,
    private teacherServices: TeacherService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
  ) {

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.campaignOne = new FormGroup({
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
    });
    this.campaignTwo = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
    this.multiselector = this.formBuilder.group({
      teacherID: [this.selectedItems, Validators.required]
    }
    );
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
    }
  }

  ngOnInit(): void {
    //this.getStudentAnalyticsRecords();
    //this.getClassSection()
    this.getTeacher();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'teacherID',
      textField: 'teacherName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };

  }

  TeacherAnalyticsData: any;
  getClassSection() {
    this.studentService.GetStudentClassSectionForAnalytics().subscribe((res: any) => {
      this.toppingList = res.responseData
      console.log('Classsection Data', this.toppingList)
    })
  }

  getTeacher() {
    this.teacherService.GetTeacherDropdown().subscribe((res: any) => {

      this.toppingList = res.responseData
      console.log('Teacher Data', this.toppingList)
    });
  }


  myArr: any;
  getTeacherAnalyticsRecords() {
    this.teacherServices.GetTeacherAnalyticsRecords(this.dataObj).subscribe((res: any) => {
      console.log('Data In Data Source', res.responseData)
      console.log('Satarting Data Source', this.dataSource)
      console.log('starting Student Analytics', this.TeacherAnalyticsData)
      if (res.responseData == null) {
        this.dataSource = '';
        this.TeacherAnalyticsData = [];
        this.dateArray = []
        this.toaster.error("No record found")
      }
      if (res.statusCode == 200) {
        //if(this.showAccourding=='all'){
        // this.dataSource = res.responseData;
        this.dataSource = ''
        this.TeacherAnalyticsData = [];

        console.log('mid Data Source', this.dataSource)
        console.log('mid Student Analytics', this.TeacherAnalyticsData)


        this.myArr = res.responseData

        let finaldata = this.myArr;

        this.myArr.forEach((x: any) => {

          let ttt = finaldata.filter((element: any) => {
            return (element.teacherID == x.teacherID)
          });
          if (ttt) {
            this.dateArray.forEach((element: any) => {
              let dtcase = ttt.filter((z: any) => {
                let tt = moment(z.date).format('YYYY-MM-DD');
                return (tt == element || z.date == '')
              });
              if (dtcase.length > 0) {
                dtcase.forEach((y: any) => {
                  let obj: TeacherAnalyticsRecords = {
                    Date: element,
                    TeacherName: y.teacherName,
                    attendanceStatusMasterID: y.attendanceStatusMasterID,
                    DateFromDB: y.date ? moment(y.date).format('YYYY-MM-DD') : element,
                    TeacherID: y.teacherID,
                    key: 'autoclick'
                  }
                  this.TeacherAnalyticsData.push(obj);
                  let idx = finaldata.indexOf(y);
                  delete finaldata[idx];
                })
              } else {
                let obj: TeacherAnalyticsRecords = {
                  Date: element,
                  TeacherName: x.teacherName,
                  attendanceStatusMasterID: x.attendanceStatusMasterID,
                  DateFromDB: element,
                  TeacherID: x.teacherID,
                  key: 'autoclick'
                }
                this.TeacherAnalyticsData.push(obj);
              }

            })

          }
        })


        console.log('data in object', this.TeacherAnalyticsData);
        this.dataSource = this.TeacherAnalyticsData;
        this.dateArray = []
        console.log('end Data Source', this.dataSource)
        console.log('end Student Analytics', this.TeacherAnalyticsData)

        console.log('DataSource Data is Here ', this.dataSource)
        // }
      }
      else {
        this.dataSource = ''
        this.TeacherAnalyticsData = [];
        this.dateArray = []
      }
    })

  }

  getTeacherAnalyticsRecordsFinal() {
    this.teacherServices.GetTeacherAnalyticsRecords(this.dataObj).subscribe((res: any) => {
      console.log('Data In Data Source', res.responseData)
      console.log('Satarting Data Source', this.dataSource)
      console.log('starting Student Analytics', this.TeacherAnalyticsData)
      if (res.responseData == null) {
        this.dataSource = ''
        this.TeacherAnalyticsData = [];
        this.dateArray = []
        this.toaster.error("No record found")
      }
      if (res.statusCode == 200) {
        //if(this.showAccourding=='all'){
        // this.dataSource = res.responseData;
        this.dataSource = ''
        this.TeacherAnalyticsData = [];

        console.log('mid Data Source', this.dataSource)
        console.log('mid Student Analytics', this.TeacherAnalyticsData)


        let resData = res.responseData.filter((x: any) => x.attendanceStatusMasterID != null && x.attendanceStatusMasterID != 5);

        this.dateArray.forEach((element: any) => {
          let data = resData.filter((x: any) => moment(x.date).format('YYYY-MM-DD') == element);
          let teachersLS = [...new Map(resData.map((item: any) =>
            [item['teacherID'], item])).values()];
          teachersLS.forEach((x: any) => {
            let filterData = data.filter((y: any) => y.teacherID == x.teacherID);
            if (filterData.length > 0) {
              let obj: TeacherAnalyticsRecords = {
                Date: element,
                TeacherName: filterData[0].teacherName,
                attendanceStatusMasterID: filterData[0].attendanceStatusMasterID,
                DateFromDB: filterData[0].date ? moment(filterData[0].date).format('YYYY-MM-DD') : element,
                TeacherID: filterData[0].teacherID,
                key: 'autoclick',
                IsSubmitted: true
              }
              this.TeacherAnalyticsData.push(obj)
            } else {
              let obj: TeacherAnalyticsRecords = {
                Date: element,
                TeacherName: x.teacherName,
                attendanceStatusMasterID: x.attendanceStatusMasterID,
                DateFromDB: x.date ? moment(x.date).format('YYYY-MM-DD') : element,
                TeacherID: x.teacherID,
                key: 'autoclick',
                IsSubmitted: false
              }
              this.TeacherAnalyticsData.push(obj)
            }

          })
        })
        if (this.dataObj.view == 'all') {
          this.dataSource = this.TeacherAnalyticsData;
        }
        else {
          this.dataSource = this.TeacherAnalyticsData.filter((x: any) => x.IsSubmitted == false);
        }

        this.dateArray = []
        console.log('end Data Source', this.dataSource)
        console.log('end Student Analytics', this.TeacherAnalyticsData)

        console.log('DataSource Data is Here ', this.dataSource)
        // }
      }
      else {
        this.dataSource = ''
        this.TeacherAnalyticsData = [];
        this.dateArray = []
      }
    })

  }
  teacherID: any
  dataObj: any
  showAccourding: any
  onSubmit() {
    ////debugger;
    console.log(this.campaignOne.value)
    console.log(this.campaignTwo.value)
    console.log(this.toppings.value)
    this.teacherID = []
    for (let i of this.toppings.value) {
      console.log(i.classID);
      this.teacherID.push(i.teacherID);
    }
    this.showAccourding = (this.ViewStudent.value) === null ? 'all' : this.ViewStudent.value
    this.dataObj = {
      'teacherID': this.teacherID.toString(),
      'startDate': moment(this.campaignOne.value.start).format('YYYY-MM-DD'),
      'endDate': moment(this.campaignOne.value.end).format('YYYY-MM-DD'),
      'view': (this.ViewStudent.value) === null ? 'all' : this.ViewStudent.value
    }
    //this.classSetionId=this.toppings.value
    //console.log(this.classSetionId.get('classID').value)
    console.log(this.ViewStudent.value)
    this.getBetweenDates(this.campaignOne.value.start, this.campaignOne.value.end);
    // this.getTeacherAnalyticsRecords();
    this.getTeacherAnalyticsRecordsFinal();
    console.log("Hello From Teacher Analytics")
  }
  ExportTOExcel() {
    // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.writeFile(wb, 'SheetJS.xlsx');
    let dataToExport = this.dataSource
      .map((x: any) => ({
        Date: x.Date,
        TeacherName: x.TeacherName,
        Session: x.attendanceStatusMasterID == '5' || x.attendanceStatusMasterID == "" ? 'No' : 'Yes'
      }));

    let workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, <XLSX.Table2SheetOpts>{ sheet: 'Sheet 1' });
    let workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
    XLSX.writeFile(workBook, 'Teacher Attandance Report.xlsx');
  }

  infoCall(teacherId: any) {
    //this.router.navigate(['/view-staff/']);
    ////debugger;
    //this.route.navigate(["/admin/academicslearning/manage-students/view-profile"], { queryParams: { id: studentId } });
    this.route.navigate(["/admin/academicslearning/manage-teachers/view-teacher"], { queryParams: { id: teacherId } });
  }
  getBetweenDates(startDate: any, endDate: any) {
    var currentDate = moment(startDate);
    var eD = moment(endDate);
    while (currentDate <= eD) {
      this.dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
      currentDate = moment(currentDate).add(1, 'days');
    }
  }

  getRecordsAccordingToDate(element: any) {
    console.log('teacher Route Data', element);
    //debugger
    this.commonService.setTeacherAttSubmissionData(element);
    // this.route.navigateByUrl('../manage-student-attendance');
  }

  select(event: any) {
    ////debugger;
    console.log(event)
  }

}

export interface TeacherAnalyticsRecords {
  Date: Date,
  TeacherName: any,
  attendanceStatusMasterID: '',
  DateFromDB: any,
  TeacherID: any,
  key: any;
  IsSubmitted?: boolean
}