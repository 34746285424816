import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { environment, profile, Student, Enrollments, Teacher, masters, Master, RubicApi } from 'src/environments/environment';
import { Criteria, CriteriaAttributes, Rubic, RubicApiResponse } from '../common/MasterModel/masterModel';

@Injectable({
  providedIn: 'root'
})
export class RubicGradingScaleService {
  constructor(private http : HttpClient) { }
  
  Get(RubicId:number,AcademicSessionId: number =0,ClassId: number=0,SectionId: number=0,SubjectId: number=0, StudentId: number=0){
    return this.http.get(`${environment.baseUrl}${RubicApi.getRubic}${'?RubicId=' +RubicId}${'&AcademicSessionId=' + AcademicSessionId}${'&ClassId=' + ClassId}${'&SectionId=' + SectionId}${'&SubjectId=' + SubjectId}${'&StudentId=' +StudentId}`);
  }
  Upsert(data: any) {
    return this.http.post(`${environment.baseUrl}${RubicApi.upsert}`, data)
  }

  transformRubicData(response: any){
    return response.reduce((acc: any, data: any) => {
      let {rubicID,rubicName,rubicCriteriaID,criteriaTitle,criteriaDescription,rubicCriteriaAttributeID,attributeName,attributeValue,selectedCriteria,selectedAttribute} = data;
      let prevCat = acc.find((x: Rubic) => x.RubicId === rubicID );
      if(!prevCat){
        acc.push(new Rubic(rubicID,rubicName,[new Criteria(rubicCriteriaID,criteriaTitle,criteriaDescription,false, (selectedCriteria && selectedCriteria ===rubicCriteriaID) ,[new CriteriaAttributes(rubicCriteriaAttributeID,attributeName,attributeValue,false,(selectedAttribute && selectedAttribute ===rubicCriteriaAttributeID))])]));
      } else {
          let prevSubCat = prevCat.Criterias.find((x: Criteria) => x.RubicCriteriaId === rubicCriteriaID);
          if(!prevSubCat) {

              prevCat.Criterias.push(new Criteria(rubicCriteriaID,criteriaTitle,criteriaDescription,false,(selectedCriteria && selectedCriteria ===rubicCriteriaID), [new CriteriaAttributes(rubicCriteriaAttributeID,attributeName,attributeValue,false,(selectedAttribute && selectedAttribute ===rubicCriteriaAttributeID))]));

          } else {
              let prevAttributes= prevSubCat.Attributes.find((x: CriteriaAttributes)=>{x.RubicCriteriaAttributeId === rubicCriteriaAttributeID});

              if(!prevAttributes){
                prevSubCat.Attributes.push(new CriteriaAttributes(rubicCriteriaAttributeID,attributeName,attributeValue,false,(selectedAttribute && selectedAttribute ===rubicCriteriaAttributeID)));
              }
          }
      }
      return acc;
    }, []);
  }

}
