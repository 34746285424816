import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.css']
})
export class AddRolesComponent implements OnInit {

  form: FormGroup;
  treeViewList: any = [];
  //  items = new TreeviewItem({
  //   text: "IT",
  //   value: 9,
  //   children: [
  //     {
  //       text: "Programming",
  //       value: 91,
  //       children: [
  //         {
  //           text: "Frontend",
  //           value: 911,
  //           children: [
  //             { text: "Angular 1", value: 9111 },
  //             { text: "Angular 2", value: 9112 },
  //             { text: "ReactJS", value: 9113 },
  //           ],
  //         },
  //         {
  //           text: "Backend",
  //           value: 912,
  //           children: [
  //             { text: "C#", value: 9121 },
  //             { text: "Java", value: 9122 },
  //             { text: "Python", value: 9123, checked: false },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       text: "Networking",
  //       value: 92,
  //       children: [
  //         { text: "Internet", value: 921 },
  //         { text: "Security", value: 922 },
  //       ],
  //     },
  //   ],
  // });
  


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private manageRolesService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private location: Location
    ) { }
    roleData:any;
    permission: any = [];
    dropdownEnabled = true;
    items: TreeviewItem[];
    values: number[];
    config = TreeviewConfig.create({
      hasAllCheckBox:false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight:3000
    });
    buttonClasses = [
      'btn-outline-primary',
      'btn-outline-secondary',
      'btn-outline-success',
     
    ];
    buttonClass = this.buttonClasses[0];
  ngOnInit(): void {
   console.log(this.permissionService.getAllPermissions());
    this.form = this.formBuilder.group({
      roleName: ['', [Validators.required]],
      roleDescription: [''],
      
    });

    this.getPermissionList();
    // this.RoleScreensList();

  }
  getPermissionList () {
    this.manageRolesService.GetPermissions().subscribe((res : any) => {
      if(res.statusCode == "200") {
        this.RoleScreensList(res.responseData);
      }
    })
  }

  RoleScreensList(res: any){
    this.items =  [  new TreeviewItem({
      text: 'Select All', value: 0,checked: true,
      children:res
    })]

  
    console.log(this.items);
 
  }
  roleList :any = [];
  onSelectedChange(value: any) {
    this.roleList = []
    this.roleList = value;
  }
  onFilterChange(value: any): void {
    console.log('filter:', value);
  }
  formRequest:any;
  submitted = false;
  onSubmit(){
    var list: any = [];
    this.submitted = true;
   
    if (this.form.invalid) {
      //this.SpinnerService.hide();
      return;
    }
    console.log(this.roleList);
    
    if(this.roleList.length == 0){
      this.toaster.error('Atleast one permission select!', 'Error');
      return;
    }
    this.roleList.forEach((role: any) => {
      list.push({
        "screenActionID" : role
      })
    });
    this.formRequest = {
     
      "roleName": this.form.controls.roleName.value,
      "roleDescription": this.form.controls.roleDescription.value,
      "roleScreenPermissions": list   // this.roleList
    }
     this.RoleAdd();
  
  }
  RoleAdd(){
    this.manageRolesService.AddRoles(this.formRequest).subscribe((res:any) => {
      console.log(res);
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        this.router.navigate(['/manage-roles']);
        this.location.back();
      }else{

        this.toaster.error(res.message, 'Error');
      }
      
    });
  }
  allowAlphabetOnly(event:any) {
    this.commonService.allowAlphabetOnly(event);
  }
}
