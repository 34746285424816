import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/services/master.service';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';

@Component({
  selector: 'app-template-two',
  templateUrl: './template-two.component.html',
  styleUrls: ['./template-two.component.css']
})
export class TemplateTwoComponent implements OnInit {
  @Input() reportCardItem: any; 
  @ViewChild('titleCommentEditor', {}) titleObj: RichTextEditorComponent;

  templateID: any;
  dropdownSettings: any = {};
  ShowFilter = true;
  reportCardMasterFields: any;
  studentCusChk: any[];
  traitsCusChk: any[];
  toppings = new FormControl('');
  toppings1 = new FormControl('');
  instituteGroup: any;
  studentGroup: any;
  commentPosition = 1;
  attendanceGroup: any;
  assessmentsGroup: any;
  othersGroup: any;
  studentCustomGroup: any;
  characterTraitsGroup: any;
  toppingList: any;
  toppingList1: any;
  orgID: any;
  users: any;
  user: any;
  academicSessionID: any;
  croppedImage: any;

  public editorModel: any = {
    customCommentTitle: "Custom title",
    customCommentValue: "<p>You can set any custom title here ...</p>",
    contentCommentTitle: "",
    contentCommentValue: "",
    signatureCommentTitle: "",
    signatureCommentValue: ""
  };
  
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'CreateTable', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'ClearFormat', 'Print', 'SourceCode', 'InsertCode', '|',
      'Undo', 'Redo', '|',
      'FullScreen', 'Preview'
      ]
  };

  constructor(
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private masterService: MasterService,
  ) {
    if(localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
      this.croppedImage = localStorage.getItem('Logo');
    }else {
      this.croppedImage = '../../../../../../assets/img/admin/org_logo.png';
    }

    this.users = localStorage.getItem('currentUser');
      var currentUser = JSON.parse(this.users);
      this.academicSessionID = currentUser.academicSessionID
      if (currentUser != null) {
        this.user = currentUser;
      }
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'fieldID',
      textField: 'fieldName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };
    this.templateID = this.reportCardItem.value.Template;
    this.getFormat(this.templateID);
    this.getStudentCustomField();
    this.getAttendanceCustomField();
  }

  onSubmit(){
    if(this.reportCardItem.invalid) {
      if(this.reportCardItem.controls.selectedClassSectionID.errors?.required) {
        this.toaster.error('Please Select Class-Section');
      }
      if(this.reportCardItem.controls.Name.errors?.required) {
        this.toaster.error('Name cannot be empty');
      }
      if(this.reportCardItem.controls.Name.errors?.pattern){
        this.toaster.error('Enter a valid Name');
      }
    } else {
      let data = {
        ReportCardFormatName: this.reportCardItem.value.Name,
        ReportCardTemplateID: this.templateID,
        academicSessionID: this.academicSessionID,
        ClassSection: this.reportCardItem.value.selectedClassSectionID,
        Fields: this.reportCardMasterFields,
        CustomFields: this.studentCustomGroup,
        CommentFields: this.editorModel,
        CharacterTraitFields: this.characterTraitsGroup,
        AttendanceGroup: this.attendanceCustomGroup
      }
      this.manageAssessmentService.CreateReportCardFieldDetails(data).subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
          // this.router.navigate(["admin/"]);
        } else {
          this.toaster.error(res.message, 'Error');
        }
      })
    }
  }

  reset() {
    this.reportCardItem.reset();
  }

  addComment(templateRef: TemplateRef<any>){
    this.dialog.open(templateRef);
  }

  getFormat(Id: number) {
    this.manageAssessmentService.GetAllReportCardFields(Id).subscribe((res: any) => {
      this.reportCardMasterFields = res.responseData;
      this.instituteGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 1);
      this.studentGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 2);
      this.attendanceGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 3);
      this.assessmentsGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 4);
      this.othersGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 5);
    });
  }
  getStudentCustomField() {
    this.manageAssessmentService.GetAllStudentField().subscribe((res: any) => {
      this.studentCustomGroup = res.responseData.filter((x: any) => x.fieldID != 1 && x.fieldID != 2 && x.fieldID != 3 && x.fieldID != 9 && x.fieldID != 10 && x.fieldID != 11);
      this.studentCustomGroup.forEach((element: any) => {
        element.checked = false;
      });
      this.characterTraitsGroup = this.studentCustomGroup.map((x: any) => Object.assign({}, x));
      this.toppingList = this.studentCustomGroup;
      this.toppingList1 = this.characterTraitsGroup;
    });
  }
  attendanceCustomGroup: any;
  getAttendanceCustomField() {
    this.manageAssessmentService.GetAllAttendanceField().subscribe((res: any) => {
      this.attendanceCustomGroup = res.responseData;
      this.attendanceCustomGroup.forEach((element: any) => {
        if(element.attendanceStatusMasterID == 1 || element.attendanceStatusMasterID == 2)
          element.checked = true;
        else
          element.checked = false;
      });
    });
  }

  itemOnChangeInstitute(idx: any) {
    this.instituteGroup[idx].checked = !this.instituteGroup[idx].checked;
  }
  itemOnChangeStudent(idx: any) {
    this.studentGroup[idx].checked = !this.studentGroup[idx].checked;
    if(this.studentGroup[idx].fieldName == 'Custom fields') {
      this.toppings.setValue('');
      this.studentCustomGroup.forEach((element: any) => {
        element.checked = false;
      });
    }
  }
  itemOnChangeAttendance(idx: any) {
    this.attendanceGroup[idx].checked = !this.attendanceGroup[idx].checked;
  }
  itemOnChangeAttendanceCustom(idx: any) {
    this.attendanceCustomGroup[idx].checked = !this.attendanceCustomGroup[idx].checked;
  }
  itemOnChangeAssessments(idx: any) {
    this.assessmentsGroup[idx].checked = !this.assessmentsGroup[idx].checked;
  }
  itemOnChangeOthers(idx: any) {
    this.othersGroup[idx].checked = !this.othersGroup[idx].checked;
    if(this.othersGroup[idx].fieldName == 'Character Traits') {
      this.toppings1.setValue('');
      this.characterTraitsGroup.forEach((element: any) => {
        element.checked = false;
      });
    }
  }

  changeStudentField(event: any) {
    this.studentCustomGroup.forEach((element: any) => {
      let idx = this.studentCusChk.indexOf(element);
      if(idx != -1) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  changeCharacterTraitField(event: any) {
    this.characterTraitsGroup.forEach((element: any) => {
      let idx = this.traitsCusChk.indexOf(element);
      if(idx != -1) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }
  

  addCustomTitleComment() {
    let question = this.titleObj.value;
    this.editorModel.customCommentValue = question;
    let name = document.querySelector<HTMLInputElement>('input[name="blockTitle"]')?.value;
    this.editorModel.customCommentTitle = name;
  }

}
