import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, PaymentDetails, Salary  } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentDetailsService {
  constructor(private http: HttpClient) {}

  GetPaymentDetailsByID(PaymentDetailsByID: number) {
    return this.http.get(`${environment.baseUrl}${PaymentDetails.GetPaymentDetailsByID}`+'?paymentDetailsID='+PaymentDetailsByID);
  }

  GetPaymentDetails(year: number, month: number, userID : number, paymentStatus: string) {
    return this.http.get(`${environment.baseUrl}${PaymentDetails.GetPaymentDetails}`+'?year='+year +'&month='+month +'&userID='+userID + '&paymentStatus='+paymentStatus );
  }

  GetTeacherPaymentDetails(year: number, month: number, userID : number) {
    return this.http.get(`${environment.baseUrl}${PaymentDetails.GetTeacherPaymentDetails}`+'?year='+year +'&month='+month +'&userID='+userID);
  }

  PayUserSalary(data: any) {
    return this.http.post(`${environment.baseUrl}${PaymentDetails.PayUserSalary}`,data);
  }

  PayBulkUserSalary(data: any) {
    return this.http.post(`${environment.baseUrl}${PaymentDetails.PayBulkUserSalary}`,data);
  }

  GetYearAndMonthFromPaymentDetails(){
    return this.http.get(`${environment.baseUrl}${PaymentDetails.GetYearAndMonthFromPaymentDetails}`);
  }
  GetSalarySlipFormByTeacherID(id: number) {
    return this.http.get(`${environment.baseUrl}${Salary.GetSalarySlipFormByTeacherID}`+'?teacherID='+id);
  }
}
