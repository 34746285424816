import { Component, OnInit, TemplateRef, NgModule, CUSTOM_ELEMENTS_SCHEMA, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DepartmentService } from '../services/department.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ClassService } from '../services/classServices/class.service';
import { SectionService } from '../services/sectionServices/section.service';
import { ClassDropdownService } from 'src/app/masterServices/classDropdownService/class-dropdown.service';
import { SubjectService } from '../services/subjectServices/subject.service';
import { SectionDropdownService } from 'src/app/masterServices/sectionDropdownService/section-dropdown.service';
import { MasterService } from 'src/app/services/master.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CommonService } from 'src/app/common/core-services/common.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-manage-class-section-and-subjects',
  templateUrl: './manage-class-section-and-subjects.component.html',
  styleUrls: ['./manage-class-section-and-subjects.component.css']
})
export class ManageClassSectionAndSubjectsComponent implements OnInit, OnDestroy {
  searchDepartment: any;
  searchClass: any;
  searchSection: any;
  searchSubject: any;
  collapseDeptFlag = false;
  collapseClassFlag = false;
  collapseSecFlag = false;
  collapseSubFlag = false;
  flag: boolean = true;
  flag1: boolean = true;
  flag2: boolean = true;
  flag3: boolean = true;
  addDepartmentName = new FormControl('');
  addClassForm: FormGroup;
  addDepartmentForm: FormGroup;
  addSectionForm: FormGroup;
  addSubjectForm: FormGroup;
  departmentTerm: any;
  classTerm: any;
  sectionTerm: any;
  subjectTerm: any;
  departmentListMenu: any = [];
  classListMenu: any = [];
  sectionsListMenu: any = [];
  subjectsListMenu: any = [];
  isAddDepartment: boolean;
  DepartmentID: number;
  ClassID: number;
  SectionID: number;
  classListDropdown: any;
  SubjectID: number;
  sectionListDropdown: any;
  academicSessions: any;
  departmentListDropdown: any;
  updateTextInPopUp: boolean;
  selectedDepartmentID: number;
  selectedClassID: number;
  selectedSectionID: number;
  users: any;
  user: any;
  checked: true;
  selectedDepartment: any;
  selectedClass: any;
  selectedSection: any;
  departmentMode: boolean;
  noSubjectText: boolean;
  disableSelect = false;
  permissions: any = [];
  constructor(
    private dialog: MatDialog,
    private departmentService: DepartmentService,
    private toaster: ToastrService,
    private masterService: MasterService,
    private router: Router,
    private classService: ClassService,
    private sectionService: SectionService,
    private classDropdownService: ClassDropdownService,
    private subjectService: SubjectService,
    private sectionDropdownService: SectionDropdownService,
    private dialogService: DialogService,
    private commonService: CommonService,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private fb: FormBuilder) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.user = currentUser;
    }
  }
  ngOnDestroy(): void {
    sessionStorage.setItem('Loader', '1');
  }
  nodepartment: boolean = false;
  checkedDepartmentData(departmentTerm: any) {
    this.nodepartment = false;
    if (this.departmentListMenu) {
      let data = JSON.parse(JSON.stringify(this.departmentListMenu));
      let dataSource = new MatTableDataSource(data);
      const filterValue = departmentTerm || '';
      dataSource.filter = filterValue;
      if (dataSource.filteredData.length == 0) { this.nodepartment = true; return true; }
      else {
        this.nodepartment = false; return false;
      }
    }
    else if (departmentTerm) {
      { this.nodepartment = true; return true; }
    }
    else {
      { this.nodepartment = false; return false; }
    }
  }


  noclass: boolean = false;
  checkedClassData(classTerm: any) {
    this.noclass = false;
    if (this.classListMenu) {
      let data = JSON.parse(JSON.stringify(this.classListMenu));
      let dataSource = new MatTableDataSource(data);
      const filterValue = classTerm || '';
      dataSource.filter = filterValue;
      if (dataSource.filteredData.length == 0) { this.noclass = true; return true; }
      else {
        this.noclass = false; return false;
      }
    }
    else if (classTerm) {
      { this.noclass = true; return true; }
    }
    else {
      { this.noclass = false; return false; }
    }
  }

  nosection: boolean = false;
  checkedSectionData(sectionTerm: any) {
    this.nosection = false;
    if (this.sectionsListMenu) {
      let data = JSON.parse(JSON.stringify(this.sectionsListMenu));
      let dataSource = new MatTableDataSource(data);
      const filterValue = sectionTerm || '';
      dataSource.filter = filterValue;
      // data=data.filter((x:any)=>x.subjectName==filterValue || x.subjectCode==filterValue);
      if (dataSource.filteredData.length == 0) { this.nosection = true; return true; }
      else {
        this.nosection = false; return false;
      }
    }
    else if (sectionTerm) {
      { this.nosection = true; return true; }
    }
    else {
      { this.nosection = false; return false; }
    }
  }

  nosubject: boolean = false;
  checkedData(subjectTerm: any) {
    this.nosubject = false;
    if (this.subjectsListMenu) {
      let data = JSON.parse(JSON.stringify(this.subjectsListMenu));
      let dataSource = new MatTableDataSource(data);
      console.log(this.subjectsListMenu);
      const filterValue = subjectTerm || '';
      dataSource.filter = filterValue;
      // data=data.filter((x:any)=>x.subjectName==filterValue || x.subjectCode==filterValue);
      if (dataSource.filteredData.length == 0) { this.nosubject = true; return true; }
      else {
        this.nosubject = false; return false;
      }
    }
    else if (subjectTerm) {
      { this.nosubject = true; return true; }
    }
    else {
      { this.nosubject = false; return false; }
    }

  }


  changeDepartmentEnableDisable() {
    this.EnableDepartment();
  }

  DisableDepartment() {
    ////debugger;
    //this.departmentMode = !this.departmentMode;
    this.getClassList();
    this.filterSectionListByClassId(this.classListMenu[0].classID, 0)
  }
  GetEnableDepartment() {
    this.departmentService.GetEnableAndDisable().subscribe((res: any) => {
      this.checked = res.responseData
      console.log("Getresdep", this.checked);
    });
  }
  EnableDepartment() {
    this.departmentService.EnableDepartment().subscribe((res: any) => {
      console.log("resdep", res);

      if (res.status == 1) {
        this.departmentMode = true;
      }
      else {
        this.departmentMode = false;
        this.DisableDepartment();
      }
    });

    this.getMasterDropdown();
    this.getDepartmentList();
  }
  ngOnInit(): void {
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Manage Class, Section and Subjects');
    sessionStorage.setItem('Loader', '0');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    this.ActivityLogTrack();

    this.InItDepartment();
    this.InItClass();
    this.InItSection();
    this.InItSubject();
    this.getMasterDropdown();
    this.getDepartmentList();
    if (this.departmentMode == false) {
      this.getClassList()
    }

    this.mergecalls();
  }
  checkActionPermission(actionName: any) {
    let screen = 'Manage Class, Section and Subjects';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }
  mergecalls() {
    this.nosubject = false;
    this.noclass = false;
    this.nosection = false;

    let d0 = this.departmentService.GetEnableAndDisable();
    let d1 = this.sectionDropdownService.SectionDropdown();
    let d2 = this.classDropdownService.ClassDropdown();
    let d3 = this.masterService.GetAcademicSessionDropdown();
    let d4 = this.departmentService.DepartmentDropdown();
    let d5 = this.departmentService.AllDepartmentPlan();
    let d6 = this.departmentService.GetEnableAndDisable();
    sessionStorage.setItem('Loader', '0');
    forkJoin([d0, d1, d2, d3, d4, d5, d6]).subscribe((res: any[]) => {
      this.checked = res[0].responseData;
      this.sectionListDropdown = res[1].responseData;
      this.classListDropdown = res[2].responseData;
      this.academicSessions = res[3].responseData;
      this.departmentListDropdown = res[4].responseData;
      //this.departmentListMenu = res[5].responseData;
      this.departmentMode = res[6].responseData;
      this.filterClassListByDepartmentID(this.departmentListMenu[0]?.departmentID, 0);

      //this.filterClassListByDepartmentID(this.departmentListMenu[0].departmentID, 0);
    });

    sessionStorage.setItem('Loader', '0');
  }
  InItDepartment() {
    this.addDepartmentForm = this.fb.group({
      departmentName: ['', Validators.required],
    });
  }
  InItClass() {
    this.addClassForm = this.fb.group({
      departmentID: [0, Validators.required],
      className: ['', Validators.required],
      classCode: ['', Validators.required],
      studentsLimit: ['', Validators.required],
    }
    );
  }

  InItSection() {
    this.addSectionForm = this.fb.group({
      classID: ['', Validators.required],
      sectionName: ['', Validators.required],
      sectionCode: ['', Validators.required],
      studentsLimit: ['', Validators.required],
    }
    );
  }

  InItSubject() {
    this.addSubjectForm = this.fb.group({
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      subjectName: ['', Validators.required],
      subjectCode: ['', Validators.required],
      subjectCredit: ['', Validators.required],
      isCore: true,
    }
    );
  }
  getMasterDropdown() {
    sessionStorage.setItem('Loader', '0');
    this.sectionDropdownService.SectionDropdown().subscribe((res: any) => {
      this.sectionListDropdown = res.responseData
    })
    this.classDropdownService.ClassDropdown().subscribe((res: any) => {
      this.classListDropdown = res.responseData
    });

    this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
      this.academicSessions = res.responseData;
    });
    this.departmentService.DepartmentDropdown().subscribe((res: any) => {
      this.departmentListDropdown = res.responseData;
      //this.filterClassListByDepartmentID(this.selectedDepartmentID, this.selectedDepartment)
    });
    sessionStorage.setItem('Loader', '0');
  }

  selectSectionByClassID(event: any) {
    this.noclass = false;
    this.nosection = false;
    this.nosubject = false;
    this.sectionService.GetSectionByClassId(event.value).subscribe((res: any) => {
      this.sectionListDropdown = res.responseData
    });
  }
  activityLog: any


  ActivityLogTrack() {

    this.activityLog = {
      ActivityLogTrack: {
        ModuleName: 'Academic & Learning',
        SubModuleName: 'Courses & Subjects',
        ActivityTypeId: 10,
        Description: 'Class, Section and Subjects record viewed by',
        ScreenName: 'Manage Class, Section and Subjects',
        RecordTypeId: 51,
        RecordId: 0,
        Type: 0

      }
    }
    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response: any) => {
      if (response != null && response != undefined && response != '') {
        let responseData = response.responseData;
        console.log("responseData:", responseData);
      }


    });
  }

  filterClassListByDepartmentID(id: number, index: number) {
    this.selectedSectionID = 0;
    this.selectedClassID = 0;
    this.selectedSectionID = 0;
    if (this.selectedDepartmentID == 0) {
      this.disableSelectDept = false;
    }
    if (this.selectedClassID == 0) {
      this.disableSelectClass = false;
    }
    if (this.selectedSectionID == 0) {
      this.disableSelectClass = false;
      this.disableSelectSec = false;
    }
    this.selectedDepartment = index;
    this.noclass = false;
    this.nosection = false;
    this.selectedDepartmentID = id;
    this.sectionsListMenu = [];
    this.subjectsListMenu = [];
    this.nosubject = false;
    sessionStorage.setItem('Loader', '0');
    this.classService.GetClassByDepartmentId(id).subscribe((res: any) => {
      if (this.departmentMode == false) {
        this.getClassList()
      }
      else {
        this.classListMenu = res.responseData;
        if (res.responseData) {
          ////debugger;
          if (this.selectedClassID === undefined) {
            // this.sectionsListMenu = [];
            this.selectedClassID = 0;
            this.filterSectionListByClassId(this.classListMenu[0].classID, 0);

          }
          //this.filterSectionListByClassId(this.classListMenu[0].classID, this.selectedClass);
          else {
            this.filterSectionListByClassId(this.classListMenu[0].classID, 0);
            this.filterSectionListByClassId(this.selectedClassID, this.selectedClass);
          }

        }
      }

    });
    sessionStorage.setItem('Loader', '0');
  }

  filterSectionListByClassId(id: number, index: number) {
    this.nosubject = false;
    this.noclass = false;
    this.nosection = false;
    this.selectedClassID = id;
    this.selectedClass = index;
    this.subjectsListMenu = [];
    this.sectionsListMenu = [];
    sessionStorage.setItem('Loader', '0');
    this.sectionService.GetSectionByClassId(id).subscribe((res: any) => {
      this.sectionsListMenu = res.responseData;
      if (this.sectionsListMenu) {
        if (this.selectedSectionID === undefined) {
          this.selectedSectionID = 0;
          this.filterSubjectListBySectionId(this.sectionsListMenu[0].sectionID, 0);
        }
        this.filterSubjectListBySectionId(this.sectionsListMenu[0].sectionID, 0);
        this.filterSubjectListBySectionId(this.selectedSectionID, this.selectedSection);
      }
    })
    sessionStorage.setItem('Loader', '0');
  }


  SubjectListByClassId(id: number) {
    this.nosubject = false;
    this.noclass = false;
    this.nosection = false;
    this.subjectService.GetSubjectByClassId(id).subscribe((res: any) => {
      this.subjectsListMenu = res.responseData;
    })
  }


  filterSubjectListBySectionId(id: number, index: number) {
    //this.disableSelectSec=true;
    this.nosubject = false;
    this.noclass = false;
    this.nosection = false;
    this.subjectsListMenu = [];
    this.selectedSectionID = id;
    this.selectedSection = index;
    sessionStorage.setItem('Loader', '0');
    this.subjectService.GetSubjectBySectionId(id).subscribe((res: any) => {
      this.subjectsListMenu = res.responseData;
      console.log(this.subjectsListMenu);

    })
    sessionStorage.setItem('Loader', '0');
  }
  checkNoDepartmentClass: any;
  getDepartmentList() {
    this.nosubject = false;
    this.noclass = false;
    this.nosection = false;

    this.departmentService.AllDepartmentPlan().subscribe((res: any) => {
      this.departmentListMenu = res.responseData;
      ////debugger;
      if (this.departmentMode == false) {
        this.getClassList();
      }
      else {
        this.filterClassListByDepartmentID(this.selectedDepartmentID, this.selectedDepartment);
      }
    });
  }

  getClassList() {
    this.classService.AllClass().subscribe((res: any) => {
      this.classListMenu = res.responseData;

      if (this.departmentMode == false) {
        this.filterSectionListByClassId(this.selectedClassID, this.selectedClass);
      }
      console.log("this.classListMenuLIFO", this.classListMenu);
      this.nosubject = false;
    });
  }

  getSectionList() {
    this.sectionService.AllSection().subscribe((res: any) => {
      this.sectionsListMenu = res.responseData;
      this.nosubject = false;
    })
  }
  openDialogModal(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = false;
    this.InItClass();
    this.InItSection();
    this.InItSubject();
    this.disableSelectDept = false;
    this.disableSelectClass = false;
    this.disableSelectSec = false;
    this.dialog.open(templateRef);
  }
  openDepartmentModal(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = false;
    this.InItDepartment();
    this.dialog.open(templateRef);
  }
  disableSelectDept: boolean;
  openClassModal(templateRef: TemplateRef<any>) {
    this.InItClass();
    this.updateTextInPopUp = false;
    this.dialog.open(templateRef);
    this.addClassForm.patchValue({
      departmentID: this.selectedDepartmentID
    });
    if (this.selectedDepartmentID > 0) {
      this.disableSelectDept = true;
    }
    //this.selectedDepartmentID=0;
    // this.selectedClassID=0;
    // this.selectedSectionID=0;
  }
  disableSelectClass: boolean;
  openSectionModal(templateRef: TemplateRef<any>) {
    // this.disableSelect=true;
    this.InItSection();
    this.updateTextInPopUp = false;
    this.dialog.open(templateRef);
    this.addSectionForm.patchValue({
      classID: this.selectedClassID
    });
    if (this.selectedClassID > 0) {
      this.disableSelectClass = true;
    }
    // this.selectedDepartmentID=0;
    // this.selectedClassID=0;
    // this.selectedSectionID=0;

  }

  disableSelectSec: boolean;
  openSubjectModal(templateRef: TemplateRef<any>) {
    //this.disableSelect=true;
    this.InItSubject();
    this.updateTextInPopUp = false;
    this.dialog.open(templateRef);
    this.addSubjectForm.patchValue({
      classID: this.selectedClassID,
      sectionID: this.selectedSectionID
    });
    // this.selectedDepartmentID=0;
    // this.selectedClassID=0;
    //this.selectedSectionID=0;
    if (this.selectedSectionID > 0) {
      this.disableSelectClass = true;
      this.disableSelectSec = true;
    }
  }


  editDepartmentModal(templateRef: TemplateRef<any>, departmentID: number) {
    this.disableSelectDept = false;
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef);
    this.DepartmentID = departmentID
    this.addDepartmentForm = this.fb.group({
      departmentID: [0],
      departmentName: ['', Validators.required],
    });
    this.departmentService.GetDepartmentById(departmentID).subscribe((res: any) => {
      console.log(res);
      this.addDepartmentForm.patchValue({
        departmentID: res.responseData['departmentID'],
        departmentName: res.responseData['departmentName'],
      });
      console.log('this.addDepartmentForm', this.addDepartmentForm);
    });
  }


  onSubmit() {
    ////debugger;
    if (this.addDepartmentForm.invalid) {
      return;
    }
    this.AddDepartment();
  }

  onUpdateSubmit() {
    ////debugger;
    if (this.addDepartmentForm.invalid) {
      return;
    }
    this.editDepartmentById()

  }

  AddDepartment() {
    console.log("this.addDepartmentForm.value", this.addDepartmentForm.value);

    this.departmentService.AddDepartment(this.addDepartmentForm.value).subscribe((res: any) => {
      if (res.status == 0) {
        this.toaster.error(res.message);
      }
      else {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.addDepartmentForm.reset();
          this.getDepartmentList();
          this.getMasterDropdown();
          this.dialog.closeAll();
          this.selectedClassID = 0;
          this.selectedSectionID = 0;
          this.selectedDepartment = 0;
        }
        else {
          this.toaster.error(res.message);
        }
      }

    });
  }

  editDepartmentById() {
    console.log("this.addDepartmentForm", this.addDepartmentForm.value);
    this.departmentService.EditDepartment(this.addDepartmentForm.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.getDepartmentList();
        this.getMasterDropdown();
        this.reset();
        this.dialog.closeAll();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  checkWhiteSpace(event: any) {
    if (event.target.value.length > 0) {
      return;
    } else {
      event.preventDefault();
    }
  }
  deleteDepartment(id: any) {
    this.dialogService.confirm(`Are you sure you want to delete department?`).subscribe((result: any) => {
      if (result == true) {
        this.departmentService.DeleteDepartment(id).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.getDepartmentList()
            this.dialog.closeAll();
          }
          else {
            this.toaster.error(res.message);
          }
        });
      }
    });

  }

  editClassModal(templateRef: TemplateRef<any>, classID: number) {
    this.disableSelectDept = false;
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef);
    this.ClassID = classID
    console.log("this.ClassID", this.ClassID);

    this.addClassForm = this.fb.group({
      classID: [0],
      departmentID: [0, Validators.required],
      className: ['', Validators.required],
      classCode: ['', Validators.required],
      studentsLimit: ['', Validators.required],
    }
    );

    this.classService.GetClassByClassId(classID).subscribe((res: any) => {
      console.log("ClassRes", res);
      this.addClassForm.patchValue({
        classID: res.responseData['classID'],
        departmentID: res.responseData['departmentID'],
        className: res.responseData['className'],
        classCode: res.responseData['classCode'],
        studentsLimit: res.responseData['studentsLimit'],
      });
    });
  }


  onSubmitClass() {

    if (this.addClassForm.invalid) {
      return;
    }
    if (!this.updateTextInPopUp) {
      this.ClassID = 0;
    }
    if (!this.ClassID) {
      this.AddClass();
    }
    else {
      this.editClassById()
    }
  }

  AddClass() {
    ////debugger;
    if (this.departmentMode == false) {
      this.addClassForm.patchValue({
        departmentID: 0
      });
    }
    this.classService.AddClass(this.addClassForm.value).subscribe((res: any) => {
      ////debugger;
      if (res.status == 0) {
        this.toaster.error(res.message);
      }
      else {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.getMasterDropdown();
          this.dialog.closeAll();
          if (this.departmentMode == false) {
            this.getClassList()
          }
          else {
            this.filterClassListByDepartmentID(this.addClassForm.value.departmentID, this.selectedDepartment)
          }

          this.addDepartmentForm.reset();
        }
        else {
          this.toaster.error(res.message);
        }
      }

    });
  }

  editClassById() {
    console.log("this.addClassForm", this.addClassForm.value);
    this.classService.EditClass(this.addClassForm.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.getMasterDropdown();
        this.dialog.closeAll();
        if (this.departmentMode == false) {
          this.getClassList()
        }
        else {
          this.filterClassListByDepartmentID(this.addClassForm.value.departmentID, this.selectedDepartment)
        }

        //this.getClassByClassID(this.addClassForm.value.classID)
        this.reset();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }

  getClassByClassID(classId: any) {
    this.classService.GetClassById(classId).subscribe((res: any) => {
      if (this.departmentMode == false) {
        this.getClassList()
      }
      else {
        this.classListMenu = res.responseData;
      }

    });
  }
  //

  deleteClass(id: any) {
    this.dialogService.confirm(`Are you sure you want to delete class?`).subscribe((result: any) => {
      if (result == true) {
        this.classService.DeleteClass(id).subscribe((res: any) => {
          if (res.status > 0) {
            if (this.departmentMode == false) {
              this.getClassList()
            }
            else {
              this.filterClassListByDepartmentID(this.selectedDepartmentID, this.selectedDepartment)
            }
            this.toaster.success(res.message);
            this.getMasterDropdown();
            this.dialog.closeAll();
          }
          else {
            this.toaster.error(res.message);
          }
        });
      }
    });
  }
  editSectionModal(templateRef: TemplateRef<any>, sectionID: number) {
    this.disableSelectClass = true;
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef);

    this.SectionID = sectionID
    this.addSectionForm = this.fb.group({
      sectionID: [0],
      classID: ['', Validators.required],
      sectionName: ['', Validators.required],
      sectionCode: ['', Validators.required],
      studentsLimit: ['', Validators.required],
    }
    );
    this.sectionService.GetSectionById(sectionID).subscribe((res: any) => {
      console.log(res);
      this.addSectionForm.patchValue({
        sectionID: res.responseData[0]['sectionID'],
        classID: res.responseData[0]['classID'],
        sectionName: res.responseData[0]['sectionName'],
        sectionCode: res.responseData[0]['sectionCode'],
        studentsLimit: res.responseData[0]['studentsLimit'],
      });
    });
  }

  onSubmitSection() {

    if (this.addSectionForm.invalid) {
      return;
    }
    if (!this.updateTextInPopUp) {
      this.SectionID = 0;
    }
    if (!this.SectionID) {
      this.AddSection();
    }
    else {
      this.editSectionById()
    }
  }

  AddSection() {
    this.sectionService.AddSection(this.addSectionForm.value).subscribe((res: any) => {
      if (res.status == 0) {

        this.toaster.error(res.message);
      }
      else {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.getMasterDropdown();
          this.filterSectionListByClassId(this.addSectionForm.value.classID, this.selectedClass);
          this.addSectionForm.reset();
          this.dialog.closeAll();
        }
        else {
          this.toaster.error(res.message);
        }
      }

    });
  }

  editSectionById() {

    console.log("this.addSectionForm", this.addSectionForm.value);
    this.sectionService.EditSection(this.addSectionForm.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.getMasterDropdown();
        this.filterSectionListByClassId(this.addSectionForm.value.classID, this.selectedClass);
        this.reset();
        this.dialog.closeAll();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }


  deleteSection(id: any) {
    this.dialogService.confirm(`Are you sure you want to delete section?`).subscribe((result: any) => {
      if (result == true) {
        this.sectionService.DeleteSection(id).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.filterSectionListByClassId(this.selectedClassID, this.selectedClass);
            this.dialog.closeAll();
          }
          else {
            this.toaster.error(res.message);
          }
        });
      }
    });
  }



  editSubjectModal(templateRef: TemplateRef<any>, subjectID: number) {
    this.disableSelectClass = true;
    this.disableSelectSec = true;
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef);

    this.SubjectID = subjectID
    this.addSubjectForm = this.fb.group({
      subjectID: [0],
      classID: ['', Validators.required],
      sectionID: ['', Validators.required],
      subjectName: ['', Validators.required],
      subjectCode: ['', Validators.required],
      subjectCredit: ['', Validators.required],
      isCore: ['', Validators.required],
    }
    );
    this.subjectService.GetSubjectById(subjectID).subscribe((res: any) => {
      console.log(res);

      this.addSubjectForm.patchValue({
        subjectID: res.responseData[0]['subjectID'],
        classID: res.responseData[0]['classID'],
        sectionID: res.responseData[0]['sectionID'],
        subjectName: res.responseData[0]['subjectName'],
        subjectCode: res.responseData[0]['subjectCode'],
        subjectCredit: res.responseData[0]['subjectCredit'],
        isCore: res.responseData[0]['isCore'],
      });
    });
  }

  onSubmitSubject() {

    if (this.addSubjectForm.invalid) {
      return;
    }
    if (!this.updateTextInPopUp) {
      this.SubjectID = 0;
    }
    if (!this.SubjectID) {
      this.AddSubject();
    }
    else {
      this.editSubjectById()
    }
  }

  AddSubject() {

    console.log(this.addSubjectForm.value);

    this.subjectService.AddSubject(this.addSubjectForm.value).subscribe((res: any) => {
      if (res.status == 0) {
        this.toaster.error(res.message);
      }
      else {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.getMasterDropdown();
          this.filterSubjectListBySectionId(this.addSubjectForm.value.sectionID, this.selectedSection);
          this.addSubjectForm.reset();
          this.dialog.closeAll();
        }
        else {
          this.toaster.error(res.message);
        }
      }

    });
  }

  editSubjectById() {

    console.log("this.addSubjectForm", this.addSubjectForm.value);
    this.subjectService.EditSubject(this.addSubjectForm.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.getMasterDropdown();
        this.filterSubjectListBySectionId(this.addSubjectForm.value.sectionID, this.selectedSection);
        this.nosubject = false;
        this.reset();
        this.dialog.closeAll();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }

  deleteSubject(id: any) {
    this.dialogService.confirm(`Are you sure you want to delete subject?`).subscribe((result: any) => {
      if (result == true) {
        this.subjectService.DeleteSubject(id).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.filterSubjectListBySectionId(this.selectedSectionID, this.selectedSection);
            this.dialog.closeAll();

          }
          else {
            this.toaster.error(res.message);
          }
        });
      }
    });

  }

  reset() {
    this.InItDepartment();
    this.InItClass();
    this.InItSection();

    // this.selectedClassID=0;
    // this.selectedSectionID=0;
    // this.disableSelectDept=true;
    // this.disableSelectClass=false;
  }

  subReset() {
    this.InItSubject();
    // this.selectedSectionID=0;
    // this.disableSelectSec=false;
  }

  allowAlphabetandNumberOnly(event: any) {
    this.commonService.allowAlphaNumber(event)
  }

  collapseDepartment() {

    this.collapseDeptFlag = !this.collapseDeptFlag;
    // this.collapseSubFlag = false;
    // this.collapseClassFlag = false;
    // this.collapseSecFlag = false;
  }

  collapseClass() {
    this.collapseClassFlag = !this.collapseClassFlag;
    // this.collapseDeptFlag = false;
    // this.collapseSubFlag = false;
    // this.collapseSecFlag = false;
  }

  collapseSubject() {
    this.collapseSubFlag = !this.collapseSubFlag;
    // this.collapseDeptFlag = false;
    // this.collapseClassFlag = false;
    // this.collapseSecFlag = false;
  }

  collapseSection() {
    this.collapseSecFlag = !this.collapseSecFlag;
    // this.collapseDeptFlag = false;
    // this.collapseSubFlag = false;
    // this.collapseClassFlag = false;
  }
  drop(event: CdkDragDrop<string[]>, type: any, list: any) {
    console.log(this.selectedDepartment);
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    if (type == "department") {
      this.filterClassListByDepartmentID(event.item.data.departmentID, event.currentIndex);
    } else if (type == "class") {
      this.filterSectionListByClassId(event.item.data.classID, event.currentIndex);
    } else if (type == "section") {
      this.filterSubjectListBySectionId(event.item.data.sectionID, event.currentIndex);
    }
  }


  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(event.previousContainer.data,
  //                       event.container.data,
  //                       event.previousIndex,
  //                       event.currentIndex);
  //   }
  // }
  OpenPo() {
    this.router.navigate(['admin/obe/app-program-outcome'])
  }
  OpenCo() {
    this.router.navigate(['admin/obe/app-course-outcome'])
  }
  remvoeDepartmentListMenu(index: number) {
    this.departmentListMenu.splice(index, 1);
  }

  remvoeClassListMenu(index: number) {
    this.classListMenu.splice(index, 1);
  }

  remvoeSectionListMenu(index: number) {
    this.sectionsListMenu.splice(index, 1);
  }

  remvoeSubjectListMenu(index: number) {
    this.subjectsListMenu.splice(index, 1);
  }

  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
}
