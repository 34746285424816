<form [formGroup]="form">
  <div style="margin-bottom:10px; line-height:15px;">
    <a class="text-colored"
        style="text-decoration:underline;" href="javascript:void(0);" (click)="startAutoMatching()">Auto select matching
        columns</a>
  </div>

<div class="row">
<div *ngFor="let headerControl of dynamicInputControls" class="col-lg-4 col-sm-12">
    <app-map-excel-input
    [inputControl]="headerControl"
    [form]="form"
  ></app-map-excel-input>
</div>
</div>
<div class="text-right">
  <div style="margin-top: 15px ;">
      <button type="submit" id="startImportButton" [disabled]="!form.valid" (click)="onButtonClick($event)" matRipple class="btn btn-primary btn-sm"><i
              class="fa fa-upload"></i> Start Import</button>
  </div>
  <!-- <div class="mt-4">
    <span>Import process in progress.please wait..</span>
  </div> -->
  <div *ngIf="!inProgress" style="justify-content: flex-end;display: flex;align-items: center;">
    <div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>&nbsp;&nbsp;<span>Import process in progress.please wait..</span>
  </div>
</div>
</form>
<br>  