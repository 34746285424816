import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Branch, environment, Master } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStatusDropdownService {
  constructor(private http: HttpClient) { }

  AcademicSessionStatusDropdown() {
    return this.http.get(`${environment.baseUrl}${Master.acdemicSessionStatusDropdownAPI}`);
  }
  getOrganizationType() {
    return this.http.get(`${environment.adminUrl}${Master.organizationTypeAPI}`);
  }
  GetSiteSubscriptionPlanDropdown() {
    return this.http.get(`${environment.adminUrl}${Master.getSiteSubscriptionPlanDropdown}`);
  }
  GetEmailSubscriptionPlanDropdown() {
    return this.http.get(`${environment.adminUrl}${Master.getEmailSubscriptionPlanDropdown}`);
  }
  GetSMSSubscriptionPlanDropdown() {
    return this.http.get(`${environment.adminUrl}${Master.getSMSSubscriptionPlanDropdown}`);
  }
  GetBranchDropdown(Id: any) {
    return this.http.get(`${environment.baseUrl}${Branch.getBranchAPI}${'?id=' + Id}`);
  }
   GetOrganizationDropdown(Id: any) {
    return this.http.get(`${environment.baseUrl}${Branch.getOrganizationsByUserAPI}${'?userName=' + Id}`);
  }
  GetBranchList(userName: any,keyword: any) {
    return this.http.get(`${environment.baseUrl}${Branch.getBranchesList}${'?userName=' +userName}${'&keyword=' +keyword}`);
  }
  GetSiteSubscriptionPlans() {
    return this.http.get(`${environment.baseUrl}${Master.getSiteSubscriptionPlans}`);
  }
  GetEmailSubscriptionPlans() {
    return this.http.get(`${environment.baseUrl}${Master.getEmailSubscriptionPlans}`);
  }
  GetSMSSubscriptionPlans() {
    return this.http.get(`${environment.baseUrl}${Master.getSMSSubscriptionPlans}`);
  }
  GetOrganization() {
    return this.http.get(`${environment.baseUrl}${Master.getOrganization}`);
  }
  GetTransaction() {
    return this.http.get(`${environment.baseUrl}${Master.GetTransaction}`);
  }
  GetTransactionById(data: any) {
    return this.http.get(`${environment.baseUrl}${Master.GetTransactionById}${data}`);
  }
}


