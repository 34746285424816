<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Manage Students</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
          <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
              Learning</a></li>
          <li class="breadcrumb-item active" aria-current="page">Manage Students</li>
        </ol>
      </div>
    </div>
    <div class="">
      <div class="add_button" *ngIf="checkActionPermission('Add Student')">
        <button class="addbtn btn" matRipple routerLink="../manage-students/add-student"> <i
            class="fas fa-plus-circle"></i> Add Student </button>


        <!-- <div class="switchToggle ml-2">
          <input type="checkbox" id="switch" class="checkbox statusbtn">
          <label for="switch" class="toggle bigbtn">
            <span class="yes" (click)="EnableDepartment()" >Active</span>
            <span class="no" *ngIf="departmentMode">Deactive</span>
          </label>
        </div> -->
      </div>
    </div>
  </div>

  <div class="cardWidget">
    <!-- <div class="cardTitle centerVTItems justify-content-between">
      <h3>Students</h3>
      <button class="btn btn-primary btnsmall" matRipple ><i class="fas fa-plus"></i>
        Add Student</button>
    </div> -->

    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">

        <form [formGroup]="filterForm">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 s12 m3 form-group mb-0">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Academic Session</mat-label>
                    <input type="text" (input)="filterSession($event,'Academic Sessions')" matInput
                      formControlName="academicSessionID" [matAutocomplete]="sessionauto">
                    <mat-autocomplete (optionSelected)="filterSession($event,'Academic Sessions')"
                      #sessionauto="matAutocomplete" [displayWith]="sessiondisplayFn">
                      <mat-option *ngFor="let session of sessionfilteredOptions | async" [value]="session">
                        {{session.sessionName}}
                      </mat-option>
                      <mat-option *ngIf="sessionFlag==true" disabled>
                        No Academic Session Found
                      </mat-option>
                    </mat-autocomplete>

                  </mat-form-field>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-12 s12 m3 form-group mb-0">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Class</mat-label>
                    <input type="text" (input)="filterSectionListByClassId($event,'Class')" matInput
                      formControlName="classID" [matAutocomplete]="classauto">
                    <mat-autocomplete #classauto="matAutocomplete" [displayWith]="displayFn"
                      (optionSelected)="filterSectionListByClassId($event,'Class')">
                      <mat-option *ngFor="let class of filteredOptions | async" [value]="class">
                        {{class.className}}
                      </mat-option>
                      <mat-option *ngIf="classFlag==true" disabled>
                        No Class Found
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>


                <div class="col-lg-3 col-md-6 col-sm-12 s12 m3 form-group mb-0">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Section</mat-label>
                    <input type="text" (input)="filterSubjectListBySectionId($event,'Section')" matInput
                      formControlName="sectionID" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="sectiondisplayFn"
                      (optionSelected)="filterSubjectListBySectionId($event,'Section')">
                      <mat-option *ngFor="let section of sectionfilteredOptions | async" [value]="section">
                        {{section.sectionName}}
                      </mat-option>
                      <mat-option *ngIf="sectionFlag==true || sectionList.length==0" disabled>
                        No Section Found
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 s12 m3 form-group mb-0">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Subject</mat-label>
                    <input type="text" (input)="getStudentBySubjectID($event,'')" matInput formControlName="subjectID"
                      [matAutocomplete]="autosubject">
                    <mat-autocomplete (optionSelected)="getStudentBySubjectID($event,'')" #autosubject="matAutocomplete"
                      [displayWith]="subjectdisplayFn">
                      <mat-option *ngFor="let subject of subjectfilteredOptions | async" [value]="subject">
                        {{subject.subjectName}}
                      </mat-option>
                      <mat-option *ngIf="subjectFlag==true || subjectList.length==0" disabled>
                        No Subject Found
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>

              </div>
            </div>
          </div>
        </form>


        <!-- 
        <div class="col-md-2">
          <button class="btn btn-primary btn-block mt-1 mb-3">Go</button>
        </div> -->
      </div>
      <div class="tableActionTop">
        <div class="tableFilter">

          <div class="filterForm" [class.toggled]="classToggled">
            <form [formGroup]="dynamicFilterform" (ngSubmit)="onSubmit()">
              <div class="row">
                <ng-container *ngFor="let field of dynamicFilterFields">
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{field.fieldName}}</mat-label>
                      <input matInput type="text" formControlName="{{field.fieldName}}">
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
              <div class="btnsWidget form-group text-right">
                <button type="submit" class="btn btn-primary btn-sm" (click)="dynamicFilter()">Search</button>
                <button type="button" (click)="resetFilter()" class="btn btn-reset btn-sm">Reset</button>
              </div>
            </form>
          </div>
        </div>
        <div class="rightTableActions centerItemvert">
          <div class="iconsset d-flex">
            <div class="viewColumns tableActions" matTooltip="Filter">
              <i title="Filter Student" class="fas fa-filter cursor-pointer" (click)="toggleField()"></i>
              <span class="mobileHidden cursor-pointer" (click)="toggleField()"></span>
            </div>

            <div class="viewColumns tableActions" matTooltip="Hide and Show columns">
              <a (click)="actionDialogPopup(hideShowColPopup)"><i title="Hide/Show Column"
                  class="fas fa-columns"></i></a>
            </div>
            <!-- <div class="downloadTable tableActions" matTooltip="Upload file">
            <a (click)="actionDialogPopup(importPopup)"><i title="Import Students" class="fas fa-upload"></i></a>
          </div> -->
            <div *ngIf="checkActionPermission('Download Student')" class="downloadTable tableActions"
              matTooltip="Download file">
              <a (click)="actionDialogPopup(exportPopup)"><i title="Export Students" class="fas fa-download"></i></a>
            </div>
          </div>
          <div class="buttonsset d-flex align-items-center">

            <div class="enrolmentTable tableActions">
              <a mat-button [matMenuTriggerFor]="enrollment" matRipple class="ddButtonType"
                matTooltip="Student and Subject Enrollment Status filter">Enrollment <i
                  class="fa fa-caret-down rightItem"></i></a>
              <mat-menu #enrollment="matMenu" xPosition="before" class="actionSelectedDD">
                <h2><b> <i class="fas fa-user-graduate"></i> Students Enrollment Status</b></h2>
                <hr>
                <button mat-menu-item [ngStyle]="{'color' : activeItem==='Upcoming' ? 'blue' : 'rgba(0,0,0,.54)' }"
                  (click)="GetStudentByStuEnrollStatus(3, 'Upcoming')">Upcoming</button>
                <button mat-menu-item (click)="GetStudentByStuEnrollStatus(4, 'InProgress')"
                  [ngStyle]="{'color' : activeItem==='InProgress' ? 'blue' : 'rgba(0,0,0,.54)' }">In Progress</button>
                <button mat-menu-item [ngStyle]="{'color' : activeItem==='Completed' ? 'blue' : 'rgba(0,0,0,.54)' }"
                  (click)="GetStudentByStuEnrollStatus(5, 'Completed')">Completed</button>
                <button mat-menu-item [ngStyle]="{'color' : activeItem==='Incomplete' ? 'blue' : 'rgba(0,0,0,.54)' }"
                  (click)="GetStudentByStuEnrollStatus(6, 'Incomplete')">Incomplete</button>
                <button mat-menu-item [ngStyle]="{'color' : activeItem==='Withdrawn' ? 'blue' : 'rgba(0,0,0,.54)' }"
                  (click)="GetStudentByStuEnrollStatus(7, 'Withdrawn')">Withdrawn</button>
                <!-- <button mat-menu-item>Not Set</button> -->
                <h2><b> <i class="fas fa-book-open"></i> Subjects Enrollment Status</b></h2>
                <hr>
                <button mat-menu-item [ngStyle]="{'color' : activeSubItem==='InProgress' ? 'blue' : 'rgba(0,0,0,.54)' }"
                  (click)="GetStudentBySubEnrollStatus(3, 'InProgress')">In Progress</button>
                <button mat-menu-item [ngStyle]="{'color' : activeSubItem==='Completed' ? 'blue' : 'rgba(0,0,0,.54)' }"
                  (click)="GetStudentBySubEnrollStatus(4, 'Completed')">Completed</button>
                <button mat-menu-item [ngStyle]="{'color' : activeSubItem==='Incomplete' ? 'blue' : 'rgba(0,0,0,.54)' }"
                  (click)="GetStudentBySubEnrollStatus(5, 'Incomplete')">Incomplete</button>
                <button mat-menu-item [ngStyle]="{'color' : activeSubItem==='Withdrawn' ? 'blue' : 'rgba(0,0,0,.54)' }"
                  (click)="GetStudentBySubEnrollStatus(6, 'Withdrawn')">Withdrawn</button>
                <!-- <button mat-menu-item>Not Set</button> -->
              </mat-menu>
            </div>
            <div class="selectedColTable tableActions">
              <button mat-button [matMenuTriggerFor]="beforeMenu" class="ddButtonType" [disabled]="!printFlag"><span
                  class="leftItem conterColumns">{{selectedData}}</span> <span
                  matTooltip={{selectedStudentTooltip}}>Selected</span>
                <i class="fa fa-caret-down rightItem"></i></button>

              <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
                <button mat-menu-item (click)="actionDialogPopup(studentEnrollment)"
                  *ngIf="checkActionPermission('Student Enrollment')">
                  <i class="fas fa-user-plus"></i>
                  Student Enrollment</button>
                <div [ngClass]="DisabledPointer()?  'disablePointer' : ''" [matTooltip]="toolTipText">
                  <button mat-menu-item (click)="actionDialogPopup(subjectEnrollment)" [disabled]="DisabledPointer()"
                    *ngIf="checkActionPermission('Elective Subject Enrollment')"><i class="fas fa-vote-yea"></i>
                    Elective Subject Enrollment
                  </button>
                </div>

                <!-- <button mat-menu-item (click)="actionDialogPopup(subjectEnrollment)" matTooltip="You need to select academic session, class, section and subjects in filter to perform this action"><i class="fas fa-vote-yea"></i>
              Elective Subject Enrollment
            </button> -->

                <div [ngClass]="DisabledPointer()?  'disablePointer' : ''" [matTooltip]="toolTipText">
                  <button mat-menu-item (click)="updateStudentEnrollmentStatusByStudentId(updateStudentEnrollment)"
                    [disabled]="DisabledPointer()" *ngIf="checkActionPermission('Update Student Enrollment')"><i
                      class="fas fa-user-cog"></i>
                    Update Student
                    Enrollment Status
                  </button>
                </div>
                <!-- <button mat-menu-item (click)="updateStudentEnrollmentStatusByStudentId(studentEnrollment)" matTooltip="You need to select academic session, class and section in filter to perform this action"><i
                class="fas fa-user-cog"></i> Update Student
              Enrollment Status</button> -->
                <div [ngClass]="DisabledPointer1()?  'disablePointer' : ''" [matTooltip]="toolTipText1">
                  <button mat-menu-item
                    (click)="updateSubjectEnrollmentStatusByStudentId(updateSubjectEnrollmentStatus)"
                    [disabled]="DisabledPointer1()"><i class="fas fa-clipboard-list"
                      *ngIf="checkActionPermission('Update Subject Enrollment')"></i>
                    Update Subject Enrollment Status
                  </button>
                </div>
                <!-- <h4 class="actionSelected">Enrollments</h4>
              <button mat-menu-item (click)="actionDialogPopup(studentEnrollment)">

                <i class="fas fa-user-plus"></i>
                Student Enrollment</button>
                <p [ngClass]="DisabledPointer()?  'disablePointer' : ''" [matTooltip]="toolTipText">
                  <button  mat-menu-item (click)="actionDialogPopup(subjectEnrollment)" [disabled]="DisabledPointer()"   ><i class="fas fa-vote-yea"></i>
                   Elective Subject Enrollment 
                 </button>
                </p>  -->

                <!-- <button mat-menu-item (click)="actionDialogPopup(subjectEnrollment)" matTooltip="You need to select academic session, class, section and subjects in filter to perform this action"><i class="fas fa-vote-yea"></i>
                Elective Subject Enrollment
              </button> -->
                <!-- <button mat-menu-item (click)="updateStudentEnrollmentStatusByStudentId(updateStudentEnrollment)" matTooltip="You need to select academic session, class and section in filter to perform this action"><i
                  class="fas fa-user-cog"></i> Update Student
                Enrollment Status</button>
              <button mat-menu-item (click)="updateSubjectEnrollmentStatusByStudentId(updateSubjectEnrollmentStatus)" matTooltip="You need to select academic session, class and section in filter to perform this action"><i
                  class="fas fa-clipboard-list"></i> Update Subject Enrollment Status</button> -->
                <h4 class="actionSelected">Communications</h4>
                <button mat-menu-item (click)="studentEmailSent(studentEmail)"
                  *ngIf="checkActionPermission('Send Email')"><i class="fas fa-envelope-open-text"></i>
                  Send Email</button>
                <button mat-menu-item *ngIf="checkActionPermission('Send SMS')" (click)="studentSmsSent(studentSms)"><i
                    class="fas fa-comment-alt"></i> Send
                  SMS</button>
                <button mat-menu-item (click)="studentEmailSentInvite()"
                  *ngIf="checkActionPermission('Invite to Login (Email)')"><i class="fas fa-exchange-alt"></i> Invite to
                  Login (Email)</button>
                <button mat-menu-item (click)="studentSmsSentInvite()"
                  *ngIf="checkActionPermission('Invite to Login (SMS)')"><i class="fas fa-exchange-alt"></i> Invite to
                  Login
                  (SMS)</button>
                <h4 class="actionSelected">Academics</h4>
                <button mat-menu-item (click)="printId()" *ngIf="checkActionPermission('Print ID Card')"><i
                    class="fas fa-id-card-alt"></i> Print ID Cards</button>
                <button mat-menu-item (click)="printReport()" *ngIf="checkActionPermission('Print Report Cards')"><i
                    class="fas fa-print"></i> Print Report Cards</button>
                <button mat-menu-item (click)="Enable()" *ngIf="checkActionPermission('Enable Report Cards')"><i
                    class="fas fa-address-card"></i> Enable Report Cards</button>
                <button mat-menu-item (click)="Disable()" *ngIf="checkActionPermission('Disable Report Cards')"><i
                    class="far fa-id-card"></i> Disable Report Cards</button>
                <h4 class="actionSelected">Other Actions</h4>
                <button mat-menu-item (click)="Block()" *ngIf="checkActionPermission('Block Student')"><i
                    class="fas fa-ban"></i> Block</button>
                <button mat-menu-item (click)="Unblock()" *ngIf="checkActionPermission('Unblock Student')"><i
                    class="fas fa-unlock-alt"></i> Unblock</button>
                <button mat-menu-item (click)="MovetoAlumni()" *ngIf="checkActionPermission('Move to Alumni')"><i
                    class="fas fa-exchange-alt"></i> Move to Alumni</button>
                <button mat-menu-item (click)="DeleteMultipleStudent()"
                  *ngIf="checkActionPermission('Delete Student')"><i class="fas fa-trash"></i> Delete</button>
              </mat-menu>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="matCardBody">
      <div class="tableActionTop">
        <div class="tableFilter">
          <!-- <a (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden">Filter</span></a> -->
          <!-- <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>First Name</mat-label>
                      <input matInput type="text" formControlName="firstName">
                    </mat-form-field>
                  </div>

                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Last Name</mat-label>
                      <input matInput type="text" formControlName="lastName">
                    </mat-form-field>
                  </div>


                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Admission Number</mat-label>
                      <input matInput type="text" formControlName="admissionNumber">
                    </mat-form-field>
                  </div>

                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Email Address</mat-label>
                      <input matInput type="text" formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Phone Number</mat-label>
                      <input matInput type="text" formControlName="contactNumber">
                    </mat-form-field>
                  </div>

                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>

                </div>
              </form>
            </div> -->
        </div>
        <div class="rightTableActions centerItemVert">


        </div>

      </div>
      <div class="mat-elevation-z8" *ngIf="checkActionPermission('Student List')">
        <table id="manage-student-excel-table" mat-table class="w-100" [dataSource]="dataSource" matSort
          matSortDisableClear matSortDirection="asc" (matSortChange)="SortChange($event)">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                (change)="changee($event,row)" [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="columnName" *ngFor="let columnName of displayedColumnsList">
            <th [hidden]="(columnName == 'Section' || columnName == 'Class')" mat-header-cell mat-sort-header
              *matHeaderCellDef>{{ columnName }}</th>
            <td [hidden]="(columnName == 'Section' || columnName == 'Class')" mat-cell *matCellDef="let row">
              <span *ngIf="columnName != 'profilePhoto' && columnName != 'First Name' && columnName != 'Last Name'"
                [innerHTML]="row[columnName]"></span>
              <span *ngIf="columnName == 'First Name'">
                <a (click)="infoCall(row['studentID'])">{{row[columnName]}}</a><br>
                <ng-container *ngFor="let student of coreList">
                  <ng-container *ngIf="student.studentID==row['studentID']">
                    <span *ngFor="let classsection of student.classSectionModel">
                      <span *ngIf="displayedColumnsList.includes('Class')"
                        class="tableclass mr-2">{{classsection.className}}
                        &nbsp; </span>
                      <span *ngIf="displayedColumnsList.includes('Section')"
                        class="tablesection">{{classsection.sectionName}} <br></span>
                    </span>
                  </ng-container>
                </ng-container>
                <!-- <span *ngIf="displayedColumnsList.includes('Class')" class="tableclass">  {{row['Class']}}</span><br>
              <span *ngIf="displayedColumnsList.includes('Section')" class="tablesection">   {{row['Section']}}</span> -->
              </span>
              <span *ngIf="columnName == 'Last Name'"><a
                  (click)="infoCall(row['studentID'])">{{row[columnName]}}</a></span>
              <span *ngIf="columnName == 'profilePhoto'"><img [src]="row[columnName]" alt=""
                  onerror="this.src='../../../../../assets/img/avtaradmin.jpg';"></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <Button *ngIf="checkActionPermission('View Student')" title="Details"
                (click)="infoCall(element.studentID)" class="tbactionbtn"><i class="fas fa-eye"></i></Button>
              <Button *ngIf="checkActionPermission('Add Student Attachment')" title="Attachment"
                (click)="singleattachment(element.studentID)" class="tbactionbtn"><i
                  class="fas fa-paperclip"></i></Button>
              <Button *ngIf="checkActionPermission('Update Student')" title="Edit" (click)="editCall(element.studentID)"
                class="tbactionbtn"><i class="fas fa-edit"></i></Button>
              <Button *ngIf="checkActionPermission('Reset Student Password')" title="Reset"
                (click)="resetCall(element.studentID)" class="tbactionbtn"><i class="fas fa-lock"></i></Button>
              <Button *ngIf="checkActionPermission('Delete Student')" title="Delete"
                (click)="DeleteStudent(element.studentID)" class="tbactionbtn"><i class="fas fa-trash"></i></Button>
            </td>

          </ng-container>
          <tr mat-header-row *matHeaderRowDef="changeColumnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: changeColumnsToDisplay"></tr>
        </table>
        <div *ngIf="metaData.totalRecords==0">
          <p>No Record Found</p>
        </div>
      </div>
      <mat-paginator [length]="metaData['totalRecords']" [pageSizeOptions]="itemperPage"
        [pageIndex]="metaData['currentPage'] - 1" [pageSize]="metaData['pageSize']" (page)="handlePage($event)"
        showFirstLastButtons></mat-paginator>



    </div>
  </div>
  <!-- </div> -->

  <ng-template #loginPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Edit Students Credentials</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="updateCreditialForm">
          <div class="">
            <mat-form-field class="w-100" appearance="outline" floatLabel="always">
              <mat-label>Username</mat-label>
              <!-- <span matSuffix class="faIcon"><i class="fas fa-user"></i></span> -->
              <input [readonly]="true" matInput required name="UserName" formControlName="userName">
              <!-- <mat-error
                *ngIf="updateCreditialForm.get('userName')?.touched && updateCreditialForm.get('UserName')?.errors?.required">
                Username is required
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Password" required [type]="hide ? 'password' : 'text'"
                formControlName="password" type="password" name="password">
              <a mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide = !hide"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
              <mat-error
                *ngIf="updateCreditialForm.get('password')?.touched && updateCreditialForm.get('password')?.errors?.required">
                Please enter your password
              </mat-error>
              <mat-error *ngIf="updateCreditialForm.get('password')?.errors?.pattern">
                Password must contain minimum eight characters,at least one uppercase and lowercase letter,one number
                and one special characters<strong>(!@#$%_^&*~)</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input matInput placeholder="Verify Password" required [type]="hide1 ? 'password' : 'text'"
                (keypress)="onMatch()" type="password" formControlName='confirmPassword'>
              <a mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide1 = !hide1"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </a>
              <mat-error
                *ngIf="updateCreditialForm.get('confirmPassword')?.touched && updateCreditialForm.get('confirmPassword')?.errors?.required">
                Please enter your Confirm password
              </mat-error>
              
            </mat-form-field>
          </div>
          <div *ngIf="this.updateCreditialForm.errors?.match && !updateCreditialForm.get('confirmPassword')?.errors?.required" class="state"><span style="color: red">
            Password & Confirm Password are not matching</span></div>
          <div class="form-group17">
            <p>
              <mat-checkbox formControlName="logoutFromAll">Logout from all active login sessions in mob app
              </mat-checkbox>
            </p>
            <p>
              <mat-checkbox formControlName="sendUpdateNotification">Send update notification email to students
              </mat-checkbox>
            </p>
          </div>
          <div class="form-group17 text-right">
            <button [disabled]="updateCreditialForm.invalid" (click)="updateCreditial()" class="btn btn-primary btn-sm"
              mat-dialog-close>Update</button>
          </div>
        </form>
      </mat-dialog-content>
    </div>
  </ng-template>
  <ng-template #hideShowColPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Hide/Show Colums</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <p><a (click)="selectAllField()" class="tags underlinedLink">Select All</a> / <a (click)="unselectAllField()"
            class="tags underlinedLink">Unselect All</a>
        </p>
        <div class="row-flex" cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="col s12 m12 form-group">
            <mat-checkbox class="example-margin" [disabled]="true" [(ngModel)]="checked">
              Profile Picture
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group" *ngFor="let field of hideshowGridColumnList" cdkDrag>
            <mat-checkbox class="example-margin" [(ngModel)]="field.isShowInGrid" (change)="onCheck()">
              {{field.fieldName}}
            </mat-checkbox>
          </div>
        </div>
      </mat-dialog-content>
    </div>
  </ng-template>
  <!-- <ng-template #hideShowColPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Hide/Show Colums</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <p><a (click)="selectAll()" class="tags underlinedLink">Select All</a> / <a (click)="unselectAll()"
            class="tags underlinedLink">Unselect All</a>
        </p>
        <form [formGroup]="columnForm">
          <div class="row-flex">
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" [disabled]="true" (change)="onSelectColumn(1)"
                formControlName="firstName">
                First Name
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="lastName">
                Last Name
              </mat-checkbox>
            </div>
            
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="admissionNo">
                Admission No.
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="email">
                Email
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="phoneNumber">
                Phone Number
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="dateOfBirth">
                dob
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="section">
                Section
              </mat-checkbox>
            </div>

            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="gender">
                gender
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="bloodGroup">
                bloodGroup
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" (change)="onSelectColumn(1)" formControlName="profileSummary">
                profileSummary
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="studentSkill" (change)="onSelectColumn(1)">
                Skills
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="facebookProfile" (change)="onSelectColumn(1)">
                Facebook Link
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="linkedInProfile" (change)="onSelectColumn(1)">
                Linkedin Profile Link
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="address" (change)="onSelectColumn(1)">
                Address
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="zipcode" (change)="onSelectColumn(1)">
                Zip Code
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="country" (change)="onSelectColumn(1)">
                Country
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="motherName" (change)="onSelectColumn(1)">
                Mother Name
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="fatherName" (change)="onSelectColumn(1)">
                Father Name
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="parentContact" (change)="onSelectColumn(1)">
                Parent Contact
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="parentEmail" (change)="onSelectColumn(1)">
                Parent email
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="applicationId" (change)="onSelectColumn(1)">
                Application ID
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="enrollment" (change)="onSelectColumn(1)">
                Enrollment
              </mat-checkbox>
            </div>
          </div>
        </form>

      </mat-dialog-content>
    </div>
  </ng-template> -->


  <ng-template #deletePopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title> Do you want to delete ?</h5>
      </div>
      <mat-dialog-content class="mat-typography">
        <!-- <p class="mb-4 center ">Do you want to delete ?</p> -->
        <div class="btnsWidget form-group center">
          <!-- <button (click)=confirmDelete() class="btn btn-primary btn-sm">Yes</button>(ngSubmit)="onSubmitSubject() -->
          <button mat-dialog-close class="btn btn-reset btn-sm">NO</button>

        </div>
      </mat-dialog-content>
    </div>
  </ng-template>

  <ng-template #importPopup>
    <form>
      <div class="dialog-md">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
          <h2 mat-dialog-title> Import Students</h2>
        </div>
        <mat-dialog-content class="mat-typography">
          <div class="btnsWidget form-group center">
            <div class="form-group">
              <!-- <input #fileInput type="file"> -->
              <p class="chngePicupload">
                <input type="file" accept=".csv">
                <img src="../../../../../../assets/img/upload_icon.svg" alt="">
              </p>
              <p class="note"><b>Note: </b>Please upload only CSV file</p>
            </div>
          </div>
        </mat-dialog-content>
        <div class="form-group17 text-right mb-0">
          <button (click)="addFile()" class="btn btn-primary btn-sm" mat-dialog-close>Import</button>
        </div>
      </div>
    </form>
  </ng-template>


  <ng-template #exportPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Export Student Data</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <p><a (click)="selectAllExport()" class="tags underlinedLink">Select All</a> / <a (click)="unselectAllExport()"
            class="tags underlinedLink">Unselect All</a>
        </p>
        <div class="row-flex">
          <div *ngFor="let field of exportField" class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" [(ngModel)]="field.checkbox">
              {{field.fieldName}}
            </mat-checkbox>
          </div>
        </div>
        <div class="form-group17 text-right">
          <button (click)="export()" class="btn btn-primary btn-sm" mat-dialog-close>Export</button>
        </div>
        <!-- <form [formGroup]="exportForm" (ngSubmit)="export()">
          <div class="row-flex">
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="firstName">
                First Name
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="lastName">
                Last Name
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="admissionNo">
                Admission No.
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="studentEmail">
                Email
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="dob">
                Date of Birth
              </mat-checkbox>
            </div>

            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="studentContact">
                Student Contact
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="gender">
                Gender
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="bloodGroup">
                Blood Group
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="profileSummary">
                Profile Summary
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="studentSkill">
                Skills
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="facebookProfile">
                Facebook Link
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="linkedInProfile">
                Linkedin Profile Link
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="address">
                Address
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="zipcode">
                Zip Code
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="state">
                State
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="country">
                Country
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="motherName">
                Mother Name
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="fatherName">
                Father Name
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="parentContact">
                Parent Contact
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="parentEmail">
                Parent email
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="applicationId">
                Application ID
              </mat-checkbox>
            </div>
            <div class="col s12 m6 form-group">
              <mat-checkbox class="example-margin" formControlName="enrollment">
                Enrollment
              </mat-checkbox>
            </div>
          </div>
          <div class="form-group17 text-right">
            <button type="submit" [disabled]="exportForm.invalid" class="btn btn-primary btn-sm"
              mat-dialog-close>Export</button>
          </div>
        </form> -->

      </mat-dialog-content>
    </div>
  </ng-template>
  <ng-template #studentEnrollment>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <!-- <h2 mat-dialog-title>Student Enrollment</h2> -->
        <!-- <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Student Enrollment Status</h2>
        <ng-template #elseTemplate>
          <h2 mat-dialog-title>Student Enrollment</h2>
        </ng-template> -->
        <h2 mat-dialog-title>Student Enrollment</h2>
      </div>
      <form [formGroup]="studentEnrollmentForm" (ngSubmit)="onSubmitStudentEnrollment()">
        <div>
          <p class="graphTitle">You are about to enroll selected {{selectedData}} Students into class section as
            selected below:</p>
          <!-- <mat-chip-list class="example-chip selected-chips" cdkDropList cdkDropListOrientation="horizontal">
            <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
              {{student.firstName}} {{student.lastName}}
            </mat-chip>
          </mat-chip-list> -->
        </div>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Academic Session</mat-label>
          <mat-select placeholder="Session" formControlName="academicSessionID">
            <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
              {{session.academicSessionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="studentEnrollmentForm.get('academicSessionID')?.touched  && studentEnrollmentForm.get('academicSessionID')?.errors?.required">
            academicSession is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Class</mat-label>
          <mat-select placeholder="Select Class" (selectionChange)="filterSection($event)" formControlName="classID">
            <mat-option value="" disabled>Select Class</mat-option>
            <mat-option *ngFor="let classSelect of enrollClassList" [value]="classSelect.classID">
              {{classSelect.className}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="studentEnrollmentForm.get('classID')?.touched && studentEnrollmentForm.get('classID')?.errors?.required">
            Please Select a class
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Section</mat-label>
          <mat-select placeholder="Select Class" formControlName="sectionID">
            <mat-option value="" disabled>Select Section</mat-option>
            <mat-option *ngFor="let sectionSelect of sectionListDropdown" [value]="sectionSelect.sectionID">
              {{sectionSelect.sectionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="studentEnrollmentForm.get('sectionID')?.touched && studentEnrollmentForm.get('sectionID')?.errors?.required">
            Please Select a Section
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Enrollment Status</mat-label>
          <mat-select placeholder="Select Enrollment Status" formControlName="studentEnrollmentStatusID">
            <mat-option value="" disabled>Select Enrollment Status</mat-option>
            <mat-option *ngFor="let sectionStatus of studentEnrollmentStatus"
              [value]="sectionStatus.studentEnrollmentStatusID">
              {{sectionStatus.studentEnrollmentStatusName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="studentEnrollmentForm.get('studentEnrollmentStatusID')?.touched && studentEnrollmentForm.get('studentEnrollmentStatusID')?.errors?.required">
            Please Enrollment Status
          </mat-error>
        </mat-form-field>

        <div class="btnsWidget form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close
            [disabled]="studentEnrollmentForm.invalid">Save</button>
          <button typetype="reset" (click)="resetNew()" class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
        </div>

        <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
          [disabled]="addSubjectForm.invalid">Save</button>
      </div> -->
      </form>
    </div>
  </ng-template>

  <ng-template #updateStudentEnrollment>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <!-- <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Student Enrollment Status</h2>
        <ng-template #elseTemplate>
          <h2 mat-dialog-title>Student Enrollment</h2>
        </ng-template> -->
        <h2 mat-dialog-title>Update Student Enrollment Status</h2>
      </div>
      <form [formGroup]="updateStudentEnrollmentForm" (ngSubmit)="onSubmitUpdateStudentEnrollmentStatus()">
        <div>
          <p class="graphTitle">You are about to enroll selected {{selectedData}} Students into class section as
            selected below:</p>
          <!-- <mat-chip-list class="example-chip selected-chips" cdkDropList cdkDropListOrientation="horizontal">
            <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
              {{student.firstName}} {{student.lastName}}
            </mat-chip>
          </mat-chip-list> -->
        </div>
        <!-- <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Academic Session</mat-label>
          <mat-select placeholder="Session" formControlName="academicSessionID">
            <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
              {{session.academicSessionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="studentEnrollmentForm.get('academicSessionID')?.touched  && studentEnrollmentForm.get('academicSessionID')?.errors?.required">
            academicSession is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Class</mat-label>
          <mat-select placeholder="Select Class" (selectionChange)="filterSection($event)" formControlName="classID">
            <mat-option value="Select Class">SelectClass</mat-option>
            <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
              {{classSelect.className}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="studentEnrollmentForm.get('classID')?.touched && studentEnrollmentForm.get('classID')?.errors?.required">
            Please Select a class
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Section</mat-label>
          <mat-select placeholder="Select Class" formControlName="sectionID">
            <mat-option value="Select Section">Select Section</mat-option>
            <mat-option *ngFor="let sectionSelect of sectionListDropdown" [value]="sectionSelect.sectionID">
              {{sectionSelect.sectionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="studentEnrollmentForm.get('sectionID')?.touched && studentEnrollmentForm.get('sectionID')?.errors?.required">
            Please Select a Section
          </mat-error>
        </mat-form-field> -->
        <div class="w-100">
          <div class="row">
            <div class="col-6"><b>Academic Session :</b></div>
            <div class="col-6"> {{SessionName}}</div>
          </div>
      
        </div>
        <div class="w-100 mt-1">
          <div class="row">
            <div class="col-6"><b>Class - Section :</b></div>
            <div class="col-6"> {{ClassName}} - {{SectionName}}</div>
            </div>
         
        </div>
        <mat-form-field class="w-100 mt-3" appearance="outline">
          <mat-label>Select Enrollment Status</mat-label>
          <mat-select placeholder="Select Enrollment Status" formControlName="studentEnrollmentStatusID">
            <mat-option value="" disabled>Select Enrollment Status</mat-option>
            <mat-option *ngFor="let sectionStatus of studentEnrollmentStatus"
              [value]="sectionStatus.studentEnrollmentStatusID">
              {{sectionStatus.studentEnrollmentStatusName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="updateStudentEnrollmentForm.get('studentEnrollmentStatusID')?.touched && updateStudentEnrollmentForm.get('studentEnrollmentStatusID')?.errors?.required">
            Please Enrollment Status
          </mat-error>
        </mat-form-field>

        <div class="btnsWidget form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close
            [disabled]="updateStudentEnrollmentForm.invalid">Save</button>
          <button typetype="reset" (click)="resetNew()" class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
        </div>

        <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
          [disabled]="addSubjectForm.invalid">Save</button>
      </div> -->
      </form>
    </div>
  </ng-template>

  <ng-template #subjectEnrollment>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Elective Subject Enrollment</h2>
      </div>
      <form [formGroup]="subjectEnrollmentForm" (ngSubmit)="onSubmitElectiveSubjectEnrollment()">
        <div>
          <p class="graphTitle">You are about to enroll selected {{selectedData}} Students into elective subjects as
            selected below:</p>
          <!-- <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
            <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
              {{student.firstName}} {{student.lastName}}
            </mat-chip>
          </mat-chip-list> -->
        </div>
        <div class="w-100">
          <div class="row">
            <div class="col-6 ">
              <b>Academic Session :</b>
            </div>
            <div class="col-6">
              {{SessionName}}
            </div>
          </div>
        

        </div>
        <!-- <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Academic Session</mat-label>
          <mat-select placeholder="Select Acdemic Session" readonly formControlName="academicSessionID">
            <mat-option *ngFor="let session of academicSessions" disabled [value]="session.academicSessionID">
              {{session.academicSessionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="subjectEnrollmentForm.get('academicSessionID')?.touched  && subjectEnrollmentForm.get('academicSessionID')?.errors?.required">
            academicSession is required
          </mat-error>
        </mat-form-field> -->
        <!-- <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Class</mat-label>
          <mat-select placeholder="Select Class" (selectionChange)="filterSection($event)" formControlName="classID">
            <mat-option value="Select Class">SelectClass</mat-option>
            <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
              {{classSelect.className}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="subjectEnrollmentForm.get('classID')?.touched && subjectEnrollmentForm.get('classID')?.errors?.required">
            Please Select a class
          </mat-error>
        </mat-form-field> -->
        <div class="w-100 mt-1">
          <div class="row">
            <div class="col-6"><b>Class - Section :</b></div>
            <div class="col-6">{{ClassName}} - {{SectionName}}</div>
            </div>
       
        </div>
        <!-- <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Section</mat-label>
          <mat-select placeholder="Select Class" (selectionChange)="filterElectiveSubject($event)"
            formControlName="sectionID">
            <mat-option value="Select Section">Select Section</mat-option>
            <mat-option *ngFor="let sectionSelect of sectionListDropdown" [value]="sectionSelect.sectionID">
              {{sectionSelect.sectionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="subjectEnrollmentForm.get('sectionID')?.touched && subjectEnrollmentForm.get('sectionID')?.errors?.required">
            Please Select a Section
          </mat-error>
        </mat-form-field> -->

        <!-- <mat-form-field class="w-100" appearance="outline"> -->
        <div class="searchSelect form-group mt-5">
          <!-- <mat-select placeholder="Select Subjects" class="form-control borderRadius8 w-100"
              formControlName="subjectID" multiple>
              <mat-option *ngFor="let item of subjectList" [value]="item.subjectID">{{item.subjectName}}
              </mat-option>
            </mat-select> -->
            <div class="form-group multiselected">
              <div class="w-100" appearance="outline">
          <p-multiSelect [options]="ElectiveSubjectListDropdown" required formControlName="subjectID"
            defaultLabel="Select a Elective Subject" (onChange)="onElementChange($event)" optionLabel="subjectName"
            display="chip"></p-multiSelect>
          <mat-label class="mutilselectlable">Elective Subject</mat-label>
          </div></div>
          <mat-error class="mt-2"
          *ngIf="subjectEnrollmentForm.get('subjectID')?.touched && subjectEnrollmentForm.get('subjectID')?.errors?.required">
          Please Select Subject
        </mat-error>
        </div>
        <!-- <mat-label>Elective Subject</mat-label>
          <mat-select placeholder="Select Enrollment Status" formControlName="subjectID">
            <mat-option value="Select Enrollment Status" disabled>Select Elective Subject</mat-option>
            <mat-option *ngFor="let subject of ElectiveSubjectListDropdown" [value]="subject.subjectID">
              {{subject.subjectName}}
            </mat-option>
            <mat-option *ngIf="electiveSubjectFlag==true" disabled>
              No elective subject Found
            </mat-option>
          </mat-select> -->
     
        <!-- </mat-form-field> -->

        <div class="btnsWidget form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close
            [disabled]="subjectEnrollmentForm.invalid">Save</button>
          <button typetype="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
        </div>

        <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
          [disabled]="addSubjectForm.invalid">Save</button>
      </div> -->
      </form>
    </div>
  </ng-template>

  <ng-template #updateSubjectEnrollmentStatus>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Update Subject Enrollment Status</h2>
      </div>
      <form [formGroup]="UpdateSubjectEnrollmentStatusForm" (ngSubmit)="onSubmitUpdateSubjectEnrollmenttatus()">
        <div>
          <p class="graphTitle">You are about to update subjectenrollment status selected {{selectedData}} Students as
            selected below:</p>
          <!-- <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
            <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
              {{student.firstName}} {{student.lastName}}
            </mat-chip>
          </mat-chip-list> -->
        </div>
        <!-- <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Academic Session</mat-label>
          <mat-select placeholder="Session" formControlName="academicSessionID">
            <mat-option value="Select Session">Academic Sessions</mat-option>
            <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
              {{session.academicSessionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="UpdateSubjectEnrollmentStatusForm.get('academicSessionID')?.touched  && UpdateSubjectEnrollmentStatusForm.get('academicSessionID')?.errors?.required">
            Academic Session is required
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Class</mat-label>
          <mat-select placeholder="Select Class" (selectionChange)="filterSection($event)" formControlName="classID">
            <mat-option value="Select Class">SelectClass</mat-option>
            <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
              {{classSelect.className}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="UpdateSubjectEnrollmentStatusForm.get('classID')?.touched && UpdateSubjectEnrollmentStatusForm.get('classID')?.errors?.required">
            Please Select a class
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Section</mat-label>
          <mat-select placeholder="Select Class" (selectionChange)="filterElectiveSubject($event)"
            formControlName="sectionID">
            <mat-option value="Select Section">Select Section</mat-option>
            <mat-option *ngFor="let sectionSelect of sectionListDropdown" [value]="sectionSelect.sectionID">
              {{sectionSelect.sectionName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="UpdateSubjectEnrollmentStatusForm.get('sectionID')?.touched && UpdateSubjectEnrollmentStatusForm.get('sectionID')?.errors?.required">
            Please Select a Section
          </mat-error>
        </mat-form-field> -->
        <div class="w-100">
          <div class="row">
            <div class="col-6">
             <b>Academic Session :</b>  
            </div>
            <div class="col-6">{{SessionName}}</div>
          </div>
 
        </div>
        <div class="w-100">
          <div class="row">
            <div class="col-6"><b>Class - Section :</b></div>
            <div class="col-6">  {{ClassName}} - {{SectionName}}</div>
          </div>
         
        </div>
        <!-- <mat-form-field class="w-100" appearance="outline">
          <mat-label> Subject</mat-label>
          <mat-select placeholder="Select Status" formControlName="subjectID">
            <mat-option value="Select Status">Select Subject</mat-option>
            <mat-option *ngFor="let subject of SubjectListDropdown" [value]="subject.subjectID">
              {{subject.subjectName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="UpdateSubjectEnrollmentStatusForm.get('subjectID')?.touched && UpdateSubjectEnrollmentStatusForm.get('subjectID')?.errors?.required">
            Please Select Subject
          </mat-error>
        </mat-form-field> -->
        <div class="w-100">
          <div class="row">
            <div class="col-6"><b>Subject :</b></div>
            <div class="col-6"> {{SubjectName}}</div>
          </div>
         
        </div>

        <mat-form-field class="w-100 mt-2" appearance="outline">
          <mat-label>Select Enrollment Status</mat-label>
          <mat-select placeholder="Select Enrollment Status" formControlName="subjectEnrollmentStatusID">
            <mat-option value="" disabled>Select Enrollment Status</mat-option>
            <mat-option *ngFor="let sectionStatus of subjectEnrollmentStatusDropdown"
              [value]="sectionStatus.subjectEnrollmentStatusID">
              {{sectionStatus.subjecttEnrollmentStatusName}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="UpdateSubjectEnrollmentStatusForm.get('subjectEnrollmentStatusID')?.touched && UpdateSubjectEnrollmentStatusForm.get('subjectEnrollmentStatusID')?.errors?.required">
            Please Enrollment Status
          </mat-error>
        </mat-form-field>
        <div class="btnsWidget form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close
            [disabled]="UpdateSubjectEnrollmentStatusForm.invalid">Save</button>
          <button typetype="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #studentEmail>
    <div>
      <i class="material-icons popupClose" (click)="resetEmailD()" mat-dialog-close>clear</i>
      <div class="dialogTitle ">
        <h2 mat-dialog-title>Send Student Email</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="studentEmailForm" (ngSubmit)="onSubmitstudentEmail()">
          <div>
            <!-- <p class="graphTitle"> selected {{selectedData}} Students:</p> -->

          </div>
          <div class="row-flex">
            <div class="col-12 form-group">
              <mat-label>Send As</mat-label>
              <br>
              <br>
              <!-- <mat-checkbox [checked]='true' class="example-margin" [disabled]="true">
              info@creocms.com.au
            </mat-checkbox> -->
              <mat-radio-group aria-label="Select an option" formControlName="senderEmail" required>
                <mat-radio-button value=true [checked]="true" (change)="instituteMail()">info@creocms.com.au(Institute's
                  email)</mat-radio-button>
                <mat-radio-button value=false (change)="userMail()">{{userEmail}}(Your Email)</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="col-12 form-group">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Subject </mat-label>
                <input required maxlength="100" formControlName="subject" matInput placeholder="subject">
                <mat-error
                  *ngIf="studentEmailForm.get('subject')?.touched  && studentEmailForm.get('subject')?.errors?.required">
                  Subject is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 form-group">
              <mat-label class="mb-2">Receivers</mat-label>
              <br>
              <mat-checkbox [checked]="true" class="example-margin" (change)="onStudentEmailChange()"
                formControlName="studentEmailCheck">
                Students Email
              </mat-checkbox>
              <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal" readyonly>
                <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                  {{student.email}}
                </mat-chip>
              </mat-chip-list>
              <!-- <mat-form-field class="w-100" appearance="outline">
              <textarea class="CustomboxEmail" *ngFor="let student of studentData.email?.split(',')" matInput  readonly 
              placeholder="Enter custom email addresses in comma separated format">
              {{student}}
            </textarea>
          </mat-form-field>  -->
            </div>

          </div>
          <div class="row-flex">
            <!-- <div class="col s12 m6 form-group">
              <mat-label>Send As</mat-label>
               <mat-checkbox [checked]='true' class="example-margin" [disabled]="true">
                info@creocms.com.au
              </mat-checkbox> 
              <mat-radio-group aria-label="Select an option" formControlName="senderEmail" required>
                <mat-radio-button value=true [checked]="true" (change)="instituteMail()">info@creocms.com.au(Institute's email)</mat-radio-button>
                <mat-radio-button  value=false (change)="userMail()">{{userEmail}}(Your Email)</mat-radio-button>
              </mat-radio-group>
            </div> -->

            <div class="col-12 form-group">
              <mat-checkbox [checked]='true' class="example-margin" (change)="onparentChange()"
                formControlName="parentCheck">
                Parents Email
              </mat-checkbox>
              <ng-container *ngIf="!boxhide">
                <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                  <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                    {{student.parentEmail}}
                  </mat-chip>
                </mat-chip-list>
              </ng-container>
            </div>
            <!-- <div class="col s12 m12 form-group">
              <mat-checkbox [checked]='true' class="example-margin" (change)="onparentChange()"
                formControlName="parentCheck">
                Parents Email
              </mat-checkbox>
              <ng-container *ngIf="!boxhide">
                <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                  <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                    {{student.parentEmail}}
                  </mat-chip>
                </mat-chip-list>
              </ng-container>
            </div> -->
            <div class="col-12 form-group">
              <mat-checkbox [checked]='false' class="example-margin" (change)="oncustomChange()"
                formControlName="customCheck">
                Custom Email
              </mat-checkbox>
              <!-- *ngIf="customboxhide" -->
              <ng-container>
                <br>
                <div class="col s12 m12 form-group mb-0">
                  <mat-form-field class="w-100" appearance="outline">
                    <textarea class="CustomboxEmail" matInput [readonly]="!customboxhide" formControlName="customEmail"
                      placeholder="Enter custom email addresses in comma separated format"></textarea>
                  </mat-form-field>
                </div>
                <!-- <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                  <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                    {{student.parentEmail}}
                  </mat-chip>
                </mat-chip-list> -->
              </ng-container>

            </div>

            <div class="col-12 form-group">



              <!-- <select formControlName="template" class="form-control w-100" (change)="selectChangeHandler($event)">
                    <option value="6">Default</option>  
                    <option value="6">Custom Template</option>  
                </select>   -->
              <!-- <mat-label class="mb-2"></mat-label> -->
              <div class="px-2">
                <!-- 
                  <mat-form-field class="w-100" appearance ="outline">
                    <mat-label>Select Academic Session</mat-label>
                    <mat-select formControlName="AcademicSessionID" placeholder="Session">
                     <mat-option *ngIf="!academicSessions">No Academic Sessions</mat-option>
                     <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
                       {{session.academicSessionName}}
                     </mat-option>
                   </mat-select>
                  </mat-form-field> -->


                <mat-form-field appearance="outline">
                  <mat-label>Select Template</mat-label>
                  <mat-select formControlName="template" (selectionChange)="selectChangeTemplate($event)"
                    name="template">
                    <mat-option *ngFor="let temp of customtemplate" [value]="temp.emailTemplateID">
                      {{temp.templateName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
              <div class="text-right">
                <!-- <button class="btn btn-primary btnsmall" (change)="dataVaiable()"> Data Variables</button> -->
                <a (click)="dataVaiable()">
                  <p>Data Variables</p>
                </a>
              </div>
              <div *ngIf="!dataVariablebox" class="col-lg-12 col-sm-12 dataVarlist mt-2">
                <div class="template-group-title">
                  You can use following data variables in this email template:
                </div>
                <ul class="mt-2">
                  <h2>Students Attributes</h2>
                </ul>
                <ul>
                  <li>First Name - [student-first_name]</li>
                  <li>Last Name - [student-last_name]</li>
                  <li>Admission No - [student-admission_number]</li>
                  <li>Date of Birth - [student-student_dob]</li>
                  <li>Gender - [student-gender]</li>
                  <li>Address - [student-address]</li>
                  <li>Zip Code - [student-zipcode]</li>
                  <li>Student Email - [student-student_email]</li>
                  <li>Blood Group - [student-blood_group]</li>
                  <li>Profile Summary - [student-profile_summary]</li>
                  <li>Facebook Link - [student-fb_profile_link]</li>
                  <li>Linkedin Profile Link - [student-linkedin_profile_link]</li>
                  <li>Country - [student-country_id]</li>
                  <li>State - [student-state]</li>
                  <li>Father Name - [student-father_name]</li>
                  <li>Mother Name - [student-mother_name]</li>
                  <li>Parent Contact - [student-parents_contact]</li>
                  <li>Parent email - [student-parents_email]</li>
                  <li>Skills - [student-skills]</li>
                  <li>Student Contact - [student-student_contact]</li>
                  <li>Middle name - [student-middle-name]</li>
                  <li>Hobbies - [student-hobbies]</li>
                </ul>
                <ul class="mt-2">
                  <h2>Institute</h2>
                </ul>
                <ul>
                  <li>Name - [inst-name] </li>
                  <li>Institute URL - [inst-url]</li>
                </ul>
              </div>

              <!-- <label for="website">Website:</label>   -->

            </div>



            <div class="col-12 form-group">
              <!-- <mat-form-field class="w-100" appearance="outline">
            <mat-label>Message</mat-label>
            <textarea required maxlength="256" formControlName="body" matInput placeholder="Message" ></textarea>
            <mat-error *ngIf="studentEmailForm.get('body')?.touched  && studentEmailForm.get('body')?.errors?.required">
              Message is required
            </mat-error>
        </mat-form-field> -->
              <div class="px-2">
                <ejs-richtexteditor #textEditorValue required formControlName="body" [toolbarSettings]='tools'>
                </ejs-richtexteditor>
              </div>
              <!-- [maxLength]='256' -->
            </div>

            
            <div class="form-group col-12 px-4">
              <input formControlName="attachment" (change)="uploadFile($event)" id="file" type="file" multiple>
          </div>


          </div>

          <div class="btnsWidget form-group17 text-right">
            <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close
              [disabled]="studentEmailForm.invalid">Send</button>
            <button (click)="resetEmailD()" class="btn btn-reset btn-sm" mat-dialog-close>Close</button>
          </div>

          <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
          [disabled]="addSubjectForm.invalid">Save</button>
      </div> -->
        </form>
      </mat-dialog-content>
    </div>
  </ng-template>



  <ng-template #studentSms>
    <div>
      <i class="material-icons popupClose" (click)="resetSMSForm()" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Send Student SMS </h2>
      </div>
      <mat-dialog-content class="mat-typography">
      <form [formGroup]="studentSmsForm" (ngSubmit)="onSubmitstudentSms()">
        <div>


        </div>
        <div class="row-flex">
          <div class="col s12 m12 form-group">
            <mat-label>Receivers</mat-label>
            <br>
            <mat-checkbox [checked]='true' class="example-margin" [disabled]="true">
              Students Contact Number
            </mat-checkbox>
            <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
              <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                {{student.contactNumber}}
              </mat-chip>
            </mat-chip-list>
          </div>


          <div class="col s12 m12 form-group">
            <mat-checkbox [checked]='false' class="example-margin" (change)="onparentSmsChange()"
              formControlName="parentCheckSms">
              Parents Contact Number
            </mat-checkbox>
            <ng-container *ngIf="!boxhidesms">
              <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                <mat-chip class="example-box" cdkDrag *ngFor="let student of studentData">
                  {{student.parentContactNumber}}
                </mat-chip>
              </mat-chip-list>
            </ng-container>

          </div>

          <div class="col-12 form-group">
            <mat-checkbox [checked]='false' class="example-margin" (change)="oncustomChangeSMS()"
              formControlName="customCheck">
              custom numbers
            </mat-checkbox>
            <ng-container>
              <br>
              <div class="col s12 m12 form-group mb-0">
                <mat-form-field class="w-100" appearance="outline">
                  <textarea class="textareaForSMS" matInput [readonly]="!customboxhideSMS" formControlName="customSMS"
                    placeholder="enter custom numbers in comma separated format"></textarea>
                </mat-form-field>
              </div>
            </ng-container>
          </div>

          <div class="col s12 m12 form-group">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Message</mat-label>
              <textarea required maxlength="256" formControlName="message" matInput placeholder="Message"></textarea>
              <mat-error
                *ngIf="studentSmsForm.get('message')?.touched  && studentSmsForm.get('message')?.errors?.required">
                Message is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="btnsWidget form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close
            [disabled]="studentSmsForm.invalid">Send</button>
          <button typetype="reset" (click)="resetSMSForm()" class="btn btn-reset btn-sm" mat-dialog-close>Close</button>
        </div>
      </form>
      </mat-dialog-content>
    </div>
  </ng-template>

  <!-- <button mat-button (click)="actionDialogPopup(manageattachments)">Open dialog</button>
    <ng-template #manageattachments>
      <div class="dialog-xl attachment-popup">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
          <h2 mat-dialog-title>Manage Attachments</h2>
        </div>
        <mat-dialog-content class="mat-typography">
          <mat-accordion>
            <mat-expansion-panel  >
              <mat-expansion-panel-header class="pl-0">
                <mat-panel-title>
                  Hide Attachment Form
                </mat-panel-title>

              </mat-expansion-panel-header>

     <Form>
       <div class="form-group">
        <mat-form-field class="example-full-width d-block" appearance="outline">
          <mat-label>Enter Title</mat-label>
          <input matInput placeholder="" value="">
        </mat-form-field>
       </div>

       <div class="form-group">

        <mat-form-field class="example-full-width d-block" appearance="outline">
          <mat-label>Leave a comment</mat-label>
          <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
        </mat-form-field>
       </div>
      <div class="form-group">
        <label for="" class="mr-2">Attachment</label>
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="1">Upload From Computer</mat-radio-button>
          <mat-radio-button value="2">Add From Google Drive</mat-radio-button>
        </mat-radio-group>
      </div>

      <input type="submit" class="btn btn-primary" value="Save">
     </Form>
            </mat-expansion-panel>

          </mat-accordion>


          <div class="attchements-container">
            <h3>Attachments</h3>

            <div class="table-responsive">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                
                <ng-container matColumnDef="srno">
                  <th mat-header-cell *matHeaderCellDef> Sr No. </th>
                  <td mat-cell *matCellDef="let element"> {{element.srno}} </td>
                </ng-container>

                
                <ng-container matColumnDef="titletext">
                  <th mat-header-cell *matHeaderCellDef> Title </th>
                  <td mat-cell *matCellDef="let element"> {{element.titletext}} </td>
                </ng-container>


                <ng-container matColumnDef="descriptiontext">
                  <th mat-header-cell *matHeaderCellDef> Description </th>
                  <td mat-cell *matCellDef="let element"> {{element.descriptiontext}} </td>
                </ng-container>

           
                <ng-container matColumnDef="attachmenttext">
                  <th mat-header-cell *matHeaderCellDef> Attachment </th>
                  <td mat-cell *matCellDef="let element"> {{element.attachmenttext}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="attachmentColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: attachmentColumns;"></tr>
              </table>





            </div>
          </div>

        </mat-dialog-content>
      </div>
    </ng-template> -->