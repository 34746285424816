import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, Salary } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SalaryService {
  constructor(private http: HttpClient) {}

  GetSalaryFormFields() {
    return this.http.get(`${environment.baseUrl}${Salary.GetSalaryFormFields}`);
  }
  
  CreateSalaryFormField(data: any){
    return this.http.post(`${environment.baseUrl}${Salary.CreateSalaryFormField}`,data);
  }

  GetSalaryForm() {
    return this.http.get(`${environment.baseUrl}${Salary.GetSalaryForm}`);
  }

  GetSalaryFormByTeacherID(TeacherID: Number) {
    return this.http.get(`${environment.baseUrl}${Salary.GetSalaryFormByTeacherID}`+'?teacherID='+TeacherID);
  }

  GetSalaryCalculationsByTeacherID(TeacherID: Number) {
    return this.http.get(`${environment.baseUrl}${Salary.GetSalaryCalculationsByTeacherID}`+'?teacherID='+TeacherID);
  }

  UpdateSalaryForm(data: any){
    return this.http.post(`${environment.baseUrl}${Salary.UpdateSalaryForm}`,data);
  }

  UpdateAllSalaryFormFields(data: any){
    return this.http.post(`${environment.baseUrl}${Salary.UpdateAllSalaryFormFields}`,data);
  }


  GetAccountAndLedgerOrgID(id: Number) {
    return this.http.get(`${environment.baseUrl}${Salary.GetAccountAndLedgerByOrgID}`+'?id='+id);
  }

}
