import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormioOptions, FormioHookOptions } from 'angular-formio';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-manage-teacher-field',
  templateUrl: './manage-teacher-field.component.html',
  styleUrls: ['./manage-teacher-field.component.css']
})
export class ManageTeacherFieldComponent implements OnInit {
  formioOptions: FormioOptions = {
    disableAlerts: true,
  }
  submitted: boolean;
  jsonFormData: Object;
  templateFormId: number;
  templateName: string;
  teacherId:number;
  public form = {
    components: []
  };
  constructor(
    private masterservice: MasterService,
    private router: Router,
    private toaster: ToastrService
    ) {
    this.submitted = false;
   }
  editFlag:boolean;
  ngOnInit(): void {
    this.editFlag = false;
    this.teacherId = 2;
    this.masterservice.GetCustomFormTemplate(this.teacherId).subscribe((res:any) =>{
      if(res.responseData != null){
        this.submitted = true
        this.editFlag = true;
        this.templateName = res.responseData.formName;
        this.form = JSON.parse(res.responseData.formInJson || '');
      console.log("Inside if",res)
    }
    else{
      this.submitted = false
    }
    })
  }
  ss:string
  SaveTemplateForm(){
    if(this.form.components.length == 0){
      this.toaster.error("Please Add Custom Field", 'Error');
      return;
    }
    const jsonFormString = JSON.stringify(this.form);
    const postData = {
      "formUniqueID": this.teacherId,
      "formName": "Teacher Custom Template",
      "formDescription": "",
      "formInJson":  jsonFormString,
    }
    console.log("AFTERSAVE",this.form)
    if(this.editFlag == false){
      console.log("INSIDE ADD NEW")
    this.masterservice.AddCustomFormTemplate(postData).subscribe((res:any) => {
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        
      }else{
        this.toaster.error(res.message, 'Error');
      }
    })
  }
  if(this.editFlag == true){
    console.log("INSIDE EDIT")
    this.masterservice.UpdateCustomFormTemplate(postData).subscribe((res:any) => {
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        
      }else{
        this.toaster.error(res.message, 'Error');
      }
    })
  }
   }
}
