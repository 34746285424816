<div class="table-responsive mt-15">

  <!-- <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner></mat-spinner>
    </div> -->

  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          (change)="changee($event,row)" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container [matColumnDef]="column.key" *ngFor="let column of displayedColumns">
      <th style="width:50px !important;" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.isSort"
        disableClear width='{{column.width}}'> {{column.displayName}} </th>
      <!-- <td mat-cell *matCellDef="let element" [style.color]="element[column.key]"> {{element[column.key]}} </td> -->
      <td mat-cell *matCellDef="let element" [style.color]="element[column.key]">
        <span *ngIf="!column.type && column.key != 'profilePhoto' && !column.type && column.key != 'profilePicture'" [innerHTML]="element[column.key]"></span>
        <span *ngIf="column.key == 'profilePhoto' || column.key == 'profilePicture'"><img [src]="element[column.key]" alt="" onerror="this.src='../../../../../assets/img/avtaradmin.jpg';"></span>
        <!-- <span *ngIf="column.key == 'firstName'"><a href="#" class="namehighlight"></a></span> -->
        <span *ngIf="column.type=='togglespan'">
          <mat-slide-toggle [checked]="element[column.key]" [disabled]="!column.permission"
            (change)="onToggleClick('toggle',element,column)"></mat-slide-toggle>
        </span>
      </td>

    </ng-container>

    <ng-container matColumnDef="Actions" *ngFor="let column of extraColumns">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true" width='{{column.width}}'>Actions</th>
      <td mat-cell *matCellDef="let row">
        <button *ngFor="let a of actionButton" class="tbactionbtn" (click)='onActionClick(a.key, row)'
          title="{{a.displayName}}">
          <i class="{{a.class}}" aria-hidden="true"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  </table>

  <mat-paginator [length]="metaData['totalRecords']" [pageSizeOptions]="itemperPage" [pageIndex]="metaData['currentPage'] - 1"
    [pageSize]="metaData['pageSize']"   showFirstLastButtons></mat-paginator>

</div>