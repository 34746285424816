<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <!-- <h2>{{firstName}} {{lastName}}</h2> -->
            <h2>{{studentName}}</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic"><i
                                class="fas fa-home"></i> Home</a>
                    <!-- <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
                            Learning</a></li> -->
                    <li class="breadcrumb-item"><a
                            routerLink="/admin/academicslearning/manage-students">Manage Students</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Enrollment</li>
                </ol>
            </div>
           
        </div>

        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
            </div>
          </div>
    </div>
    <div class="cardWidget">
        <!-- <div class="cardTitle centerVTItems justify-content-between"><h3>Virat Sharma</h3></div> -->
        <app-profile-tabs></app-profile-tabs>
        <div class="cardBody">
            <div class="profileDetailsWidget pt-2 enrollmentWidget">
                <mat-accordion>
                    <p class="text-right enrollBTN"><button class="btn btn-primary btnsmall" matRipple
                            (click)="actionDialogPopup(enrollment)"><i class="fas fa-sign-in-alt"></i> Enroll</button>
                    </p>
                    <mat-expansion-panel class="mb-4" *ngFor="let items of EnrollmentDetails">
                        <mat-expansion-panel-header matRipple>
                            <mat-panel-title>
                                <div class="classSession">
                                    <h4 class="sessionyear">{{items.acedemicSessionName}}</h4>
                                    <h2 class="sessionname">{{items.className}} - {{items.sectionName}}</h2>
                                </div>
                                <div class="actionsCol tableActions centerVTItems" (click)="noop($event)">
                                    <mat-select class="form-control borderRadius8 mr-3 selected"
                                        [(ngModel)]="items.studentEnrollmentStatusID"
                                        (selectionChange)="changeStudentEnrollmentStatus($event,items.studentID,items.sectionID, items.classID, items.acedemicSessionID)"
                                        placeholder="Select Enrollment Status">
                                        <mat-option value="" disabled>Select Enrollment Status
                                        </mat-option>
                                        <mat-option *ngFor="let sectionStatus of studentEnrollmentStatus"
                                            [value]="sectionStatus.studentEnrollmentStatusID">
                                            {{sectionStatus.studentEnrollmentStatusName}}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-select class="form-control borderRadius8 mr-3 selected" [formGroup] = "formSelect" formControlName="level"
                                        placeholder="Select Status">
                                        <mat-option value="option" ng-selected>Upcoming</mat-option>
                                        <mat-option value="option">In Progress</mat-option>
                                        <mat-option value="option">Completed</mat-option>
                                        <mat-option value="option">In Complete</mat-option>
                                    </mat-select> -->
                                    <i (click)="deleteStudentEnrollment(items.studentID,items.sectionID, items.classID, items.acedemicSessionID)"
                                        class="fa-trash fas"></i>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div class="subjectsEnrollment">
                                <h5 class="subjctTitle">Core Subjects</h5>
                                <div *ngFor="let item of items.lstSubject">
                                    <div class="Addeddsubject d-flex justify-content-between align-items-center"
                                        *ngIf="item.isCore==true">
                                        <p class="flex-grow-1" *ngIf="item.isCore==true">{{item.subjectName}}</p>
                                        <div class="subjectStatusCol" *ngIf="item.isCore==true">
                                            <mat-select class="form-control borderRadius8"
                                                [(ngModel)]="item.subjectEnrollmentStatusID" name ="item.subjectEnrollmentStatusID"
                                                (selectionChange)="changeSubjectEnrollmentStatus($event,item.studentID,items.classID,items.sectionID,items.acedemicSessionID, item.subjectID)"
                                                placeholder="Not set">
                                                <mat-option value="" disabled>Select Enrollment Status
                                                </mat-option>
                                                <!-- <mat-option *ngIf="item.subjectEnrollmentStatusID==0">Not Set
                                                </mat-option> -->
                                                <mat-option
                                                    *ngFor="let sectionStatus of subjectEnrollmentStatusDropdown"
                                                    [value]="sectionStatus.subjectEnrollmentStatusID">
                                                    {{sectionStatus.subjecttEnrollmentStatusName}}
                                                </mat-option>
                                            </mat-select>
                                            <!-- <mat-select class="form-control borderRadius8" placeholder="Select Status">
                                                <mat-option value="option" ng-selected>Upcoming</mat-option>
                                                <mat-option value="option">In Progress</mat-option>
                                                <mat-option value="option">Completed</mat-option>
                                                <mat-option value="option">In Complete</mat-option>
                                            </mat-select> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="subjectsEnrollment">
                                <h5 class="subjctTitle">Elective Subjects
                                </h5>
                                <div *ngFor="let item of items.lstSubject">
                                    <div *ngIf="item.isCore==false">
                                        <div class="Addeddsubject d-flex justify-content-between align-items-center">

                                            <p class=" flex-grow-1" *ngIf="item.isCore==false">{{item.subjectName}}</p>
                                            <div class="subjectStatusCol">
                                                <mat-select class="form-control borderRadius8"
                                                    [(ngModel)]="item.subjectEnrollmentStatusID"
                                                    (selectionChange)="changeSubjectEnrollmentStatus($event,item.studentID,items.classID,items.sectionID,items.acedemicSessionID, item.subjectID)"
                                                    placeholder="Not set">
                                                    <mat-option value="" disabled>Select Enrollment Status
                                                    </mat-option>
                                                    <mat-option
                                                        *ngFor="let sectionStatus of subjectEnrollmentStatusDropdown"
                                                        [value]="sectionStatus.subjectEnrollmentStatusID">
                                                        {{sectionStatus.subjecttEnrollmentStatusName}}
                                                    </mat-option>
                                                </mat-select>
                                                <!-- <mat-select class="form-control borderRadius8"
                                                    placeholder="Select Status">
                                                    <mat-option value="option" ng-selected>Upcoming</mat-option>
                                                    <mat-option value="option">In Progress</mat-option>
                                                    <mat-option value="option">Completed</mat-option>
                                                    <mat-option value="option">In Complete</mat-option>
                                                </mat-select> -->
                                                <div>
                                                    <i (click)="deleteElectiveSubjectEnrollment(item.studentID,item.subjectID)"
                                                        class="fas fa-times"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <p class="text-right"><button class="btn btn-primary btnsmall mt-1" matRipple
                                        matTooltip="Enroll Students into elective studnet"
                                        (click)="electiveDialogPopup(addElectiveSubject,items.sectionID, items.classID, items.acedemicSessionID, items.studentID)"><i
                                            class="fas fa-plus"></i> Add
                                        Elective</button></p>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                    <!-- <p class="text-right enrollBTN"><button class="btn btn-primary btnsmall">Enroll</button></p>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="classSession center">
                                    <h4>2020 - 21</h4>
                                    <span>V - A</span>
                                </div>                               
                                <div class="actionsCol tableActions centerVTItems" (click)="noop($event)">
                                    <mat-select class="form-control borderRadius8" placeholder="Select Status">
                                        <mat-option value="Select Status">Select Status</mat-option>
                                        <mat-option value="option" ng-selected>Upcoming</mat-option>
                                        <mat-option value="option">In Progress</mat-option>
                                        <mat-option value="option">Completed</mat-option>
                                        <mat-option value="option">In Complete</mat-option>
                                    </mat-select>
                                    <mat-icon class="ml-2">delete</mat-icon>
                                </div>
                                
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div class="subjectsEnrollment">
                                <h5 class="subjctTitle">Core Subjects</h5>
                                <div class="Addeddsubject d-flex justify-content-between align-items-center">
                                    <p class="flex-grow-1">Maths</p>
                                    <div class="subjectStatusCol">
                                        <mat-select class="form-control borderRadius8" placeholder="Select Status">
                                            <mat-option value="Select Status">Select Status</mat-option>
                                            <mat-option value="option" ng-selected>Upcoming</mat-option>
                                            <mat-option value="option">In Progress</mat-option>
                                            <mat-option value="option">Completed</mat-option>
                                            <mat-option value="option">In Complete</mat-option>
                                        </mat-select></div>                                    
                                </div>
                            </div>
                            <div class="subjectsEnrollment">
                                <h5 class="subjctTitle">Elective Subjects
                                </h5>
                                <p class="text-right"><button class="btn btn-primary btnsmall">+ Add Elective</button></p>
                            </div>
                        </ng-template>
                    </mat-expansion-panel> -->
                </mat-accordion>

            </div>
        </div>
    </div>
</div>


<ng-template #addElectiveSubject>
    <div class="dialog-xll">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div *ngIf="!electiveSubject">
            No elective subject found
        </div>
        <div *ngIf="electiveSubject">
            <div class="dialogTitle center-align loginTtile">
                <h2 mat-dialog-title>Enroll into Elective Subjects </h2>
            </div>
            <form>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Search Subjects</mat-label>
                    <input type="text" placeholder="Pick one" aria-label="Number" [(ngModel)]="electiveSubjectTerm"
                        matInput [formControl]="myControl">
                    <!-- <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete> -->
                </mat-form-field>
            </form>
            <div class="row">
                <div *ngFor="let ite of electiveSubject | filter:electiveSubjectTerm;" class="col-lg-6 col-sm-12">
                    <div class="card p-3">
                        <div class="sub-title-block">
                            <div class="subject-title-info" style="word-break: break-all;">
                                <h4 class="stud-el-sub-name mb-1">{{ite.subjectName}}</h4>
                                <div>
                                    <span class="">{{ite.className}} - {{ite.sectionName}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="sub-extra-info mt-1">
                            <div>
                                <span class="pull-left">Credits / Hours:</span>
                                <span class="ml-1">{{ite.subjectCredit}}</span>
                            </div>

                        </div>
                      
                            <div class="inline-block mt-2" >
                                <a (click)="onSubmit(ite.subjectID)" class="tags underlinedLink">Enroll</a>
                            </div>
                        

                       
                            <div class="inline-block mt-2">
                                <a (click)="deleteElectiveSubjectEnrollmentPop(ite.subjectID)"
                                    class="tags underlinedLink cancel">Cancel</a>
                            </div>
                       


                    </div>
                </div>
                <!-- <div class="col-lg-6 col-sm-12">
                    <div class="card p-3">
                        <div class="sub-title-block">
                            <div class="subject-title-info" style="word-break: break-all;">
                                <h4 class="stud-el-sub-name mb-1">Hindi</h4>
                                <div>
                                    <span class="">Second year - Sect</span>
                                </div>
                            </div>
                        </div>
                        <div class="sub-extra-info mt-1">
                            <div>
                                <span class="pull-left">Credits / Hours:</span>
                                <span class="ml-1">222</span>
                            </div>
    
                        </div>
                        <div class="inline-block mt-2">
                            <a class="tags underlinedLink cancel">Cancel</a>
                        </div>
    
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="card p-3">
                        <div class="sub-title-block">
                            <div class="subject-title-info" style="word-break: break-all;">
                                <h4 class="stud-el-sub-name mb-1">Hindi</h4>
                                <div>
                                    <span class="">Second year - Sect</span>
                                </div>
                            </div>
                        </div>
                        <div class="sub-extra-info mt-1">
                            <div>
                                <span class="pull-left">Credits / Hours:</span>
                                <span class="ml-1">222</span>
                            </div>
    
                        </div>
                        <div class="inline-block mt-2">
                            <a class="tags underlinedLink">Add</a>
                        </div>
    
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="card p-3">
                        <div class="sub-title-block">
                            <div class="subject-title-info" style="word-break: break-all;">
                                <h4 class="stud-el-sub-name mb-1">Hindi</h4>
                                <div>
                                    <span class="">Second year - Sect</span>
                                </div>
                            </div>
                        </div>
                        <div class="sub-extra-info mt-1">
                            <div>
                                <span class="pull-left">Credits / Hours:</span>
                                <span class="ml-1">222</span>
                            </div>
    
                        </div>
                        <div class="inline-block mt-2">
                            <a class="tags underlinedLink cancel">Cancel</a>
                        </div>
    
                    </div>
                </div> -->
            </div>
            <div class="text-right mt-2">
                <button class="btn btn-reset btn-sm" mat-dialog-close matripple>Close</button>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #enrollment>
    <div class="dialog-md">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <!-- <h2 mat-dialog-title>Student Enrollment</h2> -->
            <!-- <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Student Enrollment Status</h2>
          <ng-template #elseTemplate>
            <h2 mat-dialog-title>Student Enrollment</h2>
          </ng-template> -->
            <h2 mat-dialog-title>Student Enrollment</h2>
        </div>
        <form [formGroup]="studentEnrollmentForm" (ngSubmit)="onSubmitStudentEnrollment()">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select Academic Session</mat-label>
                <mat-select placeholder="Select Session" formControlName="academicSessionID">
                    <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
                        {{session.academicSessionName}}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="studentEnrollmentForm.get('academicSessionID')?.touched  && studentEnrollmentForm.get('academicSessionID')?.errors?.required">
                    Academic session is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select Class</mat-label>
                <mat-select placeholder="Select Class" (selectionChange)="filterSection($event)"
                    formControlName="classID">
                    <mat-option value="" disabled>Select Class</mat-option>
                    <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
                        {{classSelect.className}}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="studentEnrollmentForm.get('classID')?.touched && studentEnrollmentForm.get('classID')?.errors?.required">
                    Please select a class
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select Section</mat-label>
                <mat-select placeholder="Select Section" formControlName="sectionID">
                    <mat-option value="" disabled>Select Section</mat-option>
                    <mat-option *ngFor="let sectionSelect of sectionListDropdown" [value]="sectionSelect.sectionID">
                        {{sectionSelect.sectionName}}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="studentEnrollmentForm.get('sectionID')?.touched && studentEnrollmentForm.get('sectionID')?.errors?.required">
                    Please select a section
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select Enrollment Status</mat-label>
                <mat-select placeholder="Select Enrollment Status" formControlName="studentEnrollmentStatusID">
                    <mat-option value="" disabled>Select Enrollment Status</mat-option>
                    <mat-option *ngFor="let sectionStatus of studentEnrollmentStatus"
                        [value]="sectionStatus.studentEnrollmentStatusID">
                        {{sectionStatus.studentEnrollmentStatusName}}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="studentEnrollmentForm.get('studentEnrollmentStatusID')?.touched && studentEnrollmentForm.get('studentEnrollmentStatusID')?.errors?.required">
                    Please select a enrollment status
                </mat-error>
            </mat-form-field>

            <div class="btnsWidget form-group17 text-right">
                <button type="submit" class="btn btn-primary btn-sm" 
                    [disabled]="studentEnrollmentForm.invalid">Save</button>
                <button typetype="reset" class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
            </div>

            <!-- <div class="forgotpassword form-group17 center">
          <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
            [disabled]="addSubjectForm.invalid">Save</button>
        </div> -->
        </form>
    </div>
    <!-- <div class="dialog-md">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h2 mat-dialog-title>Student Enrollment</h2>
        </div>
        <form>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Academic Sessions</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Class/Section</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>

            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Enrollment Status</mat-label>
                <mat-select placeholder="Select Status" formControlName="Fee Name">
                    <mat-option value="Select Status">Upcoming</mat-option>
                    <mat-option value="Select Status">Inprogress</mat-option>
                    <mat-option value="Select Status">Completed</mat-option>
                    <mat-option value="Select Status">Uncompleted</mat-option>
                    <mat-option value="Select Status">Withdrawn</mat-option>
                </mat-select>
                <mat-error>
                    Please Select a status </mat-error>
            </mat-form-field>



        </form>


        <div class="text-right mt-2">
            <button class="btn btn-primary btn-sm mr-2" matripple>Enroll</button>
            <button class="btn btn-reset btn-sm" matripple>Close</button>
        </div>
    </div> -->
</ng-template>