import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, Classes } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ClassService {
  constructor(private http: HttpClient) { }

  AddClass(data: any) {
    ////debugger;
    return this.http.post(`${environment.baseUrl}${Classes.addClassesAPI}`, data);
  }

  AllClass() {
    return this.http.get(`${environment.baseUrl}${Classes.getAllClassesAPI}`);
  }

  GetClassById(Id: any) {
    return this.http.get(`${environment.baseUrl}${Classes.getClassesByIdAPI}${'?classID=' + Id}`);
  }

  GetClassByClassId(Id: any) {
    return this.http.get(`${environment.baseUrl}${Classes.GetClassByClassIdAPI}${'?classID=' + Id}`);
  }

  GetClassDropdown() {
    return this.http.get(`${environment.baseUrl}${Classes.GetClassesForDropdown}`);
  }
  EditClass(data: any) {
    return this.http.post(`${environment.baseUrl}${Classes.EditClassesByIdAPI}`, data);
  }

  DeleteClass(Id: any) {
    return this.http.get(`${environment.baseUrl}${Classes.DeleteClassesByIdAPI}${'?id=' + Id}`);
  }

  GetClassByDepartmentId(Id: any) {
    return this.http.get(`${environment.baseUrl}${Classes.GetClassByClassIdOrDepartmentId}${'?departmentID=' + Id}`);
  }
  GetClassSectionDropdownByAcademicSessionID(studentID: any, acdemicSessionID: any) {
    return this.http.get(`${environment.baseUrl}${Classes.GetClassSectionDropdownByAcademicSessionID}${'?studentID=' + studentID + '&acdemicSessionID=' + acdemicSessionID}`);
  }
  GetClassAndSectionList() {
    return this.http.get(`${environment.baseUrl}${Classes.getClassandSection}`);
  }
}
