<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <!-- <h2>{{firstName}} {{lastName}}</h2> -->
            <!-- <h2>Virat Singh</h2> -->
            <h2>{{firstName}} {{lastName}}</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic"><i
                                class="fas fa-home"></i> Home</a>
                    <!-- <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
                            Learning</a></li> -->
                    <li class="breadcrumb-item"><a
                            routerLink="/admin/academicslearning/manage-students">Manage Students</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Activities</li>
                </ol>
            </div>
           
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
            </div>
          </div>
    </div>
    <!--/Page Title-->
    <div class="cardWidget">
        <!-- <div class="cardTitle centerVTItems justify-content-between"><h3>Virat Sharma</h3></div> -->
        <app-profile-tabs></app-profile-tabs>
        <div class="cardBody">
            <div class="tabularWidget">
                <mat-tab-group mat-align-tabs="start">
                    <mat-tab label="Review">
                        <form class="activityForm" [formGroup]="myForm" (ngSubmit)="Review()">
                            <div class="row-flex">

                                <div class="col s12">
                                    <ejs-richtexteditor #textEditorValue required formControlName="review"
                                    [toolbarSettings]='tools'  pattern="^[^\s]+(\s+[^\s]+)*$">

                                    </ejs-richtexteditor>
                                    <mat-error
                                        *ngIf="myForm.get('review')?.touched  && myForm.get('review')?.errors?.required">
                                        please enter review
                                    </mat-error>
                                </div>
                               
                                <div class="col s12 pl-0">
                                    <br>
                                    <!-- <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Start Typing Name</mat-label>
                                      
                                    </mat-form-field> -->
                                    <!--                              
                                        <ng-multiselect-dropdown class="mutliselectdropdown"
                                            name="city"
                                            [placeholder]="'Select The Names'"
                                            [data]="cities"
                                            formControlName="city"
                                            [settings]="dropdownSettings"
                                            (onSelect)="onItemSelect($event)">
                                        </ng-multiselect-dropdown> -->


                                    <mat-form-field class="example-chip-list" appearance="outline" class="col s6">
                                        <mat-label>start typing tag name</mat-label>
                                        <mat-chip-list #chipList aria-label="Fruit selection">
                                            <mat-chip *ngFor="let fruit of fruits" (removed)="remove(fruit)">
                                                {{fruit}}
                                                <button matChipRemove>
                                                    <mat-icon class="chipclose">close</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <input formControlName="tagName" (keyup.enter)="onEnter($event)"
                                                placeholder="new name" #fruitInput [formControl]="fruitCtrl"
                                                [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="add($event)">
                                        </mat-chip-list>
                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                            <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                                                {{fruit}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>



                                </div>

                            </div>
                            <div class="text-right">
                                <button class="btn btn-primary btn-sm mt-2" type="submit" [disabled]="myForm.invalid"
                                    matRipple>Save</button>
                            </div>

                        </form>
                    </mat-tab>


                    <mat-tab label="Email">
                        <form #Emailform="ngForm" class="activityForm" [formGroup]="sendEmailForm" *ngIf="studentData">
                            <div class="row-flex">

                                <div class="col s12">
                                    <label id="example-radio-group-label" class="text-colored">Send As</label><br>
                                    <mat-radio-group aria-label="Select an option" formControlName="senderEmail"
                                        required>
                                        <mat-radio-button #radbtn [value]=true class="mb-1">info@creocms.com.au(Institute's email)
                                        </mat-radio-button><br>
                                        <mat-radio-button [value]=false class="mb-1">{{userEmail}}(Your Email)</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="col s12">
                                    <label id="example-radio-group-label" class="text-colored">Receivers</label><br>
                                    <mat-checkbox [checked]="true" class="example-margin mb-1" [(ngModel)]="studentEmailCheck"
                                        formControlName="studentEmailCheck">
                                        Students Email({{ studentEmail }})
                                    </mat-checkbox><br>
                                    <!-- <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal" readyonly >
                                        <mat-chip class="example-box" cdkDrag >
                                          {{ studentData.email }}
                                        </mat-chip>
                                      </mat-chip-list> -->

                                    <mat-checkbox [checked]='false' class="example-margin mb-1" [(ngModel)]="parentEmailCheck"
                                        formControlName="parentCheck">
                                        Parents Email({{parentEmail}})
                                    </mat-checkbox>
                                    <!-- <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal">
                                            <mat-chip class="example-box" cdkDrag >
                                            {{studentData.parentEmail}}
                                            </mat-chip>
                                        </mat-chip-list> -->
                                        <mat-error
                                        *ngIf="studentEmailCheck==false && parentEmailCheck==false">
                                        Please select receiver
                                    </mat-error>
                                </div>
                                <div class="col s12">
                                    <mat-form-field class="w-100 mt-2" appearance="outline">
                                        <mat-label>Email Subject...</mat-label>
                                        <input matInput formControlName="subject" placeholder="Email Subject...">
                                        <mat-error
                                            *ngIf="sendEmailForm.get('subject')?.touched  && sendEmailForm.get('subject')?.errors?.required">
                                            Please enter email subject
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="col s4">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Default</mat-label>
                                        <mat-select formControlName="template"
                                            (selectionChange)="selectChangeTemplate($event)" name="template">
                                            <mat-option *ngFor="let temp of customtemplate"
                                                [value]="temp.emailTemplateID">
                                                {{temp.templateName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col s12">
                                    <ejs-richtexteditor required formControlName="body" [toolbarSettings]='tools'>
                                    </ejs-richtexteditor>
                                </div>

                            </div>
                            <div class="text-right mt-2">
                                <button class="btn btn-primary btn-sm" (click)="onSubmitEmail(Emailform)" [disabled]="sendEmailForm.invalid || studentEmailCheck==false && parentEmailCheck==false"
                                    matRipple>Send</button>
                            </div>

                        </form>
                    </mat-tab>
                    <mat-tab label="SMS">
                        <form #SMSform="ngForm" class="activityForm"  [formGroup]="SmsForm" (ngSubmit)="SendSMS(SMSform)" *ngIf="studentData">
                            <div class="row-flex">
                                <div class="col s12">
                                    <label id="example-radio-group-label" class="text-colored">Receivers</label>
                                    <br>
                                    <mat-checkbox [checked]="true" class="example-margin mb-1" [(ngModel)]="studentSMSCheck"
                                        formControlName="studentControl">
                                        Student Contact({{ studentContact }})
                                    </mat-checkbox>
                                    <br>
                                    <mat-checkbox [checked]='false' class="example-margin mb-1" [(ngModel)]="parentSMSCheck"
                                        formControlName="parentCheckSMS">
                                        Parents Contact({{ parentContactCore }})
                                    </mat-checkbox>
                                    <mat-error
                                    *ngIf="studentSMSCheck==false && parentSMSCheck==false">
                                    Please select receiver
                                </mat-error>
                                </div>
                                <div class="col s12">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>SMS Body...</mat-label>
                                        <textarea matInput placeholder="SMS Body..." #text formControlName="message" maxlength="304"
                                            pattern="^[^\s]+(\s+[^\s]+)*$" (keydown)="wordCounter()"></textarea>
                                        <mat-error
                                            *ngIf="SmsForm.get('message')?.touched  && SmsForm.get('message')?.errors?.required">
                                            Message is required
                                        </mat-error>
                                    </mat-form-field>
                                    <p class="hintText text-right" style="background-color: white;">
                                        <span class="charLimit">{{words}}/304 characters</span><span class="creditSms">1
                                            SMS Credit</span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-right mt-2">
                                <button class="btn btn-primary btn-sm" [disabled]="SmsForm.invalid ||studentSMSCheck==false && parentSMSCheck==false" matRipple
                                    type="submit">Send</button>
                            </div>

                        </form>
                    </mat-tab>

                    <mat-tab label="Private Note">
                        <form #form="ngForm" class="activityForm" [formGroup]="FormPrivateNote" (ngSubmit)="PrivateNote(form)">
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Private Note Subject</mat-label>
                                        <input matInput formControlName="PrivateNote" placeholder="Private Note Subject"
                                            required pattern="^[^\s]+(\s+[^\s]+)*$">
                                        <mat-error
                                            *ngIf="FormPrivateNote.get('PrivateNote')?.touched  && FormPrivateNote.get('PrivateNote')?.errors?.required">
                                            private note subject required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <ejs-richtexteditor #textEditorValue required formControlName="note"
                                        pattern="^[^\s]+(\s+[^\s]+)*$" [toolbarSettings]='tools' >
                                    </ejs-richtexteditor>
                                    <!-- <ejs-richtexteditor  #emailBody [toolbarSettings]='tools' >

                                    </ejs-richtexteditor> -->
                                    
                                    <mat-error
                                        *ngIf="FormPrivateNote.get('note')?.touched  && FormPrivateNote.get('note')?.errors?.required">
                                          Note is required
                                    </mat-error>
                                </div>
                            </div>
                            <div class="text-right">
                                <button class="btn btn-primary btn-sm mt-2" [disabled]="FormPrivateNote.invalid"
                                    type="submit" matRipple>Save</button>
                            </div>
                        </form>
                    </mat-tab>
                </mat-tab-group>
                <app-activity-list *ngIf="show" [StudentID]="studentID"></app-activity-list>
            </div>
        </div>
    </div>
</div>
