<!-- <main> -->
<!-- <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Student</h2>

        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-students">Manage Student</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Student</li>
            </ol>
        </div>
    </div> -->
    <div class="container container1580">
        <div class="pageTitle">
            <h2>Profile Settings</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a routerLink="/manage-students">Manage Student</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Add Student</li>
                </ol>
            </div>
        </div>

        <div class="cardWidget">
            <div class="matCard">
                <div class="matCrdTitle centerItemVert justify-content-between">
                    <div class="cardTitle centerVTItems justify-content-between">
                        <h3>Edit Profile</h3>
                        <button class="btn btn-primary btnsmall" routerLink="../">Back</button>
                    </div>

                </div>
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">

                          <div class="row">
                            <!-- <div class="col-md-3 col-sm-4">
                              <div class="profilePicLeft form-group30"> -->
                                <!-- <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Profile pic <span class="req">*</span></mat-label>
                                    <input matInput placeholder="Profile" required name="Profile" formControlName="profilePic">
                                </mat-form-field>     -->
                                <!-- <p class="chngePic">
                                    <input type="file" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event)">
                                    <span class="icon centerItem">
                                        <mat-icon>edit</mat-icon>
                                    </span>
                                    <img [src]="croppedImage" alt="">
                                </p> -->

                                <!-- <p class="chngePic">
                                        <input type="file" accept="image/*" (change)="fileChangeEvent($event)">
                                        <span class="icon centerItem">
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                        <img [src]="croppedImage" alt="image">
                                    </p> -->
                                <!-- <input type="file" (change)="fileChangeEvent($event)" /> -->

                                <!-- <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png" (imageCropped)="imageCropped($event)"
                                    (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                                    (loadImageFailed)="loadImageFailed()"></image-cropper> -->

                                <!-- <img [src]="croppedImage" /> -->

                            <!-- </div>
                            </div> -->

                            <div class="col-md-9 col-sm-8">
                              <div class="profileContentRight flex-grow-1">

                                <!-- <div class="proSection">
                                    <h5>Enrollment</h5>

                                    <div class="row">


                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Select Academic Session</mat-label>
                                                <mat-select placeholder="Session" formControlName="academicSessionID">
                                                    <mat-option value="Select Session">Academic Sessions</mat-option>
                                                    <mat-option *ngFor="let session of academicSessions"
                                                        [value]="session.academicSessionID">
                                                        {{session.academicSessionName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="form.get('academicSessionID')?.touched  && form.get('academicSessionID')?.errors?.required">
                                                    academicSession is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Select Class</mat-label>
                                                <mat-select placeholder="Class" formControlName="classID">
                                                    <mat-option value="Select Class">Classes</mat-option>
                                                    <mat-option *ngFor="let class of classes" [value]="class.classID">
                                                        {{class.className}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="form.get('classID')?.touched  && form.get('classID')?.errors?.required">
                                                    Class is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>


                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Select Section</mat-label>
                                                <mat-select placeholder="Section" formControlName="sectionID">
                                                    <mat-option value="Select Section">Sections</mat-option>
                                                    <mat-option *ngFor="let section of sections"
                                                        [value]="section.sectionID">
                                                        {{section.sectionName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="form.get('sectionID')?.touched  && form.get('sectionID')?.errors?.required">
                                                    Section is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div> -->

                                <div class="proSection">
                                    <h5>Profile Details</h5>

                                    <div class="row">

                                       
                                  
                                     
                                        
                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Organization Name</mat-label>
                                                <input matInput placeholder="Organization Name" (keypress)="allowAlphabetOnly($event)" required name="organizationName"
                                                    formControlName="organizationName">
                                                    <mat-error
                                                    *ngIf="form.get('organizationName')?.touched  && form.get('organizationName')?.errors?.required">
                                                    Organization Name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Organization Short Name</mat-label>
                                                <input matInput (keypress)="allowAlphabetOnly($event)" placeholder="Organization Short Name" name="organizationshorName"
                                                    formControlName="organizationShortName">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Sub Domain Name</mat-label>
                                                <input matInput placeholder="Sub Domain NAme" (keypress)="allowAlphabetOnly($event)" required name="domainname"
                                                    formControlName="subDomainName">
                                                    <mat-error
                                                    *ngIf="form.get('subDomainName')?.touched  && form.get('subDomainName')?.errors?.required">
                                                    Sub-Domain Name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Maximum Student Allowed </mat-label>
                                                <input matInput placeholder="Maximum Student Allowed " (keypress)="allowAlphabetOnly($event)" required name="maximumStudentAllowed"
                                                    formControlName="maximumStudentAllowed">
                                                    <mat-error
                                                    *ngIf="form.get('maximumStudentAllowed')?.touched  && form.get('maximumStudentAllowed')?.errors?.required">
                                                    Maximum Student Allowed is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m4 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Organization Short Name</mat-label>
                                                <input matInput placeholder="Organization Short Name" (keypress)="allowAlphabetOnly($event)" required name="organizationShortName"
                                                    formControlName="organizationShortName">
                                                    <mat-error
                                                    *ngIf="form.get('organizationShortName')?.touched  && form.get('organizationShortName')?.errors?.required">
                                                    Organization Short Name Name is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="proSection">
                                    <h5>Personal Information</h5>

                                    <div class="row">
                                        <div class="col s12 m6 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Student Email</mat-label>
                                                <input matInput placeholder="email" required name="email"
                                                    formControlName="email">
                                                <mat-error
                                                    *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                    Student Email is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m6 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>DateOfBirth</mat-label>
                                                <input (click)="picker.open()" [max]="maxDate" matInput
                                                    placeholder="DateOfBirth" required name="DateOfBirth"
                                                    formControlName="dateOfBirth" [matDatepicker]="picker">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error
                                                    *ngIf="form.get('dob')?.touched  && form.get('dob')?.errors?.required">
                                                    Field is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m6 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Student contact Number</mat-label>
                                                <input (keypress)="allowOnlyNumber($event)" maxlength="10" matInput
                                                    placeholder="Phone Number" required name="contactNumber"
                                                    formControlName="contactNumber">
                                                <mat-error
                                                    *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                    Student contact is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col s12 m6 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-select placeholder="Blood Group" required name="bloodGroup"
                                                    formControlName="bloodGroupID">
                                                    <mat-option value="Select Blood Group">Blood Group</mat-option>
                                                    <mat-option *ngFor="let bloodGroup of bloodGroups"
                                                        [value]="bloodGroup.bloodGroupID">
                                                        {{bloodGroup.bloodGroupName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="form.get('bloodGroupID')?.touched  && form.get('bloodGroupID')?.errors?.required">
                                                    Blood Group is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>


                                        <div class="col s12 m12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Select Gender</mat-label>
                                                <mat-select placeholder="Gender" formControlName="genderID">
                                                    <mat-option *ngFor="let gender of genders " [value]="gender.genderID">
                                                        {{gender.genderName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error
                                                    *ngIf="form.get('gender')?.touched  && form.get('gender')?.errors?.required">
                                                    Gender is required
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div> -->


                                <!-- <div class="proSection">
                                    <h5>Skills & Social Details</h5>
                                    <div class="row-flex">


                                        <div class="col s12 m12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Select Student Skills </mat-label>
                                                <mat-select placeholder="Stdenty Skills" formControlName="studentSkillID">
                                                    <mat-option *ngFor="let skill of skills " [value]="skill.studentSkillID">
                                                        {{skill.skill}}
                                                    </mat-option>
                                                </mat-select>

                                            </mat-form-field>
                                        </div>

                                        <div class="col s12 m6 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Facebook Profile Link</mat-label>
                                                <input (keypress)="allowAlphabetOnly($event)" matInput
                                                    placeholder="Facebook Profile Link"
                                                    formControlName="facebookLink">

                                            </mat-form-field>
                                        </div>

                                        <div class="col s12 m6 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>LinkedIn Profile Link</mat-label>
                                                <input (keypress)="allowAlphabetOnly($event)" matInput type="url"
                                                    placeholder="LinkedIn Profile Link"
                                                    formControlName="linkedInlink">

                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div> -->



                                <div class="proSection center">
                                    <button type="submit" [disabled]="form.invalid" class="btn btn-primary">Submit</button>
                                </div>
                            </div>

                            </div>
                          </div>




                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- </main> -->
    <!-- <ng-template #uploadPopup>
        <div class="dialog-md">
            <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
            <div class="dialogTitle center-align loginTtile">
                <h5 mat-dialog-title>Select Image Area</h5>
            </div>
            <mat-dialog-content class="mat-typography">
                <div class="row">
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png"
                        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

                </div>

            </mat-dialog-content>
        </div>
    </ng-template> -->
