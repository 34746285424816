<div class="cardTitle centerVTItems justify-content-between">
    <h3 class="text-colored"><i class="fas fa-user-cog mr-2"></i> Weighted Subject &amp; Category Settings</h3>
</div>
<form [formGroup]="weightedSubjectAndCategorySettings">
    <div class="gradeSettings">
        <div class="row-flex mt-4">
            <div class="col s12 m4 form-group">Setup Weighted Categories ? <i class="fa fa-info-circle"
                    [popover]="weightedcategory" triggers="mouseenter:mouseleave" [adaptivePosition]="true"
                    placement="bottom"></i></div>
            <div class="col s12 m8 form-group">
                <mat-radio-group aria-label="Select an option" formControlName="setupWeightedCategories" required>
                    <mat-radio-button [value]=true>Yes</mat-radio-button>
                    <mat-radio-button [value]=false>No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col s12 m4 form-group">Display Overall Score? <i class="fa fa-info-circle" [popover]="scores"
                    triggers="mouseenter:mouseleave" [adaptivePosition]="true" placement="bottom"></i></div>
            <div class="col s12 m8 form-group">
                <mat-radio-group aria-label="Select an option" formControlName="displayOverallScore" required>
                    <mat-radio-button [value]=true>Yes</mat-radio-button>
                    <mat-radio-button [value]=false>No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col s12 m4 form-group">Consider Overall Score calculation
                for categories without grade entry?</div>
            <div class="col s12 m8 form-group">
                <mat-radio-group aria-label="Select an option"
                    formControlName="categoriesScoreCalculationWithoutGradeEntry" required>
                    <mat-radio-button [value]=true>Yes</mat-radio-button>
                    <mat-radio-button [value]=false>No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col s12 m4 form-group mt-3">Overall score grading scale</div>
            <div class="col s12 m8 l6 form-group">
                <mat-form-field class="w-100" appearance="outline" required>
                    <mat-label>Select Section</mat-label>
                    <mat-select placeholder="Select Class" formControlName="gradingScalesID">
                        <mat-option value="Select Section">Select Section</mat-option>
                        <mat-option [value]="1">Percentage</mat-option>
                    </mat-select>
                </mat-form-field>
                <a (click)="actionDialogPopup(catWeight)" class="underlinedLink font-weight-bold colured">Manage
                    Weights</a>
            </div>
            <div class="col s12 m4 form-group"></div>
            <div class="col s12 m8 form-group">
                <button type="submit" (click)="submitSubjectAndCategorySettings()"
                    class="btn btn-primary mr-1 min-140 btn-sm" mat-dialog-close matRipple>Save</button>
                <!-- <button type="reset" (click)="reset()" class="btn btn-reset min-140" mat-dialog-close>Cancel</button> -->
            </div>
        </div>
    </div>
</form>
<ng-template #catWeight>
    <div class="dialog-full manage-weight-dialog">
        <i class="material-icons popupClose" (click)="resetSearch()"  mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h2 mat-dialog-title>Manage Category Weight</h2>
        </div>
        <form>
            <div class="weightDivScroll">

                <div class="d-flex align-items-center">
                    <span>Common Weight for all Section &amp; Class</span>
                    <!-- <mat-slide-toggle class="custom-slideToggle m-2"></mat-slide-toggle> -->
                    <!-- <div class="switchToggle ml-2">
                        <input type="checkbox" id="switch" class="checkbox" />
                        <label for="switch" class="toggle">
                            <span class="yes">Yes</span>
                            <span class="no">No</span>
                        </label>
                    </div> -->
                    <mat-slide-toggle [(ngModel)]="isAllSet" name="demo"
                        (change)="onChange($event); CategoriesExpansionPanel(0, 0, 0);" class="custom-slideToggle m-2">
                    </mat-slide-toggle>
                </div>

                <div class="sameWeight" style="display: none;">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Category Name</th>
                                <th>Weight</th>
                                <th>(%)ss</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of categories">
                                <td>{{item.assessmentCategoryID}}</td>
                                <td>{{item.assessmentCategoryName}}</td>
                                <td><input type="text" value="10" class="form-control customControl w100 center"></td>
                                <td>{{item.percentage}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="diffrentWeight row-flex">
                    <div class="leftColSec">
                        <div class="departmentSearch">
                            <div class="departSelect" *ngIf="!isAllSet">
                                <form [formGroup]="form">
                                    <div class="row">
                                        <div class="col-6">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Options</mat-label>
                                                <mat-select aria-placeholder="Select Options" required
                                                    formControlName="filterType"
                                                    (selectionChange)="optionSelected($event.value)">
                                                    <mat-option value="1" *ngIf="departmentChecked">Department
                                                    </mat-option>
                                                    <mat-option value="2">Class</mat-option>
                                                    <mat-option value="3">Section</mat-option>

                                                </mat-select>
                                                <mat-error
                                                *ngIf="form.get('filterType')?.touched && form.get('filterType')?.errors?.required">
                                                Option is required
                                            </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Search</mat-label>
                                                <input matInput type="text" placeholder="search"
                                                    formControlName="filterName" (keyup)="getSearchVal()">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </form>
                                <!-- <button mat-raised-button color="primary" (click)='getFilterResult()'>Search</button> -->
                            </div>
                            <!-- <div class="departinput">class="listTreeStructure"
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Search Departments</mat-label>
                                    <mat-chip-list #chipList aria-label="Department selection">
                                        <mat-chip *ngFor="let department of departments" [selectable]="selectable"
                                            [removable]="removable" (removed)="remove(department)">
                                            {{department.name}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="Search Departments" [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                                    </mat-chip-list>
                                </mat-form-field>
                            </div> -->
                        </div>

                        <div class="xray" *ngIf="!isAllSet">
                            <div class="departmentLists my-3" *ngFor="let dept of departmentListMenu">
                                <ul class="listTreeStructure">
                                    <li>
                                        <a *ngIf="dept.departmentName != ''">{{dept.departmentName}}</a>

                                        <ng-container *ngFor="let cl of dept.classes">
                                            <ul>
                                                <li>
                                                    <a>{{cl.className}}</a>

                                                    <ng-container *ngFor="let sec of cl.sections">
                                                        <ul>
                                                            <li>
                                                                <a class="active"
                                                                    (click)='onSectionClick(sec.sectionsID)'>{{sec.sectionName}}</a>
                                                            </li>
                                                        </ul>
                                                    </ng-container>
                                                    <hr>

                                                </li>
                                            </ul>
                                        </ng-container>

                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div class="rightColSec" *ngIf="!isAllSet">
                        <!-- <p  class="rightNavBread"><span>Class Name</span>  <span>Section A</span></p> -->
                        <mat-accordion>
                            <ng-container *ngFor="let subject of subjectsListMenu; let i = index">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header
                                        (click)="subjectExpansionPanel(subject.subjectID, subject.sectionID, subject.classID)">
                                        {{subject.subjectName}} <mat-icon class="iconPosRight"
                                            (click)="actionDialogPopup(copyPopup)">file_copy</mat-icon>
                                    </mat-expansion-panel-header>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>Category Name</th>
                                                <th>Weight</th>
                                                <th>(%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of categories">
                                                <td>{{item.assessmentCategoryID}}</td>
                                                <td>{{item.assessmentCategoryName}}</td>
                                                <td><input type="number" [value]="item.weight"
                                                        class="form-control customControl w100 center"
                                                        (focusout)="onKeyUp($event, item.assessmentCategoryID, subject.subjectID, subject.sectionID, subject.classID, item)"
                                                        id="{{'weight-'+item.assessmentCategoryID+'-'+subject.subjectID+'-'+subject.sectionID+'-'+subject.classID}}">
                                                </td>
                                                <td><input type="number" value="0.00" step="0.00"
                                                        class="form-control customControl w100 center"
                                                        id="{{'percentage-'+item.assessmentCategoryID+'-'+subject.subjectID+'-'+subject.sectionID+'-'+subject.classID}}"
                                                        readonly ></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>Total</td>
                                                <td><input type="number" [(ngModel)]="totalWeight"
                                                        [ngModelOptions]="{standalone: true}"
                                                        id="{{'totalWeight-'+subject.subjectID+'-'+subject.sectionID+'-'+subject.classID}}"
                                                        class="form-control customControl w100 center" readonly></td>
                                                <td><input type="number" [(ngModel)]="totalPercentage"
                                                        [ngModelOptions]="{standalone: true}"
                                                        id="{{'totalPercentage-'+subject.subjectID+'-'+subject.sectionID+'-'+subject.classID}}"
                                                        class="form-control customControl w100 center" readonly></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </mat-expansion-panel>
                            </ng-container>
                        </mat-accordion>
                    </div>

                    <div class="rightColSec" *ngIf="isAllSet">
                        <mat-accordion>
                            <!-- <ng-container *ngFor="let subject of subjectsListMenu; let i = index"> -->
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header (click)="CategoriesExpansionPanel(0, 0, 0)">
                                    All Categories <mat-icon class="iconPosRight" (click)="saveCommonWeight($event)">
                                        save</mat-icon>
                                </mat-expansion-panel-header>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Sr No.</th>
                                            <th>Category Name</th>
                                            <th>Weight</th>
                                            <th>(%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cat of categories">
                                            <td>{{cat.assessmentCategoryID}}</td>
                                            <td>{{cat.assessmentCategoryName}}</td>
                                            <td><input type="number" [value]="cat.weight"
                                                    class="form-control customControl w100 center"
                                                    (focusout)="onKeyUpAllSet(cat.assessmentCategoryID)"
                                                    id="{{'weightAllSet-'+cat.assessmentCategoryID}}"></td>
                                            <td><input type="number" value="0" placeholder="percentage"
                                                    class="form-control customControl w100 center"
                                                    id="{{'percentageAllSet-'+cat.assessmentCategoryID}}"  readonly></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Total</td>
                                            <td><input type="number" [(ngModel)]="totalWeightAllSet"
                                                    [ngModelOptions]="{standalone: true}" id="{{totalWeightAllSet}}"
                                                    class="form-control customControl w100 center" readonly>
                                            </td>
                                            <td><input type="number" [(ngModel)]="totalPercentageAllSet"
                                                    [ngModelOptions]="{standalone: true}" id="{{totalPercentageAllSet}}"
                                                    class="form-control customControl w100 center" readonly>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-expansion-panel>
                            <!-- </ng-container> -->
                        </mat-accordion>
                    </div>
                </div>

            </div>
            <!-- <div class="btnsWidget mt-4 form-group17 center">
                <button type="button" class="btn btn-primary min-140 mr-1" mat-dialog-close>Save</button>
                <button type="button" class="btn btn-reset min-140" mat-dialog-close>Cancel</button>
            </div> -->
        </form>
    </div>
</ng-template>

<ng-template #copyPopup>
    <div class="dialog-lg" style="height: 400px;">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h2 mat-dialog-title>Manage Weight To Other Subject</h2>
        </div>

        <mat-dialog-content class="mat-typography">

            <!-- <mat-form-field class="w-100" appearance="outline">
                <mat-label>Search Subjects</mat-label>
                <mat-chip-list #chipList2 aria-label="Subject selection">
                    <mat-chip *ngFor="let subject of departmentListMenu" [selectable]="selectable" [removable]="removable"
                        (removed)="remove1(subject)">
                        {{subject.name}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Search Subjects" [matChipInputFor]="chipList2"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="add1($event)">
                </mat-chip-list>
            </mat-form-field> -->

            <!-- <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Subject</mat-label>
                <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="onClickSubject(option)">
                    {{option.subjectName}} {{option.DepartmentName}} {{option.ClassName}} {{option.SectionName}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field> -->

            <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let user of filteredOptions | async" [value]="selectedSubject">
                    <div (click)="optionClicked($event, user)">
                        <mat-checkbox [checked]="user.selected" (change)="toggleSelection(user)" (click)="$event.stopPropagation()">
                            {{ user.subjectName }} {{ user.DepartmentName }} {{user.ClassName}} {{user.SectionName}}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete> -->

            <!-- <ng-container *ngFor="let copySubject of copySubjectListMenu">
                    <i >{{copySubject.subjectName}}</i> <button class="addbtn" (click)="removeAddedSubject(copySubject)">x</button>
                </ng-container> -->

            <!-- <form [formGroup]="form">

                    <p-multiSelect [options]="subjectListMenu" formControlName="selectedCities" defaultLabel="Select a City"
                        optionLabel="subjectName" display="chip" (onSelect)="onSelectSubject($event)">
                    </p-multiSelect>
                  
                  </form> -->

            <div class="form-group multiselected">
                <div class="w-100" appearance="outline">
                    <p-multiSelect [options]="subjectListMenu" [(ngModel)]="copySubjectListMenu"
                        defaultLabel="Select a Subject" optionLabel="subjectName" display="chip"
                        (onClick)="copySubjectListMenu = elm.value" #elm>
                    </p-multiSelect>
                    <mat-label class="mutilselectlable">Roles</mat-label>
                </div>
            </div>

            <div class="btnsWidget form-group17 center">
                <button type="button" class="btn btn-primary min-140 mr-1" mat-dialog-close
                    (click)="copySelectedSubject($event)">Copy</button>
                <button type="button" class="btn btn-reset min-140" mat-dialog-close>Cancel</button>
            </div>

        </mat-dialog-content>
    </div>
</ng-template>



<ng-template #weightedcategory>
    <p class="text-center fs-sm"><b>If Yes:</b> Overall scores will be calculated based on Assessment category
        weightage. <br>
        <b>If No:</b> Overall scores need to be entered manually from grade-book
    </p>
</ng-template>
<ng-template #scores>
    <p class="text-center fs-sm"><b>If Yes: </b>Overall scores will be displayed in Grade Book and report Cards. <br>
        <b>If No: </b>Overall scores will be hidden from Grade Book and Report Cards.
    </p>
</ng-template>