<!-- <div class="container container1580">
    <div class="pageTitle">
        <h2>Manage Teacher Attendance</h2>
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Manage Teacher Attendance</li>
            </ol>
        </div>
    </div> -->
<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>Manage Teacher's Attendance</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Manage Teacher's Attendance</li>
                </ol>
            </div>
        </div>

    </div>
    <!--/Page Title-->
    <div class="cardWidget">
        <div class="assesmentTabs">
            <a routerLink="../manage-teacher-attendance" routerLinkActive="active"><i class="fas fa-tasks"></i> Manage Teacher's
                Attendance</a>
            <a routerLink="../attendance-submission-analytics"  routerLinkActive="active"><i
                    class="fas fa-chart-line"></i> Attendance Submission
                Analytics</a>
            <a routerLink="../attendance-settings" [queryParams]="{ id: '2' }" routerLinkActive="active"><i class="fas fa-cog"></i> Settings</a>
        </div>
        <div class="cardBody">
            <form [formGroup]="teacherAttendanceForm" (ngSubmit)="analyticDta = null;getTeacherAttendanceByDate()">
                <div class="row">

                    <div class="col-lg-5 col-sm-12">
                        <div class="form-group multiselected">
                            <div class="w-100" appearance="outline">
                                <p-multiSelect [options]="teacherListDropdown" required formControlName="selectedTeacher"
                                    defaultLabel="Select a Teacher" optionLabel="name" display="chip"
                                    (onChange)="onElementChange($event)"></p-multiSelect>
                                <mat-label class="mutilselectlable">Teachers</mat-label>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-12">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)"
                                formControlName="Date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="teacherAttendanceForm.controls['Date'].invalid">
                                Date is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-sm-12">
                        <button type="submit" class="btn btn-primary btn-block smallerBtn mt-1" matRipple>Find <svg
                                xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478"
                                viewBox="0 0 12.611 8.478">
                                <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                                    <path id="Path"
                                        d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                                        transform="translate(-3 -5.95)" fill="#fff" />
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </form>
            <div *ngIf="TEACHER_DATA && TEACHER_DATA.length>0">
                <div class="topLegendWidget d-sm-flex justify-content-between">
                    <!-- <div class="multiCheckstatus mr-2">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-select placeholder="Select Status" #select multiple>
                                <mat-option (click)="optionClick()" *ngFor="let teacher of teachers"
                                    [value]="teacher.value">
                                    {{teacher.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->

                    <div class="legendsAttendance row-flex align-items-center">
                        <div class="legendCol" *ngFor="let att of AttendanceStatusList">
                            <span *ngIf="att.statusName != 'Not Marked Yet'" class="legendColor" [ngStyle]="{'background-color': att.colorCode }"></span> 
                            <label *ngIf="att.statusName != 'Not Marked Yet'">{{att.statusName}}</label>
                        </div>

                    </div>
                </div>
                <div class="float-right">
                    <div class="d-flex">
                        <div class="downloadTable tableActions" matTooltip="Download Attendence">
                            <a (click)="exporter.exportTable('csv', {fileName:'teacher_attendance'})"><i
                                    title="Export Students" class="fas fa-download mt-3"></i></a>
                        </div>
                        <div class="btn-group mr-2" role="group">
                            <button class="btn  btn-outline-primary prevnex" (click)=prevWeek() matRipple><i
                                    class="fas fa-chevron-left"></i>
                                Previous</button>
                            <button class="btn  btn-outline-primary prevnex" (click)=nextWeek() matRipple>Next <i
                                    class="fas fa-chevron-right"></i></button>
                        </div>
                    </div>
                </div>
                <div class="mat-elevation-z8 py-4">
                    <table matTableExporter #exporter="matTableExporter" mat-table [dataSource]="dataSource">

                        <!-- ID Column -->
                        <ng-container matColumnDef="teacherid">
                            <th mat-header-cell *matHeaderCellDef> Teacher ID </th>
                            <td mat-cell *matCellDef="let element"> {{element.teacherID}} </td>
                        </ng-container>

                        <!-- ID Column -->
                        <ng-container matColumnDef="teacherName">
                            <th mat-header-cell *matHeaderCellDef> Teacher Name </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.teacherName}}
                            </td>
                        </ng-container>

                        <!-- Description Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email </th>
                            <td mat-cell *matCellDef="let element"> {{element.email}}</td>
                        </ng-container>

                        <!-- Plan Name Column -->
                        <ng-container matColumnDef="contact">
                            <th mat-header-cell *matHeaderCellDef> Contact </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.contact ? "+" : ''}}{{element.contact.slice(1,-1)}}
                            </td>
                        </ng-container>

                        <!-- Monday Column -->
                        <ng-container matColumnDef="monday">
                            <th mat-header-cell *matHeaderCellDef class="text-left">
                                <div class="attendanceWeek" [ngClass]="this.collapseMonFlag ? 'collapse_mon' : ''">
                                    <span (click)="collapseMon()">Mon <i class="fas fa-caret-down"></i>
                                        <br>{{WeekDates.MonDate}}
                                    </span>
                                    <div class="markAttendanceDD">
                                        <span (click)="collapseMon()" class="close-icon">
                                            <i class="fas fa-close"></i>
                                          </span>
                                        <p class="d-flex justify-content-between">
                                            <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                                                (change)="markAll($event.checked)">
                                                Mark All
                                            </mat-checkbox>
                                            <select [disabled]="isHolidaySelected || !isMarkAllSelected"
                                                class="selectAttendance"
                                                (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Mon')">
                                                <option value="{{att.attendanceStatusMasterID}}"
                                                    *ngFor="let att of AttendanceStatusList"
                                                    [ngStyle]="{'background-color': att.colorCode }">
                                                    {{att.statusSortCode}}
                                                </option>

                                            </select>
                                        </p>
                                        <p class="mt-1 holidayMarkTop">
                                            <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                                                (change)="markHoliday($event.checked, 'Mon')">Holiday
                                            </mat-checkbox>

                                            <i class="fas fa-info-circle ml-2"
                                                matTooltip="This day will be marked as holiday."
                                                aria-label="Button that displays a tooltip when focused or hovered over"></i>
                                        </p>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-left">
                                <span class="markedAtt presentMark" id="col{{element.teacherID}}monday"
                                    (click)="changeAttStatusOnClick(element.monday, element.teacherID, 'monday', true)">
                                </span>
                            </td>
                        </ng-container>
                        <!-- Tuesday Column -->
                        <ng-container matColumnDef="tuesday">
                            <th mat-header-cell *matHeaderCellDef class="text-left">
                                <div class="attendanceWeek" [ngClass]="this.collapseTueFlag ? 'collapse_tue' : ''">
                                    <span (click)="collapseTue()">Tue <i class="fas fa-caret-down"></i>
                                        <br>{{WeekDates.TuesDate}}
                                    </span>
                                    <div class="markAttendanceDD">
                                        <span (click)="collapseTue()" class="close-icon">
                                            <i class="fas fa-close"></i>
                                          </span>
                                        <p class="d-flex justify-content-between">
                                            <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                                                (change)="markAll($event.checked)">
                                                Mark All</mat-checkbox>
                                            <select class="selectAttendance"
                                                [disabled]="isHolidaySelected || !isMarkAllSelected"
                                                (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Tues')">
                                                <option value="{{att.attendanceStatusMasterID}}"
                                                    *ngFor="let att of AttendanceStatusList">
                                                    {{att.statusSortCode}}</option>
                                            </select>
                                        </p>
                                        <p class="mt-1 holidayMarkTop">
                                            <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                                                (change)="markHoliday($event.checked, 'Tues')">Holiday
                                            </mat-checkbox>
                                            <i class="fas fa-info-circle ml-2"
                                                matTooltip="This day will be marked as holiday."
                                                aria-label="Button that displays a tooltip when focused or hovered over"></i>
                                        </p>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-left">
                                <span class="markedAtt absentMark" id="col{{element.teacherID}}tuesday"
                                    (click)="changeAttStatusOnClick(element.tuesday, element.teacherID, 'tuesday', true)">
                                </span>
                            </td>
                        </ng-container>





                        <!-- Wednesday Column -->
                        <ng-container matColumnDef="wednesday">
                            <th mat-header-cell *matHeaderCellDef class="text-left">
                                <div class="attendanceWeek" [ngClass]="this.collapseWedFlag ? 'collapse_wed' : ''">
                                    <span (click)="collapseWed()">Wed <i class="fas fa-caret-down"></i><br>
                                        {{WeekDates.WedDate}}
                                    </span>
                                    <div class="markAttendanceDD">
                                        <span (click)="collapseWed()" class="close-icon">
                                            <i class="fas fa-close"></i>
                                          </span>
                                        <p class="d-flex justify-content-between">
                                            <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                                                (change)="markAll($event.checked)">
                                                Mark All</mat-checkbox>
                                            <select class="selectAttendance"
                                                [disabled]="isHolidaySelected || !isMarkAllSelected"
                                                (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Wed')">
                                                <option value="{{att.attendanceStatusMasterID}}"
                                                    *ngFor="let att of AttendanceStatusList">
                                                    {{att.statusSortCode}}
                                                </option>
                                            </select>
                                        </p>
                                        <p class="mt-1 holidayMarkTop">
                                            <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                                                (change)="markHoliday($event.checked, 'Wed')">Holiday</mat-checkbox>
                                            <i class="fas fa-info-circle ml-2"
                                                matTooltip="This day will be marked as holiday."
                                                aria-label="Button that displays a tooltip when focused or hovered over"></i>
                                        </p>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-left">
                                <span class="markedAtt holidayMark" id="col{{element.teacherID}}wednesday"
                                    (click)="changeAttStatusOnClick(element.wednesday, element.teacherID, 'wednesday', true)">
                                </span>
                            </td>
                        </ng-container>
                        <!-- Thursday Column -->
                        <ng-container matColumnDef="thursday">
                            <th mat-header-cell *matHeaderCellDef class="text-left">
                                <div class="attendanceWeek" [ngClass]="this.collapseThuFlag ? 'collapse_thu' : ''">
                                    <span (click)="collapseThu()">Thu <i class="fas fa-caret-down"></i>
                                        <br>{{WeekDates.ThursDate}}
                                    </span>
                                    <div class="markAttendanceDD">
                                        <span (click)="collapseThu()" class="close-icon">
                                            <i class="fas fa-close"></i>
                                          </span>
                                        <p class="d-flex justify-content-between">
                                            <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                                                (change)="markAll($event.checked)">
                                                Mark All</mat-checkbox>
                                            <select class="selectAttendance"
                                                [disabled]="isHolidaySelected || !isMarkAllSelected"
                                                (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Thurs')">
                                                <option value="{{att.attendanceStatusMasterID}}"
                                                    *ngFor="let att of AttendanceStatusList">
                                                    {{att.statusSortCode}}</option>
                                            </select>
                                        </p>
                                        <p class="mt-1 holidayMarkTop">
                                            <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                                                (change)="markHoliday($event.checked, 'Thurs')">Holiday
                                            </mat-checkbox>
                                            <i class="fas fa-info-circle ml-2"
                                                matTooltip="This day will be marked as holiday."
                                                aria-label="Button that displays a tooltip when focused or hovered over"></i>
                                        </p>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-left">
                                <span class="markedAtt presentMark" id="col{{element.teacherID}}thursday"
                                    (click)="changeAttStatusOnClick(element.thursday, element.teacherID, 'thursday', true)">

                                </span>
                            </td>
                        </ng-container>
                        <!-- Friday Column -->
                        <ng-container matColumnDef="friday">
                            <th mat-header-cell *matHeaderCellDef class="text-left">
                                <div class="attendanceWeek" [ngClass]="this.collapseFriFlag ? 'collapse_fri' : ''">
                                    <span (click)="collapseFri()">Fri <i class="fas fa-caret-down"></i>
                                        <br>{{WeekDates.FriDate}}
                                    </span>
                                    <div class="markAttendanceDD">
                                        <span (click)="collapseFri()" class="close-icon">
                                            <i class="fas fa-close"></i>
                                          </span>
                                        <p class="d-flex justify-content-between">
                                            <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                                                (change)="markAll($event.checked)">
                                                Mark All</mat-checkbox>
                                            <select class="selectAttendance"
                                                [disabled]="isHolidaySelected || !isMarkAllSelected"
                                                (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Fri')">
                                                <option value="{{att.attendanceStatusMasterID}}"
                                                    *ngFor="let att of AttendanceStatusList">
                                                    {{att.statusSortCode}}</option>
                                            </select>
                                        </p>
                                        <p class="mt-1 holidayMarkTop">
                                            <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                                                (change)="markHoliday($event.checked, 'Fri')">Holiday
                                            </mat-checkbox>
                                            <i class="fas fa-info-circle ml-2"
                                                matTooltip="This day will be marked as holiday."
                                                aria-label="Button that displays a tooltip when focused or hovered over"></i>
                                        </p>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-left">
                                <span class="markedAtt nullMark" id="col{{element.teacherID}}friday"
                                    (click)="changeAttStatusOnClick(element.friday, element.teacherID, 'friday', true)">

                                </span>
                            </td>
                        </ng-container>
                        <!-- Sat Column -->
                        <ng-container matColumnDef="saterday">
                            <th mat-header-cell *matHeaderCellDef class="text-left">
                                <div class="attendanceWeek" [ngClass]="this.collapseSatFlag ? 'collapse_sat' : ''">
                                    <span (click)="collapseSat()">Sat <i class="fas fa-caret-down"></i>
                                        <br>{{WeekDates.SatDate}}
                                    </span>
                                    <div class="markAttendanceDD">
                                        <span (click)="collapseSat()" class="close-icon">
                                            <i class="fas fa-close"></i>
                                          </span>
                                        <p class="d-flex justify-content-between">
                                            <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                                                (change)="markAll($event.checked)">
                                                Mark All</mat-checkbox>
                                            <select class="selectAttendance"
                                                [disabled]="isHolidaySelected || !isMarkAllSelected"
                                                (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Sat')">
                                                <option value="{{att.attendanceStatusMasterID}}"
                                                    *ngFor="let att of AttendanceStatusList">
                                                    {{att.statusSortCode}}</option>
                                            </select>
                                        </p>
                                        <p class="mt-1 holidayMarkTop">
                                            <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                                                (change)="markHoliday($event.checked, 'Sat')">Holiday
                                            </mat-checkbox>
                                            <i class="fas fa-info-circle ml-2"
                                                matTooltip="This day will be marked as holiday."
                                                aria-label="Button that displays a tooltip when focused or hovered over"></i>
                                        </p>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-left">
                                <span class="markedAtt holidayMark" id="col{{element.teacherID}}saturday"
                                    (click)="changeAttStatusOnClick(element.saturday, element.teacherID, 'saturday', true)">

                                </span>
                            </td>
                        </ng-container>
                        <!-- Sun Column -->
                        <ng-container matColumnDef="sunday">
                            <th mat-header-cell *matHeaderCellDef class="text-left">
                                <div class="attendanceWeek" [ngClass]="this.collapseSunFlag ? 'collapse_sun' : ''">
                                    <span (click)="collapseSun()">Sun <i class="fas fa-caret-down"></i>
                                        <br>{{WeekDates.SunDate}}
                                    </span>
                                    <div class="markAttendanceDD">
                                        <span (click)="collapseSun()" class="close-icon">
                                            <i class="fas fa-close"></i>
                                          </span>
                                        <p class="d-flex justify-content-between">
                                            <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                                                (change)="markAll($event.checked)">
                                                Mark All</mat-checkbox>
                                            <select class="selectAttendance"
                                                [disabled]="isHolidaySelected || !isMarkAllSelected"
                                                (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Sun')">
                                                <option value="{{att.attendanceStatusMasterID}}"
                                                    *ngFor="let att of AttendanceStatusList">
                                                    {{att.statusSortCode}}</option>
                                            </select>
                                        </p>
                                        <p class="mt-1 holidayMarkTop">
                                            <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                                                (change)="markHoliday($event.checked, 'Sun')">Holiday
                                            </mat-checkbox>
                                            <i class="fas fa-info-circle ml-2"
                                                matTooltip="This day will be marked as holiday."
                                                aria-label="Button that displays a tooltip when focused or hovered over"></i>
                                        </p>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-left">
                                <span class="markedAtt holidayMark" id="col{{element.teacherID}}sunday"
                                    (click)="changeAttStatusOnClick(element.sunday, element.teacherID, 'sunday', true)">

                                </span>
                            </td>
                        </ng-container>



                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>

                <div class="justify-content-between">

                    <div class="row">
                        <div class="col-md-9 col-sm-8">
                            <div class="notiOptions flex-grow-1" [ngClass]="this.collapseSentNoti ? 'active' : ''">
                                <mat-checkbox (click)="collapseNoti()" class="mb-2">Send Absent Notification
                                </mat-checkbox>
                                <div class="notiOptionWidget">
                                    <div class="notiOptionList">
                                        <div class="leftLabel">Send to: </div>
                                        <div class="rightCheckBoxes">
                                            <!-- <mat-checkbox class="example-margin" (click)="NotificationToParent()">
                                                    Parents</mat-checkbox> -->
                                            <mat-checkbox class="example-margin" (click)="NotificationToStudent()">
                                                Teachers</mat-checkbox>
                                        </div>
                                    </div>
                                    <div class="notiOptionList">
                                        <div class="leftLabel">Send via:</div>
                                        <div class="rightCheckBoxes">
                                            <mat-checkbox class="example-margin" (click)="NotificationViaEmail()">
                                                Email</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="NotificationViaSMS()">SMS
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                    <div class="notiOptionList">
                                        <div class="leftLabel">Notify For:</div>
                                        <div class="rightCheckBoxes" *ngIf="show || showForSMS">
                                            <mat-checkbox class="example-margin" *ngIf="monAbsent && monAbsent.length>0"
                                                (click)="mondayAbsent()">Mon
                                            </mat-checkbox>
                                            <mat-checkbox class="example-margin" *ngIf="tueAbsent && tueAbsent.length>0"
                                                (click)="tuesdayAbsent()">
                                                Tue</mat-checkbox>
                                            <mat-checkbox class="example-margin" *ngIf="webAbsent && webAbsent.length>0"
                                                (click)="wednesdayAbsent()">
                                                Wed</mat-checkbox>
                                            <mat-checkbox class="example-margin"
                                                *ngIf="thurAbsent && thurAbsent.length>0" (click)="thursdayAbsent()">Thu
                                            </mat-checkbox>
                                            <mat-checkbox class="example-margin" *ngIf="friAbsent && friAbsent.length>0"
                                                (click)="fridayAbsent()">Fri
                                            </mat-checkbox>
                                            <mat-checkbox class="example-margin" *ngIf="satAbsent && satAbsent.length>0"
                                                (click)="saturdayAbsent()">
                                                Sat</mat-checkbox>
                                            <mat-checkbox class="example-margin" *ngIf="sunAbsent && sunAbsent.length>0"
                                                (click)="sundayAbsent()">Sun
                                            </mat-checkbox>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                            <div class="btnsWidget text-right">

                                <button class="btn btn-primary" (click)="saveTeacherAttendance()" matRipple>Submit
                                </button>


                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>
</div>