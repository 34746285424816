<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <!-- <h2>{{firstName}} {{lastName}}</h2> -->
            <h2>{{studentName}}</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic"><i
                                class="fas fa-home"></i> Home</a>
                    <!-- <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
                            Learning</a></li> -->
                    <li class="breadcrumb-item"><a
                            routerLink="/admin/academicslearning/manage-students">Manage Students</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Report Card</li>
                </ol>
            </div>
           
        </div>

        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
            </div>
          </div>
    </div>
    <div class="cardWidget">
        <!-- <div class="cardTitle centerVTItems justify-content-between"><h3>Virat Sharma</h3></div> -->
        <app-profile-tabs></app-profile-tabs>
        <div class="cardBody">
            <div class="profileDetailsWidget pt-2 enrollmentWidget">
                <form>
                    <mat-form-field class="w-50" appearance="outline">
                        <mat-label> Select Class Section</mat-label>
                        <mat-select  placeholder="Class Section" (selectionChange)="getReportCard($event)" >
                                <mat-option *ngFor="let item of studentList" [value]="item.id">{{item.studentName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>

            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex btnsWidget reportButtons"></div>
                <div *ngIf="showPrintButton">
                    <i class="fas fa-ellipsis-v" [matMenuTriggerFor]="printMenu"></i>
                    <mat-menu #printMenu="matMenu" xPosition="before">
                        <button mat-menu-item [useExistingCss]="true"
                        printSectionId="print-section"
                        ngxPrint>
                            Print Black &amp; White
                        </button>
                        <button mat-menu-item mat-menu-item [useExistingCss]="true"
                        styleSheetFile="../../../../../../assets/css/theme/default.css" 
                        printSectionId="print-section"
                        ngxPrint>
                            Print Color
                        </button>
                    </mat-menu>
                </div>

            </div>
            <div class="row-flex" id="print-section">
                <div class=" col-md-13 col-xl-12 s12">
                    <div class="reportCardFrame">
                        <app-default-template *ngIf="reportCardFormatID == 0" [studentData] = "studentDetails"></app-default-template>
                        <app-report-cards-template1 *ngIf="reportCardTemplateID == 3" [studentData] = "studentDetails"></app-report-cards-template1>
                        <app-report-cards-template2 *ngIf="reportCardTemplateID == 4" [studentData] = "studentDetails"></app-report-cards-template2>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


