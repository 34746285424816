import { HttpClient } from '@angular/common/http';
import { Injectable, ElementRef } from '@angular/core';
import * as FileSaver from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';

export interface ExcelJson {
  data: Array<any>;
  header?: Array<string>;
  skipHeader?: boolean;
  origin?: string | number;

}

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  constructor(
    private httpp: HttpClient
  ) {
   }

   
  /** Date Format*/
  GetFormattedDate(date: any) {
    const todayTime = new Date(date);
    const month = todayTime.getMonth() + 1;
    const day = todayTime.getDate();
    const year = todayTime.getFullYear();
    return month + '/' + day + '/' + year;
  }

  /**Reject numbers */
  checkForNumber(res: any) {
    const regexp = new RegExp('^[1-9]\d{0,2}$');
    const result = regexp.test(res.key);
    if (result) {
      res.preventDefault();
      return false;
    } else {
      return true
    }
  }

  

  getSystemIpAddress(){
    try {
      this.httpp.get('https://api.ipify.org?format=json').subscribe((res: any)=>{
        localStorage.setItem('IpLocal', String(res.ip));
      });
    } catch (error) {
      console.error('Error retrieving public IP:', error);
      localStorage.setItem('IpLocal', '');
    }
  }



  /** Reject Special Symbol */
  checkForNameSpecialSymbol(res: any) {
    const regexp = new RegExp('^[A-Za-z0-9\@\.\_\&\*\#\%\!\$\(\)\,\-\/\ ]*$');
    const result = regexp.test(res.key);
    if (!result) {
      res.preventDefault();
      return false;
    } else {
      return true
    }
  }

  // Allow only alphabet
  allowAlphabetOnly(res: any) {
    const regexp = new RegExp('^[0-9!@#$&()\\-`.+,/\"]*$');
    const result = regexp.test(res.key);
    if (result) {
      res.preventDefault();
      this.noWhitespaceValidator(res)
      return false;
    } else {
      this.noWhitespaceValidator(res)
      return true;
    }
  }

  /** Allow only Number */
  allowOnlyNumber(res: any) {
    const regexp = new RegExp('^([0-9])$');
    const result = regexp.test(res.key);
    if (!result) {
      res.preventDefault();
      return false;
    } else {
      return true
    }
  }

  noWhitespaceValidator(event: any) {
    const isWhitespace = (event.target.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : event.target.value = '';
  }

  /** Allow only Aplpha Number */
  allowAlphaNumber(res: any) {
    const regexp = new RegExp('^[!$()\\-`.+/\%^*=\|?><\~"]$');
    const result = regexp.test(res.key);
    if (result) {
      res.preventDefault();
      this.noWhitespaceValidator(res)
      return false;
    } else {
      this.noWhitespaceValidator(res)
      return true;
    }
  }

  checkSubDomain(res: any) {
    const regexp = new RegExp('^[a-zA-Z0-9]+$');
    const result = regexp.test(res.key);
    if (!result) {
      res.preventDefault();
      this.noWhitespaceValidator(res)
      return false;
    } else {
      this.noWhitespaceValidator(res)
      return true;
    }
  }
  /**Method to get first day date of month */
  getFirstDayOfMonth(value: any) {
    const date = value,
      y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    console.log('firs', firstDay.toDateString());
    return firstDay.toDateString();
  }

  getLastDayOfMonth(value: any) {
    const date = value,
      y = date.getFullYear(), m = date.getMonth();
    const lastDay = new Date(y, m + 1, 0);
    console.log('last', lastDay.toDateString());
    return lastDay.toDateString();
  }
  phoneLengths(value: any) {
    if (value > 10) {
      return true;
    } else {
      return false;
    }
  }


  AttSubmission: any;

  setAttSubmissionData(data: any) {
    this.AttSubmission = data;
  }
  getAttSubmissionData() {
    return this.AttSubmission;
  }

  TeacherAttSubmission: any;

  setTeacherAttSubmissionData(data: any) {
    this.TeacherAttSubmission = data;
  }
  getTeacherAttSubmissionData() {
    return this.TeacherAttSubmission;
  }


  /**
   * Creates excel from the table element reference.
   *
   * @param element DOM table element reference.
   * @param fileName filename to save as.
   */
  public exportTableElmToExcel(element: ElementRef, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element.nativeElement);
    // generate workbook and add the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);

  }

  /**
   * Creates XLSX option from the Json data. Use this to customise the sheet by adding arbitrary rows and columns.
   *
   * @param json Json data to create xlsx.
   * @param fileName filename to save as.
   */
  public exportJsonToExcel(json: ExcelJson[], fileName: string): void {
    // inserting first blank row
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      json[0].data,
      this.getOptions(json[0])
    );

    for (let i = 1, length = json.length; i < length; i++) {
      // adding a dummy row for separation
      XLSX.utils.sheet_add_json(
        worksheet,
        [{}],
        this.getOptions(
          {
            data: [],
            skipHeader: true
          }, -1)
      );
      XLSX.utils.sheet_add_json(
        worksheet,
        json[i].data,
        this.getOptions(json[i], -1)
      );
    }
    const workbook: XLSX.WorkBook = { Sheets: { Sheet1: worksheet }, SheetNames: ['Sheet1'] };
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);
  }

  /**
   * Creates XLSX option from the data.
   *
   * @param json Json data to create xlsx.
   * @param origin XLSX option origin.
   * @returns options XLSX options.
   */
  private getOptions(json: ExcelJson, origin?: number): any {
    // adding actual data
    const options: ExcelJson = {
      skipHeader: true,
      origin: -1,
      header: [],
      data: []
    };
    options.skipHeader = json.skipHeader ? json.skipHeader : false;
    if (!options.skipHeader && json.header && json.header.length) {
      options.header = json.header;
    }
    if (origin) {
      options.origin = origin ? origin : -1;
    }
    return options;
  }

  /**
   * Saves the file on client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  /**
   * Creates an array of data to csv. It will automatically generate title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(rows: object[], fileName: string, columns?: string[]): any {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map((row: any) => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');
    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  }

  /*
    this.users = [
    {
      id: 1,
      firstName: 'Mark',
      lastName: 'Otto',
      handle: '@mdo'
    },
    {
      id: 2,
      firstName: 'Jacob',
      lastName: 'Thornton',
      handle: '@fat'
    },
    {
      id: 3,
      firstName: 'Larry',
      lastName: 'the Bird',
      handle: '@twitter'
    },
  ];
  
    }
  
  
  **
   * Function prepares data to pass to export service to create excel from Table DOM reference
   *
   *
  exportElmToExcel(): void {
    this.exportService.exportTableElmToExcel(this.userTable, 'user_data');
  }
  
  **
   * Function prepares data to pass to export service to create excel from Json
   *
   *
  exportToExcel(): void {
  
    const edata: Array<ExcelJson> =[];
    const udt: ExcelJson = {
      data: [
        { A: 'User Data' }, // title
        { A: '#', B: 'First Name', C: 'Last Name', D: 'Handle' }, // table header
      ],
      skipHeader: true
    };
    this.users.forEach(user => {
      udt.data.push({
        A: user.id,
        B: user.firstName,
        C: user.lastName,
        D: user.handle
      });
    });
    edata.push(udt);
  
    // adding more data just to show "how we can keep on adding more data"
    const bd = {
      data: [
        // chart title
        { A: 'Some more data', B: '' },
        { A: '#', B: 'First Name', C: 'Last Name', D: 'Handle' }, // table header
      ],
      skipHeader: true
    };
    this.users.forEach(user => {
      bd.data.push({
        A: String(user.id),
        B: user.firstName,
        C: user.lastName,
        D: user.handle
      });
    });
    edata.push(bd);
    this.exportService.exportJsonToExcel(edata, 'user_data_customized');
  }
  
  **
   * Funtion prepares data to pass to export service to create csv from Json
   *
   *
  exportToCsv(): void {
    this.exportService.exportToCsv(this.users, 'user-data', ['id', 'firstName', 'lastName', 'handle']);
  }
  */
}
