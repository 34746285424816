import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FinanceAndAccountService } from 'src/app/services/finance-and-account.service';
@Component({
  selector: 'app-manage-idcard',
  templateUrl: './manage-idcard.component.html',
  styleUrls: ['./manage-idcard.component.css']
})
export class ManageIdcardComponent implements OnInit {
  sendas: string[] = ['test@test.com(Institute Email)', 'test@test.com(Your Email)'];
  form: FormGroup;
  studentform: FormGroup;
  studentnameFlag: boolean;
  admissionFlag: boolean;
  studentclassFlag: boolean;
  studentSectionFlag: boolean;
  studentPhotoFlag: boolean;
  generateQRFlag: boolean;
  generateBarCodeFlag: boolean;
  dobFlag: boolean;
  defaultTillFlag: boolean;
  planTextFlag: boolean;
  classsectionFlag: boolean;
  studentId: any;
  studentIdList: any;
  studentList: any;
  defaultvalid: string;
  plaintext: string;
  studentData: any;
  selectedStudent: any
  user: any;
  users: any;
  idcardBackground: any;
  instituteName: string;
  minDate = new Date();
  maxDate = new Date();
  @ViewChild('content') content: ElementRef;
  croppedImage: any;
  orgID: any;
  constructor(private accountService: FinanceAndAccountService, private formBuilder: FormBuilder,
    private studentformBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private studentService: StudentService,
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != "") {
      this.idcardBackground = currentUser.idcardBackground

    }
    else {
      this.idcardBackground = "#fff";
    }
  }

  logo: any;
  ngOnInit(): void {
    this.studentnameFlag = false;
    this.admissionFlag = false;
    this.studentclassFlag = false;
    this.studentSectionFlag = false;
    this.studentPhotoFlag = false;
    this.generateQRFlag = false;
    this.generateBarCodeFlag = false;
    this.dobFlag = false;
    this.defaultTillFlag = false;
    this.planTextFlag = false;
    this.classsectionFlag = false;
    this.studentData = {
      "studentName": "",
      "admissionNo": "",
      "studentClass": "",
      "studentSection": "",
      "studentPhoto": "",
      "generateQR": "",
      "generateBarCode": "",
      "dob": "",
      "defaultTill": "",
      "planText": "",
      "classSection": ""
    }

    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    console.log("USERR", currentUser);
    this.instituteName = currentUser.orgName;
    this.activatedRoute.queryParams.subscribe(params => {
      console.log("recieved", params.data)
      this.studentIdList = {
        "studentsID": params.data == undefined ? null : params.data
      }
    });
    console.log("IDDS", this.studentIdList)
    this.GetStudentByIdList();
    if (localStorage.getItem('OrgLogo') != "" && localStorage.getItem('OrgLogo') != null) {
      //debugger;
      this.croppedImage = localStorage.getItem('OrgLogo');
      var imageName = this.croppedImage.split('/')
      this.orgID = currentUser.orgID;
      let obj = {
        orgId: this.orgID,
        imagePath: imageName[5]
      }
      debugger
      this.accountService.getLogoInBase64(obj).subscribe((res: any) => {
        debugger
        if (res) {
          this.logo = res.responseData;
          console.log('imgLogo', this.logo)
        }
      })
    } else {
      this.logo = "../../../../../../../assets/img/admin/logo.png";
    }
  }

  // if(localStorage.getItem('OrgLogo') != "" && localStorage.getItem('OrgLogo') != null) {

  //   this.logo = localStorage.getItem('OrgLogo');

  // }else {

  //   this.logo = '../../../../../../assets/img/admin/org_logo.png';

  // }
  // }
  GetStudentByIdList() {
    this.studentService.getStudentsDatabyIdList(this.studentIdList).subscribe((res: any) => {
      this.studentList = res.responseData;
      console.log("ALLStudent", this.studentList)
      if (this.studentList.length == 1) {
        this.studentData = {
          "studentName": this.studentList[0].firstName + [' '] + this.studentList[0].lastName,
          "admissionNo": this.studentList[0].admissionNumber,
          "studentClass": this.studentList[0].className,
          "studentSection": this.studentList[0].sectionName,
          "studentPhoto": this.studentList[0].profilePhoto,
          "generateQR": this.studentList[0].qrCode,
          "generateBarCode": this.studentList[0].barCode,
          "dob": this.studentList[0].dateOfBirth,
          "defaultTill": [''],
          "planText": [''],
          "classSection": this.studentList[0].classSectionName
        }
        this.selectedStudent = this.studentList[0]
        console.log("DEFAult data", this.studentData)
      }
    })
  }
  onSelect(data: any) {
    console.log("SELECTED DATA", data)
    this.studentData = {
      "studentName": data.value['firstName'] + [' '] + data.value['lastName'],
      "admissionNo": data.value['admissionNumber'],
      "studentClass": data.value['className'],
      "studentSection": data.value['sectionName'],
      "studentPhoto": data.value['profilePhoto'],
      "generateQR": data.value['qrCode'],
      "generateBarCode": data.value['barCode'],
      "dob": data.value['dateOfBirth'],
      "defaultTill": data.value[''],
      "planText": data.value[''],
      "classSection": data.value['classSectionName']
    }
    console.log("studentData", this.studentData)
  }

  onsChange() {
    console.log("Class", this.studentclassFlag, this.studentSectionFlag)
    if (this.studentSectionFlag == true && this.studentclassFlag == true) {
      this.classsectionFlag = true;
    }
    else {
      this.classsectionFlag = false;
    }
  }

  // GetStudentById(){
  //   this.studentService.GetStudentById(this.studentId).subscribe((res:any) => {
  //     console.log("studentdata",res)
  //     this.studentform.patchValue({
  //       studentName: res.responseData['firstName'],
  //     admissionNo: res.responseData['admissionNumber'],
  //     studentClass: res.responseData['className'],
  //     studentSection: res.responseData['sectionName'],
  //     studentPhoto: res.responseData['profilePhoto'],
  //     generateQR: res.responseData['qrCode'],
  //     generateBarCode: res.responseData['barCode'],
  //     dob: res.responseData['dateOfBirth'],
  //     defaultTill: res.responseData[''],
  //     planText: res.responseData['']
  //     })
  //   })
  //   console.log("STUDENTFORM",this.studentform)
  // }
  selected = -1;
  generateList: string[] = [
    'Generate QR Code', 'Generate BarCode'
  ]
  onChange(data: any) {

    if (data == 0) {
      this.generateQRFlag = true;
      this.generateBarCodeFlag = false;
      console.log("00", this.generateQRFlag, this.generateBarCodeFlag)
    }
    if (data == 1) {
      this.generateQRFlag = false;
      this.generateBarCodeFlag = true;
      console.log("11", this.generateQRFlag, this.generateBarCodeFlag)
    }
  }
  fileUrl: any
  pdf() {
    const doc = new jsPDF();
    const content = this.content.nativeElement;
    html2canvas(content).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      // Few necessary setting options
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const doc = new jsPDF('p', 'mm');
      let heightLeft = imgHeight;
      let position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      // Generated PDF
      doc.save(this.studentData.studentName + '.pdf');
    });
  }
}
