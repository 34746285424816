import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import { PermissionService } from 'src/app/services/permission.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';

@Component({
  selector: 'app-grade-setup',
  templateUrl: './grade-setup.component.html',
  styleUrls: ['./grade-setup.component.css']
})
export class GradeSetupComponent implements OnInit {
  users: any;
  user: any;
  permissions: any;
  constructor(
    private permissionService: PermissionService,
    private roleService: RoleAndPermissionService,
    private masterService : MasterService
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.user = currentUser;
    }
   }

  ngOnInit(): void {
    //Legasov Added 25 March 2022
this.permissionService.checkComponentHasScreenPermission('Grade Setup');
    this.permissions = this.permissionService.getAllPermissions();
    if(this.permissions.length == 0){
     this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
       if(res.status> 0){
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
       }
     })
    }
  }


  activityLog:any;
  ActivityLogTrack()
  {
    
    this.activityLog={
      ActivityLogTrack:{
              ModuleName: 'Academic & Learning',
              SubModuleName: 'Asessment & Gradings',
              ActivityTypeId: 10,
              Description: 'Grade-setup record viewed by',
              ScreenName: 'Grade Setup',
              RecordTypeId: 54,  
              RecordId: 0,      
              Type: 0
            }
    }
    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response:any)=>{
      if(response!=null && response !=undefined && response !=''){
        let responseData=response.responseData;
        console.log("responseData:",responseData);
      }
  
  
    });
  }


  checkScreenPermission(screen: any){
    return this.permissions.find((x:any) => x.screenName == screen);
  }
  checkActionPermission(actionName: any){
    let screen = 'Grade Setup';
   //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
  //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
}

}
