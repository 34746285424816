import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageAssessmentAndGradingsService } from 'src/app/app-layout/content/academics-and-learning/assesment-and-gradings/services/manage-assessment-and-gradings.service';
import { MappingContainerStudentGradeComponent } from '../mapping-container-student-grade/mapping-container-student-grade.component';
import * as XLSX from 'xlsx';
import { NumberInput } from '@angular/cdk/coercion';

@Component({
  selector: 'app-import-grade-book-status',
  templateUrl: './import-grade-book-status.component.html',
  styleUrls: ['./import-grade-book-status.component.css']
})
export class ImportGradeBookStatusComponent implements OnInit {

  public link: number;
  public message: string;
  public caller:string;
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  failedCount: number=0;
  totalCount: number=0;
  showStats: boolean;
  parentRef:MappingContainerStudentGradeComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private gradeBookService: ManageAssessmentAndGradingsService) {
    this.link = data.dataKey.link;
    this.message=data.dataKey.message;
    this.caller = data.dataKey.caller;
    this.failedCount = data.dataKey.failedCount;
    this.totalCount=data.dataKey.totalCount;
    this.showStats= data.dataKey.showStats;
    this.parentRef= data.dataKey.parentRef;
   }

  ngOnInit(): void {
  }
  closeDialog(){
    this.parentRef.dialogRef.close();
    this.parentRef.parentRef.closeDialog();
  }
  exportCsvToXlsx(data: any,filename: string){
    let workBook: any = null;
          let parsedExcelRowArray = null;
          let rawExcel: File;
         const reader = new FileReader();
         reader.onload = (event) => {
         const data = reader.result;
         workBook = XLSX.read(data, { type: 'binary' });
         rawExcel = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

           const blob = new File([new Blob([rawExcel], {type: this.fileType})], `${filename}.xlsx`);
          const url= window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          link.download = `${filename}.xlsx`;
          link.click();
       };

        reader.readAsBinaryString(data);
  }
  downloadFile(data : any){
    this.exportCsvToXlsx(data,"StatusReport");
  }
  getStatusReport(){
         this.gradeBookService.GetImportGradeStatusReport(this.link).subscribe({
          next: (data: any)=>{
            this.downloadFile(data);
         
          },
          error: (event: any)=>{},
        });
    }
}
