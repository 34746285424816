<form>
    <div class="cardBody">
        <div class="reportCardsWidget mt-3 mt-md-0">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex btnsWidget reportButtons">
                </div>
            </div>
            <div class="row-flex">
                <div class="col-md-4 col-xl-3 s2">
                    <div class="manageCardLeft">
                        <div class="template-group-title my-2">Report Card Settings</div>
                        <div *ngIf="instituteGroup != null" class="sub-head">Institute</div>
                        <div class="list">
                            <div *ngFor="let item of instituteGroup; let i = index">
                                <div *ngIf="item.fieldName == 'Custom title'" class="withcmts">
                                    <mat-checkbox  (change)="itemOnChangeInstitute(i)" [checked]="item.checked"
                                        class="example-margin matbox">Custom title
                                    </mat-checkbox>
                                    <a (click)="addComment(titleComment)" class="template-group-title text-underline addcomment">Add Comments</a>
                                </div>
                                <mat-checkbox *ngIf="item.fieldName != 'Custom title'" (change)="itemOnChangeInstitute(i)" [checked]="item.checked" class="example-margin matbox">
                                    {{ item.fieldName }}
                                </mat-checkbox>
                            </div>
                        </div>
                        <div *ngIf="studentGroup != null" class="sub-head">Student</div>
                        <div class="list">
                            <mat-checkbox  *ngFor="let item of studentGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeStudent(i)">{{ item.fieldName }}</mat-checkbox>
                            <div class="searchSelect form-group">
                                <mat-label class="mutilselectlable">Custom Fields</mat-label>
                                <p-multiSelect *ngIf="studentGroup | customRepotCard: 'Custom fields'"
                                [options]="toppingList" defaultLabel="Custom Fields"  [formControl]="toppings" optionLabel="fieldName" display="chip" (onClick)="studentCusChk = elm.value; changeStudentField($event)" #elm>
                                </p-multiSelect>
                            </div>
                        </div>
                        <div *ngIf="attendanceGroup != null" class="sub-head">Attendance</div>
                        <div class="list">
                            <mat-checkbox *ngFor="let item of attendanceGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAttendance(i)">{{ item.fieldName }}</mat-checkbox>

                            <mat-checkbox *ngFor="let item of attendanceCustomGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAttendanceCustom(i)">{{ item.fieldName }}</mat-checkbox>
                        </div>
                        <div *ngIf="assessmentsGroup != null" class="sub-head">Assessment</div>
                        <div class="list">
                            <div *ngFor="let item of assessmentsGroup; let i = index">
                                <mat-checkbox class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAssessments(i)">{{ item.fieldName }}</mat-checkbox>

                                <mat-select class="ml-5" [(value)]="commentPosition"
                                *ngIf="(item.fieldName == 'Comments') && (assessmentsGroup | customRepotCard: 'Comments') " placeholder="Comments">
                                    <mat-option [value]="1">Display in bottom</mat-option>
                                    <mat-option [value]="2">Display in Score Table</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div *ngIf="othersGroup != null" class="sub-head">Others</div>
                        <div class="list">
                            <div *ngFor="let item of othersGroup; let i = index" >
                                <mat-checkbox *ngIf="item.fieldName == 'Grading structure for final score'" [checked]="item.checked" (change)="itemOnChangeOthers(i)" class="example-margin matbox">{{ item.fieldName }} </mat-checkbox>
                                <div *ngIf="item.fieldName == 'Contents block'" class="withcmts">
                                    <mat-checkbox [checked]="item.checked" (change)="itemOnChangeOthers(i)" class="example-margin matbox">{{ item.fieldName }}
                                    </mat-checkbox>
                                    <a (click)="addComment(contentComment)" class="template-group-title text-underline addcomment">Add Comments</a>
                                </div>

                                <div *ngIf="item.fieldName == 'Signature block'" class="withcmts">
                                    <mat-checkbox [checked]="item.checked" (change)="itemOnChangeOthers(i)" class="example-margin matbox">{{ item.fieldName }}
                                    </mat-checkbox>
                                    <a (click)="addComment(signatureComment)" class="template-group-title text-underline addcomment">Add Comments</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-md-8 col-xl-9 s10">
                    <div class="reportCardFrame">
                        <div *ngIf="instituteGroup | customRepotCard: 'Custom title'" class="reportHeader">
                            <div class="text-center">
                                <p class="logoleft institutename"><strong>{{ editorModel.customCommentTitle }}</strong></p>
                            </div>
                            <div class="logoleft customtext" [innerHTML]="editorModel.customCommentValue">
                                
                            </div>
                            <div class="headerRightAddress">

                            </div>
                        </div>
                        <div class="reportHeader d-flex justify-content-between align-items-center row mx-10">
                            <div class="col-lg-6 col-sm-12 d-flex align-items-center">
                                <img *ngIf="instituteGroup | customRepotCard: 'Logo'" class="mr-2 logos" [src]="croppedImage" alt="logo">
                                <h1 *ngIf="instituteGroup | customRepotCard: 'Institute name'" class="institutename">Institute</h1>
                            </div>
                            <div class="headerRightAddress col-lg-6 col-sm-12">
                                <p *ngIf="instituteGroup | customRepotCard: 'Institute address'"><strong>Address:</strong> Abc, street, city</p>
                                <p>
                                    <strong *ngIf="instituteGroup | customRepotCard: 'Contact'" >Contact: 401237856</strong> 
                                    <strong *ngIf="instituteGroup | customRepotCard: 'Email'" class="ml-3">Email: email4562@email.com</strong> 
                                </p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between form-group student-card-report">
                            <div class="studentInfo d-flex align-items-center">
                                <div *ngIf="studentGroup | customRepotCard: 'Photograph'" class="studentThumb"><img src="../../../../../../assets/img/admin/student1.jpg" alt=""></div>
                                <div class="flex-grow-1 studentNameCl">
                                    <h4 *ngIf="studentGroup | customRepotCard: 'Name'">Student Name</h4>
                                    <p>
                                        <strong *ngIf="studentGroup | customRepotCard: 'Class'">V-</strong>
                                        <strong *ngIf="studentGroup | customRepotCard: 'Section'">A</strong>
                                    </p>
                                    <p *ngIf="studentGroup | customRepotCard: 'Admission_no'">Admission No. 001</p>
                                    <p *ngIf="studentGroup | customRepotCard: 'Academic session name'">Session:2026-2027</p>
                                    <div *ngFor="let item of studentCustomGroup">
                                        <p *ngIf="item | customRepotCard: 'studentCustomFields' ">
                                            {{item.fieldName}}
                                        </p>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            <div class="studentInfoAttendence">
                                <table *ngIf="attendanceGroup | customRepotCard: 'Display attendance'" class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Attendance</th>
                                            <th class="center">0.00%</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let item of attendanceCustomGroup">
                                            <tr *ngIf="attendanceCustomGroup | customRepotCard: item.fieldName">
                                                <td [ngStyle]="{ color: item.colorCode }">{{ item.fieldName }}</td>
                                                <td [ngStyle]="{ color: item.colorCode }" class="center">
                                                    <span *ngIf="attendanceGroup | customRepotCard: 'Display in numbers'">0<span *ngIf="!(attendanceGroup | customRepotCard: 'Disable Max Status Count')">/0</span></span> <span *ngIf="attendanceGroup | customRepotCard: 'Display in percentage'"> (0.00%)</span>
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="semesterVise">
                            <div *ngIf="!(assessmentsGroup | customRepotCard: 'Hide subjects scores table completely')" class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Subjects</th>
                                            <th *ngIf="assessmentsGroup | customRepotCard: 'Display subjects code'" scope="col">Subject Code</th>
                                            <th *ngIf="assessmentsGroup | customRepotCard: 'Display subjects credits'" scope="col">Credits / Hours</th>
                                            <th *ngIf="assessmentsGroup | customRepotCard: 'Display subjects enrollment status'" scope="col">Enrollment status</th>
                                            <th *ngIf="assessmentsGroup | customRepotCard: 'Display subjects teachers'" scope="col">Subjects teachers</th>
                                            <th *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 2)" scope="col">Subjects comments</th>
                                            <th *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')" scope="col">Category 1</th>
                                            <th *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')" scope="col">Category 2</th>
                                            <th *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')" scope="col">Category 3</th>
                                            <th *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')" scope="col">Category 4</th>
                                            <th *ngIf="assessmentsGroup | customRepotCard: 'Display total obtained points for assessment eg: 95/100'" scope="col">Total Points</th>
                                            <th *ngIf="assessmentsGroup | customRepotCard: 'Final scores'" scope="col" class="finalScore">Final Score</th>
                                            <th *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'" scope="col">Grade Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Subject 1</td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects code'">
                                                <div class="text-center">XXX</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects credits'">
                                                <div class="text-center">10</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects enrollment status'">
                                                <div class="text-center enrolmentstatus">XXX</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects teachers'">
                                                <div class="text-center">XXX</div>
                                            </td>
                                            <td *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 2)"></td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"> </td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"> </td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"></td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"></td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display total obtained points for assessment eg: 95/100'">0/0</td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Final scores'" class="finalScore"></td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'">-</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Subject 1</td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects code'">
                                                <div class="text-center">XXX</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects credits'">
                                                <div class="text-center">10</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects enrollment status'">
                                                <div class="text-center enrolmentstatus">XXX</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects teachers'">
                                                <div class="text-center">XXX</div>
                                            </td>
                                            <td *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 2)"></td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"> </td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"> </td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"></td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"></td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display total obtained points for assessment eg: 95/100'">0/0</td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Final scores'" class="finalScore"></td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'">-</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Subject 1</td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects code'">
                                                <div class="text-center">XXX</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects credits'">
                                                <div class="text-center">10</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects enrollment status'">
                                                <div class="text-center enrolmentstatus">XXX</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects teachers'">
                                                <div class="text-center">XXX</div>
                                            </td>
                                            <td *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 2)"></td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"> </td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"> </td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"></td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"></td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display total obtained points for assessment eg: 95/100'">0/0</td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Final scores'" class="finalScore"></td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'">-</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Subject 1</td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects code'">
                                                <div class="text-center">XXX</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects credits'">
                                                <div class="text-center">10</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects enrollment status'">
                                                <div class="text-center enrolmentstatus">XXX</div>
                                            </td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display subjects teachers'">
                                                <div class="text-center">XXX</div>
                                            </td>
                                            <td *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 2)"></td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"> </td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"> </td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"></td>
                                            <td *ngIf="!(assessmentsGroup | customRepotCard: 'Hide categories')"></td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Display total obtained points for assessment eg: 95/100'">0/0</td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Final scores'" class="finalScore"></td>
                                            <td *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'">-</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="assessmentsGroup | customRepotCard: 'Enable GPA'" class="text-right flex-end">
                            <div class="smalltable">
                                <p class="mb-1 mt-3">Earned Grade Points: 0.00</p>
                                <p class="mb-1 mt-2">Total Credits / Hours: 0.00</p>
                                <p class="mb-1 mt-2">GPA: 0.00</p>
                            </div>

                        </div>

                        <div *ngIf="assessmentsGroup | customRepotCard: 'Rubric scores'" class="block-border extra-block-sm" data-keyword="rubric_scores">
                            <h2 class="mb-2 text-center template-group-title ">Rubric Scores</h2>
                            <div class="row text-center">
                                <div class="col-md-2 mb10"></div>
                                <div class="col-md-4 mb10">
                                    <h6 class="strong">Subject 1 - Assessment 1</h6>
                                    <div *ngIf="(assessmentsGroup | customRepotCard: 'Display Primary teachers name in rubric scores table') || (assessmentsGroup | customRepotCard: 'Display Secondary teachers name in rubric scores table')" class="" data-keyword="rubric_scores_teacher"
                                        style="font-size: 12px;margin-bottom: 5px;">Teachers Name: XXX</div>
                                    <table class="table table-bordered mt-2 table-striped mb10">
                                        <thead>
                                            <tr>
                                                <th class="center" style="width:50% !important;">Criteria</th>
                                                <th class="center" style="width:50% !important;">Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="center">Criteria 1</td>
                                                <td class="center">Excellent <span *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class=""
                                                        data-keyword="hide_rubric_points">[4]</span></td>
                                            </tr>
                                            <tr>
                                                <td class="center">Criteria 2</td>
                                                <td class="center">Satisfactory <span *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class=""
                                                        data-keyword="hide_rubric_points">[2]</span></td>
                                            </tr>
                                            <tr>
                                                <td class="center">Criteria 3</td>
                                                <td class="center">Good <span *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class=""
                                                        data-keyword="hide_rubric_points">[3]</span></td>
                                            </tr>
                                            <tr *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class="" data-keyword="hide_rubric_points">
                                                <td class="center">Total</td>
                                                <td class="center">9/12</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-4 mb10">
                                    <h6 class="strong">Subject 2 - Assessment 1</h6>
                                    <div *ngIf="(assessmentsGroup | customRepotCard: 'Display Primary teachers name in rubric scores table') || (assessmentsGroup | customRepotCard: 'Display Secondary teachers name in rubric scores table')" class="" data-keyword="rubric_scores_teacher"
                                        style="font-size: 12px;margin-bottom: 5px;">Teachers Name: XXX</div>
                                    <table class="table table-bordered mt-2 table-striped mb10">
                                        <thead>
                                            <tr>
                                                <th class="center" style="width:50% !important;">Criteria</th>
                                                <th class="center" style="width:50% !important;">Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="center">Criteria 1</td>
                                                <td class="center">Excellent <span *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class=""
                                                        data-keyword="hide_rubric_points">[4]</span></td>
                                            </tr>
                                            <tr>
                                                <td class="center">Criteria 2</td>
                                                <td class="center">Satisfactory <span *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class=""
                                                        data-keyword="hide_rubric_points">[2]</span></td>
                                            </tr>
                                            <tr>
                                                <td class="center">Criteria 3</td>
                                                <td class="center">Good <span *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class=""
                                                        data-keyword="hide_rubric_points">[3]</span></td>
                                            </tr>
                                            <tr *ngIf="assessmentsGroup | customRepotCard: 'Hide points in rubric'" class="" data-keyword="hide_rubric_points">
                                                <td class="center">Total</td>
                                                <td class="center">9/12</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-2 mb10"></div>
                            </div>
                            <!-- <div class="clearfix"></div>
                            <div class="clearfix"></div> -->
                        </div>

                        <div *ngIf="othersGroup | customRepotCard: 'Grading structure for final score'" class="semesterVise fulltable">
                            <h5 class="template-group-title">Grading structure for final score</h5>
                        </div>
                        <div *ngIf="(assessmentsGroup | customRepotCard: 'Comments') && (commentPosition == 1)" class="semesterVise fulltable">
                            <h5 class="template-group-title">Comments</h5>
                        </div>
                        
                        <div *ngIf="othersGroup | customRepotCard: 'Contents block'" class="semesterVise fulltable">
                            <h5 class="template-group-title">{{ editorModel.contentCommentTitle }}</h5>
                            <div [innerHTML]="editorModel.contentCommentValue">
                            </div>
                        </div>
                        <div *ngIf="othersGroup | customRepotCard: 'Signature block'" class="semesterVise fulltable">
                            <h5 class="template-group-title">{{ editorModel.signatureCommentTitle }}</h5>
                            <div [innerHTML]="editorModel.signatureCommentValue">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btnsWidget form-group17 text-right">
        <button type="button" (click)="onSubmit()" class="btn btn-primary btn-sm m-0 ">Save</button>
        <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Cancel</button>
    </div>

    <ng-template #titleComment>
        <div class="dialog-lg">
            <i class="material-icons popupClose" mat-dialog-close>clear</i>
            <div class="dialogTitle">
              <h2 mat-dialog-title><i style="color: #808080;">Custom title</i> - <b>Add Contents</b></h2>
            </div>
            <form>
                <div class="row">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Block Title</mat-label>
                        <input matInput name="blockTitle" [value]="editorModel.customCommentTitle" placeholder="Block Title" />
                    </mat-form-field>
                </div>
                <div class="row">
                    <ejs-richtexteditor #titleCommentEditor required [toolbarSettings]='tools' [value]="editorModel.customCommentValue" >
                    </ejs-richtexteditor>
                </div>
        
              <div class="btnsWidget form-group17 text-right">
                <button type="button" (click)="addCustomTitleComment()" class="btn btn-primary btn-sm" mat-dialog-close matRipple>Save</button>
                <button type="button" class="btn btn-reset btn-sm" mat-dialog-close matRipple>Close</button>
              </div>  
            </form>
          </div>
    </ng-template>

    <ng-template #contentComment>
        <div class="dialog-lg">
            <i class="material-icons popupClose" mat-dialog-close>clear</i>
            <div class="dialogTitle">
              <h2 mat-dialog-title><i style="color: #808080;">Contents block</i> - <b>Add Contents</b></h2>
            </div>
            <form>
                <div class="row">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Block Title</mat-label>
                        <input matInput name="blockContent" [value]="editorModel.contentCommentTitle" placeholder="Block Title" />
                    </mat-form-field>
                </div>
                <div class="row">
                    <ejs-richtexteditor #contentCommentEditor required [toolbarSettings]='tools' [value]="editorModel.contentCommentValue">
                    </ejs-richtexteditor>
                </div>
        
              <div class="btnsWidget form-group17 text-right">
                <button type="button" (click)="addContentComment()" class="btn btn-primary btn-sm" mat-dialog-close matRipple>Save</button>
                <button type="button" class="btn btn-reset btn-sm" mat-dialog-close matRipple>Close</button>
              </div>  
            </form>
          </div>
    </ng-template>

    <ng-template #signatureComment>
        <div class="dialog-lg">
            <i class="material-icons popupClose" mat-dialog-close>clear</i>
            <div class="dialogTitle">
              <h2 mat-dialog-title><i style="color: #808080;">Signature block</i> - <b>Add Contents</b></h2>
            </div>
            <form>
                <div class="row">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Block Title</mat-label>
                        <input matInput name="blockSignature" [value]="editorModel.signatureCommentTitle" placeholder="Block Title" />
                    </mat-form-field>
                </div>
                <div class="row">
                    <ejs-richtexteditor #signatureCommentEditor required [toolbarSettings]='tools' [value]="editorModel.signatureCommentValue" >
                    </ejs-richtexteditor>
                </div>
        
              <div class="btnsWidget form-group17 text-right">
                <button type="button" (click)="addSignatureComment()" class="btn btn-primary btn-sm" mat-dialog-close matRipple>Save</button>
                <button type="button" class="btn btn-reset btn-sm" mat-dialog-close matRipple>Close</button>
              </div>  
            </form>
          </div>
    </ng-template>
</form>
