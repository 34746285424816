import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';
import { HtmlEditorService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-edit-template-one',
  templateUrl: './edit-template-one.component.html',
  styleUrls: ['./edit-template-one.component.css']
  // providers: [ToolbarService, HtmlEditorService]
})
export class EditTemplateOneComponent implements OnInit {
  @Input() reportCardItem: any; 
  
  @ViewChild('titleCommentEditor', {}) titleObj: RichTextEditorComponent;
  @ViewChild('contentCommentEditor', {}) contentObj: RichTextEditorComponent;
  @ViewChild('signatureCommentEditor', {}) signatureObj: RichTextEditorComponent;
  @ViewChild("elm") elm: ElementRef;

  reportCardMasterFields: any;
  templateID: any;
  setGPA: any;
  logo: any;
  instituteName: any;
  instituteAddress: any;
  studentCusChk: any[];
  toppings = new FormControl('');
  email: any;
  commentPosition = 1;
  ShowFilter = true;
  toppingList: any;
  instituteGroup: any;
  studentGroup: any;
  attendanceGroup: any;
  assessmentsGroup: any;
  othersGroup: any;
  studentCustomGroup: any;
  attendanceCustomGroup: any;
  fields: any = [];
  classSection: any = [];
  customCommntArr: any = [];
  orgID: any;
  users: any;
  user: any;
  academicSessionID: any;
  croppedImage: any;
 ReportCardFormatID:any

  public editorModel: any = {
    customCommentTitle: "Custom title",
    customCommentValue: "<p>You can set any custom title here ...</p>",
    contentCommentTitle: "Contents Block",
    contentCommentValue: "<p>You can set any custom contents here ...</p>",
    signatureCommentTitle: "Signature Block",
    signatureCommentValue: "<p>You can set signature text here as you want ...</p>"
  };

  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'CreateTable', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'ClearFormat', 'Print', 'SourceCode', 'InsertCode', '|',
      'Undo', 'Redo', '|',
      'FullScreen', 'Preview'
      ]
  };
  
  constructor(
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private masterService: MasterService
  ) {
      if(localStorage.getItem('Logo') != "" && localStorage.getItem('Logo') != null) {
        this.croppedImage = localStorage.getItem('Logo');
      }else {
        this.croppedImage = '../../../../../../assets/img/admin/org_logo.png';
      }

      this.users = localStorage.getItem('currentUser');
      var currentUser = JSON.parse(this.users);
      this.academicSessionID = currentUser.academicSessionID
      if (currentUser != null) {
        this.user = currentUser;
      }
  }

  ngOnInit(): void {
    this.templateID = this.reportCardItem.value.Template;
    this.ReportCardFormatID=Number(this.reportCardItem.value.ReportCardFormatID)
    //this.getFormat(this.templateID);
    // this.getStudentCustomField();
   // this.getAttendanceCustomField();
    this.getTemplateValues();
  }


  onSubmit(){
    if(this.reportCardItem.invalid) {
      if(this.reportCardItem.controls.selectedClassSectionID.errors?.required) {
        this.toaster.error('Please Select Class-Section');
      }
      if(this.reportCardItem.controls.Name.errors?.required) {
        this.toaster.error('Name cannot be empty');
      }
      if(this.reportCardItem.controls.Name.errors?.pattern){
        this.toaster.error('Enter a valid Name');
      }
    } else {
      this.comments[0].extraFieldValue = this.commentPosition.toString();
      for(let i=0;i<this.reportCardMasterFields.length;i++){
        if(this.reportCardMasterFields[i].fieldName=='Custom title'){this.reportCardMasterFields[i].change=true}
        if(this.reportCardMasterFields[i].fieldName=='Comments'){this.reportCardMasterFields[i].change=true}
        if(this.reportCardMasterFields[i].fieldName=='Contents block'){this.reportCardMasterFields[i].change=true}
        if(this.reportCardMasterFields[i].fieldName=='Signature block'){this.reportCardMasterFields[i].change=true}
      }
      let data = {
        ReportCardFormatName: this.reportCardItem.value.Name,
        academicSessionID: this.academicSessionID,
        ReportCardsFormatID:this.ReportCardFormatID,
        ReportCardTemplateID: this.templateID,
        ClassSection: this.reportCardItem.value.selectedClassSectionID,
        ReportCardMasterField:this.reportCardMasterFields
        //Fields: this.reportCardMasterFields,
        //CustomFields: this.studentCustomGroup,
        //CommentFields: this.editorModel,
        //ExtraFieldValue: this.commentPosition.toString(),
        //AttendanceGroup: this.attendanceCustomGroup
      }
      console.log("this final data to be sent",this.reportCardMasterFields)
      this.manageAssessmentService.UpdateReportCardFieldDetails(data).subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
          // this.router.navigate(["admin/"]);
        } else {
          this.toaster.error(res.message, 'Error');
        }
      })
    }
    
  }

  reset() {
    this.reportCardItem.reset();
  }

  addComment(templateRef: TemplateRef<any>){
    this.dialog.open(templateRef);
  }

  // getFormat(Id: number) {
  //   this.manageAssessmentService.GetAllReportCardFields(Id).subscribe((res: any) => {
  //     this.reportCardMasterFields = res.responseData;
  //     this.instituteGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 1);
  //     this.studentGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 2);
  //     this.attendanceGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 3);
  //     this.assessmentsGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 4);
  //     this.othersGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 5);
  //   });
  // }
  customTitle: any=[];
  contentblock: any=[];
  signatureBlock: any=[];
  comments: any= [];
  getTemplateValues(){
    this.manageAssessmentService.GetAssignedTemplateDetails(this.ReportCardFormatID).subscribe((res: any) => {
      this.reportCardMasterFields = res.responseData;
      console.warn('>>>>>>>>>>>>>>', res.responseData);
      this.instituteGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 1);
      this.studentGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 2);
      this.attendanceGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 3);
      this.attendanceCustomGroup = this.reportCardMasterFields.filter((x: any) => x.extraFieldValue == 'Attendance fields' && x.aLabel != '');
      this.assessmentsGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 4);
      this.othersGroup = this.reportCardMasterFields.filter((x: any) => x.reportCardGroupTypeID == 5);
      this.studentCustomGroup = this.reportCardMasterFields.filter((x: any) => x.extraFieldValue == "Custom fields")
      this.toppingList = this.studentCustomGroup;
      this.studentCusChk =this.reportCardMasterFields.filter((x: any) => x.extraFieldValue == "Custom fields" && x.checked == true);
      this.toppings.setValue(this.studentCusChk);
       
      this.customTitle = this.instituteGroup.filter((x: any) => x.fieldName == "Custom title");
      this.contentblock = this.othersGroup.filter((x: any) => x.fieldName == "Contents block");
      this.signatureBlock = this.othersGroup.filter((x: any) => x.fieldName == "Signature block");
      this.comments = this.assessmentsGroup.filter((x: any) => x.fieldName == "Comments");
      
      if(this.comments.length)
        this.commentPosition = Number(this.comments[0].extraFieldValue);
        
      if(this.customTitle.length) {
        this.editorModel.customCommentTitle = this.customTitle[0].commentTitle;
        this.editorModel.customCommentValue = this.customTitle[0].commentValue;
      }
      if(this.contentblock.length) {
        this.editorModel.contentCommentTitle = this.contentblock[0].commentTitle;
        this.editorModel.contentCommentValue = this.contentblock[0].commentValue;
      }
      if(this.signatureBlock.length) {
        this.editorModel.signatureCommentTitle = this.signatureBlock[0].commentTitle;
        this.editorModel.signatureCommentValue = this.signatureBlock[0].commentValue;
      }
    });


    
  }

  // getStudentCustomField() {
  //   this.manageAssessmentService.GetAllStudentField().subscribe((res: any) => {
  //     this.studentCustomGroup = res.responseData.filter((x: any) => x.fieldID != 1 && x.fieldID != 2 && x.fieldID != 3 && x.fieldID != 9 && x.fieldID != 10 && x.fieldID != 11);
  //     this.studentCustomGroup.forEach((element: any) => {
  //       element.checked = false;
  //     });
  //     this.toppingList = this.studentCustomGroup;
  //     console.warn('topping',this.toppingList);
  //   });
  // }

  getAttendanceCustomField() {
    this.manageAssessmentService.GetAllAttendanceField().subscribe((res: any) => {
      this.attendanceCustomGroup = res.responseData;
      this.attendanceCustomGroup.forEach((element: any) => {
        if(element.attendanceStatusMasterID == 1 || element.attendanceStatusMasterID == 2)
          element.checked = true;
        else
          element.checked = false;
      });
    });
  }

  changeStudentField(event: any) {
    console.warn(this.studentCusChk);
    this.studentCustomGroup.forEach((element: any) => {
      let idx = this.studentCusChk.indexOf(element);
      if(idx != -1) {
        element.checked = true;
        element.change=true;
      } else {
        element.checked = false;
        element.change=true;
      }
    });
  }

  itemOnChangeInstitute(idx: any) {
    this.instituteGroup[idx].checked = !this.instituteGroup[idx].checked;
    this.instituteGroup[idx].change = !this.instituteGroup[idx].change;
  }
  itemOnChangeStudent(idx: any) {
    this.studentGroup[idx].checked = !this.studentGroup[idx].checked;
    this.studentGroup[idx].change = !this.studentGroup[idx].change;
    if(this.studentGroup[idx].fieldName == 'Custom fields') {
      this.toppings.setValue('');
      this.studentCustomGroup.forEach((element: any) => {
        element.checked = false;
        this.studentCustomGroup[idx].change = !this.studentCustomGroup[idx].change;
      });
    }
  }
  itemOnChangeAttendance(idx: any) {
    this.attendanceGroup[idx].checked = !this.attendanceGroup[idx].checked;
    this.attendanceGroup[idx].change = !this.attendanceGroup[idx].change;
  }
  itemOnChangeAttendanceCustom(idx: any) {
    this.attendanceCustomGroup[idx].checked = !this.attendanceCustomGroup[idx].checked;
    this.attendanceCustomGroup[idx].change = !this.attendanceCustomGroup[idx].change;
  }
  
  itemOnChangeAssessments(idx: any) {
    this.assessmentsGroup[idx].checked = !this.assessmentsGroup[idx].checked;
    this.assessmentsGroup[idx].change = !this.assessmentsGroup[idx].change;
  }
  itemOnChangeOthers(idx: any) {
    this.othersGroup[idx].checked = !this.othersGroup[idx].checked;
    this.othersGroup[idx].change = !this.othersGroup[idx].change;
  }


  addCustomTitleComment() {
    let question = this.titleObj.value;
    this.editorModel.customCommentValue = question;
    let name = document.querySelector<HTMLInputElement>('input[name="blockTitle"]')?.value;
    this.editorModel.customCommentTitle = name;
    this.customTitle[0].commentTitle = this.editorModel.customCommentTitle;
    this.customTitle[0].commentValue = this.editorModel.customCommentValue;
    
  }

  addContentComment() {
    let question = this.contentObj.value;
    this.editorModel.contentCommentValue = question;
    let name = document.querySelector<HTMLInputElement>('input[name="blockContent"]')?.value;
    this.editorModel.contentCommentTitle = name;
    this.contentblock[0].commentTitle = this.editorModel.contentCommentTitle;
    this.contentblock[0].commentValue = this.editorModel.contentCommentValue;
  }

  addSignatureComment() {
    let question = this.signatureObj.value;
    this.editorModel.signatureCommentValue = question;
    let name = document.querySelector<HTMLInputElement>('input[name="blockSignature"]')?.value;
    this.editorModel.signatureCommentTitle = name;
    this.signatureBlock[0].commentTitle = this.editorModel.signatureCommentTitle;
    this.signatureBlock[0].commentValue = this.editorModel.signatureCommentValue;
  }
}
