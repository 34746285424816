import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile-tabs',
  templateUrl: './profile-tabs.component.html',
  styleUrls: ['./profile-tabs.component.css']
})
export class ProfileTabsComponent implements OnInit {

  constructor(
    private activatedRoute:ActivatedRoute,
    private router: Router,
  ) { }
  studentID:any;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id==undefined?null:params.id;
    });
  }
}
