<div class="cardTitle centerVTItems justify-content-between">
    <h3 class="text-colored"><i class="fas fa-book-open mr-2"></i>Report Cards Format</h3>
    <button
    *ngIf="checkActionPermission('Add Report Card Format')" class="btn btn-primary btn-sm" routerLink="../reportcardformats" matRipple><i class="fas fa-plus"></i> Add New</button>
    <!-- (click)="actionDialogPopup(addformat)" -->
</div>
<div class="gradeSettings">
   <div class="mat-elevation-z8"  *ngIf="checkActionPermission('Report Card Format List')">
    <table mat-table [dataSource]="formatList" matSort>
      
        <!-- ID Column -->
        <ng-container matColumnDef="reportCardsFormatID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.reportCardsFormatID}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="reportCardsFormatName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
          <td mat-cell *matCellDef="let element"> {{element.reportCardsFormatName}} </td>
        </ng-container>
       
          <!-- Duration Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <!-- <div class="actionsCol firstRowIcon">
                    <mat-icon (click)="Editformat(addformat, element.reportCardsFormatID)">info</mat-icon>
                </div> -->
                <!-- <div class="actionsCol otherIcons"> -->
                    <!-- <i class="fas fa-arrows-alt"></i> -->
                    <div class="actionsCol">
                      <mat-icon *ngIf="checkActionPermission('Update Report Card Format')" (click)="EditReportCard(element.reportCardsFormatID)">edit</mat-icon>
                      <mat-icon *ngIf="checkActionPermission('Delete Report Card Format')" (click)=deleteFormat(element.reportCardsFormatID)>delete</mat-icon>
                    </div>
                    
                <!-- </div> -->
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>  
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> --> 
</div>

<ng-template #addformat>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle">
      <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Report Card Format</h2>
      <ng-template #elseTemplate>
        <h2 mat-dialog-title>Add Report Card Format</h2>
      </ng-template>
    </div>
    <form [formGroup]="addaddformatForm">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Report Card Format Name" formControlName="reportCardsFormatName" required />
        <mat-error *ngIf="addaddformatForm.get('reportCardsFormatName')?.touched && addaddformatForm.get('reportCardsFormatName')?.errors?.required">
          Report Card Format Name is required
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Class</mat-label>
        <mat-select placeholder="Select Class" (selectionChange)="selectSectionByClassID($event)" formControlName="classID"
         >
          <mat-option value="Select Class">SelectClass</mat-option>
          <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
            {{classSelect.className}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addaddformatForm.get('classID')?.touched && addaddformatForm.get('classID')?.errors?.required">
          Please Select a class
        </mat-error>
      </mat-form-field>


      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Section</mat-label>
        <mat-select placeholder="Select Class" formControlName="sectionID">
          <mat-option value="Select Section">Select Section</mat-option>
          <mat-option *ngFor="let sectionSelect of sectionListDropdown" [value]="sectionSelect.sectionID">
            {{sectionSelect.sectionName}} 
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="addaddformatForm.get('sectionID')?.touched && addaddformatForm.get('sectionID')?.errors?.required">
          Please Select a Section
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Template</mat-label>
        <mat-select placeholder="Select Template" formControlName="templateID">
          <mat-option value="Select Template">Select Template</mat-option>
          <mat-option [value]="1">Default</mat-option>
        </mat-select>
        <mat-error
        *ngIf="addaddformatForm.get('templateID')?.touched && addaddformatForm.get('templateID')?.errors?.required">
        Please Select a Template
      </mat-error>
      </mat-form-field>

      <div class="btnsWidget form-group17 text-right">
        <button type="button" (click)="addaddformat()" class="btn btn-primary btn-sm" mat-dialog-close matRipple>Save</button>
        <button typetype="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close matRipple>Cancel</button>
      </div>  
    </form>
  </div>
</ng-template>

<!-- <ng-template #addformat>
  <div class="dialog-md">
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Add Report Cards Format</h2>
    </div>
    <hr>
    <div class="popover-content p-0">
      <app-template-one></app-template-one>
      <hr>
    </div>
  </div>
</ng-template> -->
