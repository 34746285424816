import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ManageAssessmentAndGradingsService } from '../../../app-layout/content/academics-and-learning/assesment-and-gradings/services/manage-assessment-and-gradings.service'
import * as XLSX from 'xlsx'; 
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.css']
})
export class ImportDialogComponent implements OnInit {

  AcademicSessionId:Number;
  ClassId : Number;
  SectionId : Number;
  SubjectId : Number;
  importForm: FormGroup;
  columnListForExport:any;

  constructor(private gradeBookService:ManageAssessmentAndGradingsService,
    public dialogRef: MatDialogRef<ImportDialogComponent>,private toaster: ToastrService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data:any,
    ) { 
        this.AcademicSessionId = data.AcademicSessionId;
        this.ClassId = data.ClassId;
        this.SectionId = data.SectionId;
        this.SubjectId = data.SubjectId;

    }

  ngOnInit(): void {
    this.getImportColumns();
  }

  getImportColumns(){
    const formData = new FormData();
    formData.append("AcademicSessionId",String(this.AcademicSessionId));
    formData.append("ClassId",String(this.ClassId));
    formData.append("SubjectId",String(this.SubjectId));
    formData.append("SectionId",String(this.SectionId));
      this.gradeBookService.getColumnsForImport(formData).subscribe((res:any)=>{
        if(res?.responseData!=null){
          this.columnListForExport = res?.responseData;
        let group:any = {};
        res?.responseData.forEach((item:any)=>{
           group[item.fieldName] = new FormControl(false);
        })
        this.importForm = new FormGroup(group);
      }
      })
  }

  downloadImportFile(event: any){
    event.preventDefault();
    let selectedColumns:string='';
    
    this.columnListForExport.forEach((item:any)=>{
      if(this.importForm.controls[item.fieldName].value){
        selectedColumns +='['+item.fieldName+']'+',';
      }
    });
    if(selectedColumns==='')
    {
    this.toaster.error(`Please select atleast one column to import file template`);
    }
    else {

      let a = selectedColumns.split(',').find((val:string)=>{
              return val==='[Admission Number]';
      });

      if(a?.length===0|| a===undefined){
        this.toaster.error(`Please select Admission Number to import file template`);
        return;
      }
              

      let fileName='ImportFieTemplate';
      
    const formData = new FormData();
    formData.append("AcademicSessionId",String(this.AcademicSessionId));
    formData.append("ClassId",String(this.ClassId));
    formData.append("SubjectId",String(this.SubjectId));
    formData.append("SectionId",String(this.SectionId));
    formData.append("SelectedColumns",String(selectedColumns));

    let result$;
    result$ = this.gradeBookService.ImportFile(formData);
    
    result$.subscribe((data:any)=>{
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let workBook: any = null;
          let parsedExcelRowArray = null;
          let rawExcel: File;
         const reader = new FileReader();
         reader.onload = (event) => {
         const data = reader.result;
         workBook = XLSX.read(data, { type: 'binary' });
         rawExcel = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

        const blob = new File([new Blob([rawExcel], {type: fileType})], `${fileName}.xlsx`);
          const url= window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          link.download = `${fileName}.xlsx`;
          link.click();
       };

        reader.readAsBinaryString(data);
  });
    }
}

  selectUnSelectAllImport(val:boolean){
    this.columnListForExport.forEach((item:any)=>{
      this.importForm.controls[item.fieldName].setValue(val);
    })
  }

}
