import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';

@Component({
  selector: 'app-reportgrade-display',
  templateUrl: './reportgrade-display.component.html',
  styleUrls: ['./reportgrade-display.component.css']
})
export class ReportgradeDisplayComponent implements OnInit {
  ReportCardsGradeBooksDisplay:FormGroup;
  constructor(
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private fb: FormBuilder,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {
    this.GetReportCardsGradeBooksDisplay();
    this.ReportCardsGradeBooksDisplay = this.fb.group({
      reportCardsAndGradeBookDisplayID: [0],
      displayReportCardsToStudentsParent: ['', Validators.required],
      displayGradeBookToStudentsParent: ['', Validators.required],
    });
  }
  GetReportCardsGradeBooksDisplay(){
    this.manageAssessmentService.GetReportCardsAndGradeBookDisplay().subscribe((res:any)=>{
      this.ReportCardsGradeBooksDisplay.patchValue({
        reportCardsAndGradeBookDisplayID: res.responseData['reportCardsAndGradeBookDisplayID'],
        displayReportCardsToStudentsParent: res.responseData['displayReportCardsToStudentsParent'],
        displayGradeBookToStudentsParent: res.responseData['displayGradeBookToStudentsParent'],
      });
    })
  }
  submitReportCardsGradeBooksDisplay(){
    if (this.ReportCardsGradeBooksDisplay.invalid) {
      return;
    }
    this.manageAssessmentService.UpdateReportCardsAndGradeBookDisplay(this.ReportCardsGradeBooksDisplay.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
      }
      else {
        this.toaster.error(res.message)
      }
    });
  
  }
}
