<i class="material-icons popupClose" mat-dialog-close>clear</i>
<h1 mat-dialog-title>Import Status Report</h1>

<div mat-dialog-content>
    <div style="font-size: 13px; margin-top: 5px; line-height: 15px; display: block;" class="successMessage alert alert-info" role="alert">
       {{message}}
    </div>
    <div id="responseMessage2" *ngIf="showStats"
  style="font-size: 13px; margin-top: 5px; line-height: 15px; display: block;"
  class="successMessage alert alert-info">{{totalCount}} total rows fetched from the excel file.<br>{{failedCount}} records failed to process<br></div>

    <div class="row alignbase">
        <div class="col-md-3 my-2">
            <h3><b>Download Report : </b></h3>
        </div>
        <div class="col-md-9 my-2" *ngIf="showStats && link != 0">
            <strong><a [routerLink]="" (click)="getStatusReport()">Get Imported Status Report<i title="Status Report" class="ml-2 fas fa-download"></i></a></strong>
        </div>
    </div>
</div>
<div class="text-right">
<div>
  <button class="btn btn-reset btn-sm" matRipple mat-button mat-dialog-close (click)="closeDialog()">Close</button>
</div>
</div>
