<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Manage Student Attendance</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
          <li class="breadcrumb-item active" aria-current="page">Manage Student Attendance</li>
        </ol>
      </div>
    </div>

  </div>
  <div class="cardWidget">
    <div class="assesmentTabs">
      <a routerLink="../manage-student-attendance" routerLinkActive="active"><i class="fas fa-tasks"></i> Manage Student
        Attendance</a>
      <a routerLink="../student-submission-analytics"  routerLinkActive="active"><i class="fas fa-chart-line"></i>
        Attendance Submission Analytics</a>
      <a routerLink="../attendance-settings"  [queryParams]="{ id: '1' }" routerLinkActive="active"><i class="fas fa-cog"></i>Settings</a>
    </div>
    <div class="cardBody">
      <form [formGroup]="stuAttendanceForm" (ngSubmit)="analyticDta = null;getStudentsAttendanceByDate();">
        <div>
          <div class="row">
            <div class="col-lg-10 col-sm-12">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Class</mat-label>
                        <!-- <mat-select  placeholder="Select Class"
                        matTooltip="{{selectedClass}}"
        matTooltipClass="my-custom-tooltip"
         (selectionChange)="selectSectionByClassID($event)"
                          formControlName="ClassID"> -->
                          <!-- <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'class')"> -->

                          <input type="text" (input)="onKeyFilter($event, 'class')" matInput
                          formControlName="ClassID" [matAutocomplete]="classauto">


                          <mat-autocomplete #classauto="matAutocomplete" 
                          (optionSelected)="selectSectionByClassID($event.option)" [displayWith]="dispalyFns.bind(this)" >

                          <mat-option *ngIf="!classListDropdown">No Classes</mat-option>
                          <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
                            {{classSelect.className}}
                          </mat-option>
                        <!-- </mat-select> -->
                        <!-- <mat-error *ngIf="stuAttendanceForm.controls['ClassID'].invalid">
                          Class is required
                        </mat-error> -->
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Section</mat-label>
                        <!-- <mat-select  placeholder="Select Section"
                        matTooltip="{{selectedSection}}"
        matTooltipClass="my-custom-tooltip"
         (selectionChange)="GetSubjectBySectionId($event)"
                          formControlName="SectionID"> -->
                          <!-- <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'section')"> -->


                          <input type="text" (input)="onKeyFilter($event, 'section')" matInput
                          formControlName="SectionID" [matAutocomplete]="sectionauto">


                          <mat-autocomplete #sectionauto="matAutocomplete" 
                          (optionSelected)="GetSubjectBySectionId($event.option)" [displayWith]="dispalyFnsSection.bind(this)" >

                          <mat-option *ngIf="sectionListDropdown.length==0">No Sections</mat-option>
                          <mat-option [value]="sec.sectionID" *ngFor="let sec of sectionListDropdown">
                            {{sec.sectionName}}
                          </mat-option>
                          </mat-autocomplete>
                        <!-- </mat-select> -->
                        <!-- <mat-error *ngIf="stuAttendanceForm.controls['SectionID'].invalid">
                          Section is required
                        </mat-error> -->
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Subject</mat-label>
                        <!-- <mat-select  placeholder="Select Subject" formControlName="SubjetID">
                          <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'subject')"> -->


                          <input type="text" (input)="onKeyFilter($event, 'subject')" matInput
                          formControlName="SubjetID" [matAutocomplete]="subjectauto">


                          <mat-autocomplete #subjectauto="matAutocomplete" 
                           [displayWith]="dispalyFnsSubject.bind(this)" >



                          <mat-option *ngIf="subjectList.length==0">No Subjects</mat-option>
                          <mat-option [value]="sub.subjectID" *ngFor="let sub of subjectList">{{sub.subjectName}}
                          </mat-option>
                        <!-- </mat-select> -->
                        <!-- <mat-error *ngIf="stuAttendanceForm.controls['SubjetID'].invalid">
                          Subject is required
                        </mat-error> -->
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)"
                          formControlName="Date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="stuAttendanceForm.controls['Date'].invalid">
                          Date is required
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div class="col-lg-2 col-sm-12">
              <button type="submit" class="btn btn-primary btn-block smallerBtn mt-1" matRipple>
                Find <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                  <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                    <path id="Path"
                      d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                      transform="translate(-3 -5.95)" fill="#fff" />
                  </g>
                </svg></button>
            </div>


          </div>
        </div>
      </form>


      <div *ngIf="STUDENT_DATA && STUDENT_DATA.length>0">
        <div class="topLegendWidget d-sm-flex justify-content-between mt-2">
          <div class="multiCheckstatus mr-2">
            <mat-form-field class="w-100" appearance="outline">
              <mat-select placeholder="Select Status" #select multiple [(ngModel)]="Lstatus"
                (ngModelChange)="statusChange($event)">
                <mat-option (click)="optionClick()" *ngFor="let status of Status" [value]="status.viewValue">
                  {{status.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="legendsAttendance row-flex align-items-center">
            <div class="legendCol" *ngFor="let att of attendanceStatus">
              <span *ngIf="att.statusName != 'Not Marked Yet'" class="legendColor" [ngStyle]="{'background-color': att.colorCode }"></span> 
              <label *ngIf="att.statusName != 'Not Marked Yet'">{{att.statusName}}</label>
            </div>

            <!-- <div class="legendCol present">
              <span class="legendColor"></span> <label>Present</label>
            </div>
            <div class="legendCol absent">
              <span class="legendColor"></span> <label>Absent</label>
            </div>
            <div class="legendCol null">
              <span class="legendColor"></span> <label>Null</label>
            </div>
            <div class="legendCol holiday">
              <span class="legendColor"></span> <label>Holiday</label>
            </div> -->
          </div>
        </div>

        <div class="float-right">
            <!-- <a (click)="exporter.exportTable('csv', {fileName:'student_attendance'})"><i
                class="fas fa-download mr-3"></i></a> -->
                <div class="d-flex">
                <div class="downloadTable tableActions" matTooltip="Download Attendence">
                  <a (click)="exporter.exportTable('csv', {fileName:'student_attendance'})"><i title="Export Students" class="fas fa-download mt-3"></i></a>
                </div>
          <div class="btn-group mr-2" role="group">
            <button class="btn btn-outline-primary" (click)=prevWeek() matRipple><i
                class="fas fa-chevron-left"></i>
              Previous</button>
            <button class="btn btn-outline-primary" (click)=nextWeek() matRipple>Next <i
                class="fas fa-chevron-right"></i></button>
          </div>
        </div>
        </div>


        <div class="mat-elevation-z8 py-4">
          <table matTableExporter #exporter="matTableExporter" mat-table [dataSource]="dataSource">

            <!-- ID Column -->
            <ng-container matColumnDef="admissionNo">
              <th mat-header-cell *matHeaderCellDef> Admission No </th>
              <td mat-cell *matCellDef="let element"> {{element.admissionNumber}} </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef> First Name </th>
              <td mat-cell *matCellDef="let element">
                {{element.firstName}}
              </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="lastName">
              <th mat-header-cell *matHeaderCellDef> Last Name </th>
              <td mat-cell *matCellDef="let element"> {{element.lastName}}</td>
            </ng-container>

            <!-- Plan Name Column -->
            <ng-container matColumnDef="enrollmentStatus">
              <th mat-header-cell *matHeaderCellDef> Enrollment Satus </th>
              <td mat-cell *matCellDef="let element">
                {{element.studentEnrollmentStatusName}}

              </td>
            </ng-container>

            <ng-container matColumnDef="className" >
              <th mat-header-cell *matHeaderCellDef style="display:none"> Class Name </th>
              <td mat-cell *matCellDef="let element" style="display:none">
                {{element.className}}
              </td>
            </ng-container>

            <ng-container matColumnDef="sectionName" >
              <th mat-header-cell *matHeaderCellDef style="display:none"> Section Name </th>
              <td mat-cell *matCellDef="let element" style="display:none">
                {{element.sectionName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="subjectName" >
              <th mat-header-cell *matHeaderCellDef style="display:none"> Subject Name </th>
              <td mat-cell *matCellDef="let element" style="display:none">
                {{element.subjectName}}
              </td>
            </ng-container>



            <!-- Monday Column -->
            <ng-container matColumnDef="monday">
              <th mat-header-cell *matHeaderCellDef class="text-left">
                <div class="attendanceWeek" [ngClass]="this.collapseMonFlag ? 'collapse_mon' : ''">
                  <span (click)="collapseMon()">
                    Mon <i class="fas fa-caret-down"></i> <br> {{WeekDates.MonDate}}
                  </span>
                  <div class="markAttendanceDD">
                    <span (click)="collapseMon()" class="close-icon">
                      <i class="fas fa-close"></i>
                    </span>
                    <p class="d-flex justify-content-between">
                      <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                        (change)="markAll($event.checked)">
                        Mark All
                      </mat-checkbox>
                      <select class="selectAttendance" [disabled]="isHolidaySelected || !isMarkAllSelected"
                        (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Mon')">
                        <option value="{{att.attendanceStatusMasterID}}" *ngFor="let att of AttendanceStatusList"
                          [ngStyle]="{'background-color': att.colorCode }">
                          {{att.statusSortCode}}

                        </option>
                      </select>
                    </p>
                    <p class="mt-1 holidayMarkTop">
                      <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                        (change)="markHoliday($event.checked, 'Mon')">Holiday
                      </mat-checkbox>
                      <i class="fas fa-info-circle ml-2"
                        matTooltip="This day will be marked as holiday."
                        aria-label="Button that displays a tooltip when focused or hovered over"></i>
                    </p>
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="text-left">
                <span class="markedAtt presentMark" id="col{{element.studentID}}monday"
                  (click)="changeAttStatusOnClick(element.monday, element.studentID, 'monday', true)">

                </span>
              </td>
            </ng-container>

            <!-- Tuesday Column -->
            <ng-container matColumnDef="tuesday">
              <th mat-header-cell *matHeaderCellDef class="text-left">
                <div class="attendanceWeek" [ngClass]="this.collapseTueFlag ? 'collapse_tue' : ''">
                  <span (click)="collapseTue()">
                    Tue <i class="fas fa-caret-down"></i> <br> {{WeekDates.TuesDate}}
                  </span>
                  <div class="markAttendanceDD">
                    <span (click)="collapseTue()" class="close-icon">
                      <i class="fas fa-close"></i>
                    </span>
                    <p class="d-flex justify-content-between">
                      <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                        (change)="markAll($event.checked)">Mark All</mat-checkbox>
                      <select class="selectAttendance" [disabled]="isHolidaySelected || !isMarkAllSelected"
                        (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Tues')">
                        <option value="{{att.attendanceStatusMasterID}}" *ngFor="let att of AttendanceStatusList">
                          {{att.statusSortCode}}</option>
                      </select>
                    </p>
                    <p class="mt-1 holidayMarkTop">
                      <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                        (change)="markHoliday($event.checked, 'Tues')">Holiday
                      </mat-checkbox>
                      <i class="fas fa-info-circle ml-2"
                        matTooltip="This day will be marked as holiday."
                        aria-label="Button that displays a tooltip when focused or hovered over"></i>
                    </p>
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="text-left">
                <span class="markedAtt absentMark" id="col{{element.studentID}}tuesday"
                  [ngStyle]="{'background-color':  element.colorCode }"
                  (click)="changeAttStatusOnClick(element.tuesday, element.studentID, 'tuesday', true)">{{element.colorCode}},{{element.tuesday}}</span>
              </td>
            </ng-container>

            <!-- Wednesday Column -->
            <ng-container matColumnDef="wednesday">
              <th mat-header-cell *matHeaderCellDef class="text-left">
                <div class="attendanceWeek" [ngClass]="this.collapseWedFlag ? 'collapse_wed' : ''">
                  <span (click)="collapseWed()">
                    Wed <i class="fas fa-caret-down"></i> <br> {{WeekDates.WedDate}}
                  </span>
                  <div class="markAttendanceDD">
                    <span (click)="collapseWed()" class="close-icon">
                      <i class="fas fa-close"></i>
                    </span>
                    <p class="d-flex justify-content-between">
                      <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                        (change)="markAll($event.checked)">Mark All</mat-checkbox>
                      <select class="selectAttendance" [disabled]="isHolidaySelected || !isMarkAllSelected"
                        (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Wed')">
                        <option value="{{att.attendanceStatusMasterID}}" *ngFor="let att of AttendanceStatusList">
                          {{att.statusSortCode}}
                        </option>
                      </select>
                    </p>
                    <p class="mt-1 holidayMarkTop">
                      <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                        (change)="markHoliday($event.checked, 'Wed')">Holiday</mat-checkbox>
                      <i class="fas fa-info-circle ml-2"
                        matTooltip="This day will be marked as holiday."
                        aria-label="Button that displays a tooltip when focused or hovered over"></i>
                    </p>
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="text-left">
                <span class="markedAtt presentMark" id="col{{element.studentID}}wednesday"
                  [ngStyle]="{'background-color':  element.colorCode }"
                  (click)="changeAttStatusOnClick(element.wednesday, element.studentID, 'wednesday', true)">{{element.colorCode}}
                  {{element.wednesday}}</span>
              </td>
            </ng-container>

            <!-- Thursday Column -->
            <ng-container matColumnDef="thursday">
              <th mat-header-cell *matHeaderCellDef class="text-left">
                <div class="attendanceWeek" [ngClass]="this.collapseThuFlag ? 'collapse_thu' : ''">
                  <span (click)="collapseThu()">
                    Thu <i class="fas fa-caret-down"></i> <br> {{WeekDates.ThursDate}}
                  </span>
                  <div class="markAttendanceDD">
                    <span (click)="collapseThu()" class="close-icon">
                      <i class="fas fa-close"></i>
                    </span>
                    <p class="d-flex justify-content-between">
                      <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                        (change)="markAll($event.checked)">Mark All</mat-checkbox>
                      <select class="selectAttendance" [disabled]="isHolidaySelected || !isMarkAllSelected"
                        (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Thurs')">
                        <option value="{{att.attendanceStatusMasterID}}" *ngFor="let att of AttendanceStatusList">
                          {{att.statusSortCode}}</option>
                      </select>
                    </p>
                    <p class="mt-1 holidayMarkTop">
                      <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                        (change)="markHoliday($event.checked, 'Thurs')">Holiday
                      </mat-checkbox>
                      <i class="fas fa-info-circle ml-2"
                        matTooltip="This day will be marked as holiday."
                        aria-label="Button that displays a tooltip when focused or hovered over"></i>
                    </p>
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="text-left">
                <span class="markedAtt presentMark" id="col{{element.studentID}}thursday"
                  (click)="changeAttStatusOnClick(element.thursday, element.studentID, 'thursday', true)">{{element.thursday}}</span>
              </td>
            </ng-container>

            <!-- Friday Column -->
            <ng-container matColumnDef="friday">
              <th mat-header-cell *matHeaderCellDef class="text-left">
                <div class="attendanceWeek" [ngClass]="this.collapseFriFlag ? 'collapse_fri' : ''">
                  <span (click)="collapseFri()">
                    Fri <i class="fas fa-caret-down"></i> <br> {{WeekDates.FriDate}}
                  </span>
                  <div class="markAttendanceDD">
                    <span (click)="collapseFri()" class="close-icon">
                      <i class="fas fa-close"></i>
                    </span>
                    <p class="d-flex justify-content-between">
                      <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                        (change)="markAll($event.checked)">Mark All</mat-checkbox>
                      <select class="selectAttendance" [disabled]="isHolidaySelected || !isMarkAllSelected"
                        (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Fri')">
                        <option value="{{att.attendanceStatusMasterID}}" *ngFor="let att of AttendanceStatusList">
                          {{att.statusSortCode}}</option>
                      </select>
                    </p>
                    <p class="mt-1 holidayMarkTop">
                      <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                        (change)="markHoliday($event.checked, 'Fri')">Holiday
                      </mat-checkbox>
                      <i class="fas fa-info-circle ml-2"
                        matTooltip="This day will be marked as holiday."
                        aria-label="Button that displays a tooltip when focused or hovered over"></i>
                    </p>
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="text-left">
                <span class="markedAtt nullMark" id="col{{element.studentID}}friday"
                  (click)="changeAttStatusOnClick(element.friday, element.studentID, 'friday', true)">{{element.friday}}</span>
              </td>
            </ng-container>

            <!-- Sat Column -->
            <ng-container matColumnDef="saturday">
              <th mat-header-cell *matHeaderCellDef class="text-left">
                <div class="attendanceWeek" [ngClass]="this.collapseSatFlag ? 'collapse_sat' : ''">
                  <span (click)="collapseSat()">
                    Sat <i class="fas fa-caret-down"></i> <br> {{WeekDates.SatDate}}
                  </span>
                  <div class="markAttendanceDD">
                    <span (click)="collapseSat()" class="close-icon">
                      <i class="fas fa-close"></i>
                    </span>
                    <p class="d-flex justify-content-between">
                      <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                        (change)="markAll($event.checked)">Mark All</mat-checkbox>
                      <select class="selectAttendance" [disabled]="isHolidaySelected || !isMarkAllSelected"
                        (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Sat')">
                        <option value="{{att.attendanceStatusMasterID}}" *ngFor="let att of AttendanceStatusList">
                          {{att.statusSortCode}}</option>
                      </select>
                    </p>
                    <p class="mt-1 holidayMarkTop">
                      <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                        (change)="markHoliday($event.checked, 'Sat')">Holiday
                      </mat-checkbox>
                      <i class="fas fa-info-circle ml-2"
                        matTooltip="This day will be marked as holiday."
                        aria-label="Button that displays a tooltip when focused or hovered over"></i>
                    </p>
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="text-left">
                <span class="markedAtt holidayMark" id="col{{element.studentID}}saturday"
                  (click)="changeAttStatusOnClick(element.saturday, element.studentID, 'saturday', true)">{{element.saturday}}</span>
              </td>
            </ng-container>

            <!-- Sun Column -->
            <ng-container matColumnDef="sunday">
              <th mat-header-cell *matHeaderCellDef class="text-left">
                <div class="attendanceWeek" [ngClass]="this.collapseSunFlag ? 'collapse_sun' : ''">
                  <span (click)="collapseSun()">
                    Sun <i class="fas fa-caret-down"></i> <br> {{WeekDates.SunDate}}
                  </span>
                  <div class="markAttendanceDD">
                    <span (click)="collapseSun()" class="close-icon">
                      <i class="fas fa-close"></i>
                    </span>
                    <p class="d-flex justify-content-between">
                      <mat-checkbox [disabled]="isHolidaySelected" [checked]="isMarkAllSelected"
                        (change)="markAll($event.checked)">Mark All</mat-checkbox>
                      <select class="selectAttendance" [disabled]="isHolidaySelected || !isMarkAllSelected"
                        (click)="isMarkAllSelected && setWholeClassAttStatus($event.target, 'Sun')">
                        <option value="{{att.attendanceStatusMasterID}}" *ngFor="let att of AttendanceStatusList">
                          {{att.statusSortCode}}</option>
                      </select>
                    </p>
                    <p class="mt-1 holidayMarkTop">
                      <mat-checkbox [disabled]="isMarkAllSelected" [checked]=" isHolidaySelected"
                        (change)="markHoliday($event.checked, 'Sun')">Holiday
                      </mat-checkbox>
                      <i class="fas fa-info-circle ml-2"
                        matTooltip="This day will be marked as holiday."
                        aria-label="Button that displays a tooltip when focused or hovered over"></i>
                    </p>
                  </div>
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="text-left">
                <span class="markedAtt holidayMark" id="col{{element.studentID}}sunday"
                  (click)="changeAttStatusOnClick(element.sunday, element.studentID, 'sunday', true)">{{element.sunday}}</span>
              </td>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No data matching the filter
              </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
        </div>
        <div class="justify-content-between">
          <div class="row mb-2 ml-1">
            {{TotalStudentsFound}} Students
          </div>
          <div class="row">
            <div class="col-md-10 col-sm-12">
              <div class="notiOptions flex-grow-1" [ngClass]="this.collapseSentNoti ? 'active' : ''">
                <mat-checkbox (click)="collapseNoti()" class="mb-2">Send Absent Notification</mat-checkbox>
                <div class="notiOptionWidget">
                  <div class="notiOptionList">
                    <div class="leftLabel">Send to: </div>
                    <div class="rightCheckBoxes">
                      <mat-checkbox class="example-margin" (click)="NotificationToParent()">Parents</mat-checkbox>
                      <mat-checkbox class="example-margin" (click)="NotificationToStudent()">Students</mat-checkbox>
                    </div>
                  </div>
                  <div class="notiOptionList">
                    <div class="leftLabel">Send via:</div>
                    <div class="rightCheckBoxes">
                      <mat-checkbox class="example-margin" (click)="NotificationViaEmail()">Email</mat-checkbox>
                      <mat-checkbox class="example-margin" (click)="NotificationViaSMS()">SMS</mat-checkbox>
                    </div>
                  </div>
                  <div class="notiOptionList">
                    <div class="leftLabel">Notify For:</div>
                    <div class="rightCheckBoxes" *ngIf="show || showForSMS">
                      <mat-checkbox class="example-margin" *ngIf="monAbsent && monAbsent.length>0"
                        (click)="mondayAbsent()">Mon</mat-checkbox>
                      <mat-checkbox class="example-margin" *ngIf="tueAbsent && tueAbsent.length>0"
                        (click)="tuesdayAbsent()">Tue</mat-checkbox>
                      <mat-checkbox class="example-margin" *ngIf="webAbsent && webAbsent.length>0"
                        (click)="wednesdayAbsent()">Wed</mat-checkbox>
                      <mat-checkbox class="example-margin" *ngIf="thurAbsent && thurAbsent.length>0"
                        (click)="thursdayAbsent()">Thu</mat-checkbox>
                      <mat-checkbox class="example-margin" *ngIf="friAbsent && friAbsent.length>0"
                        (click)="fridayAbsent()">Fri</mat-checkbox>
                      <mat-checkbox class="example-margin" *ngIf="satAbsent && satAbsent.length>0"
                        (click)="saturdayAbsent()">Sat</mat-checkbox>
                      <mat-checkbox class="example-margin" *ngIf="sunAbsent && sunAbsent.length>0"
                        (click)="sundayAbsent()">Sun</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="">
                <button class="btn btn-primary btn-block" (click)="saveStudentAttendance()" matRipple>Submit
                </button>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div class="note cursor-pointer" (click)="showlogs()" style="font-size: 18px;">
                <b>View Edit Logs</b>
              </div>
              <div *ngIf="showlogshide">
              <table mat-table [dataSource]="dataSourceAttendance" class="mat-elevation-z8 d-table" >
        
                <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->
            
                <!-- Position Column -->
                
                <!-- Name Column -->
                <ng-container matColumnDef="changes">
                  <th mat-header-cell *matHeaderCellDef>Changes</th>
                  <td mat-cell *matCellDef="let element"> {{element.changes}} </td>
                </ng-container>
                <ng-container matColumnDef="createdDate">
                  <th mat-header-cell *matHeaderCellDef>Date</th>
                  <td mat-cell *matCellDef="let element"> {{element.logDate | date: dateFormat}} </td>
                </ng-container>
                <ng-container matColumnDef="attendanceMessage">
                  <th mat-header-cell *matHeaderCellDef>Message</th>
                  <td mat-cell *matCellDef="let element"> {{element.attendanceMessage}} </td>
                </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
              </table>
              <!-- <div *ngIf="noRecord==true">No records found</div> -->
              <mat-paginator [length]="metaData['totalRecords']" [pageIndex]="metaData['currentPage'] - 1"
              [pageSize]="metaData['pageSize']" (page)="handlePage($event)"   showFirstLastButtons></mat-paginator>
            </div>
            </div>
            </div>
           
          
        </div>
      </div>
    </div>
  </div>
</div>