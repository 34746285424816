import { Component, ElementRef, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { MasterDropdownService } from '../../common/services/MasterDropdownServices/master-dropdown.service';
import { MatDialog } from '@angular/material/dialog';
//import { SubscriptionPlanService } from "../../common/services/subscriptionPlanService/subscription-plan.service";
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from 'src/app/app-layout/header/header.component';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { SessionStatusDropdownService } from 'src/app/masterServices/sessionStatusDropdownServices/session-status-dropdown.service';
import { DialogService } from 'src/app/services/dialog.service';
import { AcademicSessionModel } from '../../courses-and-subjects/model/AcademicSession.Model';
import { AcademicSessionService } from '../../courses-and-subjects/services/academicSessionServices/academic-session.service';
//import { ToastrService } from 'ngx-toastr';
//import { SiteSubscriptionModel } from './SiteSubscriptionModel';
//import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
//import { DialogService } from 'src/app/common/core-services/DialogService';////
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge } from 'rxjs';
@Component({
  selector: 'app-new-academic-sessions',
  templateUrl: './new-academic-sessions.component.html',
  styleUrls: ['./new-academic-sessions.component.css'],
})
export class NewAcademicSessionsComponent implements OnInit, AfterViewInit {
  @ViewChild('copyPopup', { read: TemplateRef }) copyTemplate: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayCol: string[] = ['academicSessionName','startDate','endDate','academicSessionStatusName','lockunlock', 'actions'];
  //classToggled = false;

  // public toggleField() {
  //   this.classToggled = !this.classToggled;
  // }

  // @ViewChild(MatSort)
  // sort!: MatSort;
  // constructor(private dialog: MatDialog) { }
  // displayedColumns: string[] = ['id', 'name', 'timeperiod', 'status', 'lockunlock', 'actions'];
  //   // dataSource = ELEMENT_DATA;
  //   dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  //   //selection = new SelectionModel<PeriodicElement>(true, []);
  // ngAfterViewInit(): void {
  //   this.dataSource.sort = this.sort;
  // }

  // actionDialogPopup(templateRef: TemplateRef<any>) {
  //   this.dialog.open(templateRef);
  // }

  /** Whether the number of selected elements matches the total number of rows. */
  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.data.length;
  //   return numSelected === numRows;
  // }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //       this.selection.clear() :
  //       this.dataSource.data.forEach(row => this.selection.select(row));
  // }

  requestpages: any;
  form: FormGroup;
  sessionCopyForm: FormGroup;
  metaData: any;
  dateFormat:any;
  private filterModel: FilterModel;
  academicSessionListData: AcademicSessionModel[];
  copyFromAcademicSession: AcademicSessionModel[];
  copyToAcademicSession: AcademicSessionModel[];
  keepExistingDataCheck: boolean = false;
  flushExistingDataDataCheck: boolean = false;
  teacherAndSubjectAllocationCheck: boolean = false;
  classSchedulesCheck: boolean = false;
  assessmentCategoryCheck: boolean = false;
  gradingPeriodsCheck: boolean = false;
  gradingScalesCheck: boolean = false;
  assessmentCheck: boolean = false;
  reportCardFormatsCheck: boolean = false;
  classToggled = false;
  sessionStatus: any;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  displayedColumns: Array<any> = [
    {
      displayName: 'Session Name',
      key: 'academicSessionName',
      isSort: true,
      class: '',
      width: '20%',
    },
    {
      displayName: 'Start Date',
      key: 'startDate',
      isSort: true,
      class: '',
      width: '20%',
    },
    {
      displayName: 'End Date',
      key: 'endDate',
      isSort: true,
      class: '',
      width: '20%',
    },
    {
      displayName: 'Status',
      key: 'academicSessionStatusName',
      isSort: true,
      class: '',
      width: '20%',
    },
    {
      displayName: 'Unlock/Lock',
      key: 'isLocked',
      type: ['togglespan'],
      permission: true,
      isSort: true,
      class: '',
      width: '10%',
    },
    //{ displayName: 'Lock/Unlock', key: 'isLocked', isSort: true, class: '', width: '20%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' },
  ];
  actionButtons: Array<any> = [
    {
      displayName: 'Drag and Drop to Order',
      key: '',
      class: 'fas fa-arrows-alt',
    },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    {
      displayName: 'Copy data from another Academic session',
      key: 'copy',
      class: 'fas fa-copy',
    },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
  users:any;
  constructor(
    private dialog: MatDialog,
    private academicSessionService: AcademicSessionService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private sessionStatusDropdownService: SessionStatusDropdownService //private masterDropdownService: MasterDropdownService
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if (currentUser != null) {
      this.dateFormat = currentUser.dateFormat;
    }
    this.filterModel = new FilterModel();
    this.academicSessionListData = new Array<AcademicSessionModel>();
    this.copyFromAcademicSession = new Array<AcademicSessionModel>();
    this.copyToAcademicSession = new Array<AcademicSessionModel>();

    // this.academicSessionListData = this.academicSessionListData.filter((x:any)=>x.academicSessionID !=1)
    // console.log();

    
    
  } 

  ngOnInit(): void {
   
    console.log("");
    
    for (let i = 0; i < this.academicSessionListData.length; i++) {
      console.log("this.academicSessionListData[i]", this.academicSessionListData[i]);
      if(this.academicSessionListData[i].academicSessionID==1){
        console.log("default");
        this.actionButtons = this.actionButtons.filter((x:any)=>x.displayName !='Delete')
        
      }
      
    }
    this.metaData = new Metadata();
    //this.masterDropdown();
    this.form = this.formBuilder.group({
      academicSessionName: [''],
      academicSessionStatusName: [''],
    });

    this.sessionFormBuilder();

    this.getacademicSessionList(this.filterModel);
    this.getMasterDropdown();
  }

  sessionFormBuilder(){
    this.sessionCopyForm = this.formBuilder.group({
      copyDataFrom: [0, [Validators.required]],
      copyDataTo: [0, [Validators.required]],
      keepExistingData: [true],
      flushExistingData: [false],
      teacherAndSubjectAllocation: [false],
      classSchedules: [false],
      assessmentCategory: [false],
      gradingPeriods: [false],
      gradingScales: [false],
      assessment: [false],
      reportCardFormats: [false],
    });
    this.keepExistingDataCheck = true;
    this.flushExistingDataDataCheck = false;
  }
  
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .subscribe(() => {
        const changeState = {
          sort: this.sort.active || '' ,
          order: this.sort.direction || '',
          pageNumber: (this.paginator.pageIndex + 1)
        }
        this.onPageOrSortChange(changeState);
      })
  }

  onPageOrSortChange(changeState?: any) {
    //debugger;
    console.log("onPageOrSortChange", changeState)
    this.setPaginatorModel(
      changeState.pageNumber, 
      this.filterModel.pageSize,
       changeState.sort, 
       changeState.order, 
       this.filterModel.searchText);
    this.getacademicSessionList(this.filterModel)
    console.log("onPageOrSortChange this.filterModel ", this.filterModel)
  }


  // onPageOrSortChange(changeState?: any) {
  //   this.setPaginatorModel(
  //     changeState.pageNumber,
  //     this.filterModel.pageSize,
  //     changeState.sort,
  //     changeState.order,
  //     this.filterModel.searchText
  //   );
  //   this.getacademicSessionList(this.filterModel);
  // }

  getMasterDropdown() {
    this.sessionStatusDropdownService
      .AcademicSessionStatusDropdown()
      .subscribe((res: any) => {
        this.sessionStatus = res.responseData;
      });
  }

  onSubmit() {
    this.requestpages = {
      pageSize: 25,
      pageNumber: 1,
      columnName: '',
      academicSessionName: this.form.controls.academicSessionName.value,
      academicSessionStatusName:
        this.form.controls.academicSessionStatusName.value,
      sortOrder: '',
    };
    console.log(this.requestpages);
    this.getacademicSessionList(this.requestpages);
  }

  onActionClick(action: any, data: any){
    const id = data && data.academicSessionID;
    switch ((action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;

      case 'COPY':
        this.copyCall(id);
        break;

      case 'DELETE':
        {
          this.dialogService
            .confirm(`Are you sure you want to delete this Academic Session?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.DeleteAcademicSession(id);
              }
            });
        }
        break;

      case 'TOGGLE':
        //debugger;
       // console.log('Toggele', actionObj);
        if (data.isLocked != true) {
          this.dialogService
            .confirm(`Are you sure you want to lock academic session?`)
            .subscribe((result: true) => {
              if (result == true) {
                this.academicSessionService
                  .LockUnlockAcademicSession(id)
                  .subscribe((res: any) => {
                    this.toaster.success(res.message);
                    this.InitialGridCall();
                    this.getacademicSessionList(this.requestpages);
                  });
              } else {
                this.InitialGridCall();
                this.getacademicSessionList(this.requestpages);
              }
            });
        } else {
          this.dialogService
            .confirm(`Are you sure you want to unlock academic session?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.academicSessionService
                  .LockUnlockAcademicSession(id)
                  .subscribe((res: any) => {
                    this.toaster.success(res.message);
                    this.InitialGridCall();
                    this.getacademicSessionList(this.requestpages);
                  });
              } else {
                this.InitialGridCall();
                this.getacademicSessionList(this.requestpages);
              }
            });
        }

      // case 'INFO':
      //   this.infoCall(id);
      //   break;
      // default:
      //   break;
    }

  }

  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.academicSessionID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;

      case 'COPY':
        this.copyCall(id);
        break;

      case 'DELETE':
        {
          this.dialogService
            .confirm(`Are you sure you want to delete this Academic Session?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.DeleteAcademicSession(id);
              }
            });
        }
        break;

      case 'TOGGLE':
        console.log('Toggele', actionObj);
        if (actionObj.data.isLocked != true) {
          this.dialogService
            .confirm(`Are you sure you want to lock academic session?`)
            .subscribe((result: true) => {
              if (result == true) {
                this.academicSessionService
                  .LockUnlockAcademicSession(id)
                  .subscribe((res: any) => {
                    this.toaster.success(res.message);
                    this.InitialGridCall();
                    this.getacademicSessionList(this.requestpages);
                  });
              } else {
                this.InitialGridCall();
                this.getacademicSessionList(this.requestpages);
              }
            });
        } else {
          this.dialogService
            .confirm(`Are you sure you want to unlock academic session?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.academicSessionService
                  .LockUnlockAcademicSession(id)
                  .subscribe((res: any) => {
                    this.toaster.success(res.message);
                    this.InitialGridCall();
                    this.getacademicSessionList(this.requestpages);
                  });
              } else {
                this.InitialGridCall();
                this.getacademicSessionList(this.requestpages);
              }
            });
        }

      // case 'INFO':
      //   this.infoCall(id);
      //   break;
      // default:
      //   break;
    }
  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(
      1,
      this.filterModel.pageSize,
      this.filterModel.sortColumn,
      this.filterModel.sortOrder,
      searchText
    );
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getacademicSessionList(this.filterModel);
    }
  }

  getacademicSessionList(filterModel: any) {
    this.academicSessionService
      .AllAcademicSession(filterModel)
      .subscribe((res: any) => {
        if (res.status > 0) {
          console.log('resSession', res);
          this.academicSessionListData = res.responseData;
          this.copyFromAcademicSession = res.responseData;
          this.copyToAcademicSession = res.responseData;
          this.metaData = res.meta;
        } else {
          this.metaData = new Metadata();
        }
        console.log("this.academicSessionListData",this.academicSessionListData);
      });
  }

  setPaginatorModel(
    pageNumber: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string,
    searchText: string
  ) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  editCall(academicSessionID: any) {
    console.log('IDD', academicSessionID);
    const dRef = this.dialog.open(HeaderComponent, {
      data: { id: academicSessionID, flag: true },
    });
    dRef.componentInstance.onEdit.subscribe(() => {
      console.log('Insidddd close');
      this.InitialGridCall();
      this.getacademicSessionList(this.requestpages);
    });
  }

  copyCall(id: number) {
    console.log('copyCall ID : ', id);

    this.sessionCopyForm.reset();
    this.sessionFormBuilder();
    this.keepExistingDataCheck = true;
    this.flushExistingDataDataCheck = false;
    this.sessionCopyForm.controls['keepExistingData'].setValue(this.keepExistingDataCheck);
    this.sessionCopyForm.controls['flushExistingData'].setValue(this.flushExistingDataDataCheck);

    this.sessionCopyForm.controls['copyDataTo'].setValue(id);
    this.copyFromAcademicSession = this.academicSessionListData;
    this.copyFromAcademicSession = this.copyFromAcademicSession.filter(
      (x) => x.academicSessionID != id
    );
    this.dialog.open(this.copyTemplate);
  }

  flushKeepDataCheck(keepExistingData: boolean, flushExistingData: boolean) {
    this.sessionCopyForm.controls['keepExistingData'].setValue(
      keepExistingData
    );
    this.sessionCopyForm.controls['flushExistingData'].setValue(
      flushExistingData
    );
    this.keepExistingDataCheck = keepExistingData;
    this.flushExistingDataDataCheck = flushExistingData;
    console.log('THIS FORM', this.sessionCopyForm.value);
  }

  tableOperationsSelection(tableName: string) {
    switch (tableName) {
      case 'teacherAndSubjectAllocation':
        if (this.teacherAndSubjectAllocationCheck == false) {
          this.teacherAndSubjectAllocationCheck = true;
        } else {
          this.teacherAndSubjectAllocationCheck = false;
          this.classSchedulesCheck = false;
        }

        this.sessionCopyForm.controls['teacherAndSubjectAllocation'].setValue(this.teacherAndSubjectAllocationCheck);
        this.sessionCopyForm.controls['classSchedules'].setValue(this.classSchedulesCheck);
        break;

      case 'classSchedules':
        if (this.classSchedulesCheck == false) {
          this.teacherAndSubjectAllocationCheck = true;
          this.classSchedulesCheck = true;
        } else {
          this.teacherAndSubjectAllocationCheck = false;
          this.classSchedulesCheck = false;
        }

        this.sessionCopyForm.controls['classSchedules'].setValue(this.classSchedulesCheck);
        this.sessionCopyForm.controls['teacherAndSubjectAllocation'].setValue(this.teacherAndSubjectAllocationCheck);
        break;

      case 'assessmentCategory':
        if (this.assessmentCategoryCheck == false) {
          this.assessmentCategoryCheck = true;
        } else {
          this.assessmentCategoryCheck = false;
          this.gradingPeriodsCheck = false;
          this.gradingScalesCheck = false;
          this.assessmentCheck = false;
        }

        this.sessionCopyForm.controls['assessmentCategory'].setValue(this.assessmentCategoryCheck);
        this.sessionCopyForm.controls['gradingPeriods'].setValue(this.gradingPeriodsCheck);
        this.sessionCopyForm.controls['gradingScales'].setValue(this.gradingScalesCheck);
        this.sessionCopyForm.controls['assessment'].setValue(this.assessmentCheck);
        break;

      case 'gradingPeriods':
        if (this.gradingPeriodsCheck == false) {
          this.gradingPeriodsCheck = true;
        } else {
          this.assessmentCategoryCheck = false;
          this.gradingPeriodsCheck = false;
          this.gradingScalesCheck = false;
          this.assessmentCheck = false;
        }

        this.sessionCopyForm.controls['assessmentCategory'].setValue(this.assessmentCategoryCheck);
        this.sessionCopyForm.controls['gradingPeriods'].setValue(this.gradingPeriodsCheck);
        this.sessionCopyForm.controls['gradingScales'].setValue(this.gradingScalesCheck);
        this.sessionCopyForm.controls['assessment'].setValue(this.assessmentCheck);
        break;

      case 'gradingScales':
        if (this.gradingScalesCheck == false) {
          this.gradingScalesCheck = true;
        } else {
          this.gradingScalesCheck = false;
          this.assessmentCategoryCheck = false;
          this.gradingPeriodsCheck = false;
          this.assessmentCheck = false;
          this.reportCardFormatsCheck = false;
        }

        this.sessionCopyForm.controls['assessmentCategory'].setValue(this.assessmentCategoryCheck);
        this.sessionCopyForm.controls['gradingPeriods'].setValue(this.gradingPeriodsCheck);
        this.sessionCopyForm.controls['gradingScales'].setValue(this.gradingScalesCheck);
        this.sessionCopyForm.controls['assessment'].setValue(this.assessmentCheck);
        this.sessionCopyForm.controls['reportCardFormats'].setValue(this.reportCardFormatsCheck);
        break;

      case 'assessment':
        if (this.assessmentCheck == false) {
          this.assessmentCategoryCheck = true;
          this.gradingPeriodsCheck = true;
          this.gradingScalesCheck = true;
          this.assessmentCheck = true;
        } else {
          this.assessmentCategoryCheck = false;
          this.gradingPeriodsCheck = false;
          this.gradingScalesCheck = false;
          this.assessmentCheck = false;
          if (this.reportCardFormatsCheck == true) {
            this.tableOperationsSelection('reportCardFormats')
          }
        }

        this.sessionCopyForm.controls['assessmentCategory'].setValue(this.assessmentCategoryCheck);
        this.sessionCopyForm.controls['gradingPeriods'].setValue(this.gradingPeriodsCheck);
        this.sessionCopyForm.controls['gradingScales'].setValue(this.gradingScalesCheck);
        this.sessionCopyForm.controls['assessment'].setValue(this.assessmentCheck);
        break;

      case 'reportCardFormats':
        if (this.reportCardFormatsCheck == false) {
          this.reportCardFormatsCheck = true;
          this.gradingScalesCheck = true;
        } else {
          this.reportCardFormatsCheck = false;
          this.gradingScalesCheck = false;
          if (this.assessmentCheck == true) {
            this.tableOperationsSelection('assessment')
          }
        }

        this.sessionCopyForm.controls['reportCardFormats'].setValue(this.reportCardFormatsCheck);
        this.sessionCopyForm.controls['gradingScales'].setValue(this.gradingScalesCheck);

        break;
    }
  }

  sessionCopyFormSubmit() {
    console.log("this.sessionCopyForm", this.sessionCopyForm);
    console.log("this.sessionCopyForm.valid", this.sessionCopyForm.valid);
   
    if (this.sessionCopyForm.value.copyDataFrom > 0 && this.sessionCopyForm.value.copyDataTo > 0 ) {
      if (this.sessionCopyForm.value.assessment != false || 
        this.sessionCopyForm.value.assessmentCategory != false || 
        this.sessionCopyForm.value.classSchedules != false || 
        this.sessionCopyForm.value.gradingPeriods != false || 
        this.sessionCopyForm.value.gradingScales != false || 
        this.sessionCopyForm.value.reportCardFormats != false || 
        this.sessionCopyForm.value.teacherAndSubjectAllocation != false ) {
        console.log("this.sessionCopyForm.value", this.sessionCopyForm.value);
  
          var obj = {
            copyFromAcademicSessionID: this.sessionCopyForm.value.copyDataFrom,
            copyToAcademicSessionID: this.sessionCopyForm.value.copyDataTo,
            subjectAllocation: this.sessionCopyForm.value.teacherAndSubjectAllocation,
            classSchedules: this.sessionCopyForm.value.classSchedules,
            assessmentCategory: this.sessionCopyForm.value.assessmentCategory,
            gradingScales: this.sessionCopyForm.value.gradingScales,
            gradingPeriods: this.sessionCopyForm.value.gradingPeriods,
            assessment: this.sessionCopyForm.value.assessment,
            reportCardFormats: this.sessionCopyForm.value.reportCardFormats,
            keepExistingData: this.sessionCopyForm.value.keepExistingData,
            flushExistingData: this.sessionCopyForm.value.flushExistingData
          }
          console.log('COPY obj :', obj);
          
          this.academicSessionService
            .CopyAcademicSessionData(obj)
              .subscribe((res: any) => {
              this.toaster.success(res.message);
              this.dialog.closeAll();
            });
      }
      else{
        this.toaster.error("Select atleast one option!")
      }
    }
    else{
      this.toaster.error("Select copy from option!")
    }
    
   
  }

  DeleteAcademicSession(id: any) {
    this.academicSessionService
      .DeleteAcademicSession(id)
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.InitialGridCall();
          this.getacademicSessionList(this.requestpages);
        } else {
          this.toaster.error(res.message);
        }
      });
  }

  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getacademicSessionList(this.requestpages);
  }

  InitialGridCall() {
    this.requestpages = {
      pageSize: 25,
      pageNumber: 1,
      columnName: '',
      academicSessionName: '',
      academicSessionStatusName: '',
      sortOrder: '',
    };
  }
}

// export interface PeriodicElement {
// 	id: number; 
// 	name: string;
// 	timeperiod: string;
// 	status: string;
// 	lockunlock: string;
// 	actions: any;
//   }
  
//   const ELEMENT_DATA: PeriodicElement[] = [
// 	{id: 1, name: 'Virat', timeperiod: '01/08/2021 - 31/-8/2021', status: 'Upcoming', lockunlock: ' ',  actions: ' '},
//   {id: 2, name: 'Amit Mahajan ', timeperiod: '01/08/2021 - 31/-8/2021', status: 'Upcoming', lockunlock: ' ',  actions: ' '},
//   {id: 3, name: 'David Miller', timeperiod: '01/08/2021 - 31/-8/2021', status: 'Upcoming', lockunlock: ' ',  actions: ' '},
//   {id: 4, name: 'Virat', timeperiod: '01/08/2021 - 31/-8/2021', status: 'Upcoming', lockunlock: ' ',  actions: ' '},
//   {id: 5, name: 'Amit Mahajan ', timeperiod: '01/08/2021 - 31/-8/2021', status: 'Upcoming', lockunlock: ' ',  actions: ' '},
//   {id: 6, name: 'David Miller', timeperiod: '01/08/2021 - 31/-8/2021', status: 'Upcoming', lockunlock: ' ',  actions: ' '},
// 	];

