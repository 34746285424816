import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, masters } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubjectEnrollmentStatusDropdownService {

  constructor(private http : HttpClient) { }

  getSubjectEnrollmentStatusDropdown(){
 return this.http.get(`${environment.baseUrl}${masters.geSubectEnrollmentStateusDropdown}`);
  }

}
