import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { environment, profile, Student, Enrollments, Teacher, masters, Master } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  constructor(private http : HttpClient) { }
  GetTeacherTablecolumn(id:any){
    return this.http.get(`${environment.baseUrl}${Teacher.getTeacherTableColumn}${'?id=' +id}`);
  }
  UpdateTeacherTable(data:any){
    return this.http.post(`${environment.baseUrl}${Teacher.updateTeacherTableColumn}`,data)
  }

  GetTeacherAttendanceByDate(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.getTeacherAttendanceByDate}`, data)
  }
  GetAllTeacherAttendanceByDate(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.getAllTeacherAttendanceByDate}`, data)
  }
  
  saveTeacherAttendance(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.saveTeacherAttendanceByDate}`, data)
  }
  GetTeacherAnalyticsRecords(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.getTeacherAttendanceAnalyticsRecord}`, data);
  }

  getTeacherClassSchedule(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.getTeacherClassSchedule}`, data);
  }

  getColumnsForExport(formType:number){
    return this.http.get(
      `${environment.baseUrl}${Teacher.getColumnsForExport}/${formType}`
    );
    
  }

  exportFile(formType:string, columnList:string,academicSessionId: string ):Observable<Blob>{
    const formData: FormData = new FormData();
    formData.append('FormType',formType);
    formData.append('ColumnList',columnList);
    formData.append('AcademicSessionId',academicSessionId || String(0));
    return this.http.post(`${environment.baseUrl}${Teacher.exportTeacher}`,formData,{responseType:'blob'}); 
  }

  
}
