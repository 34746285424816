import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-subscriptionpop-up',
  templateUrl: './subscriptionpop-up.component.html',
  styleUrls: ['./subscriptionpop-up.component.css']
})
export class SubscriptionpopUpComponent implements OnInit {
  isShownSubscription:boolean=true;
  constructor(private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  onNoClick(){
    // localStorage.setItem()
    localStorage.setItem('isShownSubscription',JSON.stringify(this.isShownSubscription));
    this.dialogRef.closeAll();
  }

}
