
<div class="reportHeader d-flex justify-content-between align-items-center row mx-10">
    <div class="col-lg-6 col-sm-12 d-flex align-items-center">
        <img class="mr-2 logos" [src]="croppedImage" alt="logo">
        <h1 class="institutename">{{ orgName }}</h1>
    </div>
    <div class="headerRightAddress col-lg-6 col-sm-12">
        <p><strong>Address:</strong>{{ orgAddress }}</p>
        <p>
            <strong>Contact: {{ orgContact }}</strong> 
            <strong class="ml-3">Email: {{ orgEmail }}</strong> 
        </p>
    </div>
</div>
<div class="d-flex justify-content-between form-group student-card-report">
    <div class="studentInfo d-flex align-items-center">
        <div class="studentThumb">
            <img *ngIf="studentDetails[0]?.ProfilePhoto != '' " [src]="studentDetails[0].ProfilePhoto" alt="">
            <img *ngIf="studentDetails[0]?.ProfilePhoto == '' " src="../../../../../assets/img/avtaradmin.jpg" alt="">
        </div>
        <div class="flex-grow-1 studentNameCl">
            <h4>{{ studentDetails[0].Name }}</h4>
            <p>
                <strong>{{ studentDetails[0].ClassName }}-</strong>
                <strong>{{ studentDetails[0].SectionName }}</strong>
            </p>
            <p>Admission No. {{ studentDetails[0].AdmissionNumber }}</p>
            <p>Session: {{ studentDetails[0].AcademicSessionName }}</p>
        </div>
    </div>
    <div class="studentInfoAttendence">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Attendance</th>
                    <th class="center"><span *ngIf="percentage"> {{percentage[0].percentage}}</span>%</th>
                </tr>
            </thead>
            <tbody *ngFor="let item of attendanceCustomGroup">
                <tr *ngIf="item.checked">
                    <td [ngStyle]="{ color: item.colorCode }">{{ item.fieldName }}</td>
                    <td [ngStyle]="{ color: item.colorCode }" class="center">
                        <span>{{ item.total }}<span>/{{ item.totalDays }}</span></span> <span *ngIf="item.showPercentage"> ({{ item.percentage }}%)</span>
                    </td>
                </tr>
              </tbody>
        </table>
    </div>
</div>

<div class="semesterVise">
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Subjects</th>
                    <th scope="col" class="center" *ngFor="let item of assessmentName">{{ item.assessmentCategoryName }}
                        <br>
                        <div class="assm-names ass-cat-1" data-keyword="all_assessments">
                            <div *ngFor="let items of item.assessmentList" class="assm-v-name" style="width:63px;">
                                <div class="assm-name">{{ items.assessmentName }}</div>
                            </div>
                            <div class="assm-v-name  " data-keyword="assm_cats_hidde" style="width:75px; border: none;">
                                <div class="assm-name final-scoreed" style="left: -39px; padding: 6px 10px;">
                                    <b class="pwhite">Category Score</b>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </th>
                    <th scope="col" class="finalScore">Final Score</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of assessmentDetails">
                    <th scope="row">{{ item.RowNumber }}</th>
                    <td>{{ item.SubjectName }}</td>
                    <td class="center" *ngFor="let items of assessmentName">
                        <div class="assm-score" *ngFor="let itemss of items.assessmentList">
                            <div class="subject-name-height-block subject-height-1">{{ item[itemss.assessmentName] }} / {{ itemss.points }}</div>
                        </div>
                        <div class="score-bg pull-left">
                            <b class="pwhite">{{ item['cat' + items.assessmentCategoryID] }}%</b>
                        </div>
                    </td>
                    <td class="finalScore">{{ item.finalScore }}%</td>
                </tr>
                
            </tbody>
        </table>
    </div>
</div>
<div class="semesterVise fulltable">
    <h5 class="template-group-title">Comments</h5>
</div>