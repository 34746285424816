<div class="report-card">
    <div class="report-custom">
      <!-- Report card header block -->
      <div class="report-card-header-block">
        <div *ngIf="instituteGroup | customRepotCard: 'Custom title'">
          <div class="text-center"><p class="customTitle mb-2">{{editorModel.customCommentTitle}}</p></div>
          <div class="text-left" [innerHTML]="editorModel.customCommentValue"></div>
        </div>
        <div class="row">
          <div class="col-md-3 extra-header-info">
         
          </div>
          <div class="col-md-6 inst-info-block">
           
            <div *ngIf="instituteGroup | customRepotCard: 'Logo'"  class="text-center">
              <div class="inst-image-block">
                <img width="80px" [src]="croppedImage" class="inst-image"
                  alt="Inst. Logo" />
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div *ngIf="instituteGroup | customRepotCard: 'Institute name'" class="col-md-3 inst-extra-info-block">
            <div class="text-center inst-extra-info">
              <span class="inst-name">{{ orgName }}</span>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <!-- Report card Header block -->
      <!-- Report card Student Information -->
      <div class="reportcard-student-info">
        <table class="table table-bordered table-vertical-center scores-table">
          <tbody class="row mx-0">
            <tr *ngIf="studentGroup | customRepotCard: 'Name'" class="student-name col-lg-12 col-sm-12 px-0">
              <div class="designboxed" style="width:100%;"><b> Name:</b> {{ studentDetails[0].Name }}</div>
            </tr>
            <!-- <tr *ngIf="studentGroup | customRepotCard: 'Academic session name'" class="student-name">
              <td colspan="4"><b> Academic Sesssion:</b> {{ studentDetails[0].AcademicSessionName }}</td>
            </tr> -->
            <tr *ngIf="studentGroup | customRepotCard: 'Academic session name'" class="student-name col-lg-12 col-sm-12 px-0">
              <div class="designboxed">
                <b>Academic Session:</b> {{ studentDetails[0].AcademicSessionName }}
              </div>
            </tr>
            <tr class="col-lg-6 col-sm-12 p-0 d-flex">
              <div *ngIf="studentGroup | customRepotCard: 'Class'" class="designboxed" style="width: 25%">
                <b>Class</b>
              </div>
              <div *ngIf="studentGroup | customRepotCard: 'Class'"class="designboxed" style="width: 75%">{{ studentDetails[0].ClassName }}</div>
            </tr>
              <tr class="col-lg-6 col-sm-12 p-0 d-flex">
              <div *ngIf="studentGroup | customRepotCard: 'Section'"class="designboxed" style="width: 25%">
                <b>Section</b>
              </div>
              <div *ngIf="studentGroup | customRepotCard: 'Section'"class="designboxed" style="width: 75%">
                {{ studentDetails[0].SectionName }}
              </div>
            </tr>
            <tr class="col-lg-6 col-sm-12 p-0 d-flex">
              <div *ngIf="studentGroup | customRepotCard: 'Admission_no'" class="designboxed" style="width: 25%">
                <b>Admission No</b>
              </div>
              <div *ngIf="studentGroup | customRepotCard: 'Admission_no'" class="designboxed" style="width: 75%">
                {{ studentDetails[0].AdmissionNumber }}
              </div>
            </tr>
            <ng-container *ngFor="let item of studentCustomGroup">
              <tr class="col-lg-6 col-sm-12 p-0 d-flex" *ngIf="item | customRepotCard: 'studentCustomFields' ">
                  <div style="width: 25%"  class="designboxed" >
                      <b>{{item.label}}:</b>
                  </div>
                  <div style="width: 75%" class="designboxed">
                      {{ studentDetails[0][item.fName] }}
                  </div>
              </tr>
            </ng-container>
            <!-- <div *ngFor="let item of studentCustomGroup">
                <p *ngIf="item | customRepotCard: 'studentCustomFields' ">
                    {{item.label}}: {{ studentDetails[0][item.fName] }}
                </p>
            </div> -->
            
          </tbody>
        </table>
        <div class="clearfix"></div>
      </div>
      <!-- Report card Student Information -->
      <!-- Report card Assessment Score Information -->
      <div class="report-card-assessment-score-block">
        <!-- <before-scores-table></before-scores-table> -->
        <h4 style="margin-top: 5px"></h4>
        <div class="temp2-scores-block">
          <div class="cat-view-options">
            <table class="table table-bordered  table-vertical-center scores-table">
              <thead>
                <tr>
                  <th class="center">Subjects</th>
                  <th *ngFor="let item of assessmentName" class="center" colspan="3">{{ item.assessmentCategoryName }}</th>
                  <!-- <th class="center" colspan="3">Category 2</th>
                  <th class="center" colspan="3">Category 3</th> -->
                  <th class="center" colspan="2">Combined</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <th class="center">Core</th>
                    <ng-container *ngFor="let item of assessmentName">
                        <th class="center">Marks</th>
                        <th class="center">%</th>
                        <th class="center">Grade</th>
                    </ng-container>
                    <!-- <th class="center">Marks</th>
                    <th class="center">%</th>
                    <th class="center">Grade</th>
                    <th class="center">Marks</th>
                    <th class="center">%</th>
                    <th class="center">Grade</th> -->
                    <th class="center">% Marks</th>
                    <th class="center">Grade</th>
                </tr>

                <tr *ngFor="let item of assessmentDetails">
                    <td>{{ item.SubjectName }}</td>
                    <ng-container *ngFor="let items of assessmentName">
                        <!-- <td class="center">{{ item['catScore' + items.assessmentCategoryID] }} / {{ item['catTotal' + items.assessmentCategoryID] }}</td> -->
                        <td class="center">{{ item['catScore' + items.assessmentCategoryID] }} / {{ item['catTotal' + items.assessmentCategoryID] }}</td>
                        <td class="center">{{ item['cat' + items.assessmentCategoryID] }}</td>
                        <td class="center">-</td>
                    </ng-container>
                    <!-- <td class="center">-</td>
                    <td class="center">-</td>
                    <td class="center">-</td>
                    <td class="center">-</td>
                    <td class="center">-</td>
                    <td class="center">-</td> -->
                    <!-- Overall For subject -->
                    <!-- Overall for subject -->
                    <td class="center">{{ item.finalScore }}%</td>
                    <td class="center">-</td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
        <div class="clearfix"></div>
        <!-- Consolidate Score for grading period  -->
        <div class="clearfix"></div>

        <!-- Consolidate Score for grading period  -->
      </div>
      <!-- Report card Assessment Score Information -->
      <!-- Reportcard Extra block like Grading Scale attendance information and other information -->
      <div class="reportcard-extra-block page-break-avoid">
        <!-- Grading Scale information -->
        <div class="row">
          <div *ngIf="othersGroup | customRepotCard: 'Grading structure for final score'" class="col-md-6 overall-score-grading-block">
            <div class="block-border extra-block-sm">
              <h4 class="mb10">Grading structure for final score</h4>
              <div></div>
            </div>
          </div>
          <!-- Grading Scale information -->
          <div class="offset-1 col-md-5 attendance-checter-block">
            <!-- Attendance info table -->
            <div class="attendance-block">
              <table  *ngIf="attendanceGroup | customRepotCard: 'Display attendance'"  class="table  table-bordered text-center attendance-summary">
                <thead>
                  <tr>
                    <th class="center" style="border: 0px">Attendance</th>
                    <th class="center" style="border: 0px">
                      <!-- 0.00% -->
                    
                   
                  <span *ngIf="percentage"> 
                      {{percentage[0].percentage}} %
                  </span>
                    </th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of attendanceCustomGroup">
                  <tr *ngIf="item.checked && item.aLabel != ''">
                      <td [ngStyle]="{ color: item.colorCode }">{{ item.aLabel }}</td>
                      <td [ngStyle]="{ color: item.colorCode }" class="center">
                          <span *ngIf="attendanceGroup | customRepotCard: 'Display in numbers'">{{ item.total }}<span *ngIf="!(attendanceGroup | customRepotCard: 'Disable Max Status Count')">/{{ item.totalDays }}</span></span> <span *ngIf="attendanceGroup | customRepotCard: 'Display in percentage'"> ({{ item.percentage }}%)</span>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Attendance info table -->
            <!-- Other info table -->
            <div class="report-other-info-block mt-3">
              <table *ngIf="othersGroup | customRepotCard: 'Character Traits'" class="table table-bordered  table-vertical-center scores-table character-traits-table">
                <thead>
                  <tr>
                    <th>Character</th>
                    <th class="center">Remarks</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of characterTraitsGroup">
                  <tr *ngIf="item.checked && item.tLabel != ''">
                    <td>
                      {{ item.tLabel }}
                    </td>
                    <td>{{ studentDetails[0][item.fName] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Other info table -->
          </div>
          
        </div>
        <div class="clearfix"></div>
      </div>
      <!-- Reportcard Extra block like Grading Scale attendance information and other information -->
      <!-- Reportcard Footer block it contain reamarks-->
      <div class="reportcard-footer-block page-break-avoid">
        <div class="row">
          <div class="col-md-6 col-sm-6 report-signature-block">
            <div *ngIf="othersGroup | customRepotCard: 'Contents block'" class="signature-block">
              <h5>Class Teacher's Signature: ___________________</h5>
              <br />
              <h5>Parents Signature: ___________________</h5>
            </div>
          </div>
          <div class="offset-2 col-md-4 col-sm-6 student-progress-block">
            <table *ngIf="othersGroup | customRepotCard: 'Contents block'" class="table table-bordered progress-block">
              <tbody>
                <tr>
                  <td class="center" style="width: 50%">Progress</td>
                  <td class="center" style="width: 50%"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-12" style="margin-top: 35px">
            <table *ngIf="othersGroup | customRepotCard: 'Signature block'" class="table table-bordered">
              <thead>
                <tr>
                  <th class="center">School Stamp</th>
                  <th class="center">Principal's Signature</th>
                  <th class="center">Ministry</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div style="min-height: 50px"></div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Reportcard Footer block -->
    </div>
  </div>