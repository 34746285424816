
<div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Export {{title}} Data</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <p><a (click)="selectUnSelectAllExport(true)" class="tags underlinedLink">Select All</a> / <a (click)="selectUnSelectAllExport(false)"
          class="tags underlinedLink">Unselect All</a>
      </p>
      <form [formGroup]="exportForm" (ngSubmit)="downloadExportFile($event)">
        <div class="row-flex">
          <div *ngFor="let item of columnListForExport" class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" *ngIf="item.fieldName != 'Academic Sessions'" [formControlName]="item.fieldName">
              {{item.fieldName}}
            </mat-checkbox>
            <div class="fluid-container">
              <mat-checkbox class="example-margin" (change)="toggle($event)" *ngIf="item.fieldName === 'Academic Sessions'" [formControlName]="item.fieldName">
                {{item.fieldName}}
              </mat-checkbox>
              <div class="col" *ngIf="item.fieldName === 'Academic Sessions'">
                  <mat-form-field *ngIf="isEnrolled === true" class="w-100" appearance="outline">
                    <mat-label class="extralabel" for="AcademicSession"></mat-label>
                    <mat-select
                    matNativeControl
                    (selectionChange)="changeAcademicDropdownValue($event.value)"
                    placeholder="Select"
                    name="AcademicSession"
                    id="AcademicSession"
                    required
                  >
                  <mat-option [value]="session.sessionID" *ngFor="let session of sessionList">
                    {{ session.sessionName }}
                  </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group17 text-right">
          <button type="submit"  class="btn btn-primary btn-sm"
            >Export</button>
        </div>
      </form>

    </mat-dialog-content>
  </div>

