import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ToastrService } from 'ngx-toastr';
import { StudentService } from 'src/app/services/student.service';
import { COMMA, ENTER, T } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { HtmlEditorService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { isThisISOWeek } from 'date-fns';



@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css'],
  providers: [ToolbarService, HtmlEditorService]
})
export class ActivitiesComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  // fruits: string[] = ['Lemon'];
  // allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  fruits: string[] = [];
  allFruits: string[] = [''];

  @ViewChild("text") text: ElementRef;
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

  myForm: FormGroup;
  FormPrivateNote: FormGroup;
  sendEmailForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  studentSMSCheck = true;
  parentSMSCheck = false;
  studentEmailCheck = true;
  parentEmailCheck = false;
  studentID: any
  cities: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  contactNumber: string;
  parentContact: string;
  wordCount: any;
  studentControl: FormControl;
  parentControl: FormControl;
  words: any = 0;
  SmsForm: FormGroup;
  sendas: string[] = ['test@test.com(Institute Email)', 'test@test.com(Your Email)'];
  receivers: string[] = ['Student Email(Email missing)', 'Parents Email(Email missing)'];
  studentParentContact: any;
  favoriteSeason: string
  formbind: any;
  formbind1: any;
  number: any
  getFieldAndGroupDto: any;
  firstName: string;
  lastName: string;
  studentContact: any
  parentContactCore: any
  studentEmail: any
  parentEmail: any
  coutryCode: any
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'CreateTable', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'ClearFormat', 'Print', 'SourceCode', 'InsertCode', '|',
      'Undo', 'Redo', '|',
      'FullScreen', 'Preview'
    ]
  };

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private studentService: StudentService,
    private studenService: StudentService,
    private toaster: ToastrService,) {
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allFruits.slice())),
    );
  } add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
    }

    // Clear the input value
    // event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }
  removedTagName: any = []
  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.removedTagName.push(fruit)
      this.fruits.splice(index, 1);
    }
  }
  selectedtagName: any = []
  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.selectedtagName.push(event.option.viewValue)

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }




  user: any
  orgID: any;
  userEmail: any;
  ngOnDestroy(): void {
    sessionStorage.setItem('Loader', '1');
  }
  ngOnInit(): void {
    ////debugger;
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? null : params.id;
      this.SmsForm = this.fb.group({
        message: ['', Validators.required],
        studentControl: true,
        parentCheckSMS: false
      }
      );
    });

    // this.studentService.GetStudentListById(this.studentID).subscribe((res: any) => {
    //   console.log(res.responseData)
    //   this.studentParentContact=res.responseData
    //   // this.contactNumber = res.responseData.contactNumber;
    //   // this.parentContact = res.responseData.parentContact;
    //   this.number=res.responseData
    //   let obj = {
    //     'contactNumber': res.responseData.contactNumber,
    //     'parentContact': res.responseData.parentContact,

    //   }
    //   this.receiversContact.push(obj);
    //   ////debugger;

    // });
    this.cities = [
      { item_id: 1, item_text: 'New Delhi' },
      { item_id: 2, item_text: 'Mumbai' },
      { item_id: 3, item_text: 'Bangalore' },
      { item_id: 4, item_text: 'Pune' },
      { item_id: 5, item_text: 'Chennai' },
      { item_id: 6, item_text: 'Navsari' }
    ];
    this.selectedItems = [{ item_id: 4, item_text: 'Pune' }, { item_id: 6, item_text: 'Navsari' }];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.studentID = params.id == undefined ? null : params.id;
    // });
    console.log(" this.studentID", this.studentID);
    this.GetStudentByIdList();
    this.GetCustomTemplateList();
    //   this.cities = [
    //     { item_id: 1, item_text: 'New Delhi' },
    //     { item_id: 2, item_text: 'Mumbai' },
    //     { item_id: 3, item_text: 'Bangalore' },
    //     { item_id: 4, item_text: 'Pune' },
    //     { item_id: 5, item_text: 'Chennai' },
    //     { item_id: 6, item_text: 'Navsari' }
    // ];
    // this.selectedItems = [{ item_id: 4, item_text: 'Pune' }, { item_id: 6, item_text: 'Navsari' }];
    // this.dropdownSettings = {
    //     singleSelection: false,
    //     idField: 'item_id',
    //     textField: 'item_text',
    //     selectAllText: 'Select All',
    //     unSelectAllText: 'UnSelect All',
    //     itemsShowLimit: 3,
    //     allowSearchFilter: this.ShowFilter
    // };

    this.myForm = this.fb.group({
      review: ['', Validators.required],
      tagName: ['']
    });

    this.sendEmailForm = this.fb.group({
      studentEmailCheck: true,
      parentCheck: false,
      fromEmail: [],
      subject: ['', Validators.required],
      body: ['', Validators.required],
      template: new FormControl(),
      senderEmail: true,
    });

    this.FormPrivateNote = this.fb.group({
      PrivateNote: ['', Validators.required],
      note: ['', Validators.required],
    });
    this.sendEmailForm.setErrors({ required: true });
    this.sendEmailForm.valueChanges.subscribe((newValue) => {
      if (newValue.studentEmailCheck === true || newValue.parentCheck === true) {
        this.sendEmailForm.setErrors(null);
      } else {
        this.sendEmailForm.setErrors({ required: true });
      }
    });



    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    this.orgID = currentUser.orgID;
    this.userEmail = currentUser.email
    this.GetStudentByID();
    this.selectChangeTemplate(6)
  }


  GetStudentByID() {
    this.studentService.GetdynamicStudentByID(this.studentID).subscribe((res: any) => {
      let studentdetail = res.responseData.coreStudentDetails;
      console.log('student complete detail', studentdetail)
      this.getFieldAndGroupDto = studentdetail.groups;
      console.warn('studentRecord', this.getFieldAndGroupDto);
      // if (studentdetail.profilePhoto!==undefined && studentdetail.profilePhoto!==null && studentdetail.profilePhoto!=='' ) {
      //   this.croppedImage = studentdetail.profilePhoto;
      // }
      this.getFieldAndGroupDto.forEach((grp: any) => {
        grp.fields = grp.fields.filter((f: any) => f.isUnpublished == false);
        grp.fields.forEach((f: any) => {
          if (f.fieldName == 'First Name') {
            this.firstName = f.fieldValue;
          }
          if (f.fieldName == 'Last Name') {
            this.lastName = f.fieldValue;
          }
          if (f.fieldName == 'Contact') {
            this.studentContact = f.fieldValue
          }
          if (f.fieldName == 'Parents Contact') {
            this.parentContactCore = res.responseData.coreStudentDetails.contactNumberCountryCode + f.fieldValue;
          }
          if (f.fieldName == 'Student Email') {
            this.studentEmail = f.fieldValue
          }
          if (f.fieldName == 'Parents Email') {
            this.parentEmail = f.fieldValue
          }

        })
      });
    });
    console.log('full name', this.firstName + this.lastName)
    console.log('sc', this.studentContact)
    console.log('pc', this.parentContactCore)
    console.log('se', this.studentEmail)
    console.log('pe', this.parentEmail)
  }

  @ViewChild('Emailform') Emailform: any;
  onSubmitEmail(Emailform: any) {
    this.show = false;
    // if (this.sendEmailForm.invalid) {
    //   return;
    // }
    ////debugger;
    console.log(this.sendEmailForm.value);
    let arr: any = [];

    if (this.sendEmailForm.controls.studentEmailCheck.value) {
      arr.push(this.studentEmail)
    }
    if (this.sendEmailForm.controls.parentCheck.value) {
      arr.push(this.parentEmail)
    }

    let email: any;
    if (this.sendEmailForm.controls.senderEmail.value) {
      email = 'info@creocms.com.au'
    }
    else {
      email = this.userEmail
    }


    this.formbind = {
      'toEmailMutiple': arr,
      'subject': this.sendEmailForm.controls.subject.value,
      'body': this.sendEmailForm.controls.body.value,
      'organizationID': this.orgID,
      'fromEmail': email,
      'studentID': this.studentID,
    }

    sessionStorage.setItem('Loader', '0');
    this.studenService.sendEmail(this.formbind).subscribe((res: any) => {
      if (res.status > 0) {

        this.toaster.success(res.message);
        //this.sendEmailForm.reset();
        this.sendEmailForm.patchValue({
          subject: ''
        })
        this.show = true;
        this.Emailform.resetForm()
        this.sendEmailForm.controls.senderEmail.setValue(true);
        this.sendEmailForm.patchValue({
          studentEmailCheck: true,
          body: this.body
        })
        this.studentEmailCheck = true;
        this.parentEmailCheck = false;
      }
      else {
        this.toaster.error(res.message);
      }
    });
    console.log('final', this.formbind);

  }
  reset() {
    this.ngOnInit();
    this.sendEmailForm.reset();
  }


  studentData: any
  studentId: any
  studentName: any
  GetStudentByIdList() {
    this.studenService.GetStudentListById(this.studentID).subscribe((res: any) => {
      this.studentData = res.responseData
      console.log("this.studentData", this.studentData);
      this.studentName = this.studentData.firstName + ' ' + this.studentData.lastName;
    });
  }

  selectedTemplate: any;
  EmailList: any;
  body: any
  selectChangeTemplate(event: any) {
    //this.selectedTemplate = event.target.value;
    this.studenService.GetEmailTemplateById(this.sendEmailForm.controls.template.value === null ? 6 : this.sendEmailForm.controls.template.value).subscribe((tempRes: any) => {
      this.body = tempRes["responseData"].emailBody
      this.sendEmailForm.patchValue({
        'body': tempRes["responseData"].emailBody
      });
    });
  }
  customtemplate: any[] = []
  GetCustomTemplateList() {
    this.studenService.GetCuastomEmailTemplate().subscribe((res: any) => {
      console.log("CustomeEmailTemplateList", res.responseData)
      this.customtemplate = res.responseData;
    });
  }


  onStudentEmailChange() { }
  onparentChange() { }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }
  wordCounter() {
    //this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
    this.wordCount = this.text ? this.text.nativeElement.value : 0;
    this.words = this.wordCount ? this.wordCount.length : 0;

  }
  @ViewChild('SMSform') SMSform: any;
  SendSMS(SMSform: any) {
    ////debugger;
    this.show = false;
    console.log(this.SmsForm.controls.studentControl.value)
    console.log(this.SmsForm.controls.parentCheckSMS.value)
    console.log(this.SmsForm.controls.message.value)
    let contactToSendSMS: any = []
    if (this.SmsForm.controls.studentControl.value) {
      contactToSendSMS.push(this.studentContact)
    }
    if (this.SmsForm.controls.parentCheckSMS.value) {
      contactToSendSMS.push(this.parentContactCore)
    }
    this.formbind1 = {
      'MultipleMobileNo': contactToSendSMS,
      'Message': this.SmsForm.controls.message.value,
      'senderNumber': '+13156607071',
    }
    sessionStorage.setItem('Loader', '0');
    this.studenService.SentSmsMaster(this.formbind1).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.show = true;
        this.SMSform.resetForm();
        this.studentSMSCheck = true
        this.parentSMSCheck = false;
        this.SmsForm.patchValue({
          studentControl: true
        })
      }
      else {
        this.toaster.error(res.message);
      }
    });

  }
  @ViewChild('form') form: any;
  PrivateNote(form: any) {
    this.show = false;
    console.log(this.FormPrivateNote.controls.PrivateNote.value)
    console.log(this.FormPrivateNote.controls.note.value)
    let NoteObj = {
      'PrivateNoteSubject': this.FormPrivateNote.controls.PrivateNote.value,
      'Note': this.FormPrivateNote.controls.note.value,
      'StudentID': this.studentID

    }
    sessionStorage.setItem('Loader', '0');
    this.studenService.SendPrivateNote(NoteObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.show = true;
        this.form.resetForm();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  EnterEventTagName: any = []
  onEnter(event: any) {
    console.log("Enter Button Event", event)
    this.EnterEventTagName.push(((event.target).value));

  }
  arrTagName: any = []
  show = true;
  Review() {
    this.show = false;
    console.log(this.myForm.controls.review.value)
    console.log(this.myForm.controls.tagName.value)
    let finaltagName = this.EnterEventTagName
    for (let i = 0; i < this.selectedtagName.length; i++) {
      finaltagName.push(this.selectedtagName[i])

    }
    let res = finaltagName.filter((item: any) => !this.removedTagName.includes(item));
    console.log(res);
    let ReviewObj = {
      'Review': this.myForm.controls.review.value,
      'TagName': res,
      'StudentID': this.studentID


    }


    // let t = this.myForm.controls.review.value.trim();
    // let s = t.replace(/\s/g, "");
    //  console.log(s)

    let t = this.myForm.controls.review.value.trim()
    console.log(t.length);
    sessionStorage.setItem('Loader', '0');
    this.studenService.SendReview(ReviewObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.show = true;
        this.myForm.reset();
      }
      else {
        this.toaster.error(res.message);
      }
    });










  }





  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }


}
