import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { DynamicFormModule, environment, masters, profile, Student, Teacher } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeachersService {

  constructor(private http: HttpClient) { }

  AddTeacher(data: any) {
    ////debugger;
    return this.http.post(`${environment.baseUrl}${Teacher.addTeacherAPI}`, data);
  }
  GetTeacherList(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.getTeacherList}`, data);
  }
  UpdateTeacherProfilePhoto(Id: any, fileToUpload: File) {
    let formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      //'Content-Type': 'application/json',
      //'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem('AccessToken')}`,
    });
    return this.http.post(`${environment.baseUrl}${Teacher.UpdateTeacherProfileImage}${Id}`, formData);
  }

  DeleteTeacher(Id: any) {
    return this.http.get(`${environment.baseUrl}${Teacher.deleteTeacher}` + '?id=' + Id);
  }

  UpdateTeacher(data: any) {

    return this.http.post(`${environment.baseUrl}${Teacher.updateTeacher}`, data);
  }

  GetTeacherById(Id: any) {
    return this.http.get(`${environment.baseUrl}${Teacher.getTeachertByID}${Id}`);
  }


  GetTeacherListById(id: any) {
    return this.http.get(`${environment.baseUrl}${Teacher.getTeacherDetailsByIdAPI}${'?id=' + id}`);
  }

  updateTeacherCreditial(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.updateCreditial}`, data);
  }
  GetTeacherDetailsById(id: any) {
    return this.http.get(`${environment.baseUrl}${Teacher.getTeacherByIdData}${'?id=' + id}`);
  }

  AddProfilePhoto(data: any) {

    return this.http.post(`${environment.baseUrl}${profile.getProfilePhoto}`, data);
  }
  GetTeacherTablecolumn(id: any) {
    return this.http.get(`${environment.baseUrl}${Teacher.getTeacherTableColumn}${'?id=' + id}`);
  }
  UpdateTeacherTable(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.updateTeacherTableColumn}`, data)
  }
  DeleteMultipleTeacher(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.deleteMultipleTeacher}`, data)
  }
  GetAcademicSessionDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getAcademicSessionDropdown}`);
  }
  GetElectiveSubjectDropDown() {
    return this.http.get(`${environment.baseUrl}${masters.getEnrollmentElectiveSubjectDropDown}`);
  }

  downloadFile(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.downloadfile}` + '?filepath=' + id);
  }
  deleteAttachmentDetailsId(id: any) {
    return this.http.get(`${environment.baseUrl}${masters.deleteStaffAttachById}` + '?id=' + id);
  }
  GetTeacherByIdData(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.getTeacherDataByIdList}`, data);
  }
  GetTeachersDatabyIdList(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.getTeacherDataByIdList}`, data);
  }

  postTeacherDocumentDetails(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.postTeacherDocumentDetails}`, data);
  }

  postTeacherDocData<T>(data: {}): Observable<any> {
    let url = Teacher.uploadTeacherDocument;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }


  // common post method for all http requests
  // tslint:disable-next-line
  postData<T>(data: {}): Observable<any> {
    let url = profile.getProfilePhoto;
    let newHTTPParams = new HttpParams();
    // if (reqAPIParams != null) {
    //   reqAPIParams.forEach(element => {
    //     newHTTPParams = newHTTPParams.append(element.Key, element.Value);
    //   });
    // }
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }


  GetTeacherAttachList(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.getStaffAttachList}`, data);
  }
  getAttachmentDetailsId(id: any) {
    return this.http.get(`${environment.baseUrl}${masters.getStaffAttachById}` + '?id=' + id);
  }

  // attach base url
  private getUrl(url: string): string {
    return environment.baseUrl + url;
  }
  // common error handling method
  public handleError = (error: Response) => {
    // Do messaging and error handling here
    //this.errorhandler.handleError(error.status);
    return observableThrowError(error);
  };


  ImportTeachers(fileToUpload: any) {
    ////debugger;
    let input = new FormData();
    input.append("files", fileToUpload);
    return this.http.post(`${environment.baseUrl}${Teacher.importTeachers}`, input);
  }
  getSubjectwithClassandSection() {
    return this.http.get(`${environment.baseUrl}${Teacher.getSubjectwithClassAndSection}`);
  }
  subjectAllocationAPI(data: any) {
    return this.http.post(`${environment.baseUrl}${Teacher.subjectAllocationAPI}`, data)
  }
  getTeacherTableList(data: any) {
    return this.http.post(
      `${environment.baseUrl}${DynamicFormModule.getTeacherTableList}`, data
    );
  }
  addTeacherShowHidecolumn(data: any) {
    return this.http.post(
      `${environment.baseUrl}${DynamicFormModule.addShowHideDynamicTeacherList}`, data
    );
  }
  addCoreTeacher(data: any) {
    return this.http.post(
      `${environment.baseUrl}${Teacher.createcoreTeacher}`, data
    );
  }
  getTeacherById(id: any) {
    return this.http.get(
      `${environment.baseUrl}${Teacher.GetDynamicFormDetailsByTeacherID}${'?id=' + id}`
    );
  }
  updateCoreTeacher(data: any) {
    return this.http.post(
      `${environment.baseUrl}${Teacher.updatecoreTeacher}`, data
    );
  }
  getDynamicTeacherById(id: any) {
    return this.http.get(
      `${environment.baseUrl}${Teacher.getcoreTeacherDetailById}${'?id=' + id}`
    );
  }
  deleteDynamicTeacher(data: any) {
    return this.http.post(
      `${environment.baseUrl}${Teacher.deletecoreTeacher}`, data
    );
  }

  DownloadTemplate(): Observable<Blob> {
    return this.http.get(`${environment.baseUrl}${Teacher.downloadCsvForImport}`, { responseType: 'blob' });
  }
  getTeachersFieldsForDropdownOptions() {
    return this.http.get(`${environment.baseUrl}${Teacher.getTeacherFields}`);
  }

  ImportExcelFile(fileToUpload: File, mapping: Object, isUpdateExisting: string) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('mapping', JSON.stringify(mapping));
    formData.append('IsUpdateExisting', isUpdateExisting);
    return this.http.post(`${environment.baseUrl}${Teacher.importTeacher}`, formData);
  }
  ImportStatusReport(importProcessId: number) {
    return `${environment.baseUrl}${Teacher.getStatusReport}${'?id=' + importProcessId}`
  }
  getStatusReport(link: number): Observable<Blob> {
    return this.http.get(this.ImportStatusReport(link), { responseType: 'blob' });
  }

}

