<!-- <i class="material-icons popupClose" mat-dialog-close>
    <button class="material-icons popupClose" mat-button (click)="onNoClick()">X</button>
    </i> -->
    <div class="warning">
 <i style="cursor: pointer;" class="material-icons popupClose" (click)="onNoClick()" mat-dialog-close>clear</i>
 <div >
    <b>Note:</b> {{data}} 
    <a  routerLink="/admin/settings/subscription" (click)="onNoClick()"><b><u style="cursor: pointer;">Upgrade now</u></b></a>
</div>
</div>

