<div class="container container1580">
        <div class="headingcontent">
          <div class="pageTitle mt-0">
            <h2 *ngIf="updateText; else elseTemplate">Update Attachment</h2>
 
            <ng-template #elseTemplate>
                <h2 mat-dialog-title>Add Attachment</h2>
            </ng-template> 
            <div class="breadcrumbWidget">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/admin"><i
                      class="fas fa-home"></i> Home</a>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-students">Manage Student</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Attachment</li>
              </ol>
            </div>
          </div>
        </div>

    <div class="cardWidget">
        <div class="matCard">

            <div class="profileDetailsWidget">
                <div>
                    <form [formGroup]="form">

                        <div class="profileContentRight flex-grow-1">

                            <div class="proSection">

                                <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Title </mat-label>
                                            <input maxlength="100" matInput placeholder="Title" required name="title"
                                                formControlName="title" pattern="^[^\s]+(\s+[^\s]+)*$">
                                            <mat-error
                                                *ngIf="form.get('title')?.touched  && form.get('title')?.errors?.required">
                                                Title is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('title')?.hasError('pattern')">
                                                Please enter valid title
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Description </mat-label>
                                            <textarea required maxlength="256" formControlName="description" matInput
                                                placeholder="Description" pattern="^[^\s]+(\s+[^\s]+)*$"></textarea>
                                            <mat-error
                                                *ngIf="form.get('description')?.touched  && form.get('description')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('description')?.hasError('pattern')">
                                                Please enter valid description
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-group">
                                        <input type="file" (change)="uploadFile($event)" name="filename">
                                    </div>


                                </div>
                            </div>
                          
                                <div class="col-12 text-right">
                                    <button type="submit" [disabled]="form.invalid" class="btn btn-primary btn-sm"  (click)="onSubmit($event)"> 
                                        <span *ngIf="updateText">Update <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                                            <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                                              <path id="Path" d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z" transform="translate(-3 -5.95)" fill="#fff"/>
                                            </g>
                                          </svg>
                            </span><span *ngIf="!updateText">Submit <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                                            <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                                              <path id="Path" d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z" transform="translate(-3 -5.95)" fill="#fff"/>
                                            </g>
                                          </svg>
                            </span></button>
                                </div>
                            
                           
                         
                           
                        </div>

                    </form>
                </div>
            </div>
        </div>
        <main>
            <div class="pageTitle">
            </div>
            <div class="container-fluid">
                <div class="matCard">
                    <!-- <div class="cardTitle centerVTItems justify-content-between">
                    <h3>Manage Attachment</h3>
                    <button class="btn btn-primary btnsmall" (click)="goto()">
                      Back</button>
                  </div> -->
                    <div class="tableActionTop mb-3">
                        

                        <div class="tableFilter">
                            
                          
                            <div class="filterForm" [class.toggled]="classToggled">
                                <form [formGroup]="searchingForm" (ngSubmit)="onSearchingSubmit()">
                                    <div class="row">
                                        <!-- <div class="col s12 m6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search Student Name</mat-label>
                                                <input matInput type="text" formControlName="studentName">
                                            </mat-form-field>
                                        </div> -->
                                        <div class="col s12 m6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search Document Name</mat-label>
                                                <input matInput type="text" formControlName="documentName">
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m6">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search Title Name</mat-label>
                                                <input matInput type="text" formControlName="title">
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    
                                    <div class="btnsWidget form-group">
                                        <button type="submit" class="btn btn-primary btn-sm">Search</button>
                                        <button type="button" (click)="reset()"
                                            class="btn btn-reset btn-sm">Reset</button>

                                    </div>
                                
                                </form>
                            </div>
                        </div>
                        <div class="text-right">
                            <!-- <a (click)="toggleField()" matTooltip="Filter"><i class="fas fa-filter"></i> <span
                                class="mobileHidden"></span></a> -->
                                <div class="iconsset">
                                    
                                    <div class="viewColumns tableActions" >
                                        <i class="fas fa-filter cursor-pointer" (click)="toggleField()" matTooltip="Filter"></i>
                                        <span class="mobileHidden cursor-pointer" (click)="toggleField()"></span>
                                    </div>
                                </div>
                        </div>
                    </div>



                    <app-data-table [inputColumns]="displayedColumns" [inputSource]="attchData" [inputMeta]="metaData"
                        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
                        (onTableActionClick)="onTableActionClick($event)">
                    </app-data-table>
                </div>


            </div>

        </main>
    </div>
</div>