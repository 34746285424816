<main>
  <div class="pageTitle">
    <!-- <h2>Manage Students</h2>
        <div class="breadcrumbWidget">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="../../dashboard">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="page">Manage Students</li>
          </ol>
        </div> -->
  </div>
  <div class="container-fluid">
    <div class="matCard">
      <div class="cardTitle centerVTItems justify-content-between">
        <h3>Manage Attachment</h3>
        <button class="btn btn-primary btnsmall" (click)="goto()">
          Back</button>
      </div>
      <div class="tableActionTop mb-3">

        <div class="tableFilter">
          <a (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden">Filter</span></a>
          <div class="filterForm" [class.toggled]="classToggled">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col s12 m6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Search Teacher Name</mat-label>
                    <input matInput type="text" formControlName="teacherName">
                  </mat-form-field>
                </div>
                <div class="col s12 m6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Search Document Name</mat-label>
                    <input matInput type="text" formControlName="title">
                  </mat-form-field>
                </div>

              </div>
              <div class="btnsWidget form-group">
                <button type="submit" class="btn btn-primary btn-sm">Search</button>
                <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>

              </div>
            </form>
          </div>
        </div>
      </div>



      <app-data-table [inputColumns]="displayedColumns" [inputSource]="attchData" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-data-table>
    </div>


  </div>

</main>