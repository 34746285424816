import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { FormInputBase } from '../../MasterModel/masterModel';

@Component({
  selector: 'app-map-excel-input',
  templateUrl: './map-excel-input.component.html',
  styleUrls: ['./map-excel-input.component.css'],
})
export class MapExcelInputComponent {
  @Input()
  inputControl!: FormInputBase<string>;
  @Input()
  form!: FormGroup;
  isValid(field:string) {
    return this.form.controls[field].hasError('required');
  }
  
}
