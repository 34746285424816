import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, Subject } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  constructor(private http : HttpClient) { }

  AddSubject(data:any) {
    return this.http.post(`${environment.baseUrl}${Subject.addSubjectAPI}`,data);
  }

  AllSubject() {
    return this.http.get(`${environment.baseUrl}${Subject.getAllSubjectAPI}`);
  }

  AllSubjectsList() {
    return this.http.get(`${environment.baseUrl}${Subject.getAllSubjectListAPI}`);
  }
  
  GetSubjectById(Id:any) {
     return this.http.get(`${environment.baseUrl}${Subject.getSubjectByIdAPI}${'?subjectID=' + Id}`);
  }

  EditSubject(data:any) {
    return this.http.post(`${environment.baseUrl}${Subject.EditSubjectByIdAPI}`,data);
  }

  DeleteSubject(Id:any) {
    return this.http.get(`${environment.baseUrl}${Subject.DeleteSubjectByIdAPI}${'?id=' + Id}`);
  }

  GetSubjectBySectionId(Id:any) {
    return this.http.get(`${environment.baseUrl}${Subject.getSubjectBySectionIdAPI}${'?sectionID=' + Id}`);
  }

  GetSubjectByClassId(Id:any) {
    return this.http.get(`${environment.baseUrl}${Subject.getSubjectByClassIDOrSectionIdOrSubjectAPI}${'?classID=' + Id}`);
  }
  GetSubjectByTeacherId(Id:any) {
    return this.http.get(`${environment.baseUrl}${Subject.getSubjectByTeacherID}`);
  }


}
