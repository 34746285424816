<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <!-- <h2>{{firstName}} {{lastName}}</h2> -->
      <h2>Manage Roles</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Manage Roles</li>
        </ol>
      </div>
    </div>
    <div class="">
      <div class="add_button">
        <button class="addbtn btn" matRipple routerLink="../manage-roles/add-roles"> <i class="fas fa-plus-circle"></i>
          Add Role </button>
      </div>
    </div>
  </div>
  <div class="cardWidget">

    <form [formGroup]="filterForm" (ngSubmit)="roleFilterData()">
      

      <div class="iconsset d-flex justify-content-end">
        <div class="viewColumns tableActions" matTooltip="Filter">
          <i title="Filter Student" class="fas fa-filter cursor-pointer" (click)="toggleField()"></i>
          <span class="mobileHidden cursor-pointer" (click)="toggleField()"></span>
        </div>

      </div>
      <div class="filterForm" [class.toggled]="classToggled">
        <div class="row mb-2">
          
          <div class="col-lg-3 col-sm-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select Role</mat-label>
              <input type="text" matInput
                formControlName="roleName" [matAutocomplete]="classauto">
              <mat-autocomplete #classauto="matAutocomplete" [displayWith]="displayFn"
                >
                <mat-option *ngFor="let class of roleList" [value]="class">
                  {{class.roleName}}
                </mat-option>
                <mat-option *ngIf="roleFlag==true" disabled>
                  No Role Found
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!-- <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select Events</mat-label>
              <mat-select placeholder="Select Events" >
                <mat-option value="allEvent">All Events</mat-option>
                <mat-option value="participating">Participating</mat-option>
                <mat-option value="other">Other</mat-option>
              </mat-select>
              <mat-error>
                Please Select Events</mat-error>
            </mat-form-field> -->
            <!-- formControlName="Select Events" -->
          </div>
         
          <!-- <div class="col-lg-3 col-sm-12"></div> -->
          <div class="btnsWidget form-group text-right">
            <button type="submit" class="btn btn-primary btn-sm" >Search</button>
            <button type="button" (click)="resetFilter()" class="btn btn-reset btn-sm">Reset</button>
          </div>
          <!-- <div class="col-lg-3 col-sm-12 text-right">
            <button type="submit" class="btn btn-primary w-100" matRipple>Search <svg
                xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                  <path id="Path"
                    d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                    transform="translate(-3 -5.95)" fill="#fff" />
                </g>
              </svg>
            </button>
          </div> -->
        </div>
      </div>
    </form>
    <!-- <div class="cardTitle centerVTItems justify-content-between">
        <h3>Roles</h3>
      
      </div> -->
    <div class="matCard">
      <!-- <div class="text-right">
        <button class="btn btn-primary btn-sm" ><i class="fas fa-plus"></i>
          Add New</button>
        </div> -->

      <div class="matCrdTitle centerItemVert justify-content-between">
        <div class="tableActionTop">
          <div class="rightTableActions centerItemvert">
          </div>
        </div>
      </div>

      <div class="matCardBody">
        <div class="tableActionTop">
          <div class="tableFilter">
          </div>
          <div class="rightTableActions centerItemVert">
          </div>

        </div>
        <div class="mat-elevation-z8">

        </div>
      </div>
      <app-data-table [inputColumns]="displayedColumns" [inputSource]="usersData" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-data-table>
    </div>
  </div>
</div>