import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { Metadata, StudentInvoiceMeta } from 'src/app/common/MasterModel/masterModel';
import { DialogService } from 'src/app/services/dialog.service';
import { FinanceAndAccountService } from 'src/app/services/finance-and-account.service';
import { StudentService } from 'src/app/services/student.service';

export interface PeriodicElement {
}
const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-fees-and-invoicing',
  templateUrl: './fees-and-invoicing.component.html',
  styleUrls: ['./fees-and-invoicing.component.css']
})

export class FeesAndInvoicingComponent implements OnInit {
  displayedColumns: string[] = ['Invoice ID', 'Fee Name', 'Fees Amount', 'Discount', 'Paid Amount', 'Status', 'Invoice Date', 'Due Date', 'Actions'];
  dataSource = ELEMENT_DATA;
  filterModel: any;
  metaData = new Metadata();
  users:any;
  dateFormat:any
  constructor(
    private activatedRoute: ActivatedRoute,
    private studentService: StudentService,
    private dialogService: DialogService,
    private paymentService: FinanceAndAccountService,
    private toaster: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
  ) { 
    this.filterModel = new StudentInvoiceMeta();
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.currency = currentUser.currency;
    this.dateFormat = currentUser.dateFormat;
   }
  currency:any;
  studentID: any;
  paidAmount:"0.00";
  unPaidAmount:"0.00";
  filterForm: FormGroup;
  statusList:any;
  feesNameList:any
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? null : params.id;
      this.filterModel.studentId = this.studentID;
      this.filterModel.recurring = null
    });
    this.filterForm = this.formBuilder.group({
      invoiceId:  [''],
      statusId:  [''],
      studentId:  this.studentID,
      feeName:  [''],
      startDate: [''],
      endDate:  [''],
      recurring: [''],
    })
    this.getInvoiceFeeslist();
    this.GetStudentByIdList();
    this.getPaidAndUnpaidAmount();
    this.getMasterData();
  }
  getMasterData(){
    this.paymentService.getInvoiceStatusList().subscribe((res:any)=>{
      this.statusList = res.responseData;
    })
    this.paymentService.getFeesName().subscribe((res:any)=>{
      this.feesNameList = res.responseData;
    })
  }
  getInvoiceFeeslist() {
    console.log("FILTER",this.filterModel)
    this.studentService.getAllInvoicesByStudentId(this.filterModel).subscribe((res: any) => {
      this.dataSource = res.responseData;
      this.metaData = res.meta;
    })
  }
  exportCSV() {
    let data: any[] = [];
    //data = this.dataSource.filter(x => this.selectedId.some(z => z == x.invoiceID));
    this.commonService.exportToCsv(this.dataSource, 'invoice_Report', ['invoiceID', 'feeName', 'payer', 'feeAmount', 'discount', 'paidAmount', 'status', 'invoiceDate', 'dueDate', 'informed']);
  }
  studentName: any;
  GetStudentByIdList() {
    this.studentService.GetStudentListById(this.studentID).subscribe((res: any) => {
      this.studentName = res.responseData.firstName + ' ' + res.responseData.lastName;
    });
  }
  filterSubmit(){
    console.log("FILTERSUBMIT",this.filterForm.value)
    this.filterModel.invoiceId = this.filterForm.value.invoiceId;
    this.filterModel.statusId = this.filterForm.value.statusId;
    this.filterModel.studentId = this.studentID;
    this.filterModel.feeName = this.filterForm.value.feeName;
    this.filterModel.startDate = this.filterForm.value.startDate ? moment(this.filterForm.value.startDate).format('YYYY-MM-DD') : this.filterForm.value.startDate;
    this.filterModel.endDate = this.filterForm.value.endDate ? moment(this.filterForm.value.endDate).format('YYYY-MM-DD') : this.filterForm.value.endDate;
    this.filterModel.recurring = this.filterForm.value.recurring;
    this.getInvoiceFeeslist();
  }
  reset(){
    this.filterForm.reset();
    this.filterModel = new StudentInvoiceMeta();
    this.filterModel.studentId = this.studentID;
    this.getInvoiceFeeslist();

  }
  getPaidAndUnpaidAmount(){
    this.studentService.getStudentPaidAndUnPaidAmount(this.studentID).subscribe((res: any) => {
      if(res.responseData.length != 0){
        this.paidAmount = res.responseData[0].paidAmount;
        this.unPaidAmount = res.responseData[0].unPaidAmount
      }
    });
  }
  delObj: any
  onsingleDelete(id: any) {
    this.delObj = [JSON.stringify(id)]
    console.log("Onsingledelete", this.delObj)
    this.dialogService
      .confirm(`Are you sure, you want to delete this Invoice?`)
      .subscribe((result: any) => {
        if (result == true) {
          this.paymentService.deleteInvoicesByIdList(this.delObj).subscribe((res: any) => {
            if (res.status > 0) {
              this.toaster.success(res.message, 'Success');
              this.getInvoiceFeeslist();

            } else {
              this.toaster.error(res.message, 'Error');
            }
          })
        }
      });
  }
  viewInvoiceDetails(id: any) {
    this.router.navigate(["admin/administration/invoice-recipt"], { queryParams: { id: id } });
  }
  public handlePage(e: any) {
    this.filterModel.pageNumber = e.pageIndex + 1;
    this.filterModel.pageSize = e.pageSize;
    this.getInvoiceFeeslist();
  }
}
