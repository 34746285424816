<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>Salary Details</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage
                            Teachers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Salary Details</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
                <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-teachers">Back</button>
            </div>
        </div>
    </div>
    <div class="cardWidget">
        <app-teacherstabs></app-teacherstabs>
        <div class="cardBody">
            <div class="row" *ngIf="salaryFormDB.length != 0">
                <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-100">
                    <div class="col-lg-12 col-sm-12">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12" *ngFor="let salaryFormItem of salaryFormDB; let i = index">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>{{salaryFormItem.salaryFormFieldName}} </mat-label>
                                    <input type="number" [ngClass]="salaryFormItem.deductionType ? 'redd' : 'greenn'"
                                        (blur)="onBlurMethodtxt($event, salaryFormItem, salaryFormItem.salaryFormFieldValue)"
                                        [placeholder]="salaryFormItem.salaryFormFieldName" matInput
                                        formControlName="{{ salaryFormItem.salaryFormFieldID }}"
                                        oninput="validity.valid||(value = 0);">

                                    <!-- <mat-error *ngIf="salaryFormItem.salaryFormFieldValue == 0">
                                                    {{ salaryFormItem.salaryFormFieldName }} is required
                                                </mat-error> -->
                                    <mat-error
                                        *ngIf="form.get([salaryFormItem.salaryFormFieldID])?.touched  && form.get([salaryFormItem.salaryFormFieldID])?.errors?.required">
                                        {{ salaryFormItem.salaryFormFieldName }} is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <div class="text-right">
                                    <button class="btn btn-primary btn-sm" matRipple>Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12"></div>
                </form>
            </div>
            <div class="row" *ngIf="salaryBeforeDeduction > 0">
                <div class="col-lg-4 col-sm-12 text-center" >
                    <b>Gross Salary</b><h3><b>{{salaryBeforeDeduction}}</b></h3>
                </div>
                <div class="col-lg-4 col-sm-12 text-center">
                <b>Deduction Amount</b><h3><b>{{deductionAmount}}</b></h3>
                </div>
                <div class="col-lg-4 col-sm-12 text-center">
                <b>Net Salary</b><h3><b>{{salaryAfterDeduction}}</b></h3>
            </div>
        </div>
    </div>
</div>