import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, ManageAttendance } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageAttendanceService {

  constructor(private http: HttpClient) { }

  AddAttendanceStatus(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAttendance.addAttendanceStatus}`, data);
  }

  GetAllAttendanceStatus() {
    return this.http.get(`${environment.baseUrl}${ManageAttendance.getAllAttendanceStatus}`);
  }

  DeleteAttendanceStatus(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAttendance.deleteAttendanceStatus}` + '?id=' + Id);
  }

  DeleteAttendanceStatusById(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAttendance.deleteAttendanceStatusApi}` + '?id=' + Id);
  }


  UpdateAttendanceStatus(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAttendance.updateAttendanceStatus}`, data);
  }

  GetAttendanceStatusById(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAttendance.getAttendanceStatusById}` + '/' + Id);
  }
}
