import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Admin, environment, profile } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminStaffService {
  constructor(private http: HttpClient) { }

  AddAdmin(data: any) {
    return this.http.post(`${environment.baseUrl}${Admin.addAdminAPI}`, data);
  }
  GetAdminList(data: any) {
    return this.http.post(`${environment.baseUrl}${Admin.getAdminList}`, data);
  }
  UpdateAdminProfilePhoto(Id: any, fileToUpload: File) {
    let formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      //'Content-Type': 'application/json',
      //'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem('AccessToken')}`,
    });
    return this.http.post(`${environment.baseUrl}${Admin.UpdateAdminProfileImage}${Id}`, formData);
  }

  DeleteAdmin(Id: any) {
    return this.http.get(`${environment.baseUrl}${Admin.deleteAdmin}` + '?id=' + Id);
  }

  UpdateAdmin(data: any) {

    return this.http.post(`${environment.baseUrl}${Admin.updateAdmin}`, data);
  }

  GetAdminById(Id: any) {
    return this.http.get(`${environment.baseUrl}${Admin.getAdminByID}${Id}`);
  }


  GetAdminListById(id: any) {
    return this.http.get(`${environment.baseUrl}${Admin.getAdminDetailsByIdAPI}${'?id=' + id}`);
  }

  AddProfilePhoto(data: any) {

    return this.http.post(`${environment.baseUrl}${profile.getProfilePhoto}`, data);
  }

  GetAdminTablecolumn(id: any) {
    return this.http.get(`${environment.baseUrl}${Admin.getAdminTableCustomColumn}${'?id=' + id}`);
  }

  UpdateAdminTable(data: any) {
    return this.http.post(`${environment.baseUrl}${Admin.updateAdminTableColumn}`, data)
  }

  // common post method for all http requests
  // tslint:disable-next-line
  postData<T>(data: {}): Observable<any> {
    let url = profile.getProfilePhoto;
    let newHTTPParams = new HttpParams();
    // if (reqAPIParams != null) {
    //   reqAPIParams.forEach(element => {
    //     newHTTPParams = newHTTPParams.append(element.Key, element.Value);
    //   });
    // }
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  // attach base url
  private getUrl(url: string): string {
    return environment.baseUrl + url;
  }
  // common error handling method
  public handleError = (error: Response) => {
    // Do messaging and error handling here
    //this.errorhandler.handleError(error.status);
    return observableThrowError(error);
  };
  ImportAdmin(fileToUpload: any) {
    let input = new FormData();
    input.append("files", fileToUpload);
    return this.http.post(`${environment.baseUrl}${Admin.importAdmin}`, input);
  }
  sentAdminEmail(data: any) {
    return this.http.post(`${environment.baseUrl}${Admin.sentAdminEmail}`, data);
  }
  CheckDuplicateUserName(username: any) {
    return this.http.get(`${environment.baseUrl}${Admin.checkDuplicateUserName}` + '?username=' + username);
  }
}
