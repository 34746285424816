import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModuleDefinition } from 'src/app/common/MasterModel/masterModel';
import { environment, Master, Student, Teacher } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassDropdownService {

  constructor(private http : HttpClient) { }

  ClassDropdown() {
    return this.http.get(`${environment.baseUrl}${Master.classDropdownAPI}`);
  }
  getSubjectByClassandSectionId(data:any){
    return this.http.post(`${environment.baseUrl}${Teacher.getSujectbyclasssectionId}`, data)
  }
 
  getFieldsForDropdownOptions(caller: string){
    switch(caller){
      case ModuleDefinition.Student: { 
        return this.http.get(`${environment.baseUrl}${Student.getStudentFields}`);
        break;
    }
    case ModuleDefinition.Teacher: {
      return this.http.get(`${environment.baseUrl}${Teacher.getTeacherFields}`);
      break;
    }
  }
  return this.http.get(`${environment.baseUrl}${Student.getStudentFields}`);
  }
}
