import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../core-services/common.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  orgSubdomain: any;
  constructor(
    private loaderService: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private commonService: CommonService
  ) {
    const domain = window.location.hostname;
    let getLink = window.location.href;
    const { hostname } = new URL(getLink)
    const [subdomain] = hostname.split('.')
    this.orgSubdomain = subdomain
    if (domain == 'localhost' || domain == 'ms.stagingsdei.com' || domain == 'creolms.com.au') {
      this.orgSubdomain = 'ytp'
    }
    else {
      this.orgSubdomain = subdomain
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem('AccessToken');
    let isApiUrl = request.url.startsWith(environment.baseUrl);

    //Legasov Added March-17-2022
    // if (this.tokenExpired(String(token)) == true) {
    //   if (this.router.url != '/') {
    //     this.router.navigate(['/']);
    //     this.toaster.error('Your session expired', 'Error');
    //   }
    // }

    // else if(token === null){
    //   if (this.router.url != '/') {
    //     this.router.navigate(['/']);
    //     this.toaster.error('An error occurred', 'Error');
    //   }
    // }
    let ipAdd = String(localStorage.getItem('IpLocal'));
    if (isApiUrl) {
      request = request.clone({
        setHeaders: {
          subdomain: this.orgSubdomain,
          ipAddress: ipAdd
        },
      });
    }
    if (token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${sessionStorage.getItem('AccessToken')}`,
          //subdomain: this.orgSubdomain
        },
      });
    }

    this.showLoader();

    //next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            //console.log("event", event)
            // if (event.body.statusCode == 401) {
            //   if (this.router.url != '/admin/errorpage') {
            //     this.router.navigate(['/admin/errorpage']);
            //   }
            // }
            this.onEnd();
          }
        },
        // (err: any) => {
        //   console.error("Status", err.status);
        //   this.onEnd();
        // }
      )
    );
  }

  //   private handleAuthError(err: HttpErrorResponse): Observable<any> {
  //     //handle your auth error or rethrow
  //     if (err.status === 401 || err.status === 403) {
  //       console.log("handleAuthError", err)
  //         //navigate /delete cookies or whatever
  //         //this.router.navigateByUrl(`/login`);
  //         // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
  //         //return of(err.message); // or EMPTY may be appropriate here
  //     }
  //     return throwError(err);
  // }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    if (
      sessionStorage.getItem('Loader') &&
      sessionStorage.getItem('Loader') == '0'
    ) {
    } else {
      this.loaderService.show();
    }
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

  //Legasov Added March-17-2022
  private tokenExpired(token: string) {
    if (token != 'null') {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      return Math.floor(new Date().getTime() / 1000) >= expiry;
    } else {
      return false;
    }
  }
}
