<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>{{fullName}}</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage
                            Teachers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Class Schedule</li>
                </ol>
            </div>
        </div>
        <div class="">
          <div class="add_button">
            <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-teachers">Back</button>
          </div>
        </div>
    </div>
    <div class="cardWidget">
        <app-teacherstabs></app-teacherstabs>

            <div class="cardBody">
                <form [formGroup]="form">
                  <div class="row">
                    <div class="col-lg-5 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Academic Session</mat-label>
                        <mat-select formControlName="sessionID" 
                          placeholder="Select Session">
                          <mat-option value="" disabled>Select Academic Session
                          </mat-option>
                          <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
                            {{session.academicSessionName}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- <div class="col-lg-5 col-sm-12">
                      <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Select Class-Section</mat-label>
                        <mat-select formControlName="classSectionID" placeholder="Select Class-Section">
                          <mat-option value="" disabled>Select Class-Section
                          </mat-option>
                          <mat-option *ngFor="let classsection of classsectionList" [value]="classsection">
                            {{classsection.classSectionName}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div> -->
                    <div class="col-lg-2 col-sm-12">
                      <button class="btn btn-primary w-100" matRipple (click)="onSubmit()">
                        Find <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                          <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                            <path id="Path"
                              d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                              transform="translate(-3 -5.95)" fill="#fff" />
                          </g>
                        </svg>
          
          
                      </button>
                      <!-- <div class="btnsWidget">
                                  <button type="button" (click)="onSubmit()" class="btn btn-primary">Find</button>
                                </div> -->
                    </div>
                  </div>
                </form>
          
          
          
                <div class="row text-center align-items-center">
                  <div class="col-md-3">
          
                    <div class="btn-group w-100" role="group">
                      <button class="btn btn-outline-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                      (viewDateChange)="closeOpenMonthViewDay()" matRipple><i
                          class="fas fa-chevron-left"></i>
                        Previous</button>
                      <button class="btn btn-outline-primary"  mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                      (viewDateChange)="closeOpenMonthViewDay()" matRipple>Next <i
                          class="fas fa-chevron-right"></i></button>
                    </div>
          
          
                    
                    <!-- <div class="btn-group w-100"> -->
                      <!-- <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        Previous
                      </div> -->
                      <!-- <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                        Today
                      </div> -->
                      <!-- <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        Next
                      </div> -->
                    <!-- </div> -->
                  </div>
                  <!-- <div class="col-md-4 my-4 my-md-0">
                    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                  </div> -->
                  
                  <div class="col-md-5"><h5>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h5></div>
                  <div class="col-md-4">
                    <div class="btn-group w-100">
                      <div
                              class="btn btn-primary"
                              (click)="setView(CalendarView.Month)"
                              [class.active]="view === CalendarView.Month"
                            >
                              Month
                            </div>
                      <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                        [class.active]="view === CalendarView.Week">
                        Week
                      </div>
                      <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                        [class.active]="view === CalendarView.Day">
                        Day
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div [ngSwitch]="view" *ngIf="events">
                  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" 
                  [activeDayIsOpen]="activeDayIsOpen">
                  </mwl-calendar-month-view>
                  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" >
                  </mwl-calendar-week-view>
                  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh">
                  </mwl-calendar-day-view>
                </div>
          
          
                <!-- <div class="classScheduleWeek">
                  <div class="tabularWidget">
                    <mat-tab-group mat-align-tabs="start">
                      <mat-tab label="Mon">
                        <div class="scheduleList">
                          <div *ngIf="monShedule.length==0 && NoFoundsFlag">
                            <p>No record found</p>
                          </div>
                          <div *ngFor="let mon of monShedule" class="scheduleRow d-flex mb-1">
                            <div class="scheduleTime">
                              <span class="TimeFrom">{{mon.startTime}}</span>
                              <span class="TimeTo">{{mon.endTime}}</span>
                            </div>
                            <div class="scheduleSubjects flex-grow-1">
                              <h3>{{mon.title}}</h3>
                              <p>{{mon.subject}} ({{mon.class}} - {{mon.section}})</p>
                              <p>Teachers - {{mon.teacher}}</p>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                      <mat-tab label="Tue">
                        <div class="scheduleList">
                          <div *ngIf="tueShedule.length==0 && NoFoundsFlag">
                            <p>No record found</p>
                          </div>
                          <div *ngFor="let tue of tueShedule" class="scheduleRow d-flex mb-1">
                            <div class="scheduleTime">
                              <span class="TimeFrom">{{tue.startTime}}</span>
                              <span class="TimeTo">{{tue.endTime}}</span>
                            </div>
                            <div class="scheduleSubjects flex-grow-1">
                              <h3>{{tue.title}}</h3>
                              <p>{{tue.subject}} ({{tue.class}} - {{tue.section}})</p>
                              <p>Teachers - {{tue.teacher}}</p>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                      <mat-tab label="Wed">
                        <div class="scheduleList">
                          <div *ngIf="wedShedule.length==0 && NoFoundsFlag">
                            <p>No record found</p>
                          </div>
                          <div *ngFor="let wed of wedShedule" class="scheduleRow d-flex mb-1">
                            <div class="scheduleTime">
                              <span class="TimeFrom">{{wed.startTime}}</span>
                              <span class="TimeTo">{{wed.endTime}}</span>
                            </div>
                            <div class="scheduleSubjects flex-grow-1">
                              <h3>{{wed.title}}</h3>
                              <p>{{wed.subject}} ({{wed.class}} - {{wed.section}})</p>
                              <p>Teachers - {{wed.teacher}}</p>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                      <mat-tab label="Thu">
                        <div class="scheduleList">
                          <div *ngIf="thuseShedule.length==0 && NoFoundsFlag">
                            <p>No record found</p>
                          </div>
                          <div *ngFor="let thu of thuseShedule" class="scheduleRow d-flex mb-1">
                            <div class="scheduleTime">
                              <span class="TimeFrom">{{thu.startTime}}</span>
                              <span class="TimeTo">{{thu.endTime}}</span>
                            </div>
                            <div class="scheduleSubjects flex-grow-1">
                              <h3>{{thu.title}}</h3>
                              <p>{{thu.subject}} ({{thu.class}} - {{thu.section}})</p>
                              <p>Teachers - {{thu.teacher}}</p>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                      <mat-tab label="Fri">
                        <div class="scheduleList">
                          <div *ngIf="friShedule.length==0 && NoFoundsFlag">
                            <p>No record found</p>
                          </div>
                          <div *ngFor="let fri of friShedule" class="scheduleRow d-flex mb-1">
                            <div class="scheduleTime">
                              <span class="TimeFrom">{{fri.startTime}}</span>
                              <span class="TimeTo">{{fri.endTime}}</span>
                            </div>
                            <div class="scheduleSubjects flex-grow-1">
                              <h3>{{fri.title}}</h3>
                              <p>{{fri.subject}} ({{fri.class}} - {{fri.section}})</p>
                              <p>Teachers - {{fri.teacher}}</p>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                      <mat-tab label="Sat">
                        <div class="scheduleList">
                          <div *ngIf="satShedule.length==0 && NoFoundsFlag">
                            <p>No record found</p>
                          </div>
                          <div *ngFor="let sat of satShedule" class="scheduleRow d-flex mb-1">
                            <div class="scheduleTime">
                              <span class="TimeFrom">{{sat.startTime}}</span>
                              <span class="TimeTo">{{sat.endTime}}</span>
                            </div>
                            <div class="scheduleSubjects flex-grow-1">
                              <h3>{{sat.title}}</h3>
                              <p>{{sat.subject}} ({{sat.class}} - {{sat.section}})</p>
                              <p>Teachers - {{sat.teacher}}</p>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                      <mat-tab label="Sun">
                        <div class="scheduleList">
                          <div *ngIf="sunShedule.length==0  && NoFoundsFlag">
                            <p>No record found</p>
                          </div>
                          <div *ngFor="let sun of sunShedule" class="scheduleRow d-flex mb-1">
                            <div class="scheduleTime">
                              <span class="TimeFrom">{{sun.startTime}}</span>
                              <span class="TimeTo">{{sun.endTime}}</span>
                            </div>
                            <div class="scheduleSubjects flex-grow-1">
                              <h3>{{sun.title}}</h3>
                              <p>{{sun.subject}} ({{sun.class}} - {{sun.section}})</p>
                              <p>Teachers - {{sun.teacher}}</p>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div> -->
              </div>
        
    </div>
</div>