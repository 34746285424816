import { Component, OnInit } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';

@Component({
  selector: 'app-teachers-forms',
  templateUrl: './teachers-forms.component.html',
  styleUrls: ['./teachers-forms.component.css']
})
export class TeachersFormsComponent implements OnInit {
  isCollapsed = true;
  textfieldoptions = true;
  textareaoptions = true;
  dateoptions = true;
  selectionoptions = true;
  checkboxoptions = true;
  singlLineWithFields: any = [];
  multiLineWithFields: any = [];

  constructor() { }

  ngOnInit(): void {
    this.SingleLineControl();
    this.MultiLineControl();
  }

  onDrop(event: any) {
    ////debugger;
  }
  onDragStart(event: DragEvent) {
  }

  onDragEnd(event: DragEvent) {

  }

  onDraggableCopied(event: DragEvent) {

  }

  onDraggableLinked(event: DragEvent) {

  }

  onDragged(item: any, list: any[], effect: DropEffect) {

  }

  SingleLineControl() {
    this.singlLineWithFields.push(
      {
        "id": "",
        "fieldID": 0,
        "fieldName": "Single-Line",
        "folderID": 0,
        "folderName": "",
        "fieldTypeID": 0,
        "fieldTypeName": "Textbox",
        "placeholder": "",
        "defaultValue": "",
        "fillList": "",
        "FileData": [],
        "ImageData": [],
        "rows": 0,
        "column": 0,
        "selectedValue": '',
        "isRequired": false,
        "readonly": false,
        "charLimit": "40",
        "fontSize": "14px",
        "fontName": "",
        "fontstyle": "",
        "fontweight": "",
        "textdecoration": "",
        "fontColor": "",
        "fontfamily": "Arial",
        "offsetX": "",
        "offsetY": "",
        "height": 0,
        "width": 0,
        "textalign": "",
        "chkSelectedValues": [],
        "rbSelectedValues": "",
        "fieldNames": "",
        "buttonText": "",
        "signatureBase64": "",
        "signatureText": '',
        "drawBase64": "",
        "colorCode": "",
        "colorCodeAlpha": "",
        'signInitialFontFamily': '',
        'Lat': '',
        'Long': '',
        "selectedParticipant": "",
        "backgroundColor": "",
        "buttonName": "",
        "buttonURL": "",
        "brushColor": undefined,
        "rotation": 0
      })
  }

  MultiLineControl() {
    this.multiLineWithFields.push(
      {
        "id": "",
        "fieldID": 0,
        "fieldName": "Multi-Line",
        "folderID": 0,
        "folderName": "",
        "fieldTypeID": 0,
        "fieldTypeName": "Textarea",
        "placeholder": "",
        "defaultValue": "",
        "fillList": "",
        "FileData": [],
        "ImageData": [],
        "rows": 0,
        "column": 0,
        "selectedValue": '',
        "isRequired": false,
        "readonly": false,
        "charLimit": "40",
        "fontSize": "14px",
        "fontName": "",
        "fontstyle": "",
        "fontweight": "",
        "textdecoration": "",
        "fontColor": "",
        "fontfamily": "Arial",
        "offsetX": "",
        "offsetY": "",
        "height": 0,
        "width": 0,
        "textalign": "",
        "chkSelectedValues": [],
        "rbSelectedValues": "",
        "fieldNames": "",
        "buttonText": "",
        "signatureBase64": "",
        "signatureText": '',
        "drawBase64": "",
        "colorCode": "",
        "colorCodeAlpha": "",
        'signInitialFontFamily': '',
        'Lat': '',
        'Long': '',
        "selectedParticipant": "",
        "backgroundColor": "",
        "buttonName": "",
        "buttonURL": "",
        "brushColor": undefined,
        "rotation": 0
      })
  }
  onDragCanceled(event: DragEvent) {

  }

  onDragover(event: DragEvent) {

  }

}
