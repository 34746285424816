<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
          <!-- <h2>{{firstName}} {{lastName}}</h2> -->
          <h2>Manage Teachers Fields</h2>
          <div class="breadcrumbWidget">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic"><i class="fas fa-home"></i> Home</a>
              <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp; Learning</a></li>
              <li class="breadcrumb-item active" aria-current="page">Manage Teachers Fields</li>
            </ol>
          </div>
        </div>
      
      </div>
    <div class="cardWidget">
    
<div class="row">
    <div class="col-lg-3 col-sm-12">
        <h4 class="text-colored mb-4">Add Field</h4>
          <div class="fieldTypes form-fields-block">
            <div class="fieldType dynamicallyAddableField form-field " matRipple  *ngFor="let field of singlLineWithFields" [dndDraggable]="field" [dndEffectAllowed]="'copy'"
            (dndEnd)="onDragEnd($event)"><i class="fas fa-font"></i><div>Text</div></div>
            <div class="fieldType dynamicallyAddableField form-field " matRipple *ngFor="let field of multiLineWithFields" [dndDraggable]="field" [dndEffectAllowed]="'copy'"
            (dndEnd)="onDragEnd($event)"  ><i class="fas fa-align-justify"></i><div>Textarea</div></div>
            <div class="fieldType dynamicallyAddableField form-field " matRipple ><i class="fas fa-calendar-minus"></i><div>Date</div></div>
            <div class="fieldType dynamicallyAddableField form-field " matRipple ><i class="fas fa-hand-point-up"></i><div>Selection</div></div>
            <div class="fieldType dynamicallyAddableField form-field "matRipple ><i class="fas fa-check-square"></i><div>Chcekbox</div></div>
          </div>
   
        <div class="separtor"></div>
        <div class="tab-entery-form" matRipple (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapsedform">
            <a class="add-field-group-link hidden"(click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapsedform" ><b><i class="fa fa-plus"></i> Add Field Group</b></a>
            
            <form id="field-group-entry-form" class="field-group-entry-form" id="collapsedform" [collapse]="isCollapsed" >
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="w-100 mt-2">
                        <mat-label>Field Group Name</mat-label>
                        <input matInput type="text" >
                      </mat-form-field>
                </div>
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-primary btn-sm mr-2" matRipple>Add</button>
                    <button type="button" class="btn btn-reset btn-sm" matRipple>Cancel</button>
                </div>
            </form>
        </div>

    </div>
    
    <div class="col-lg-6 col-sm-12">
        <h4 class="text-colored mb-4">Fields <i class="fas fa-info-circle" [popover]="informationForForm" triggers="mouseenter:mouseleave"
            [adaptivePosition]="true"
            placement="bottom"></i></h4>
            
    <section>
      <div  (dndDrop)="onDrop($event)" [dndDropzone]="[]">
            <mat-accordion >
              
                <mat-expansion-panel  class="mb-4">
                    <mat-expansion-panel-header matRipple>
                        <mat-panel-title class="pannelheading">
                           
                                <div class="text">
                                    <h2 class="panelheadingtext" contentEditable="true">Teachers Staff Details</h2>
                                </div>                               
                                <div class="actions">
                                    <i class="fas fa-arrows-alt mr-3"></i>
                                   <i class="fa-trash fas"></i>
                                </div>
                         
                          
                            
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                   
                        <div class="textfield" matRipple (click)="textfieldoptions = !textfieldoptions"
                        [attr.aria-expanded]="!textfieldoptions" aria-controls="textfieldoptions">
                            <p class="textheading" contentEditable="true">Text Field</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>
                         
                        </div>
                        <div class="textfield" matRipple (click)="textareaoptions = !textareaoptions"
                        [attr.aria-expanded]="!textareaoptions" aria-controls="textareaoptions">
                            <p class="textheading" contentEditable="true">Text Area</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="dateoptions = !dateoptions"
                        [attr.aria-expanded]="!dateoptions" aria-controls="dateoptions">
                            <p class="textheading" contentEditable="true">Date</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="selectionoptions = !selectionoptions"
                        [attr.aria-expanded]="!selectionoptions" aria-controls="selectionoptions">
                            <p class="textheading" contentEditable="true"> Selection</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="checkboxoptions = !checkboxoptions"
                        [attr.aria-expanded]="!checkboxoptions" aria-controls="checkboxoptions">
                            <p class="textheading" contentEditable="true"> CheckBox</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                   
                      
                    </ng-template>
                </mat-expansion-panel>
               
            </mat-accordion>

            <mat-accordion >
              
                <mat-expansion-panel  class="mb-4">
                    <mat-expansion-panel-header matRipple>
                        <mat-panel-title class="pannelheading">
                           
                                <div class="text">
                                    <h2 class="panelheadingtext" contentEditable="true">Additional Information</h2>
                                </div>                               
                                <div class="actions">
                                    <i class="fas fa-arrows-alt mr-3"></i>
                                   <i class="fa-trash fas"></i>
                                </div>
                         
                          
                            
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div class="textfield" matRipple (click)="textfieldoptions = !textfieldoptions"
                        [attr.aria-expanded]="!textfieldoptions" aria-controls="textfieldoptions">
                            <p class="textheading" contentEditable="true">Text Field <span class="mandatory">*</span></p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>
                         
                        </div>
                        <div class="textfield" matRipple (click)="textareaoptions = !textareaoptions"
                        [attr.aria-expanded]="!textareaoptions" aria-controls="textareaoptions">
                            <p class="textheading" contentEditable="true">Text Area</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="dateoptions = !dateoptions"
                        [attr.aria-expanded]="!dateoptions" aria-controls="dateoptions">
                            <p class="textheading" contentEditable="true">Date</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="selectionoptions = !selectionoptions"
                        [attr.aria-expanded]="!selectionoptions" aria-controls="selectionoptions">
                            <p class="textheading" contentEditable="true"> Selection</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="checkboxoptions = !checkboxoptions"
                        [attr.aria-expanded]="!checkboxoptions" aria-controls="checkboxoptions">
                            <p class="textheading" contentEditable="true"> CheckBox</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                   
                   
                    </ng-template>
                </mat-expansion-panel>
               
            </mat-accordion>

            <mat-accordion >
              
                <mat-expansion-panel  class="mb-4">
                    <mat-expansion-panel-header matRipple>
                        <mat-panel-title class="pannelheading">
                           
                                <div class="text">
                                    <h2 class="panelheadingtext" contentEditable="true">Skills and Social Deatils</h2>
                                </div>                               
                                <div class="actions">
                                    <i class="fas fa-arrows-alt mr-3"></i>
                                   <i class="fa-trash fas"></i>
                                </div>
                         
                          
                            
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <div class="textfield" matRipple (click)="textfieldoptions = !textfieldoptions"
                        [attr.aria-expanded]="!textfieldoptions" aria-controls="textfieldoptions">
                            <p class="textheading" contentEditable="true">Text Field</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>
                         
                        </div>
                        <div class="textfield" matRipple (click)="textareaoptions = !textareaoptions"
                        [attr.aria-expanded]="!textareaoptions" aria-controls="textareaoptions">
                            <p class="textheading" contentEditable="true">Text Area</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="dateoptions = !dateoptions"
                        [attr.aria-expanded]="!dateoptions" aria-controls="dateoptions">
                            <p class="textheading" contentEditable="true">Date</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="selectionoptions = !selectionoptions"
                        [attr.aria-expanded]="!selectionoptions" aria-controls="selectionoptions">
                            <p class="textheading" contentEditable="true"> Selection</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                        <div class="textfield" matRipple (click)="checkboxoptions = !checkboxoptions"
                        [attr.aria-expanded]="!checkboxoptions" aria-controls="checkboxoptions">
                            <p class="textheading" contentEditable="true"> CheckBox</p>
                            <div class="actionsbtns">
                                <i class="fas fa-arrows-alt mr-3"></i>
                                <i class="fa-trash fas mr-3"></i>
                                <i class="fas fa-chevron-right"></i>
                            </div>

                        </div>
                   
                   
                    </ng-template>
                </mat-expansion-panel>
               
            </mat-accordion>
       
      </div>
    </section>
    </div>
    <div class="col-lg-3 col-sm-12">
        <div class="cardWidget mt-30 textFieldoptions" id="textfieldoptions" [collapse]="textfieldoptions">
            <div class="headofcard mb-3">
                <h4 class="text-colored ">Text Field</h4>
                <i class="fas fa-times cursor-pointer" (click)="textfieldoptions = !textfieldoptions"
                [attr.aria-expanded]="!textfieldoptions" aria-controls="textfieldoptions"></i>
            </div>
        
          <h5 class="mb-3">Field Id :text-045</h5>

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Label</mat-label>
            <input matInput type="text" >
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Field Type</mat-label>
            <mat-select placeholder="Text Field" >
              <mat-option value="Text Field">Text Field</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Status</mat-label>
            <mat-select placeholder="Select Status" >
              <mat-option value="publish">Publish</mat-option>
              <mat-option value="publish">Unpublish</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-checkbox class="example-margin ml-0">Re-enrollment unique identifier <i class="fas fa-info-circle" [popover]="textfield" triggers="mouseenter:mouseleave"
            [adaptivePosition]="true"
            placement="bottom"></i></mat-checkbox> -->

            <h5 class="mb-3 mt-3">Rules</h5>
            <mat-checkbox class="example-margin ml-0">Required</mat-checkbox>
            <mat-checkbox class="example-margin">Email</mat-checkbox>
            <mat-checkbox class="example-margin">Date</mat-checkbox>

            <div class="text-right mt-2">
              <button class="btn btn-primary btn-sm">Save</button>
            </div>



        </div>
        <div class="cardWidget mt-30 textareaFieldoptions" id="textareaoptions" [collapse]="textareaoptions">
            <div class="headofcard mb-3">
                <h4 class="text-colored ">Textarea Field</h4>
                <i class="fas fa-times cursor-pointer" (click)="textareaoptions = !textareaoptions"
                [attr.aria-expanded]="!textareaoptions" aria-controls="textareaoptions"></i>
            </div>
        
          <h5 class="mb-3">Field Id :textarea-045</h5>

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Label</mat-label>
            <input matInput type="text" >
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Field Type</mat-label>
            <mat-select placeholder="Textarea Field" >
              <mat-option value="Text Field">TextArea Field</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Status</mat-label>
            <mat-select placeholder="Select Status" >
              <mat-option value="publish">Publish</mat-option>
              <mat-option value="publish">Unpublish</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-checkbox class="example-margin ml-0">Re-enrollment unique identifier <i class="fas fa-info-circle" [popover]="textfield" triggers="mouseenter:mouseleave"
            [adaptivePosition]="true"
            placement="bottom"></i></mat-checkbox> -->

            <h5 class="mb-3 mt-3">Rules</h5>
            <mat-checkbox class="example-margin ml-0">Required</mat-checkbox>
            <mat-checkbox class="example-margin">Email</mat-checkbox>
            <mat-checkbox class="example-margin">Date</mat-checkbox>

            <div class="text-right mt-2">
              <button class="btn btn-primary btn-sm">Save</button>
            </div>



        </div>
        <div class="cardWidget mt-30 dateoptions" id="dateoptions" [collapse]="dateoptions">
            <div class="headofcard mb-3">
                <h4 class="text-colored ">Date Field</h4>
                <i class="fas fa-times cursor-pointer" (click)="dateoptions = !dateoptions"
                [attr.aria-expanded]="!dateoptions" aria-controls="dateoptions"></i>
            </div>
        
          <h5 class="mb-3">Field Id :date-045</h5>

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Label</mat-label>
            <input matInput type="text" >
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Field Type</mat-label>
            <mat-select placeholder="Date Field" >
              <mat-option value="Text Field">Date Field</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Status</mat-label>
            <mat-select placeholder="Select Status" >
              <mat-option value="publish">Publish</mat-option>
              <mat-option value="publish">Unpublish</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-checkbox class="example-margin ml-0">Re-enrollment unique identifier <i class="fas fa-info-circle" [popover]="textfield" triggers="mouseenter:mouseleave"
            [adaptivePosition]="true"
            placement="bottom"></i></mat-checkbox> -->

            <h5 class="mb-3 mt-3">Rules</h5>
            <mat-checkbox class="example-margin ml-0">Required</mat-checkbox>
            <mat-checkbox class="example-margin">Email</mat-checkbox>
            <mat-checkbox class="example-margin">Date</mat-checkbox>

            <div class="text-right mt-2">
              <button class="btn btn-primary btn-sm">Save</button>
            </div>


        </div>
        <div class="cardWidget mt-30 selectionoptions" id="selectionoptions" [collapse]="selectionoptions">
            <div class="headofcard mb-3">
                <h4 class="text-colored ">Selection Field</h4>
                <i class="fas fa-times cursor-pointer" (click)="selectionoptions = !selectionoptions"
                [attr.aria-expanded]="!selectionoptions" aria-controls="selectionoptions"></i>
            </div>
        
          <h5 class="mb-3">Field Id :select-045</h5>

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Label</mat-label>
            <input matInput type="text" >
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Field Type</mat-label>
            <mat-select placeholder="Select Field" >
              <mat-option value="Text Field">Select Field</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Status</mat-label>
            <mat-select placeholder="Select Status" >
              <mat-option value="publish">Publish</mat-option>
              <mat-option value="publish">Unpublish</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Options</mat-label>
            <textarea   matInput placeholder="Option1, Option2, Option3,...... " ></textarea>
            <mat-error>
              Options is required
            </mat-error>
            </mat-form-field>

          <!-- <mat-checkbox class="example-margin ml-0">Re-enrollment unique identifier <i class="fas fa-info-circle" [popover]="textfield" triggers="mouseenter:mouseleave"
            [adaptivePosition]="true"
            placement="bottom"></i></mat-checkbox> -->

            <h5 class="mb-3 mt-3">Rules</h5>
            <mat-checkbox class="example-margin ml-0">Required</mat-checkbox>
            <mat-checkbox class="example-margin">Email</mat-checkbox>
            <mat-checkbox class="example-margin">Date</mat-checkbox>

            <div class="text-right mt-2">
              <button class="btn btn-primary btn-sm">Save</button>
            </div>



        </div>
        <div class="cardWidget mt-30 chcekboxoptions" id="checkboxoptions" [collapse]="checkboxoptions">
            <div class="headofcard mb-3">
                <h4 class="text-colored ">Chcekbox Field</h4>
                <i class="fas fa-times cursor-pointer" (click)="checkboxoptions = !checkboxoptions"
                [attr.aria-expanded]="!checkboxoptions" aria-controls="checkboxoptions"></i>
            </div>
        
          <h5 class="mb-3">Field Id :checkbox-045</h5>

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Label</mat-label>
            <input matInput type="text" >
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Field Type</mat-label>
            <mat-select placeholder="checkbox Field" >
              <mat-option value="Text Field">checkbox Field</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Status</mat-label>
            <mat-select placeholder="Select Status" >
              <mat-option value="publish">Publish</mat-option>
              <mat-option value="publish">Unpublish</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Options</mat-label>
            <textarea   matInput placeholder="Option1, Option2, Option3,...... " ></textarea>
            <mat-error>
              Options is required
            </mat-error>
            </mat-form-field>

          <!-- <mat-checkbox class="example-margin ml-0">Re-enrollment unique identifier <i class="fas fa-info-circle" [popover]="textfield" triggers="mouseenter:mouseleave"
            [adaptivePosition]="true"
            placement="bottom"></i></mat-checkbox> -->

            <h5 class="mb-3 mt-3">Rules</h5>
            <mat-checkbox class="example-margin ml-0">Required</mat-checkbox>
            <mat-checkbox class="example-margin">Email</mat-checkbox>
            <mat-checkbox class="example-margin">Date</mat-checkbox>

            <div class="text-right mt-2">
              <button class="btn btn-primary btn-sm">Save</button>
            </div>



        </div>
    </div>
</div>
    
    
    </div>




</div>
    <ng-template  #informationForForm>
        <p class="popovertext">-Drag &amp; Drop to update the display order of fields.<br>
            - Click on the settings icon to update the field options/settings.<br>
            - Only published fields will be displayed in students  form / profile page.<br>
            - Fields marked <b style="color:red;"> * </b>  is required and mandatory.</p>
    </ng-template>

    <!-- <ng-template  #textfield>
        <p class="popovertext">Attribute/field with re-enrollment unique identifier setting enabled, will be used for providing Re-enrollment option for re-enrolling existing students into a new course while enrolling the submission into CRM module.</p>
    </ng-template> -->
