import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.css']
})
export class ViewStudentComponent implements OnInit {
  form: FormGroup;
  studentID: number;
  data: string;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  genderName: string;
  admissionNumber: string
  email: string
  fatherName: string
  motherName: string
  parentsContact: string
  parentsEmail: string
  profilePhoto: string = './../../assets/img/display.jpg';
  facebookLink: string
  linkedInlink: string
  profileSummary: string
  skill: string
  contactNumber: string
  address: string
  zipCode: string
  stateName: string
  countryName: string
  city: string
  bloodGroupName: string
  academicSessionName: string
  className: string
  sectionName: string
  parentContact: string
  extraField: any
  extraformField: boolean



  constructor(

    private activatedRoute: ActivatedRoute,
    private studentService: StudentService

  ) { }

  ngOnInit(): void {
    this.extraformField = false;
    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? null : params.id;
    });
    //this.adminUserID = this.activatedRoute.snapshot.params.Id
    this.studentService.GetStudentListById(this.studentID).subscribe((res: any) => {
      this.firstName = res.responseData.firstName;
      this.lastName = res.responseData.lastName;
      this.dateOfBirth = res.responseData.dateOfBirth;
      this.genderName = res.responseData.genderName;
      this.admissionNumber = res.responseData.admissionNumber;
      this.email = res.responseData.email;
      this.skill = res.responseData.skill;
      this.profileSummary = res.responseData.profileSummary;
      this.linkedInlink = res.responseData.linkedInlink;
      this.facebookLink = res.responseData.facebookLink;
      this.profilePhoto = res.responseData.profilePhoto ? res.responseData.profilePhoto : './../../assets/img/display.jpg';
      this.contactNumber = res.responseData.contactNumber;
      this.address = res.responseData.address;
      this.city = res.responseData.city;
      this.countryName = res.responseData.countryName;
      this.stateName = res.responseData.stateName;
      this.motherName = res.responseData.motherName;
      this.fatherName = res.responseData.fatherName;
      this.parentContact = res.responseData.parentContact;
      this.parentsEmail = res.responseData.parentEmail;
      this.zipCode = res.responseData.zipCode;
      this.bloodGroupName = res.responseData.bloodGroupName;
      this.sectionName = res.responseData.sectionName;
      this.className = res.responseData.className;
      this.academicSessionName = res.responseData.academicSessionName;
      if (res.responseData.studentExtraInfoInJson != "") {
        this.extraformField = true;
        var extra = JSON.parse(res.responseData.studentExtraInfoInJson)
        this.extraField = extra.data;
      }
    });
  }
}
