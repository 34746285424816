import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, Section } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SectionService {

  constructor(private http : HttpClient) { }

  AddSection(data:any) {
    return this.http.post(`${environment.baseUrl}${Section.addSectonAPI}`,data);
  }

  AllSection() {
    return this.http.get(`${environment.baseUrl}${Section.getAllSectionAPI}`);
  }
  
  GetSectionById(Id:any) {
     return this.http.get(`${environment.baseUrl}${Section.getSectionByIdAPI}${'?sectionID=' + Id}`);
  }

  EditSection(data:any) {
    return this.http.post(`${environment.baseUrl}${Section.EditSectionByIdAPI}`,data);
  }

  DeleteSection(Id:any) {
    return this.http.get(`${environment.baseUrl}${Section.DeleteSectionByIdAPI}${'?id=' + Id}`);
  }

  GetSectionByClassId(Id:any) {
    return this.http.get(`${environment.baseUrl}${Section.GetsectionBySectionIdOrClassId}${'?classID=' + Id}`);
  }

}
