<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>Edit Student Profile</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
                    <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
                            Learning</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-students">Manage
                            Student</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Edit Student Profile</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-students">Back</button>
            </div>
          </div>
    </div>


    <div class="cardWidget">
        <div class="matCard">
            <!-- <div class="matCrdTitle centerItemVert justify-content-between">
                <div class="cardTitle centerVTItems justify-content-between">
                    <h3>Profile</h3>
                    <button class="btn btn-primary btnsmall" routerLink="../">Back</button>
                </div>
    
            </div> -->
            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">

                        <div class="row">
                            <div class="col-md-2 col-sm-12">
                                <div class="profilePicLeft form-group30">
                                    <!-- <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Profile pic <span class="req">*</span></mat-label>
                                    <input matInput placeholder="Profile" required name="Profile" formControlName="profilePic">
                                </mat-form-field>     -->



                                    <p class="chngePic">
                                        <input type="file" accept="image/*"
                                            (change)="actionDialogPopup(uploadPopup,$event)">
                                        <span class="icon centerItem">
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                        <img [src]="croppedImage" alt="">
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-10 col-sm-12">
                                <div class="profileContentRight flex-grow-1">

                                    <div class="proSection">
                                        <h5><i class="fas fa-vote-yea mr-2"></i> Enrollment</h5>

                                        <div class="row">


                                            <div class="col s12 m4 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Academic Session</mat-label>
                                                    <mat-select placeholder="Session"
                                                        formControlName="academicSessionID">
                                                        <mat-option value="" disabled>Academic Sessions</mat-option>
                                                        <mat-option *ngFor="let session of academicSessions"
                                                            [value]="session.academicSessionID">
                                                            {{session.academicSessionName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('academicSessionID')?.touched  && form.get('academicSessionID')?.errors?.required">
                                                        academicSession is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m4 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Class</mat-label>
                                                    <mat-select placeholder="Class"
                                                        (selectionChange)="filterSectionListByClassId($event.value)"
                                                        formControlName="classID">
                                                        <mat-option value="" disabled>Select Class</mat-option>
                                                        <mat-option *ngFor="let class of classes"
                                                            [value]="class.classID">
                                                            {{class.className}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('classID')?.touched  && form.get('classID')?.errors?.required">
                                                        Class is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>


                                            <div class="col s12 m4 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Section</mat-label>
                                                    <mat-select placeholder="Section" formControlName="sectionID">
                                                        <mat-option value="" disabled>Select Sections</mat-option>
                                                        <mat-option *ngFor="let section of sections"
                                                            [value]="section.sectionID">
                                                            {{section.sectionName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('sectionID')?.touched  && form.get('sectionID')?.errors?.required">
                                                        Section is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="proSection">
                                        <h5><i class="fas fa-user mr-2"></i> Personal Information</h5>

                                        <div class="row-flex">
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Student Email</mat-label>
                                                    <input matInput placeholder="email" required name="email"
                                                        formControlName="email" maxlength="50">
                                                    <mat-error
                                                        *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                        Student Email is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Date of Birth</mat-label>
                                                    <input (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                        matInput placeholder="Date Of Birth" required name="DateOfBirth"
                                                        formControlName="dateOfBirth" [matDatepicker]="picker">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-error
                                                        *ngIf="form.get('dob')?.touched  && form.get('dob')?.errors?.required">
                                                        Field is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>


                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <!-- <mat-label>Blood Group</mat-label> -->
                                                    <!-- <input matInput  placeholder="Blood Group" required name="bloodGroup" formControlName="bloodGroup"> -->
                                                    <mat-label>Select Blood Group</mat-label>
                                                    <mat-select placeholder="Blood Group" required name="bloodGroup"
                                                        formControlName="bloodGroupID">
                                                        <mat-option value="" disabled>Select Blood Group</mat-option>
                                                        <mat-option *ngFor="let bloodGroup of bloodGroups"
                                                            [value]="bloodGroup.bloodGroupID">
                                                            {{bloodGroup.bloodGroupName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('bloodGroupID')?.touched  && form.get('bloodGroupID')?.errors?.required">
                                                        Blood Group is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>


                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Gender</mat-label>
                                                    <mat-select placeholder="Gender" required name="gender"
                                                        formControlName="genderID">
                                                        <mat-option value="" disabled>Select Gender</mat-option>
                                                        <mat-option *ngFor="let gender of genders "
                                                            [value]="gender.genderID">
                                                            {{gender.genderName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('gender')?.touched  && form.get('gender')?.errors?.required">
                                                        Gender is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">

                                                    <ngx-mat-intl-tel-input style="border: none;"
                                                        [preferredCountries]="['au', 'us']" [enablePlaceholder]="true"
                                                        [enableSearch]="true" name="ContactNumber"
                                                        formControlName="contactNumber" #phone>
                                                    </ngx-mat-intl-tel-input>
                                                    <mat-hint class="hintexted">eg :
                                                        {{phone.selectedCountry.placeHolder}}</mat-hint>
                                                    <mat-error
                                                        *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                        Student contact is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="proSection">
                                        <h5><i class="fas fa-book-reader mr-2"></i> Academic Details</h5>

                                        <div class="row">

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>First Name </mat-label>
                                                    <input maxlength="20" matInput placeholder="First Name" required name="firstname"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="firstName">
                                                    <mat-error
                                                        *ngIf="form.get('firstName')?.touched  && form.get('firstName')?.errors?.required">
                                                        First Name is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('firstName')?.hasError('pattern')">
                                                        Please enter valid First Name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Last Name</mat-label>
                                                    <input maxlength="20" matInput placeholder="Last Name" required name="lastName"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="lastName">
                                                    <mat-error
                                                        *ngIf="form.get('lastName')?.touched  && form.get('lastName')?.errors?.required">
                                                        Last Name is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('lastName')?.hasError('pattern')">
                                                        Please enter valid Last Name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Admission Number</mat-label>
                                                    <input maxlength="10" matInput placeholder="Admission Number"
                                                        required name="admissionNumber"
                                                        formControlName="admissionNumber"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$">
                                                    <mat-error
                                                        *ngIf="form.get('admissionNumber')?.touched  && form.get('admissionNumber')?.errors?.required">
                                                        Admission Number is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('admissionNumber')?.hasError('pattern')">
                                                        Please enter valid Admission Number
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="proSection">
                                        <h5><i class="fas fa-star mr-2"></i> Skills & Social Details</h5>
                                        <div class="row-flex">


                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Profile Summary</mat-label>
                                                    <textarea input matInput placeholder="Profile Summary"
                                                        formControlName="profileSummary" maxlength="250"></textarea>

                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Skills</mat-label>
                                                    <input matInput placeholder="Skills"
                                                        formControlName="studentSkillID" maxlength="250">
                                                </mat-form-field>
                                            </div>
                                            <!-- <div class="col s12 m6 form-group">
                                  <mat-form-field class="w-100" appearance="outline">
                                      <mat-label>Select Student Skills </mat-label>
                                      <mat-select placeholder="Stdent Skills" formControlName="studentSkillID">
                                          <mat-option *ngFor="let skill of skills " [value]="skill.studentSkillID">
                                              {{skill.skill}}
                                          </mat-option>
                                      </mat-select>
                                      
                                  </mat-form-field>
                              </div> -->

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Facebook Profile Link</mat-label>
                                                    <input matInput placeholder="Facebook Profile Link"
                                                        formControlName="facebookLink" maxlength="100">

                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>LinkedIn Profile Link</mat-label>
                                                    <input matInput type="url" placeholder="LinkedIn Profile Link"
                                                        formControlName="linkedInlink" maxlength="100">

                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- <div class="proSection">
                                      
                                        <div class="row-flex">
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Address</mat-label>
                                                    <input matInput placeholder="address" formControlName="address"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$">
                                                    <mat-error
                                                        *ngIf="form.get('address')?.touched  && form.get('address')?.errors?.required">
                                                        Address is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('address')?.hasError('pattern')">
                                                        Please enter valid Address
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>



                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Country</mat-label>
                                                    <mat-select placeholder="Country"
                                                        (valueChange)="GetStateList($event)"
                                                        formControlName="countryID">
                                                        <mat-option value="">Select Country</mat-option>
                                                        <mat-option *ngFor="let country of countries"
                                                            [value]="country.countryID">
                                                            {{country.countryName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('countryID')?.touched  && form.get('countryID')?.errors?.required">
                                                        Country is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>LinkedIn Profile Link</mat-label>
                                                    <input matInput type="url" placeholder="LinkedIn Profile Link"
                                                        formControlName="linkedInlink">

                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div> -->


                                    <div class="proSection">
                                        <h5><i class="fas fa-plus-square mr-2"></i> Additional Details</h5>
                                        <div class="row-flex">
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Address</mat-label>
                                                    <input matInput placeholder="address" formControlName="address"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="100">
                                                    <mat-error
                                                        *ngIf="form.get('address')?.touched  && form.get('address')?.errors?.required">
                                                        Address is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('address')?.hasError('pattern')">
                                                        Please enter valid Address
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>



                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Country</mat-label>
                                                    <mat-select placeholder="Country"
                                                        (valueChange)="GetStateList($event)"
                                                        formControlName="countryID">
                                                        <mat-option value="" disabled>Select Country</mat-option>
                                                        <mat-option *ngFor="let country of countries"
                                                            [value]="country.countryID">
                                                            {{country.countryName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('countryID')?.touched  && form.get('countryID')?.errors?.required">
                                                        Country is required
                                                    </mat-error>
                                                </mat-form-field>



                                            </div>

                                            <!-- [disabled]="true" -->
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>State</mat-label>
                                                    <mat-select placeholder="State" formControlName="stateID">
                                                        <mat-option value="" disabled>Select State</mat-option>
                                                        <mat-option *ngFor="let state of states"
                                                            [value]="state.stateID">
                                                            {{state.stateName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('stateID')?.touched  && form.get('stateID')?.errors?.required">
                                                        State is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>City</mat-label>
                                                    <input maxlength="20" matInput placeholder="city"
                                                        formControlName="city" pattern="^[^\s]+(\s+[^\s]+)*$">
                                                    <mat-error
                                                        *ngIf="form.get('city')?.touched  && form.get('city')?.errors?.required">
                                                        City is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('city')?.hasError('pattern')">
                                                        Please enter valid City
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Zip Code</mat-label>
                                                    <input (keypress)="allowAlphaNumber($event)" maxlength="10" matInput
                                                        placeholder="Zip Code" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                        formControlName="zipCode">
                                                    <mat-error
                                                        *ngIf="form.get('zipCode')?.touched  && form.get('zipCode')?.errors?.required">
                                                        Zip Code is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('zipCode')?.hasError('pattern')">
                                                        Please enter valid Zip Code
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>





                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Father's Name </mat-label>
                                                    <input maxlength="20" (keypress)="allowAlphaNumber($event)" matInput
                                                        placeholder="Father's Name" required name="fatherName"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="fatherName">
                                                    <mat-error
                                                        *ngIf="form.get('fatherName')?.touched  && form.get('fatherName')?.errors?.required">
                                                        Father's Name is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('fatherName')?.hasError('pattern')">
                                                        Please enter valid Father's Name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Mother's Name </mat-label>
                                                    <input maxlength="20" (keypress)="allowAlphaNumber($event)" matInput
                                                        placeholder="Mother's Name" required name="motherName"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="motherName">
                                                    <mat-error
                                                        *ngIf="form.get('motherName')?.touched  && form.get('motherName')?.errors?.required">
                                                        Mother's Name is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('motherName')?.hasError('pattern')">
                                                        Please enter valid Mother's Name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Parents Contact</mat-label>
                                                    <input maxlength="10" (keypress)="allowOnlyNumber($event)"
                                                        maxlength="10" matInput placeholder="Parents Contact" required
                                                        name="parentContact" formControlName="parentContact">
                                                    <mat-error
                                                        *ngIf="form.get('parentContact')?.touched  && form.get('parentContact')?.errors?.required">
                                                        Parent Contact is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('parentContact')?.hasError('pattern')">
                                                        Please enter valid Parents Contact
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Parents Email</mat-label>
                                                    <input matInput placeholder="parentEmail" required
                                                        name="parentEmail" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50"
                                                        formControlName="parentEmail" required name="parentEmail">
                                                    <mat-error
                                                        *ngIf="form.get('parentEmail')?.touched  && form.get('parentEmail')?.errors?.required">
                                                        Parent Email is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('parentEmail')?.hasError('pattern')">
                                                        Please enter valid Parent Email
                                                    </mat-error>
                                                </mat-form-field>

                                            </div>



                                        </div>
                                    </div>
                                    <!-- <div *ngIf="extraformField == true" class="proSection">
                                                <h5>Custom Fields</h5>
                                                <div class="row-flex">
                                                    <div class="col s12 form-group">
                                                        <formio [form]='jsonFormData' [options]="formioOptions"
                                                            [submission]="initialFormValues"
                                                            (change)="onChange($event)">
                                                        </formio>
                                                    </div>
                                                </div>
                                            </div> -->
                                    <div class="proSection text-right">
                                        <button type="submit" class="btn btn-primary btn-sm" matRipple>Update <svg xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478" viewBox="0 0 12.611 8.478">
                                            <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                                              <path id="Path" d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z" transform="translate(-3 -5.95)" fill="#fff"/>
                                            </g>
                                          </svg>
                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>




                <!-- </div>
            </div>
            </div> -->
            <!-- <div *ngIf="extraformField == true" class="proSection">
                            <h5>Custom Fields</h5>
                            <div class="row-flex">
                                <div class="col s12 form-group">
                                    <formio [form]='jsonFormData' [options]="formioOptions"
                                        [submission]="initialFormValues" (change)="onChange($event)"></formio>
                                </div>
                            </div>
                        </div> -->
            <!-- <div class="proSection text-right">
                            <button type="submit" class="btn btn-primary btn-sm" matRipple>Update<i
                                    class="fas fa-arrow-right ml-2"></i></button>
                        </div> -->
            </form>
        </div>
    </div>
</div>


</div>
</div>

<ng-template #uploadPopup>
    <div class="dialog-md">
        <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
        <div class="dialogTitle loginTtile">
            <h5 mat-dialog-title>Select Image Area</h5>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

            </div>

        </mat-dialog-content>
        <div class="proSection text-right">
            <button class="btn btn-primary btn-sm" mat-dialog-close (click)="finalUpload()">Save</button>
        </div>
    </div>
</ng-template>