import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
// import { MatSort} from '@angular/material/sort';
// import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-assessment-categories',
  templateUrl: './assessment-categories.component.html',
  styleUrls: ['./assessment-categories.component.css']
})
export class AssessmentCategoriesComponent implements AfterViewInit {

  classToggled = false;
  addAssessmentCategoryForm: FormGroup;
  AssessmentCategoryID: number;
  updateTextInPopUp: boolean;
  users: any;
  user: any;
  permissions: any;
  academicSessionID: any;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }

  // @ViewChild(MatSort)
  // sort!: MatSort;
  constructor(
    private dialog: MatDialog,
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private permissionService: PermissionService,
    private roleService: RoleAndPermissionService,
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
    if (currentUser != null) {
      this.user = currentUser;
    }
  }

  displayedColumns: string[] = ['id', 'assessmentCategoryName', 'actions'];
  // dataSource = ELEMENT_DATA;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    //Legasov Added 25 March 2022
    this.permissionService.checkComponentHasScreenPermission('Grade Setup');
    this.permissions = this.permissionService.getAllPermissions();
    if (this.permissions.length == 0) {
      this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
        if (res.status > 0) {
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
        }
      })
    }
    this.initForm();
    this.GetAllAssessmentCategory();
  }
  checkScreenPermission(screen: any) {
    return this.permissions.find((x: any) => x.screenName == screen);
  }
  checkActionPermission(actionName: any) {
    let screen = 'Grade Setup';
    //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
    //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
  }

  initForm() {
    this.addAssessmentCategoryForm = this.fb.group({
      assessmentCategoryName: ['', Validators.required],
      academicSessionID: [this.academicSessionID],
    });
  }
  // public noWhitespaceValidator(control: FormControl) {
  //   const isWhitespace = (control.value || '').trim().length === 0;
  //   const isValid = !isWhitespace;
  //   return isValid ? null : { 'whitespace': true };
  // }

  checkWhiteSpace(event: any) {
    if (event.target.value.length > 0) {
      return;
    } else {
      event.preventDefault();
    }
  }
  GetAllAssessmentCategory() {
    this.dataSource = new MatTableDataSource();
    this.manageAssessmentService.allAssessmentCategory(this.academicSessionID).subscribe((res: any) => {
      this.dataSource.data = res.responseData;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
    })
  }
  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.initForm();
    this.updateTextInPopUp = false;
    this.dialog.open(templateRef);
  }


  onSubmitAssessmentCategory() {
    if (this.addAssessmentCategoryForm.invalid) {
      return;
    }
    if (!this.updateTextInPopUp) {
      this.AssessmentCategoryID = 0;
    }
    if (!this.AssessmentCategoryID) {
      this.AddAssessmentCategory();
    }
    else {
      this.editAssessmentCategoryById()
    }
  }
  editAssessmentCategoryModal(templateRef: TemplateRef<any>, assessmentCategoryID: number) {
    //debugger;
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef);
    this.AssessmentCategoryID = assessmentCategoryID
    this.addAssessmentCategoryForm = this.fb.group({
      assessmentCategoryID: [0],
      assessmentCategoryName: ['', Validators.required],
      academicSessionID: [0]
    });
    this.manageAssessmentService.getAssessmentCategoryById(assessmentCategoryID, this.academicSessionID).subscribe((res: any) => {
      console.log(res);
      this.addAssessmentCategoryForm.patchValue({
        assessmentCategoryID: res.responseData['assessmentCategoryID'],
        assessmentCategoryName: res.responseData['assessmentCategoryName'],
        academicSessionID: res.responseData.academicSessionID
        // console.log('this.addAssessmentCategoryForm', res);
      });

    });
  }
  AddAssessmentCategory() {
    ////debugger;
    console.log("this.addAssessmentCategoryForm.value", this.addAssessmentCategoryForm.value);

    this.manageAssessmentService.addAssessmentCategory(this.addAssessmentCategoryForm.value).subscribe((res: any) => {
      if (res.status == 0) {
        this.toaster.error(res.message);
      }
      else {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.addAssessmentCategoryForm.reset();
          this.GetAllAssessmentCategory();
        }
        else {
          this.toaster.error(res.message);
        }
      }

    });
  }

  editAssessmentCategoryById() {
    //debugger;
    console.log("this.addAssessmentCategoryForm", this.addAssessmentCategoryForm.value);
    this.manageAssessmentService.editAssessmentCategory(this.addAssessmentCategoryForm.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.GetAllAssessmentCategory();
        this.reset();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }

  deleteAssessmentCategory(id: any) {
    this.dialogService.confirm(`Are you sure you want to delete Assessment Category?`).subscribe((result: any) => {
      if (result == true) {
        this.manageAssessmentService.deleteAssessmentCategory(id).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.GetAllAssessmentCategory();
          }
          else {
            this.toaster.error(res.message);
          }
        });
      }
    });
  }

  reset() {
    this.initForm();
    // this.addAssessmentCategoryForm.reset();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.data.length;
  //   return numSelected === numRows;
  // }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //     this.selection.clear() :
  //     this.dataSource.data.forEach((row:any) => this.selection.select(row));
  // }
}
