import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment, AcademicSession, Branch, masters } from 'src/environments/environment';
import { AcademicModule } from '../../../academic.module';
@Injectable({
  providedIn: 'root'
})
export class AcademicSessionService {
  currentAcademicSession: Subject<any> = new Subject<any>();
  
  constructor(private http : HttpClient) { }

  AcademicSessionValueChange(academicSessionId: number){
    this.currentAcademicSession.next(academicSessionId);
  }
  AddAcademicSession(data:any) {
    return this.http.post(`${environment.baseUrl}${AcademicSession.addAcademicSessionAPI}`,data);
  }

  AllAcademicSession(data:any) {
    return this.http.post(`${environment.baseUrl}${AcademicSession.getAcademicSessionAPI}`,data);
  }
  getAllAcademicSession() {
    return this.http.get(`${environment.baseUrl}${AcademicSession.getAcademicSessionAPI}`);
  }

  GetSubscriptionPlanById(Id:any) {
    return this.http.get(`${environment.baseUrl}${AcademicSession.getAcademicSessionByIdAPI}${Id}`);
 }

 UpdateSubscriptionPlan(data:any) {
   return this.http.post(`${environment.baseUrl}${AcademicSession.EditAcademicSessionByIdAPI}`,data);
 }

 DeleteAcademicSession(Id:any) {
   return this.http.get(`${environment.baseUrl}${AcademicSession.DeleteAcademicSessionByIdAPI}${'?id=' + Id}`);
 }

 UpdateAcademicSession(data:any){
   return this.http.post(`${environment.baseUrl}${AcademicSession.EditAcademicSessionByIdAPI}`,data)
 }

 postBranchData(data:any) {
  return this.http.post(`${environment.baseUrl}${Branch.postBranchAPI}`,data);
}

LockUnlockAcademicSession(Id:any) {
   return this.http.get(`${environment.baseUrl}${AcademicSession.AcademicSessionAcademicSessionLockUnlockAPI}${'?id=' + Id}`);
 }

 CheckSubdomain(data:any) {
  return this.http.post(`${environment.baseUrl}${masters.checkproductionAPI}`, data);
}

CopyAcademicSessionData(data:any) {
  return this.http.post(`${environment.baseUrl}${AcademicSession.CopyAcademicSessionDateAPI}`, data);
}
}
