<div class="table-responsive mt-15">

    <!-- <div class="loading-shade" *ngIf="isLoadingResults">
      <mat-spinner></mat-spinner>
    </div> -->

    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear>

      <ng-container [matColumnDef]="column.key" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.isSort" disableClear width='{{column.width}}'> {{column.displayName}} </th>
        <!-- <td mat-cell *matCellDef="let element" [style.color]="element[column.key]"> {{element[column.key]}} </td> -->
        <td mat-cell *matCellDef="let element" [style.color]="element[column.key]">
          <span *ngIf="!column.type" [innerHTML]="element[column.key]"></span>
          <!-- for customized cells -->
          <!-- <i *ngIf="column.isInfo && element[column.key]" [matTooltip]="element[column.key]"  matTooltipClass="example-tooltip" class="fa fa-info-circle infoIcon"></i> -->
          <!-- <span *ngIf="column.type && column.type!='date' && column.type!='decimal' && column.type!='roundspan' && column.type!='togglespan'" [innerHTML]="element[column.key] | status:column.type "></span> -->
          <!-- <span *ngIf="column.type=='date'" [innerHTML]="element[column.key] | date:'MM/dd/yyyy'"></span>
          <span *ngIf="column.type=='decimal'" [innerHTML]="element[column.key] |number:'2.2-2'"></span>
          <span *ngIf="column.type=='roundspan'" [innerHTML]="element[column.key] |span"></span> -->

          <span *ngIf="column.type=='togglespan'"><mat-slide-toggle [checked]="element[column.key]" [disabled]="!column.permission" (change)="onToggleClick('toggle',element,column)" ></mat-slide-toggle></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Actions" *ngFor="let column of extraColumns">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true" width='{{column.width}}'>Actions</th>
          <td mat-cell *matCellDef="let row">
            <button *ngFor="let a of actionButton" class="tbactionbtn" 
               (click)='onActionClick(a.key, row)' title="{{a.displayName}}">
                <i class="{{a.class}}" aria-hidden="true"></i>
            </button>
          </td>
        </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
    <div *ngIf="noRecord==true">No records found</div>
    <mat-paginator [length]="metaData['totalRecords']" [pageIndex]="metaData['currentPage'] - 1"
    [pageSize]="metaData['pageSize']" showFirstLastButtons></mat-paginator>

  </div>




  