import {SelectionModel} from '@angular/cdk/collections';
import { Component, TemplateRef, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from 'src/app/services/permission.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { RubicDialogComponent } from 'src/app/common/components/rubic-grading-scale/rubic-grading-scale.component';
import { RubicGradingScaleService } from 'src/app/services/rubic-grading-scale.service';
import { Criteria, CriteriaAttributes, Rubic, RubicApiResponse, RubricDefaults } from 'src/app/common/MasterModel/masterModel';
import { ToastrService } from 'ngx-toastr';
import { ManageAssessmentAndGradingsService } from '../../../services/manage-assessment-and-gradings.service';
import { DialogService } from 'src/app/services/dialog.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
@Component({
  selector: 'app-rubic-scale',
  templateUrl: './rubic-scale.component.html',
  styleUrls: ['./rubic-scale.component.css']
})
export class RubicScaleComponent implements AfterViewInit {
 
  constructor(private dialog: MatDialog,
    private permissionService: PermissionService,
    private roleService: RoleAndPermissionService,
    private rubicService: RubicGradingScaleService,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private manageAssessmentService: ManageAssessmentAndGradingsService) {
      this.users = localStorage.getItem('currentUser');
      var currentUser = JSON.parse(this.users);
      if (currentUser != null) {
        this.user = currentUser;
      }
     }
  users: any;
  dialogRef: any;
  user: any;
  permissions: any;
  displayedColumns: string[] = ['rubicID', 'rubicName', 'actions'];
  defaultCriteria: Criteria = RubricDefaults.Criteria;
  RubicObject: Rubic = RubricDefaults.Rubic;
    // dataSource = ELEMENT_DATA;
    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    selection = new SelectionModel<PeriodicElement>(true, []);
    rubicScaleList: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
      ngAfterViewInit(): void {        
    }

    
    addEditRubic(rubicId: number = 0){
      let rubicData: Rubic = this.RubicObject;
      if(rubicId === 0)
      {
        this.initRubicDialog(rubicData);
      }
      else{
        this.rubicService.Get(rubicId).subscribe((responseData)=>{
          const response= JSON.parse(JSON.stringify(responseData));
          if(response && response.length > 0 ){
            rubicData = this.rubicService.transformRubicData(response);
             this.initRubicDialog(rubicData);
          }
          else
          {
            this.toaster.info('We cannot find the rubic in the database. Please contact Administartor.');
          }
        });
      }
    }

   initRubicDialog(rubicData: any){
     this.dialogRef = this.dialog.open(RubicDialogComponent, {
      width: '80%',
      data: {
        Collection: {
          defaultCriteria: this.defaultCriteria,
          rubicObject: rubicData,
          userID: this.user.userId,
          closeCallback: this.closeDialog.bind(this),
          refreshCallback: this.GetAllRubicScale.bind(this)
        }
      },
      disableClose: true,
    });
   }

   closeDialog(){
      this.dialogRef.close();
   }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  //rubicScaleList:any;
  ngOnInit(): void {
    //Legasov Added 25 March 2022
this.permissionService.checkComponentHasScreenPermission('Grade Setup');
    this.permissions = this.permissionService.getAllPermissions();
    if(this.permissions.length == 0){
     this.roleService.getPermissionByUserID(this.user.userId).subscribe((res: any) => {
       if(res.status> 0){
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
       }
     })
    } 
    this.GetAllRubicScale();
  }
  GetAllRubicScale() {
    this.rubicScaleList = new MatTableDataSource();
    this.manageAssessmentService.getAllRubicScaleList().subscribe((res: any) => {
      if (res.status > 0) {
        this.rubicScaleList.data = res.responseData;
        this.rubicScaleList.sort = this.sort;
        this.rubicScaleList.paginator = this.paginator;
        this.rubicScaleList.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
      }
    });
  }

  deleteRubric(id: number) {
    this.dialogService
      .confirm(`Are you sure you want to delete rubric?`)
      .subscribe((result: any) => {
        if (result == true) {
          this.manageAssessmentService
            .deleteRubric(id)
            .subscribe((res: any) => {
              if (res.status > 0) {
                this.toaster.success(res.message);
                this.GetAllRubicScale();
              } else {
                this.toaster.error(res.message);
              }
            });
        }
      });
  }

  checkActionPermission(actionName: any){
    let screen = 'Grade Setup';
   //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
  //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
}
  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
}

export interface PeriodicElement {
	id: number; 
	name: string;	
	actions: any;
  }
  
  const ELEMENT_DATA: PeriodicElement[] = [
	  {id: 1, name: 'Default Rubic', actions: ' '},
    {id: 2, name: 'Common Rubric', actions: ' '},
	];
