import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { environment, profile, Student, Enrollments, masters, Email, Sms, Master, PaymentAccountDetail, DynamicFormModule } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpClient) { }

  AddStudent(data: any) {
    return this.http.post(
      `${environment.baseUrl}${Student.CreateCoreStudent}`,
      data
    );
  }
  UpdateCoreStudent(data: any) {
    return this.http.post(
      `${environment.baseUrl}${Student.UpdateCoreStudent}`,
      data
    );
  }
  UpdateStudentProfilePhoto(studentId: any, fileToUpload: File) {
    let formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      //'Content-Type': 'application/json',
      //'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${sessionStorage.getItem('AccessToken')}`,
    });
    return this.http.post(`${environment.baseUrl}${Student.UpdateStudentProfileImage}${studentId}`, formData);
  }
  ImportClassSectionSubjectExcel(formData: FormData) {
    return this.http.post(`${environment.baseUrl}${Student.importClassSectionStudentDataExcel}`, formData);
  }
  GetStudentList(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentList}`, data);
  }
  DeleteStudent(Id: any) {
    return this.http.get(`${environment.baseUrl}${Student.deleteStudent}` + '?id=' + Id);
  }

  UpdateStudent(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.updateStudent}`, data);
  }

  GetStudentById(Id: any) {
    return this.http.get(`${environment.baseUrl}${Student.getStudentByID}${Id}`);
  }

  GetStudentIdCardbyId(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.getStudentDetailsByIdAPI}${id}`)
  }


  GetStudentListById(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.getStudentDetailsByIdAPI}${'?id=' + id}`);
  }

  AddProfilePhoto(data: any) {
    return this.http.post(`${environment.baseUrl}${profile.getProfilePhoto}`, data);
  }

  getStudentsDatabyIdList(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentsDataByIdList}`, data);
  }
  getParentsDetails(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.getParentDetails}${'?id=' + id}`);
  }
  updateStudentCreditial(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.updateCreditial}`, data);
  }
  getInvoiceandFeesByid(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.getInvoiceandfeesBystudentId}${id}`);
  }
  getClassScheduleById(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getclassSchedulebyId}`, data);
  }
  // common post method for all http requests
  // tslint:disable-next-line
  postData<T>(data: {}): Observable<any> {
    let url = profile.getProfilePhoto;
    let newHTTPParams = new HttpParams();
    // if (reqAPIParams != null) {
    //   reqAPIParams.forEach(element => {
    //     newHTTPParams = newHTTPParams.append(element.Key, element.Value);
    //   });
    // }
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }



  // attach base url
  private getUrl(url: string): string {
    return environment.baseUrl + url;
  }
  // common error handling method
  public handleError = (error: Response) => {
    // Do messaging and error handling here
    //this.errorhandler.handleError(error.status);
    return observableThrowError(error);
  };

  StudentEnrollment(data: any) {
    return this.http.post(`${environment.baseUrl}${Enrollments.studentEnrollmentAPI}`, data);
  }

  UpdateStudentEnrollmentStatus(data: any) {
    return this.http.post(`${environment.baseUrl}${Enrollments.updateStudentEnrollmentStatusAPI}`, data);
  }

  DeleteSubjectEnrollment(data: any) {
    return this.http.post(`${environment.baseUrl}${Enrollments.deleteElectiveSubjectEnrollmentAPI}`, data);
  }

  DeleteStudentEnrollment(data: any) {
    return this.http.post(`${environment.baseUrl}${Enrollments.deleteStudentEnrollmentAPI}`, data);
  }
  UpdateSubjectEnrollmentStatus(data: any) {
    return this.http.post(`${environment.baseUrl}${Enrollments.updateSubjectEnrollmentStatusAPI}`, data);
  }
  GetStudentEnrollmentDetailsId(id: any) {
    return this.http.get(`${environment.baseUrl}${Enrollments.getEnrollmentDetailsBystudentIDAPI}${id}`);
  }

  GetStudentEnrollmentTabDetailsId(id: any) {
    return this.http.get(`${environment.baseUrl}${Enrollments.getSubjectEnrollmentTabDetailsBystudentIDAPI}${'?id=' + id}`);
  }
  GetSubjectEnrollmentDetailsId(id: any) {
    return this.http.get(`${environment.baseUrl}${Enrollments.getSubjectEnrollmentDetailsBystudentIDAPI}${'?studentID=' + id}`);
  }
  SubjectEnrollment(data: any) {
    return this.http.post(`${environment.baseUrl}${Enrollments.subjectEnrollmentAPI}`, data);
  }
  GetStudentTablecolumn(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.getStudentTableCustomColumn}`);
  }
  UpdateStudentTable(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.updateStudentTableColumn}`, data)
  }
  GetStudentByMultiId(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentByMultiId}`, data);
  }

  postStudentDocData<T>(data: {}): Observable<any> {
    let url = Student.uploadStudentDocument;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }
  postStudnetDocumentDetails(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.postStudentDocumentDetails}`, data);
  }
  GetStudentAttachList(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentAttachList}`, data);
  }
  getAttachmentDetailsId(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.getStudentAttachById}` + '?id=' + id);
  }
  downloadFile(path: any) {
    // return this.http.get(`${environment.baseUrl}${Student.downloadfile}` + '?filepath=' + id);
    return this.http.get(`${environment.baseUrl}${Student.downloadfile}` + "?filepath=" + path, { responseType: 'blob' });
  }
  deleteAttachmentDetailsId(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.deleteStudentAttachById}` + '?id=' + id);
  }
  GetStudentByIdData(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentByIdData}`, data);
  }
  StudentSentEmail(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.sentStudentEmail}`, data);
  }

  CommonEmailSenderNotification(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.CommonEmailSenderNotification}`, data);
  }
  StudentLoginInvitationEmail(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.StudentLoginInvitationEmail}`, data);
  }

  SendEmailToStudents(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.sendEmailToStudent}`, data);
  }

  // GetStudentAlumniList(data: any) {
  //   return this.http.post(`${environment.baseUrl}${Student.getStudentAlumniList}`, data);
  // }
  getDynamicStudentAlumniList(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getalumniList}`, data);
  }
  AddUpdateDynamicAlumniColumn(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.AddUpdateDynamicAlumniFields}`, data);
  }
  RevertStudentAlumni(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.revertStudentAlumni}`, data);
  }
  StudentSentSms(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.sentStudentSms}`, data);
  }
  ChangeStudentStatus(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.blockUnblockStudent}`, data);
  }
  AddStudentToAlumni(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.addStudentToAlumni}`, data);
  }
  DeleteMultipleStudent(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.deleteMultipleStudent}`, data);
  }
  GetMultipleTeacherData(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.getMultipleTeacherDataById}`, data);
  }

  GetMultipleAdminData(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.getMultipleAdminStaffDataVyId}`, data);
  }
  GetStudentDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getStudentDropDownData}`);
  }
  GetStudentDropdownNew() {
    return this.http.get(`${environment.baseUrl}${masters.getStudentDropDownDataNew}`);
  }

  GetStudentDropdownByClassSection(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.getStudentDropDownDataNewByClassSection}`, data);
  }
  GetTeacherDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getTeacherDropDownData}`);
  }
  getAllTeacher() {
    return this.http.get(`${environment.baseUrl}${masters.getAllTeacher}`);
  }
  GetAcademicSessionDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getAcademicSessionDropdown}`);
  }
  AllClass() {
    return this.http.get(`${environment.baseUrl}${masters.getAllClassesAPI}`);
  }
  SubjectEnrollmentStatusDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.geSubectEnrollmentStateusDropdown}`);
  }

  // SentEmailMaster(data: any) {
  //   return this.http.post(`${environment.baseUrl}${Email.sentEmailAPI}`, data);
  // }
  customtemplateEmail(arr: any, subject: any, body: any, orgId: any, fromEmail: any, fileToUpload: any, isFileAttached: any, IsTestingEmail: any) {
    const formData: FormData = new FormData();
    for (var i = 0; i < fileToUpload.length; i++) {
      formData.append("files", fileToUpload[i]);
    }
    formData.append('toEmailMutiple', arr);
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('fromEmail', fromEmail);
    formData.append('IsFileAttached', isFileAttached)
    formData.append('OrganizationID', orgId)
    formData.append('IsTestingEmail', IsTestingEmail)
    console.log("FINALDATAEMAIL", formData)
    return this.http.post(`${environment.baseUrl}${Email.sentEmailAPI}`, formData);
  }
  studentEmailAttachment(studentId: any, arr: any, subject: any, body: any, orgId: any, fromEmail: any, fileToUpload: any, isFileAttached: any, IsTestingEmail: any,
    IsEmailSendToStudents: any, IsEmailSendToParents: any, IsEmailSendToCustom: any) {
    const formData: FormData = new FormData();
    for (var i = 0; i < fileToUpload.length; i++) {
      formData.append("files", fileToUpload[i]);
    }
    formData.append('studentID', studentId);
    formData.append('toEmailMutiple', arr);
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('fromEmail', fromEmail);
    formData.append('IsFileAttached', isFileAttached)
    formData.append('OrganizationID', orgId)
    formData.append('IsTestingEmail', IsTestingEmail)
    formData.append('IsEmailSendToStudents', IsEmailSendToStudents)
    formData.append('IsEmailSendToParents', IsEmailSendToParents)
    formData.append('IsEmailSendToCustom', IsEmailSendToCustom)

    console.log("FINALDATAEMAIL", formData)
    return this.http.post(`${environment.baseUrl}${Email.sentEmailAPI}`, formData);
  }
  ChangeAlumniStudentStatus(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.blockUnblockAlumniStudent}`, data);
  }
  DeleteMultipleAlumniStudent(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.deleteMultipleAlumniStudent}`, data);
  }
  StudentReportcardEnableDisable(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.StudentreportcadEnableDisable}`, data);
  }
  GtEmailMaster(data: any) {
    return this.http.post(`${environment.baseUrl}${Email.getEmailAPI}`, data);
  }
  // GetEmailTemplateById(id:any) {
  //   return this.http.post(`${environment.baseUrl}${Email.getEmailTemplateById}`+ '?id=' + id);
  // }
  GetEmailTemplateById(id: any) {
    return this.http.get(`${environment.baseUrl}${Email.getEmailTemplateById}` + '?id=' + id)
  }
  GetCuastomEmailTemplate() {
    return this.http.get(`${environment.baseUrl}${Email.getCustomEmailTemplate}`)
  }
  getEmailStatistics() {
    return this.http.get(`${environment.baseUrl}${Email.getEmailStatisticsAPI}`);
  }
  SentSmsMaster(data: any) {
    return this.http.post(`${environment.baseUrl}${Sms.sentSmsAPI}`, data);
  }
  GetSentSmsMasterList(data: any) {
    return this.http.post(`${environment.baseUrl}${Sms.getSmsAPI}`, data);
  }

  SaveSmsCustomGrid(data: any) {
    return this.http.post(`${environment.baseUrl}${Sms.saveSMSCustomGrid}`, data);
  }
  GetSmsCustomGrid() {
    return this.http.get(`${environment.baseUrl}${Sms.getSMSCustomGrid}`);
  }

  GetSentSmsStatistics() {
    return this.http.get(`${environment.baseUrl}${Sms.getSmsStatisticsAPI}`);
  }

  GetSentSmsEmailStatistics() {
    return this.http.get(`${environment.baseUrl}${Sms.getSmsEmailStatisticsAPI}`);
  }

  GetPayPClientId() {
    return this.http.get(`${environment.baseUrl}${Master.getPPkey}`);
  }
  PostTransaction(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.postTransaction}`, data);
  }
  UpdateOrganization(data: any) {
    return this.http.post(`${environment.baseUrl}${Master.UpdateOrganization}`, data);
  }
  GetAlumniTableColumn(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.getAlumnistudentColumn}${'?id=' + id}`);
  }
  UpdateAlumniTableColumn(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.updateAlumniStudentColumn}`, data)
  }
  GetStudentAttendanceByDate(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentAttendanceByDate}`, data)
  }
  GetAttendanceLog(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentAttendanceLog}`, data)
  }
  GetCalendarAttendanceByStudentID(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getCalendarAttendanceByStudentID}`, data)
  }
  saveStudentAttendance(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.saveStudentAttendanceByDate}`, data)
  }

  GetStudentAnalyticsRecords(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentAnalyticsRecords}`, data);
  }

  GetStudentAnalyticsRecordsFinal(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getStudentAnalyticsRecordsFinal}`, data);
  }

  GetSubjectTeacherForClasssection(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getSubjectTeacher}`, data);
  }
  GetTeacherForMultiClasssection(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getTeacherForMultiClassSection}`, data);
  }

  GetStudentClassSectionForAnalytics() {
    return this.http.get(`${environment.baseUrl}${Student.getStudentClassSetionForAnalutics}`);
  }
  GetCombineClassSectionForAnalytics() {
    return this.http.get(`${environment.baseUrl}${Student.getCombineClassSection}`);
  }

  GetAdminDropdown() {

    return this.http.get(`${environment.baseUrl}${masters.getAdminDropDown}`);
  }

  GetAlumniParentDropdown() {

    return this.http.get(`${environment.baseUrl}${masters.getAlumniParentDropDown}`);
  }
  GetParentDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getParentDropdown}`);
  }
  StudentEnrollmentStatusDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getEnrollmentStateusDropdown}`);
  }
  GetActiveParentDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getActiveParentDropDown}`);
  }
  GetStudentByEnrollmentAndClass(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.getStudentBYEnrollmentAndClass}`, data)
  }
  GetStudentByEnrollmentAndSubject(data: any) {
    return this.http.post(`${environment.baseUrl}${masters.getStudentByEnrollmentAndSubject}`, data)
  }

  GetAllElectiveSubject(data: any) {
    return this.http.post(`${environment.baseUrl}${Enrollments.getAllElectiveSubjectAPI}`, data);
  }

  GetPaymentAccountDetail(data: any) {
    return this.http.get(`${environment.baseUrl}${PaymentAccountDetail.GetPaymentAccountDetail}${data}`);
  }
  CreateUpdatePaymentAccountDetail(data: any) {
    return this.http.post(`${environment.baseUrl}${PaymentAccountDetail.CreateUpdatePaymentAccountDetail}`, data);
  }
  GetAllPaymentAccountDetail() {
    return this.http.get(`${environment.baseUrl}${PaymentAccountDetail.GetAllPaymentAccountDetail}`);
  }
  UpdatePaymentAccountDetail(data: any) {
    return this.http.post(`${environment.baseUrl}${PaymentAccountDetail.UpdatePaymentAccountDetail}`, data);
  }
  SendPrivateNote(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.SendPrivateNote}`, data);

  }
  SendReview(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.SendReview}`, data);

  }
  FilteredSMS(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.FilteredSMSToShow}`, data);

  }
  FilteredEmail(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.FilteredEmailToShow}`, data);

  }
  sendEmail(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.sendEmail}`, data);
  }

  ImportStudents(fileToUpload: any) {
    ////debugger;
    let input = new FormData();
    input.append("files", fileToUpload);
    return this.http.post(`${environment.baseUrl}${Student.importStudentApi}`, input);
  }

  UploadFiles(fileToUpload: any) {
    return this.http.post(`${environment.baseUrl}${masters.uploadFilesAPI}`, fileToUpload);
  }

  ImportExcelFile(fileToUpload: File, mapping: Object, isUpdateExisting: string, isAlumni: string) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('mapping', JSON.stringify(mapping));
    formData.append('IsUpdateExisting', isUpdateExisting);
    formData.append('IsAlumni', isAlumni);
    return this.http.post(`${environment.baseUrl}${Student.importStudent}`, formData);
  }

  EmailAttachment(arr: any, subject: any, body: any, organizationID: any, fromEmail: any, fileToUpload: any, isFileAttached: any) {
    const formData: FormData = new FormData();
    for (var i = 0; i < fileToUpload.length; i++) {
      console.log("INSIDEDATAT IFF", formData);
      formData.append("files", fileToUpload[i]);
    }
    formData.append('toEmailMutiple', arr);
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('fromEmail', fromEmail);
    formData.append('IsFileAttached', isFileAttached)
    return this.http.post(`${environment.baseUrl}${Student.sentStudentEmail}`, formData);
  }

  ImportStatusReport(importProcessId: number) {
    return `${environment.baseUrl}${Student.getStatusReport}${'?id=' + importProcessId}`
  }
  getStatusReport(link: number): Observable<Blob> {
    return this.http.get(this.ImportStatusReport(link), { responseType: 'blob' });
  }

  exportFile(formType: string, columnList: string, academicSessionId: string, isAlumni: boolean): Observable<Blob> {
    const formData: FormData = new FormData();
    formData.append('FormType', formType);
    formData.append('ColumnList', columnList);
    formData.append('AcademicSessionId', academicSessionId || String(0));
    formData.append('IsAlumni', String(isAlumni));
    return this.http.post(`${environment.baseUrl}${Student.exportStudent}`, formData, { responseType: 'blob' });
  }

  DownloadTemplate(): Observable<Blob> {
    return this.http.get(`${environment.baseUrl}${Student.downloadCsvForImport}`, { responseType: 'blob' });
  }

  // GetEmailTemplateById(id:any){
  //   ////debugger;
  //   return this.http.get(`${environment.baseUrl}${masters.getEmailTemplateById}`+ '?id=' + id);
  // }

  GetSmsCreditLog(id: any, keyword: any, date: any) {
    //return this.http.get(`${environment.baseUrl}${Sms.getSmsCreditLog}${data}`);?id=4&KeyWord=gffg&CreatedDate=656
    return this.http.get(`${environment.baseUrl}${Sms.getSmsCreditLog}${id}${'&KeyWord=' + keyword}${'&CreatedDate=' + date}`);
  }
  GetEmailCreditLog(id: any, keyword: any, date: any) {
    return this.http.get(`${environment.baseUrl}${Email.getEmailCreditLog}${id}${'&KeyWord=' + keyword}${'&CreatedDate=' + date}`);

  }
  GetCustomFormById(id: any) {
    return this.http.get(
      `${environment.baseUrl}${Student.customFormByIdApi}${'?id=' + id}`
    );
  }
  GetStudentByID(id: any) {
    return this.http.get(
      `${environment.baseUrl}${Student.GetDynamicFormDetailsByStudentID}${'?id=' + id}`
    );
  }
  AddUpdateDynamicFormGroupFields(data: any) {
    return this.http.post(
      `${environment.baseUrl}${Student.AddUpdateDynamicFormGroupFields}`, data
    );
  }

  CreateEditDeleteGroup(data: any) {
    return this.http.post(
      `${environment.baseUrl}${Student.CreateEditDeleteGroup}`, data
    );
  }
  // GetEmailTemplateById(id:any){
  //   ////debugger;
  //   return this.http.get(`${environment.baseUrl}${masters.getEmailTemplateById}`+ '?id=' + id);
  // }

  getStudentTableList(data: any) {
    return this.http.post(
      `${environment.baseUrl}${DynamicFormModule.getStudentTableList}`, data
    );
  }
  getStudentCount() {
    return this.http.get(
      `${environment.baseUrl}${DynamicFormModule.getStudentCount}`
    );
  }

  addHideandShowList(data: any) {
    return this.http.post(
      `${environment.baseUrl}${DynamicFormModule.addShowHideDynamicList}`, data
    );
  }
  GetdynamicStudentByID(id: any) {
    return this.http.get(
      `${environment.baseUrl}${Student.getcoreStudentDetailById}${'?id=' + id}`
    );
  }
  deletecoreStudent(data: any) {
    return this.http.post(
      `${environment.baseUrl}${Student.deletecoreStudent}`, data
    );
  }

  getColumnsForExport(formType: number) {
    return this.http.get(
      `${environment.baseUrl}${Student.getColumnsForExport}/${formType}`
    );

  }

  getStudentPaidAndUnPaidAmount(id: any) {
    return this.http.get(`${environment.baseUrl}${Student.studentInvoicepaidandunpaidAmount}${'?id=' + id}`);
  }
  getAllInvoicesByStudentId(data: any) {
    return this.http.post(`${environment.baseUrl}${Student.getAllInvoicesByStudentId}`, data
    );
  }

  GetStudentDynamicField() {
    return this.http.get(`${environment.baseUrl}${Student.GetStudentDynamicField}`);
  }

}
