import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Classes, environment, ManageAssessmentAndGradings, Subject } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ManageAssessmentAndGradingsService {

  constructor(private http: HttpClient) { }
  upsert(data: any){
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.upsert}`,data);
  }
  getGradeBook(AcademicSessionId: number,ClassId: number,SectionId: number,SubjectId: number,CategoryId: number = 0){
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.getGradeBook}${'?AcademicSessionId=' + AcademicSessionId}${'&ClassId=' + ClassId}${'&SectionId=' + SectionId}${'&SubjectId=' + SubjectId}${'&CategoryId=' + CategoryId}`);
  }
  addAssessmentCategory(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.addAssessmentCategoryAPI}`, data);
  }
  allAssessmentCategory(academicSessionID: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.getAssessmentCategoryList}${'?academicSessionID=' + academicSessionID}`);
  }

  getAssessmentCategoryById(Id: any, academicSessionID: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.getAssessmentCategoryByID}${'?assessmentCategoryID=' + Id}${'&academicSessionID=' + academicSessionID}`);
  }

  editAssessmentCategory(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.updateAssessmentCategory}`, data);
  }

  deleteAssessmentCategory(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.deleteAssessmentCategory}${'?id=' + Id}`);
  }

  AddWeightedSubjectAndCategorySetting(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.AddWeightedSubjectAndCategorySetting}`, data);
  }

  getWeightedSubjectAndCategorySetting(academicSessionID:any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetWeightedSubjectAndCategorySettingByID}${'?academicSessionID=' + academicSessionID}`);
  }

  UpdateGradingPeriod(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.UpdateGradingPeriodAPI}`, data);
  }
  GetGradingPeriod() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.getGradingPeriodAPI}`);
  }

  UpdateReportCardsAndGradeBookDisplay(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.updateReportCardsAndGradeBookDisplayAPI}`, data);
  }
  GetReportCardsAndGradeBookDisplay() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.getReportCardsAndGradeBookDisplayAPI}`);
  }

  addUpdateReportCardFormatAPI(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.addReportCardFormatAPI}`, data);
  }

  getReportCardFormatById(Id:any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.getReportCardFormatByIdAPI}${'?reportCardsFormatID=' + Id}`);
  }

  deleteReportCardFormatById(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.deleteReportCardFormatByIdAPI}${'?id=' + Id}`);
  }

  GetReportCardFormat(academicSessionID: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.getReportCardFormatList}${'?academicSessionID=' + academicSessionID}`);
  }
  
  GetAllGradeMasterDataList() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllGradeMasterDataList}`);
  }

  GetAllGradingscaleList(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllGradingscaleList}${'?academicSessionID=' + Id}`);
  }

  getAllRubicScaleList(){
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllRubicScaleList}`);
  }
  AddGradingScaleAPI(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.addGradingScaleAPI}`, data);
  }
  GetGradingScaleByID(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetGradingScaleById}${'?id=' + Id}`);
  }
  UpdateGradingScaleByID(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.UpdateGradingScaleById}`,data);
  }
  deleteGradingScale(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.deleteGradingScaleAPI}${'?id=' + Id}`);
  }
  AddManageCategoryWeights(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.addManageCategoryWeightsAPI}`, data);
  }
  GetAllAssessmentList(data:any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAssessmentListAPI}`, data);
  }

  AddAssessment(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.AddAssessmentAPI}`, data);
  }

  sendNotificationById(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.sendAssessmentNotificationAPI}${'?assessmentID=' + Id}`);
  }

  CopyAssessment(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.CopyAssessmentAPI}`, data);
  }

  GetClassAndSectionList(){
    return this.http.get(`${environment.baseUrl}${Classes.getClassandSection}`);
  }

  AllSubject() {
    return this.http.get(`${environment.baseUrl}${Subject.getAllSubjectAPI}`);
  }

  
  getAssessmentById(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAssessmentByIDAPI}${'?assessmentID=' + Id}`);
  }

  deleteAssessment(data:any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.DeleteAssessmentAPI}`, data);
  }

  PublishAndUnpublish(data:any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.PublishAndUnpublishAPI}`, data);
  }

  GetAllReportCardMasterFields() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllReportCardMasterFieldsAPI}`);
  }

  
  CreateReportCardFieldDetails(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.CreateReportCardFieldDetailsAPI}`, data);
  }
  
  GetAllReportCardFields(Id:number) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllReportCardFieldsAPI}`  + '?id=' + Id);
  }

  GetAllStudentField() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllStudentFieldAPI}`);
  }

  GetAllAttendanceField() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllAttendanceFieldAPI}`);
  }

  GetAllStudent() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllStudentAPI}`);
  }

  GetAllStudentID(studentID: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllStudentIDAPI}${'?studentID=' + studentID}`);
  }

  GetStudentAssignedTemplate(StudentID: bigint, ClassID: number, SectionID: number) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetStudentAssignedTemplate}${'?studentID=' + StudentID}${'&classID=' + ClassID}${'&sectionID=' + SectionID}`);
  }

  GetAssignedTemplateDetails(Id: number) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAssignedTemplateDetails}`  + '?id=' + Id);
  }
  
  GetAllReportCardTemplate() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllReportCardTemplateAPI}`);
  }
  GetAllReportCardGroup() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllReportCardGroupAPI}`);
  }

  GetWeightBySubjectIDClassIDSectionID(SectionID: number, ClassID: number, SubjectID: number) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetWeightBySubjectIDClassIDSectionID}${'?sectionID=' + SectionID}${'&classID=' + ClassID}${'&subjectID=' + SubjectID}`);
  }

  CopySubjectWeightToOtherSubject(data: any){
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.CopySubjectWeightToOther}`, data);
  }
  GetAssessmentDropDownByClassSectionID(data: any){
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAssessmentDropDownByClassSectionID}`, data);
  }

  EnableDisableReportCard(data:any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.ReportCardEnableOrDisable}`,data);
  }

  CheckReportCardEnableDisable(StudentID: number,ClassID: number,SectionID: number) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.CheckReportCardEnableOrDisable}${'?studentID=' + StudentID}${'&classID=' + ClassID}${'&sectionID=' + SectionID}`);
  }

  exportGradeBook(data:any):Observable<Blob>{
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.ExportGradeBookUrl}`,data,{responseType:'blob'});
  }

  getGradeBookLogs(data:any){
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.LogGradeBookUrl}`, data);   
  }

  getColumnsForImport(data:any){
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.ColumnsForImport}`, data);   
  }

  ImportFile(formData:any ):Observable<Blob>{
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.ImportFile}`,formData,{responseType:'blob'}); 
  }

  ImportExcelFile(formData:any) {
    
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.ImportExcelGradeBook}`, formData);
  }

  getFieldsForDropdownOptions(data:any){
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.GetStudentGradeBookFields}`,data);
  }

  GetAllGradingScaleTypesList() {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetAllGradingScaleTypesList}`);
  }

  GetImportGradeStatusReport(id:Number):Observable<Blob> {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetImportGradeStatusReport}${'?id=' + id}`,{responseType: 'blob'});
  }
 
  GetReportCardDetailById(ReportCardsFormatID:any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.GetReportCardDetailById}${'?ReportCardsFormatID=' + ReportCardsFormatID}`);
  }
  UpdateReportCardFieldDetails(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.UpdateReportCardFieldDetails}`, data);
  }

  deleteRubric(Id: any) {
    return this.http.get(`${environment.baseUrl}${ManageAssessmentAndGradings.deleteRubricAPI}${'?id=' + Id}`);
  }
  sendReportCrad(data: any) {
    return this.http.post(`${environment.baseUrl}${ManageAssessmentAndGradings.SendReportCard}`, data);
  }
}
