<div class="reportCardsWidget mt-3 mt-md-0">
    <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex btnsWidget reportButtons">
        </div>
    </div>

    <div class="row-flex">
        <div class="col-md-4 col-xl-3 s2">
            <div class="manageCardLeft">
                <div class="template-group-title my-2">Report Card Settings</div>
                <div *ngIf="instituteGroup != 0" class="sub-head">Institute</div>
                <div class="list">
                    <div *ngFor="let item of instituteGroup; let i = index">
                        <div *ngIf="item.fieldName == 'Custom title'" class="withcmts">
                            <mat-checkbox  (change)="itemOnChangeInstitute(i)" [checked]="item.checked"
                                class="example-margin matbox">Custom title
                            </mat-checkbox>
                            <a class="template-group-title text-underline addcomment">Add Comments</a>
                        </div>
                        <mat-checkbox *ngIf="item.fieldName != 'Custom title'" (change)="itemOnChangeInstitute(i)" [checked]="item.checked" class="example-margin matbox">
                            {{ item.fieldName }}
                        </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="studentGroup != 0" class="sub-head">Student</div>
                <div class="list">
                    <mat-checkbox  *ngFor="let item of studentGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeStudent(i)">{{ item.fieldName }}</mat-checkbox>
                </div>
             
                <div *ngIf="assessmentsGroup != 0" class="sub-head">Assessment</div>
                <div class="list">                 
                    <div *ngFor="let item of assessmentsGroup; let i = index">
                        <mat-checkbox class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAssessments(i)">{{ item.fieldName }}</mat-checkbox>
                    </div>
                </div>
                <div *ngIf="attendanceGroup.length != 0" class="sub-head">Attendance</div>
                <div class="list">
                    <mat-checkbox *ngFor="let item of attendanceGroup; let i = index" class="example-margin matbox" [checked]="item.checked" (change)="itemOnChangeAttendance(i)">{{ item.fieldName }}</mat-checkbox>
                </div>
                <div *ngIf="othersGroup != 0" class="sub-head">Others</div>
                <div class="list">
                    <div *ngFor="let item of othersGroup; let i = index">
                        <mat-checkbox  [checked]="item.checked" (change)="itemOnChangeOthers(i)" class="example-margin matbox">{{ item.fieldName }} </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class=" col-md-8 col-xl-9 s10">

            <div class="report-card">
                <div class="report-custom">
                    <div class="report-card-header-block pb-0">
                        <div class="row">
                            <div class="col-md-4 extra-header-info" style="display: flex;align-items: center;">
                                <div class="text-left inst-extra-info">
                                    <div class="inst-name"><b style="font-size: 14px !important;">Kingdom of Saudi Arabia</b></div>
                                    <div class="contact"><b style="font-size: 14px !important;">Ministry of Education</b></div>
                                    <div class="contact"><b style="font-size: 14px !important;">Private Education Deputy</b></div>
                                    <div class="contact"><b style="font-size: 14px !important;">International and Foreign office in Riyadh</b></div>
                                </div>
                            </div>
                            <div class="col-md-4 inst-info-block">
                                <div class="text-center d-flex">
                                    <img *ngIf="instituteGroup | customRepotCard: 'Logo'" class="arabicsoc" src="../../../../../../../assets/img/arabic_sosiety.png" alt="logo" style="width: 65px;">
                                   <img src="../../../.../../../../../../assets/img/arabic logo.png" alt="logo" style="width: 300px;">
                                </div>
                            </div>
                            <div class="col-md-4 inst-info-block">
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>

                  

                    <div class="reportcardtitile">
                        <div class="row">
                            <div class="col-4 extra-header-info mx-15">
                                <div *ngIf="instituteGroup | customRepotCard: 'Institute name'" class="inst-name"><b style="font-size: 14px !important;">Edu.Line International School</b></div>
                                <div class="contact"><b style="font-size: 14px !important;">Curriculum: American</b></div>
                                <div class="contact"><b style="font-size: 14px !important;">License number: 196073</b></div>
                            </div>
                            <div class="col-4 text-center">
                                <div class="reportcard">
                                    <b>First Semester Report Grade: 7</b>
                                </div>
                            <div *ngIf="studentGroup | customRepotCard: 'Academic session name'" class="reportcard">
                                <b>2020-2021 G
                                    </b>
                            </div>
                            <div class="reportcard">
                                <b> 1441 - 1442 H</b>
                            </div>
                            </div>
                            <div class="col-4">
                            
                            </div>
                        </div>
                    </div>
                    <div class="report-card-header-block pb-0">
                        <div class="row">
                            <div class="col-md-6 extra-header-info" style="display: flex;align-items: center;">
                              <div class="coloredboxed">
                                <div class="text-left inst-extra-info">
                                    <div class="inst-name" *ngIf="studentGroup | customRepotCard: 'Name'"><b style="font-size: 14px !important;">STUDENT’S NAME : DANA KHALID BIN ABDURREHMAN BIN
                                        MOHAMMAD ALYEMENI AL AJMI</b></div>
                                    <div class="contact"><b style="font-size: 14px !important;">Nationality : SAUDI</b></div>
                                    <div class="contact"><b style="font-size: 14px !important;">Place & Date of Birth : 02/11/2008 G RIYADH</b></div>
                                    <div class="contact"><b style="font-size: 14px !important;">Passport No. : -----------</b></div>
                                    <div *ngIf="studentGroup | customRepotCard: 'Admission_no'" class="contact"><b style="font-size: 14px !important;">ID No.: 1144804257</b></div>
                                    <div class="contact"><b style="font-size: 14px !important;">Admission Date : 2020-2021 G</b></div>
                                    <div class="contact"><b style="font-size: 14px !important;">Previous School : EDUCATIONAL AGE INTERNATIONAL</b></div>
                                    
                                </div>
                                  
                              </div>
                            </div>
                            <div class="col-md-6 inst-info-block">
                            
                            </div>
                           
                        </div>
                    </div>

                    <div class="report-card-header-block pb-0">
                        <div class="text-center">
                            <div class="reportcard headings">
                               <b>Student Marks</b>
                            </div>
                        </div>

                    </div>
                   
                    <div class="report-card-assessment-score-block pt-0">
                       
                        <div class="temp2-scores-block">
                            <div class="cat-view-options">
                                <table class="table table-bordered  table-vertical-center score-table">
                                    <thead class="arabic">
                                        <tr>
                                            <th class="text-left text-dark" colspan="1">Subjects</th>
                                            <th class="center text-dark" colspan="2">First Semester (50)</th>
                                            <th class="center text-dark" colspan="3">Credit Hours</th>
                                            <th class="center text-dark" colspan="4">Grading</th>
                                        </tr>
                                    </thead>
                                    <tbody class="arabic">
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>English</b></div></th>
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">61</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">62</th>
                                            <th class="center text-dark" colspan="4" style="vertical-align:middle;">A++</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Urdu</b></div></th>
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">61</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">62</th>
                                            <th class="center text-dark" colspan="4" style="vertical-align:middle;">A++</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Genral Scince</b></div></th>
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">61</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">62</th>
                                            <th class="center text-dark" colspan="4" style="vertical-align:middle;">A++</th>
                                        </tr>
                                        <tr>
                                            <th  colspan="1"><div class="text-dark"><b>Mathematics</b></div></th>
                                            <th class="center text-dark"  colspan="2" style="vertical-align:middle;">61</th>
                                            <th class="center text-dark" colspan="3" style="vertical-align:middle;">62</th>
                                            <th class="center text-dark" colspan="4" style="vertical-align:middle;">A++</th>
                                        </tr>

                                        <tr style="height:50px">
                                            <th  colspan="1" style="vertical-align: middle;"><div class="text-dark text-center" style="font-size: 18px !important;" ><b>Total</b></div></th>
                                            <th class="center text-dark text-center"  colspan="2" style="vertical-align:middle;" style="font-size: 18px !important;" ><b>375</b></th>
                                            <th class="center text-dark text-center" colspan="3" style="vertical-align:middle;" style="font-size: 18px !important;" ><b>10</b></th>
                                            <th class="center text-dark text-center" colspan="4" style="vertical-align:middle;" style="font-size: 18px !important;" ><b>A+</b></th>
                                        </tr>
                                  

                               
                                    </tbody>
                                </table>

                            </div>
                        </div>

                    </div>
                    <div *ngIf="othersGroup | customRepotCard: 'Signature block'" class="report-card-header-block mt-5">
                        <div class="row">
                            <div class="col-4 text-center">
                                <div class="inst-name"><b style="font-size: 14px !important;">School Principal</b></div>
                            </div>
                            <div class="col-4 text-center">
                                <div class="inst-name"><b style="font-size: 14px !important;">School Stamp</b></div>
                            </div>
                            <div class="col-4 text-center">
                                <div class="inst-name"><b style="font-size: 14px !important;">Exam Control</b></div>
                            </div>
                        </div>
                    </div>
                    <div class="reportcard-extra-block page-break-avoid">
                        <div class="row">
                            <div class="col-md-2 overall-score-grading-block">
                            </div>
                            <div class="col-md-8 attendance-checter-block">
                                <div class="report-other-info-block mt-3">
                                    <div class="text-center m-auto">
                                    <img *ngIf="instituteGroup | customRepotCard: 'Logo'" src="../../../../../../../assets/img/arabic2.png"alt="logo">
                                </div>
                                    <table *ngIf="othersGroup | customRepotCard: 'Grading structure for final score'"
                                        class="table table-bordered  table-vertical-center scores-table character-traits-table arabic">
                                        <thead class="arabic">
                                            <tr>
                                                <th class="center">Marks</th>
                                                <th class="center">Rank</th>
                                                <th class="center">G.P.A</th>
                                            </tr>
                                        </thead>
                                        <tbody class="arabic">
                                            <tr>
                                                <th class="center">100 - 95</th>
                                                <th class="center">A+</th>
                                                <th class="center">4</th>
                                            </tr>
                                   
                                            <tr>
                                                <th class="center">94 - 90</th>
                                                <th class="center">A</th>
                                                <th class="center">3.5</th>
                                            </tr>
                                            <tr>
                                                <th class="center">89 - 85</th>
                                                <th class="center">B+</th>
                                                <th class="center">3</th>
                                            </tr>
                                            <tr>
                                                <th class="center">84 - 80</th>
                                                <th class="center">B</th>
                                                <th class="center">2.5</th>
                                            </tr>
                                            <tr>
                                                <th class="center">79 - 73</th>
                                                <th class="center">C+</th>
                                                <th class="center">2</th>
                                            </tr>
                                            <tr>
                                                <th class="center">72 - 65</th>
                                                <th class="center">C</th>
                                                <th class="center">1.5</th>
                                            </tr>
                                            <tr>
                                                <th class="center">65 - 50</th>
                                                <th class="center">D</th>
                                                <th class="center">1</th>
                                            </tr>
                                            <tr>
                                                <th class="center">Less than 50</th>
                                                <th class="center">F</th>
                                                <th class="center">0</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-2 overall-score-grading-block"></div>

                        </div>

                    </div>
                    <div class="text-center">
                        <p class="mb-2"><b>* Subject : French, Physical Education, Conduct, Attendance not included in total.</b></p>
                    </div>

                    <div class="text-center">
                        <p *ngIf="instituteGroup | customRepotCard: 'Contact'" class="mt-3"><b>Contact Us : </b>0118033373</p>
                    </div>

                    <div class="text-center">
                        <p *ngIf="instituteGroup | customRepotCard: 'Email'" class=""><b>E-mail :</b>edulinesc@hotmail.com</p>
                    </div>
                    <div class="text-center">
                        <p class=""><b>Facebook :</b>www.facebook.com/pages/Edu.Line-International-Schoo l</p>
                    </div>
                    <div class="text-center">
                        <p *ngIf="instituteGroup | customRepotCard: 'Institute address'" class="" ><b>Address :</b>Exit 22, Al Faryan Bridge, South Ring Road, Riyadh, K.S.A</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="btnsWidget form-group17 text-right">
    <button type="button" (click)="onSubmit()" class="btn btn-primary btn-sm m-0 ">Save</button>
    <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Cancel</button>
</div>