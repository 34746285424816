import { V } from '@angular/cdk/keycodes';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment, ManageTheme } from './environments/environment';


let themeID = 'num' + localStorage.getItem('ThemeID')?.toString();
var getXray = Object.entries(ManageTheme).find(([key, val]) => key === themeID);
if (getXray) {
  var varXray = getXray[1].toString();
  document.getElementById('chkThemeReplace')?.setAttribute('href', varXray);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
