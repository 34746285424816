import { NgModule } from '@angular/core';
import { BrowserModule,Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './common/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DialogComponent } from './common/dialog/dialog.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderComponent } from './app-layout/header/header.component';
import { JwtInterceptor } from './common/intercepters/jwt.interceptor';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatRippleModule } from '@angular/material/core';
import { NgxPayPalModule } from 'ngx-paypal';
//import { NgxStripeModule } from 'ngx-stripe';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
// import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportStatusReportLinkComponent } from './common/components/import-status-report-link/import-status-report-link.component';
import { MappingContainerComponent } from './common/components/mapping-container/mapping-container.component';
import { MapExcelInputComponent } from 'src/app/common/components/map-excel-input/map-excel-input.component';
import { ExportDialogComponent } from './common/components/export-dialog/export-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {RubicDialogComponent} from './common/components/rubic-grading-scale/rubic-grading-scale.component';
import { ImportDialogComponent } from './common/components/import-dialog/import-dialog.component';
import { MapStudentGradeImportComponent } from './common/components/map-student-grade-import/map-student-grade-import.component';
import { MappingContainerStudentGradeComponent } from './common/components/mapping-container-student-grade/mapping-container-student-grade.component';
import { ImportGradeBookStatusComponent } from './common/components/import-grade-book-status/import-grade-book-status.component'
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import { AcademicModule } from './app-layout/content/academics-and-learning/academic.module';
import { BnNgIdleService } from 'bn-ng-idle';

@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    ImportStatusReportLinkComponent,
    MappingContainerComponent,
    MapExcelInputComponent,
    ExportDialogComponent,
    RubicDialogComponent,
    ImportDialogComponent,
    MapStudentGradeImportComponent,
    MappingContainerStudentGradeComponent,
    ImportGradeBookStatusComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // NgxFileDropModule,
    SharedModule,
    MatRippleModule,
    MatCardModule,
    // HeaderComponent,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    HttpClientModule,
    MatDialogModule,
    // HttpClientModule,
    RichTextEditorAllModule,
    MatTableExporterModule,
    // LayoutModule
    NgxPayPalModule,
    //NgxStripeModule.forRoot('***your-stripe-publishable-key***')
    NgxSpinnerModule,
    MatAutocompleteModule,    
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    AcademicModule,
  ],
  entryComponents: [HeaderComponent],
  // providers: [ ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },Title, BnNgIdleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
