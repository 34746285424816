<!-- <p class="mb-3">No rubric structure added yet. You can start adding one.</p> -->
<p class="text-right mb-2" *ngIf="checkActionPermission('Add Rubric')"><button class="btn btn-primary btn-sm" (click)="addEditRubic()" matRipple><i class="fas fa-plus"></i> Add New</button></p>
<div class="mat-elevation-z8" *ngIf="checkActionPermission('Rubric List')">
 <table mat-table [dataSource]="rubicScaleList" matSort>
   
     <!-- ID Column -->
     <ng-container matColumnDef="rubicID">
       <th mat-header-cell *matHeaderCellDef> Id </th>
       <td mat-cell *matCellDef="let element"> {{element.rubicID}} </td>
     </ng-container>

     <!-- Name Column -->
     <ng-container matColumnDef="rubicName">
       <th mat-header-cell *matHeaderCellDef mat-sort-header> Category Name</th>
       <td mat-cell *matCellDef="let element"> {{element.rubicName}} </td>
     </ng-container>
    
       <!-- Duration Column -->
     <ng-container matColumnDef="actions">
         <th mat-header-cell *matHeaderCellDef> Actions </th>
         <td mat-cell *matCellDef="let element">
             <div class="actionsCol">
                 <!-- <i class="fas fa-arrows-alt"></i> -->
                 <mat-icon
                 *ngIf="checkActionPermission('Update Rubric')" (click)="addEditRubic(element.rubicID)">edit</mat-icon>
                 <mat-icon
                 *ngIf="checkActionPermission('Delete Rubric')" (click)="deleteRubric(element.rubicID)">delete</mat-icon>
             </div>
         </td>
       </ng-container>
   
       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
       <tr mat-row *matRowDef="let row; columns: displayedColumns;"
           (click)="selection.toggle(row)">
       </tr>
       <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">No rubric structure added yet. You can start adding one.</td>
    </tr>
 </table>
 <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of rubic"></mat-paginator>
 </div>  
 <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> --> 

<ng-template #addeditRubic>
    <div class="dialog-xll">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
         <h2 mat-dialog-title>Edit Rubric Scale</h2> 
      </div>
      <div class="">
        <div class="gradeListHead gradelistWidget">
            <div class="criteriaCol">Criteria</div>
            <div class="scaleItemsCol">Grading Scale</div>
            <div class="scalePointsCol center">Points</div>
        </div>
        <div class="gradeListrow gradelistWidget">
            <div class="titlesofRubic">
               <div class="criteriaCol titles" contenteditable="true">Title</div><br>
              <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
            </div>
            <div class="scaleItemsCol d-flex">
                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">5</div>
                    <div class="scaleText" contenteditable="true">Excellent</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">3</div>
                    <div class="scaleText" contenteditable="true">Good</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">2</div>
                    <div class="scaleText" contenteditable="true">Satisfactory</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">1</div>
                    <div class="scaleText" contenteditable="true">Needs Improvement</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>
                
            </div>
            <div class="scalePointsCol text-right">5</div>
            <mat-icon class="deleteIconRight">delete</mat-icon>
            <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
        </div>
        <div class="gradeListrow gradelistWidget">
   
            <div class="titlesofRubic">
              <div class="criteriaCol titles" contenteditable="true">Title</div><br>
             <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
           </div>
            <div class="scaleItemsCol d-flex">
                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">5</div>
                    <div class="scaleText" contenteditable="true">Excellent</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">3</div>
                    <div class="scaleText" contenteditable="true">Good</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">2</div>
                    <div class="scaleText" contenteditable="true">Satisfactory</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">1</div>
                    <div class="scaleText" contenteditable="true">Needs Improvement</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>
                
            </div>
            <div class="scalePointsCol text-right">5</div>
            <mat-icon class="deleteIconRight">delete</mat-icon>
        </div>
        <div class="gradeListrow gradelistWidget">
     
                <div class="titlesofRubic">
                  <div class="criteriaCol titles" contenteditable="true">Title</div><br>
                 <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
               </div>
            <div class="scaleItemsCol d-flex">
                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">5</div>
                    <div class="scaleText" contenteditable="true">Excellent</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">3</div>
                    <div class="scaleText" contenteditable="true">Good</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">2</div>
                    <div class="scaleText" contenteditable="true">Satisfactory</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>

                <div class="scaleWidgetPop">
                    <div class="scalePoint" contenteditable="true">1</div>
                    <div class="scaleText" contenteditable="true">Needs Improvement</div>
                    <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                </div>
                
            </div>
            <div class="scalePointsCol text-right">5</div>
            <mat-icon class="deleteIconRight">delete</mat-icon>
            <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
        </div>

        <div class="gradeListrowTotal gradelistWidget">
            <div class="criteriaCol"><a href="javascript:void(0)" class="underlinedLink">+Add Criteria</a></div>
            <div class="scaleItemsCol text-right">Total Points</div>
            <div class="scalePointsCol center">15</div>
        </div>
    </div>

    <div class="btnsWidget form-group17 text-right">
        <button type="button" matRipple class="btn btn-primary btn-sm" mat-dialog-close>Save</button>
        <button type="button" matRipple class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
    </div>  
      <!-- <mat-dialog-content class="mat-typography">
        
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Rubric Scale</mat-label>
            <input matInput placeholder="Default Rubric">        
        </mat-form-field>
        <mat-checkbox class="example-margin">Set Rubic Points for GPA Calculations</mat-checkbox>

        <div class="manageGradeList my-4">
            <div class="gradeListHead gradelistWidget">
                <div class="criteriaCol">Criteria</div>
                <div class="scaleItemsCol">Grading Scale</div>
                <div class="scalePointsCol text-right">Points</div>
            </div>
            <div class="gradeListrow gradelistWidget">
                
                <div class="titlesofRubic">
                    <div class="criteriaCol titles" contenteditable="true">Title</div><br>
                   <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
                 </div>
                <div class="scaleItemsCol d-flex">
                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">5</div>
                        <div class="scaleText" contenteditable="true">Excellent</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">3</div>
                        <div class="scaleText" contenteditable="true">Good</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">2</div>
                        <div class="scaleText" contenteditable="true">Satisfactory</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">1</div>
                        <div class="scaleText" contenteditable="true">Needs Improvement</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>
                    
                </div>
                <div class="scalePointsCol text-right">5</div>
                <mat-icon class="deleteIconRight">delete</mat-icon>
            </div>
            <div class="gradeListrow gradelistWidget">
               
                <div class="titlesofRubic">
                    <div class="criteriaCol titles" contenteditable="true">Title</div><br>
                   <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
                 </div>
                <div class="scaleItemsCol d-flex">
                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">5</div>
                        <div class="scaleText" contenteditable="true">Excellent</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">3</div>
                        <div class="scaleText" contenteditable="true">Good</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">2</div>
                        <div class="scaleText" contenteditable="true">Satisfactory</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">1</div>
                        <div class="scaleText" contenteditable="true">Needs Improvement</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>
                    
                </div>
                <div class="scalePointsCol text-right">5</div>
                <mat-icon class="deleteIconRight">delete</mat-icon>
            </div>
            <div class="gradeListrow gradelistWidget">
             
                    <div class="titlesofRubic">
                        <div class="criteriaCol titles" contenteditable="true">Title</div><br>
                       <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
                     </div>
                <div class="scaleItemsCol d-flex">
                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">5</div>
                        <div class="scaleText" contenteditable="true">Excellent</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">3</div>
                        <div class="scaleText" contenteditable="true">Good</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">2</div>
                        <div class="scaleText" contenteditable="true">Satisfactory</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>

                    <div class="scaleWidgetPop">
                        <div class="scalePoint" contenteditable="true">1</div>
                        <div class="scaleText" contenteditable="true">Needs Improvement</div>
                        <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                    </div>
                    
                </div>
                <div class="scalePointsCol text-right">5</div>
                <mat-icon class="deleteIconRight">delete</mat-icon>
            </div>

            <div class="gradeListrowTotal gradelistWidget">
                <div class="criteriaCol"><a href="javascript:void(0)" class="underlinedLink">+Add Criteria</a></div>
                <div class="scaleItemsCol text-right">Total Points</div>
                <div class="scalePointsCol text-right">15</div>
            </div>
        </div>

        <div class="btnsWidget form-group17 text-right">
            <button type="button" matRipple class="btn btn-primary btn-sm" mat-dialog-close>Save</button>
            <button type="button"matRipple class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
        </div>  
      </mat-dialog-content>     -->

    </div> 
  </ng-template>

  <ng-template #addedRubic>
    <div class="dialog-xll">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
           <h2 mat-dialog-title>Addddd Rubric Scale</h2> 
        </div>
        <mat-dialog-content class="mat-typography">
<!--           
          <mat-form-field class="w-100" appearance="outline">
              <mat-label>Rubric Scale</mat-label>
              <input matInput placeholder="Default Rubric">        
          </mat-form-field> -->

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Rubric Scale</mat-label>
            <input matInput type="text" formControlName="Default Rubric">
          </mat-form-field>

          <!-- <mat-checkbox class="example-margin">Set Rubic Points for GPA Calculations</mat-checkbox> -->
  
          <div class="manageGradeList">
              <div class="gradeListHead gradelistWidget">
                  <div class="criteriaCol">Criteria</div>
                  <div class="scaleItemsCol">Grading Scale</div>
                  <div class="scalePointsCol center">Points</div>
                  
              </div>
              <div class="gradeListrow gradelistWidget">
                  <div class="titlesofRubic">
                     <div class="criteriaCol titles" contenteditable="true">Title</div><br>
                    <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
                  </div>
                  <div class="scaleItemsCol d-flex">
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">5</div>
                          <div class="scaleText" contenteditable="true">Excellent</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">3</div>
                          <div class="scaleText" contenteditable="true">Good</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">2</div>
                          <div class="scaleText" contenteditable="true">Satisfactory</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">1</div>
                          <div class="scaleText" contenteditable="true">Needs Improvement</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
                      
                  </div>
                  <div class="scalePointsCol text-right">5</div>
                  <mat-icon class="deleteIconRight">delete</mat-icon>
                  <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
              </div>
              <div class="gradeListrow gradelistWidget">
         
                  <div class="titlesofRubic">
                    <div class="criteriaCol titles" contenteditable="true">Title</div><br>
                   <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
                 </div>
                  <div class="scaleItemsCol d-flex">
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">5</div>
                          <div class="scaleText" contenteditable="true">Excellent</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">3</div>
                          <div class="scaleText" contenteditable="true">Good</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">2</div>
                          <div class="scaleText" contenteditable="true">Satisfactory</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">1</div>
                          <div class="scaleText" contenteditable="true">Needs Improvement</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
                      
                  </div>
                  <div class="scalePointsCol text-right">5</div>
                  <mat-icon class="deleteIconRight">delete</mat-icon>
              </div>
              <div class="gradeListrow gradelistWidget">
           
                      <div class="titlesofRubic">
                        <div class="criteriaCol titles" contenteditable="true">Title</div><br>
                       <div class="criteriaCol subtitles" contenteditable="true">Sub title</div>
                     </div>
                  <div class="scaleItemsCol d-flex">
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">5</div>
                          <div class="scaleText" contenteditable="true">Excellent</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">3</div>
                          <div class="scaleText" contenteditable="true">Good</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">2</div>
                          <div class="scaleText" contenteditable="true">Satisfactory</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
  
                      <div class="scaleWidgetPop">
                          <div class="scalePoint" contenteditable="true">1</div>
                          <div class="scaleText" contenteditable="true">Needs Improvement</div>
                          <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
                      </div>
                      
                  </div>
                  <div class="scalePointsCol text-right">5</div>
                  <mat-icon class="deleteIconRight">delete</mat-icon>
                  <div class="scaleTexticons"><i class="fas fa-plus mr-1"></i><i class="fas fa-trash-alt"></i></div>
              </div>
  
              <div class="gradeListrowTotal gradelistWidget">
                  <div class="criteriaCol"><a href="javascript:void(0)" class="underlinedLink">+Add Criteria</a></div>
                  <div class="scaleItemsCol text-right">Total Points</div>
                  <div class="scalePointsCol center">15</div>
              </div>
          </div>
  
          <div class="btnsWidget form-group17 text-right">
              <button type="button" matRipple class="btn btn-primary btn-sm" mat-dialog-close>Save</button>
              <button type="button" matRipple class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
          </div>  
        </mat-dialog-content>    
      </div> 

  </ng-template>
