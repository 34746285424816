import { Component, TemplateRef, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import {VERSION} from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DepartmentService } from '../../../courses-and-subjects/services/department.service';
import { ClassService } from '../../../courses-and-subjects/services/classServices/class.service';
import { SectionService } from '../../../courses-and-subjects/services/sectionServices/section.service';
import { SubjectService } from '../../../courses-and-subjects/services/subjectServices/subject.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { map, pluck, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Title } from '@angular/platform-browser';

export interface Department {
  name: string;
}

export interface Subject {
  name: string;
}

export interface SubjectModel {
  subjectID: number;
  subjectName: string;
}

@Component({
  selector: 'app-wights-setting',
  templateUrl: './wights-setting.component.html',
  styleUrls: ['./wights-setting.component.css'],
})
export class WightsSettingComponent implements AfterViewInit {
  weightedSubjectAndCategorySettings: FormGroup;

  // categories = [
  //   {id: 1, name: 'Exams', percentage: '10.99'},
  //   {id: 2, name: 'Practicals', percentage: '21.98'},
  // ]
  departmentListMenu: any[];
  subjectListMenu: any[];
  copySubjectListMenu: any[];
  classListMenu: any;
  sectionsListMenu: any;
  subjectsListMenu: any[];
  allSubjectsList: any[];
  selectedDepartmentID: number;
  selectedClassID: number;
  selectedSectionID: number;
  dataSource: any;
  categories: any;
  checked: boolean;
  departmentChecked: boolean;
  totalWeight: number = 0;
  totalPercentage: number = 0;
  totalWeightAllSet: number = 0;
  totalPercentageAllSet: number = 0;
  filterType: number = 0;
  filterName: string;
  SubjectModelList: SubjectModel[];
  subjectID: number;
  sectionID: number;
  classID: number;

  ManageCategoryObj: {
    weightedSubjectAndCategorySettingID: number;
    classID: any;
    sectionID: any;
    subjectID: any;
    assessmentCategoryID: any;
    weight: any;
    weightresult: number;
    isAllSet: boolean;
  };

  isAllSet: boolean;
  //categories:any;
  users: any;
  user: any;
  academicSessionID: any;
  form: FormGroup;
  constructor(
    private dialog: MatDialog,
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private departmentService: DepartmentService,
    private classService: ClassService,
    private sectionService: SectionService,
    private subjectService: SubjectService,
    private formBuilder: FormBuilder
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
    if (currentUser != null) {
      this.user = currentUser;
    }
  }

  ngAfterViewInit(): void { }

  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
    this.isAllSet = false;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      filterType: [0, Validators.required],
      filterName: [''],
    });
    this.getAllDepartmentSubjectsList();
    this.getDepartmentList(0, '');
    this.getClassList();
    this.getSectionList();
    this.getAllSubject();
    this.GetDepartmentStatus();
    //this.GetAllAssessmentCategory();
    this.GetWeightedSubjectAndCategorySettings();
    this.weightedSubjectAndCategorySettings = this.fb.group({
      weightedSubjectAndCategorySettingID: [0],
      setupWeightedCategories: ['', Validators.required],
      displayOverallScore: ['', Validators.required],
      categoriesScoreCalculationWithoutGradeEntry: ['', Validators.required],
      gradingScalesID: ['', Validators.required],
    });
    this.autoCompleteFilter();
  }

  onChange($event: MatSlideToggleChange) {
    this.isAllSet = $event.checked;
    this.GetWeightedSubjectAndCategorySettings();

    this.totalWeightAllSet = 0;
    this.totalPercentageAllSet = 0;

  }

  saveManageWeightCategory(
    eventWeight: any,
    eventPercentage: any,
    subjectID: any,
    classID: any,
    sectionID: any,
    categoryID: any
  ) {
    this.ManageCategoryObj = {
      weightedSubjectAndCategorySettingID: 1,
      classID: classID,
      sectionID: sectionID,
      subjectID: subjectID,
      assessmentCategoryID: categoryID,
      weight: eventWeight,
      weightresult: eventPercentage,
      isAllSet: this.isAllSet,
    };
    this.manageAssessmentService
      .AddManageCategoryWeights(this.ManageCategoryObj)
      .subscribe((res: any) => {
        this.toaster.success("Updated successfully");
        if (res.statusCode == 200) {
          this.toaster.success(res.message);
        } else {
          this.toaster.error(res.message);
        }
      });
  }

  GetWeightedSubjectAndCategorySettings() {
    this.manageAssessmentService
      .getWeightedSubjectAndCategorySetting(this.academicSessionID)
      .subscribe((res: any) => {
        console.log("getWeightedSubjectAndCategorySetting", res);

        this.weightedSubjectAndCategorySettings.patchValue({
          weightedSubjectAndCategorySettingID:
            res.responseData['weightedSubjectAndCategorySettingID'],
          setupWeightedCategories: res.responseData['setupWeightedCategories'],
          displayOverallScore: res.responseData['displayOverallScore'],
          categoriesScoreCalculationWithoutGradeEntry:
            res.responseData['categoriesScoreCalculationWithoutGradeEntry'],
          gradingScalesID: res.responseData['gradingScalesID'],
        });
      });
  }

  submitSubjectAndCategorySettings() {
    if (this.weightedSubjectAndCategorySettings.invalid) {
      return;
    }
    this.manageAssessmentService
      .AddWeightedSubjectAndCategorySetting(
        this.weightedSubjectAndCategorySettings.value
      )
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
        } else {
          this.toaster.error(res.message);
        }
      });
  }

  getDepartmentList(filterType: number, filterName: string) {
    this.departmentService
      .getAllDepartmentCommonWeight(filterType, filterName)
      .subscribe((res: any) => {
        ////debugger;
        if (res.status > 0) {
          this.departmentListMenu = res.responseData;
          console.log('this.departmentListMenu', this.departmentListMenu);
          //this.departmentListMenu = this.extractData(res)
        }
      });
  }

  getAllDepartmentSubjectsList() {
    this.departmentService
      .getAllDepartmentCommonWeightSubjects()
      .subscribe((res: any) => {
        if (res.status > 0) {
          this.subjectListMenu = res.responseData;
          console.log('this.subjectListMenu', this.subjectListMenu);
          //this.departmentListMenu = this.extractData(res)
        }
      });
  }

  GetAllAssessmentCategory() {
    this.manageAssessmentService
      .allAssessmentCategory(this.academicSessionID)
      .subscribe((res: any) => {
        this.categories = res.responseData;
        this.categories.forEach((element: any) => {
          element['weight-'] = 0;
        });
        console.log('this.categories', this.categories);
      });
  }

  getClassList() {
    this.classService.AllClass().subscribe((res: any) => {
      this.classListMenu = res.responseData;
      console.log('CLASS', this.classListMenu);
    });
  }

  getSectionList() {
    this.sectionService.AllSection().subscribe((res: any) => {
      this.sectionsListMenu = res.responseData;
      this.SubjectBySectionID(this.sectionsListMenu[0].sectionID);
    });
  }

  reset() {
    this.weightedSubjectAndCategorySettings.reset();
  }

  panels = [
    {
      title: 'Physics',
      content: 'content 1',
    },
    {
      title: 'Maths',
      content: 'content 2',
    },
  ];

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  departments: Department[] = [
    // {name: 'Lemon'},
    // {name: 'Lime'},
    // {name: 'Apple'},
  ];

  subjects: Subject[] = [
    // {name: 'Lemon'},
    // {name: 'Lime'},
    // {name: 'Apple'},
  ];

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.departments.push({ name: value });
    }

    // Clear the input value
    // event.chipInput!.clear();
  }

  add1(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.subjects.push({ name: value });
    }

    // Clear the input value
    // event.chipInput!.clear();
  }

  remove(department: Department): void {
    const index = this.departments.indexOf(department);

    if (index >= 0) {
      this.departments.splice(index, 1);
    }
  }

  remove1(subject: Subject): void {
    const index = this.subjects.indexOf(subject);

    if (index >= 0) {
      this.subjects.splice(index, 1);
    }
  }

  GetDepartmentStatus() {
    this.departmentService.GetEnableAndDisable().subscribe((res: any) => {
      this.departmentChecked = res.responseData;
      console.log('Getresdep', this.departmentChecked);
    });
  }

  getAllSubject() {
    this.subjectService.AllSubjectsList().subscribe((res: any) => {
      if (res.status > 0) {
        this.allSubjectsList = res.responseData;
        console.log(' this.allSubjectsList', this.allSubjectsList);
      }
    });
  }

  onSectionClick(sectionId: any) {
    console.log('sectionId', sectionId);
    this.totalWeight = 0;
    this.totalPercentage = 0;
    this.SubjectBySectionID(sectionId);
  }
  chan: any;
  optionSelected(optionVal: number) {
    console.log('optionSelected', optionVal);
    this.chan = optionVal;
    this.getSearchVal()
  }

  // getSearchVal(searchVal: any) {
  //   console.log('getSearchVal', searchVal.target.value);
  //   if (this.filterType > 0) {
  //     this.getDepartmentList(this.filterType, searchVal.target.value);
  //   } else {
  //     this.toaster.error('Select Filter Option');
  //   }
  // }

  getSearchVal() {
    ////debugger;
    console.log('getSearchVal', this.form.value);
    if (this.form.value.filterType && this.form.value.filterName && this.chan) {
      this.getDepartmentList(this.form.value.filterType, this.form.value.filterName);
    }
    //else {
    //   this.toaster.error('Select Filter Option');
    // }
  }


  SubjectBySectionID(id: any) {
    this.selectedSectionID = id;
    console.log('sectionID', id);
    this.subjectService.GetSubjectBySectionId(id).subscribe((res: any) => {
      if (res.status > 0) {
        this.subjectsListMenu = res.responseData;
        console.log(' this.subjectsListMenu', this.subjectsListMenu);
      }
    });

    this.GetAllAssessmentCategory();
  }

  onKeyUp(
    event: any,
    assessmentCategoryID: number,
    subjectID: number,
    sectionID: number,
    classID: number,
    item: any
  ) {
    console.log(
      'onKeyUp',
      event.target.value,
      assessmentCategoryID,
      subjectID,
      sectionID,
      classID,
      item
    );

    //Calling this function for calculations
    this.weightAndPercentageCal(subjectID, sectionID, classID);

    //Get this event percentage
    var eventPercentage = Number(
      (<HTMLInputElement>(
        document.getElementById(
          'percentage-' +
          assessmentCategoryID +
          '-' +
          subjectID +
          '-' +
          sectionID +
          '-' +
          classID
        )
      )).value
    );

    //Saving each event value with percentage in DB
    // setTimeout(() => {
    //   this.saveManageWeightCategory(
    //     Number(event.target.value),
    //     eventPercentage,
    //     subjectID,
    //     classID,
    //     sectionID,
    //     assessmentCategoryID
    //   );
    // }, 500);

    setTimeout(() => {
      this.saveEachWeightAndPercentageCal(subjectID, sectionID, classID);
    }, 300);

    ////Fetch Saved DB record and display
    // setTimeout(() => {
    //   this.subjectExpansionPanel(subjectID, sectionID, classID);
    // }, 1200);
  }

  saveEachWeightAndPercentageCal(
    subjectID: number,
    sectionID: number,
    classID: number
  ) {
    this.weightAndPercentageCal(subjectID, sectionID, classID);
    //Save all weight and percentage after calculations
    this.categories.forEach((element: any) => {
      var weightValue = Number(
        (<HTMLInputElement>(
          document.getElementById(
            'weight-' +
            element.assessmentCategoryID +
            '-' +
            subjectID +
            '-' +
            sectionID +
            '-' +
            classID
          )
        )).value
      );

      var percentageValue = Number(
        (<HTMLInputElement>(
          document.getElementById(
            'percentage-' +
            element.assessmentCategoryID +
            '-' +
            subjectID +
            '-' +
            sectionID +
            '-' +
            classID
          )
        )).value
      );

      this.saveWeightPercentageCategory(
        weightValue,
        percentageValue,
        subjectID,
        classID,
        sectionID,
        element.assessmentCategoryID
      );
    });
  }

  weightAndPercentageCal(
    subjectID: number,
    sectionID: number,
    classID: number
  ) {
    this.totalWeight = 0;
    this.totalPercentage = 0;

    //Adding all weights from cat
    this.categories.forEach((element: any) => {
      var weightValue = (<HTMLInputElement>(
        document.getElementById(
          'weight-' +
          element.assessmentCategoryID +
          '-' +
          subjectID +
          '-' +
          sectionID +
          '-' +
          classID
        )
      )).value;
      this.totalWeight = this.totalWeight + Number(weightValue);
      //this.totalWeight.toFixed(1)
    });

    //Getting all percentage from cat
    this.categories.forEach((element: any) => {
      var weightValue = (<HTMLInputElement>(
        document.getElementById(
          'weight-' +
          element.assessmentCategoryID +
          '-' +
          subjectID +
          '-' +
          sectionID +
          '-' +
          classID
        )
      )).value;
      var catPercentage = (Number(weightValue) * 100) / this.totalWeight;

      (<HTMLInputElement>(
        document.getElementById(
          'percentage-' +
          element.assessmentCategoryID +
          '-' +
          subjectID +
          '-' +
          sectionID +
          '-' +
          classID
        )
      )).value = catPercentage.toFixed(2);;
    });

    //Adding all percentage from cat
    this.categories.forEach((element: any) => {
      var percentageValue = (<HTMLInputElement>(
        document.getElementById(
          'percentage-' +
          element.assessmentCategoryID +
          '-' +
          subjectID +
          '-' +
          sectionID +
          '-' +
          classID
        )
      )).value;
      this.totalPercentage = Math.round(this.totalPercentage + Number(percentageValue));
    });
  }

  saveWeightPercentageCategory(
    eventWeight: number,
    eventPercentage: number,
    subjectID: number,
    classID: number,
    sectionID: number,
    categoryID: number
  ) {
    this.ManageCategoryObj = {
      weightedSubjectAndCategorySettingID: 1,
      classID: classID,
      sectionID: sectionID,
      subjectID: subjectID,
      assessmentCategoryID: categoryID,
      weight: eventWeight,
      weightresult: eventPercentage,
      isAllSet: this.isAllSet,
    };
    ////debugger;
    this.manageAssessmentService
      .AddManageCategoryWeights(this.ManageCategoryObj)
      .subscribe((res: any) => {
        this.toaster.success("Updated successfully");
        // if (res.statusCode == 200) {
        //   this.toaster.success(res.message);
        // } else {
        //   this.toaster.error(res.message);
        // }
      });
  }

  subjectExpansionPanel(subjectID: number, sectionID: number, classID: number) {
    this.totalWeight = 0;
    this.totalPercentage = 0;

    this.subjectID = subjectID;
    this.sectionID = sectionID;
    this.classID = classID;

    console.log('subjectExpansionPanel', subjectID, sectionID, classID);
    this.manageAssessmentService
      .GetWeightBySubjectIDClassIDSectionID(sectionID, classID, subjectID)
      .subscribe((res: any) => {
        if (res.status > 0) {
          console.log('subjectExpansionPanel', res.responseData);
          res.responseData.forEach((element: any) => {
            (<HTMLInputElement>(
              document.getElementById(
                'weight-' +
                element.assessmentCategoryID +
                '-' +
                subjectID +
                '-' +
                sectionID +
                '-' +
                classID
              )
            )).value = element.weight;

            (<HTMLInputElement>(
              document.getElementById(
                'percentage-' +
                element.assessmentCategoryID +
                '-' +
                subjectID +
                '-' +
                sectionID +
                '-' +
                classID
              )
            )).value = element.weightresult.toFixed(2);
          });

          this.weightAndPercentageCal(subjectID, sectionID, classID);
          // this.weightAndPercentageCal(
          //   res.responseData[1].subjectID,
          //   res.responseData[1].sectionID,
          //   res.responseData[1].classID
          // );
        }
      });
  }

  //Auto complete Area
  filteredOptions: Observable<any[]>;
  myControl = new FormControl();
  selectedSubject: any[] = new Array<any>();
  lastFilter: string = '';

  autoCompleteFilter() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.subjectName)),
      map((subjectName) =>
        subjectName ? this._filter(subjectName) : this.subjectListMenu.slice()
      )
    );

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith<string | any[]>(''),
      map((value) => (typeof value === 'string' ? value : this.lastFilter)),
      map((filter) => this.filter(filter))
    );
  }

  filter(filter: string): any[] {
    this.lastFilter = filter;
    if (filter) {
      return this.subjectListMenu.filter((option) => {
        return (
          option.firstname.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
          option.lastname.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        );
      });
    } else {
      return this.subjectListMenu.slice();
    }
  }

  displayFn(obj: any): string {
    return obj ? obj.subjectName : '';
  }

  private _filter(subjectName: string): any[] {
    const filterValue = subjectName.toLowerCase();

    return this.subjectListMenu.filter((option) =>
      option.subjectName.toLowerCase().includes(filterValue)
    );
  }

  optionClicked(event: Event, user: any) {
    event.stopPropagation();
    this.toggleSelection(user);
  }

  toggleSelection(obj: any) {
    obj.selected = !obj.selected;
    if (obj.selected) {
      this.selectedSubject.push(obj);
    } else {
      const i = this.selectedSubject.findIndex(
        (value) => value.subjectName === obj.subjectName
      );
      this.selectedSubject.splice(i, 1);
    }

    this.myControl.setValue(this.selectedSubject);
  }

  onClickSubject(event: any) {
    console.log('onClickSubject', event);
    var obj = {
      subjectName:
        event.subjectName +
        '  ' +
        event.departmentName +
        ' - ' +
        event.sectionName +
        ' - ' +
        event.className,
      classID: event.classID,
      departmentID: event.departmentID,
      sectionsID: event.sectionsID,
      subjectID: event.subjectID,
    };
    this.copySubjectListMenu.push(obj);

    this.myControl.reset();
  }

  // removeAddedSubject(subject: any) {
  //   console.log('this.copySubjectListMenu B', this.copySubjectListMenu);

  //   // this.copySubjectListMenu = this.copySubjectListMenu.filter(x => x.classID != subject.classID
  //   //   && x.departmentID != subject.departmentID
  //   //   && x.sectionsID != subject.sectionsID
  //   //   && x.subjectID != subject.subjectID)

  //   for (let index = 0; index < this.copySubjectListMenu.length; index++) {
  //     const element = this.copySubjectListMenu[index];
  //     if (
  //       element.classID === subject.classID &&
  //       element.departmentID === subject.departmentID &&
  //       element.sectionsID === subject.sectionsID &&
  //       element.subjectID === subject.subjectID
  //     ) {
  //       this.copySubjectListMenu.splice(this.copySubjectListMenu.indexOf(this.copySubjectListMenu), 1);
  //       break;
  //     }
  //   }

  //   console.log('this.copySubjectListMenu F', this.copySubjectListMenu);

  //   this.myControl.reset();
  // }

  copySelectedSubject(event: Event) {
    console.log('copySelectedSubject', this.copySubjectListMenu);

    var obj = {
      classID: this.classID,
      sectionsID: this.sectionID,
      subjectID: this.subjectID,
    };

    //this.copySubjectListMenu.push(obj);

    this.manageAssessmentService
      .CopySubjectWeightToOtherSubject({
        CopySubjectDto: obj,
        DepartmentCommonWeightDtos: this.copySubjectListMenu,
      })
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.toaster.success(res.message);
        }
        //else {
        //   this.toaster.error(res.message);
        // }
      });
  }
  resetSearch() {
    this.form.reset();
    this.getDepartmentList(0, '');
  }
  // onSelectSubject(event: Event){
  //   console.log("event", event);
  //   console.log("onSelectSubject", this.copySubjectListMenu);
  //   //console.log("let userName = this.form.controls['selectedCities'].value.name;", this.form.controls['selectedCities'].value.name)
  // }

  CategoriesExpansionPanel(sectionID: number, classID: number, subjectID: number) {

    //get saved IsAllSet records
    //if(this.isAllSet == true)
    this.manageAssessmentService
      .GetWeightBySubjectIDClassIDSectionID(sectionID, classID, subjectID)
      .subscribe((res: any) => {
        if (res.status > 0) {
          res.responseData.forEach((element: any) => {

            (<HTMLInputElement>(
              document.getElementById(
                'weightAllSet-' +
                element.assessmentCategoryID
              )
            )).value = element.weight;

            (<HTMLInputElement>(
              document.getElementById(
                'percentageAllSet-' +
                element.assessmentCategoryID
              )
            )).value = element.weightresult.toFixed(2);

            //calculate the total
            this.onKeyUpAllSet(element.assessmentCategoryID)

          });
        }
      });
  }

  onKeyUpAllSet(assessmentCategoryID: number) {
    ////debugger;
    console.log('onKeyUp', event);

    this.totalWeightAllSet = 0;
    this.totalPercentageAllSet = 0;

    //Adding all weights from cat
    this.categories.forEach((element: any) => {
      var weightValue = (<HTMLInputElement>(
        document.getElementById('weightAllSet-' + element.assessmentCategoryID)
      )).value;
      this.totalWeightAllSet = this.totalWeightAllSet + Number(weightValue);
    });

    //Getting all percentage from cat
    this.categories.forEach((element: any) => {
      var weightValue = (<HTMLInputElement>(
        document.getElementById('weightAllSet-' + element.assessmentCategoryID)
      )).value;
      var catPercentage = (Number(weightValue) * 100) / this.totalWeightAllSet;

      (<HTMLInputElement>(
        document.getElementById(
          'percentageAllSet-' + element.assessmentCategoryID
        )
      )).value = parseFloat(catPercentage.toString()).toFixed(2);
    });

    //Adding all percentage from cat
    this.categories.forEach((element: any) => {
      var percentageValue = (<HTMLInputElement>(
        document.getElementById(
          'percentageAllSet-' + element.assessmentCategoryID
        )
      )).value;
      this.totalPercentageAllSet =
        Math.round(this.totalPercentageAllSet + Number(percentageValue));

    });

    //Saving all data in DB
    this.toaster.success("Updated successfully");
    this.categories.forEach((element: any) => {
      var percentageValue = (<HTMLInputElement>(
        document.getElementById(
          'percentageAllSet-' + element.assessmentCategoryID
        )
      )).value;

      var weightValue = (<HTMLInputElement>(
        document.getElementById('weightAllSet-' + element.assessmentCategoryID)
      )).value;

    });
  }

  saveCommonWeight(event: Event) {
    console.log("event", event);

    this.categories.forEach((element: any, index: number) => {

      var weightValue = (<HTMLInputElement>(
        document.getElementById('weightAllSet-' + element.assessmentCategoryID)
      )).value;

      var percentageValue = (<HTMLInputElement>(
        document.getElementById('percentageAllSet-' + element.assessmentCategoryID)
      )).value;

      if (index == 0) {
        setTimeout(() => {
          this.saveWeightPercentageCategory(
            Number(weightValue),
            Number(percentageValue),
            0,
            0,
            0,
            element.assessmentCategoryID
          );
        }, 10);
      }
      else {
        setTimeout(() => {
          this.saveWeightPercentageCategory(
            Number(weightValue),
            Number(percentageValue),
            0,
            0,
            0,
            element.assessmentCategoryID
          );
        }, 4000);
      }
      //console.log("saveCommonWeight index", index)
    });

  }
}