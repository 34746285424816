import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { MasterService } from 'src/app/services/master.service';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-login-user-profile',
  templateUrl: './login-user-profile.component.html',
  styleUrls: ['./login-user-profile.component.css']
})
export class LoginUserProfileComponent implements OnInit {form: FormGroup;
  countries: any;

  roles: any;
  bloodGroups: any;
  genders:any;
  skills:any;
  


  constructor(
    private formBuilder: FormBuilder,  
    private studentService: StudentService,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private masterService: MasterService

  ) { }
user:any;
adminUserID:any;
ngOnInit(): void {
    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    console.log("USERR",currentUser);
    this.adminUserID = currentUser.orgID;
    this.form = this.formBuilder.group({
      organizationID:this.adminUserID,
      contactPersonFullName: ['', Validators.required],
      contactNumber: ['', Validators.required],
      userName: ['', Validators.required],
      //password: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subDomainName: ['', Validators.required],
      // subscriptionEndDate: ['', Validators.required],
      // subscriptionStartDate: ['', Validators.required],
      // organizationName: ['', Validators.required],
      // organizationShortName: [''],
      contactNumberCountryCode: ['']
    });
 
    this.GetProfileData();
    this.GetCountiresList();
    this.GetBloodGroupList();
    this.GetGenderList();
    this.GetClassList();
    this.GetSectionList();
    this.GetAcademicSessionList();
    this.GetStudentSkillsList();

  
}
profileData : any
GetProfileData(){
  this.masterService.GetLoginUserProfileData(this.adminUserID).subscribe((res:any)=> {
    this.profileData=res.responseData[0]
    console.log("UserData",this.profileData)
    
    this.form.patchValue({
      organizationID:this.profileData['organizationID'],
      contactPersonFullName: this.profileData['contactPersonFullName'],
      contactNumber: this.profileData['contactNumber'],
      userName: this.profileData['userName'],
      email:this.profileData['email'],
      contactNumberCountryCode: this.profileData['contactNumberCountryCode'],
    })
  })
}
GetCountiresList() {
  this.masterService.GetCountryDropdown().subscribe((res: any) => {
    this.countries = res.responseData;
    console.log("countries" , this.countries);
  })
}
states:any;
  GetStateList(countryId:any) {
    this.masterService.GetStateDropdown(countryId).subscribe((res: any) => {
      this.states = res.responseData;
      console.log(res);
    })
  }
GetBloodGroupList() {
  this.masterService.GetBloodGroupDropdown().subscribe((res: any) => {
    this.bloodGroups = res.responseData;
  })
}
GetGenderList() {
  this.masterService.GetGenderDropdown().subscribe((res: any) => {
    this.genders = res.responseData;
  })
}
classes:any
GetClassList() {
  this.masterService.GetClassDropdown().subscribe((res: any) => {
    this.classes = res.responseData;
  })
}
sections:any
GetSectionList() {
  this.masterService.GetSectionDropdown().subscribe((res: any) => {
    this.sections = res.responseData;
  })
}
academicSessions:any
GetAcademicSessionList() {
  this.masterService.GetAcademicSessionDropdown().subscribe((res: any) => {
    this.academicSessions = res.responseData;
    console.log("sessions" , this.academicSessions);
  })
}

GetStudentSkillsList() {
  this.masterService.GetStudentSkillsDropdown().subscribe((res: any) => {
    this.skills = res.responseData;

  })
}
profileImage: string = '';
  AddProfile(file:any) {
    this.studentService.postData(file).subscribe((res: any) => {
      console.log(res);
      if(res.body.status > 0){
      this.profileImage = res.body.responseData.imageName;
        console.log(this.profileImage);
      }
      
    })
 
  }

  profileDataObj:any
   onSubmit() {
    console.log("FINALDATA",this.form.value)
    console.log(this.form.value);
    // if (this.form.invalid) {
    //   return;
    // }
  this.profileDataObj={
    "organizationID": this.form.value.organizationID,
    "contactPersonFullName": this.form.value.contactPersonFullName,
    "contactNumber": this.form.value.contactNumber,
    "contactNumberCountryCode": this.form.value.contactNumberCountryCode,
    "UserName": this.form.value.userName,
    "email": this.form.value.email,
  }
  console.log("FINALDATA",this.profileDataObj)
    this.masterService.UpdateLoginUserProfileData(this.profileDataObj).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        this.router.navigate(['/admin/login-user-profile']);
      }else{
        this.toaster.error(res.message, 'Error');
      }
    });
  }

  imageUrl: any;
  uploadFile(event:any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();
    //for (let file of this.filesdata)
      formData.append('files', file);
    this.AddProfile(formData);
    // if (event.target.files && event.target.files[0]) {
    //   reader.readAsDataURL(file);

    //   // When file uploads set it to file formcontrol
    //   reader.onload = () => {
    //     this.imageUrl = reader.result;
    //     // this.registrationForm.patchValue({
    //     //   file: reader.result
    //     // });
    //     // this.editFile = false;
    //     // this.removeUpload = true;
    //   }
    //   // ChangeDetectorRef since file is loading outside the zone
    //   //this.cd.markForCheck();        
    // }
  }


  /// crop image
  // imageChangedEvent: any = '';
  //   croppedImage: any = '../../../assets/img/display.jpg';

  //   fileChangeEvent(event: any): void {
  //     this.imageChangedEvent = event;
  // }
  // imageFile:any;
  // imageCropped(event: ImageCroppedEvent) {
  //     this.croppedImage = event.base64;
      
  //     this.imageFile =  this.base64ToFile(
  //       event.base64,
  //       this.imageChangedEvent.target.files[0].name,
  
  //     )
  // }
  // imageLoaded(image: LoadedImage) {
  //     // show cropper
  // }
  // cropperReady() {
  //     // cropper ready
  // }
  // loadImageFailed() {
  //     // show message
  // }
  
  // actionDialogPopup(templateRef: TemplateRef<any>,data:any) {
  //   this.dialog.open(templateRef,{
  //     data:data

  //   });
    
  //   this.fileChangeEvent(data);
    
  // }
  // finalUpload(){
  //   //alert('called');
  //   this.uploadFile(this.imageFile);
  // }
  // base64ToFile(data:any, filename:any) {

  //   const arr = data.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   let u8arr = new Uint8Array(n);

  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new File([u8arr], filename, { type: mime });
  // }

  
  allowOnlyNumber(event:any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event:any) {
    this.commonService.allowAlphabetOnly(event);
  }

  maxDate = new Date();
}


