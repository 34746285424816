import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  user:any;
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     
      this.user = localStorage.getItem('currentUser');
      const currentUser = JSON.parse(this.user);
      if (currentUser != null && currentUser != undefined) {
        if(localStorage.getItem('IsPlanExipred')=='true'){
          if(state.url == '/admin/settings/subscription') {
            return true;
          } else {
            this.router.navigate(['admin/settings/subscription']);
            return false;
          }
        }
        console.log("authorized");
          return true;
          
      }
      console.log("Not authorized");
      this.router.navigate(['/']);
      return false;
  }
}
