<div class="container container1580">
    <div class="pageTitle">
        <div class="breadcrumbWidget">

        </div>
    </div>

    <div class="cardWidget">

        <div class="matCard">

            <div class="matCardBody">
                <div class="cardTitle centerVTItems justify-content-end">
                    <h3 class="mr-auto">Teacher custom field</h3>
                    <button class="btn btn-primary btnsmall mr-1" form="templateForm" (click)="SaveTemplateForm()">Save Template</button>
                    <!-- <button class="btn btn-primary btnsmall">Back</button> -->
                </div>
                <div class="profileDetailsWidget">
                      <div class="custom-formbuilder">
                        <form-builder [form]="form" [options]="formioOptions"></form-builder>
                      </div>

                </div>
            </div>
        </div>
    </div>

</div>
