import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, Master } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SectionDropdownService {

  constructor(private http:HttpClient) { }

  SectionDropdown() {
    return this.http.get(`${environment.baseUrl}${Master.sectionDropdownAPI}`);
  }
  
}
