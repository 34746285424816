import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Pipe({
  name: 'customSearch'
  // pure: false
})
@Injectable()
export class CustomSearchPipe implements PipeTransform {

  transform(items: any[], field: string, value: string): any[] {

    console.log(value);
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }
    ////debugger;
    const data = items.filter(singleItem => singleItem[field].toLowerCase().startsWith(value.toLowerCase()));
    console.log("data", data);

    return data

  }
}
