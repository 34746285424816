<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle mt-0">
      <h2>Old Academic Sessions</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i
                class="fas fa-home"></i> Home</a>
          <!-- <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
              Learning</a></li> -->
          <li class="breadcrumb-item active" aria-current="page">Old Academic Sessions</li>
        </ol>
      </div>
    </div>

  </div>
    <div class="cardWidget">
        <!-- <div class="cardTitle">
            <h3>Closed Academic Sessions</h3>                    
        </div> -->
        <div class="cardBody">
            
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>
                  <!-- Checkbox Column -->
                  <!-- <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container> -->
                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                      <td mat-cell *matCellDef="let element"> {{element.academicSessionID}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="academicsession">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Academic Session </th>
                      <td mat-cell *matCellDef="let element"> {{element.academicSessionName}} </td>
                    </ng-container>
                  
                    <!-- Time Period Column -->
                    <ng-container matColumnDef="timeperiod">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Time Period </th>
                      <td mat-cell *matCellDef="let element"> {{element.startDate  | date:dateFormat}} - {{element.endDate  | date:dateFormat}}</td>
                    </ng-container>
                  
                    <!-- Plan Duration Column -->
                    <ng-container matColumnDef="courses">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Courses </th>
                      <td mat-cell *matCellDef="let element"> <span class="Bold">{{1}}</span> </td>
                    </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                          (click)="selection.toggle(row)">
                      </tr>
                  </table>
                 </div>  <mat-paginator [pageSizeOptions]="[10, 50, 100, 200]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>




