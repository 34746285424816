import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';

@Component({
  selector: 'app-grading-periods',
  templateUrl: './grading-periods.component.html',
  styleUrls: ['./grading-periods.component.css']
})
export class GradingPeriodsComponent implements OnInit {
  gradingPeriods:FormGroup;
  constructor(
    private manageAssessmentService: ManageAssessmentAndGradingsService,
    private fb: FormBuilder,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {
    this.GetGradingPeriod();
    this.gradingPeriods = this.fb.group({
      gradingPeriodID: [0],
      setUpGradingPeriods: ['', Validators.required],
    });
  }

  GetGradingPeriod(){
    this.manageAssessmentService.GetGradingPeriod().subscribe((res:any)=>{
      this.gradingPeriods.patchValue({
        gradingPeriodID: res.responseData['gradingPeriodID'],
        setUpGradingPeriods: res.responseData['setUpGradingPeriods'],
      });
    })
  }
  submitGradingPeriods(){
    if (this.gradingPeriods.invalid) {
      return;
    }
    this.manageAssessmentService.UpdateGradingPeriod(this.gradingPeriods.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
      }
      else {
        this.toaster.error(res.message)
      }
    });
  
  }
}
