import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AttacmentFilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { DialogService } from 'src/app/services/dialog.service';
import { AttachmentModel } from '../view-attachment/attachmentModel';
import { environment, Student } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-add-attchment',
  templateUrl: './add-attchment.component.html',
  styleUrls: ['./add-attchment.component.css']
})
export class AddAttchmentComponent implements OnInit {
  form: FormGroup;
  // studentIdList:any;
  studentList: any;
  studentID: number;
  data: string;
  requestpages: any;
  searchingForm: FormGroup;
  metaData: any;
  private filterModel: AttacmentFilterModel;
  attchData: AttachmentModel[];
  classToggled = false;
  id: any;
  updateText: boolean;
  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  displayedColumns: Array<any> = [
    { displayName: 'Student Name', key: 'studentName', isSort: true, class: '', width: '20%' },
    { displayName: 'Title', key: 'title', isSort: true, class: '', width: '20%' },
    { displayName: 'Document Name', key: 'documentLabel', isSort: true, class: '', width: '20%' },
    { displayName: 'Description', key: 'description', isSort: true, class: '', width: '40%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
  actionButtons: Array<any> = [
    { displayName: 'Download', key: 'download', class: 'fas fa-download' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
  constructor(private formBuilder: FormBuilder,
    private studentformBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private studentService: StudentService,
    private toaster: ToastrService,
    private router: Router,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private http: HttpClient,

  ) {
    this.filterModel = new AttacmentFilterModel();
    this.attchData = new Array<AttachmentModel>();
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.studentID = params.id == undefined ? null : params.id;
      console.log("studentID", this.studentID);
    });

    this.form = this.formBuilder.group({
      studentDocumentID: 0,
      studentID: 0,
      title: ['', Validators.required],
      description: ['', Validators.required],
      documentName: [],
      documentLabel: ['']

    });
    this.metaData = new Metadata();
    this.searchingForm = this.formBuilder.group({
      documentName: [''],
      title: [''],


    });
    this.InitialGridCall();
    this.getAttchList(this.requestpages, '', '');
  }
  //
  //
  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText, this.studentID);
    //this.InitialGridCall();
    //this.filterModel
    this.getAttchList(this.filterModel, '', '');
    // this.getAttchList(this.requestpages, '', '');
  }
  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string, studentID: any) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
    this.filterModel.searchText = searchText;
    this.filterModel.studentID = studentID;
  }

  getAttchList(filterModel: any, tags: any, roleIds: any) {
    this.studentService.GetStudentAttachList(filterModel).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);
        this.attchData = res.responseData;
        this.metaData = res.meta;

      } else {
        this.metaData = new Metadata();
      }

    });
  }

  onTableActionClick(actionObj?: any) {
    this.id = actionObj.data && actionObj.data.studentDocumentID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editStudentAttachment(this.id);
       
        break;
      case 'DELETE':
        {
          this.dialogService
            .confirm(`Are you sure you want to delete this record?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.deleteAttach(this.id);
              }
            });
        }
        break;
      case 'DOWNLOAD':
        // this.downloadfile(actionObj.data.documentPath);
        //  window.open(`${environment.baseUrl}${Student.downloadfile}`+"?filepath="+actionObj.data.documentPath, "_blank");
        this.DownLoadExcel(actionObj.data.documentPath);
        break;
      default:
        break;
    }
  }


  DownLoadExcel(path: any) {
    this.studentService.downloadFile(path).subscribe((data: any) => { this.downloadFile(data) });
  }

  downloadFile(data: any) {
    console.log("DOwnload",data)
    const contentType = data.type
    const blob = new Blob([data], { type: contentType });
  const url= window.URL.createObjectURL(blob);
  window.open(url);

  saveAs(blob)
  }

  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText, this.studentID);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getAttchList(this.filterModel, '', '');
    }
  }

  // searching
  onSearchingSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      //"studentName": this.searchingForm.controls.studentName.value,
      "title": this.searchingForm.controls.title.value,
      "columnName": "",
      "sortOrder": "",
      "studentID": this.studentID,
      "documentName": this.searchingForm.controls.documentName.value

    }
    console.log(this.requestpages);
    this.getAttchList(this.requestpages, '', '')
    //this.AdminUserList();
  }
  reset() {
    this.searchingForm.reset();
    this.form.reset();
    this.InitialGridCall();

    this.getAttchList(this.requestpages, '', '')
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "searchValue": "",
      "columnName": "",
      "sortOrder": "",
      "studentID": this.studentID,
      "documentName": ""
    }
  }
  // end

  downloadfile(path: any) {
    this.studentService.downloadFile(path).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);

      } else {

      }

    });
  }

  deleteAttach(id: any) {
    this.studentService.deleteAttachmentDetailsId(id).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);
        this.toaster.success(res.message, 'Success');
        this.InitialGridCall();

        //this.reset();
        this.getAttchList(this.requestpages, '', '');

      } else {
        this.metaData = new Metadata();
      }

    });
  }
  //
  //
  submitted = false;
  formRequest: any;
  onSubmit(event:any) {

    this.submitted = true;
    const button = (event.srcElement.disabled === undefined) ? event.srcElement.parentElement : event.srcElement;
        button.setAttribute('disabled', true);
        setTimeout(function () {
        button.removeAttribute('disabled');
        }, 1000);
    if (this.form.invalid) {
      return;
    }
    if (this.documentFile != '') {
      this.form.controls.documentName.setValue(this.documentFile);
    }
    else{
      this.toaster.error("Please choose File");
      return;
    }
    this.form.controls.studentID.setValue(this.studentID);
    console.log("form value", this.form.value);
    this.form.controls.documentLabel.patchValue(this.documentLabel);
    this.studentService.postStudnetDocumentDetails(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
       
        this.toaster.success(res.message, 'Success');
        this.InitialGridCall();
        this.getAttchList(this.requestpages, '', '');
        window.location.reload();
      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }


  documentLabel: string = '';
  uploadFile(event: any) {
    console.log("UPLOADD",event.target.files)
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      let file = event.target.files[0];
      this.documentLabel = file.name;
      formData.append('files', file);
      if(file.size < 20000000){
        this.AddDocument(formData);
      }
      else{
        this.toaster.error("Please upload less than 20mb attachment file.")
        return;
      }
    }
    if(event.target.files.length == 0){
      this.documentFile = "";
    }
  }

  documentFile: string = '';
  AddDocument(file: any) {
    this.studentService.postStudentDocData(file).subscribe((res: any) => {
      console.log("upload API",res);
      if (res.body.status > 0) {
        this.documentFile = res.body.responseData.imageName;
        console.log("ADDDOCU",this.documentFile);
      }
    })
  }
  goto() {
    this.router.navigate(['/admin/academicslearning/manage-students/view-attachment']);
  }
  editStudentAttachment(id: any) {
    if (this.id) {
      this.updateText = true;
    }
    this.studentService.getAttachmentDetailsId(id).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.form.patchValue({
          studentDocumentID: res.responseData['studentDocumentID'],
          studentID: res.responseData['studentID'],
          title: res.responseData['title'],
          description: res.responseData['description'],
          documentName: res.responseData['documentName'],
        });
        console.log("AfterEdit",this.form.value)
        // this.router.navigate(['/admin/academicslearning/manage-students/add-attachment']);
      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }
}
