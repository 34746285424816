import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { DialogService } from 'src/app/services/dialog.service';
import { StudentService } from 'src/app/services/student.service';
import { environment, Student } from 'src/environments/environment';
import { AttachmentModel } from './attachmentModel';

@Component({
  selector: 'app-view-attachment',
  templateUrl: './view-attachment.component.html',
  styleUrls: ['./view-attachment.component.css']
})
export class ViewAttachmentComponent implements OnInit {

  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  attchData: AttachmentModel[];
  classToggled = false;
  public toggleField() {
    this.classToggled = !this.classToggled;
   }
   displayedColumns: Array<any> = [
    { displayName: 'STUDENT NAME', key: 'studentName', isSort: true, class: '', width: '20%' },
    { displayName: 'TITLE', key: 'title', isSort: true, class: '', width: '20%' },
    { displayName: 'DESCRIPTION', key: 'description', isSort: true, class: '', width: '40%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
   actionButtons: Array<any> = [
    { displayName: 'Download', key: 'download', class: 'fas fa-download' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private studentService:StudentService,) { 
      this.filterModel = new FilterModel();
     this.attchData = new Array<AttachmentModel>();
    }

  ngOnInit(): void {
    this.metaData = new Metadata();
    this.form = this.formBuilder.group({
      studentName: [''],
      title: [''],
     
    });
    //alert(btoa("category=textile&user=user1"));
    //alert(atob("Y2F0ZWdvcnk9dGV4dGlsZSZ1c2VyPXVzZXIx"));
    this.getAttchList(this.filterModel, '', '', );
  }

  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getAttchList(this.filterModel, '', '')
  }
  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  getAttchList(filterModel: any, tags: any, roleIds: any) {
    this.studentService.GetStudentAttachList(filterModel).subscribe((res: any) => {
      if(res.status > 0){
        console.log(res);
        this.attchData = res.responseData;
        this.metaData = res.meta;
       
      }else{
        this.metaData = new Metadata();
      }
     
    });
  }

  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.studentDocumentID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
      let  data = {
        studentDocumentID: id,
        studentName:actionObj.data.studentName
       }
       let values = (btoa(JSON.stringify(data)));
        this.router.navigate(["/admin/academicslearning/manage-students/edit-attachment"], { queryParams: { data: values} });
        break;
      case 'DELETE':
        {
          
          this.dialogService
            .confirm(`Are you sure you want to delete this record?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.deleteAttach(id);
              }
            });
        }
        break;
        case 'DOWNLOAD':
        //this.downloadfile(actionObj.data.documentPath);
        window.open(`${environment.baseUrl}${Student.downloadfile}`+"?filepath="+actionObj.data.documentPath, "_blank");
        break;
      default:
        break;
    }
  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getAttchList(this.filterModel, '', '');
    }
  }

  // searching
  onSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "studentName": this.form.controls.studentName.value,
      "title": this.form.controls.title.value,
      "columnName": "",
      "sortOrder": ""
    }
    console.log(this.requestpages);
    this.getAttchList(this.requestpages, '', '')
    //this.AdminUserList();
  }
  reset() {
    this.form.reset();
    this.InitialGridCall();
   
    this.getAttchList(this.requestpages, '', '')
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "searchValue": "",
      "columnName": "",
      "sortOrder": ""
    }
  }
  // end

  downloadfile(path:any){
    this.studentService.downloadFile(path).subscribe((res: any) => {
      if(res.status > 0){
        console.log(res);
              
      }else{
        
      }
     
    });
  }

  deleteAttach(id:any){
    this.studentService.deleteAttachmentDetailsId(id).subscribe((res: any) => {
      if(res.status > 0){
        console.log(res);
        this.toaster.success(res.message, 'Success');
        //this.reset();
        this.getAttchList(this.filterModel, '', '', );
       
      }else{
        this.metaData = new Metadata();
      }
     
    });
  }

  goto(){
    this.router.navigate(['/admin/academicslearning/manage-students']);
  }

}
