import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customRepotCard',
  pure: false
})

@Injectable()
export class CustomRepotCardPipe implements PipeTransform {

  transform(items: any, name: string): any {
    if (!items || !name) {
        return false;
    }
    let data;
    if(name == 'groupCheck') {
      data = items.filter((x: any) => x.checked == true);
    } else if(name == 'studentCustomFields') {
       return items.checked;
    } 
    else {
      data =  items.filter((x: any) => x.fieldName.toLowerCase() == name.toLowerCase() && x.checked == true);
    }
    
    if(data.length > 0){
      return true;
    } else {
      return false;
    }
  }

}
