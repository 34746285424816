import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StudentService } from 'src/app/services/student.service';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';
@Component({
  selector: 'app-edit-report-card',
  templateUrl: './edit-report-card.component.html',
  styleUrls: ['./edit-report-card.component.css']
})
export class EditReportCardComponent implements OnInit {
  dropdownSettings: any;
  customError: any;
  toppings = new FormControl('', Validators.required);
  reportCardFormat: FormGroup;
  multiselector: FormGroup;
  reportCardTemplateList: any;
  reportCardGroupList: any;
  toppingList: any;
  selectedItems: any[] = [];
  ShowFilter = true;

  users: any;
  user: any;
  permissions: any;
  academicSessionID: any;
  reportCardFormatDetailList: any
  reportCardFormatID: any
  classSectionList: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private reportCardService: ManageAssessmentAndGradingsService,
    private studentService: StudentService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.multiselector = this.formBuilder.group({
      selectedClassSectionID: [this.selectedItems]
    });

    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
    if (currentUser != null) {
      this.user = currentUser;
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.reportCardFormatID = params.id == undefined ? null : params.id;
    });
    this.reportCardFormat = this.formBuilder.group({
      Name: ['', Validators.required],
      selectedClassSectionID: ['', Validators.required],
      Template: [''],
      ReportCardFormatID: [this.reportCardFormatID]
    });


    this.getClassSection();
    this.getTemplateList();
    this.getReportCardFormatDetail()
    // this.getGroupList();
  }

  customErr() {
    if (this.reportCardFormat.controls.Name.errors) {
      let obj: any = this.reportCardFormat.controls.Name.errors;
      if (obj.required == true) {
        this.customError = 'Name cannot be empty';
      }
      if (obj.pattern) {
        this.customError = 'Enter a valid Name';
      }
    }
  }

  getClassSection() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idDropDown',
      textField: 'dropDown',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };
    this.studentService.GetCombineClassSectionForAnalytics().subscribe((res: any) => {
      this.toppingList = res.responseData;
      console.log("real Class-section", this.toppingList)
    })
  }


  getTemplateList() {
    ////debugger;
    this.reportCardService.GetAllReportCardTemplate().subscribe((res: any) => {
      this.reportCardTemplateList = res.responseData;
    })
  }

  getGroupList() {
    ////debugger;
    this.reportCardService.GetAllReportCardGroup().subscribe((res: any) => {
      this.reportCardGroupList = res.responseData;
    })
  }
  getReportCardFormatDetail() {
    this.reportCardService.GetReportCardDetailById(this.reportCardFormatID).subscribe((res: any) => {
      this.classSectionList = []
      this.classSectionList.push({
        classID: res.responseData[0].classID,
        className: res.responseData[0].className,
        dropDown: res.responseData[0].dropDown,
        idDropDown: res.responseData[0].idDropDown,
        sectionId: res.responseData[0].sectionID,
        sectionName: res.responseData[0].sectionName,
      })
      this.reportCardFormatDetailList = res.responseData;
      console.log("edit Report card", this.reportCardFormatDetailList)
      this.reportCardFormat.patchValue({
        Name: this.reportCardFormatDetailList[0].reportCardsFormatName,
        selectedClassSectionID: this.classSectionList,
        Template: this.reportCardFormatDetailList[0].reportCardTemplateID

      })
    })

  }

}
