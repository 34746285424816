<div class="container container1580">
      <div class="headingcontent">
        <div class="pageTitle mt-0">
          <h2>Manage Admins</h2>
          <div class="breadcrumbWidget">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/admin"><i
                    class="fas fa-home"></i> Home</a>
              <li class="breadcrumb-item active" aria-current="page">Manage Admins</li>
            </ol>
          </div>
        </div>
        <div class="">
          <div class="add_button">
            <button class="addbtn btn" matRipple routerLink="../add-admin-staff"
            *ngIf="checkActionPermission('Add Admin')"> <i
                class="fas fa-plus-circle"></i> Add Admin Staff</button>
          </div>
        </div>
      </div>
  
    <div class="cardWidget">
 
      <div class="matCard">
        <div class="matCrdTitle centerItemVert justify-content-between">
          <div class="row">
           
  
           
            
          </div>
          <div class="tableActionTop">
            <div class="tableFilter">  
            
              <div class="filterForm" [class.toggled]="classToggled">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                 
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Admin Name</mat-label>
                      <input matInput type="text" formControlName="adminName">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Email Address</mat-label>
                      <input matInput type="text" formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Contact No.</mat-label>
                      <input matInput type="text" formControlName="contactNumber">
                    </mat-form-field>
                  </div>

                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>User Name</mat-label>
                      <input matInput type="text" formControlName="userName">
                    </mat-form-field>
                  </div>
                </div>
                <div class="btnsWidget form-group text-right">
                  <button type="submit" class="btn btn-primary btn-sm" matRipple>Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm" matRipple>Reset</button>
                </div>
                </form>
              </div>
            </div>
            <div class="rightTableActions centerItemvert">
              <div class="iconsset d-flex align-items-center">
                <div class="viewColumns tableActions" matTooltip="Filter">
                  <i  class="fas fa-filter cursor-pointer" (click)="toggleField()" ></i>
                  <span class="mobileHidden cursor-pointer" (click)="toggleField()"></span>
                </div>

              <!-- <div (click)="toggleField()"><i class="fas fa-filter cursor-pointer"></i> <span
                class="mobileHidden cursor-pointer"></span></div> -->


              <div class="viewColumns tableActions" matTooltip="Hide/Show Columns">
                <a (click)="actionDialogPopup(hideShowColPopup,$event)"><i class="fas fa-columns"></i></a>                           
             </div>
             <!-- <div class="downloadTable tableActions" matTooltip="Upload File">
              <a (click)="importDialogPopup(importPopup)"><i title="Import Admin Staffs" class="fas fa-upload"></i></a>
            </div>
            <div class="downloadTable tableActions" matTooltip="Downlaod Data"
            *ngIf="checkActionPermission('Download Admin')">
              <a><i title="Export Admin Staff" class="fas fa-download"></i></a>
            </div> -->

             </div>
             <div class="buttonsset d-flex align-items-center">
              <div class="selectedColTable tableActions">
                <button mat-button [matMenuTriggerFor]="beforeMenu" class="ddButtonType"[disabled]="!printFlag" matRipple matTooltip="You need to select atleast one record"><span
                    class="leftItem conterColumns" >{{selectedData}}</span> <span>Selected</span> <i
                    class="fa fa-caret-down rightItem"></i></button>
                <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
                  <h4 class="actionSelected">Communications</h4>
                  <button mat-menu-item (click)="adminEmailSent(adminEmail)"
                  *ngIf="checkActionPermission('Send Email')"><i class="fas fa-envelope-open-text"></i> Send Email</button>
                  <button mat-menu-item (click)="adminSmsSent(adminSms)"
                  *ngIf="checkActionPermission('Send SMS')"><i class="fas fa-comment-alt"></i> Send SMS</button>
                  <button mat-menu-item (click)="adminEmailSentInvite()"
                  *ngIf="checkActionPermission('Invite Login (Email)')"><i class="fas fa-exchange-alt"></i> Invite to Login (Email)</button>
                  <button mat-menu-item (click)="adminSmsSentInvite()"
                  *ngIf="checkActionPermission('Invite Login (SMS)')"><i class="fas fa-exchange-alt"></i> Invite to Login (SMS)</button>
                </mat-menu>
              </div>
            </div>
          </div>
  
          </div> 
        </div>
  
        <div class="matCardBody">
          <div class="tableActionTop">
            <div class="tableFilter">
              <!-- <a (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden">Filter</span></a> -->
              <!-- <div class="filterForm" [class.toggled]="classToggled">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                  <div class="row">
                    <div class="col s12 m6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>First Name</mat-label>
                        <input matInput type="text" formControlName="firstName">
                      </mat-form-field>
                    </div>
  
                    <div class="col s12 m6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Last Name</mat-label>
                        <input matInput type="text" formControlName="lastName">
                      </mat-form-field>
                    </div>
  
  
                    <div class="col s12 m6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Admission Number</mat-label>
                        <input matInput type="text" formControlName="admissionNumber">
                      </mat-form-field>
                    </div>
  
                    <div class="col s12 m6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Email Address</mat-label>
                        <input matInput type="text" formControlName="email">
                      </mat-form-field>
                    </div>
                    <div class="col s12 m6">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Phone Number</mat-label>
                        <input matInput type="text" formControlName="contactNumber">
                      </mat-form-field>
                    </div>
  
                  </div>
                  <div class="btnsWidget form-group">
                    <button type="submit" class="btn btn-primary btn-sm">Search</button>
                    <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
  
                  </div>
                </form>
              </div> -->
            </div>
            <div class="rightTableActions centerItemVert">
  
  
            </div>
  
          </div>
          <div class="mat-elevation-z8">
  
          </div>
        </div>
  
        <app-checkboxdata-table *ngIf="checkActionPermission('Admin List') && filterData.length>0;else nodata" [inputColumns]="displayedColumns"  [inputSource]="usersData" [inputMeta]="metaData"
                  [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)" (onselect)="onselect($event)"
                  (onTableActionClick)="onTableActionClick($event)" >
                  
                </app-checkboxdata-table>

                <ng-template #nodata>
                  <p class="text-center"> No matching records found
                  </p>
                </ng-template>


                <!-- <app-ws-table [rows]="rows" (onSort)="onSort($event)" [isCheckBox]="true" [columns]="columns"
                [loading]="tableLoading"  [rowOffset]="filerData.current_page"
                [rowLimit]="filerData.per_page" (onPager)='onPager($event)' (rowDelete)="onDeletion($event)"
                (rowEdit)="onEdit($event)" [isClass]="true" [isLocal]="false" (selectedItem)="selectedClasses($event)"
                (onDownload)="onFiles($event)">
              </app-ws-table>  -->



        
      </div>
    </div>

    
  <!-- </div> -->
  
  <!-- <ng-template #loginPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Reset Password</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline" floatLabel="always">
              <mat-label>Username</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
              <input matInput value="Sharma">
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
              <input matInput>
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
              <input matInput>
            </mat-form-field>
          </div>
          <div class="form-group17 center">
            <button type="button" class="btn btn-primary" mat-dialog-close>Send</button>
          </div>
        </form>
  
      </mat-dialog-content>
    </div>
  </ng-template> -->
  
  
  
  <ng-template #hideShowColPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
         <h2 mat-dialog-title>Hide/Show Colums</h2> 
      </div>
      <mat-dialog-content class="mat-typography">
        <p><a (click)="selectAll()" class="tags underlinedLink">Select All</a> / <a (click)="unselectAll()" class="tags underlinedLink">Unselect All</a>
        </p>
          <form [formGroup]="columnForm">
             <div class="row-flex">
                <div class="col s12 m6 form-group">
                  <mat-checkbox [disabled]="true" (change)="onSelectColumn()" class="example-margin m-0" formControlName="adminName">
                  Admin Name
                  </mat-checkbox>
                </div>
                <!-- <div class="col s12 m6 form-group">
                  <mat-checkbox (change)="onSelectColumn()" class="example-margin m-0" formControlName="profilepic">
                    Profile pic
                  </mat-checkbox>
                </div> -->
                <div class="col s12 m6 form-group">
                  <mat-checkbox (change)="onSelectColumn()" class="example-margin m-0" formControlName="adminID">
                    Admin ID
                  </mat-checkbox>
                </div>
                <div class="col s12 m6 form-group">
                  <mat-checkbox (change)="onSelectColumn()" class="example-margin m-0" formControlName="userName">
                  UserName
                  </mat-checkbox>
                </div>
                <div class="col s12 m6 form-group">
                  <mat-checkbox (change)="onSelectColumn()" class="example-margin m-0" formControlName="email">
                    Email
                  </mat-checkbox>
                </div>
                <div class="col s12 m6 form-group">
                  <mat-checkbox (change)="onSelectColumn()" class="example-margin m-0" formControlName="contactNumber">
                    Phone Number
                  </mat-checkbox>
                </div>
                
             </div>
          </form>
         
      </mat-dialog-content>    
    </div> 
  </ng-template>
  
  <ng-template #deletePopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title> Do you want to delete ?</h5>
      </div>
      <mat-dialog-content class="mat-typography">
        <!-- <p class="mb-4 center ">Do you want to delete ?</p> -->
        <div class="btnsWidget form-group center">
          <!-- <button (click)=confirmDelete() class="btn btn-primary btn-sm">Yes</button> -->
          <button mat-dialog-close class="btn btn-reset btn-sm">NO</button>
  
        </div>
      </mat-dialog-content>
    </div>
  </ng-template>
  <ng-template #adminEmail>
    <div>
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Send Admin Email</h2>
        <ng-template #elseTemplate>
          <h2 mat-dialog-title>Admin Email</h2>
        </ng-template>
      </div>
      <mat-dialog-content class="mat-typography">
      <form [formGroup]="adminEmailForm" (ngSubmit)="onSubmitEmail()">
        <div>
          </div>
          <div class="row-flex">
            <div class="col s12 m6 form-group">
              <mat-label>Send As</mat-label>
              <mat-checkbox [checked]='true' class="example-margin" [disabled]="true"  >
                info@creocms.com.au
              </mat-checkbox>
            </div>
  
            <div class="col s12 m12 form-group">
              <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Subject </mat-label>
                  <input required maxlength="100" formControlName="subject" matInput placeholder="subject">
                  <mat-error *ngIf="adminEmailForm.get('subject')?.touched  && adminEmailForm.get('subject')?.errors?.required">
                    Subject is required
                  </mat-error>
              </mat-form-field>
          </div>
          <div class="col s12 m12 form-group">
            <mat-label>Receivers</mat-label>
            <mat-checkbox [checked]='true' class="example-margin" [disabled]="true"  >
              Admins Email
            </mat-checkbox>
            <mat-chip-list class="example-chip"  cdkDropList  cdkDropListOrientation="horizontal" >
              <mat-chip class="example-box" cdkDrag *ngFor="let admin of adminData">
                {{admin.email}}
              </mat-chip>
             </mat-chip-list>
          </div>
          <div class="col s12 m6" class="form-group">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Select Template</mat-label>
              <mat-select formControlName="template" (selectionChange)="selectChangeTemplate($event)"
                  name="template">
                  <mat-option *ngFor="let temp of customtemplate" [value]="temp.emailTemplateID">
                    {{temp.templateName}}
                </mat-option>

              </mat-select>
          </mat-form-field>
          </div>
  <div class="col s12 m12 form-group">
          <ejs-richtexteditor #textEditorValue required formControlName="body" [maxLength]='256' >
          </ejs-richtexteditor>
      </div>
      <div class="form-group">
        <input formControlName="attachment" (change)="uploadFile($event)" id="file" type="file" multiple>
    </div>
          </div>
  
        <div class="btnsWidget form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close [disabled]="adminEmailForm.invalid">Send</button>
          <button typetype="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close>Close</button>
        </div>
  
        <!-- <div class="forgotpassword form-group17 center">
          <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
            [disabled]="addSubjectForm.invalid">Save</button>
        </div> -->
      </form>
      </mat-dialog-content>
    </div>
  </ng-template>
  
  
  <ng-template #importPopup>
    <form>
      <div class="dialog-md">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle">
          <h5 mat-dialog-title> Import Admin Staffs</h5>
        </div>
        <mat-dialog-content class="mat-typography">
          <div class="btnsWidget form-group center">
            <p class="chngePicupload">
              <input type="file" accept=".csv" >
              <img src="../../../../../../assets/img/upload_icon.svg" alt="">
          </p>
            <p class="note"><b>Note: </b>Please upload only CSV file</p>
          </div>
        </mat-dialog-content>
        <div class="form-group17 text-right">
          <button (click)="addFile()" class="btn btn-primary btn-sm"
            mat-dialog-close>Import</button>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #adminSms>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2  mat-dialog-title>Send Admin SMS </h2>
      </div>
      <mat-dialog-content class="mat-typography">
      <form [formGroup]="adminSmsForm" (ngSubmit)="onSubmitSms()">
        <div>
         
          
          </div>
          <div class="row-flex">
          <div class="col-12 form-group">
            <mat-label>Receivers</mat-label><br>
            <mat-checkbox [checked]='true' class="example-margin" [disabled]="true"  >
              Admins Contact Number
            </mat-checkbox>
            <mat-chip-list class="example-chip"  cdkDropList  cdkDropListOrientation="horizontal" >
              <mat-chip class="example-box" cdkDrag *ngFor="let admin of adminData">
                {{admin.contactNumber}}
              </mat-chip>
             </mat-chip-list>
          </div>
  
  
          <!-- <div class="col s12 m12 form-group">
            <mat-checkbox [checked]='false' class="example-margin" (change)="onparentSmsChange()" formControlName="parentCheckSms"   >
              Parents Contact Number
            </mat-checkbox>
            <ng-container *ngIf="!boxhidesms">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Parent Contact Number </mat-label>
                <input  maxlength="14"  formControlName="parentContactNo" matInput placeholder="Parent Contact">
              
            </mat-form-field>
            </ng-container>
            
        </div> -->
  
        <div class="col s12 m12 form-group">
          <mat-form-field class="w-100" appearance="outline">
              <mat-label>Message</mat-label>
              <textarea required maxlength="256" formControlName="message" matInput placeholder="Message" ></textarea>
              <mat-error *ngIf="adminSmsForm.get('message')?.touched  && adminSmsForm.get('message')?.errors?.required">
                Message is required
              </mat-error>
          </mat-form-field>
      </div>
  
          
          </div>
  
        <div class="btnsWidget form-group17 text-right">
          <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close [disabled]="adminSmsForm.invalid">Send</button>
          <button typetype="reset" (click)="reset()" class="btn btn-reset btn-sm" mat-dialog-close>Close</button>
        </div>
      </form>
      </mat-dialog-content>
    </div>
  </ng-template>
  
  
  