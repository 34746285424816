<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>Custom Templates</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-grade">Manage Assesment
                            And Grading </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Custom Templates</li>
                </ol>
            </div>
        </div>

    </div>
    <div class="cardWidget">
    <form [formGroup]="reportCardFormat">
        <div class="cardBody">
            <div class="row-flex mb-3">
                <div class="col-sm-12 col-xl-6 col-md-6 s12 m4 mt-3 mt-md-0">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Report Card Format Name" maxlength="50" (keyup)="customErr()" (click)="customErr()" formControlName="Name" pattern="^[^\s]+(\s+[^\s]+)*$">
                        <mat-error>
                            {{ customError }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-xl-6 col-md-6 multiselectformfield">
                    <div class="multiselectformfield">
                    <mat-label class="multiselectlabel">Select Class Section</mat-label>
                        <p-multiSelect [options]="toppingList" defaultLabel="Select Class-Section" [formControl]="toppings" formControlName="selectedClassSectionID" optionLabel="dropDown" display="chip" toppings.required="true" (onClick)="reportCardFormat.value.selectedClassSectionID = elm.value" #elm>
                        </p-multiSelect>
                        <mat-error *ngIf="reportCardFormat.get('selectedClassSectionID')?.touched && reportCardFormat.get('selectedClassSectionID')?.errors?.required">
                            Please select Class-Section
                        </mat-error>
                    </div>
                </div>
                <div class="col-sm-12 col-xl-6 col-md-6 s12 m4 mt-3 mt-md-0">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label> Select Template</mat-label>
        
                    <mat-select  placeholder="Template" formControlName="Template">
                            <mat-option *ngFor="let item of reportCardTemplateList" [value]="item.reportCardTemplateID">{{item.templateName}}</mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
    <app-template-one *ngIf="reportCardFormat.value.Template == 3" [reportCardItem]="reportCardFormat"></app-template-one>
    <app-template-two *ngIf="reportCardFormat.value.Template == 4" [reportCardItem]="reportCardFormat"></app-template-two>
    <app-template-three *ngIf="reportCardFormat.value.Template == 5" [reportCardItem]="reportCardFormat"></app-template-three>
    <app-template-four *ngIf="reportCardFormat.value.Template == 6" [reportCardItem]="reportCardFormat"></app-template-four>
    </div>
</div>