import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { AdminStaffService } from 'src/app/services/admin-staff.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
import { StudentService } from 'src/app/services/student.service';
import { AdminModel } from '../admin-model';
import { MasterService } from 'src/app/services/master.service';

@Component({

  selector: 'app-manage-admin-staff',
  templateUrl: './manage-admin-staff.component.html',
  styleUrls: ['./manage-admin-staff.component.css'],
 
})
export class ManageAdminStaffComponent implements OnInit {
  //dataSource = new MatTableDataSource;
  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  usersData: AdminModel[];

  adminEmailForm: FormGroup;
  adminSmsForm: FormGroup;

  classToggled = false;
  columnForm: FormGroup;

  selectedRow: any;
  selectedData: any;
  printFlag: boolean;
  users: any;
  userName: any;
  permissions: any;
  user: any
  userId: any;
  url:any
  @ViewChild("fileInput") fileInput: any;
  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  columnsToDisplay: any;
  displayedColumns: Array<any> = [
    { displayName: 'ID', key: 'adminID', isSort: true, class: '', width: '20%' },
    { displayName: 'Name', key: 'adminName', isSort: true, class: '', width: '20%' },
    { displayName: 'Phone', key: 'contactNumber', isSort: true, class: '', width: '10%' },
    { displayName: 'Email', key: 'email', isSort: true, class: '', width: '10%' },
    
    { displayName: 'Username', key: 'userName', isSort: true, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
  actionButtons: Array<any> = [
    { displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];


  constructor(
    private dialog: MatDialog,
    private adminUserService: AdminStaffService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private commonService: CommonService,
    private studentService: StudentService,
    private roleService: RoleAndPermissionService,
    private permissionService: PermissionService,
    private masterService : MasterService
  ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.orgID = currentUser.orgID;
    if (currentUser != null) {
      this.userName = currentUser;
    }
    this.filterModel = new FilterModel();
    this.usersData = new Array<AdminModel>();
    const parsedUrl = new URL(window.location.href);
    this.url = parsedUrl.origin;
  }
  //displayedColumns: string[] = ['profilepic', 'adminName', 'username', 'email', 'contactNumber', 'roleName', 'actions'];


  orgID:any;
  selection = new SelectionModel<PeriodicElement>(true, []);
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  loginuser:any
  ngOnInit(): void {
    this.loginuser = localStorage.getItem('currentUser')
    console.log(":LOGIN",JSON.parse(this.loginuser))
//Legasov Added 25 March 2022
this.permissionService.checkComponentHasScreenPermission('Manage Admin');
    this.permissions = this.permissionService.getAllPermissions();
    if(this.permissions.length == 0){
     this.roleService.getPermissionByUserID(this.userName.userId).subscribe((res: any) => {
       if(res.status> 0){
          this.permissionService.addPermissions(res.responseData);
          this.permissions = this.permissionService.getAllPermissions();
       }
     })
    }

    this.ActivityLogTrack();
    this.selectedData = 0;
    this.metaData = new Metadata();
    this.selectedRow = []
    this.metaData = new Metadata();
    this.form = this.formBuilder.group({
      adminName: [''],
      contactNumber: [''],
      email: [''],
      userName: [''],
      userId: this.userId
    });

    this.getUsersList(this.filterModel, '', '',);

    this.InitialGridCall()

    // this.AdminUserList();
    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    this.userId = currentUser.userId;
    this.columnForm = this.formBuilder.group({
      adminName: true,
      adminID: true,
      userName: true,
      email: true,
      contactNumber: true,
      userId: this.userId
    })

    this.getadminColumn();
  }
  
  
  checkActionPermission(actionName: any){
    let screen = 'Manage Admin';
   //  let permission = this.permissions.find((x: any)=> x.screenName == screen).children;
  //   return permission.find((x: any) => x.actionName == actionName);

    //let permission = this.permissionService.getFilterScreen(screen);

    return this.permissionService.checkScreenActionPermission(actionName, screen);
}
  // this.AdminUserList();

  addFile(): void {
    console.log("ImportStudent");

    let fi = this.fileInput.nativeElement;
    if (fi.files && fi.files[0]) {
      let fileToUpload = fi.files[0];
      this.adminUserService
        .ImportAdmin(fileToUpload)
        .subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message, 'Success');
            this.getUsersList(this.filterModel, '', '',);
            this.InitialGridCall()
          } else {
            this.toaster.error(res.message, 'Error');
          }
        });
    }
  }
  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, changeState.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getUsersList(this.filterModel, '', '')

  }

  activityLog:any;

  ActivityLogTrack()
  {
    
      this.activityLog={
        ActivityLogTrack:{
                ModuleName: 'Academic & Learning',
                SubModuleName: 'Teachers & Staff/Admin',
                ActivityTypeId: 10,
                Description: 'Admin list record viewed by',
                ScreenName: 'Manage Admin',
                RecordTypeId: 3,  
                RecordId: 0,      
                Type: 0
              }
      }
    
    this.masterService.GetActivityLogTrack(this.activityLog).subscribe((response:any)=>{
      if(response!=null && response !=undefined && response !=''){
        let responseData=response.responseData;
        console.log("responseData:",responseData);
      }


    });
  }

  onSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "adminName": this.form.controls.adminName.value,
      "contactNumber": this.form.controls.contactNumber.value,
      "userName": this.form.controls.userName.value,
      "email": this.form.controls.email.value,
      "columnName": "",
      "sortOrder": ""
    }
    console.log(this.requestpages);
    this.getUsersList(this.requestpages, '', '')

    //this.AdminUserList();
  }
  //metaData: any;
  // AdminUserList() {
  //   this.adminUserService.AllAdminUser(this.requestpages).subscribe((res: any) => {
  //     if(res.status > 0){
  //       console.log(res);
  //       this.usersData = res.responseData;
  //       this.metaData = res.meta;

  //     }else{
  //       this.metaData = new Metadata();
  //     }

  //   });
  // }
  adminID: number;
  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.adminID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;
      case 'DELETE':
        {
          this.user = localStorage.getItem('currentUser');
          const currentUser = JSON.parse(this.user);
          console.log("USERR", currentUser);
          this.adminID = currentUser.userId;
          if (currentUser.userId == id) {
            this.toaster.warning("You are not allowed to delete!", 'Warning');
          } else {
            this.dialogService
              .confirm(`Are you sure you want to delete this user?`)
              .subscribe((result: any) => {
                if (result == true) {
                  this.DeleteStaff(id);
                }
              });
          }

        }
        break;
      case 'DETAILS':
        this.infoCall(id);
        break;
      default:
        break;
    }
  }

  onselect(data?: any) {
    if (data == undefined) {

      this.printFlag = false;
    }
    else {
      if (data.length != undefined) {
        if (data.length == 0) {
          this.printFlag = false;
          this.selectedData = data.length;
          console.log('Rhis is our selected data ', this.selectedData)
        }
        else {
          this.selectedRow = data;
          this.selectedData = data.length;
          this.printFlag = true;
        }
      }
    }
  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getUsersList(this.filterModel, '', '');
    }
  }
  filterData:any
  getUsersList(filterModel: any, tags: any, roleIds: any) {
    this.adminUserService.GetAdminList(filterModel).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);
        this.usersData = res.responseData;
        this.filterData=this.usersData
        console.log('user data first time ',this.usersData.length)
        this.metaData = res.meta;

      } else {
        this.metaData = new Metadata();
      }

    });
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  infoCall(staffId: any) {
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(["admin/academicslearning/view-admin-staff"], { queryParams: { id: staffId } });
  }
  editCall(staffId: any) {
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(["admin/academicslearning/edit-admin-staff"], { queryParams: { id: staffId } });
  }

  deleteId: any;
  actionDialogPopup(templateRef: TemplateRef<any>, id: any) {
    this.dialog.open(templateRef, {
      data: id

    });
    this.deleteId = id;
  }

  importDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {


    });

  }

  // actionDialogPopup(templateRef: TemplateRef<any>) {
  //   this.updateTextInPopUp=false;
  //   this.dialog.open(templateRef,{
  //   });
  //   this.studentsid=[];
  //   for(var i =0;i< this.selectedRow.length;i++){
  //     this.studentsid.push(JSON.stringify(this.selectedRow[i]))
  //   }
  //   this.studentIdList = {
  //     "studentsID": this.studentsid
  //   }
  //   console.log("INSIDEACTIONPOPUP",this.studentsid)
  //   this.GetStudentByIdList();
  // }


  DeleteStaff(id: any) {
    this.adminUserService.DeleteAdmin(id).subscribe((res: any) => {

      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        //this.reset();
        this.getUsersList(this.filterModel, '', '',);
        //this.dialog.closeAll();

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }


  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getUsersList(this.requestpages, '', '')
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "adminName": "",
      "email": "",
      "contactNumber": "",
      "userName": "",
      "columnName": "",
      "sortOrder": ""
    }
  }


  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }



  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  unselectAll() {

    this.columnForm.patchValue({
      adminName: true,
      adminID: false,
      userName: false,
      email: false,
      contactNumber: false
    })
    this.onSelectColumn();
  }
  selectAll() {
    this.columnForm.patchValue({
      adminName: true,
      adminID: true,
      userName: true,
      email: true,
      contactNumber: true
    })
    this.onSelectColumn();
  }
  getadminColumn() {
    this.adminUserService.GetAdminTablecolumn(this.userId).subscribe((res: any) => {

      this.columnForm.patchValue({
        adminName: res.responseData.adminName,
        userName: res.responseData.userName,
        adminID: res.responseData.adminID,
        email: res.responseData.email,
        contactNumber: res.responseData.contactNumber,
        userId: res.responseData.userId
      })
      console.log("GETAADMIN COLUMN", res.responseData)

      this.onSelectColumn();
    })

    console.log('this is column form data', this.columnForm)

  }
  onSelectColumn() {

    this.columnsToDisplay = [
      { displayName: 'Admin Id', key: 'adminID', isSort: true, class: '', width: '20%' },
      { displayName: 'Admin Name', key: 'adminName', isSort: true, class: '', width: '20%' },
      { displayName: 'EMAIL', key: 'email', isSort: true, class: '', width: '10%' },
      { displayName: 'PHONE NUMBER', key: 'contactNumber', isSort: true, class: '', width: '10%' },
      { displayName: 'User Name', key: 'userName', isSort: true, class: '', width: '10%' },

      { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

    ];
    this.displayedColumns = this.columnsToDisplay
    for (var i = 0; i < this.displayedColumns.length; i++) {
      if (this.columnForm.value.adminName == false) {
        if (this.displayedColumns[i].key == 'adminName')
          this.displayedColumns.splice(i, 1);
      }
      if (this.columnForm.value.userName == false) {
        if (this.displayedColumns[i].key == 'userName')
          this.displayedColumns.splice(i, 1);
      }
      if (this.columnForm.value.email == false) {
        if (this.displayedColumns[i].key == 'email')
          this.displayedColumns.splice(i, 1);
      } if (this.columnForm.value.contactNumber == false) {
        if (this.displayedColumns[i].key == 'contactNumber')
          this.displayedColumns.splice(i, 1);
      }
      if (this.columnForm.value.adminID == false) {
        if (this.displayedColumns[i].key == 'adminID')
          this.displayedColumns.splice(i, 1);
      }
    }
    this.removeUserName();
    this.removeEmail();
    this.adminUserService.UpdateAdminTable(this.columnForm.value).subscribe((res: any) => {
      console.log('this is admin user unselect all , ', res)

    })
  }

  removeUserName() {
    for (var i = 0; i < this.displayedColumns.length; i++) {
      if (this.columnForm.value.userName == false) {
        if (this.displayedColumns[i].key == 'userName')
          this.displayedColumns.splice(i, 1);
      }
    }
  }
  removeEmail() {
    for (var i = 0; i < this.displayedColumns.length; i++) {
      if (this.columnForm.value.email == false) {
        if (this.displayedColumns[i].key == 'email')
          this.displayedColumns.splice(i, 1);
      }
    }
  }
  formbind: any;
  updateTextInPopUp: boolean;
  adminsid: any;
  adminIdList: any;
  adminData: any;
  adminEmailSent(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef, {
    });
    this.adminsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.adminsid.push(JSON.stringify(this.selectedRow[i]))
    }
    this.adminIdList = {
      "adminID": this.adminsid
    }
    console.log("INSIDEACTIONPOPUP", this.adminsid)
    this.GetAdminByIdData();
    this.adminEmailForm = this.formBuilder.group({
      fromEmail: [],
      subject: ['', Validators.required],
      parentEmail: [''],
      body: ['', Validators.required],
      template:[''],
      attachment:['']
    }
    );
    this.GetCustomTemplateList();
  }
  customtemplate: any[] = []
  GetCustomTemplateList(){
    this.studentService.GetCuastomEmailTemplate().subscribe((res :any) => {
      console.log("CustomeEmailTemplateList", res.responseData)
      this.customtemplate = res.responseData;
      for(var i=0;i<this.customtemplate.length;i++){
        if(this.customtemplate[i].templateName === "Default"){
          this.adminEmailForm.patchValue({
            'template': this.customtemplate[i].emailTemplateID,
            'body': this.customtemplate[i].emailBody
          });
        }
      }
    })
  }
  GetAdminByIdData() {
    console.log("SELECTED Admin", this.adminIdList)
    this.studentService.GetMultipleAdminData(this.adminIdList).subscribe((res: any) => {
      console.log('get admin by id', res.responseData);
      this.adminData = res.responseData
    })
  }
  selectChangeTemplate(event:any){
    //this.selectedTemplate = event.target.value;
    this.studentService.GetEmailTemplateById(this.adminEmailForm.controls.template.value===null?6:this.adminEmailForm.controls.template.value).subscribe((tempRes: any) => {
      this.adminEmailForm.patchValue({
        'body': tempRes["responseData"].emailBody
      });
    });
  }
  submitted = false;
  attachmentData:any[]=[];
  uploadFile(event: any){
    this.attachmentData = [];
    console.log("UPLOADD",event.target.files)
    for (var i = 0; i < event.target.files.length; i++) { 
      this.attachmentData.push(event.target.files[i]);
  }
  }
  onSubmitEmail() {
    this.submitted = true;

    if (this.adminEmailForm.invalid) {
      return;
    }
    let arr = [];
    this.adminData.forEach((item: any) => {
      arr.push(item.email)

    });
    if (this.adminEmailForm.controls.parentEmail.value != '') {
      arr.push(this.adminEmailForm.controls.parentEmail.value)
    }
    let isFileAttached:boolean;
    if(this.attachmentData.length > 0){
      isFileAttached = true;
    }
    else{
      isFileAttached = false
    }
    let subject = this.adminEmailForm.controls.subject.value;
      let body = this.adminEmailForm.controls.body.value;
      let organizationID = this.orgID;
      let fromEmail =  "info@creocms.com.au";
    // this.formbind = {
    //   'toEmailMutiple': arr,
    //   'subject': this.adminEmailForm.controls.subject.value,
    //   'body': this.adminEmailForm.controls.body.value
    // }
    console.log(this.formbind);
    this.studentService.EmailAttachment(arr, subject, body, organizationID, fromEmail, this.attachmentData,isFileAttached).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reloadCurrentRoute();
        this.reset()
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  /// for message send
  formbindmsms: any;
  adminSmsSent(templateRef: TemplateRef<any>) {
    this.updateTextInPopUp = true;
    this.dialog.open(templateRef, {
    });
    this.adminsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.adminsid.push(JSON.stringify(this.selectedRow[i]))
    }
    this.adminIdList = {
      "adminID": this.adminsid
    }
    console.log("INSIDEACTIONPOPUP", this.adminsid)
    this.GetAdminByIdData();
    this.adminSmsForm = this.formBuilder.group({

      parentContactNo: [''],
      message: ['', Validators.required],
      parentCheckSms: false
    }
    );

  }

  boxhidesms = true;
  onparentSmsChange() {
    if (this.adminSmsForm.controls.parentCheckSms.value) {
      this.boxhidesms = false;
    } else {
      this.boxhidesms = true;
      this.adminSmsForm.controls.parentContactNo.setValue('');
      //this.studentSmsForm.controls.parentContactNo.setErrors(null)
    }
  }

  onSubmitSms() {
    this.submitted = true;

    if (this.adminSmsForm.invalid) {
      return;
    }
    let arr = [];
    this.adminData.forEach((item: any) => {
      arr.push(item.contactNumber)

    });
    if (this.adminSmsForm.controls.parentContactNo.value != '') {
      arr.push(this.adminSmsForm.controls.parentContactNo.value)
    }
    this.formbind = {
      'multipleMobileNo': arr,
      'message': this.adminSmsForm.controls.message.value
    }
    console.log(this.formbind);
    this.studentService.StudentSentSms(this.formbind).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  // email Invite to
  adminEmailSentInvite() {
    this.adminsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.adminsid.push(JSON.stringify(this.selectedRow[i]))
    }
    this.adminIdList = {
      "adminID": this.adminsid
    }
    this.GetAdminByIdData();
    this.dialogService
      .confirm(`Are you sure you want invite to login!`)
      .subscribe((result: any) => {
        if (result == true) {
          this.emailInviteCall();
        }
      });
  }

  emailInviteCall() {

    let arrx: any = [];
    this.adminData.forEach((item: any) => {
      arrx.push(item.email)
    });
    let msg = "<p><span>Hello,<br>Please click to login site <a classname='e-rte-anchor' href='[orgUrl]' title='[orgUrl]' target='_blank'>[orgUrl]/</a></span></p><p><span><a classname='e-rte-anchor' href='[orgUrl]' title='[orgUrl]' target='_blank'><br></a></span><br></p><p><span>thank you</span></p><p><span>team support</span></p><p><span><br></span></p><p><span><br></span><br></p>";
    let data = {
      'toEmailMutiple': arrx,
      'subject': 'Invite to Login',
      'body': msg
    }
    console.log("EMAILINVITE", data);
    this.adminUserService.sentAdminEmail(data).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
  /// end

  /// invite to SMS
  adminSmsSentInvite() {
    this.adminsid = [];
    for (var i = 0; i < this.selectedRow.length; i++) {
      this.adminsid.push(JSON.stringify(this.selectedRow[i]))
    }
    this.adminIdList = {
      "adminID": this.adminsid
    }
    this.GetAdminByIdData();
    this.dialogService
      .confirm(`Are you sure you want invite to login!`)
      .subscribe((result: any) => {
        if (result == true) {
          this.smsInviteCall();
        }
      });

  }

  smsInviteCall() {
    let arrd: any = [];
    this.adminData.forEach((item: any) => {
      arrd.push(item.contactNumber)
    });
    let data = {
      'multipleMobileNo': arrd,
      'message': "Hello,\n Please click to login site "+this.url+"/ \n thank you \n team support"
    }
    console.log(data);
    this.studentService.StudentSentSms(data).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.reset();
        this.reloadCurrentRoute();
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }
}




export interface PeriodicElement {
  id: number;

  adminName: string;
  adminID: string;
  userName: string;
  email: string;
  contactNumber: string;
  actions: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { id: 1, adminName: 'Virat Sharma', adminID: 'test001', userName: 'Academic', email: 'test@gmail.com', contactNumber: '123456789', actions: ' ' },
];





