import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.service';
import { AdminStaffService } from 'src/app/services/admin-staff.service';
import { MasterService } from 'src/app/services/master.service';
import { Location } from '@angular/common'
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RoleAndPermissionService } from 'src/app/services/role-and-permission.service';
@Component({
  selector: 'app-edit-admin-staff',
  templateUrl: './edit-admin-staff.component.html',
  styleUrls: ['./edit-admin-staff.component.css']
})
export class EditAdminStaffComponent implements OnInit {


  form: FormGroup;
  adminID: number;
  data: string;
  previewUrl: any = '../../../assets/img/display.jpg';
  image1: string = '';

  @ViewChild('phone') phone: any;


  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private adminService: AdminStaffService,
    private masterService: MasterService,
    private router: Router,
    private toaster: ToastrService,
    private commonService: CommonService,
    private location: Location,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private roleService: RoleAndPermissionService,
  ) { 
    this.GetCountiresList();
    this.GetRolesList();
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
  countries: any;
  bloodGroups: any;
  genders: any;
  phoneShow: boolean;


  back() {
    this.location.back()
  }
  ngOnInit(): void {

    //this.adminUserID = this.activatedRoute.snapshot.params.Id 
    this.activatedRoute.queryParams.subscribe(params => {
      this.adminID = params.id == undefined ? null : params.id;
    });


    this.form = this.formBuilder.group({
      adminID: this.adminID,
      //  studentID:[0],

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      middleName: [''],
      contactNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: [''],
      zipCode: [''],
      countryID: ['', Validators.required],
      stateID: ['', Validators.required],
      city: ['', Validators.required],
      profilePhoto: [''],
      userID: [0],
      hasExcess: [true],
     // password: [''],
      userName: ['', Validators.required],
      roleID: ['', Validators.required],

      isSendCredentials: [true]
    });
    this.GetAdminById();

    

  }
  roleList: any;
  rolesData:any=[];
  GetRolesList(){
    this.roleService.getRoleListDropdown().subscribe((res: any)=> {
       this.roleList = res.responseData;
       this.rolesData=[...this.roleList];
    })
  }
  /// crop image
  /// crop image
  imageChangedEvent: any = '';
  croppedImage1: any = '../../../assets/img/display.jpg';
  croppedImage2: any = '../../../assets/img/banner-img.png';
  imageNumber: any;
  fileChangeEvent(event: any, imgNum: any): void {
    this.imageChangedEvent = event;
    this.imageNumber = imgNum;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent, img: any) {
    if (this.imageNumber == '1') {
      this.croppedImage1 = event.base64;
    }


    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
    this.imageFile = this.imageChangedEvent.target.files[0];
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  finalUpload() {
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  actionDialogPopup(templateRef: TemplateRef<any>, data: any, imgNumner: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data, imgNumner);

  }
  previousStateVal: any = [];
  previousCountryVal: any = [];
  previousRoleVal : any=[];
  onKeyFilter(val: any, type: string){
      let serachTxt = String(val.target.value);
      if(type == 'state') {
        if(serachTxt != ''){
          this.states = this.previousStateVal.filter((x: any)=> x.stateName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
        } else {
          this.states = this.previousStateVal;
        }
      } else if(type == 'country') {
        if(serachTxt != ''){
          this.countries = this.previousCountryVal.filter((x: any)=> x.countryName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
        } else {
          this.countries = this.previousCountryVal;
        }
      }


      else if(type == 'role') {
        if(serachTxt != ''){
          this.roleList = this.previousRoleVal.filter((x: any)=> x.roleName.toLocaleLowerCase().indexOf(serachTxt.toLocaleLowerCase()) !== -1);
        } else {
          this.roleList = this.previousRoleVal;
        }
      }
  }


  countryName:any
  dispalyFns(countryName:any){
    this.countryName=this?.countrie.filter((a:any)=>a.countryID==countryName);
    return this.countryName[0]?.countryName

  }
stateName:any=[];
  dispalyStateAuto(stateName:any){
  this.stateName=this.statesValue.filter((a:any)=>a.stateID==stateName);
  return this.stateName[0]?.stateName;
  }

  roleName:any=[];
  dispalyRolesAuto(roleName:any){
    this.roleName=this.rolesData.filter((a:any)=>a.roleID==roleName)
    return this.roleName[0]?.roleName;
    }



  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  imageUrl: any;
  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();

    formData.append('files', file);
    this.AddAboutUsImage(formData);


  }

  AddAboutUsImage(file: any) {
    this.masterService.postDataAboutImg(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {

        switch (this.imageNumber) {
          case '1':
            this.image1 = res.body.responseData.imageName;
            break;

          default:
            break;
        }
        this.imageNumber = '';
        console.log(res.body.responseData.imageName);
      }

    })
  }


  GetAdminById() {
    this.adminService.GetAdminById(this.adminID).subscribe((res: any) => {
      // if (res.status > 0) {
      //   this.croppedImage1 =  res.responseData.adminImageBase64;

      //this.id = res.responseData['homePageID'];
      //this.id = res.responseData['aboutUsID'];
      this.form.patchValue({
        adminID: res.responseData[0]['adminID'],
        firstName: res.responseData[0]['firstName'],
        lastName: res.responseData[0]['lastName'],
        email: res.responseData[0]['email'],
        address: res.responseData[0]['address'],
        city: res.responseData[0]['city'],
        contactNumber: res.responseData[0]['contactNumber'],
        countryID: res.responseData[0]['countryID'],
        stateID: res.responseData[0]['stateID'],
        zipCode: res.responseData[0]['zipCode'],
        userName: res.responseData[0]['userName'],
        roleID: res.responseData[0]['roleID']


      });
      if (res?.responseData[0]['profilePhoto']) {
        this.croppedImage1 = res.responseData[0]['profilePhoto']
      }
      this.GetStateList(res.responseData[0]['countryID'],res.responseData[0]['stateID'],true);
      this.data = res.responseData;
      console.log("sae", this.data)
      if (res.responseData.profilePhoto != '') {

        this.croppedImage1 = res.responseData[0].profilePhoto;

        console.log('profilePhoto', res.responseData[0].profilePhoto);



        //   this.croppedImage1 =  res.responseData.aboutUsImageBase64;
      }
    });
  }
  statesValue:any=[];
  countrie:any;
  GetCountiresList() {
    this.masterService.GetCountryDropdown().subscribe((res: any) => {
      this.countries = res.responseData;
      this.countrie=[...this.countries];
      this.previousCountryVal = Object.assign([], this.countries);
    })

  }

  onSubmit() {
    this.updateStaff();
  }

  updateStaff() {

    if (this.image1 != '') {
      this.form.controls.profilePhoto.setValue(this.image1);
    } else {
      if (this.form.controls.adminID.value == 0) {
        this.toaster.error('Upload images first', 'Error');
        return;
      }

    }

    console.log("PHONENO", this.phone)
    this.form.value.contactNumber = this.phone.numberInstance.nationalNumber
    this.form.value.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
    this.form.value.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);
    // let newPassword
    // newPassword = this.randPass(2,2,2) 
    // this.form.value.password = newPassword;
    this.adminService.UpdateAdmin(this.form.value).subscribe((res: any) => {
      console.log("update", res);
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        if (this.imageFile) {
          this.adminService.UpdateAdminProfilePhoto(this.adminID, this.imageFile).subscribe((res: any) => {
            this.back();
          }, err => this.back());
        } else
          this.back();



      } else {
        this.toaster.error(res.message, 'Error');
      }

    })
  }


  states: any;
  
  GetStateList(countryId: any,stateID? :any, update ?: boolean ) {
    //let countryID= countryId.option['value'];
    this.form.controls['stateID'].setValue('');
    let countryID= this.form.controls['countryID'].value;
    this.masterService.GetStateDropdown(countryID).subscribe((res: any) => {
      this.statesValue=res.responseDate;
      this.states = res.responseData;
      this.statesValue=[...this.states];
      this.previousStateVal = Object.assign([], this.states);
      if(update){
        this.form.patchValue({
          stateID: stateID
        });
      }
      console.log(res);
    })
  }

  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }

  phoneLength(event: any) {
    this.phoneShow = this.commonService.phoneLengths(event.target.value.length);
  }
  checkWhiteSpace(event:any) {
    if (event.target.value.length > 0) {
      return;
    } else {
      event.preventDefault();
    }
  }

//   randPass(lettersLength: number ,numbersLength: number, splCharLength : number) {
//     var j, x, i;
//     var result : any
//     var letters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
//     var numbers       = '0123456789';
//     var specialCharacter = '(~!@#$%^&*_-+=`|\(){}[]:;"<>,.?/)';
//     for (i = 0; i < lettersLength; i++ ) {
//         result += letters.charAt(Math.floor(Math.random() * letters.length));
//     }
//     for (i = 0; i < numbersLength; i++ ) {
//         result += numbers.charAt(Math.floor(Math.random() * numbers.length));
//     }
//     for (i = 0; i<splCharLength; i++) {
//         result += specialCharacter.charAt(Math.floor(Math.random()*specialCharacter.length));
//     }
//     result = result.split("");
//     for (i = result.length - 1; i > 0; i--) {
//         j = Math.floor(Math.random() * (i + 1));
//         x = result[i];
//         result[i] = result[j];
//         result[j] = x;
//     }
//     result = result.join("");
//     return result
// }

}

