<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2>{{firstName}} {{lastName}}</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage
                            Teachers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">View Teacher Deatails</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-teachers">Back</button>
            </div>
          </div>
    </div>
    <div class="cardWidget">
        <app-teacherstabs></app-teacherstabs>
        <div class="cardBody">
         
            <div class="profileDetailsWidget pt-4 d-md-flex">
                <div class="profilePicLeft form-group30">
                    <p class="chngePic">
                        <input type="file">

                        <img [src]="croppedImage" alt="">

                    </p>
                </div>
                <div class="profileContentRight flex-grow-1 ml-2">
                    <div *ngFor="let grp of getFieldAndGroupDto" class="proSection pt-0">
                        <h5>{{grp.groupName}}</h5>
                        <div class="row">
                            <div *ngFor="let f of grp.fields" class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>{{f.fieldName}} :</label>
                                    <span *ngIf="f.isDate == false">{{f.fieldValue}}</span>
                                    <span *ngIf="f.isDate == true">{{f.fieldValue | date: dateFormat}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="proSection pt-0">
                        <h5>Teacher Details</h5>
                        <div class="row">
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>First Name :</label>
                                    <span>{{firstName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Last Name :</label>
                                    <span>{{lastName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Employee Type Name:</label>
                                    <span>{{employeeTypeName}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Email :</label>
                                    <span>{{email}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Date of Birth : </label>
                                    <span>{{dateOfBirth}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Gender :</label>
                                    <span>{{genderName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Blood Group :</label>
                                    <span>{{bloodGroupName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label> Teacher Contact :</label>
                                    <span>{{contactNumber}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="proSection">
                        <h5>Skills & Social Details</h5>
                        <div class="row">


                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Profile Summary :</label>
                                    <span>{{profileSummary}}</span>
                                </div>
                            </div>

                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label> Skills:</label>
                                    <span>{{teacherSkillsName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>LinkedInLink :</label>
                                    <span>{{linkedInlink}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Facebook Link :</label>
                                    <span>{{facebookLink}}</span>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="proSection">
                        <h5>Additionals Details</h5>
                        <div class="row">
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Address :</label>
                                    <span>{{address}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Zip Code :</label>
                                    <span>{{zipCode}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>Country :</label>
                                    <span>{{countryName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 s12 m6 form-group">
                                <div class="detailsContent">
                                    <label>State :</label>
                                    <span>{{stateName}}</span>
                                </div>
                            </div>






                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</div>