import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, RolesandPermission } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RoleAndPermissionService {

  constructor(private http : HttpClient) { }

  GetRolesList(data:any) {
    return this.http.post(`${environment.baseUrl}${RolesandPermission.getroleList}`,data);
  }
  AddRoles(data:any) {
    return this.http.post(`${environment.baseUrl}${RolesandPermission.addrole}`,data);
  }
  GetRoleScreens() {
    return this.http.get(`${environment.baseUrl}${RolesandPermission.getroleScreen}`);
  }
  GetPermissions() {
    return this.http.get(`${environment.baseUrl}${RolesandPermission.getPermission}`);
  }
  GetPermissionByRoleID (data: any) {

    return this.http.get(`${environment.baseUrl}${RolesandPermission.getPermissionByRoleID}${'?id=' + data}`);
  }
  updateRole(data: any){
    return this.http.post(`${environment.baseUrl}${RolesandPermission.updateRole}`,data);
  }
  getPermissionByUserID(data: any) {
    return this.http.get(`${environment.baseUrl}${RolesandPermission.getPermissionByUserID}${'?id=' + data}`)
  }
  getAllTheme() {
    return this.http.get(`${environment.baseUrl}${RolesandPermission.getAllTheme}`)
  }
  getOrganizationThemeById(Id: any) {
    return this.http.get(`${environment.baseUrl}${RolesandPermission.getOrganizationThemeById}` + '?id=' + Id);
  }
  updateOrganizationTheme(data: any){
    return this.http.post(`${environment.baseUrl}${RolesandPermission.updateOrganizationTheme}`,data);
  }
  getRoleListDropdown() {
    return this.http.get(`${environment.baseUrl}${RolesandPermission.getRoleListDropdown}`);
  }
  deleteRole(data: any) {
    return this.http.get(`${environment.baseUrl}${RolesandPermission.deleteRoles}${'?id=' + data}`)
  }
}
