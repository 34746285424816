import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ImportExportDataComponent } from 'src/app/app-layout/content/settings/organization-settings/import-export-data/import-export-data.component';
import { StudentService } from 'src/app/services/student.service';
import { TeachersService } from 'src/app/services/teachers.service';
import { FormInputBase, ModuleDefinition } from '../../MasterModel/masterModel';
import { ImportStatusReportLinkComponent } from '../import-status-report-link/import-status-report-link.component';

@Component({
  selector: 'app-mapping-container',
  templateUrl: './mapping-container.component.html',
  styleUrls: ['./mapping-container.component.css']
})
export class MappingContainerComponent implements OnInit {
  @Input() dynamicInputControls: FormInputBase<string>[] = [];
  payLoad={};
  @Input() uploadedFile: File;
  @Input() caller: string; 
  statusLink: string;
  @Input() form: FormGroup;
  @Input() excelheaders: string[];
  public unique_key: number;
  public parentRef: ImportExportDataComponent;
  responseMessage: string='';
  responseSent: boolean=false;
  @Input() isAlumni: boolean = false;
  failedCount=0;
  totalCount=0;
  dialogRef: MatDialogRef<ImportStatusReportLinkComponent, any>;
  constructor(private studenService: StudentService,private teacherService: TeachersService,private toaster: ToastrService, private dialog: MatDialog,) { }
  inProgress: boolean =true
  ngOnInit(): void {
    this.form =this.toFormGroup(this.dynamicInputControls);
    console.log(this.form);
    console.log(this.excelheaders);
  }

  ngAfterContentChecked() {
    
     }
  
  toFormGroup(controls: FormInputBase<string>[]) {
    const group: any = {};
    controls.forEach((control) => {
      group[control.key] = control.required
        ? new FormControl('', Validators.required)
        : new FormControl('');
    });
    return new FormGroup(group);
  }

  startAutoMatching(){

    Object.keys(this.form.controls).forEach(key => {
       this.form.controls[key].setValue(key);
   });
  }

  transformPayloadForImport(payload: any){
    const mappings: {key: string, value: string}[]= [];
    const res = {};
    Object.keys(payload).forEach(key => {
      mappings.push({key: key, value:payload[key]?.key || 'Not Mapped'});
    });
    mappings.forEach(({ key, value }) => Object.assign(res, { [key]: value }));

    return res;
  }
  openStatusResponseDialog(ImportProcessId:number,failedCount: number,totalRecords: number,message: string,caller: string,showStats: boolean = true){
     this.dialogRef = this.dialog.open(ImportStatusReportLinkComponent, {
      data: {
        dataKey: {
          message: message,
          link: ImportProcessId,
          caller: caller,
          failedCount: failedCount,
          totalCount: totalRecords,
          showStats: showStats,
          parentRef: this
        },
      }, 
      disableClose:true,
      width: "60vw",
    });
  }
  onButtonClick(event : MouseEvent)
  {
    (event.target as HTMLButtonElement).disabled = true;
    this.onSubmit();
  }
  onSubmit(){
  
    //this.payLoad =this.transformPayloadForImport(this.form.getRawValue());
    console.log("ONSUBMIIT")
    this.payLoad = this.form.getRawValue();

    if(this.uploadedFile){ 
      this.inProgress = !this.inProgress;
      switch(this.caller){
        case ModuleDefinition.Student: {
          this.studenService.ImportExcelFile(this.uploadedFile,this.payLoad,String(this.parentRef.isUpdateExistingChecked),String(this.isAlumni)).subscribe({
            next: (event: any)=>{
                  if(event.responseData) {
                    this.inProgress = true;
                    if (event.responseData.message === 'Success with errors')
                    {
                      this.openStatusResponseDialog(event.responseData.importProcessId,event.responseData.failedCount,event.responseData.totalRecords,"The data has been imported with a few errors. You can download the report from the link provided",ModuleDefinition.Student);
                    }
                    else if(event.responseData.message === 'Error'){
                      this.openStatusResponseDialog(0,event.responseData.failedCount,event.responseData.totalRecords,"There's an error processing your request. Please contact administrator for further assistance",ModuleDefinition.Student,false);
                    }
                    else
                    {
                      this.openStatusResponseDialog(event.responseData.importProcessId,event.responseData.failedCount,event.responseData.totalRecords,"The data has been imported successfully",ModuleDefinition.Student);
                    }
                  }
            },
            error: (event: any)=>{
              this.inProgress = true;
              this.openStatusResponseDialog(0,event.responseData.failedCount,event.responseData.totalRecords,"There's an error processing your request. Please contact administrator for further assistance",ModuleDefinition.Student,false);
            },
          });
          break;
      }
      case ModuleDefinition.Teacher: {
        this.teacherService.ImportExcelFile(this.uploadedFile,this.payLoad,String(this.parentRef.isUpdateExistingChecked)).subscribe({
          next: (event: any)=>{
            if(event.responseData) {
              this.inProgress = true;
              if (event.responseData.message === 'Success with errors')
              {
                this.openStatusResponseDialog(event.responseData.importProcessId,event.responseData.failedCount,event.responseData.totalRecords,"The data has been imported with a few errors. You can download the report from the link provided",ModuleDefinition.Teacher);
              }
              else if(event.responseData.message === 'Error'){
                this.openStatusResponseDialog(0,event.responseData.failedCount,event.responseData.totalRecords,"There's an error processing your request. Please contact administrator for further assistance",ModuleDefinition.Teacher,false);
              }
              else
              {
                this.openStatusResponseDialog(event.responseData.importProcessId,event.responseData.failedCount,event.responseData.totalRecords,"The data has been imported successfully",ModuleDefinition.Teacher);
              }
            }
          },
          error: (event: any)=>{
            this.inProgress = true;
            this.openStatusResponseDialog(0,event.responseData.failedCount,event.responseData.totalRecords,"There's an error processing your request. Please contact administrator for further assistance",ModuleDefinition.Teacher,false);
          },
        });
        break;
      }
    }
  }
  
      
  }
}
