<div class="container container1580">
    <div class="pageTitle">
        <h2>Edit Attachment</h2>
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-teacher-attachment/view-teacher-attachment">Manage Attachment</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Attachment</li>
            </ol>
        </div>
    </div>

    <div class="cardWidget">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                

            </div>
            <div class="profileDetailsWidget pt-4">
                <div class="col-md-6 px-0">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">

                        <div class="profileContentRight flex-grow-1">

                            <div class="proSection">
                              
                                <h2 > Teacher Name :- {{teacherName}}</h2>
                                <br>
                                <div class="">
                                    
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Title </mat-label>
                                            <input  maxlength="100" matInput
                                                placeholder="Title" required name="title"
                                                formControlName="title">
                                            <mat-error
                                                *ngIf="form.get('title')?.touched  && form.get('title')?.errors?.required">
                                                Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Description </mat-label>
                                            <textarea required maxlength="256" formControlName="description" matInput placeholder="Description" ></textarea>
                                            <mat-error *ngIf="form.get('description')?.touched  && form.get('description')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-group">
                                        <input type="file" (change)="uploadFile($event)"  name="filename">
                                    </div>


                                </div>
                            </div>

                            <div class="col-md-3 px-0 mx-auto">
                                <button type="submit" class="btn btn-primary btn-block">Submit</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


