<div class="container container1580">
    <div class="headingcontent">
        <div class="pageTitle mt-0">
            <h2 *ngIf="teacherID == 0">Add Teacher</h2>
            <h2 *ngIf="teacherID > 0">Edit Teacher</h2>
            <div class="breadcrumbWidget">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/admin"><i class="fas fa-home"></i> Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/academicslearning/manage-teachers">Manage
                            Teachers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Add Teacher</li>
                </ol>
            </div>
        </div>
        <div class="">
            <div class="add_button">
              <button class="addbtn btn" matRipple routerLink="/admin/academicslearning/manage-teachers">Back</button>
            </div>
          </div>
    </div>

    <div class="cardWidget">
        <div class="matCard">
            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-2 col-sm-12">
                                <div class="profilePicLeft ">
                                    <p class="chngePic">
                                        <input type="file" accept="image/*"
                                            (change)="actionDialogPopup(uploadPopup,$event)">
                                        <span class="icon centerItem">
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                        <img [src]="croppedImage" alt="">
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-10 col-sm-12">
                                <div class="profileContentRight">
                                    <div *ngFor="let grp of getFieldAndGroupDto">
                                        <div *ngIf="grp.preDefinedGroup=='TEACHERS DETAILS'" class="proSection">
                                            <h5><i class="fas fa-user mr-2"></i> {{grp.groupName}}</h5>
                                            <div class="row-flex">
                                                <ng-container *ngFor="let f of grp.fields">
                                                    <div *ngIf="f.fieldName=='Teacher Email'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Teacher Email</mat-label>
                                                            <input matInput
                                                                (blur)="onBlurMethodtxt($event,grp,'Teacher Email')"
                                                                placeholder="email" required name="email" matInput
                                                                formControlName="email" type="email"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50">
                                                            <mat-error
                                                                *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                                Email is required
                                                            </mat-error>
                                                            <mat-error *ngIf="form.get('email')?.hasError('pattern')">
                                                                Please enter valid email
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Date of Birth'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Date of Birth</mat-label>
                                                            <input
                                                                (dateChange)="onBlurMethodtxt($event,grp,'Date of Birth')"
                                                                (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                                matInput placeholder="Date Of Birth" required
                                                                name="DateOfBirth" formControlName="dateOfBirth"
                                                                [matDatepicker]="picker">
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                            <mat-error
                                                                *ngIf="form.get('dob')?.touched  && form.get('dob')?.errors?.required">
                                                                Date of Birth is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Gender'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Select Gender</mat-label>
                                                            <mat-select 
                                                                (selectionChange)="ddlChange($event.value,grp,'Gender')"
                                                                placeholder="Gender" required name="genderID"
                                                                formControlName="genderID">
                                                                <mat-option value="" disabled>Select Gender</mat-option>
                                                                <mat-option *ngFor="let gender of genders"
                                                                    [value]="gender.genderID">
                                                                    {{gender.genderName}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                                *ngIf="form.get('genderID')?.touched  && form.get('genderID')?.errors?.required">
                                                                Gender is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Contact'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">

                                                            <ngx-mat-intl-tel-input style="border: none;"
                                                                (ngModelChange)="onBlurPhone($event,grp,'Contact')"
                                                                [preferredCountries]="['au', 'us']"
                                                                [enablePlaceholder]="true" [enableSearch]="true"
                                                                name="ContactNumber" formControlName="contactNumber"
                                                                #phone>
                                                            </ngx-mat-intl-tel-input>
                                                            <mat-hint class="hintexted">eg :
                                                                {{phone.selectedCountry.placeHolder}}</mat-hint>
                                                            <mat-error
                                                                *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                                Teacher contact is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='First Name'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>First Name </mat-label>
                                                            <input maxlength="20"
                                                                (blur)="onBlurMethodtxt($event,grp,'First Name')"
                                                                placeholder="First Name" required name="firstname"
                                                                matInput pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="firstName">
                                                            <mat-error
                                                                *ngIf="form.get('firstName')?.touched  && form.get('firstName')?.errors?.required">
                                                                First Name is required
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('firstName')?.hasError('pattern')">
                                                                Please enter valid First Name
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Last Name'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Last Name</mat-label>
                                                            <input maxlength="20"
                                                                (blur)="onBlurMethodtxt($event,grp,'Last Name')"
                                                                placeholder="Last Name" required name="lastName"
                                                                matInput pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="lastName">
                                                            <mat-error
                                                                *ngIf="form.get('lastName')?.touched  && form.get('lastName')?.errors?.required">
                                                                Last Name is required
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('lastName')?.hasError('pattern')">
                                                                Please enter valid Last Name
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Teacher Code'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Teacher Code</mat-label>
                                                            <input maxlength="10"
                                                                (blur)="onBlurMethodtxt($event,grp,'Teacher Code')"
                                                                matInput placeholder="Teacher Code" required
                                                                name="teacherCode" formControlName="teacherNumber"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$">
                                                            <mat-error
                                                                *ngIf="form.get('teacherNumber')?.touched  && form.get('teacherNumber')?.errors?.required">
                                                                Teacher Code is required
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="form.get('teacherNumber')?.hasError('pattern')">
                                                                Please enter valid Teacher Code
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Employee Type'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Employee Type</mat-label>
                                                            <mat-select 
                                                                (selectionChange)="ddlChange($event.value,grp,'Employee Type')"
                                                                placeholder="Employee Type" required name="employeeTypeID"
                                                                formControlName="employeeTypeID">
                                                                <mat-option value="" disabled>Select Employee Type</mat-option>
                                                                <mat-option *ngFor="let employee of employeeTypes "
                                                            [value]="employee.employeeTypeID">
                                                            {{employee.employeeTypeName}}
                                                        </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                                *ngIf="form.get('employeeTypeID')?.touched  && form.get('employeeTypeID')?.errors?.required">
                                                                Employee Type is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                    class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                             [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            type="number" (keypress)="allowOnlyNumber($event)" [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <mat-select
                                                            (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                            [placeholder]="f.fieldName" [required]="f.isRequried==true" 
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-option value="" disabled>Select {{f.fieldName}}
                                                            </mat-option>
                                                            <mat-option
                                                                *ngFor="let drp of splitValues(f.defaultValue)"
                                                                [value]="drp">
                                                                {{drp}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                                class="col-12 py-2">
                                                    <div class="w-100" appearance="outline">
                                                       
                                                        <ng-container>
                                                            <div class="row">
                                                                <div class="col-1">
                                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                                </div>
                                                                <div class="col-11 flex-wrap">
                                                            <ul class="check d-flex flex-wrap">
                                                              
                                                                <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                    <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                        (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                        {{ chk }}
                                                                    </mat-checkbox>
                                                                </li>
                                                            </ul>
                                                            </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            matInput [name]="f.fieldName" [required]="f.isRequried==true"
                                                            [placeholder]="f.fieldName" 
                                                            matInput formControlName="{{ f.fieldName }}"
                                                            type="email" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                            >
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                            Please enter valid email
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input
                                                            (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                            matInput [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input
                                                            (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                            matInput [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            input 
                                                            (keypress)="allowAlphaNumber($event)" matInput
                                                            [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            ></textarea>
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div *ngIf="grp.preDefinedGroup=='ADDITIONAL INFORMATION'" class="proSection">
                                            <h5><i class="fas fa-user mr-2"></i> {{grp.groupName}}</h5>
                                            <div class="row-flex">
                                                <ng-container *ngFor="let f of grp.fields">
                                                <div *ngIf="f.fieldName=='Blood Group'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Select Blood Group</mat-label>
                                                            <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,'Blood Group')"
                                                                placeholder="Blood Group" name="bloodGroup" [required]="f.isRequried==true"
                                                                formControlName="bloodGroupID">
                                                                <mat-option value="" disabled>Select Blood Group
                                                                </mat-option>
                                                                <mat-option *ngFor="let bloodGroup of bloodGroups"
                                                                    [value]="bloodGroup.bloodGroupID">
                                                                    {{bloodGroup.bloodGroupName}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                            *ngIf="form.get('bloodGroupID')?.touched  && form.get('bloodGroupID')?.errors?.required">
                                                            Blood Group is required
                                                        </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Address'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Address</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'Address')"
                                                                matInput placeholder="address"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="address"
                                                                maxlength="100" [required]="f.isRequried==true">
                                                            <mat-error *ngIf="form.get('address')?.hasError('pattern')">
                                                                Please enter valid Address
                                                            </mat-error>
                                                            <mat-error
                                                            *ngIf="form.get('address')?.touched  && form.get('address')?.errors?.required">
                                                            Address Group is required
                                                        </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Country'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Country</mat-label>
                                                            <!-- <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,'Country')"
                                                                placeholder="Country"
                                                                (valueChange)="GetStateList($event)"
                                                                formControlName="countryID" [required]="f.isRequried==true"> -->


                                                                <input type="text" (input)="onKeyFilter($event, 'country')" matInput
                                                                formControlName="countryID" [matAutocomplete]="countryauto">
                                                               
                
                                                                <mat-autocomplete #countryauto="matAutocomplete" 
                                                                (optionSelected)="GetStateList($event);ddlChange($event.option.value,grp,'Country')" [displayWith]="dispalyFns.bind(this)" >

                                                                <mat-option value="" [disabled]="true">Select Country
                                                                </mat-option>
                                                                <mat-option *ngFor="let country of countries"
                                                                    [value]="country.countryID">
                                                                    {{country.countryName}}
                                                                </mat-option>
                                                            <!-- </mat-select> -->
                                                            <!-- <mat-error
                                                            *ngIf="form.get('countryID')?.touched  && form.get('countryID')?.errors?.required">
                                                            Country is required
                                                        </mat-error> -->
                                                        </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>

                                                    <!-- [disabled]="true" -->
                                                    <div *ngIf="f.fieldName=='State'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>State</mat-label>
                                                            <!-- <mat-select
                                                                (selectionChange)="ddlChange($event.value,grp,'State')"
                                                                placeholder="State" formControlName="stateID" [required]="f.isRequried==true">
                                                                <input class="form-control keyySrch"  (keyup)="onKeyFilter($event, 'state')"> -->

                                                                <input type="text" (input)="onKeyFilter($event, 'state')" matInput
                                                                formControlName="stateID" [matAutocomplete]="stateAuto">
                
                
                                                                <mat-autocomplete #stateAuto="matAutocomplete" 
                                                                [displayWith]="dispalyStateAuto.bind(this)"(optionSelected)="ddlChange($event.option.value,grp,'State')">

                                                                <mat-option value="" [disabled]="true">Select State</mat-option>
                                                                <mat-option *ngFor="let state of states"
                                                                    [value]="state.stateID">
                                                                    {{state.stateName}}
                                                                </mat-option>
                                                            <!-- </mat-select> -->
                                                            <!-- <mat-error
                                                            *ngIf="form.get('stateID')?.touched  && form.get('stateID')?.errors?.required">
                                                            State is required
                                                        </mat-error> -->
                                                        </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='City'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>City</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'City')"
                                                                maxlength="20" matInput placeholder="city"
                                                                formControlName="city" [required]="f.isRequried==true">
                                                        </mat-form-field>
                                                        <mat-error
                                                            *ngIf="form.get('city')?.touched  && form.get('city')?.errors?.required">
                                                            city is required
                                                            </mat-error>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='Zip Code'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Zip Code</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'Zip Code')"
                                                                (keypress)="allowAlphaNumber($event)" maxlength="10"
                                                                matInput placeholder="Zip Code" [required]="f.isRequried==true"
                                                                pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                formControlName="zipCode">
                                                                <mat-error
                                                            *ngIf="form.get('zipCode')?.touched  && form.get('zipCode')?.errors?.required">
                                                            Zip Code is required
                                                            </mat-error>
                                                            <mat-error *ngIf="form.get('zipCode')?.hasError('pattern')">
                                                                Please enter valid Zip Code
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                    class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                             [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            type="number" (keypress)="allowOnlyNumber($event)" [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <mat-select
                                                            (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                            [placeholder]="f.fieldName" [required]="f.isRequried==true" 
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-option value="" disabled>Select {{f.fieldName}}
                                                            </mat-option>
                                                            <mat-option
                                                                *ngFor="let drp of splitValues(f.defaultValue)"
                                                                [value]="drp">
                                                                {{drp}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                                class="col-12 py-2">
                                                    <div class="w-100" appearance="outline">
                                                       
                                                        <ng-container>
                                                            <ul class="check d-flex">
                                                                <mat-label>{{f.fieldName}} </mat-label>
                                                                <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                    <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                        (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                        {{ chk }}
                                                                    </mat-checkbox>
                                                                </li>
                                                            </ul>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            matInput [name]="f.fieldName"
                                                            [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                            matInput formControlName="{{ f.fieldName }}"
                                                            type="email" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                            >
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                            Please enter valid email
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input
                                                            (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                            matInput [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input
                                                            (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                            matInput [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            input 
                                                            (keypress)="allowAlphaNumber($event)" matInput
                                                            [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            ></textarea>
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div *ngIf="grp.preDefinedGroup=='SKILLS & SOCIAL DETAILS'" class="proSection">
                                            <h5><i class="fas fa-user mr-2"></i> {{grp.groupName}}</h5>
                                            <div class="row-flex">
                                                <ng-container *ngFor="let f of grp.fields">
                                                    <div *ngIf="f.fieldName=='Profile Summary'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Profile Summary</mat-label>
                                                            <textarea
                                                                (blur)="onBlurMethodtxt($event,grp,'Profile Summary')"
                                                                input (keypress)="allowAlphaNumber($event)" matInput
                                                                placeholder="Profile Summary"
                                                                formControlName="profileSummary"
                                                                maxlength="250"></textarea>
                                                                <mat-error
                                                                *ngIf="form.get('profileSummary')?.touched  && form.get('profileSummary')?.errors?.required">
                                                                Profile Summary is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Skills'" class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Skill</mat-label>
                                                            <input (blur)="onBlurMethodtxt($event,grp,'Skills')"
                                                                matInput placeholder="skill"
                                                                formControlName="teacherSkillsID">
                                                                <mat-error
                                                                *ngIf="form.get('teacherSkillsID')?.touched  && form.get('teacherSkillsID')?.errors?.required">
                                                                Skills is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.fieldName=='Facebook Profile Link'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>Facebook Profile Link</mat-label>
                                                            <input
                                                                (blur)="onBlurMethodtxt($event,grp,'Facebook Profile Link')"
                                                                matInput placeholder="Facebook Profile Link"
                                                                maxlength="50" formControlName="facebookLink">
                                                            <!-- (keypress)="allowAlphabetOnly($event)" -->
                                                            <mat-error
                                                                *ngIf="form.get('facebookLink')?.touched  && form.get('facebookLink')?.errors?.required">
                                                                Facebook Profile Link is required
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>

                                                    <div *ngIf="f.fieldName=='LinkedIn Profile Link'"
                                                        class="col s12 m6 form-group">
                                                        <mat-form-field class="w-100" appearance="outline">
                                                            <mat-label>LinkedIn Profile Link</mat-label>
                                                            <input
                                                                (blur)="onBlurMethodtxt($event,grp,'LinkedIn Profile Link')"
                                                                matInput type="url" placeholder="LinkedIn Profile Link"
                                                                formControlName="linkedInlink" maxlength="50">
                                                                <mat-error
                                                                *ngIf="form.get('linkedInlink')?.touched  && form.get('linkedInlink')?.errors?.required">
                                                                LinkedIn Profile Link is required
                                                                </mat-error>
                                                            <!-- (keypress)="allowAlphabetOnly($event)" -->
                                                        </mat-form-field>
                                                    </div>
                                                    <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                    class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                             [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            type="number" (keypress)="allowOnlyNumber($event)" [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <mat-select
                                                            (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                            [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}">
                                                            <mat-option value="" disabled>Select {{f.fieldName}}
                                                            </mat-option>
                                                            <mat-option
                                                                *ngFor="let drp of splitValues(f.defaultValue)"
                                                                [value]="drp">
                                                                {{drp}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                                class="col-12 py-2">
                                                    <div class="w-100" appearance="outline">
                                                       
                                                        <ng-container>
                                                            <ul class="check d-flex">
                                                                <mat-label>{{f.fieldName}} </mat-label>
                                                                <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                    <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                        (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                        {{ chk }}
                                                                    </mat-checkbox>
                                                                </li>
                                                            </ul>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            matInput [name]="f.fieldName" [required]="f.isRequried==true"
                                                            [placeholder]="f.fieldName" 
                                                            matInput formControlName="{{ f.fieldName }}"
                                                            type="email" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                            >
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                            Please enter valid email
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input
                                                            (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                            matInput [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <input
                                                            (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                            matInput [placeholder]="f.fieldName"
                                                             matInput [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            [matDatepicker]="picker">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                        <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                                class="col-12">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>{{f.fieldName}} </mat-label>
                                                        <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                            input 
                                                            (keypress)="allowAlphaNumber($event)" matInput
                                                            [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                            formControlName="{{ f.fieldName }}"
                                                            ></textarea>
                                                            <mat-error
                                                            *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                            {{ f.fieldName }} is required
                                                            </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div *ngIf="grp.preDefinedGroup!=='TEACHERS DETAILS' && grp.preDefinedGroup!=='ADDITIONAL INFORMATION' && grp.preDefinedGroup!=='SKILLS & SOCIAL DETAILS'"
                                            class="proSection">
                                            <h5><i class="fas fa-book-reader mr-2"></i> {{grp.groupName}}</h5>
                                            <div class="row">
                                            <div class="col-lg-6 col-sm-12" *ngFor="let f of grp.fields">
                                                
                                                <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email!==true && f.isDate!==true && f.isNumber!==true"
                                                class="col-12">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                    <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                         [placeholder]="f.fieldName"
                                                         matInput [required]="f.isRequried==true"
                                                        formControlName="{{ f.fieldName }}">
                                                        <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                        </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isNumber==true"
                                            class="col-12">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                    <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                        type="number" (keypress)="allowOnlyNumber($event)" [placeholder]="f.fieldName"
                                                         matInput [required]="f.isRequried==true"
                                                        formControlName="{{ f.fieldName }}">
                                                        <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                        </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Dropdown'"
                                            class="col-12">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                    <mat-select
                                                        (selectionChange)="ddlChange($event.value,grp,f.fieldName)"
                                                        [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                        formControlName="{{ f.fieldName }}">
                                                        <mat-option value="" disabled>Select {{f.fieldName}}
                                                        </mat-option>
                                                        <mat-option
                                                            *ngFor="let drp of splitValues(f.defaultValue)"
                                                            [value]="drp">
                                                            {{drp}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                    *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                    {{ f.fieldName }} is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Checkbox' && f.defaultValue!==null && f.defaultValue!=='' && f.defaultValue!==undefined"
                                            class="col-12 py-2">
                                                <div class="w-100" appearance="outline">
                                                   
                                                    <ng-container>
                                                        <ul class="check d-flex">
                                                            <mat-label>{{f.fieldName}} </mat-label>
                                                            <li *ngFor="let chk of splitValues(f.defaultValue)" class="">
                                                                <mat-checkbox [checked]="setchkValue(f.fieldValue,chk)" class="example-margin mr-2"
                                                                    (change)="chkChange($event,grp,chk,f.fieldName)">
                                                                    {{ chk }}
                                                                </mat-checkbox>
                                                            </li>
                                                        </ul>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.email==true"
                                            class="col-12">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                    <input (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                        matInput [name]="f.fieldName"
                                                        [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                        matInput formControlName="{{ f.fieldName }}"
                                                        type="email" pattern="^[^\s]+(\s+[^\s]+)*$"
                                                        >
                                                        <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                        </mat-error>
                                                    <mat-error
                                                        *ngIf="form.get(f.fieldName)?.hasError('pattern')">
                                                        Please enter valid email
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Date'"
                                            class="col-12">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                    <input
                                                        (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                        (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                        matInput [placeholder]="f.fieldName"
                                                         matInput [required]="f.isRequried==true"
                                                        formControlName="{{ f.fieldName }}"
                                                        [matDatepicker]="picker">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textbox' && f.isDate==true"
                                            class="col-12">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                    <input
                                                        (dateChange)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                        (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                        matInput [placeholder]="f.fieldName"
                                                         matInput [required]="f.isRequried==true"
                                                        formControlName="{{ f.fieldName }}"
                                                        [matDatepicker]="picker">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="f.isDynamicField==true && f.fieldTypeName=='Textarea'"
                                            class="col-12">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>{{f.fieldName}} </mat-label>
                                                    <textarea (blur)="onBlurMethodtxt($event,grp,f.fieldName)"
                                                        input 
                                                        (keypress)="allowAlphaNumber($event)" matInput
                                                        [placeholder]="f.fieldName" [required]="f.isRequried==true"
                                                        formControlName="{{ f.fieldName }}"
                                                        ></textarea>
                                                        <mat-error
                                                        *ngIf="form.get(f.fieldName)?.touched  && form.get(f.fieldName)?.errors?.required">
                                                        {{ f.fieldName }} is required
                                                        </mat-error>
                                                </mat-form-field>
                                            </div>
                                                
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <!-- <div class="proSection">
                                        <h5><i class="fas fa-chalkboard-teacher mr-2"></i> Teachers Details</h5>

                                        <div class="row-flex">
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Employee Type</mat-label>
                                                    <mat-select placeholder="Employee Type" required name="employee"
                                                        formControlName="employeeTypeID">

                                                        <mat-option value="" disabled>Select Employee Type</mat-option>
                                                        <mat-option *ngFor="let employee of employeeTypes "
                                                            [value]="employee.employeeTypeID">
                                                            {{employee.employeeTypeName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('employeeTypeID')?.touched  && form.get('employeeTypeID')?.errors?.required">
                                                        Employee type is required is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Teacher Code </mat-label>
                                                    <input (keypress)="allowAlphaNumber($event)" matInput
                                                        placeholder="Teacher Code" required name="teacherNumber"
                                                        formControlName="teacherNumber" maxlength="10">
                                                    <mat-hint class="hintexted">Only 10 Number are allowed</mat-hint>
                                                    <mat-error *ngIf="form.get('teacherNumber')?.touched  && form.get('teacherNumber')?.errors?.required">
                                                        Teacher Code is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>First Name </mat-label>
                                                    <input maxlength="20" matInput placeholder="First Name" required name="firstname"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="firstName">
                                                    <mat-error
                                                        *ngIf="form.get('firstName')?.touched  && form.get('firstName')?.errors?.required">
                                                        First Name is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('firstName')?.hasError('pattern')">
                                                        Please enter valid First Name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Last Name</mat-label>
                                                    <input maxlength="20" matInput placeholder="Last Name" required name="lastName"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="lastName">
                                                    <mat-error
                                                        *ngIf="form.get('lastName')?.touched  && form.get('lastName')?.errors?.required">
                                                        Last Name is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('lastName')?.hasError('pattern')">
                                                        Please enter valid Last Name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <ngx-mat-intl-tel-input style="border: none;"
                                                        [preferredCountries]="['ae', 'us']" [enablePlaceholder]="true"
                                                        [enableSearch]="true" name="ContactNumber"
                                                        (keyup)="phoneLength($event)" formControlName="contactNumber"
                                                        #phone>
                                                    </ngx-mat-intl-tel-input>
                                                    <mat-hint class="hintexted">eg :
                                                        {{phone.selectedCountry.placeHolder}}</mat-hint>

                                                    <mat-error
                                                        *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                        Teacher contact is required
                                                    </mat-error>
                                                    <mat-error *ngIf="phoneShow">
                                                        Phone length should be less than 10 digits</mat-error>
                                                </mat-form-field>
                                            </div>


                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Teacher Email</mat-label>
                                                    <input matInput placeholder="email" required name="email"
                                                        pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="email" maxlength="50">
                                                    <mat-error
                                                        *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                        Teacher Email is required
                                                    </mat-error>
                                                    <mat-error *ngIf="form.get('email')?.hasError('pattern')">
                                                        Please enter valid Email
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Date Of Birth</mat-label>
                                                    <input (click)="picker.open()" [min]="minDate" [max]="maxDate"
                                                        matInput placeholder="Date Of Birth" required name="DateOfBirth"
                                                        formControlName="dateOfBirth" [matDatepicker]="picker">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                    <mat-error
                                                        *ngIf="form.get('dateOfBirth')?.touched  && form.get('dateOfBirth')?.errors?.required">
                                                        Field is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>



                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Gender</mat-label>
                                                    <mat-select placeholder="" required name="gender"
                                                        formControlName="genderID">
                                                        <mat-option *ngFor="let gender of genders "
                                                            [value]="gender.genderID">
                                                            {{gender.genderName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error
                                                        *ngIf="form.get('genderID')?.touched  && form.get('genderID')?.errors?.required">
                                                        Gender is required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>




                                        </div>
                                    </div> -->




                                    <!-- <div class="proSection">
                                        <h5><i class="fas fa-plus-square mr-2"></i>Additional Information</h5>
                                        <div class="row-flex">

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-select placeholder="Blood Group"
                                                        formControlName="bloodGroupID">
                                                        <mat-option value="" disabled>Blood Group</mat-option>
                                                        <mat-option *ngFor="let bloodGroup of bloodGroups"
                                                            [value]="bloodGroup.bloodGroupID">
                                                            {{bloodGroup.bloodGroupName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Address</mat-label>
                                                    <input matInput placeholder="address" maxlength="100" formControlName="address">
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Country</mat-label>
                                                    <mat-select placeholder="Country"
                                                        (valueChange)="GetStateList($event)"
                                                        formControlName="countryID">
                                                        <mat-option value="" disabled>Country</mat-option>
                                                        <mat-option *ngFor="let country of countries"
                                                            [value]="country.countryID">
                                                            {{country.countryName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>State</mat-label>
                                                    <mat-select placeholder="State" formControlName="stateID">
                                                        <mat-option value="" disabled>State</mat-option>
                                                        <mat-option *ngFor="let state of states"
                                                            [value]="state.stateID">
                                                            {{state.stateName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>City</mat-label>
                                                    <input maxlength="20" matInput placeholder="city"
                                                        formControlName="city">
                                                </mat-form-field>
                                            </div>
                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Zip Code</mat-label>
                                                    <input (keypress)="allowAlphaNumber($event)" maxlength="10" matInput
                                                        placeholder="Zip Code" formControlName="zipCode">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div> -->


                                    <!-- <div class="proSection">
                                        <h5><i class="fas fa-star mr-2"></i> Skills & Social Details</h5>

                                        <div class="row-flex">

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Profile Summary</mat-label>
                                                    <textarea matInput placeholder="Profile Summary"
                                                        formControlName="profileSummary" maxlength="250"></textarea>

                                                </mat-form-field>

                                            </div>


                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Select Teacher Skills </mat-label>
                                                    <mat-select placeholder="Stdenty Skills"
                                                        formControlName="teacherSkillsID">
                                                        <mat-option *ngFor="let skill of teacherSkills "
                                                            [value]="skill.teacherSkillID">
                                                            {{skill.teacherSkillsName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Facebook Profile Link</mat-label>
                                                    <input matInput placeholder="Facebook Profile Link"
                                                        formControlName="facebookLink" maxlength="100">
                                                </mat-form-field>
                                            </div>

                                            <div class="col s12 m6 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>LinkedIn Profile Link</mat-label>
                                                    <input matInput type="url" placeholder="LinkedIn Profile Link"
                                                        formControlName="linkedInlink" maxlength="100">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div> -->
                                    
                                    <div class="proSection text-right">
                                        <button n-submit type="submit" [disabled]="!form.valid" (click)="onSubmit()"
                                            class="btn btn-primary btn-sm" matRipple>Submit <svg
                                                xmlns="http://www.w3.org/2000/svg" width="12.611" height="8.478"
                                                viewBox="0 0 12.611 8.478">
                                                <g id="Group" transform="translate(12.611 8.478) rotate(180)">
                                                    <path id="Path"
                                                        d="M5.477,9.488h9.434a.7.7,0,0,1,0,1.4H5.477l2.282,2.282a.736.736,0,0,1-1.04,1.04l-3.5-3.5a.736.736,0,0,1,0-1.04l3.5-3.5a.736.736,0,0,1,1.04,1.04Z"
                                                        transform="translate(-3 -5.95)" fill="#fff" />
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #uploadPopup>
    <div class="dialog-md">
        <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
        <div class="dialogTitle loginTtile">
            <h5 mat-dialog-title>Select Image Area</h5>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

            </div>

        </mat-dialog-content>
    </div>
    <div class="proSection text-right">
        <button class="btn btn-primary btn-sm" mat-dialog-close (click)="finalUpload()" matRipple>Save</button>
    </div>
</ng-template>