import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { ManageAssessmentAndGradingsService } from '../../services/manage-assessment-and-gradings.service';

@Component({
  selector: 'app-reportcardformats',
  templateUrl: './reportcardformats.component.html',
  styleUrls: ['./reportcardformats.component.css']
})
export class ReportcardformatsComponent implements OnInit {
  dropdownSettings: any;
  customError: any;
  toppings = new FormControl('',Validators.required);
  reportCardFormat: FormGroup;
  multiselector: FormGroup;
  reportCardTemplateList: any;
  reportCardGroupList: any;
  toppingList: any;
  selectedItems: any[] = [];
  ShowFilter = true;

  users: any;
  user: any;
  permissions: any;
  academicSessionID: any;

  constructor(
    private formBuilder: FormBuilder,
    private reportCardService: ManageAssessmentAndGradingsService,
    private studentService: StudentService,
  ) { 
    this.multiselector = this.formBuilder.group({
      selectedClassSectionID: [this.selectedItems]
    });

    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    this.academicSessionID = currentUser.academicSessionID
    if (currentUser != null) {
      this.user = currentUser;
    }
  }

  ngOnInit(): void {
    this.reportCardFormat = this.formBuilder.group({
      Name:['',Validators.required],
      selectedClassSectionID:['', Validators.required],
      Template:['']
    });
    this.getClassSection();
    this.getTemplateList();
    // this.getGroupList();
  }

  customErr(){
    if(this.reportCardFormat.controls.Name.errors){
    let obj:any = this.reportCardFormat.controls.Name.errors;
      if(obj.required == true) {
        this.customError = 'Name cannot be empty';
      }
      if(obj.pattern){
        this.customError = 'Enter a valid Name';
      }
    }
  }

  getClassSection(){
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idDropDown',
      textField: 'dropDown',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };
    this.studentService.GetCombineClassSectionForAnalytics().subscribe((res:any)=>{
      this.toppingList=res.responseData;   
    })
  }
  

  getTemplateList() {
    this.reportCardService.GetAllReportCardTemplate().subscribe((res:any) => {
      //this.reportCardTemplateList = res.responseData;
      this.reportCardTemplateList = res.responseData.filter((item:any)=>{return item.templateName !="Template3" && item.templateName !="Template4"})
      console.log("TEMMPLATE",this.reportCardTemplateList)
    })
  }

  getGroupList() {
    this.reportCardService.GetAllReportCardGroup().subscribe((res:any) => {
      this.reportCardGroupList = res.responseData;
    })
  }

}
