<div class="container container1580">
  <div class="headingcontent">
    <div class="pageTitle">
      <h2>Manage Academics</h2>
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/admin"><i
                class="fas fa-home"></i> Home</a>
          <li class="breadcrumb-item"><a routerLink="../../academicslearning/manage-academic">Academic &amp;
              Learning</a></li>
          <li class="breadcrumb-item active" aria-current="page">Manage Academics</li>
        </ol>
      </div>
    </div>
    <div class="academic_content_head">
      <div class="add_button">
        <button class="addbtn btn" matRipple [matMenuTriggerFor]="addmenu"><i class="fas fa-plus-circle"></i> Add <i
            class="fas fa-chevron-down"></i></button>
        <mat-menu #addmenu="matMenu" xPosition="before">
          <button *ngIf="departmentMode" mat-menu-item (click)="openDialogModal(addDepartment)">Department</button>
          <button mat-menu-item (click)="openDialogModal(addClass)">Class</button>
          <button mat-menu-item (click)="openDialogModal(addSection)">Section</button>
          <button mat-menu-item (click)="openDialogModal(addSubject)">Subject</button>
        </mat-menu>

        <!-- <span [matMenuTriggerFor]="dotmenu" class="ml-2"><i class="fas fa-ellipsis-v"></i></span>
        <mat-menu #dotmenu="matMenu" xPosition="before">
          <button  (click)="EnableDepartment()"  mat-menu-item><span  *ngIf="!departmentMode">Enable Department</span><span *ngIf="departmentMode">Disable Department</span></button>
        
        </mat-menu> -->
        <div class="switchToggle ml-2 mt-12" *ngIf="checkActionPermission('Enable/Disable Department')" matTooltip="Enable or Disable the Department from here">
          <input type="checkbox" [checked]="checked" id="switch" (change)="changeDepartmentEnableDisable()"
            class="checkbox statusbtn">
          <label for="switch" class="toggle bigbtn">
            <span class="yes">Enable</span>
            <span class="no">Disable</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="academic_content" *ngIf="checkActionPermission('View Manage Academic')">

    <div class="academic_content_cards"
      [ngClass]="(this.collapseDeptFlag || this.collapseClassFlag || this.collapseSecFlag || this.collapseSubFlag) ? 'expand_cards' : ''">
      <div *ngIf="departmentMode" class="academic_card_1 cardcurves"
        [ngClass]="this.collapseDeptFlag ? 'collapse_dept' : ''">
        <div class="card_head_1 cardHeadCommon ">
          <div class="card_head_left">
            <i title="Collapse" class="fas fa-bars" (click)="collapseDepartment()"></i>
            <p>Departments</p>
          </div>
          <div class="card_head_right">
            <div class="inputGroup d-flex" [ngClass]=" flag ? 'd-none' : 'd-block'">
              <input matInput placeholder="Search" [(ngModel)]="searchDepartment" (keydown.space)="checkWhiteSpace($event)" (ngModelChange)="checkedDepartmentData($event)" class="form-control smallControl">
            </div>
            <i title="Search" class="fas fa-search" (click)="flag = !flag"></i>
            <i *ngIf="checkActionPermission('Add Department')" title="Add Department" class="fas fa-plus" (click)="openDepartmentModal(addDepartment)"></i>
            <!-- <i class="fas fa-ellipsis-v" [matMenuTriggerFor]="departmentMenu"></i>
            <mat-menu #departmentMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="OpenPo()">Add POs</button>
            </mat-menu> -->
          </div>
        </div>
        <div class="card_content_1 cardContentAcademic" cdkDropList
        
         (cdkDropListDropped)="drop($event, 'department', departmentListMenu)">
       
          <ul class="gridHead gridWidget">
            <li class="departName">NAME</li>
            <li class="actionCol">ACTIONS</li>
          </ul>
          <!-- <ul class="gridRow gridWidget" [ngClass]="{'selected': i == selectedDepartmentID, 'my_class2' : i != 0}" -->
          <ul class="gridRow gridWidget" [ngClass]="{'selected': i == selectedDepartment, 'my_class2' : i != 0}"
            (click)="filterClassListByDepartmentID(dept.departmentID, i)" cdkDrag 
            *ngFor="let dept of departmentListMenu | customSearch: 'departmentName' : searchDepartment; let i =index" [cdkDragData]="dept">
            <li class="departName" id="dept{{dept.departmentID}}" >{{dept.departmentName}}</li>
            <li class="actionCol">
              <i class="fas fa-arrows-alt"></i>
              <i *ngIf="checkActionPermission('Update Department')" class="fas fa-pen" title="Edit" (click)="editDepartmentModal(updateDepartment, dept.departmentID)"></i>
              <i *ngIf="checkActionPermission('Delete Department')" class="fas fa-trash" title="Delete" (click)="deleteDepartment(dept.departmentID)"></i>
            </li>
          </ul>

          <ul class="gridRow gridWidget" *ngIf="nodepartment==true">
            <li>No Departments</li>
          </ul>
          <p class="textatcenter d-flexedtext text-center" *ngIf="!departmentListMenu"> 
            <span class="d-md-block textheadeing">No departments</span>
            There are no departments at this moment. You can create on by clicking the button below
            <span class="d-md-block">    
               <button class="addbtn btn" matRipple (click)="openDepartmentModal(addDepartment)" ><i class="fas fa-plus-circle mr-2"></i>  Add Departments</button></span>
          </p>
        </div>
      </div>
      <div class="academic_card_2 cardcurves" [ngClass]="this.collapseClassFlag ? ['collapse_class'] : ''"
        [ngClass]="!departmentMode ? ['increase'] : ''">
        <div class="card_head_2 cardHeadCommon">
          <div class="card_head_left">
            <i title="Collapse" class="fas fa-bars" (click)="collapseClass()"></i>
            <p>Classes</p>
          </div>
          <div class="card_head_right">
            <div class="inputGroup d-flex" [ngClass]=" flag1 ? 'd-none' : 'd-block'">
              <input matInput placeholder="Search" [(ngModel)]="searchClass" (keydown.space)="checkWhiteSpace($event)" (ngModelChange)="checkedClassData($event)" class="form-control smallControl">
            </div>
            <i title="Search" class="fas fa-search" (click)="flag1 = !flag1"></i>
            <i *ngIf="checkActionPermission('Add Class')" title="Add Class" class="fas fa-plus" (click)="openClassModal(addClass)"></i>
          </div>
        </div>
        <div class="card_content_2 cardContentAcademic" cdkDropList (cdkDropListDropped)="drop($event, 'class', classListMenu)">
         
          <ul class="gridHead gridWidget">
            <li class="classNameCol">NAME</li>
            <li class="classCodeCol">CODE</li>
            <li class="actionCol">ACTIONS</li>
          </ul>
          <ul class="gridRow gridWidget" [ngClass]="{'selected': i == selectedClass, 'my_class2' : i != 0}"
            (click)="filterSectionListByClassId(class.classID, i)" cdkDrag
            *ngFor="let class of classListMenu | customSearch: 'className' : searchClass; let i =index" [cdkDragData]="class">
            <li class="classNameCol"  matTooltip="{{class.className}}">{{class.className}}</li>
            <li class="classCodeCol">{{class.classCode}}</li>
            <li class="actionCol">
              <i class="fas fa-arrows-alt"></i>
              <i *ngIf="checkActionPermission('Update Class')" title="Edit" class="fas fa-pen" (click)="editClassModal(addClass, class.classID)"></i>
              <i *ngIf="checkActionPermission('Delete Class')" title="Delete" class="fas fa-trash" (click)="deleteClass(class.classID)"></i>
            </li>
          </ul>
          <ul class="gridRow gridWidget" *ngIf="noclass==true">
            <li>No Classes</li>
          </ul>
          <p *ngIf="!classListMenu" class="textatcenter text-center">
            <span class="d-md-block d-flexedtext textheadeing">No Classes</span>
            There are no classes at this moment. You can create on by clicking the button below
            <span class="d-md-block">    
               <button class="addbtn btn" matRipple (click)="openClassModal(addClass)" ><i class="fas fa-plus-circle mr-2"></i>  Add Class</button></span>
            
          
            
          </p>
        </div>
      </div>
      <div class="academic_card_3 cardcurves" [ngClass]="this.collapseSecFlag  ? ['collapse_sec'] : ''"
        [ngClass]="!departmentMode ? ['increase'] : ''">
        <div class="card_head_3 cardHeadCommon">
          <div class="card_head_left">
            <i title="Collapse" class="fas fa-bars" (click)="collapseSection()"></i>
            <p>Sections</p>
          </div>
          <div class="card_head_right">
            <div class="inputGroup d-flex" [ngClass]=" flag2 ? 'd-none' : 'd-block'">
              <input matInput placeholder="Search" [(ngModel)]="searchSection" (keydown.space)="checkWhiteSpace($event)" (ngModelChange)="checkedSectionData($event)" class="form-control  smallControl">
            </div>
            <i title="Search" class="fas fa-search" (click)="flag2 = !flag2"></i>
            <i *ngIf="checkActionPermission('Add Section')" title="Add Section" class="fas fa-plus" (click)="openSectionModal(addSection)"></i>
          </div>
        </div>
        <div class="card_content_3 cardContentAcademic" cdkDropList (cdkDropListDropped)="drop($event, 'section', sectionsListMenu)">
        
          <ul class="gridHead gridWidget">
            <li class="sectionNameCol">NAME</li>
            <li class="sectionCodeCol">CODE</li>
            <li class="actionCol">ACTIONS</li>
          </ul>
          <ul class="gridRow gridWidget" [ngClass]="{'selected': i == selectedSection, 'my_class2' : i != 0}"
            (click)="filterSubjectListBySectionId(section.sectionID, i)" cdkDrag
            *ngFor="let section of sectionsListMenu | customSearch: 'sectionName' : searchSection; let i =index" [cdkDragData]="section">
            <li class="sectionNameCol"  matTooltip="{{section.sectionName}}">{{section.sectionName}}</li>
            <li class="sectionCodeCol">{{section.sectionCode}}</li>
            <li class="actionCol">
              <i class="fas fa-arrows-alt"></i>
              <i *ngIf="checkActionPermission('Update Section')" title="Edit" class="fas fa-pen" (click)="editSectionModal(addSection, section.sectionID)"></i>
              <i *ngIf="checkActionPermission('Delete Section')" title="Delete" class="fas fa-trash" (click)="deleteSection(section.sectionID)"></i>
            </li>
          </ul>
          <ul class="gridRow gridWidget" *ngIf="nosection==true">
            <li>No Sections</li>
          </ul>
          <p class="textatcenter d-flexedtext text-center" *ngIf="!sectionsListMenu">
            <span class="d-md-block textheadeing">  No Sections</span>
            There are no sections at this moment. You can create on by clicking the button below
            <span class="d-md-block">    
               <button class="addbtn btn" matRipple (click)="openSectionModal(addSection)" ><i class="fas fa-plus-circle mr-2"></i>  Add Section</button></span>
           
           
          </p>
          <!-- <p *ngIf="!sectionsListMenu">No sections
            There are no sections at this moment. You can create on by clicking the button below
            </p>  -->
        </div>
      </div>
      <div class="academic_card_4 cardcurves" [ngClass]="this.collapseSubFlag  ? ['collapse_sub'] : ''"
        [ngClass]="!departmentMode ? ['increase'] : ''">
        <div class="card_head_4 cardHeadCommon">
          <div class="card_head_left">
            <i title="Collapse" class="fas fa-bars" (click)="collapseSubject()"></i>
            <p>Subjects</p>
          </div>
          <div class="card_head_right">
            <div class="inputGroup d-flex" [ngClass]=" flag3 ? 'd-none' : 'd-block'">
              <input matInput placeholder="Search" [(ngModel)]="searchSubject" (keydown.space)="checkWhiteSpace($event)" (ngModelChange)="checkedData($event)"
                class="form-control smallControl">
            </div>
            <i title="Search" class="fas fa-search" (click)="flag3 = !flag3"></i>
            <i *ngIf="checkActionPermission('Add Subject')" title="Add Subject" class="fas fa-plus" (click)="openSubjectModal(addSubject)"></i>
            <!-- <i class="fas fa-ellipsis-v" [matMenuTriggerFor]="subjectMenu"></i> -->
            <!-- <mat-menu #subjectMenu="matMenu" xPosition="before">
              <button (click)="OpenCo()" mat-menu-item>Add COs</button>
            </mat-menu> -->
          </div>
        </div>
        <div class="card_content_4 cardContentAcademic" cdkDropList (cdkDropListDropped)="drop($event, 'subject', subjectsListMenu)">
         
          <ul class="gridHead gridWidget">
            <li class="subjectName">NAME</li>
            <li class="subjectCode">CODE</li>
            <li class="subJectCredit">CREDIT</li>
            <li class="subjectType">TYPE</li>
            <li class="actionCol">ACTIONS</li>
          </ul>
          <ul class="gridRow gridWidget" cdkDrag
            *ngFor="let subject of subjectsListMenu | customSearch: 'subjectName' : searchSubject; let i =index">
            <!-- <li *ngIf="true==true">No Subjects</li> -->
            <li class="subjectName" matTooltip="{{subject.subjectName}}">{{subject.subjectName}}</li>
            <li class="subjectCode" matTooltip="{{subject.subjectCode}}">{{subject.subjectCode}}</li>
            <li class="subJectCredit">{{subject.subjectCredit}}</li>
            <li *ngIf="subject.isCore==true" class="subjectType Bold">Core</li>
            <li *ngIf="subject.isCore==false" class="subjectType Bold">Elective</li>
            <!-- <li class="subjectType Bold" [ngClass]="subject.subjectType === 'Elective' ? 'elactive' : 'core'">
              {{subject.subjectType}}</li>  -->
            <li class="actionCol">
              <i class="fas fa-arrows-alt"></i>
              <i *ngIf="checkActionPermission('Update Subject')" title="Edit" class="fas fa-pen" (click)="editSubjectModal(addSubject, subject.subjectID)"></i>
              <i *ngIf="checkActionPermission('Delete Subject')" title="Delete" class="fas fa-trash" (click)="deleteSubject(subject.subjectID)"></i>
            </li>

          </ul>
          <ul class="gridRow gridWidget" *ngIf="nosubject==true">
            <li>No Subjects</li>
          </ul>
          <p class="textatcenter d-flexedtext text-center" *ngIf="!subjectsListMenu"><span class="d-md-block textheadeing"> No subjects</span>
            There are no subjects at this moment. You can create on by clicking the button below
            <span class="d-md-block">    
               <button class="addbtn btn" matRipple  (click)="openSubjectModal(addSubject)" ><i class="fas fa-plus-circle mr-2"></i>  Add Subjects</button></span> 
          </p>
          <!-- <p *ngIf="(subjectsListMenu | filter:subjectTerm)?.length===0">No subjects
            There are no subjects at this moment. You can create on by clicking the button below
          </p> -->
          <!-- <p *ngIf="!i">No subject found</p> -->
          <!-- <div [ngSwitch]="conditionExpression">
            <div *ngSwitchCase="expression">output</div>
            <div *ngSwitchDefault>output2</div>
          </div>
          <ng-template #templateName>
            <h2 mat-dialog-title>Add Section</h2>
          </ng-template> -->
          <!-- *ngIf="expression;else templateName" -->
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #addSection>
  <div class="dialog-md">
    <i class="material-icons popupClose" (click)="reset()" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Section</h2>
      <ng-template #elseTemplate>
        <h2 mat-dialog-title>Add Section</h2>
      </ng-template>
    </div>
    <form [formGroup]="addSectionForm" (ngSubmit)="onSubmitSection()">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Class</mat-label>
        <mat-select placeholder="Select Class" [disabled]="disableSelectClass" formControlName="classID">
          <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
            {{classSelect.className}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addSectionForm.get('classID')?.touched && addSectionForm.get('classID')?.errors?.required">
          Please Select a class
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Section Name</mat-label>
        <input matInput placeholder="Section Name" formControlName="sectionName"
          (keydown.space)="checkWhiteSpace($event)" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50">
        <mat-error
          *ngIf="addSectionForm.get('sectionName')?.touched && addSectionForm.get('sectionName')?.errors?.required">
          Section Name is required
        </mat-error>
        <mat-error *ngIf="addSectionForm.get('sectionName')?.hasError('pattern')">
          Please enter valid section name
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Section Code</mat-label>
        <input matInput placeholder="Section Code" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="20"
          formControlName="sectionCode">
        <mat-error
          *ngIf="addSectionForm.get('sectionCode')?.touched && addSectionForm.get('sectionCode')?.errors?.required">
          Section Code is required
        </mat-error>
        <mat-error *ngIf="addSectionForm.get('sectionCode')?.hasError('pattern')">
          Please enter valid section code
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Students Limit</mat-label>
        <input matInput type="text" pattern="[0-9]+" placeholder="Students Limit" maxlength="5"
          formControlName="studentsLimit">
        <mat-error
          *ngIf="addSectionForm.get('studentsLimit')?.touched && addSectionForm.get('studentsLimit')?.errors?.required">
          Student limit is required
        </mat-error>
        <mat-error *ngIf="addSectionForm.get('studentsLimit')?.hasError('pattern')">
          Only numbers are allowed.
        </mat-error>
      </mat-form-field>

      <div class="btnsWidget form-group17 center">
        <div class="text-right">



          <button type="reset" (click)="reset()" class="btn btn-reset btn-sm m-0 mr-2" mat-dialog-close>Cancel</button>
          <button type="submit" class="btn btn-primary btn-sm m-0 mr-2" 
            [disabled]="addSectionForm.invalid">Save</button>
        </div>
      </div>



      <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSectionEvent()"
          [disabled]="addSectionForm.invalid">Save</button>
      </div> -->
    </form>
  </div>
</ng-template>

<ng-template #addDepartment>
  <div class="dialog-md">
    <i class="material-icons popupClose" (click)="reset()" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Add Department</h2>
    </div>
    <form [formGroup]="addDepartmentForm" (ngSubmit)="onSubmit()">
      <!-- <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Academic Session</mat-label>
        <mat-select placeholder="Session" formControlName="academicSessionID">
          <mat-option value="Select Session">Academic Sessions</mat-option>
          <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
            {{session.academicSessionName}}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="addDepartmentForm.get('academicSessionID')?.touched  && addDepartmentForm.get('academicSessionID')?.errors?.required">
          academicSession is required
        </mat-error>
      </mat-form-field> -->
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Department Name</mat-label>
        <input matInput placeholder="Department Name" pattern="^[^\s]+(\s+[^\s]+)*$" name="deptName"
          formControlName="departmentName" maxlength="50" required />
        <mat-error
          *ngIf="addDepartmentForm.get('departmentName')?.touched  && addDepartmentForm.get('departmentName')?.errors?.required">
          Department name is required
        </mat-error>
        <!-- <mat-error *ngIf="addDepartmentForm.get('departmentName')?.hasError('maxlength') && addDepartmentForm.get('departmentName')?.touched">Department name length should not be more than 20 char.</mat-error> -->
        <mat-error *ngIf="addDepartmentForm.get('departmentName')?.hasError('pattern')">
          Please enter valid department name
        </mat-error>
      </mat-form-field>

      <div class="btnsWidget form-group17 center">
        <div class="text-right">

          <button type="reset" (click)="reset()" class="btn btn-reset btn-sm m-0 mr-2" mat-dialog-close>Cancel</button>

          <button type="submit" class="btn btn-primary btn-sm  m-0 mr-2" name="deptBtn"
            [disabled]="addDepartmentForm.invalid">Save</button>


        </div>


      </div>

      <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close name="deptBtn" (click)="addDepartmentEvent()"
          [disabled]="addDepartmentName.invalid">Save</button>
      </div> -->
    </form>
  </div>
</ng-template>

<ng-template #updateDepartment>
  <div class="dialog-md">
    <i class="material-icons popupClose" (click)="reset()" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Update Department</h2>
    </div>
    <form [formGroup]="addDepartmentForm" (ngSubmit)="onUpdateSubmit()">
      <!-- <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Academic Session</mat-label>
        <mat-select placeholder="Session" formControlName="academicSessionID">
          <mat-option value="Select Session">Academic Sessions</mat-option>
          <mat-option *ngFor="let session of academicSessions" [value]="session.academicSessionID">
            {{session.academicSessionName}}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="addDepartmentForm.get('academicSessionID')?.touched  && addDepartmentForm.get('academicSessionID')?.errors?.required">
          academicSession is required
        </mat-error>
      </mat-form-field> -->
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Department Name</mat-label>
        <input matInput placeholder="Department Name" pattern="^[^\s]+(\s+[^\s]+)*$" name="deptName"
          formControlName="departmentName" maxlength="50" required />
        <mat-error
          *ngIf="addDepartmentForm.get('departmentName')?.touched  && addDepartmentForm.get('departmentName')?.errors?.required">
          Department name is required
        </mat-error>
        <!-- <mat-error *ngIf="addDepartmentForm.get('departmentName')?.hasError('maxlength') && addDepartmentForm.get('departmentName')?.touched">Department name length should not be more than 20 char.</mat-error> -->
        <mat-error *ngIf="addDepartmentForm.get('departmentName')?.hasError('pattern')">
          Please enter valid department name
        </mat-error>
      </mat-form-field>

      <div class="btnsWidget form-group17 center">
        <div class="text-right">

          <button type="reset" (click)="reset()" class="btn btn-reset btn-sm m-0 mr-2" mat-dialog-close>Cancel</button>

          <button type="submit" class="btn btn-primary btn-sm  m-0 mr-2" name="deptBtn"
            [disabled]="addDepartmentForm.invalid">Save</button>


        </div>


      </div>

      <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close name="deptBtn" (click)="addDepartmentEvent()"
          [disabled]="addDepartmentName.invalid">Save</button>
      </div> -->
    </form>
  </div>
</ng-template>

<ng-template #addClass>
  <div class="dialog-md">
    <i class="material-icons popupClose" (click)="reset()" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Class</h2>
      <ng-template #elseTemplate>
        <h2 mat-dialog-title>Add Class</h2>
      </ng-template>
    </div>
    <form [formGroup]="addClassForm" (ngSubmit)="onSubmitClass()">
      <mat-form-field *ngIf="departmentMode" class="w-100" appearance="outline">
        <mat-label>Select Department</mat-label>
        <mat-select placeholder="Department" [disabled]="disableSelectDept"  formControlName="departmentID">
          <!-- <mat-option value="Select Department">Department</mat-option> -->
          <mat-option *ngFor="let department of departmentListDropdown" [value]="department.departmentID">
            {{department.departmentName}}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="addClassForm.get('departmentID')?.touched  && addClassForm.get('departmentID')?.errors?.required">
          Department is required
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Class Name</mat-label>
        <input matInput placeholder="Class Name" formControlName="className" pattern="^[^\s]+(\s+[^\s]+)*$"
          maxlength="50">
        <mat-error *ngIf="addClassForm.get('className')?.touched && addClassForm.get('className')?.errors?.required">
          Class name is required
        </mat-error>
        <mat-error *ngIf="addClassForm.get('className')?.hasError('pattern')">
          Please enter valid class name
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Class Code</mat-label>
        <input matInput placeholder="Class Code" (keydown.space)="checkWhiteSpace($event)"
          pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="20" formControlName="classCode">
        <mat-error *ngIf="addClassForm.get('classCode')?.touched && addClassForm.get('classCode')?.errors?.required">
          Class code is required
        </mat-error>
        <mat-error *ngIf="addClassForm.get('classCode')?.hasError('pattern')">
          Please enter valid class code
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Students Limit</mat-label>
        <input matInput type="text" pattern="[0-9]+" placeholder="Students Limit" formControlName="studentsLimit"
          maxlength="5">
        <mat-error
          *ngIf="addClassForm.get('studentsLimit')?.touched && addClassForm.get('studentsLimit')?.errors?.required">
          Student limit is required
        </mat-error>
        <mat-error *ngIf="addClassForm.get('studentsLimit')?.hasError('pattern')">
          Only numbers are allowed.
        </mat-error>
      </mat-form-field>

      <div class="btnsWidget form-group17 center">
        <div class="text-right">



          <button type="reset" (click)="reset()" class="btn btn-reset btn-sm m-0 mr-2" mat-dialog-close>Cancel</button>

          <button type="submit" class="btn btn-primary btn-sm m-0 mr-2" 
            [disabled]="addClassForm.invalid">Save</button>


        </div>
      </div>

      <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close (click)="addClassEvent()"
          [disabled]="addClassForm.invalid">Save</button>
      </div> -->
    </form>
  </div>
</ng-template>

<ng-template #addSubject>
  <div class="dialog-md">
    <i class="material-icons popupClose" (click)="subReset()" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 *ngIf="updateTextInPopUp; else elseTemplate" mat-dialog-title>Update Subject</h2>
      <ng-template #elseTemplate>
        <h2 mat-dialog-title>Add Subject</h2>
      </ng-template>
    </div>
    <form [formGroup]="addSubjectForm" (ngSubmit)="onSubmitSubject()">
      <div class="row">
        <div class="col-lg-6 col-sm-12">

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Class</mat-label>
            <mat-select placeholder="Select Class" [disabled]="disableSelectClass" (selectionChange)="selectSectionByClassID($event)"
              formControlName="classID">
              <mat-option *ngFor="let classSelect of classListDropdown" [value]="classSelect.classID">
                {{classSelect.className}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="addSubjectForm.get('classID')?.touched && addSubjectForm.get('classID')?.errors?.required">
              Please select a class
            </mat-error>
          </mat-form-field>


        </div>
        <div class="col-lg-6 col-sm-12">

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Select Section</mat-label>
            <mat-select placeholder="Select Section" [disabled]="disableSelectSec" formControlName="sectionID">
              <mat-option *ngFor="let sectionSelect of sectionListDropdown" [value]="sectionSelect.sectionID">
                {{sectionSelect.sectionName}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="addSubjectForm.get('sectionID')?.touched && addSubjectForm.get('sectionID')?.errors?.required">
              Please select a section
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-sm-12">

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Subject Name</mat-label>
            <input matInput placeholder="Subject Name" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50"
              (keydown.space)="checkWhiteSpace($event)" formControlName="subjectName">
            <mat-error
              *ngIf="addSubjectForm.get('subjectName')?.touched && addSubjectForm.get('subjectName')?.errors?.required">
              Subject name is required
            </mat-error>
            <mat-error *ngIf="addSubjectForm.get('subjectName')?.hasError('pattern')">
              Please enter valid subject name
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-sm-12">

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Subject Code</mat-label>
            <input matInput placeholder="Subject Code" (keydown.space)="checkWhiteSpace($event)" maxlength="20"
              pattern="^[^\s]+(\s+[^\s]+)*$" formControlName="subjectCode">
            <mat-error
              *ngIf="addSubjectForm.get('subjectCode')?.touched && addSubjectForm.get('subjectCode')?.errors?.required">
              Subject code is required
            </mat-error>
            <mat-error *ngIf="addSubjectForm.get('subjectCode')?.hasError('pattern')">
              Please enter valid subject code
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-sm-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Subject Credits/Score</mat-label>
            <input matInput placeholder="Subject Credits/ Score" pattern="[0-9]+" formControlName="subjectCredit"
              maxlength="10">
            <mat-error
              *ngIf="addSubjectForm.get('subjectCredit')?.touched && addSubjectForm.get('subjectCredit')?.errors?.required">
              Subject credits/score is required
            </mat-error>
            <mat-error *ngIf="addSubjectForm.get('subjectCredit')?.hasError('pattern')">
              Only numbers are allowed.
            </mat-error>
          </mat-form-field>
        </div>


        <div class="col-lg-6 col-sm-12">
          <div class="radioDefaultColor form-group35">
            <label class="label-block">Subject Type</label>
            <mat-radio-group formControlName="isCore">
              <mat-radio-button [value]=true>Core</mat-radio-button>
              <mat-radio-button [value]=false>Elective</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="addSubjectForm.get('isCore')?.touched && addSubjectForm.get('isCore')?.errors?.required">
              Subject type is required
            </mat-error>
          </div>
        </div>





      </div>


      <div class="btnsWidget form-group17 center">



        <div class="text-right">

          <button type="submit" class="btn btn-primary btn-sm m-0 mr-2" 
            [disabled]="addSubjectForm.invalid">Save</button>

          <button typetype="reset" (click)="subReset()" class="btn btn-reset btn-sm m-0 mr-2"
            mat-dialog-close>Cancel</button>

        </div>
      </div>

      <!-- <div class="forgotpassword form-group17 center">
        <button type="button" class="btn btn-primary" mat-dialog-close (click)="addSubjectEvent()"
          [disabled]="addSubjectForm.invalid">Save</button>
      </div> -->
    </form>
  </div>
</ng-template>