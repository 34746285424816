import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-teacherstabs',
  templateUrl: './teacherstabs.component.html',
  styleUrls: ['./teacherstabs.component.css']
})
export class TeacherstabsComponent implements OnInit {

  constructor(
    private activatedRoute:ActivatedRoute,
    private router: Router,
  ) { }
  teacherID:any;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.teacherID = params.id==undefined?null:params.id;
    });
  }

}
