import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as saveAs from 'file-saver';
import { StudentService } from 'src/app/services/student.service';
import { TeachersService } from 'src/app/services/teachers.service';
import { ModuleDefinition } from '../../MasterModel/masterModel';
import * as XLSX from 'xlsx';
import { MappingContainerComponent } from '../mapping-container/mapping-container.component';
import { NumberInput } from '@angular/cdk/coercion';

@Component({
  selector: 'app-import-status-report-link',
  templateUrl: './import-status-report-link.component.html',
  styleUrls: ['./import-status-report-link.component.css']
})
export class ImportStatusReportLinkComponent implements OnInit {

  public link: number;
  public message: string;
  public caller:string;
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  failedCount: number=0;
  totalCount: number=0;
  showStats: boolean;
  parentRef:MappingContainerComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private studenService: StudentService,private teacherService: TeachersService) {
    this.link = data.dataKey.link;
    this.message=data.dataKey.message;
    this.caller = data.dataKey.caller;
    this.failedCount = data.dataKey.failedCount;
    this.totalCount=data.dataKey.totalCount;
    this.showStats= data.dataKey.showStats;
    this.parentRef= data.dataKey.parentRef;
   }

  ngOnInit(): void {
  }
  closeDialog(){
    this.parentRef.dialogRef.close();
    this.parentRef.parentRef.closeDialog();
  }
  exportCsvToXlsx(data: any,filename: string){
    let workBook: any = null;
          let parsedExcelRowArray = null;
          let rawExcel: File;
         const reader = new FileReader();
         reader.onload = (event) => {
         const data = reader.result;
         workBook = XLSX.read(data, { type: 'binary' });
         rawExcel = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

           const blob = new File([new Blob([rawExcel], {type: this.fileType})], `${filename}.xlsx`);
          const url= window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          link.download = `${filename}.xlsx`;
          link.click();
       };

        reader.readAsBinaryString(data);
  }
  downloadFile(data : any){
    this.exportCsvToXlsx(data,"StatusReport");
  }
  getStatusReport(){
    switch(this.caller){
      case ModuleDefinition.Student: { 
         this.studenService.getStatusReport(this.link).subscribe({
          next: (data: any)=>{
            this.downloadFile(data);
         
          },
          error: (event: any)=>{},
        });
        break;
    }
    case ModuleDefinition.Teacher: {
      this.teacherService.getStatusReport(this.link).subscribe({
        next: (data: any)=>{
            this.downloadFile(data);
          
        },
        error: (event: any)=>{},
      });
      break;
      }
    }
  }
}
